import { Fragment } from "react";

export const QuizProgress = ({ currentQuestionIndex, showProgress, totalQuiz }) => {
  return (
    <Fragment>
      {!showProgress ? (
        <div className="w-full h-2 bg-secondary rounded-full" />
      ) : (
        <div className="flex items-center gap-1">
          {Array(totalQuiz)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                className={`w-full h-2 rounded-full transition-colors ease-out duration-300 ${
                  index <= currentQuestionIndex ? "bg-secondary" : "bg-[#FE9923]"
                }`}
              />
            ))}
        </div>
      )}
    </Fragment>
  );
};
