import { Form, message, Pagination, Modal, Input, Button, Radio, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import TitleMasterAdmin from "../../componets_global/TitleMaster";
import deleteIcon from "../../../../assets/icons/delete.png";

const { Option } = Select;

const CategoriesQuizMaster = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [formCategory] = Form.useForm();
    const [titleModal, setTitleModal] = useState("Add Category");
    const [formType, setFormType] = useState("add");
    const [detailCategory, setDetailCategory] = useState(undefined);
    const [valueCategoryType, setValueCategoryType] = useState(1);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);

    const dummyData = [
        {
            key: 1,
            id: 1,
            name: "Parent Category 1",
            child: [
                {
                    key: 1,
                    id: 4,
                    parentId: 1,
                    name: "sub 1 of parent category 1"
                },
                {
                    id: 5,
                    key: 2,
                    parentId: 1,
                    name: "sub 2 of parent category 1"
                }
            ]
        },
        {
            id: 2,
            key: 2,
            name: "Parent Category 2"
        },
        {
            id: 3,
            key: 3,
            name: "Parent Category 3"
        }
    ]

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            dataIndex: '',
            align: 'center',
            key: 'x',
            render: (_, record) => {
                return (
                    <div className="flex justify-end pr-[14px]">
                        <div
                            className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
                        >
                            <span className="text-red-600">Edit</span>
                        </div>
                        <img
                            className="cursor-pointer"
                            src={deleteIcon}
                            alt="delete"
                        />
                    </div>
                );
            },
        },
    ];

    const columnsChild = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            dataIndex: '',
            align: 'center',
            key: 'x',
            render: (_, record) => {
                return (
                    <div className="flex justify-end">
                        <div
                            className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
                        >
                            <span className="text-red-600">Edit</span>
                        </div>
                        <img
                            className="cursor-pointer"
                            src={deleteIcon}
                            alt="delete"
                        />
                    </div>
                );
            },
        },
    ];

    const closeModal = (modal = "form") => {
        setIsOpenModal(false)
    };
    const showAddModal = (id) => {
        setIsOpenModal(true)
    };

    const onChangeType = (el) => {
        setValueCategoryType(el.target.value);
    };

    const onChangePage = (el) => {

    };

    const handleScrollEventAccount = (event) => {

    };

    useEffect(async () => {

    }, []);

    return (
        <>
            {contextHolder}
            <div className="w-full h-screen px-4 py-5">
                <TitleMasterAdmin>Categories</TitleMasterAdmin>
                <div className="flex flex-col justify-end lg:flex-row gap-4">

                    <div className="flex flex-row gap-4 lg:mb-0">
                        <ActionBarButtonOnly
                            buttonTitle="Add Category"
                            placeholder="Add new category"
                            actionButton={showAddModal}
                        />
                    </div>
                </div>
                <div className="mt-[20px] w-full">
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="pl-[40px] w-full">
                                    <Table columns={columnsChild} dataSource={record.child} pagination={false}></Table>
                                </div>
                            )
                        }}
                        pagination={false}
                        dataSource={dummyData}
                    />
                </div>
                <div className="flex justify-center mt-5">

                    <Pagination
                        current={page}
                        onChange={onChangePage}
                        pageSize={limit}
                        total={totalItem}
                        showSizeChanger={false}
                    />
                </div>
            </div>
            <Modal
                onCancel={() => closeModal()}
                title={titleModal}
                open={isOpenModal}
                footer={
                    <>
                        <div className="flex justify-between mt-[20px]">
                            <Button key="back" danger onClick={() => closeModal()}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "#DD0000", color: "white" }}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                }
            >
                <div className="container p-[10px] my-[30px]">
                    <Radio.Group onChange={onChangeType} value={valueCategoryType} className="pb-[20px]">
                        <Radio value={1}>Parent Category</Radio>
                        <Radio value={2}>Child Category</Radio>
                    </Radio.Group>
                    <Form
                        labelCol={{ flex: "110px" }}
                        labelAlign="left"
                        form={formCategory}
                        colon={false}
                        wrapperCol={{ flex: 1 }}
                    >
                        {
                            valueCategoryType === 2 ? (
                                <>
                                    <Form.Item

                                        label="Parent Category"
                                        name="parentCategory"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Select Parent Category",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="w-full"
                                            showSearch
                                            placeholder="Choose a Parent Category"
                                            optionFilterProp="children"
                                            dropdownRender={(menu) => (
                                                <div onWheel={handleScrollEventAccount}>{menu}</div>
                                            )}
                                        >
                                            {dummyData.map((valParent) => (
                                                <Option key={valParent.id} value={valParent.id}>
                                                    {valParent.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </>
                            ) : <></>
                        }

                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input category",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Please input category"
                                disabled={formType !== "add"}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

const ActionBarSearchOnly = ({
    buttonTitle,
    actionButton,
    onSearch,
    placeholder,
}) => {
    return (
        <Input
            className="w-full lg:w-[200px] max-w-[400px]"
            placeholder={placeholder}
            suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
            onChange={onSearch}
        />
    );
};

const ActionBarButtonOnly = ({ buttonTitle, actionButton }) => {
    return (
        <div className=" flex justify-center">
            {actionButton && (
                <div
                    onClick={actionButton}
                    className="bg-red-600  w-[150px] h-[30px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
                >
                    {buttonTitle}
                </div>
            )}
        </div>
    );
};

export default CategoriesQuizMaster;
