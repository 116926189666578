import { Result, Spin } from "antd";
import { Fragment } from "react";
import { useQuery } from "react-query";

import { BackButton } from "../components/BackButton";
import { QuizIntroduction } from "../components/QuizIntroduction";
import { QuizProgress } from "../components/QuizProgress";
import { QuizQuestion } from "../components/QuizQuestion";
import { QuizConfirmation } from "../components/QuizConfirmation";
import { QuizResult } from "../components/QuizResult";
import { useQuiz } from "../hooks/useQuiz";
import { useParams } from "react-router-dom";
import { QuizService } from "../services/quiz.service";

const quizService = new QuizService();

export const QuizPage = () => {
  const params = useParams();

  const query = useQuery(
    ["get-quiz-by-task-id", { quizId: params.quizId, eventId: params.eventId }],
    quizService.get.quizDetail,
    {
      retry: false,
      select: (data) => {
        return {
          ...data.data,
          items: data.data.items.map((question, index) => ({
            id: question.id,
            label: `Question ${index + 1}`,
            question: question.question,
            options: question.answer.map((answer) => ({
              id: answer.id,
              answer: answer.answer,
            })),
          })),
          status: data.status,
          message: data.message,
        };
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    answers,
    canGoToNextQuestion,
    currentQuestion,
    currentQuestionIndex,
    isFirstQuestion,
    isLastQuestion,
    isQuizConfirmed,
    isQuizIntroduction,
    isQuizStarted,
    isQuizSubmitted,
    quizResult,
    chooseAnswer,
    goBackFromConfirming,
    goToNextQuestion,
    goToPrevQuestion,
    startTakingQuiz,
    submitAnswers,
  } = useQuiz({
    questions: query.data ? query.data.items : [],
  });

  return (
    <main className="relative w-full h-screen overflow-hidden bg-primary">
      {query.isLoading ? (
        <div className="flex items-center justify-center py-12">
          <Spin size="large" />
        </div>
      ) : query.isError ? (
        <Result status="404" title="404" subTitle={query.error?.message} />
      ) : query.data.status === 200 ? (
        <Fragment>
          <QuizProgress
            currentQuestionIndex={currentQuestionIndex}
            showProgress={isQuizStarted}
            totalQuiz={query.data.numberOfQuestions}
          />
          {isQuizIntroduction ? (
            <Fragment>
              <BackButton />
              <QuizIntroduction
                overviewTitle={query.data.overview.title}
                overviewMessage={query.data.overview.message}
                startTakingQuiz={startTakingQuiz}
              />
            </Fragment>
          ) : isQuizConfirmed ? (
            <QuizConfirmation
              goToPrevQuestion={goBackFromConfirming}
              submitAnswers={submitAnswers}
            />
          ) : isQuizSubmitted ? (
            <QuizResult result={quizResult} />
          ) : (
            <QuizQuestion
              {...currentQuestion}
              canGoToNextQuestion={canGoToNextQuestion}
              chosenAnswer={answers[currentQuestionIndex]}
              isFirstQuestion={isFirstQuestion}
              isLastQuestion={isLastQuestion}
              chooseAnswer={chooseAnswer}
              goToNextQuestion={goToNextQuestion}
              goToPrevQuestion={goToPrevQuestion}
            />
          )}
        </Fragment>
      ) : (
        <p>{query.data.message || "An error occured"}</p>
      )}
    </main>
  );
};
