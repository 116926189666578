import { Button, Modal, Spin, Form, Input, Radio } from "antd";
import React, { useState, useEffect } from "react";
import AlertUtility from "../../../../components/Alert/Alert";
import Onboarding from "../../../../repositores/adminRepo/OnboardRepo";
import { isNullOrEmpty } from "../../../../utils/utils";

const ModalAddOnboard = ({ show, closeModal, type, defaultValue }) => {
  const fileType = [".pdf", ".doc", ".docx"];
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState(null);

  const [formOnBoard] = Form.useForm();

  useEffect(() => {
    if(type === 'edit') {
        formOnBoard.setFieldValue('name', defaultValue?.name)
        formOnBoard.setFieldValue('description', defaultValue?.description)
        formOnBoard.setFieldValue('status', defaultValue?.status)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  const onChangeFile = (input) => {
    const value = input.target.value;
    const selectedFile = input.target.files[0];
    const type = value.substring(value.lastIndexOf("."));
    if (!fileType.includes(type)) {
      deleteFile()
    } else {
      setFileData(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const deleteFile = () => {
    setFileData(null);
    setFileName("");
  }

  const uploadDocument = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
      if(type == "add") {
        formData.append("id", "");
        formData.append("status", "ACTIVE");
      } else {
        formData.append("id", defaultValue.id);
        formData.append("status", formOnBoard.getFieldValue('status'));
      }
      formData.append("name", formOnBoard.getFieldValue('name'));
      formData.append("description", formOnBoard.getFieldValue('description'));
      formData.append("file", fileData);
      
      const { data } = await Onboarding.createOrUpdateOnBoard(formData, type)
      if (data.status == 200) {
        AlertUtility.SuccessAlert({title: "Import data succesfully"})
        closeModal(false, true)
      } else {
        throw data.message
      }
    } catch (error) {
        if(typeof error === 'undefined' ) {
            AlertUtility.ErrorAlert({title: 'Import data failed, please try again later!'})
        } else {
            AlertUtility.ErrorAlert({title: error})
        }
    } finally {
      setLoading(false)
      deleteFile()
    }
  }

  return (
    <Modal open={show} onCancel={() => closeModal(false)} footer={null}>
      {
        loading ?
        <div className="flex items-center justify-center py-8">
          <Spin />
        </div>  :
        <>
          <div className="mb-8 font-medium">
            <div className="text-xl text-[#4C4C4C]">{type == "add" ? 'Add' : 'Edit'} Data </div>
          </div>
          <div className="p-5 rounded border-[1px] border-solid border-[#DDDDDD]">
            <div className="container p-[10px] my-[30px]">
                <Form
                    labelCol={{ flex: "110px" }}
                    labelAlign="left"
                    form={formOnBoard}
                    colon={false}
                    wrapperCol={{ flex: 1 }}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input name",
                            },
                        ]}
                    >
                        <Input placeholder="Please input name"  />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: "Please input Description",
                            },
                        ]}
                    >
                        <Input placeholder="Please input description" />
                    </Form.Item>
                    {
                        type === 'edit' ? 
                        (<>
                            <Form.Item
                                label="Status"
                                name="status"
                            >
                                <Radio.Group>
                                    <Radio value="ACTIVE">ACTIVE</Radio>
                                    <Radio value="INACTIVE">INACTIVE</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </>) : <></>
                    }
                    

                </Form>
            </div>
            <div className="text-[#000]">Let's import data</div>
            <div className="max-w-xl mb-3">
              <label className="flex justify-center items-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <span className="flex flex-col items-center">
                  <img src="/assets/icons/upload.svg" alt="upload" width="35px" />
                  <span className="font-medium text-gray-600">
                    Click here!
                  </span>
                </span>
                <input
                  type="file"
                  name="file_upload"
                  className="hidden"
                  accept={fileType.join(",")}
                  onChange={(e) => onChangeFile(e)}
                />
              </label>
            </div>
            {
              fileName != "" ? 
                <div className="flex items-center mb-2"> 
                  <div className="me-4">{ fileName }</div>
                  <div 
                    className="text-red-500 text-xs cursor-pointer hover:font-semibold"
                    onClick={() => deleteFile()}
                  >
                    Delete
                  </div>
                </div> :
                <></>
            }
            <div className="flex justify-end">
              <Button
                className="bg-[#DD0000] text-white font-semibold"
                disabled={type !== 'edit' && (fileName === "" || isNullOrEmpty(formOnBoard.getFieldValue('name')) || isNullOrEmpty(formOnBoard.getFieldValue('description')))}
                onClick={() => uploadDocument()}
              >Submit</Button>
            </div>
          </div>
        </>
      }
    </Modal>
  );
};

export default ModalAddOnboard;
