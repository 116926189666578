import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import { TalentHelperProvider } from "./TalentContext";

function TalentLayout() {
  return (
    <TalentHelperProvider>
      <main
        className="bg-center bg-no-repeat bg-fixed bg-cover flex flex-col h-screen"
        style={{
          backgroundImage: "url('/assets/images/main_bg.svg')",
          backgroundSize: "cover",
        }}
      >
        {/* <div className="flex flex-1 overflow-hidden"> */}
        <div className="fixed inset-0 bg-white opacity-60" />
        <div className="relative flex flex-1 overflow-hidden">
          <Sidebar />
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className="flex flex-1 overflow-x-auto overflow-y-auto">
              <Outlet />
            </div>
          </div>
        </div>
        {/* </div> */}
      </main>
    </TalentHelperProvider>
  );
}

export default TalentLayout;
