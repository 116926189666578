import { isNullOrEmpty } from "../../../../utils/utils";

export const COLUMNS_MANAGEMENT = [
  {
    title: "",
    dataIndex: "image",
    width: 70,
    key: "image",
    align: "center",
    render: (d) => (
      <div className="w-[50px] h-[50px] text-center bg-[#d9d9d9] border-[3px] rounded-full border-[#fff] shadow-lg items-center flex justify-center mr-2">
        <div className="text-md font-bold text-[#979797]">
          {d[0]?.toUpperCase()}
        </div>
      </div>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
    render: (_, record) => {
      if (isNullOrEmpty(record.name)) {
        return "-";
      }
      return <div className="remark-cell">{record.name}</div>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 300,
    key: "email",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
    width: 100,
    render: (v) => (
      <div className="flex justify-center">
        {v.toLowerCase() === "active" ? (
          <div className="w-[76px] justify-center text-center rounded bg-[#D4FDD1] text-[#4BB543]">
            {v}
          </div>
        ) : (
          <div className="w-[76px] text-center rounded bg-[#FFCFCF] text-[#DD0000]">
            {v}
          </div>
        )}
      </div>
    ),
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    align: "center",
    width: 150,
  },
  {
    title: "Team Leader",
    dataIndex: "userTl",
    key: "userTl",
    align: "center",
    width: 150,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    align: "center",
    width: 100,
  },
];

export const COLUMNS_PENDING = [
  {
    title: "",
    dataIndex: "image",
    width: 70,
    key: "image",
    render: () => (
      <img
        src="/assets/images/user.svg"
        alt="user"
        className="w-[50px] h-[50px] rounded-full"
      />
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (v) => (
      <>
        {v.toLowerCase() === "invited" ? (
          <div className="rounded text-[#4BB543]">{v}</div>
        ) : (
          <div className="rounded text-[#FFAE10]">{v}</div>
        )}
      </>
    ),
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
  },
];

export const roleOptions = [
  {
    value: 1,
    label: "Admin",
  },
  {
    value: 2,
    label: "User",
  },
];

export const defaultTalent = {
  name: "",
  email: "",
  role: 1,
  status: false,
  id: null,
};
