import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getRoleList = async (
  page = 0,
  size = 0,
  clientName = "",
  description = "",
  type = ""
) => {
  let params = {
    page: page + 1,
    size: size,
    name: clientName,
    description: description,
    type: type
  };
  const url = `${API_BASE}/role/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const submitRole = async (body) => {
  const url = `${API_BASE}/role/create`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const updateRole = async (body) => {
  const url = `${API_BASE}/role/update`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const deleteRole = async (id) => {
  const url = `${API_BASE}/role/delete/${id}`;
  const response = await CallApi({ url, method: "PUT" });
  return response;
};

const RoleDataMasterRepo = {
  getRoleList,
  submitRole,
  updateRole,
  deleteRole,
};

export default RoleDataMasterRepo;
