import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "antd";
import { changeState } from "../../../../features/certificateTalentSlice";

const ActionBarCertificate = ({ handleInput }) => {
  const dispatch = useDispatch();

  const { activeTab } = useSelector((state) => state.certificateTalent);

  const changeActiveTab = (value) => {
    dispatch(changeState({ stateProp: "currentPage", valueProp: 0 }));
    dispatch(changeState({ stateProp: "previousPage", valueProp: false }));
    dispatch(changeState({ stateProp: "nextPage", valueProp: false }));
    dispatch(changeState({ stateProp: "activeTab", valueProp: value }));
    dispatch(changeState({ stateProp: "formSearch", valueProp: "" }));
  };

  return (
    <div className="relative md:flex md:flex-col p-0 bg-primary md:py-2 border-b-2 shadow-md">
      <div className="grid grid-cols-12 mx-[61px]">
        <div className="col-span-12 md:col-span-6 mt-5 w-[100%] flex gap-6 font-semibold text-sm ">
          <button
            disabled={activeTab === "certificate"}
            className={`md:flex flex-col hidden px-2 justify-center ${
              activeTab === "certificate" ? "text-white" : "text-white"
            }`}
            onClick={(event) =>
              event.preventDefault(changeActiveTab("certificate"))
            }
          >
            My Certificate
            <hr
              className={`
              ${activeTab === "certificate" ? "tabs-button" : null}`}
            />
          </button>
          <button
            disabled={activeTab === "certificate"}
            className={`md:hidden flex-col flex px-2 justify-center ${
              activeTab === "certificate" ? "text-white " : "text-white"
            }`}
            onClick={(event) =>
              event.preventDefault(changeActiveTab("certificate"))
            }
          >
            Issued
            <hr
              className={`
              ${activeTab === "certificate" ? "tabs-button" : null}`}
            />
          </button>

          <button
            disabled={activeTab === "revoked"}
            className={`flex flex-col px-2 justify-center
                  ${activeTab === "revoked" ? "text-white " : "text-white"}
                  `}
            onClick={(event) =>
              event.preventDefault(changeActiveTab("revoked"))
            }
          >
            Revoked
            <hr
              className={`
              ${activeTab === "revoked" ? "tabs-button" : null}`}
            />
          </button>
        </div>
        <div className="md:col-span-6 hidden md:flex justify-end content-center items-center">
          <Input
            className="w-[300px] h-[35px]"
            placeholder="Search Here"
            suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
            onChange={(event) => handleInput("formSearch", event.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionBarCertificate;
