import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import AuthLayout from "../components/auth/AuthLayout";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ActivateAccount from "../pages/auth/ActivateAccount";
import AttendanceVerification from "../pages/auth/AttendanceVerification";
import AttendanceRecorded from "../pages/auth/AttendanceRecorded";
import RouteName from "../helper/routeName";
import ListMasterEvent from "../pages/admin/master/Events/ListMasterEvent";
import MasterEvent from "../pages/admin/master/Events/MasterEvent";
import DetailEventPage from "../pages/admin/master/Events/DetailEvent";

// admin
import RouteAdm from "../services/routeAdm";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import AdminLayout from "../components/adminPage/AdminLayout";
import Leaderboard from "../pages/admin/leaderboard/Leaderboard";
import Task from "../pages/admin/dataMaster/task/Index";
import AddTask from "../pages/admin/dataMaster/task/AddTask";
import UserManagement from "../pages/admin/user-mgmt/TabIndex";
import CertificateTemplate from "../pages/admin/cerificateTemplate/Index";
import Badges from "../pages/admin/badges";
import CertificateIssued from "../pages/admin/master/CertificateIssued/index";
import RedeemHistory from "../pages/admin/redeemHistory";
import FridayBooster from "../pages/admin/fridayBooster/FridayBooster";
import Rewards from "../pages/admin/rewards";
import Journey from "../pages/admin/journey";
import StatusTalent from "../pages/admin/statusTalent/StatusTalent";
import PlacementHistory from "../pages/admin/placementHistory/PlacementHistory";
import InterviewClient from "../pages/admin/interview-client/InterviewClient";
import InterviewCandidate from "../pages/admin/interview-candidate/InterviewCandidate";
import AdminLmsCourse from "../pages/admin/lms/course";
import MataKuliah from "../pages/admin/lms/mataKuliah";
import SubMataKuliah from "../pages/admin/lms/subMataKuliah";
import HistoriesLms from "../pages/admin/lms/histories";
import DetailHistoryIdp from "../pages/admin/lms/detailHistory";
import DetailSubMataKuliah from "../pages/admin/lms/detailSubMatkul";
import AdminOnboarding from "../pages/admin/onboard/OnBoard";
import OneMonthSupervisedAdm from "../pages/admin/onboard/OneMonthSupervisedAdm";
import DetailOms from "../pages/admin/onboard/DetailOms";
import HistoryClient from "../pages/admin/historyClient/HistoryClient";
import CategoriesQuizMaster from "../pages/admin/lms/quiz/categoriesQuiz";
import QuizData from "../pages/admin/lms/quiz/QuizData";
import QuestionBank from "../pages/admin/lms/quiz/questionBank";
import FormQuestionBank from "../pages/admin/lms/quiz/formQuestion";
import AdminQuizDetail from "../pages/admin/lms/quiz/quizDetail";

// talent Side
import RouteTalent from "../services/RouteTalent";
import DashboardTalent from "../pages/talent/dashboard/Dashboard";
import TalentLeaderboard from "../pages/talent/leaderboard/Leaderboard";
import Reward from "../pages/talent/rewardTalent/Reward";
import CertificateTalent from "../pages/talent/certificate/CertificateTalent";
import CertificateDetails from "../pages/talent/certificate/CertificateDetail";
import TalentLayout from "../components/talentPage/TalentLayout";
import { JourneyRoutes } from "../modules/journey/pages/router";
import CoinHistoryTalent from "../pages/talent/coinHistory/CoinHistory";
import HistoryProjectPage from "../pages/talent/projectHistory/ProjectHistory";
import TalentIssuePage from "../pages/talent/talentIssue/TalentIssue";
import YourRole from "../pages/talent/roles/Roles";
import YourSkill from "../pages/talent/skill/Skill";
import TalentJourney from "../pages/talent/talentJourney/TalentJourney";
import TalentJourneyTermin from "../pages/talent/talentJourney/TalentJourneyTermin";
import TalentJourneyTopic from "../pages/talent/talentJourney/TalentJourneyTopic";
import PlacementTalentHistory from "../pages/talent/placementTalentHistory/PlacementTalentHistory";
import ChecklistSkill from "../pages/talent/checklistSkill/checklistSkill";
import FormInterviewTalent from "../pages/auth/FormInterviewTalent";
import ClientDataMaster from "../pages/admin/clientDataMaster";
import RoleDataMaster from "../pages/admin/roleDataMaster";
import TalentOnboarding from "../pages/talent/onboard/OnBoard";
import OneMonthSupervised from "../pages/talent/onboard/OneMonthSupervised";
import OneMonthSupervisedTL from "../pages/talent/onboard/OneMonthSupervisedTL";
import DetailOneMonthSupervisedTL from "../pages/talent/onboard/DetailOneMonthSupervisedTL";
import { Result } from "antd";

const Base = () => {
  const [hasAccess, setHasAccess] = useState(null);

  // Check access in useEffect
  useEffect(() => {
    const checkAccess = () => {
      const userTl = localStorage.getItem("user_tl");
      setHasAccess(userTl === "true");
    };

    checkAccess();

    window.addEventListener("storage", checkAccess);

    return () => {
      window.removeEventListener("storage", checkAccess);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path={RouteName.login} element={<Login />} />
            <Route
              path={RouteName.activateAccount}
              element={<ActivateAccount />}
            />
            <Route
              path={RouteName.forgotPassword}
              element={<ForgotPassword />}
            />
            <Route
              path={RouteName.setNewPassword}
              element={<ActivateAccount />}
            />
            <Route
              path={RouteName.attendanceVerification}
              element={<AttendanceVerification />}
            />
            <Route
              path={RouteName.attendanceRecorded}
              element={<AttendanceRecorded />}
            />
            <Route
              path={RouteName.formInterviewTalent}
              element={<FormInterviewTalent />}
            />
          </Route>

          <Route element={<AdminLayout />}>
            <Route path={RouteAdm.dashboard} element={<Dashboard />} />
            <Route path={RouteAdm.masterEvent} element={<ListMasterEvent />} />
            <Route path={RouteAdm.event} element={<MasterEvent />} />
            <Route path={RouteAdm.eventDetail} element={<DetailEventPage />} />
            <Route path={RouteAdm.leaderboard} element={<Leaderboard />} />
            <Route
              path={RouteAdm.userManagement}
              element={<UserManagement />}
            />
            <Route path={RouteAdm.task} element={<Task />} />
            <Route path={RouteAdm.addTask} element={<AddTask />} />
            <Route path={RouteAdm.leaderboard} element={<Leaderboard />} />
            <Route
              path={RouteAdm.certificateTemplate}
              element={<CertificateTemplate />}
            />
            <Route path={RouteAdm.badges} element={<Badges />} />
            <Route
              path={RouteAdm.certificateIssued}
              element={<CertificateIssued />}
            />
            <Route path={RouteAdm.redeemHistory} element={<RedeemHistory />} />
            <Route
              path={RouteAdm.reportFridayBooster}
              element={<FridayBooster />}
            />
            <Route path={RouteAdm.rewards} element={<Rewards />} />
            <Route path={RouteAdm.journey} element={<Journey />} />
            <Route path={RouteAdm.statusTalent} element={<StatusTalent />} />
            <Route
              path={RouteAdm.clientDataMaster}
              element={<ClientDataMaster />}
            />{" "}
            <Route
              path={RouteAdm.roleDataMaster}
              element={<RoleDataMaster />}
            />{" "}
            <Route
              path={RouteAdm.placementTalentHistory}
              element={<PlacementHistory />}
            />
            <Route
              path={RouteAdm.checklistInterviewClient}
              element={<InterviewClient />}
            />
            <Route
              path={RouteAdm.checklistInterviewCandidate}
              element={<InterviewCandidate />}
            />
            <Route
              path={RouteAdm.admClientHistory}
              element={<HistoryClient />}
            />
            <Route path={RouteAdm.admLmsCourse} element={<AdminLmsCourse />} />
            <Route path={RouteAdm.admLmsMataKuliah} element={<MataKuliah />} />
            <Route
              path={RouteAdm.admLmsSubMataKuliah}
              element={<SubMataKuliah />}
            />
            <Route
              path={RouteAdm.admLmsDetailSubMataKuliah}
              element={<DetailSubMataKuliah />}
            />
            <Route path={RouteAdm.admLmsHistories} element={<HistoriesLms />} />
            <Route
              path={RouteAdm.admLmsDetailHistories}
              element={<DetailHistoryIdp />}
            />
            <Route
              path={RouteAdm.admOnboarding}
              element={<AdminOnboarding />}
            />
            <Route path={RouteAdm.admOMS} element={<OneMonthSupervisedAdm />} />
            <Route path={RouteAdm.admDetailOMS} element={<DetailOms />} />
            {/* Quiz */}
            <Route path={RouteAdm.admQuizCategories} element={<CategoriesQuizMaster />} />
            <Route path={RouteAdm.admQuizData} element={<QuizData />} />
            <Route path={RouteAdm.admQuestionBank} element={<QuestionBank />} />
            <Route path={RouteAdm.admFormQuestionBank} element={<FormQuestionBank />} />
            <Route path={RouteAdm.admQuizDetail} element={<AdminQuizDetail />} />
          
          </Route>

          <Route element={<TalentLayout />}>
            <Route path={RouteTalent.dashboard} element={<DashboardTalent />} />
            <Route
              path={RouteTalent.coinHistory}
              element={<CoinHistoryTalent />}
            />
            <Route
              path={RouteTalent.leaderboard}
              element={<TalentLeaderboard />}
            />
            <Route path={RouteTalent.reward} element={<Reward />} />
            <Route
              path={RouteTalent.certificate}
              element={<CertificateTalent />}
            />
            <Route
              path={RouteTalent.certificateDetail}
              element={<CertificateDetails />}
            />
            <Route path={JourneyRoutes.BasePath}>
              {JourneyRoutes.Pages.map((page, key) => (
                <Route key={key} path={page.url} element={page.component} />
              ))}
            </Route>
            <Route
              path={RouteTalent.talentIssue}
              element={<TalentIssuePage />}
            />
            <Route
              path={RouteTalent.historyProject}
              element={<HistoryProjectPage />}
            />
            <Route
              path={RouteTalent.placementHistory}
              element={<PlacementTalentHistory />}
            />
            <Route path={RouteTalent.yourRole} element={<YourRole />} />
            <Route path={RouteTalent.yourSkill} element={<YourSkill />} />
            <Route
              path={RouteTalent.checklistSkill}
              element={<ChecklistSkill />}
            />
            <Route
              path={RouteTalent.talentJourney}
              element={<TalentJourney />}
            />
            <Route
              path={RouteTalent.talentJourneyTermin}
              element={<TalentJourneyTermin />}
            />
            <Route
              path={RouteTalent.talentJourneyTopic}
              element={<TalentJourneyTopic />}
            />

            <Route
              path={RouteTalent.talentOnboarding}
              element={<TalentOnboarding />}
            />

            <Route
              path={RouteTalent.talentOneMonthSupervised}
              element={<OneMonthSupervised />}
            />

            {hasAccess !== null && (
              <>
                <Route
                  path={RouteTalent.talentOneMonthSupervisedTL}
                  element={
                    hasAccess ? (
                      <OneMonthSupervisedTL />
                    ) : (
                      <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, page not found"
                        className="w-full justify-center items-center"
                      />
                    )
                  }
                />
                <Route
                  path={RouteTalent.talentOneMonthSupervisedTLDetail}
                  element={<DetailOneMonthSupervisedTL />}
                />
              </>
            )}
          </Route>

          <Route path={JourneyRoutes.BasePath}>
            <Route
              path={JourneyRoutes.QuizPage.url}
              element={JourneyRoutes.QuizPage.component}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Base;
