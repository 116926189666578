import { Spin } from "antd";
import { Fragment, useContext, useState } from "react";

import TalentHeader from "../../../components/general/TalentHeader";
import { JourneyInformation } from "../components/JourneyInformation";
import { JourneyLinePath } from "../components/JourneyLinePath";
import { JourneyNavigator } from "../components/JourneyNavigator";
import { useJourneyEvents } from "../hooks/useJourneyEvents";
import JourneyRepo from "../services/JourneyRepo";
import { useEffect } from "react";
import { TalentHelperContext } from "../../../components/talentPage/TalentContext";
import { useLocation } from "react-router-dom";
import JourneyAvatar from "../components/JourneyAvatar";

export const JourneyPage = () => {
  const location = useLocation();
  const [journeyList, setJourneyList] = useState([]);
  const [currentActive, setCurrentActive] = useState(
    location.state?.journeyId || ""
  );
  const { selectMenu } = useContext(TalentHelperContext);

  const {
    query: { isError, isFetching, data },
  } = useJourneyEvents(currentActive);

  const changePathTo = (id) => {
    setCurrentActive(id);
  };

  const getCurrentSlideIndex = (idx) => {
    setCurrentActive(journeyList[idx].id);
  };

  const getListJourney = async () => {
    await JourneyRepo.getListJourney(1, 1000)
      .then((res) => {
        let resp = res?.data?.data;
        if (res.status === 200) {
          setJourneyList(() => resp.content);
          setCurrentActive(location.state?.journeyId ?? resp?.content[0].id);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    getListJourney();
    selectMenu("journey");
  }, []);

  return (
    <div className="flex flex-col w-full h-screen overflow-hidden bg-cover bg-[url('../public/assets/images/Background-journey.png')]">
      <div className="flex-1 bg-[#B4B4B4] bg-opacity-75">
        <TalentHeader
          title={"My Journey"}
          subDescription={"Let’s Finish your journey!"}
          imageSrc={"/assets/images/start-end-journey.png"}
        />
        <JourneyInformation
          journeyData={journeyList}
          changePathTo={changePathTo}
          getCurrentSlideIndex={getCurrentSlideIndex}
        />
        <JourneyContents
          changePathTo={changePathTo}
          data={data}
          isFetching={isFetching}
          isDeveloper={true}
          isEnabler={false}
          isError={isError}
        />
      </div>
    </div>
  );
};

const JourneyContents = ({ data, isFetching, isError }) => {
  return (
    <div className="relative flex-1 p-0 overflow-y-auto">
      {isError ? (
        <div className="w-full h-full flex items-center justify-center">
          An error occured
        </div>
      ) : (
        <Fragment>
          <div className="relative z-[0] w-full h-full flex flex-col items-center">
            {isFetching ? (
              <div className="w-full flex justify-center">
                <div className="relative z-[1] w-full h-[200px] flex gap-20 px-[50px] sm:flex-col ">
                  <Spin size="large" />
                </div>
              </div>
            ) : data && data?.length > 0 ? (
              <>
                <JourneyLinePath />
                <JourneyNavigator events={data} />
              </>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                No Data
              </div>
            )}
          </div>
          <JourneyAvatar />
        </Fragment>
      )}
    </div>
  );
};
