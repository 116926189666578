import { useInfiniteQuery } from "react-query";
import { JourneyService } from "../services/journey.service";

const journeyService = new JourneyService();

export const useJourneyEvents = (path) => {
  const query = useInfiniteQuery(
    // hardocode, perlu buatkan pegination PR bersama
    ["get-events", { size: 100000, type: path }],
    path? journeyService.get.journeyEvents : '',
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage.data) return null;
        if (lastPage.data.last) return null;
        return lastPage.data.number + 1;
      },
      select: (data) => {
        return data.pages
          .map((page) => page.data.content.map((record) => record))
          .flat(1);
      },
    }
  );
  return {
    query,
  };
};
