import React from "react";

const Title = () => {
  return (
    <div className="mb-2">
      <div className="text-2xl font-bold">Task</div>
    </div>
  );
};

export default Title;
