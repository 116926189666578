import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RouteTalent from "../../../../services/RouteTalent";

const CardCertificate = ({ children }) => {
  return (
    <div>
      <div className="w-[150px] md:w-[350px] md:h-[220px] max-w-sm bg-slate-50 border rounded-2xl shadow-md mx-2 my-2 flex flex-col justify-between">
        {children}
      </div>
    </div>
  );
};

const Header = ({ data }) => {
  const navigate = useNavigate();

  const handleOpenDetails = () => {
    navigate(RouteTalent.certificateDetail, {
      state: data,
    });
  };
  return (
    <div onClick={handleOpenDetails}>
      <img
        className="p-0 rounded-t-2xl w-full max-h-[100px] md:max-h-[170px] object-cover cursor-pointer"
        alt="certificate"
        src="https://img.freepik.com/free-vector/gradient-golden-luxury-certificate-template_23-2149031875.jpg?w=996&t=st=1694528764~exp=1694529364~hmac=13c9596852895dc9f6b52438fb9a0e711b2cc6e833f5b1bbe1ca6eff07a8bb61"
      />
    </div>
  );
};

const Body = ({ title, expiredDate, revokedDate }) => {
  const { activeTab } = useSelector((state) => state.certificateTalent);

  return (
    <div className="flex items-center px-6 py-2 md:w-[380px]">
      <p className="w-4/6 text-xxs md:text-xs font-semibold tracking-tight text-black truncate">
        {title}
      </p>

      <div className="px-0 md:px-6 grid justify-items-end md:w-2/6 ">
        <p className="text-gray-400 text-xxs md:text-sx">
          {activeTab === "certificate" ? "Valid Until" : "Revoke On"}
        </p>
        <p
          className={`text-xxs md:text-xs font-semibold ${
            activeTab === "certificate" ? "text-gray-400" : "text-red-400"
          }`}
        >
          {activeTab === "certificate" ? expiredDate : revokedDate}
        </p>
      </div>
    </div>
  );
};

CardCertificate.Header = Header;
CardCertificate.Body = Body;

export default CardCertificate;
