import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const BASE_PATH = `report/event`;

const getListReportFridayBooster = async (
  page = 0,
  size = 10,
  startDate,
  endDate
) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (startDate) {
    params["startDate"] = startDate;
  }
  if (endDate) {
    params["endDate"] = endDate;
  }

  const url = `${API_BASE}/${BASE_PATH}/list-talent-follow?${createSearchParams(
    params
  )}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getDownloadFile = async (startDate, endDate) => {
  let params = {
    startDate: startDate,
    endDate: endDate,
  };

  if (startDate) {
    params["startDate"] = startDate;
  }
  if (endDate) {
    params["endDate"] = endDate;
  }

  const url = `${API_BASE}/${BASE_PATH}/talent-follow?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const FridayBoosterRepo = {
  getDownloadFile,
  getListReportFridayBooster,
};

export default FridayBoosterRepo;
