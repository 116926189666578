import { configureStore } from '@reduxjs/toolkit';
import certificateTemplateReducer from '../features/cerificateTemplateSlice';
import certificateIssuedReducer from '../features/certificateIssuedSlice';
import certificateTalentReducer from '../features/certificateTalentSlice';

export const store = configureStore({
    reducer: {
        certificateTemplate: certificateTemplateReducer,
        certificateIssued: certificateIssuedReducer,
        certificateTalent: certificateTalentReducer,
    }
});