const SkeletonTable = () => {
    return (<>
        <div className="mt-4 w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
            <div className="flex items-center justify-between">
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full mb-2.5 mt-2.5"></div>
            </div>
            <div className="flex items-center justify-between">
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
            </div>
            <div className="flex items-center justify-between">
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
            </div>
            <div className="flex items-center justify-between">
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
            </div>
            <div className="flex items-center justify-between">
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[33.33%] m-2 mb-2.5 mt-2.5"></div>
            </div>
        </div >
        
    </>)
}

export default SkeletonTable