import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";

const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const BASE_PATH = `placement-test-talent`;

const getPlacementTestTalent = async (
  page = 0,
  size = 10,
  clientName = undefined
) => {
  let params = {
    page: page + 1,
    size: size,
  };

  if (typeof clientName !== "undefined" && clientName.length) {
    params["clientName"] = clientName;
  }

  const url = `${API_BASE}/${BASE_PATH}/list-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getDetailPlacementTestTalent = async (id) => {
  const url = `${API_BASE}/${BASE_PATH}/${id}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const updatePlacementTestHistory = async (body) => {
  const url = `${API_BASE}/${BASE_PATH}/user-update`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const createClientHistory = async (body) => {
  const url = `${API_BASE}/history-client-talent`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
}

const PlacementTalentHistoryRepo = {
  getPlacementTestTalent,
  getDetailPlacementTestTalent,
  updatePlacementTestHistory,
  createClientHistory
};

export default PlacementTalentHistoryRepo;
