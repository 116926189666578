import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  message,
  Button,
  Table,
  Pagination,
  Input,
  Modal,
  Select,
  Space,
} from "antd";
import Onboarding from "../../../repositores/adminRepo/OnboardRepo";
import { isNullOrEmpty } from "../../../utils/utils";
import dayjs from "dayjs";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import FormOneMonthSupervisedAdm from "./components/FormOneMonthSupervisedAdm";

const OneMonthSupervisedAdm = () => {
  const navigate = useNavigate();

  const selectInput = useRef(null);
  const selectInputTL = useRef(null);

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [searchName, setSearchName] = useState(undefined);
  const [searchClient, setSearchClient] = useState(undefined);

  const [openModalConfirmSendEmail, setOpenModalConfirmSendEmail] =
    useState(false);

  const [dataTable, setDataTable] = useState([]);
  const [bounchPage, setBounchPage] = useState(undefined);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [counterChildRefresh, setCounterChildRefresh] = useState(1);
  const [detailOms, setDetailOms] = useState({});

  const [formTypeOms, setFormTypeOms] = useState("add");

  const formOMSRef = useRef();

  const [status, setStatus] = useState(undefined);
  const [statusTl, setStatusTl] = useState(undefined);

  const getColumnSelectProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Select
          ref={selectInput}
          placeholder="Search to Select"
          value={status}
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
            setPage(0);
            setStatus(e);
          }}
          defaultValue={isNullOrEmpty(status) ? undefined : status}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          options={[
            {
              value: "NEW",
              label: "NEW",
            },
            {
              value: "DONE",
              label: "DONE",
            },
            {
              value: "INPROGRESS",
              label: "INPROGRESS",
            },
          ]}
        ></Select>
        <Space>
          <Button
            onClick={() => handleReset(dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => {
      return (
        <FilterFilled
          style={{
            color: !isNullOrEmpty(status) ? "#1677ff" : undefined,
          }}
        />
      );
    },
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) => {
      return text;
    },
  });

  const getColumnSelectPropsTl = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Select
          ref={selectInputTL}
          placeholder="Search to Select"
          value={statusTl}
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
            setStatusTl(e);
            setPage(0);
          }}
          defaultValue={isNullOrEmpty(statusTl) ? undefined : statusTl}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          options={[
            {
              value: "NEW",
              label: "NEW",
            },
            {
              value: "DONE",
              label: "DONE",
            },
            {
              value: "NEED_ASSESTMENT",
              label: "NEED ASSESTMENT",
            },
          ]}
        ></Select>
        <Space>
          <Button
            onClick={() => handleResetTl(dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => {
      return (
        <FilterFilled
          style={{
            color: !isNullOrEmpty(statusTl) ? "#1677ff" : undefined,
          }}
        />
      );
    },
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) => {
      return text;
    },
  });

  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "id",
      align: "center",
      width: 50,
    },
    {
      title: "Talent Name",
      dataIndex: ["historyClientTalent", "userRole", "user", "fullname"],
      key: "id",
      width: 170,
    },
    {
      title: "Client",
      dataIndex: ["historyClientTalent", "clientCompany", "name"],
      key: "id",
      width: 150,
    },
    {
      title: "Role Name",
      dataIndex: ["historyClientTalent", "userRole", "roleCompany", "name"],
      align: "center",
      key: "id",
      width: 180,
    },
    {
      title: "Start Date",
      dataIndex: ["historyClientTalent", "startDate"],
      align: "center",
      key: "id",
      width: 100,
      render: (_, record) => {
        if (isNullOrEmpty(record?.historyClientTalent?.startDate)) {
          return "-";
        }
        return dayjs(record?.historyClientTalent?.startDate).format(
          "DD MMM YYYY"
        );
      },
    },
    {
      title: "End Date",
      dataIndex: ["historyClientTalent", "endDate"],
      align: "center",
      key: "id",
      width: 100,
      render: (_, record) => {
        if (isNullOrEmpty(record?.historyClientTalent?.endDate)) {
          return "-";
        }
        return dayjs(record?.historyClientTalent?.endDate).format(
          "DD MMM YYYY"
        );
      },
    },
    {
      title: "PIC",
      dataIndex: "pic",
      key: "id",
      width: 200,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "id",
      align: "center",
      width: 150,
      ...getColumnSelectProps("status"),
    },
    {
      title: "STATUS By TL",
      dataIndex: "statusByTL",
      key: "id",
      align: "center",
      width: 170,
      ...getColumnSelectPropsTl("statusByTL"),
    },
    {
      title: "Remark By Talent",
      dataIndex: "remarkByTalent",
      key: "id",
      width: 200,
      render: (_, record) => {
        if (isNullOrEmpty(record.remarkByTalent)) {
          return "-";
        }
        return <div className="remark-cell">{record.remarkByTalent}</div>;
      },
    },
    {
      title: "Remark By TL",
      dataIndex: "remarkByTL",
      key: "id",
      width: 200,
      render: (_, record) => {
        if (isNullOrEmpty(record.remarkByTL)) {
          return "-";
        }
        return <div className="remark-cell">{record.remarkByTL}</div>;
      },
    },
    {
      title: "Message",
      dataIndex: ["message"],
      key: "id",
      width: 300,
      render: (_, record) => {
        if (isNullOrEmpty(record.message)) {
          return "-";
        }
        return (
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {record.message}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 300,
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            <div
              onClick={() => {
                showEditOms(record);
              }}
              className="mr-[10px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">Edit</span>
            </div>
            <div
              onClick={() => {
                goToDetail(record);
              }}
              style={{ backgroundColor: "#DD0000" }}
              className="mr-[10px] border-2 decoration-red-600 border-red-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-white">Detail</span>
            </div>
            <div
              onClick={() => {
                setDetailOms(record);
                setOpenModalConfirmSendEmail(true);
              }}
              className="mr-[10px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">Send Email</span>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshCounter, searchName, status, searchClient, statusTl]);

  async function fetchData() {
    setLimit(10);
    try {
      let tempData = [];
      let response = await Onboarding.getListOneMonthSupervised(
        page,
        limit,
        searchName,
        searchClient,
        status,
        statusTl
      );
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content, index) => {
          content.number = (page > 0 ? limit * (page - 1) : 0) + (index + 1);
          tempData.push(content);
        });
        setDataTable(tempData);
        setTotalItem(response?.data?.data?.totalElements);
      } else {
        setDataTable([]);
        setTotalItem(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Failed to get Data Onboarding",
      });
    }
  }

  const onChangePage = (el) => {
    setLoading(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setPage(el);
      }, 1000)
    );
  };

  const onChangeSearch = (el) => {
    setLoading(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setSearchName(el.target.value);
        setPage(0);
      }, 2000)
    );
  };
  const onChangeSearchClient = (el) => {
    setLoading(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setSearchClient(el.target.value);
        setPage(0);
      }, 2000)
    );
  };

  const showEditOms = (row) => {
    setFormTypeOms("edit");
    setDetailOms(row);
    setIsOpenModal(true);
  };

  const goToDetail = (row) => {
    localStorage.setItem("_oms", JSON.stringify(row));
    navigate(`/admin/one-month-supervised/${row.id}`);
  };

  const closeModal = () => {
    setFormTypeOms("add");
    setDetailOms({});
    setIsOpenModal(false);
    setCounterChildRefresh((prev) => prev + 1);
  };

  const updateOrAddOMS = () => {
    formOMSRef.current
      .validateFields()
      .then(async () => {
        let payload = {
          id: formTypeOms === "edit" ? detailOms.id : undefined,
          historyClientTalent: {
            id: formOMSRef.current.getSelectedTalent()?.id,
          },
          status: formOMSRef.current.getFieldValue("status"),
          pic: formOMSRef.current.getFieldValue("pic"),
          tl: { id: formOMSRef.current.getFieldValue("tl") },
          remarkByTL: formOMSRef.current.getFieldValue("remarkByTl"),
          remarkByTalent: formOMSRef.current.getFieldValue("remarkByTalent"),
        };

        setLoading(true);
        formOMSRef.current.resetForm();
        let counterC = counterChildRefresh;
        setIsOpenModal(false);
        setCounterChildRefresh(counterC + 1);

        try {
          let response = await Onboarding.addOrUpdateOms(payload);
          if (response.data.status === 200) {
            const counter = refreshCounter + 1;
            messageApi.open({
              type: "success",
              content: "Successfully Update data",
            });
            setRefreshCounter(counter);
          } else {
            throw response.data;
          }
        } catch (error) {
          console.error("Error:", error);
          const errorMessage =
            error?.message ||
            error?.data?.message ||
            error?.error ||
            "Sorry we're having trouble adding data right now, please try again later";
          messageApi.open({
            type: "error",
            content: errorMessage,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        // Log validation error to debug
        console.error("Validation Error:", error);
      });
  };

  const addOMS = () => {
    setFormTypeOms("add");
    setIsOpenModal(true);
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      let response = await Onboarding.sendEmail(detailOms.id);
      if (response.data.status === 200) {
        messageApi.open({
          type: "success",
          content: "Successfully Send Email",
        });
      } else {
        throw response.data;
      }
      setLoading(false);
      setOpenModalConfirmSendEmail(false);
      setDetailOms({});
    } catch (error) {
      if (error?.message || error?.data?.message || error?.error) {
        messageApi.open({
          type: "error",
          content: error?.message || error?.data?.message || error?.error,
        });
      } else {
        messageApi.open({
          type: "error",
          content:
            "sorry we're having trouble adding data right now, please try again later",
        });
      }
      setLoading(false);
      setOpenModalConfirmSendEmail(false);
    }
  };

  const handleReset = () => {
    setStatus(undefined);
  };

  const handleResetTl = () => {
    setStatusTl(undefined);
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <div className="text-center md:text-left mb-4">
          <h4 className="text-2xl md:text-4xl font-bold">
            One Month Supervised
          </h4>
        </div>
        <div className="flex gap-x-6 justify-end">
          <div className="flex flex-col gap-y-2 gap-x-6 md:flex-row">
            <Input
              className="w-full"
              placeholder="Search Talent Name"
              onChange={onChangeSearch}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
            <Input
              className="w-full"
              placeholder="Search Client Name"
              onChange={onChangeSearchClient}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
          </div>
          <Button
            onClick={() => addOMS()}
            type="primary"
            disabled={loading}
            className="mr-1 bg-[#DD0000] w-[100px]"
            style={{ backgroundColor: "#DD0000" }}
          >
            Add
          </Button>
        </div>
        <div className="mt-6">
          <Table
            dataSource={dataTable}
            columns={columns}
            pagination={false}
            rowKey="id"
            sticky
            scroll={{
              x: 3100,
              y: 520,
            }}
          />
          <div className="w-full flex justify-center my-2 pt-2 pb-10">
            <Pagination
              current={page}
              onChange={onChangePage}
              pageSize={limit}
              total={totalItem}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>

      <Modal
        onCancel={() => closeModal()}
        title={
          formTypeOms === "add"
            ? "Add Data One Month Supervised"
            : "Update Data One Month Supervised"
        }
        open={isOpenModal}
        footer={
          <>
            <div className="flex justify-between mt-[20px]">
              <Button key="back" danger onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#DD0000", color: "white" }}
                onClick={() => updateOrAddOMS()}
              >
                Submit
              </Button>
            </div>
          </>
        }
      >
        <div className="container p-[10px] my-[30px]">
          <FormOneMonthSupervisedAdm
            key={counterChildRefresh}
            ref={formOMSRef}
            formType={formTypeOms}
            dataEdit={detailOms}
          />
        </div>
      </Modal>

      <Modal
        open={openModalConfirmSendEmail}
        onOk={(e) => {
          sendEmail();
        }}
        onCancel={() => {
          setOpenModalConfirmSendEmail(false);
        }}
        footer={null}
        closable={false}
        keyboard={false}
      >
        <div className="flex items-center gap-2">
          <p className="text-black text-[16px] font-medium">
            Are you sure want to send email to talent ?
          </p>
        </div>
        <div className="flex justify-end mt-5 gap-[10px]">
          <Button
            onClick={() => {
              setOpenModalConfirmSendEmail(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              sendEmail();
            }}
            type="primary"
            danger
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default OneMonthSupervisedAdm;
