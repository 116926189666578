import axios from "axios";
import apiClient from "./../modules/shared/api/api.client"

export default async function CallApi({ url, method, data, type }) {
  // get token:
  let headers = {};

  let token = localStorage.getItem('token') || null;
  
  let responseType = 'json';

  if (type && type !== 'qr') {
    // if the data upload with form data
    headers = {
      Accept: "*/*",
      authorization: token ? `bearer ${ token }` : '',
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data"
    };
  } else if (type === "qr") {
    // if the data upload with form data
    headers = {
      Accept: "image/png",
      authorization: token ? `bearer ${ token }` : '',
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data"
    };
    responseType = 'blob'
  } else {
    headers = {
      Accept: "application/json",
      authorization: token ? `bearer ${ token }` : '',
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };
  }
  
  const response = await apiClient.instance({ url, method, data, headers, responseType }).catch(
    (err) => err.response
  );

  // update response handling

  // end of response handling

  return response;
}
