export const createSearchParams = (params) => {
  const searchParams = new URLSearchParams();
  for (const key in params) {
    if (params[key] !== null && params[key] !== undefined) {
      if (key === "page") params[key] = Number(params[key]) - 1;
      searchParams.append(key, params[key]);
    }
  }
  return searchParams.toString();
};

export const capitalizeEachWord = (str) => {
	if (!str) {
		return "-"
	}

  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const result = arr.join(" ");
  return result
};

export function isNullOrEmpty(value) {
    return value === "" || value === null || value === undefined || typeof value === "string" && value.trim() === ""
}
