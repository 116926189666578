import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import RouteTalent from "../../../services/RouteTalent";
import LayoutCertificate from "./CertificateLayout";
const host = process.env.REACT_APP_BASE;

const CertificateDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const actionBack = () => {
    navigate(RouteTalent.certificate);
  };

  const { activeTab } = useSelector((state) => state.certificateTalent);

  const onClickView = (id) => {
    window.open(`${host}api/v1/certificate/generate/${id}`, "_blank");
  };

  return (
    <LayoutCertificate>
      <div className="flex flex-col justify-start w-full h-screen items-center gap-5 px-4 pt-5 md:gap-22 md:pt-20 md:px-16 overflow-y-auto">
        <BackArrow actionBack={actionBack} />
        <div className="flex flex-col md:grid md:grid-cols-2 w-full gap-5 overflow-hidden">
          <CertificateView
            certifFile={
              "https://img.freepik.com/free-vector/gradient-golden-luxury-certificate-template_23-2149031875.jpg?w=996&t=st=1694528764~exp=1694529364~hmac=13c9596852895dc9f6b52438fb9a0e711b2cc6e833f5b1bbe1ca6eff07a8bb61"
            }
          />
          <CertificateBody
            location={location}
            activeTab={activeTab}
            onClickView={onClickView}
          />
        </div>
      </div>
    </LayoutCertificate>
  );
};

const BackArrow = ({ actionBack }) => {
  return (
    <div className="flex flex-row w-full mb-4">
      <button
        className="border-2 border-black rounded-full grid justify-items-center items-center h-[30px] w-[30px]"
        onClick={actionBack}
      >
        <ArrowLeftOutlined twoToneColor="#fc0303" style={{ fontSize: 16 }} />
      </button>
      <p className="px-2 font-bold">Back</p>
    </div>
  );
};

const CertificateView = ({ certifFile }) => {
  return (
    <div className="col-span-1 w-full flex justify-center md:justify-start">
      <img
        className="rounded-3xl object-cover shadow-md w-[90%] md:w-full max-w-[500px]"
        alt="certificate"
        src={certifFile}
      />
    </div>
  );
};

const CertificateBody = ({ activeTab, location, onClickView }) => {
  return (
    <div className="flex flex-col w-full md:w-auto md:max-w-[550px]">
      <h1 className="text-blue-950 font-bold text-xl md:text-4xl">
        {location.state?.title}
      </h1>
      <div className="py-2">
        <p className="text-[#A8A8A8] text-sm md:text-base">Certificate Type</p>
        <p className="text-[#4C4C4C] text-sm font-bold">
          {location.state?.type}
        </p>
      </div>

      <div className="text-sm tracking-wide">
        <div className="pb-3">
          <p className="text-[#A8A8A8] text-sm md:text-base">Event</p>
          <p className="text-[#4C4C4C] text-sm font-bold">
            {location.state?.eventName}
          </p>
        </div>
        {activeTab === "certificate" && (
          <div className="pb-3">
            <p className="text-[#A8A8A8] text-sm md:text-base">Event Type</p>
            <p className="text-[#4C4C4C] text-sm font-bold">Sharing Session</p>
          </div>
        )}
        <div className="block md:hidden pb-3">
          <p className="text-[#A8A8A8] text-sm md:text-base">Date Issued</p>
          <p className="text-[#4C4C4C] text-sm font-bold">
            {location.state?.issuedDate}
          </p>
        </div>
        <div className="flex justify-between md:flex-row flex-col w-full md:w-3/4">
          {activeTab === "revoked" && (
            <div className="pb-3">
              <p className="text-[#A8A8A8] text-sm md:text-base">
                Date Revoked
              </p>
              <p className="text-[#DD0000] text-sm font-bold">
                {location.state?.revokeDate}
              </p>
            </div>
          )}
          <div className="hidden md:block pb-3">
            <p className="text-[#A8A8A8] text-sm md:text-base">Date Issued</p>
            <p className="text-[#4C4C4C] text-sm font-bold">
              {location.state?.issuedDate}
            </p>
          </div>
          {activeTab === "certificate" && (
            <div className="pb-3">
              <p className="text-[#A8A8A8] text-sm md:text-base">Valid Until</p>
              <p className="text-[#4C4C4C] text-sm font-bold">
                {location.state?.expiredDate}
              </p>
            </div>
          )}
        </div>
        {activeTab === "revoked" && (
          <div className="pb-3">
            <p className="text-[#A8A8A8] text-sm md:text-base">Reason</p>
            <p className="text-[#DD0000] text-sm font-bold">
              {location.state?.reason}
            </p>
          </div>
        )}
        <div className="pb-3">
          <p className="text-[#A8A8A8] text-sm md:text-base">
            Valid Certificate ID
          </p>
          <p className="text-[#4C4C4C] text-sm font-bold">
            {location.state?.id}
          </p>
        </div>
        {activeTab === "certificate" && (
          <div className="pb-3 flex">
            <div
              onClick={() => {
                onClickView(location.state?.id);
              }}
              className="flex justify-center cursor-pointer items-center gap-1 border border-[#A8A8A8] px-2 py-1 rounded-xl"
            >
              <img
                src="/assets/images/pdf-icon.svg"
                alt="pdf-icon"
                height={20}
                width={20}
              />
              <p className="text-black text-xs">View PDF</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CertificateDetails;
