import { useCallback, useRef } from "react";

export const useJourneyNavigator = () => {
  const navigator = useRef(null);

  const slideNavigatorTo = useCallback((x) => {
    if (navigator.current) {
      navigator.current.scrollTo({
        top: 0,
        left: x,
        behavior: "smooth",
      });
    }
  }, []);

  return {
    navigator,
    slideNavigatorTo,
  };
};
