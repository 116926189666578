import React, { useContext, useEffect, useState } from "react";
import ProjectHistoryRepo from "../../../repositores/talentRepo/ClientHistoryTalentRepo";
import TalentHeader from "../../../components/general/TalentHeader";
import {
  clientHistoryColumns,
  clientHistoryDetailColumns,
} from "./column.state";
import { Form, Pagination, Skeleton, Table, message } from "antd";
import { TalentHelperContext } from "../../../components/talentPage/TalentContext";
import { ViewHistory } from "./ViewHistory";
import AlertUtility from "../../../components/Alert/Alert";
import dayjs from "dayjs";
import FormClientHistory from "./FormClientHistory";
import PlacementTalentHistoryRepo from "../../../repositores/talentRepo/PlacementTalentHistoryRepo";

const SkeletonTable = ({ rows = 5, columns = 9 }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <th
                key={colIndex}
                className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <Skeleton.Input active size="small" className="w-24" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
                  <Skeleton.Input active size="default" className="w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ProjectHistory = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [projectPage, setProjectPage] = useState(0);
  const [search, setSearch] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  const [clientData, setClientData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const { selectMenu } = useContext(TalentHelperContext);
  const [form] = Form.useForm();
  const [editingForm, setEditingForm] = useState({
    name: "",
    description: "",
    status: "NEW",
    startDate: new Date(),
    endDate: new Date(),
    historyClientTalent: {
      id: "",
    },
    clientCompany: {
      id: "",
    },
  });
  const [projectTitle, setProjectTitle] = useState({
    clientName: "",
    roleName: "",
  });
  const [clientHistoryId, setClientHistoryId] = useState("");
  const [editingId, setEditingId] = useState("");
  const [projectTotalData, setProjectTotalData] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [showFormClientHistory, setShowFormClientHistory] = useState(false);
  const [counterKeyClientHistory, setCounterKeyClientHistory] = useState(0);

  useEffect(() => {
    if (!showModal) {
      handleResetForm();
      setIsEditing(false);
      setShowForm(false);
    }
  }, [showModal]);

  useEffect(() => {
    // if (projectData.length > 0) handlePopulateForm(projectData, editingId);
  }, [editingId]);

  useEffect(() => {
    if (!isEditing) {
      handleResetForm();
      return;
    }
    const data = projectData.find((item) => item.id === editingId);
    setEditingForm(() => ({
      id: data.id,
      name: data.name,
      description: data.description,
      status: data.status,
      startDate: dayjs(data.startDate),
      endDate: dayjs(data.endDate),
    }));
    setShowForm(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (!showForm) setIsEditing(false);
  }, [showForm]);

  const handleInputChange = (name, value) => {
    // Assuming you have an input field with a name attribute
    handleFormUpdate({ [name]: value });
  };

  const handleFormUpdate = (updatedValues) => {
    setEditingForm((prevForm) => ({
      ...prevForm,
      ...updatedValues,
    }));
  };

  const handleResetForm = () => {
    setEditingForm(() => ({
      name: "",
      description: "",
      status: "NEW",
      startDate: new Date(),
      endDate: new Date(),
    }));
  };

  // to populate data when editing
  const handlePopulateForm = (data, id) => {
    const editingData = data.find((item) => item.id === id);
    if (editingData) {
      setEditingForm(() => ({
        id: id,
        name: editingData?.name,
        description: editingData?.description,
        status: editingData?.status,
        startDate: editingData?.startDate,
        endDate: editingData?.endDate,
      }));
    }
  };

  const changePagination = (pageNumber) => {
    getHistory(pageNumber - 1);
  };

  const changeHistoryPagination = (pageNumber) => {
    getProjectHistory(pageNumber - 1, clientHistoryId);
  };

  const getHistory = async (pageNumber = 0) => {
    setLoading(true);
    const pageSize = 10;
    await ProjectHistoryRepo.getClientHistory(pageNumber, pageSize, search)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setClientData(result.data.data.content);
        setPage(result.data.data.number);
        setTotalData(result.data.data.totalElements);
      })
      .catch((error) => {
        setClientData([]);
        setPage(0);
        //setDataUser(0);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProjectHistory = async (pageNumber = 0, id) => {
    if (!id || id === "") return;
    setLoading(true);
    const pageSize = 10;
    await ProjectHistoryRepo.getProjectHistory(
      pageNumber,
      pageSize,
      projectSearch,
      id
    )
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setProjectData(result.data.data.content);
        setProjectPage(result.data.data.number);
        setProjectTotalData(result.data.data.totalElements);
        setShowForm(false);
        handleResetForm();
      })
      .catch((error) => {
        setProjectData([]);
        setProjectPage(0);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAdd = async (form) => {
    if (!clientHistoryId || clientHistoryId === "") return;
    const entity = {
      ...editingForm,
      ...form,
      historyClientTalent: {
        id: clientHistoryId,
      },
      /*       clientCompany: {
        id: companyId,
      }, */
    };
    setLoading(true);
    setUpdateSuccess(false);
    await ProjectHistoryRepo.addProjectHistory(entity)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        handleResetForm();
        setEditingForm({});
        setEditingId("");
        setShowForm(false);
        getProjectHistory(projectPage, clientHistoryId);
        setUpdateSuccess(() => {
          return true;
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleEdit = async (form) => {
    if (!clientHistoryId || clientHistoryId === "") return;
    const entity = {
      ...editingForm,
      ...form,
      historyClientTalent: {
        id: clientHistoryId,
      },
    };
    setLoading(true);
    setUpdateSuccess(false);

    await ProjectHistoryRepo.editProjectHistory(entity)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        getProjectHistory(projectPage, clientHistoryId);
        handleResetForm();
        setEditingForm(false);
        setEditingId("");
        setShowForm(false);
        setUpdateSuccess(true);
      })
      .catch((error) => {
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = async (id) => {
    setLoading(true);
    await ProjectHistoryRepo.deleteProjectHistory(id)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        getProjectHistory(projectPage, clientHistoryId);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // getHistory();
    selectMenu("projectHistory");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHistory(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (clientHistoryId === "") return;
    getProjectHistory(projectPage, clientHistoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientHistoryId]);

  useEffect(() => {
    getProjectHistory(projectPage, clientHistoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSearch]);

  useEffect(() => {}, [editingForm]);

  const handleAddClientHistory = () => {
    setCounterKeyClientHistory((preVal) => preVal + 1);
    setShowFormClientHistory(true);
  };

  const handleCloseClientHistory = () => {
    setShowFormClientHistory(false);
    setCounterKeyClientHistory((preVal) => preVal + 1);
  };

  const handleSubmitClientHistory = async (data) => {
    await PlacementTalentHistoryRepo.createClientHistory(data)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        AlertUtility.SuccessAlert({ title: "Successfully add client history" });
        getHistory(page);
        handleCloseClientHistory();
      })
      .catch((error) => {
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"History Client"} />
        <div className="flex justify-end w-full my-4">
          <input
            type="text"
            placeholder="Search Role Name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="border rounded-md  w-full md:w-[250px] py-1 px-2"
          />
          <button
            className="bg-[#DD0000] text-white  w-full md:w-[200px] py-1 font-bold rounded ms-3"
            onClick={() => {
              handleAddClientHistory();
            }}
          >
            Add
          </button>
        </div>
        {loading ? (
          <SkeletonTable rows={10} columns={9} />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={clientHistoryColumns((id) => {
                  setClientHistoryId(id);
                  setShowModal(true);
                }, setProjectTitle)}
                dataSource={clientData}
                pagination={false}
                sticky={true}
                scroll={{
                  y: 470,
                  x: 1400,
                }}
              />
            </div>
            <ViewHistory
              form={form}
              updateSucess={updateSuccess}
              setUpdateSuccess={setUpdateSuccess}
              showForm={showForm}
              setShowForm={setShowForm}
              clientName={projectTitle?.clientName}
              roleName={projectTitle?.roleName}
              show={showModal}
              closeModal={setShowModal}
              data={projectData}
              loading={loading}
              page={projectPage}
              total={projectTotalData}
              search={projectSearch}
              handleSearch={(v) => {
                setProjectSearch(v);
              }}
              isEditing={isEditing}
              changePagination={changeHistoryPagination}
              columns={clientHistoryDetailColumns(
                isEditing,
                setIsEditing,
                (id) => {
                  setIsEditing(true);
                  // get id editing, set form value to this editing item
                  setEditingId(id);
                  handlePopulateForm(projectData, id);
                },
                (id) => {
                  // Delay the action by 1000 milliseconds (1 second)
                  setTimeout(() => {
                    // Your action logic here
                    handleDelete(id);
                  }, 500);
                },
                handleInputChange,
                editingForm,
                editingId
              )}
              handleAdd={(clientId, companyId) => {
                handleAdd(clientId, companyId);
              }}
              handleEdit={(v) => {
                handleEdit(v);
              }}
              editingForm={editingForm}
              handleEditForm={handleInputChange}
              handleResetForm={handleResetForm}
            />
            <Pagination
              current={page + 1}
              total={totalData}
              showSizeChanger={false}
              onChange={(p) => changePagination(p)}
              className="flex justify-center mt-2"
            />
          </>
        )}
        <FormClientHistory
          key={"formClientHistory" + counterKeyClientHistory}
          show={showFormClientHistory}
          closeModalClient={() => {
            handleCloseClientHistory();
          }}
          doSubmit={(data) => {
            handleSubmitClientHistory(data);
          }}
        />
      </div>
    </>
  );
};

export default ProjectHistory;
