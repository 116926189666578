import { Pagination, Skeleton, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { talentIssueColumns } from "./column.state";
import TalentHeader from "../../../components/general/TalentHeader";
import { AddForm } from "./AddForm";
import TalentIssueRepo from "../../../repositores/talentRepo/TalentIssueRepo";
import dayjs from "dayjs";

const SkeletonTable = ({ rows = 5, columns = 9 }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <th
                key={colIndex}
                className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <Skeleton.Input active size="small" className="w-24" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
                  <Skeleton.Input active size="default" className="w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TalentIssue = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [projectPage, setProjectPage] = useState(0);
  const [search, setSearch] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingForm, setEditingForm] = useState({
    historyClientTalent: {
      id: "",
    },
    pic: "",
    techStack: "",
    name: "",
    description: "",
    status: "NEW",
    startDate: dayjs(),
    endDate: dayjs(),
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [projectTitle, setProjectTitle] = useState({
    clientName: "",
    roleName: "",
  });
  const [projectId, setprojectId] = useState("");
  const [editingId, setEditingId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [nextPage, setNextPage] = useState(0);
  const [projectListTotal, setProjectListTotal] = useState(0);
  const [projectListSearch, setProjectListSearch] = useState("");

  const handleProjectSearch = (v) => {
    setProjectListSearch(() => {
      return v;
    });
  };

  const handleInputChange = (name, value) => {
    // Assuming you have an input field with a name attribute
    handleFormUpdate({ [name]: value });
  };

  const handleFormUpdate = (updatedValues) => {
    setEditingForm((prevForm) => ({
      ...prevForm,
      ...updatedValues,
    }));
  };

  const handleResetForm = () => {
    setEditingForm({
      historyClientTalent: {
        id: "",
      },
      pic: "",
      techStack: "",
      name: "",
      description: "",
      status: "NEW",
      startDate: dayjs(),
      endDate: dayjs(),
    });
  };
  const changePagination = (pageNumber) => {
    handleIssues(pageNumber - 1);
  };

  const handlePopulateForm = (data, id) => {
    const editingData = data.find((item) => item.id === id);
    // const projectName = projectList?.find((item) => item.value === id);
    if (editingData) {
      setEditingForm(() => ({
        id: editingData?.historyProject?.id,
        historyClientTalent: {
          realId: editingData?.historyClientTalent?.id,
          id: editingData?.historyClientTalent?.clientCompany?.name,
        },
        pic: editingData?.pic,
        techStack: editingData?.techStack,
        name: editingData?.historyProject?.name,
        description: editingData?.description,
        status: editingData?.status,
        startDate: editingData?.startDate,
        endDate: editingData?.endDate,
        type: editingData?.type?.id,
        totalPoint: editingData?.totalPoint,
      }));

      if (editingData?.type === "c3cf4706-e8a9-4d8f-8847-b99edc844282") {
        setEditingForm([
          ...editingForm,
          { totalPoint: editingData?.totalPoint },
        ]);
      }
    }
  };

  const handleProjectList = async (page = 0, size = 10, search) => {
    await TalentIssueRepo.getProjectList(page, size, search)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        // setDataUser(result.data);
        const res = result.data.data.content;
        const data = [
          ...projectList,
          ...res?.map((item) => {
            return { label: item.name, value: item.id };
          }),
        ]?.filter(
          (option, index, self) =>
            index ===
            self.findIndex(
              (t) => t.value === option.value && t.label === option.label
            )
        );
        setProjectList((prev) => data);
        setNextPage(result.data.data.number);
        setProjectListTotal(result.data.data.totalElements);
      })
      .catch((err) => {});
  };

  const getProjectType = async () => {
    await TalentIssueRepo.getProjectType(0, 10)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }

        const res = result.data.data.content;
        const data = [
          ...projectType,
          ...res?.map((item) => {
            return { label: item.name, value: item.id };
          }),
        ]?.filter(
          (option, index, self) =>
            index ===
            self.findIndex(
              (t) => t.value === option.value && t.label === option.label
            )
        );
        setProjectType((prev) => data);
      })
      .catch((err) => {});
  };

  /*  const filteredOptions = data.filter(
    (option, index, self) =>
      index ===
      self.findIndex(
        (t) => t.value === option.value && t.label === option.label
      )
  );
 */
  const handlePopupScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      !loading
    ) {
      handleProjectList(nextPage + 1, 10, projectListSearch);
    }
  };

  useEffect(() => {
    setProjectList(() => {
      return [];
    });
    setNextPage(0);
    setProjectListTotal(0);
    if (nextPage >= 0) {
      handleProjectList(0, 10, projectListSearch);
    }
  }, [projectListSearch]);

  const handleIssueById = async (id) => {
    await TalentIssueRepo.getIssue(id)
      .then((result) => {
        //set list into
      })
      .catch((err) => {});
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await TalentIssueRepo.deleteIssue(id)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        // setDataUser(result.data);
        handleIssues(projectPage, projectId);
      })
      .catch((error) => {
        // setDataUser([]);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleAdd = async (form) => {
    if (!form.name || form.name === "") return;
    const entity = {
      ...editingForm,
      ...form,
      historyProject: {
        id: form.name,
      },
    };

    setLoading(true);
    setUpdateSuccess(false);
    await TalentIssueRepo.addIssue(entity)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        handleResetForm();
        setEditingForm(false);
        setEditingId("");
        setShowForm(false);
        handleIssues(projectPage, projectId);
        setUpdateSuccess(() => {
          return true;
        });
      })
      .catch((error) => {
        // setDataUser([]);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = async (form) => {
    if (!projectId || projectId === "") return;
    const ent = {
      ...editingForm,
      ...form,
      historyProject: {
        id: form.id,
      },
    };

    var entity = { ...ent, id: projectId };

    if (form.type.id !== "c3cf4706-e8a9-4d8f-8847-b99edc844282") {
      const { totalPoint: removedKey, ...dt } = entity;
      entity = dt;
    }

    setLoading(true);
    setUpdateSuccess(false);

    if (typeof entity.historyClientTalent.realId !== "undefined") {
      entity.historyClientTalent.id = entity.historyClientTalent.realId;
      delete entity.historyClientTalent.realId;
    }

    await TalentIssueRepo.editIssue(entity)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        // setDataUser(result.data);
        handleIssues(projectPage, projectId);
        handleResetForm();
        setEditingForm(false);
        setEditingId("");
        setShowForm(false);
        setUpdateSuccess(true);
      })
      .catch((error) => {
        // setDataUser([]);
        console.log(error);
        // AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleIssues = async (page = 0) => {
    const pageSize = 10;
    setLoading(true);
    await TalentIssueRepo.getIssueList(page, pageSize, search)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setProjectData(result.data.data.content);
        setPage(result.data.data.number);
        setTotalData(result.data.data.totalElements);
      })
      .catch((error) => {
        setProjectData([]);
        setPage(0);
        //setDataUser(0);
        // AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!showForm) {
      setIsEditing(false);
      setProjectList([]);
      setProjectListSearch("");
      setNextPage(0);
      setProjectListTotal(0);
      handleResetForm();
    }

    // handleResetForm();
  }, [showForm]);

  useEffect(() => {
    handleIssues();
    //handleProjectList();
  }, []);

  useEffect(() => {
    handleIssues(page);
    getProjectType();
  }, [search]);
  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"Issue Talent"} />
        <div className="flex justify-end w-full my-4">
          <input
            type="text"
            placeholder="Search Role Name..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="border rounded-md w-full md:w-[250px] py-1 px-2"
          />
          <button
            className="bg-[#DD0000] text-white w-full md:w-[200px] py-1 font-bold rounded ms-3"
            onClick={() => {
              handleResetForm();
              setShowForm(true);
            }}
          >
            Add
          </button>
        </div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={talentIssueColumns(
                  (id, isEdit) => {
                    setprojectId(id); // get item id
                    // setShowModal(true);
                    setShowForm(true);
                    handlePopulateForm(projectData, id);
                    // handle view / edit
                    setIsEditing(isEdit);
                  },
                  setProjectTitle,
                  (id) => {
                    // Delay the action by 1000 milliseconds (1 second)
                    setTimeout(() => {
                      // Your action logic here
                      handleDelete(id);
                    }, 500);
                  }
                )}
                dataSource={projectData}
                pagination={false}
                sticky={true}
                scroll={{
                  y: 470,
                  x: 1200,
                }}
              />
            </div>
            <AddForm
              show={showForm}
              closeModal={setShowForm}
              handleAdd={handleAdd}
              handleEditForm={handleInputChange}
              handleEdit={handleEdit}
              editingForm={editingForm}
              isEditing={isEditing}
              projectList={projectList}
              handleResetForm={handleResetForm}
              handleLoadMore={handlePopupScroll}
              handleProjectSearch={handleProjectSearch}
              typeOptions={projectType}
            />
            <Pagination
              current={page + 1}
              total={totalData}
              showSizeChanger={false}
              onChange={(p) => changePagination(p)}
              className="flex justify-center mt-2"
            />
          </>
        )}
      </div>
    </>
  );
};

export default TalentIssue;
