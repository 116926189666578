import { Form, message, Pagination, Modal, Input, Button, Radio, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import TitleMasterAdmin from "../../componets_global/TitleMaster";
import deleteIcon from "../../../../assets/icons/delete.png";
import { useNavigate, useParams } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

const QuestionBank = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [formCategory] = Form.useForm();
    const navigate = useNavigate();
    const [titleModal, setTitleModal] = useState("Add Category");
    const [formType, setFormType] = useState("add");
    const [detailCategory, setDetailCategory] = useState(undefined);
    const [valueCategoryType, setValueCategoryType] = useState(1);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);

    const dummyData = [
        {
            key: 1,
            id: 1,
            parentId: 1,
            question: `<p>What is the output of the following JavaScript code?</p><br/><pre style="background-color: #fbf4f4;padding: 19px; "> console.log(typeof null); </pre>`,
            answers: [
                {
                    id: 1,
                    questionId: 1,
                    text: `"null"`,
                    isTrue: false,
                },
                {
                    id: 2,
                    questionId: 1,
                    text: `"object"`,
                    isTrue: true,
                },
                {
                    id: 3,
                    questionId: 1,
                    text: `"undefined"`,
                    isTrue: false,
                },
                {
                    id: 4,
                    questionId: 1,
                    text: `"number"`,
                    isTrue: false,
                },
                {
                    id: 5,
                    questionId: 1,
                    text: `"function"`,
                    isTrue: false,
                }
            ]
        },
        {
            key: 2,
            id: 2,
            parentId: 1,
            question: `Which of the following is the correct way to declare a variable in JavaScript?`,
            answers: [
                {
                    id: 1,
                    questionId: 2,
                    text: `var myVariable;`,
                    isTrue: true,
                },
                {
                    id: 2,
                    questionId: 2,
                    text: `declare myVariable;`,
                    isTrue: false,
                },
                {
                    id: 3,
                    questionId: 2,
                    text: `create myVariable`,
                    isTrue: false,
                },
                {
                    id: 4,
                    questionId: 2,
                    text: `myVariable var;`,
                    isTrue: false,
                },
                {
                    id: 5,
                    questionId: 2,
                    text: `func myVariable;`,
                    isTrue: false,
                }
            ]
        },
    ]

    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'name',
            render: (_, record) => {
                return (
                    <div className="p-[20px]">
                        <div className="flex mb-[10px]">
                            <Tag>Fundamental</Tag>
                            <Tag>Javascript</Tag>
                            <Tag>Frontend</Tag>
                        </div>
                        <div className="w-full " dangerouslySetInnerHTML={{ __html: record.question }}></div>
                    </div>
                )
            }
        },
        {
            title: '',
            dataIndex: '',
            align: 'center',
            key: 'x',
            render: (_, record) => {
                return (
                    <div className="flex justify-end pr-[14px]">
                        <div
                            className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
                        >
                            <span className="text-red-600">Edit</span>
                        </div>
                        <img
                            className="cursor-pointer"
                            src={deleteIcon}
                            alt="delete"
                        />
                    </div>
                );
            },
        },
    ];

    const columnsChild = [
        {
            title: '',
            dataIndex: 'text',
            key: 'text',
            render: (_, record) => {
                return (
                    <>
                        <Radio defaultChecked={record.isTrue} disabled={true}>
                            {record.text}
                        </Radio>
                    </>
                )
            }
        },
    ];

    const closeModal = (modal = "form") => {
        setIsOpenModal(false)
    };
    const showAddModal = (id) => {
    };

    const onChangeType = (el) => {
        setValueCategoryType(el.target.value);
    };

    const onChangePage = (el) => {

    };

    const handleScrollEventAccount = (event) => {

    };

    const createQuestion = () => {
        navigate({
            pathname: `/admin/question-bank/form`,
        });
    }

    useEffect(async () => {

    }, []);

    return (
        <>
            {contextHolder}
            <div className="w-full h-screen px-4 py-5">
                <TitleMasterAdmin>Questions Bank</TitleMasterAdmin>
                <div className="flex flex-col justify-end lg:flex-row gap-4">

                    <div className="flex flex-row gap-4 lg:mb-0">
                        <Input
                            className="w-[300px]"
                            placeholder="Search"
                            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
                        />
                        <ActionBarButtonOnly
                            buttonTitle="Add Question"
                            actionButton={createQuestion}
                        />
                        <ActionBarButtonOnly
                            buttonTitle="Import Question"
                        />
                    </div>
                </div>
                <div className="mt-[20px] w-full">
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="pl-[40px] w-full">
                                    <p>Answers</p>
                                    <Table columns={columnsChild} dataSource={record.answers} pagination={false}></Table>
                                </div>
                            )
                        }}
                        pagination={false}
                        dataSource={dummyData}
                    />
                </div>
                <div className="flex justify-center mt-5">

                    <Pagination
                        current={page}
                        onChange={onChangePage}
                        pageSize={limit}
                        total={totalItem}
                        showSizeChanger={false}
                    />
                </div>
            </div>
            <Modal
                onCancel={() => closeModal()}
                title={titleModal}
                open={isOpenModal}
                footer={
                    <>
                        <div className="flex justify-between mt-[20px]">
                            <Button key="back" danger onClick={() => closeModal()}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "#DD0000", color: "white" }}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                }
            >
                <div className="container p-[10px] my-[30px]">
                    <Radio.Group onChange={onChangeType} value={valueCategoryType} className="pb-[20px]">
                        <Radio value={1}>Parent Category</Radio>
                        <Radio value={2}>Child Category</Radio>
                    </Radio.Group>
                    <Form
                        labelCol={{ flex: "110px" }}
                        labelAlign="left"
                        form={formCategory}
                        colon={false}
                        wrapperCol={{ flex: 1 }}
                    >
                        {
                            valueCategoryType === 2 ? (
                                <>
                                    <Form.Item

                                        label="Parent Category"
                                        name="parentCategory"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Select Parent Category",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="w-full"
                                            showSearch
                                            placeholder="Choose a Parent Category"
                                            optionFilterProp="children"
                                            dropdownRender={(menu) => (
                                                <div onWheel={handleScrollEventAccount}>{menu}</div>
                                            )}
                                        >
                                            {dummyData.map((valParent) => (
                                                <Option key={valParent.id} value={valParent.id}>
                                                    {valParent.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </>
                            ) : <></>
                        }

                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input category",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Please input category"
                                disabled={formType !== "add"}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

const ActionBarSearchOnly = ({
    buttonTitle,
    actionButton,
    onSearch,
    placeholder,
}) => {
    return (
        <Input
            className="w-full lg:w-[200px] max-w-[400px]"
            placeholder={placeholder}
            suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
            onChange={onSearch}
        />
    );
};

const ActionBarButtonOnly = ({ buttonTitle, actionButton }) => {
    return (
        <div className=" flex justify-center">
            {actionButton && (
                <div
                    onClick={actionButton}
                    className="bg-red-600  w-[150px] h-[30px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
                >
                    {buttonTitle}
                </div>
            )}
        </div>
    );
};

export default QuestionBank;
