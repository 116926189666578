import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Typography, Form, Input } from "antd";
import { setReason } from "../../../../../features/certificateIssuedSlice";

const ModalCertificateIssued = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    handleCancel,
    modalTitle,
    instruction,
    nameForm,
    instanceForm,
    isDetails,
    dataForm,
    buttonAction,
  } = props;

  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    instanceForm.setFieldsValue({
      id: dataForm.key,
      title: dataForm.title,
      type: dataForm.type,
      issuedTo: dataForm.issuedTo,
      date: dataForm.date,
      reason: dataForm.reason,
      revokeDate: dataForm.revokeDate,
    });
  }, []);

  return (
    <div>
      <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        style={{ top: 20 }}
      >
        <div className="mb-8">
          <h1 className="text-2xl font-bold">{modalTitle}</h1>
          <Text className="text-xs" type="secondary">
            {instruction}
          </Text>
        </div>
        <div>
          <Form
            name={nameForm}
            form={instanceForm}
            labelCol={{ span: 6 }}
            disabled={isDetails}
            autoComplete="off"
          >
            <Form.Item label="ID" name="id">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Title" name="title">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Type" name="type">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Issued To" name="issuedTo">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Date" name="date">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Reason" name="reason">
              <TextArea
                placeholder={isDetails ? "" : "Enter revoke reason"}
                disabled={isDetails}
                rows={4}
                onChange={(event) => dispatch(setReason(event.target.value))}
              />
            </Form.Item>

            {isDetails && (
              <Form.Item label="Revoke Date" name="revokeDate">
                <Input disabled />
              </Form.Item>
            )}

            {!isDetails && (
              <Form.Item>
                <div className="flex justify-end w-full">
                  <button
                    className="bg-red-600 w-full md:w-[150px] h-[30px] text-white rounded"
                    onClick={buttonAction}
                  >
                    Submit
                  </button>
                </div>
              </Form.Item>
            )}
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCertificateIssued;
