import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  Divider,
  ConfigProvider,
  Form,
  Select,
  Button,
  Space,
  Tag,
} from "antd";
import debounce from "lodash/debounce";
import {
  SearchOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

export const SearchSelectBox = ({
  data,
  onChangeInput = (e) => {},
  placeholder,
  selectedAll,
  checkedListCP,
  setListCP = (e) => {},
  doSave = () => {},
  alertSelectAll,
  saved,
  setSaved = (e) => {},
  handleSearchEmail = (e) => {},
  searchEmailData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState();
  const ref = useRef();
  const checkAll = data.length === checkedListCP.length;
  const indeterminate =
    checkedListCP.length > 0 && checkedListCP.length < data.length;

  const onChange = async (e) => {
    setSaved(false);

    (await e.target.checked)
      ? setListCP([...checkedListCP, e.target.value])
      : setListCP(checkedListCP.filter((value) => value !== e.target.value));
  };

  const handleChangePasteEmail = async () => {
    setSaved(false);

    setListCP([...checkedListCP, searchEmailData]);
  };

  const onCheckAllChange = async (e) => {
    if (e.target.checked) {
      const dt = [];
      for (let i = 0; i < data.length; i++) {
        dt.push(data[i]);
      }

      await setListCP(dt);
    } else {
      await setListCP([]);
    }
  };

  const checked = (id) => {
    return checkedListCP.includes(id);
  };

  const removeSelected = (e) => {
    setSaved(false);

    setListCP(checkedListCP.filter((value) => value !== e));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="search-select-box relative" ref={ref}>
      <div
        className={
          selectedAll
            ? "searchbox w-full bg-gray-100 rounded-xl border p-2.5 px-5 flex align-center cursor-not-allowed"
            : "searchbox w-full bg-white rounded-xl border p-2.5 px-5 flex align-center"
        }
        style={{ borderColor: "#DDDDDD" }}
      >
        <input
          className="w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onFocus={(e) => {
            setIsOpen(true);
          }}
          onChange={async (e) => {
            setInputValue(e.target.value);
            let val = e.target.value.replace(/\s+/g, "").split(",");
            val.length > 1 ? setIsOpen(false) : <></>;

            if (val.length == 1) {
              onChangeInput(e.target.value);
            }
          }}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              let val = e.target.value.replace(/\s+/g, "").split(",");
              val.length > 1 ? setIsOpen(false) : <></>;

              handleSearchEmail(val);
              await handleChangePasteEmail();

              setInputValue("");
            }
          }}
          disabled={selectedAll ? true : false}
        />
        <SearchOutlined style={{ color: "grey", fontSize: "18px" }} />
      </div>
      {selectedAll ? (
        <div className="flex gap-1 my-1">
          <ExclamationCircleOutlined style={{ color: "#DD0000" }} />
          <span>{alertSelectAll}</span>
        </div>
      ) : (
        <></>
      )}
      {!saved ? (
        <div className="flex gap-1 my-1">
          <InfoCircleOutlined style={{ color: "#faad14" }} />
          <span>You haven't saved the changes</span>
        </div>
      ) : (
        <></>
      )}
      {isOpen ? (
        <div
          className="dropdownbox h-96 bg-white rounded-xl border px-5 absolute z-50	w-full my-1"
          style={{ overflow: "auto" }}
        >
          {/* <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#dd0000",
                colorPrimaryBorder: "#dd0000",
              },
            }}
          >
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              className="my-2 h-9 flex items-center font-medium"
              style={{ color: "#4C4C4C" }}
            >
              Select All
            </Checkbox>
          </ConfigProvider> */}
          {/* <Divider className="m-0" /> */}
          <div className="flex flex-col justify-start">
            {data.map((val, i) => (
              <ConfigProvider
                key={i}
                theme={{
                  token: {
                    colorPrimary: "#dd0000",
                    colorPrimaryBorder: "#dd0000",
                  },
                }}
              >
                <Checkbox
                  className={
                    "h-9 flex items-center font-medium my-2 leading-normal"
                  }
                  style={{ color: "#4C4C4C" }}
                  key={i}
                  value={val}
                  onChange={onChange}
                  checked={checked(val)}
                >
                  <span className={checked(val) ? "text-gray-400" : ""}>
                    {val.fullname}
                  </span>
                  <br />
                  <span
                    className={checked(val) ? "text-gray-400" : ""}
                    style={{ color: "#A8A8A8" }}
                  >
                    {val.username}
                  </span>
                </Checkbox>
              </ConfigProvider>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      <Space size={[0, 8]} wrap className="my-2">
        {checkedListCP.length ? (
          checkedListCP.map((comittee, index) => {
            if (!comittee.isDeleted) {
              return (
                <Tag
                  className="rounded-xl border bg-white"
                  key={"tagid-" + index + "-" + 1}
                >
                  <div className="flex align-center">
                    <p className="pt-[6px]">{comittee.fullname}</p>
                    <Button
                      type="link"
                      style={{
                        padding: "0",
                        color: "black",
                        marginLeft: "10px",
                        paddingBottom: "",
                        fontSize: "10px",
                      }}
                      onClick={() => removeSelected(comittee)}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                </Tag>
              );
            }
          })
        ) : (
          <></>
        )}
      </Space>
    </div>
  );
};
