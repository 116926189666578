import { Button } from "antd";
import moment from "moment";

export const talentJourneyColumns = (
  handleView = (id, course, courseId) => {}
) => {
  return [
    {
      title: "Course",
      dataIndex: ["course", "name"],
      key: "id",
      align: "center",
      width: 150,
      render: (_, record) => {
        return <div className="remark-cell">{record.course?.name}</div>;
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{moment(v).format("YYYY-MMM-DD")}</p>;
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{moment(v).format("YYYY-MMM-DD")}</p>;
      },
    },
    {
      title: "Batch",
      dataIndex: ["batch", "name"],
      key: "id",
      align: "center",
      width: 150,
      render: (_, record) => {
        return <div className="remark-cell">{record.batch?.name}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status", "name"],
      key: "id",
      align: "center",
      render: (v, row) => {
        const styleData = clientStatusOptions.find(
          (item) => item.value === row.status
        );

        return (
          <div className="flex justify-center">
            {v.toLowerCase() === "active" ? (
              <div
                className={`w-[100%] justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
              >
                {v}
              </div>
            ) : (
              <div className="w-[100%] text-center rounded bg-[#FFCFCF] text-[#DD0000]">
                {v}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Point",
      dataIndex: "point",
      key: "id",
      align: "center",
      render: (v) => {
        return <span>{v == null ? "0" : v}</span>;
      },
    },
    {
      title: "IsInput",
      dataIndex: "isInput",
      key: "id",
      align: "center",
      render: (v) => {
        return <span>{v == null ? "No" : "Yes"}</span>;
      },
    },
    {
      title: "Progress",
      dataIndex: "percentageCourses",
      key: "id",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      align: "center",
      render: (v, row) => {
        return (
          <div>
            <button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                handleView(row.id, row.course.name, row.course.id);
              }}
            >
              View
            </button>
          </div>
        );
      },
    },
  ];
};

export const talentJourneyTerminColumns = (handleView = (material) => {}) => {
  return [
    {
      title: "Material",
      dataIndex: ["matkul"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return <div className="remark-cell">{record.matkul}</div>;
      },
    },
    {
      title: "Average",
      dataIndex: ["average"],
      key: "id",
      align: "center",
    },
    {
      title: "Score UTS",
      dataIndex: "score_uts",
      key: "id",
      align: "center",
    },
    {
      title: "Score UAS",
      dataIndex: "score_uas",
      key: "id",
      align: "center",
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "id",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      align: "center",
      render: (_, row) => {
        return (
          <div>
            <button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                handleView(row);
              }}
            >
              View
            </button>
          </div>
        );
      },
    },
  ];
};

export const talentJourneyTopicColumns = (
  submitted,
  handleView = (row) => {
    // console.log(id);
  },
  handleProgress = (id) => {},
  isUas,
  isUts
) => {
  return [
    {
      title: "Topic",
      dataIndex: ["subMatkul", "topikNo"],
      key: "id",
      align: "center",
      width: "100px",
      sorter: (a, b) => a.subMatkul.topikNo - b.subMatkul.topikNo,
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Title",
      dataIndex: ["subMatkul", "topik"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return <div className="remark-cell">{record.subMatkul?.topik}</div>;
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "id",
      align: "center",
      width: "150px",
      render: (v, row) => {
        return (
          <div className="flex gap-1 justify-center">
            {row.subMatkul.linkFileName ? (
              <div>
                <Button
                  className="w-full"
                  type="primary"
                  ghost
                  href={row.subMatkul.linkFileName}
                  target="_blank"
                  disabled={row.subMatkul.linkFileName === null ? true : false}
                  onClick={() => {
                    handleProgress(row.id);
                  }}
                >
                  URL 1
                </Button>
              </div>
            ) : (
              <></>
            )}

            {row.subMatkul.linkInternet ? (
              <div>
                <Button
                  className="w-full"
                  type="primary"
                  primary
                  href={row.subMatkul.linkInternet}
                  target="_blank"
                  onClick={() => {
                    handleProgress(row.id);
                  }}
                >
                  URL 2
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: "Filename",
      dataIndex: ["subMatkul", "fileName"],
      key: "id",
      align: "center",
      render: (v, row) => {
        return row.type === "uts" || row.type === "uas" ? (
          <div></div>
        ) : (
          <div className="remark-cell">{v}</div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      align: "center",
      render: (v, row) => {
        const styleData = statusOptions.find(
          (item) => item.value === row.status
        );

        return (
          <div className="flex justify-center">
            {
              <div
                className={`w-[100%] justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
              >
                {v}
              </div>
            }
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      align: "center",
      width: "100px",
      render: (v, row) => {
        /*       console.log('e', row.type, isUts, isUas);
        console.log("isuts",isUts);
        console.log("isuas",isUas); */
        return row.type !== "Materi" ? (
          <div>
            <Button
              type="dashed"
              block
              onClick={() => {
                var type = "upload";
                if (row.feedback !== null || row.description !== null) {
                  type = "view";
                }
                handleView(row, type);
              }}
              // test purpose
              // if row type is uts ? disabled if uts false -> when uas
              /* disabled={row.type === "UTS" ? !isUts : !isUas} */
            >
              {row.feedback !== null || row.description !== null
                ? "View"
                : "Submit"}
            </Button>
          </div>
        ) : (
          <div>{row.type}</div>
        );
      },
    },
  ];
};

export const statusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "DONE",
    label: "DONE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "HOLD",
    label: "HOLD",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
  {
    value: "PENDING",
    label: "PENDING",
    color: "text-[#616060]",
    bgColor: "bg-[#c2c2c2]",
  },
];

export const clientStatusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "SOLVE",
    label: "SOLVE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "REJECT",
    label: "REJECT",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
];
