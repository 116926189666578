import React from "react";
import { Table } from "antd";

const TableListDetailHistory = ({ dataTable }) => {

    const columns = [
        {
            title: "No",
            dataIndex: "number",
            key: "number",
            align: "center",
            width: '5%'
        },
        {
            title: "Talent Name",
            dataIndex: "fullName",
            key: "number",
            width: '30%'
        },
        {
            title: "Course Name",
            dataIndex: "courseName",
            key: "number"
        },
        {
            title: "Level",
            dataIndex: "levelCourse",
            align: "center",
            key: "number"
        },
        {
            title: "Progress",
            dataIndex: "progress",
            key: "number",
            align: "center",
            width: '5%',
            render: (_, record) => { 
                return <span>{record?.progress ? `${Number(record?.progress).toFixed(2)}%` : '0.00%' }</span>
            }
        },
        {
            title: "Score Already Answer",
            dataIndex: "scoreAlreadyAnswer",
            align: "center",
            key: "number"
        },
        {
            title: "Score Not Yet Answer",
            dataIndex: "scoreNotYetAnswer",
            align: "center",
            key: "number"
        },
        {
            title: "Score Status All",
            dataIndex: "scoreStatusAll",
            align: "center",
            key: "number"
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "number",
            align: "center",
            width: '5%',
        },
    ]

    return (
        <>
            <Table
                dataSource={dataTable}
                columns={columns}
                pagination={false}
                rowKey="number"
            />
        </>
    )
}

export default TableListDetailHistory