import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
} from "react";
import { Form, Input, Select, DatePicker } from "antd";
import HistoryClientRepo from "../../../../repositores/adminRepo/HistoryClientRepo";
import MasterUserManagementRepo from "../../../../repositores/adminRepo/UserManagement";
import AlertUtility from "../../../../components/Alert/Alert";
import dayjs from "dayjs";
import { TalentHelperContext } from "../../../../components/talentPage/TalentContext";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const FormTLOneMonthSupervised = forwardRef((props, ref) => {
  const { user } = useContext(TalentHelperContext);
  const [formOneMonth] = Form.useForm();

  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(100);
  const [optionsTalent, setOptionsTalent] = useState([]);
  const [totalElementTalent, setTotalElementTalent] = useState(-1);
  const [onSearchOptionTalent, setOnSearchOptionTalent] = useState(false);
  const [searchTalent, setSearchTalent] = useState("");

  const [loadingMoreTl, setLoadingMoreTl] = useState(false);
  const [pageTl, setPageTl] = useState(0);
  const [totalPageTl, setTotalPageTl] = useState(100);
  const [optionsTalentTl, setOptionsTalentTl] = useState([]);
  const [totalElementTalentTl, setTotalElementTalentTl] = useState(-1);
  const [onSearchOptionTalentTl, setOnSearchOptionTalentTl] = useState(false);
  const [searchTalentTl, setSearchTalentTl] = useState("");

  const [debounceOptionTalent, setDebounceOptionTalent] = useState(undefined);
  const [debounceOptionTalentTl, setDebounceOptionTalentTl] =
    useState(undefined);

  const [selectedTalent, setSelectedTalent] = useState({});
  const dateFormat = "YYYY/MM/DD";

  useImperativeHandle(ref, () => ({
    validateFields() {
      return formOneMonth.validateFields();
    },
    getFieldValue(name) {
      return formOneMonth.getFieldValue(name);
    },
    resetForm() {
      formOneMonth.resetFields();
    },
    getSelectedTalent() {
      return selectedTalent;
    },
  }));

  const optStatus = [
    { value: "NEW", label: "NEW" },
    { value: "DONE", label: "DONE" },
    { value: "INPROGRESS", label: "INPROGRESS" },
  ];

  useEffect(() => {
    if (props.formType === "edit") {
      setSelectedTalent(props?.dataEdit?.historyClientTalent);
      setOptionsTalentTl([
        {
          id: props?.dataEdit?.tl?.id,
          fullname: props?.dataEdit?.tl?.fullname,
        },
      ]);
      formOneMonth.setFieldsValue({
        talent: `${props?.dataEdit?.historyClientTalent?.userRole?.user?.fullname} - ${props?.dataEdit?.historyClientTalent?.clientCompany?.name} - ${props?.dataEdit?.historyClientTalent?.userRole?.roleCompany?.name}`,
        fullname:
          props?.dataEdit?.historyClientTalent?.userRole?.user?.fullname,
        client: props?.dataEdit?.historyClientTalent?.clientCompany?.name,
        role: props?.dataEdit?.historyClientTalent?.userRole?.roleCompany?.name,
        date: [
          dayjs(props?.dataEdit?.historyClientTalent?.startDate, dateFormat),
          dayjs(props?.dataEdit?.historyClientTalent?.endDate, dateFormat),
        ],
        pic: props?.dataEdit?.pic,
        status: props?.dataEdit?.status,
        remarkByTalent: props?.dataEdit?.remarkByTalent,
        remarkByTl: props?.dataEdit?.remarkByTl,
        tl: props?.dataEdit?.tl?.id,
      });
    }
  }, [props.formType, props.dataEdit]);

  useEffect(() => {
    setPage(0);
    handleFetchMore(0);
  }, [searchTalent]);

  useEffect(() => {
    setPageTl(0);
    handleFetchMoreTl(0);
  }, [searchTalentTl]);

  const handleScroll = (event) => {
    let pos =
      (event.target.scrollTop || event.target.scrollTop) +
      event.target.offsetHeight;
    let max = event.target.scrollHeight;
    if (pos >= max) {
      if (page <= totalPage && optionsTalent.length !== totalElementTalent) {
        setLoadingMore(true);
      }
      clearTimeout(debounceOptionTalent);
      setDebounceOptionTalent(
        setTimeout(() => {
          event.stopPropagation();
          if (!onSearchOptionTalent) {
            handleFetchMore(page);
          }
        }, 500)
      );
    }
  };

  const handleFetchMore = async (page) => {
    if (page <= totalPage && optionsTalent.length !== totalElementTalent) {
      try {
        const response = await HistoryClientRepo.getClientListHistory(
          page,
          20,
          undefined,
          searchTalent
        );
        let tempOptions = [...optionsTalent];
        if (response.data?.data?.content?.length) {
          response.data.data.content.forEach((talent) => {
            tempOptions.push(talent);
          });
          setOptionsTalent(tempOptions);
          setTotalPage(response.data.data.totalPages);
          setTotalElementTalent(response.data.data.totalElements);
        }
        setPage(page + 1);
        setLoadingMore(false);
        setOnSearchOptionTalent(false);
      } catch (error) {
        AlertUtility.ErrorAlert({ title: "Failed Get Data" });
        setLoadingMore(false);
      }
    }
  };

  const doSearchTalent = (input) => {
    setOnSearchOptionTalent(true);
    clearTimeout(debounceOptionTalent);
    setDebounceOptionTalent(
      setTimeout(() => {
        setOptionsTalent([]);
        setSearchTalent(input);
      }, 2000)
    );
  };

  const handleScrollTl = (event) => {
    let pos =
      (event.target.scrollTop || event.target.scrollTop) +
      event.target.offsetHeight;
    let max = event.target.scrollHeight;
    if (pos >= max) {
      if (
        pageTl <= totalPageTl &&
        optionsTalentTl.length !== totalElementTalentTl
      ) {
        setLoadingMoreTl(true);
      }
      clearTimeout(debounceOptionTalentTl);
      setDebounceOptionTalentTl(
        setTimeout(() => {
          event.stopPropagation();
          if (!onSearchOptionTalentTl) {
            handleFetchMoreTl(pageTl);
          }
        }, 2000)
      );
    }
  };

  const handleFetchMoreTl = async (pageTl) => {
    if (
      pageTl <= totalPageTl &&
      optionsTalentTl.length !== totalElementTalentTl
    ) {
      try {
        const response = await MasterUserManagementRepo.getUserManagement(
          pageTl,
          20,
          searchTalentTl
        );
        let tempOptions = [...optionsTalentTl];
        if (response.data?.data?.content?.length) {
          response.data.data.content.forEach((talent) => {
            if (!tempOptions.find((dt) => dt.id === talent.id)) {
              tempOptions.push(talent);
            }
          });
          setOptionsTalentTl(tempOptions);
          setTotalPageTl(response.data.data.totalPages);
          setTotalElementTalentTl(response.data.data.totalElements);
        }
        setPageTl(pageTl + 1);
        setLoadingMoreTl(false);
        setOnSearchOptionTalentTl(false);
      } catch (error) {
        AlertUtility.ErrorAlert({ title: "Failed Get Data" });
        setLoadingMoreTl(false);
      }
    }
  };

  const doSearchTalentTl = (input) => {
    setOnSearchOptionTalentTl(true);
    clearTimeout(debounceOptionTalentTl);
    setDebounceOptionTalentTl(
      setTimeout(() => {
        setOptionsTalentTl([]);
        setSearchTalentTl(input);
      }, 2000)
    );
  };

  return (
    <Form labelAlign="left" form={formOneMonth} layout="vertical" colon={false}>
      <Form.Item
        label="Talent"
        name="talent"
        rules={[{ required: true, message: "Please Select Talent" }]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Choose Talent"
          dropdownRender={(menu) => <div onWheel={handleScroll}>{menu}</div>}
          loading={loadingMore}
          showSearch
          filterOption={false}
          onSearch={doSearchTalent}
          disabled={props.formType === "edit"}
          defaultActiveFirstOption={false}
          onChange={(val) => {
            const tempDataTalent = optionsTalent.find(
              (talent) => talent.id === val
            );
            if (tempDataTalent) {
              setSelectedTalent(tempDataTalent);
              formOneMonth.setFieldsValue({
                fullname: tempDataTalent.userRole.user?.fullname,
                client: tempDataTalent.clientCompany?.name,
                role: tempDataTalent.userRole?.roleCompany?.name,
                date: [
                  dayjs(tempDataTalent.startDate, dateFormat),
                  dayjs(tempDataTalent.endDate, dateFormat),
                ],
              });
            }
          }}
        >
          {onSearchOptionTalent ? (
            <Option disabled>Please wait...</Option>
          ) : (
            optionsTalent.map((i) => (
              <Option key={i.id} value={i.id}>
                {i.userRole.user?.fullname} - {i.clientCompany?.name} -{" "}
                {i.userRole?.roleCompany?.name}
              </Option>
            ))
          )}
          {loadingMore ? <Option disabled>Please wait...</Option> : null}
        </Select>
      </Form.Item>

      {selectedTalent?.id && (
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <Form.Item
              label="Fullname"
              name="fullname"
              className="w-full sm:w-1/2"
            >
              <Input value={selectedTalent.userRole.user?.fullname} disabled />
            </Form.Item>
            <Form.Item label="Role" name="role" className="w-full sm:w-1/2">
              <Input
                value={
                  selectedTalent.historyClientTalent?.userRole?.roleCompany
                    ?.name
                }
                disabled
              />
            </Form.Item>
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <Form.Item label="Client" name="client" className="w-full sm:w-1/2">
              <Input
                value={selectedTalent.historyClientTalent?.clientCompany?.name}
                disabled
              />
            </Form.Item>
            <Form.Item
              label="Start Date - End Date"
              className="w-full sm:w-1/2"
              name="date"
            >
              <RangePicker className="w-full" disabled />
            </Form.Item>
          </div>
          <Form.Item
            label="PIC"
            name="pic"
            rules={[{ required: true, message: "Please input PIC" }]}
          >
            <Input placeholder="Please input PIC" />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Please select status" }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Choose status"
              options={optStatus}
            ></Select>
          </Form.Item>
          <Form.Item
            label="TL"
            name="tl"
            rules={[{ required: true, message: "Please Select TL" }]}
            initialValue={user.id}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Choose TL"
              dropdownRender={(menu) => (
                <div onScroll={handleScrollTl}>{menu}</div>
              )}
              loading={loadingMoreTl}
              showSearch
              filterOption={false}
              onSearch={doSearchTalentTl}
              defaultActiveFirstOption={false}
              value={user.id}
              disabled
              labelInValue
            >
              <Option key={user.id} value={user.id}>
                {user.fullname}
              </Option>
              {onSearchOptionTalentTl ? (
                <Option disabled>Please wait...</Option>
              ) : (
                optionsTalentTl.map((i) => (
                  <Option key={i.id} value={i.id}>
                    {i.fullname}
                  </Option>
                ))
              )}
              {loadingMoreTl ? <Option disabled>Please wait...</Option> : null}
            </Select>
          </Form.Item>
          <Form.Item label="Talent Remarks" name="remarkByTalent">
            <TextArea placeholder="Please input Talent Remarks" />
          </Form.Item>
          <Form.Item label="TL Remarks" name="remarkByTl">
            <TextArea placeholder="Please input TL Remarks" />
          </Form.Item>
        </>
      )}
    </Form>
  );
});

export default FormTLOneMonthSupervised;
