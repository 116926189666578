import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { message, Button, Table, Pagination, Input, Modal } from "antd";
import Onboarding from "../../../repositores/talentRepo/OnboardRepo";
import { isNullOrEmpty } from "../../../utils/utils";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import FormOneMonthSupervised from "./components/FormOneMonthSupervised";

const OneMonthSupervised = () => {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [searchName, setSearchName] = useState(undefined);

  const [dataTable, setDataTable] = useState([]);
  const [bounchPage, setBounchPage] = useState(undefined);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [counterChildRefresh, setCounterChildRefresh] = useState(1);
  const [detailOms, setDetailOms] = useState({});
  const formOMSRef = useRef();

  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 70,
    },
    {
      title: "Talent Name",
      dataIndex: ["historyClientTalent", "userRole", "user", "fullname"],
      key: "talentName",
      width: 150,
      render: (_, record) => {
        if (
          isNullOrEmpty(record.historyClientTalent?.userRole?.user?.fullname)
        ) {
          return "-";
        }
        return (
          <div className="remark-cell">
            {record.historyClientTalent?.userRole?.user?.fullname}
          </div>
        );
      },
    },
    {
      title: "Client",
      dataIndex: ["historyClientTalent", "clientCompany", "name"],
      key: "client",
      render: (_, record) => {
        if (isNullOrEmpty(record.historyClientTalent?.clientCompany?.name)) {
          return "-";
        }
        return (
          <div className="remark-cell">
            {record.historyClientTalent?.clientCompany?.name}
          </div>
        );
      },
    },
    {
      title: "Role Name",
      dataIndex: ["historyClientTalent", "userRole", "roleCompany", "name"],
      align: "center",
      key: "roleName",
      width: 150,
      render: (_, record) => {
        if (
          isNullOrEmpty(record.historyClientTalent?.userRole?.roleCompany?.name)
        ) {
          return "-";
        }
        return (
          <div className="remark-cell">
            {record.historyClientTalent?.userRole?.roleCompany?.name}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: ["historyClientTalent", "startDate"],
      align: "center",
      key: "startDate",
      render: (_, record) => {
        if (isNullOrEmpty(record?.historyClientTalent?.startDate)) {
          return "-";
        }
        return dayjs(record?.historyClientTalent?.startDate).format(
          "DD MMM YYYY"
        );
      },
    },
    {
      title: "End Date",
      dataIndex: ["historyClientTalent", "endDate"],
      align: "center",
      key: "endDate",
      render: (_, record) => {
        if (isNullOrEmpty(record?.historyClientTalent?.endDate)) {
          return "-";
        }
        return dayjs(record?.historyClientTalent?.endDate).format(
          "DD MMM YYYY"
        );
      },
    },
    {
      title: "PIC",
      dataIndex: "pic",
      key: "pis",
      render: (_, record) => {
        if (isNullOrEmpty(record.pic)) {
          return "-";
        }
        return <div className="remark-cell">{record.pic}</div>;
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Remark By Talent",
      dataIndex: "remarkByTalent",
      key: "remarkByTalent",
      width: 150,
      render: (_, record) => {
        if (isNullOrEmpty(record.remarkByTalent)) {
          return "-";
        }
        return <div className="remark-cell">{record.remarkByTalent}</div>;
      },
    },
    {
      title: "Message",
      dataIndex: ["message"],
      key: "message",
      width: 300,
      render: (_, record) => {
        if (isNullOrEmpty(record.message)) {
          return "-";
        }
        return <div className="remark-cell">{record.message}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            <div
              onClick={() => {
                showEditOms(record);
              }}
              className="mr-[30px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">Edit</span>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshCounter, searchName]);

  async function fetchData() {
    setLimit(10);
    try {
      let tempData = [];
      let response = await Onboarding.getListOneMonthSupervised(
        page,
        limit,
        searchName
      );
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content, index) => {
          content.number = (page > 0 ? limit * (page - 1) : 0) + (index + 1);
          tempData.push(content);
        });
        setDataTable(tempData);
        setTotalItem(response?.data?.data?.totalElements);
      } else {
        setDataTable([]);
        setTotalItem(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Failed to get Data Onboarding",
      });
    }
  }

  const onChangePage = (el) => {
    setLoading(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setPage(el);
      }, 1000)
    );
  };

  const onChangeSearch = (el) => {
    setLoading(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setSearchName(el.target.value);
        setPage(0);
      }, 2000)
    );
  };

  const showEditOms = (row) => {
    setDetailOms({
      id: row.id,
      pic: row.pic,
      status: row.status,
      remarkByTalent: row.remarkByTalent,
    });
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setDetailOms({});
    setIsOpenModal(false);
  };

  const updateOMS = () => {
    formOMSRef.current
      .validateFields()
      .then(async () => {
        let payload = {
          id: detailOms.id,
          pic: formOMSRef.current.getFieldValue("pic"),
          status: formOMSRef.current.getFieldValue("status"),
          remarkByTalent: formOMSRef.current.getFieldValue("remarkByTalent"),
        };
        setLoading(true);
        formOMSRef.current.resetForm();
        let counterC = counterChildRefresh;
        setCounterChildRefresh(counterC + 1);
        setIsOpenModal(false);
        try {
          let response = await Onboarding.editOMS(payload);
          if (response.data.status === 200) {
            const counter = refreshCounter + 1;
            messageApi.open({
              type: "success",
              content: "Successfully Update data",
            });
            setRefreshCounter(counter);
          } else {
            throw response.data;
          }
        } catch (error) {
          if (error?.message || error?.data?.message || error?.error) {
            messageApi.open({
              type: "error",
              content: error?.message || error?.data?.message || error?.error,
            });
          } else {
            messageApi.open({
              type: "error",
              content:
                "sorry we're having trouble adding data right now, please try again later",
            });
          }
          setLoading(false);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <div className="w-[100%] px-[50px] py-[20px]">
          <h4 className="text-[32px] font-bold">One Month Supervised</h4>
        </div>
        <div className="flex gap-x-6 justify-end px-[20px]">
          <Input
            className="w-full md:w-[250px] py-1 px-2"
            placeholder="Search Client Name"
            onChange={onChangeSearch}
            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
          />
        </div>
        <div className="mt-[20px] w-full px-[20px] custom-table">
          <Table
            dataSource={dataTable}
            columns={columns}
            pagination={false}
            scroll={{
              y: 470,
              x: 1500,
            }}
          />
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
            showSizeChanger={false}
            className="flex justify-center mt-5"
          />
        </div>
      </div>

      <Modal
        onCancel={() => closeModal()}
        title={"Update Data One Month Supervised"}
        open={isOpenModal}
        footer={
          <>
            <div className="flex justify-between mt-[20px]">
              <Button key="back" danger onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#DD0000", color: "white" }}
                onClick={() => updateOMS()}
              >
                Submit
              </Button>
            </div>
          </>
        }
      >
        <div className="container p-[10px] my-[30px]">
          <FormOneMonthSupervised
            key={counterChildRefresh}
            ref={formOMSRef}
            formType={"edit"}
            dataEdit={detailOms}
          />
        </div>
      </Modal>
    </>
  );
};
export default OneMonthSupervised;
