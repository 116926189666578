import React from "react";

const index = ({ className, color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2336_5868)">
        <path
          d="M17.0761 7.83172C17.0761 11.742 13.9061 14.912 9.99577 14.912C6.08546 14.912 2.91553 11.742 2.91553 7.83172C2.91553 3.9214 6.08546 0.751465 9.99577 0.751465C13.9061 0.751465 17.0761 3.9214 17.0761 7.83172Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2856 3.98773L11.2609 5.94966C11.2827 5.99928 11.3173 6.04222 11.3612 6.0741C11.405 6.10598 11.4565 6.12565 11.5104 6.1311L13.6765 6.45999C13.7384 6.46805 13.7968 6.49358 13.8447 6.53358C13.8927 6.57358 13.9282 6.6264 13.9473 6.68588C13.9663 6.74536 13.968 6.80903 13.9522 6.86943C13.9364 6.92985 13.9037 6.9845 13.8579 7.02702L12.2589 8.54666C12.2351 8.59215 12.2226 8.64273 12.2226 8.69409C12.2226 8.74545 12.2351 8.79603 12.2589 8.84152L12.5651 10.9962C12.5782 11.0585 12.5729 11.1232 12.5499 11.1826C12.5269 11.2419 12.4872 11.2933 12.4356 11.3305C12.3839 11.3677 12.3226 11.3892 12.2591 11.3922C12.1955 11.3953 12.1324 11.3799 12.0775 11.3478L10.1496 10.3271C10.0997 10.3046 10.0455 10.2929 9.99079 10.2929C9.93603 10.2929 9.88192 10.3046 9.83202 10.3271L7.90412 11.3478C7.84916 11.3799 7.78606 11.3953 7.7225 11.3922C7.65895 11.3892 7.59763 11.3677 7.54602 11.3305C7.49439 11.2933 7.45467 11.2419 7.43169 11.1826C7.40869 11.1232 7.4034 11.0585 7.41647 10.9962L7.77937 8.84152C7.79497 8.7915 7.79796 8.7384 7.78807 8.68696C7.77817 8.63552 7.7557 8.58732 7.72266 8.54666L6.12365 7.01568C6.08123 6.97272 6.05146 6.9189 6.03759 6.86015C6.02373 6.80138 6.02632 6.73993 6.04506 6.68255C6.06379 6.62516 6.09797 6.57403 6.14383 6.53478C6.1897 6.49552 6.24549 6.46965 6.30509 6.45999L8.47115 6.14245C8.52507 6.13699 8.57657 6.11732 8.62042 6.08545C8.66425 6.05356 8.69883 6.01062 8.72063 5.961L9.69593 3.99908C9.72203 3.94353 9.76315 3.8964 9.81463 3.86302C9.8661 3.82963 9.9259 3.8113 9.98726 3.81012C10.0486 3.80895 10.1091 3.82495 10.1618 3.85635C10.2145 3.88773 10.2574 3.93325 10.2856 3.98773Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.86271 11.3047L0.714111 16.7582L4.11626 15.8466L5.02786 19.2488L7.75974 14.5171"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1368 11.3047L19.2853 16.7582L15.8832 15.8466L14.9716 19.2488L12.2397 14.5171"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2336_5868">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default index;
