import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListJourney = async (page, size, name) => {
  let params = {
    page: page.toString(),
    size: size,
    name: name,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/lookup/list-admin?${createSearchParams(
    params
  )}&type=MASTER_EVENT_JOURNEY`;
  const response = await CallApi({ url, method: "GET" });

  return response;
};

const addJourney = async (body) => {
  const url = `${API_BASE}/lookup/journey`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const editJourney = async (body) => {
  const url = `${API_BASE}/lookup/journey`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const editStatus = async (body) => {
  const url = `${API_BASE}/lookup/status`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const deleteJourney = async (body) => {
  const url = `${API_BASE}/lookup/journey`;
  const response = await CallApi({ url, method: "DELETE", data: body });
  return response;
};

const getUserParticipantsJourney = async (page,size, name, id) => {
  let params = {
    page: page.toString(),
    size: size,
    name: name,
    idJourney: id
  };
  const url = `${API_BASE}/event/list-user-byjourney?${createSearchParams(
    params
  )}`;
  const response = await CallApi({url, method: "GET"});
  return response;
}

const JourneyRepo = {
  getListJourney,
  addJourney,
  editJourney,
  editStatus,
  deleteJourney,
  getUserParticipantsJourney
};

export default JourneyRepo;
