import React from 'react'
import { Outlet } from "react-router-dom"

const AuthLayout = () => {
  
  return (
    <div>
      <div className="
      bg-transparent
      bg-no-repeat bg-cover bg-center
      md:bg-[url('../public/assets/images/bg-login.svg')]"
    >
        <div className="flex bg-white bg-opacity-90 justify-center items-center h-screen overflow-auto w-full md:w-1/2">
          <div className="text-center w-3/4 md:w-2/3">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout