import React from "react";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import RouteAdm from "../../../../../services/routeAdm";
const ActionBar = ({ onSearch }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-end w-[100%]">
      <Input
        className="w-[300px]"
        placeholder="Search Here"
        onChange={onSearch}
        suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
      />
      <div
        onClick={() => navigate(RouteAdm.addTask)}
        className="bg-red-600 ml-5 w-[150px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
      >
        Add Task
      </div>
    </div>
  );
};

export default ActionBar;
