import { Button, Form, Input, Select, Switch } from "antd";
import { useState, useEffect } from "react";
import ServiceLookUpRepo from "../../../../../repositores/ServiceLookupRepo";
import MasterEventRepo from "../../../../../repositores/adminRepo/MasterEventRepo";
import { CloseCircleOutlined } from "@ant-design/icons";
import { isNullOrEmpty } from "../../../../../utils/utils";

const { Option } = Select;
const FormMasterEventTask = ({
  formType = "add",
  getDataMasterEvent,
  defaultFormValue,
  getStateMount,
}) => {
  let titleModal =
    formType === "add" ? "Add Master Event - Task" : "Edit Master Event - Task";
  let subTitle =
    formType === "add"
      ? "Enter your data below!"
      : "Please enter your data below";
  const [formEvent] = Form.useForm();
  const [listTaskForm, setListTaskForm] = useState([]);
  const [taskNumbers, setTaskNumbers] = useState([1]);
  const [totalPageTask, setTotalPageTask] = useState(-1);
  const [optionsTask, setOptionsTask] = useState([]);
  const [loadingMoreTask, setLoadingMoreTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [mounted, setMounted] = useState(true);
  const [onceFetchOption, setOnceFetchOption] = useState(false);
  const [totalPageType, setTotalPageType] = useState(-1);
  const [optionsType, setOptionsType] = useState([]);
  const [loadingMoreType, setLoadingMoreType] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [pageType, setPageType] = useState(1);
  const [pageTask, setPageTask] = useState(1);

  useEffect(() => {
    const initialTaskNumbers = listTaskForm.map((_, index) => index + 1);
    setTaskNumbers(initialTaskNumbers);
  }, [listTaskForm]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await MasterEventRepo.getQuizHeader(1, 20);
      if (defaultFormValue.masterEventTasks?.length) {
        let tempListTask = [];
        defaultFormValue.masterEventTasks.forEach((value) => {
          if (value?.type?.id) {
            if (value?.type?.name === "Certification") {
              formEvent.setFieldValue(
                "gradeCertificateVerification",
                value?.grade
              );
              defaultFormValue.isCertificateVerification = true;
              formEvent.setFieldValue("isCertificateVerification", true);
            } else if (value?.type?.name === "Attendance") {
              formEvent.setFieldValue(
                "gradeAttandanceVerification",
                value?.grade
              );
              defaultFormValue.isAttandanceVerification = true;
              formEvent.setFieldValue("isAttandanceVerification", true);
            } else {
              const tempType = [...optionsType];
              const indexOptions = optionsType.findIndex(
                (optiontp) => optiontp.id === value?.type?.id
              );
              if (indexOptions < 0) {
                tempType.push(value.type);
                setOptionsType(tempType);
              }
              tempListTask.push({
                grade: value?.grade,
                type: value?.type?.id,
                task: value?.task?.id,
              });
            }
          }
        });
        setListTaskForm(tempListTask);
      }
      let tempOptions = [...optionsTask];
      if (!onceFetchOption && response.data?.data?.content?.length) {
        await handleFetchMoreType(1, 20);
        await response.data?.data?.content?.forEach((typeEvent) => {
          const indexOptions = optionsTask.findIndex(
            (optiontp) => optiontp.id === typeEvent.id
          );
          if (indexOptions < 0) {
            tempOptions.push(typeEvent);
          }
        });
        setOptionsTask(tempOptions);
        setTotalPageTask(response.data?.data?.totalElements);
        setOnceFetchOption(true);
      }

      getStateMount(true);
      setMounted(true);
    };

    fetchData();

    return () => {
      setMounted(true);
    };
  }, [mounted]);

  const handleScrollTask = (event) => {
    event.stopPropagation();

    if (!loadingMoreTask) {
      handleFetchMoreTask(pageTask, 20);
    }
  };

  const handleFetchMoreTask = async (page, limit) => {
    if (optionsTask.length !== totalPageTask) {
      setLoadingMoreTask(true);
      const response = await MasterEventRepo.getQuizHeader(page, limit);

      let tempOptions = optionsTask;
      if (response.data?.data?.content?.length) {
        await response.data?.data?.content?.forEach((typeEvent) => {
          tempOptions.push(typeEvent);
        });
        setOptionsTask(tempOptions);
        setTotalPageTask(response.data?.data?.totalElements);
      }
      setPageTask(page + 1);
      setLoadingMoreTask(false);
    }
  };

  const handleFetchMoreType = async (page, limit) => {
    if (optionsType.length <= totalPageType || totalPageType === -1) {
      setLoadingMoreType(true);
      const response = await ServiceLookUpRepo.getServiceLookupTaskEvent(
        page,
        limit
      );
      let tempOptions = [...optionsType];
      if (response.data?.data?.content?.length) {
        await response.data?.data?.content?.forEach((typeEvent) => {
          const indexOptions = optionsType.findIndex(
            (optiontp) => optiontp.id === typeEvent.id
          );
          if (indexOptions < 0) {
            tempOptions.push(typeEvent);
          }
        });
        setOptionsType(tempOptions);
        setTotalPageType(response.data?.data?.totalElements);
      }
      setPageType(page + 1);
      setLoadingMoreType(false);
    }
  };

  const handleAddTask = () => {
    const newTask = { task: null, type: null, grade: null };
    setListTaskForm([...listTaskForm, newTask]);

    const newTaskNumber = taskNumbers.length + 1;
    setTaskNumbers([...taskNumbers, newTaskNumber]);
  };

  const removeTask = (index) => {
    const updatedTasks = listTaskForm.filter((_, idx) => idx !== index);
    setListTaskForm(updatedTasks);

    const updatedTaskNumbers = taskNumbers.filter(
      (num) => num !== taskNumbers[index]
    );
    setTaskNumbers(updatedTaskNumbers);
  };

  const handleChangeTask = (value, idx) => {
    let tempListTask = listTaskForm;
    tempListTask[idx].task = value;
    setListTaskForm(tempListTask);
    handleChangeValueForm();
  };

  const handleChangeType = (el, idx) => {
    let tempListTask = listTaskForm;
    tempListTask[idx].type = el;
    setListTaskForm(tempListTask);
    handleChangeValueForm();
  };

  const handleChangeGrade = (el, idx) => {
    let tempListTask = listTaskForm;
    tempListTask[idx].grade = el.target.value;
    setListTaskForm(tempListTask);
    handleChangeValueForm();
  };

  const handleChangeValueForm = () => {
    const payloadForm = {
      isCertificateVerification: formEvent.getFieldValue(
        "isCertificateVerification"
      ),
      isAttandanceVerification: formEvent.getFieldValue(
        "isAttandanceVerification"
      ),
      gradeAttandanceVerification: formEvent.getFieldValue(
        "isAttandanceVerification"
      )
        ? formEvent.getFieldValue("gradeAttandanceVerification")
        : 0,
      gradeCertificateVerification: formEvent.getFieldValue(
        "isCertificateVerification"
      )
        ? formEvent.getFieldValue("gradeCertificateVerification")
        : 0,
      masterEventTasks: [],
    };
    if (listTaskForm && listTaskForm.length) {
      listTaskForm.forEach((value) => {
        payloadForm.masterEventTasks.push({
          grade: Number(value.grade),
          type: { id: value.type },
          task: { id: value.task },
          isDeleted: isNullOrEmpty(value.isDeleted) ? false : value.isDeleted,
        });
      });
    }
    getDataMasterEvent(1, payloadForm);
  };

  const handleScrollType = (event) => {
    event.stopPropagation();

    if (!loadingMoreType) {
      handleFetchMoreType(pageType, 20);
    }
  };

  return (
    <>
      <div className="w-full">
        <h3 className="font-semibold text-[20px] ">{titleModal}</h3>
        <p>{subTitle}</p>
        <div className="w-full mt-[20px]">
          <Form
            labelCol={{ flex: "180px" }}
            labelAlign="left"
            form={formEvent}
            colon={false}
          >
            <div className="flex ">
              <div className="w-[60%] font-semibold mb-[10px] text-[16px]">
                <p>Verification</p>
              </div>
              <div className="font-semibold mb-[10px] text-[16px]">
                <p>
                  Grade <span style={{ color: "red" }}>*</span>
                </p>
              </div>
            </div>
            <div className="hidden">
              <Form.Item
                label="Certification Verification"
                className="w-[60%]"
                name="isCertificateVerification"
              >
                <Switch
                  checked={defaultFormValue.isCertificateVerification}
                  onChange={handleChangeValueForm}
                  className="switch-background"
                />
              </Form.Item>
              <div className="w-[15%] ">
                <Form.Item name="gradeCertificateVerification">
                  <Input
                    onChange={handleChangeValueForm}
                    placeholder="%"
                    type="number"
                    max={100}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex ">
              <Form.Item
                label="Attandance Verification"
                className="w-[60%]"
                name="isAttandanceVerification"
              >
                <Switch
                  checked={defaultFormValue.isAttandanceVerification}
                  onChange={handleChangeValueForm}
                  className="switch-background"
                />
              </Form.Item>
              <div className="w-[15%] ">
                <Form.Item name="gradeAttandanceVerification">
                  <Input
                    onChange={handleChangeValueForm}
                    placeholder="%"
                    type="number"
                    max={100}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="mt-[20px]">
              <p className="font-semibold text-[16px]">List of Task</p>
            </div>
            <table className="w-full inline-table border-collapse border border-[#DDDDDD]">
              <thead>
                <tr>
                  <th className="text-center border border-[#DDDDDD] py-2">
                    No
                  </th>
                  <th className="border border-[#DDDDDD] pl-2 text-left">
                    Task
                  </th>
                  <th className="border border-[#DDDDDD] pl-2 text-left">
                    Type
                  </th>
                  <th className="text-center border border-[#DDDDDD]">
                    Grade<span style={{ color: "red" }}>*</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {mounted &&
                  listTaskForm.map((taskForm, idx) => {
                    const uniqueKey = `${idx}-${taskForm.id || ""}`;
                    return isNullOrEmpty(taskForm?.isDeleted) ||
                      !taskForm?.isDeleted ? (
                      <tr key={uniqueKey}>
                        <td className="task-number-list text-center border border-[#DDDDDD]">
                          {taskNumbers[idx]}
                        </td>
                        <td className="border border-[#DDDDDD] col-task-list">
                          <Form.Item initialValue={taskForm.task}>
                            <Select
                              defaultValue={taskForm.task}
                              style={{ width: "100%" }}
                              onChange={(value) => handleChangeTask(value, idx)}
                              placeholder={"Select Task"}
                              dropdownRender={(menu) => (
                                <div onWheel={handleScrollTask}>{menu}</div>
                              )}
                              loading={loadingMoreTask}
                              className={
                                selectedTask === null
                                  ? "placeholder-select"
                                  : ""
                              }
                            >
                              {optionsTask.map((i) => (
                                <Option key={i.id} value={i.id}>
                                  {i.title}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </td>
                        <td
                          className="border border-[#DDDDDD] col-task-type"
                          style={{ width: 150 }}
                        >
                          <Form.Item initialValue={taskForm.type}>
                            <Select
                              style={{ width: "100%" }}
                              onChange={(value) => handleChangeType(value, idx)}
                              placeholder="Select event type"
                              dropdownRender={(menu) => (
                                <div onWheel={handleScrollType}>{menu}</div>
                              )}
                              loading={loadingMoreType}
                              filterOption={false}
                              defaultValue={taskForm.type}
                              defaultActiveFirstOption={false}
                              className={
                                selectedType === null
                                  ? "placeholder-select"
                                  : ""
                              }
                            >
                              {optionsType.map((i) => (
                                <Option key={i.id} value={i.id}>
                                  {i.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </td>
                        <td
                          className="border border-[#DDDDDD] col-task-list"
                          style={{ width: 100 }}
                        >
                          <Form.Item>
                            <Input
                              defaultValue={taskForm.grade}
                              onChange={(value) =>
                                handleChangeGrade(value, idx)
                              }
                              placeholder="%"
                              type="number"
                              max={100}
                            />
                          </Form.Item>
                        </td>
                        <td className="border border-[#DDDDDD] col-task-list">
                          <Button
                            type="link"
                            style={{ color: "red" }}
                            onClick={() => removeTask(idx)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    );
                  })}
              </tbody>
            </table>
            <Button
              onClick={handleAddTask}
              className="mt-[20px] mb-[60px]"
              danger
            >
              Add Task
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default FormMasterEventTask;
