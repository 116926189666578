import { Form, message, Pagination, Modal, Input, Button, Radio, Select, Table, Tag, Card, Flex } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleMasterAdmin from "../../componets_global/TitleMaster";
import deleteIcon from "../../../../assets/icons/delete.png";
import { SearchOutlined } from "@ant-design/icons";
import { Editor } from '@tinymce/tinymce-react';

const { Option } = Select;

const FormQuestionBank = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [formCategory] = Form.useForm();
    const [titleModal, setTitleModal] = useState("Add Category");
    const [formType, setFormType] = useState("add");
    const [detailCategory, setDetailCategory] = useState(undefined);
    const [valueCategoryType, setValueCategoryType] = useState(1);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const editorRef = useRef(null);

    const dummyData = [
        {
            key: 1,
            id: 1,
            parentId: 1,
            question: `<p>What is the output of the following JavaScript code?</p><br/><pre style="background-color: #fbf4f4;padding: 19px; "> console.log(typeof null); </pre>`,
            answers: [
                {
                    id: 1,
                    questionId: 1,
                    text: `"null"`,
                    isTrue: false,
                },
                {
                    id: 2,
                    questionId: 1,
                    text: `"object"`,
                    isTrue: true,
                },
                {
                    id: 3,
                    questionId: 1,
                    text: `"undefined"`,
                    isTrue: false,
                },
                {
                    id: 4,
                    questionId: 1,
                    text: `"number"`,
                    isTrue: false,
                },
                {
                    id: 5,
                    questionId: 1,
                    text: `"function"`,
                    isTrue: false,
                }
            ]
        },
    ]


    const closeModal = (modal = "form") => {
        setIsOpenModal(false)
    };
    const showAddModal = (id) => {
    };

    const onChangeType = (el) => {
        setValueCategoryType(el.target.value);
    };



    const onChangePage = (el) => {

    };

    const handleScrollEventAccount = (event) => {

    };

    useEffect(async () => {

    }, []);

    return (
        <>
            {contextHolder}
            <div className="w-full h-screen px-4 py-5">
                <TitleMasterAdmin>Create Questions</TitleMasterAdmin>

                <div className="mt-[20px] w-full">
                    <Card id="editorTinyMce" className="w-full" title="Question">
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_API_KEY}
                            onInit={(_evt, editor) => editorRef.current = editor}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </Card>
                </div>

                <div className="mt-[10px] mb-[20px] w-full">
                    <Card className="w-full" title="Answer">
                        <Radio.Group className="w-full">
                            <Flex className="mb-[10px] w-full">
                                <Radio value="A">
                                    <div className="pt-[5px]">A</div>
                                </Radio>
                                <Input className="w-full" placeholder="Option A"></Input>
                            </Flex>

                            <Flex className="mb-[10px]">
                                <Radio value="B">
                                    <div className="pt-[5px]">B</div>
                                </Radio>
                                <Input placeholder="Option B"></Input>
                            </Flex>

                            <Flex className="mb-[10px]">
                                <Radio value="C">
                                    <div className="pt-[5px]">C</div>
                                </Radio>
                                <Input placeholder="Option C"></Input>
                            </Flex>

                            <Flex className="mb-[10px]">
                                <Radio value="D">
                                    <div className="pt-[5px]">D</div>
                                </Radio>
                                <Input placeholder="Option D"></Input>
                            </Flex>

                            <Flex className="mb-[10px]">
                                <Radio value="E">
                                    <div className="pt-[5px]">E</div>
                                </Radio>
                                <Input placeholder="Option E"></Input>
                            </Flex>
                        </Radio.Group>
                    </Card>
                </div>

                <div className="pb-[30px] flex gap-4">
                    <div
                        className="bg-white flex w-[100%] border-2 h-[50px] mt-10 rounded-[10px] justify-center items-center cursor-pointer"
                    >

                        <span className="ml-2 text-sm"> Back</span>
                    </div>
                    <div
                        className=" bg-red-600 flex w-[100%] border-2 border-dashed h-[50px] mt-10 rounded-[10px] justify-center items-center cursor-pointer text-white"
                    >
                        <img
                            src="/assets/icons/add.svg"
                            alt=""
                            className="cursor-pointer w-4 h-4"
                        />
                        <span className="ml-2 text-sm"> Add New Question</span>
                    </div>

                </div>

            </div>

        </>
    );
};

const ActionBarSearchOnly = ({
    buttonTitle,
    actionButton,
    onSearch,
    placeholder,
}) => {
    return (
        <Input
            className="w-full lg:w-[200px] max-w-[400px]"
            placeholder={placeholder}
            suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
            onChange={onSearch}
        />
    );
};

const ActionBarButtonOnly = ({ buttonTitle, actionButton }) => {
    return (
        <div className=" flex justify-center">
            {actionButton && (
                <div
                    onClick={actionButton}
                    className="bg-red-600  w-[150px] h-[30px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
                >
                    {buttonTitle}
                </div>
            )}
        </div>
    );
};

export default FormQuestionBank;
