import apiClient from "../../../../shared/api/api.client";

export class QuizService {
  get = {
    async quizDetail({ queryKey }) {
      const params = queryKey[1];
      console.log("riki aldi=",params)
      try {
        return apiClient.instance.get(
          `/master-event-task/task-user/${params.quizId}/quiz_questions/event/${params.eventId}`
        ).then(res => res.data);
      } catch (error) {
        throw error;
      }
    },
  };

  post = {
    async quizAnswers(answers, taskId, eventId) {
      try {
        return apiClient.instance.post(
          `/event-participant-task/${taskId}/event/${eventId}`,
          { answers }
        ).then(res => res.data);
      } catch (error) {
        throw error;
      }
    },
  };
}
