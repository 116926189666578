import React, { useContext, useEffect, useState } from "react";
import ActionBarCertificate from "./components/ActionBar";
import ContentCertificate from "./components/Content";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTalentCertificateIssued,
  getAllTalentCertificateRevoked,
  changeState,
  resetPageProperty,
  getServiceLookup,
} from "../../../features/certificateTalentSlice";
import LayoutCertificate from "./CertificateLayout";
import { TalentHelperContext } from "../../../components/talentPage/TalentContext";

const CertificateTalent = () => {
  const {
    certificatesIssued,
    certificatesRevoked,
    currentPage,
    formSearch,
    types,
    activeTab,
    isLoading,
    isSuccess,
    totalItem,
    maxSizeData,
  } = useSelector((state) => state.certificateTalent);
  const { selectMenu } = useContext(TalentHelperContext);

  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState("all");

  useEffect(() => {
    selectMenu("certificates");
  }, []);

  useEffect(() => {
    setSelectedType("all");
    getAllCertificate(currentPage);
    dispatch(getServiceLookup());
  }, [activeTab]);

  useEffect(() => {
    getAllCertificate();
  }, [selectedType, currentPage]);

  const changeValueState = (nameState, value) => {
    dispatch(changeState({ stateProp: nameState, valueProp: value }));
    dispatch(changeState({ stateProp: "currentPage", valueProp: 0 }));
  };

  useEffect(() => {
    resetPageProperty();
    getAllCertificate();
  }, [formSearch]);

  const clickFilterType = (input) => {
    setSelectedType(input);
    dispatch(changeState({ stateProp: "currentPage", valueProp: 0 }));
    if (input === "all") getAllCertificate(currentPage);
  };

  const getAllCertificate = () => {
    let params = { current_page: currentPage };
    if (formSearch !== "") params.keyword = formSearch;
    if (selectedType !== "all") params.type = selectedType;
    if (activeTab === "certificate") {
      dispatch(getAllTalentCertificateIssued(params));
    } else if (activeTab === "revoked") {
      dispatch(getAllTalentCertificateRevoked(params));
    }
  };

  const handlePageChange = (page) => {
    const newPage = page - 1;
    dispatch(changeState({ stateProp: "currentPage", valueProp: newPage }));
  };

  return (
    <LayoutCertificate>
      <ActionBarCertificate handleInput={changeValueState} />
      <ContentCertificate
        issuedData={certificatesIssued}
        revokedData={certificatesRevoked}
        currentPage={currentPage + 1}
        totalItem={totalItem}
        maxSizeData={maxSizeData}
        onChange={handlePageChange}
        certificateTypes={types}
        handleClickFilter={clickFilterType}
        filterActive={selectedType}
        handleInput={changeValueState}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </LayoutCertificate>
  );
};

export default CertificateTalent;
