import CallApi from "../../services/Request-Helper";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getUserManagement = async (page = 0, size = 10, name = "") => {
  let url = `${API_BASE}/user/list-user?page=${page}&size=${size}`;
  if (name !== "") {
    url += `&name=${name}`;
  }

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getUserPending = async (page = 0, size = 10) => {
  const url = `${API_BASE}/user/list-user-pending?page=${page}&size=${size}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const adminInviteUser = async (data) => {
  const url = `${API_BASE}/register/add`;

  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const adminResendInvite = async (data) => {
  const url = `${API_BASE}/register/send-email`;

  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const adminEditUser = async (data) => {
  const url = `${API_BASE}/user`;

  const response = await CallApi({ url, method: "PUT", data });
  return response;
};

const getExcelTemplate = async () => {
  const url = `${API_BASE}/user/import-csv/user`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const importCsvUser = async (data) => {
  const url = `${API_BASE}/user/import-csv/user`;

  const response = await CallApi({ url, method: "POST", data, type: true });
  return response;
};

const addTeamLeader = async (idUser) => {
  const url = `${API_BASE}/user/add-tl`;
  const data = { idUser: [idUser] };

  const response = await CallApi({ url, method: "PUT", data });
  return response;
};

const revokeTeamLeader = async (idUser) => {
  const url = `${API_BASE}/user/revoke-tl`;
  const data = { idUser: [idUser] };

  const response = await CallApi({ url, method: "PUT", data });
  return response;
};

const MasterUserManagementRepo = {
  getUserManagement,
  getUserPending,
  adminInviteUser,
  adminResendInvite,
  adminEditUser,
  getExcelTemplate,
  importCsvUser,
  addTeamLeader,
  revokeTeamLeader,
};

export default MasterUserManagementRepo;
