import React, { useState, useEffect, useRef } from "react";
import TableEvent from "./components/TableEvent";
import { Input, Button, Pagination, Modal, Select, message } from "antd";
import { SearchOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import MasterEventRepo from "../../../../repositores/adminRepo/MasterEventRepo";
import FormMasterEvent from "./components/FormMasterEvent";
import FormMasterEventTask from "./components/FormMasterEventTask";
import { isNullOrEmpty } from "../../../../utils/utils";

const defaultForm = {
  name: null,
  grade: null,
  gc: null,
  xp: null,
  description: null,
  preReiqusition: [],
  image: null,
  isCertificateVerification: false,
  gradeCertificateVerification: null,
  isAttandanceVerification: false,
  gradeAttandanceVerification: null,
  eventType: { id: null },
  journey: { id: null },
  certificate: { id: null },
  masterEventTasks: [],
};

const ListMasterEvent = () => {
  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [loadingMoreJourney, setLoadingMoreJourney] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [page, setPage] = useState(1);
  const [pageJourney, setPageJourney] = useState(1);
  const [optionsJourney, setOptionsJourney] = useState([]);
  const [totalElementJourney, setTotalElementJourney] = useState(-1);
  const [limit, setLimit] = useState(5);
  const [totalItem, setTotalItem] = useState(0);
  const [searchEvent, setSearchEvent] = useState(undefined);
  const [selectedMasterId, setSelectedMasterId] = useState(undefined);

  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [counterForm, setCounterForm] = useState(1);

  const [showModalForm, setShowModalForm] = useState(false);

  const [stepForm, setStepForm] = useState(0);
  const [formMasterEvent, setFormMasterEvent] = useState({ ...defaultForm });
  const [formType, setFormType] = useState("add");

  const [uploadFile, setUploadFile] = useState(undefined);
  const [disabledStepOne, setDisabledStepOne] = useState(true);
  const [formMounted, setFormMounted] = useState(false);
  const isMounted = useRef(true);

  const [fileNameExist, setFileNameExist] = useState(undefined);

  useEffect(() => {
    isMounted.current = true;
    if (selectedMasterId !== undefined) {
      fetchData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [page, toggleRefresh, selectedMasterId]);

  useEffect(() => {}, [formMasterEvent]);

  useEffect(() => {
    handleFetchMoreJourney(1, 10);
  }, []);

  async function fetchData() {
    try {
      setLimit(5);
      setLoading(true);
      const response = await MasterEventRepo.getListMasterEvent(
        page,
        limit,
        searchEvent,
        selectedMasterId
      );
      if (isMounted.current) {
        if (!response.data.data) throw new Error();
        setDataTable(response?.data?.data?.content);
        setTotalItem(response?.data?.data?.totalElements);
        setLoading(false);
      }
    } catch (error) {
      if (isMounted.current) {
        messageApi.open({
          type: "error",
          content: "Failed to get all Data master event",
        });
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [page]);

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeSearch = (el) => {
    setSearchEvent(el.target.value);
    setPage(1);
  };

  const handleSearch = async (value) => {
    setLoadingMoreJourney(true);

    if (value === "") {
      await handleFetchMoreJourney(1, 10);
    } else {
      const response = await MasterEventRepo.getListJourney(0, 10, value);
      const filteredJourney = response.data?.data?.content || [];
      setOptionsJourney(filteredJourney);
      setPage(1);
    }
    setLoadingMoreJourney(false);
  };

  const handleChangeJourney = (value) => {
    setSelectedMasterId(value);
    handleFetchMoreJourney(1, 10);
  };

  const handleClear = () => {
    setSelectedMasterId(undefined);
    fetchData();
  };

  useEffect(() => {
    let delayDebounceFn;

    if (!searchEvent) {
      if (searchEvent?.length > 0) {
        delayDebounceFn = setTimeout(() => {
          setPage(() => 1);
          fetchData();
        }, 1000);
      }
    } else {
      setPage(() => 1);
      fetchData();
    }

    return () => clearTimeout(delayDebounceFn);
  }, [searchEvent]);

  const refreshForm = () => {
    setFileNameExist(undefined);
    setFormMasterEvent({ ...defaultForm });
    setStepForm(0);
    let tempCounter = counterForm + 1;
    setCounterForm(tempCounter);
    setShowModalForm(false);
    setFormMounted(false);
  };

  const handleDeleteCancel = () => {
    setOpenModalDelete(false);
  };

  const contentRender = () => {
    return (
      <>
        <table className="w-full table-auto border-[2px] ">
          <thead>
            <tr>
              <th className="px-2" colSpan={3}>
                {deleteId?.name}
              </th>
            </tr>
          </thead>
          <tbody>
            {deleteId?.masterEventTasks.length ? (
              deleteId?.masterEventTasks?.map((task, idx) => (
                <tr key={task.id + idx}>
                  <td className="px-2">{idx + 1}</td>
                  <td>{task.task?.title}</td>
                  <td className="px-2">{task.type?.name}</td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const modalDelete = () => {
    return (
      <Modal
        open={openModalDelete}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        width={350}
        footer={null}
        closable={false}
        keyboard={false}
      >
        <div className="flex items-center gap-2">
          <ExclamationCircleFilled
            style={{ fontSize: "20px", color: "#faad14" }}
          />
          <p className="text-black text-[16px] font-medium">
            Are you sure delete this Event ?
          </p>
        </div>
        {contentRender()}
        <div className="flex justify-end mt-5 gap-[10px]">
          <Button onClick={() => setOpenModalDelete(false)}>No</Button>
          <Button onClick={handleDeleteConfirm} type="primary" danger>
            Yes
          </Button>
        </div>
      </Modal>
    );
  };

  const showDeleteConfirm = (id) => {
    setOpenModalDelete(true);
    setDeleteId(id);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    const reponseDelete = await MasterEventRepo.deleteMasterEvent(deleteId.id);
    if (reponseDelete.data.status === 200) {
      messageApi.open({
        type: "success",
        content:
          reponseDelete?.data?.data?.message || reponseDelete?.data?.message,
      });
    } else {
      messageApi.open({
        type: "error",
        content:
          reponseDelete?.data?.data?.message || reponseDelete?.data?.message,
      });
    }
    setToggleRefresh(!toggleRefresh);
    setLoading(false);
    setOpenModalDelete(false);
  };

  const showModalAddEvent = () => {
    refreshForm({ ...defaultForm });
    let tempCounter = counterForm + 1;
    setStepForm(0);
    setCounterForm(tempCounter);
    setFormMounted(false);
    setFormType("add");
    setShowModalForm(true);
  };

  const handleNextStep = () => {
    let tempStep = stepForm;
    setStepForm(tempStep + 1);
  };

  const handleBackStep = () => {
    let tempStep = stepForm;
    setStepForm(tempStep - 1);
  };

  const getDataMasterEvent = (step, data) => {
    let tempForm = { ...formMasterEvent };
    switch (step) {
      case 1:
        tempForm.isCertificateVerification = data.isCertificateVerification;
        tempForm.gradeCertificateVerification = Number(
          data.gradeCertificateVerification
        );
        tempForm.isAttandanceVerification = data.isAttandanceVerification;
        tempForm.gradeAttandanceVerification = Number(
          data.gradeAttandanceVerification
        );
        tempForm.masterEventTasks = data.masterEventTasks;
        break;

      default:
        tempForm.name = data.getFieldValue("name");
        tempForm.eventType = { id: data.getFieldValue("eventType") };
        tempForm.image = data.getFieldValue("image");
        tempForm.grade = Number(data.getFieldValue("grade"));
        tempForm.gc = Number(data.getFieldValue("gc"));
        tempForm.xp = Number(data.getFieldValue("xp"));
        tempForm.journey = { id: data.getFieldValue("journey") };
        tempForm.certificate = { id: data.getFieldValue("certificate") };
        tempForm.preReiqusition = data.getFieldValue("preReiqusition");
        tempForm.description = data.getFieldValue("description");
        if (
          isNullOrEmpty(data.getFieldValue("name")) ||
          isNullOrEmpty(data.getFieldValue("eventType")) ||
          isNullOrEmpty(data.getFieldValue("grade")) ||
          isNullOrEmpty(data.getFieldValue("gc")) ||
          isNullOrEmpty(data.getFieldValue("xp")) ||
          isNullOrEmpty(data.getFieldValue("journey")) ||
          isNullOrEmpty(data.getFieldValue("description")) ||
          (!isNullOrEmpty(data.getFieldValue("description")) &&
            (data.getFieldValue("description").length <= 100 ||
              data.getFieldValue("description").length > 500))
        ) {
          setDisabledStepOne(true);
        } else {
          setDisabledStepOne(false);
        }
        break;
    }
    setFormMasterEvent(tempForm);
  };

  const submitAddMasterEvent = async () => {
    setDisableSubmit(true);
    setLoading(true);
    let tempForm = { ...formMasterEvent };
    let counterGrade = 0;
    if (tempForm.isCertificateVerification) {
      counterGrade = +Number(tempForm.gradeCertificateVerification);
    }
    if (tempForm.isAttandanceVerification) {
      counterGrade = +Number(tempForm.gradeAttandanceVerification);
    }

    // if (
    //   tempForm.masterEventTasks.length == 0 ||
    //   isNullOrEmpty(tempForm.masterEventTasks)
    // ) {
    //   setLoading(false);
    //   setDisableSubmit(false);
    //   AlertUtility.ErrorAlert({ title: "Task is required" });
    //   return;
    // }

    tempForm.masterEventTasks.forEach((value) => {
      counterGrade = +Number(value.grade);
    });
    // if(counterGrade !== 100) {
    //   setLoading(false)
    //   setDisableSubmit(false)
    //   AlertUtility.ErrorAlert({title: "Total Grade from list task or/and Certificate Verification or/and Attandance Verification Must Equals with 100"})
    //   return
    // }
    setLimit(5);
    if (uploadFile) {
      const responseUpload = await MasterEventRepo.uploadImage(
        uploadFile,
        fileNameExist
      );
      if (responseUpload?.data?.data?.fileName) {
        tempForm.image = responseUpload?.data?.data?.fileName;
        setFormMasterEvent(tempForm);
      }
    }
    let responseApi = null;

    let tasks = tempForm?.masterEventTasks?.filter(
      (item) => item.type?.id !== null && item.task?.id !== null
    );
    tempForm.masterEventTasks = [...tasks];

    if (formType === "add") {
      responseApi = await MasterEventRepo.addMasterEvent(tempForm);
    } else {
      responseApi = await MasterEventRepo.updateMasterEvent(tempForm);
      setFormType("add");
    }

    if (isMounted.current) {
      setFileNameExist(undefined);
      setDisabledStepOne(true);
      setShowModalForm(false);
      setLoading(false);
    }

    if (responseApi?.data?.status === 200) {
      messageApi.open({
        type: "success",
        content: responseApi?.data?.data?.message || responseApi?.data?.message,
      });
      setFormMounted(false);
      setStepForm(0);
      setFormMasterEvent({ ...defaultForm });
      const responseTable = await MasterEventRepo.getListMasterEvent(
        page,
        limit,
        searchEvent,
        selectedMasterId
      );
      setDataTable(responseTable.data.data?.content);
      setTotalItem(responseTable.data.data?.totalElements);
      setLoading(false);
      setDisableSubmit(false);
    } else {
      messageApi.open({
        type: "error",
        content: responseApi?.data?.data?.message || responseApi?.data?.message,
      });
      setLoading(false);
      setDisableSubmit(false);
    }
  };

  const openEditMasterEvent = (selectedData) => {
    setFileNameExist(selectedData.image);
    let tempCounter = counterForm + 1;
    setCounterForm(tempCounter);
    setFormMounted(false);
    setStepForm(0);
    setFormType("edit");
    setFormMasterEvent(selectedData);
    setShowModalForm(true);
    setDisabledStepOne(false);
  };

  const handleFetchMoreJourney = async (page, limit) => {
    if (
      optionsJourney.length < totalElementJourney ||
      totalElementJourney === -1
    ) {
      setLoadingMoreJourney(true);
      const response = await MasterEventRepo.getListJourney(page - 1, limit);
      const tempOptions = response?.data?.data?.content || [];
      setOptionsJourney((prevOptions) => [...prevOptions, ...tempOptions]);
      setTotalElementJourney(response.data?.data?.totalElements);
      setPageJourney(page + 1);
      setLoadingMoreJourney(false);
    }
  };

  const handleScrollJourney = (event) => {
    event.stopPropagation();

    if (!loadingMoreJourney) {
      handleFetchMoreJourney(pageJourney, 10);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5" id="masterEventList">
        <h4 className="text-[32px] font-bold">Master Event</h4>
        <div className="flex flex-col mb-4 justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <Select
              onChange={handleChangeJourney}
              placeholder="Select Journey"
              dropdownRender={(menu) => (
                <div onWheel={handleScrollJourney}>{menu}</div>
              )}
              value={selectedMasterId}
              onSearch={handleSearch}
              loading={loadingMoreJourney}
              showSearch
              allowClear
              onClear={handleClear}
              filterOption={false}
              optionFilterProp="children"
              defaultActiveFirstOption={false}
            >
              {optionsJourney.map((item, idx) => (
                <Option key={item.id + "-" + idx} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Input
              className="w-[300px]"
              placeholder="Search"
              onChange={onChangeSearch}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
          </div>
          <Button
            onClick={() => showModalAddEvent()}
            type="primary"
            danger
            className="bg-[#DD0000]"
            style={{ backgroundColor: "#DD0000" }}
          >
            Add Master Event
          </Button>
        </div>
        <TableEvent
          isLoading={loading}
          dataTable={dataTable}
          selectDelete={showDeleteConfirm}
          selectEdit={openEditMasterEvent}
        />
        <div className="w-full flex justify-center my-2 pt-2 pb-10">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
            showSizeChanger={false}
          />
        </div>
        {modalDelete()}
      </div>
      <Modal
        forceRender
        onCancel={refreshForm}
        open={showModalForm}
        footer={
          <>
            <div className={stepForm > 0 ? "flex justify-between" : ""}>
              {stepForm > 0 ? (
                formMounted && (
                  <Button key="back" danger onClick={handleBackStep}>
                    Previous
                  </Button>
                )
              ) : (
                <></>
              )}

              {stepForm < 1
                ? formMounted && (
                    <button
                      type="primary"
                      onClick={handleNextStep}
                      className={`${
                        disabledStepOne
                          ? "bg-slate-400 cursor-not-allowed"
                          : "bg-[#DD0000]"
                      } p-1 rounded-[5px] w-[55px] text-white`}
                      disabled={disabledStepOne}
                    >
                      Next
                    </button>
                  )
                : formMounted && (
                    <Button
                      disabled={disableSubmit}
                      type="primary"
                      onClick={submitAddMasterEvent}
                      style={{ backgroundColor: "#DD0000" }}
                    >
                      Submit
                    </Button>
                  )}
            </div>
          </>
        }
      >
        {stepForm === 0 || stepForm === 2 ? (
          <FormMasterEvent
            key={"step1" + counterForm}
            defaultFormValue={formMasterEvent}
            refreshForm={showModalForm}
            getDataMasterEvent={(step, data) => getDataMasterEvent(step, data)}
            setFileUpload={(file) => setUploadFile(file)}
            formType={formType}
            getStateMount={(val) => setFormMounted(val)}
          />
        ) : (
          <></>
        )}
        {stepForm === 1 || stepForm === 2 ? (
          <FormMasterEventTask
            key={"step2" + counterForm}
            defaultFormValue={formMasterEvent}
            getDataMasterEvent={(step, data) => getDataMasterEvent(step, data)}
            formType={formType}
            getStateMount={(val) => setFormMounted(val)}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default ListMasterEvent;
