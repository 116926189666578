import React, { useEffect, useState } from "react";
import SkeletonTable from "../../../components/general/SkeletonTable";
import { Modal, Pagination, message, Button, Form, Select, DatePicker, Input, Radio } from "antd";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";
import TableContent from "../componets_global/TableContent";
import { generateColumn } from "./components/tableConfig";
import SearchBar from "../componets_global/SearchBar";
import HistoryClientRepo from "../../../repositores/adminRepo/HistoryClientRepo";
import dayjs from "dayjs";
import AlertUtility from "../../../components/Alert/Alert";
import InterviewClientRepo from "../../../repositores/adminRepo/InterviewClientRepo";
import MasterUserManagementRepo from "../../../repositores/adminRepo/UserManagement";


const { RangePicker } = DatePicker;
const { Option } = Select;

const HistoryClient = () => { 
    const [formClientHistory] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [messageAlert, setMessageAlert] = useState({
        message: "",
        status: "false",
    });
    const [loading, setLoading] = useState(true);
    const [typeForm, setTypeForm] = useState('add')

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalItem, setTotalItem] = useState(0)
    const [listData, setListData] = useState([])
    const [refreshCounter, setRefreshCounter] = useState(1);

    const [searchTalent, setSearchTalent] = useState('')
    const [bounchPage, setBounchPage] = useState(undefined)

    const [loadingMoreClient, setLoadingMoreClient] = useState(false);
    const [pageClient, setPageClient] = useState(1);
    const [totalPageClient, setTotalPageClient] = useState(100);
    const [optionsClient, setOptionsClient] = useState([]);
    const [totalElementClient, setTotalElementClient] = useState(-1);
    const [onSearchOptionClient, setOnSearchOptionClient] = useState(false)
    const [searchOptionClient, setSearchOptionClient] = useState("")
    const [debounceOptionClient, setDebounceOptionClient] = useState(undefined)
    const [debounceScrollOptionClient, setDebounceScrollOptionClient] = useState(undefined)

    const [loadingMoreRole, setLoadingMoreRole] = useState(false);
    const [pageRole, setPageRole] = useState(1);
    const [totalPageRole, setTotalPageRole] = useState(100);
    const [optionsRole, setOptionsRole] = useState([]);
    const [totalElementRole, setTotalElementRole] = useState(-1);
    const [onSearchOptionRole, setOnSearchOptionRole] = useState(false)
    const [searchOptionRole, setSearchOptionRole] = useState("")
    const [debounceOptionRole, setDebounceOptionRole] = useState(undefined)
    const [debounceScrollOptionRole, setDebounceScrollOptionRole] = useState(undefined)

    const [loadingMoreTalent, setLoadingMoreTalent] = useState(false);
    const [pageTalent, setPageTalent] = useState(0);
    const [totalPageTalent, setTotalPageTalent] = useState(100);
    const [optionsTalent, setOptionsTalent] = useState([]);
    const [totalElementTalent, setTotalElementTalent] = useState(-1);
    const [onSearchOptionTalent, setOnSearchOptionTalent] = useState(false)
    const [searchOptionTalent, setSearchOptionTalent] = useState("")
    const [debounceOptionTalent, setDebounceOptionTalent] = useState(undefined)
    const [debounceScrollOptionTalent, setDebounceScrollOptionTalent] = useState(undefined)

    const [show, setShow] = useState(false)
    const [selectedData, setSelectedData] = useState({})


    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCounter])
    useEffect(() => {
        setPageClient(1)
        handleFetchMoreClient(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOptionClient])
    useEffect(() => {
        setPageRole(1)
        handleFetchMoreRole(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOptionRole])
    useEffect(() => {
        setPageTalent(0)
        handleFetchMoreTalent(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOptionTalent])

    async function fetchData() {
        setLimit(10);
        try {
          let response = await HistoryClientRepo.getClientListHistory(
            page,
            limit,
            undefined,
            searchTalent
          );
          if (response?.data?.data?.content) {
            setListData(response?.data?.data?.content);
            setTotalItem(response?.data?.data?.totalElements);
          } else {
            setListData([]);
            setTotalItem(0);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: "Failed to get Data event",
          });
        }
    }

    const onChangeSearch = (el) => {
        setLoading(true);
        setSearchTalent(el.target.value);
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setPage(1);
            setRefreshCounter((prevValue) => prevValue + 1)
        }, 2000))
    }
    const onChangePage = (page) => {
        setLoading(true);
        setPage(page)
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setRefreshCounter((prevValue) => prevValue + 1)
        }, 1000))
    }
    
    const onEdit = (data) => {
        setTypeForm('edit')
        setSelectedData(data)
        formClientHistory.setFieldValue('talent', data.userRole.user.fullname)
        formClientHistory.setFieldValue('client', data.clientCompany.name)
        formClientHistory.setFieldValue('role', data.userRole.roleCompany.name)
        formClientHistory.setFieldValue('status', data.status)
        formClientHistory.setFieldValue('date', [
            dayjs(data.startDate, "YYYY/MM/DD"),
            dayjs(data.endDate, "YYYY/MM/DD"),
        ])
        setShow(true)
    }
    const onDelete = (data) => {
        AlertUtility.confirmAlert({
            text: `You want to delete ? `,
            confirmText: "Yes",
            onConfirm: () => {
                handleDelete(data.id)
            },
        });
    }

    const handleScrollClient = (event) => {
        let pos = (event.target.scrollTop || event.target.scrollTop) + event.target.offsetHeight;
        let max = 32;
        if(pos === max) {
            if (
                pageClient - 1 <= totalPageClient &&
                optionsClient?.length !== totalElementClient
            ) {
                setLoadingMoreClient(true);
            }
            clearTimeout(debounceScrollOptionClient)
            setDebounceScrollOptionClient(setTimeout(() => {
                event.stopPropagation();
                if (!onSearchOptionClient) {
                  handleFetchMoreClient(pageClient);
                }
            }, 2000))
        } 
    };

    const handleFetchMoreClient = async (page) => {
        if (
            page - 1 <= totalPageClient &&
            optionsClient?.length !== totalElementClient
        ) {
            // setLoadingMoreClient(true);
            try {
                const response = await InterviewClientRepo.getListAccount(page - 1, 20, searchOptionClient);
                let tempOptions = optionsClient;
                if (response.data?.data?.content?.length) {
                await response.data?.data?.content?.forEach((typeEvent) => {
                    tempOptions.push(typeEvent);
                });
                setOptionsClient(tempOptions);
                setTotalPageClient(response.data?.data?.totalPages);
                setTotalElementClient(response.data?.data?.totalElements);
                }
                setPageClient(page + 1);
                setLoadingMoreClient(false);
                setOnSearchOptionClient(false);
            } catch (error) {
                AlertUtility.ErrorAlert({ title: "Failed Get Data" });
                setLoadingMoreClient(false);
            }
        } 
    };

    const doSearchClient = (input) => {
        setOnSearchOptionClient(true)
        // setLoadingMoreClient(true)
        clearTimeout(debounceOptionClient)
        setDebounceOptionClient(setTimeout(() => {
            setOptionsClient([])
            setSearchOptionClient(input)
        }, 2000))
    }

    const handleScrollRole = (event) => {
        let pos = (event.target.scrollTop || event.target.scrollTop) + event.target.offsetHeight;
        let max = 32;
        if (
            pageRole - 1 <= totalPageRole &&
            optionsRole?.length !== totalElementRole
        ) {
            setLoadingMoreRole(true);
        }
        if(pos === max) {
            clearTimeout(debounceScrollOptionRole)
            setDebounceScrollOptionRole(setTimeout(() => {
                event.stopPropagation();
                if (!onSearchOptionRole) {
                    handleFetchMoreRole(pageRole);
                }
            }, 2000))
        } 
    };

    const handleFetchMoreRole = async (page) => {
        if (
            page - 1 <= totalPageRole &&
            optionsRole?.length !== totalElementRole
        ) {
            // setLoadingMoreClient(true);
            try {
                const response = await InterviewClientRepo.getListRolesEmployee(page - 2, 20, searchOptionRole);
                let tempOptions = optionsRole;
                if (response.data?.data?.content?.length) {
                await response.data?.data?.content?.forEach((typeEvent) => {
                    tempOptions.push(typeEvent);
                });
                setOptionsRole(tempOptions);
                setTotalPageRole(response.data?.data?.totalPages);
                setTotalElementRole(response.data?.data?.totalElements);
                }
                setPageRole(page + 1);
                setLoadingMoreRole(false);
                setOnSearchOptionRole(false);
            } catch (error) {
                AlertUtility.ErrorAlert({ title: "Failed Get Data" });
                setLoadingMoreRole(false);
            }
        } 
    };

    const doSearchRole = (input) => {
        setOnSearchOptionRole(true)
        // setLoadingMoreRole(true)
        clearTimeout(debounceOptionRole)
        setDebounceOptionRole(setTimeout(() => {
            setOptionsRole([])
            setSearchOptionRole(input)
        }, 2000))
    }


    const handleScrollTalent = (event) => {
        let pos = (event.target.scrollTop || event.target.scrollTop) + event.target.offsetHeight;
        let max = 32;
        if(pos === max) {
            if (
                pageTalent - 1 <= totalPageTalent &&
                optionsTalent?.length !== totalElementTalent
            ) {
                setLoadingMoreTalent(true);
            }
            clearTimeout(debounceScrollOptionTalent)
            setDebounceScrollOptionTalent(setTimeout(() => {
                event.stopPropagation();
                if (!onSearchOptionTalent) {
                  handleFetchMoreTalent(pageTalent);
                }
            }, 2000))
        } 
    };

    const handleFetchMoreTalent = async (page) => {
        if (
            page - 1 <= totalPageTalent &&
            optionsTalent?.length !== totalElementTalent
        ) {
            // setLoadingMoreClient(true);
            try {
                const response = await MasterUserManagementRepo.getUserManagement(page, 20, searchOptionTalent);
                let tempOptions = optionsTalent;
                if (response.data?.data?.content?.length) {
                await response.data?.data?.content?.forEach((typeEvent) => {
                    tempOptions.push(typeEvent);
                });
                setOptionsTalent(tempOptions);
                setTotalPageTalent(response.data?.data?.totalPages);
                setTotalElementTalent(response.data?.data?.totalElements);
                }
                setPageTalent(page + 1);
                setLoadingMoreTalent(false);
                setOnSearchOptionTalent(false);
            } catch (error) {
                AlertUtility.ErrorAlert({ title: "Failed Get Data" });
                setLoadingMoreTalent(false);
            }
        } 
    };

    const doSearchTalent = (input) => {
        setOnSearchOptionTalent(true)
        // setLoadingMoreClient(true)
        clearTimeout(debounceOptionTalent)
        setDebounceOptionTalent(setTimeout(() => {
            setOptionsTalent([])
            setSearchOptionTalent(input)
        }, 2000))
    }

    const closeModal = () => {
        setShow(false)
        formClientHistory.resetFields()
    }
    const handleSubmit = () => {
        formClientHistory.validateFields().then(() => {
            let payload = {
                user: {
                    id: formClientHistory.getFieldValue('talent')
                },
                clientCompany: {
                    id: formClientHistory.getFieldValue('client')
                },
                userRole: {
                    id: formClientHistory.getFieldValue('role')
                },
                startDate: dayjs(formClientHistory.getFieldValue('date')[0]).format("YYYY-MM-DD"),
                endDate: dayjs(formClientHistory.getFieldValue('date')[1]).format("YYYY-MM-DD")
            }
            if(typeForm === 'edit') {
                payload = {
                    clientCompany: {
                        id: selectedData.clientCompany.id
                    },
                    userRole: {
                        id: selectedData.userRole.id
                    },
                    startDate: selectedData.startDate,
                    endDate: selectedData.endDate,
                    status: formClientHistory.getFieldValue('status')
                }
            } 
            handleSubmitClientHistory(payload)
        }).catch(() => {})
    }

    const handleSubmitClientHistory = async (data) => {
        if(typeForm === 'edit') {
            await HistoryClientRepo.updateHistoryClient(data).then((result) => {
                if (result.data.status !== 200) {
                  messageApi.open({
                    type: "error",
                    content:
                      result.data.status !== 500
                        ? result.data.message
                        : result.data.error,
                  });
                  return;
                }
                setRefreshCounter((prev) => prev+1)
                AlertUtility.SuccessAlert({title: "Successfully update client history"})
              })
              .catch((error) => {
                AlertUtility.ErrorAlert({ title: error.response?.message | 'Error when add client history'});
              })
              .finally(() => {
                setLoading(false);
              });
        } else {
            await HistoryClientRepo.addHistoryClient(data).then((result) => {
              if (result.data.status !== 200) {
                messageApi.open({
                  type: "error",
                  content:
                    result.data.status !== 500
                      ? result.data.message
                      : result.data.error,
                });
                return;
              }
              setRefreshCounter((prev) => prev+1)
              AlertUtility.SuccessAlert({title: "Successfully add client history"})
            })
            .catch((error) => {
              AlertUtility.ErrorAlert({ title: error.response?.message | 'Error when add client history'});
            })
            .finally(() => {
              setLoading(false);
            });
        }
        setShow(false)
        formClientHistory.resetFields()
    }

    const handleDelete = async (id) => {
        await HistoryClientRepo.deleteHistoryClient(id).then((result) => {
          if (result.data.status !== 200) {
            messageApi.open({
              type: "error",
              content:
                result.data.status !== 500
                  ? result.data.message || result.data.error
                  : result.data.error,
            });
            return;
          }
          setRefreshCounter((prev) => prev+1)
          AlertUtility.SuccessAlert({title: "Successfully delete client history"})
        })
        .catch((error) => {
          AlertUtility.ErrorAlert({ title: error.response?.message | 'Error when delete client history'});
        })
        .finally(() => {
          setLoading(false);
        });
    }
    

    return (<>
        {contextHolder}
        <AlertMiniPopUp
            messageAlert={messageAlert.message}
            isSuccess={messageAlert.status}
        />
        <div className="w-full h-screen px-4 py-5">
            <TitleMasterAdmin>Client Histories</TitleMasterAdmin>
            <div className="flex gap-x-2 justify-end">
                <div className="flex flex-col gap-y-2 gap-x-2 md:flex-row">
                    <SearchBar
                        onChangeSearch={onChangeSearch}
                        placeholder={"Search Talent Name"}
                    />
                </div>
                <div className="flex flex-row gap-y-2 gap-x-2 md:flex-row md:order-2">
                    <button
                    className="bg-red-600 w-auto px-2 text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer font-bold"
                    onClick={() => {
                        setTypeForm('add')
                        setShow(true)
                    }}
                    >
                    Add Data
                    </button>
                </div>
            </div>

            <TableContent
                dataSource={listData}
                columns={generateColumn({
                    onEdit: onEdit,
                    onDelete: onDelete
                })}
                isLoading={loading}
            />
            {
                loading ? (<></>) : (
                    <>
                        <div className="w-full flex justify-center mt-5">
                            <Pagination
                                current={page}
                                onChange={onChangePage}
                                pageSize={limit}
                                total={totalItem}
                                showSizeChanger={false}
                            />
                        </div>
                    </>
                )
            }
            

        </div>

        <Modal 
            
            open={show} 
            onCancel={closeModal} 
            footer={
                <>
                    <div className="flex justify-between mt-[20px]">
                        <Button key="back" danger onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#DD0000", color: "white" }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </>
            }
        >
            <div className="mb-8 font-medium">
                <div className="text-xl text-[#4C4C4C]">{typeForm === "add" ? 'Add' : 'Edit'} Data </div>
            </div>
            <div className="container p-[10px] my-[30px]">
                <Form
                    labelCol={{ flex: "150px" }}
                    labelAlign="left"
                    form={formClientHistory}
                    colon={false}
                    wrapperCol={{ flex: 1 }}
                >   

                    <Form.Item
                        label="Talent"
                        name="talent"
                        rules={[
                            {
                                required: true,
                                message: "Please select talent",
                            },
                        ]}
                    >
                        <Select
                            disabled={typeForm === 'edit'}
                            style={{ width: "100%" }}
                            placeholder="Choose talent"
                            dropdownRender={(menu) => (
                                <div onWheel={handleScrollTalent}>{menu}</div>
                            )}
                            loading={loadingMoreTalent || onSearchOptionTalent}
                            showSearch
                            filterOption={false}
                            onSearch={(input) => {
                                doSearchTalent(input)
                            }}
                            defaultActiveFirstOption={false}
                        >
                            {
                                onSearchOptionTalent ? 
                                <>
                                    <Option disabled value="onload search">Please wait...</Option>
                                </>
                                : 
                                    optionsTalent.map((i) => (
                                        <Option key={i.id} value={i.id}>
                                            {i.fullname}
                                        </Option>
                                    ))
                            }
                            {
                                loadingMoreTalent ? 
                                <>
                                    <Option disabled value="onload scroll">Please wait...</Option>
                                </>
                                : 
                                <></>
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Client"
                        name="client"
                        rules={[
                            {
                                required: true,
                                message: "Please select client",
                            },
                        ]}
                    >
                        <Select
                            disabled={typeForm === 'edit'}
                            style={{ width: "100%" }}
                            placeholder="Choose Client"
                            dropdownRender={(menu) => (
                                <div onWheel={handleScrollClient}>{menu}</div>
                            )}
                            loading={loadingMoreClient || onSearchOptionClient}
                            showSearch
                            filterOption={false}
                            onSearch={(input) => {
                                doSearchClient(input)
                            }}
                            defaultActiveFirstOption={false}
                        >
                            {
                                onSearchOptionClient ? 
                                <>
                                    <Option disabled value="onload search">Please wait...</Option>
                                </>
                                : 
                                    optionsClient.map((i) => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))
                            }
                            {
                                loadingMoreClient ? 
                                <>
                                    <Option disabled value="onload scroll">Please wait...</Option>
                                </>
                                : 
                                <></>
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Role"
                        name="role"
                        rules={[
                            {
                                required: true,
                                message: "Please select role",
                            },
                        ]}
                    >
                        <Select
                            disabled={typeForm === 'edit'}
                            style={{ width: "100%" }}
                            placeholder="Choose Role"
                            dropdownRender={(menu) => (
                                <div onWheel={handleScrollRole}>{menu}</div>
                            )}
                            loading={loadingMoreRole || onSearchOptionRole}
                            showSearch
                            filterOption={false}
                            onSearch={(input) => {
                                doSearchRole(input)
                            }}
                            defaultActiveFirstOption={false}
                        >
                            {
                                onSearchOptionRole ? 
                                <>
                                    <Option disabled>Please wait...</Option>
                                </>
                                : 
                                    optionsRole.map((i) => (
                                        <Option key={i.id} value={i.id}>
                                            {i.name}
                                        </Option>
                                    ))
                            }
                            {
                                loadingMoreRole ? 
                                <>
                                    <Option disabled value="onload scroll">Please wait...</Option>
                                </>
                                : 
                                <></>
                            }
                        </Select>
                    </Form.Item>

                    {
                        typeForm === 'edit' ? 
                        (<>
                            <Form.Item
                                label="Status"
                                name="status"
                            >
                                <Radio.Group>
                                    <Radio value="ACTIVE">ACTIVE</Radio>
                                    <Radio value="INACTIVE">INACTIVE</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </>) : <></>
                    }
                    
                    <Form.Item 
                        label="Date"
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: "Please select start date and end date",
                            },
                        ]}
                    >
                        <RangePicker disabled={typeForm === 'edit'} className="w-full"/>
                    </Form.Item>

                </Form>
            </div>
        </Modal>
    </>)
}

export default HistoryClient;