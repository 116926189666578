import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Pagination, Space, Switch } from "antd";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import TabelJourney from "./components/TableJourney";
import ModalJourney from "./components/ModalJourney";
import JourneyRepo from "../../../repositores/adminRepo/JourneyRepo";
import ButtonAddJourney from "./components/ButtonAddJourney";
import SearchBar from "../componets_global/SearchBar";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";
import {
  JourneyContext,
  JourneyContextProvider,
} from "./contexts/journeyContext";
import "./style.css";
const JourneyComponent = () => {
  const [form] = Form.useForm();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [listData, setListData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [totalItem, setTotalItem] = useState(0);
  const [id, setId] = useState(null);
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { setCurrentUsersList, setParticipants } = useContext(JourneyContext);

  function formatDateTime(date) {
    if (!date) return "-";
    let tempDate = new Date(date);

    let dateFormated = `${addLeadingZero(tempDate.getDate())}/${addLeadingZero(
      tempDate.getMonth()
    )}/${tempDate.getFullYear()}`;
    let timeFormated = `${addLeadingZero(tempDate.getHours())}:${addLeadingZero(
      tempDate.getMinutes()
    )}:${addLeadingZero(tempDate.getSeconds())}`;
    return `${dateFormated}, ${timeFormated}`;
  }

  function addLeadingZero(number) {
    return number < 10 ? `0${number}` : `${number}`;
  }

  useEffect(() => {
    setParticipants([]);
  }, [openModalEdit]);

  const columns = [
    {
      title: "Created Date",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 200,
      render: (_, record) => {
        return formatDateTime(record?.modifiedBy?.created_date);
      },
    },
    {
      title: "Journey Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
    },
    {
      title: "Participant Assigned",
      align: "center",
      key: "id",
      width: 180,
      render: (_, record) => {
        return (
          <Space>
            <p>{record?.totalParticipant ?? 0}</p>
          </Space>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      render: (status, record) => {
        return (
          <Switch
            onClick={(dataStatus) =>
              handleEditStatus(dataStatus ? "ACTIVE" : "INACTIVE", record.id)
            }
            checked={status.toUpperCase() === "ACTIVE"}
          />
        );
      },
    },
    {
      title: "Last Edited by",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 150,
      render: (_, record) => {
        return record?.modifiedBy?.fullname ?? "";
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, record) => {
        return (
          <Space>
            <Button
              className="border-none text-link-text font-weight-600"
              onClick={() => {
                setOpenModalView(true);
                handleUserParticipants("", record.id);
                handleClickViewModal(record.id);
              }}
            >
              View
            </Button>
            <Button
              className="border-2 border-primary font-weight-600 text-primary"
              onClick={() => {
                handleClickEditModal(record.id);
                handleUserParticipants("", record.id);
                setId(record.id);
              }}
            >
              Edit
            </Button>
            <Button
              className="border-none"
              onClick={() => {
                setId(record.id);
                setOpenModalDelete(true);
              }}
            >
              <img
                src="/assets/icons/delete.svg"
                alt="icon-delete"
                width={15}
                height={15}
              />
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {}, [form]);

  const fetchData = async () => {
    try {
      setLimit(10);
      setLoading(true);
      const response = await JourneyRepo.getListJourney(page, limit);

      if (response.status === 200) {
        setTotalItem(response?.data?.data?.totalElements);
        setListData(response?.data.data.content);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching rewards data:", error);
    }
  };

  const handleUserParticipants = async (name = "", id) => {
    await JourneyRepo.getUserParticipantsJourney(page, limit, name, id)
      .then((response) => {
        if (response.status !== 200) return;
        setCurrentUsersList(response.data.data.content);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeSearch = async (el) => {
    try {
      setPage(1);
      setLoading(true);
      setListData(null);
      const response = await JourneyRepo.getListJourney(
        page,
        limit,
        el.target.value
      );
      setListData(response.data.data.content);
      setTotalItem(response?.data?.data?.totalElements);
      setLoading(false);
    } catch (error) {
      console.error("Error searching data: ", error);
    }
  };

  const handleClickDeleteModal = async () => {
    try {
      const paramDataAdd = {
        id: id,
      };
      const response = await JourneyRepo.deleteJourney(paramDataAdd);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
      fetchData();
      setOpenModalDelete(false);
    } catch (error) {
      console.error("Error deleting rewards:", error);
      //showErrorMessage("Delete Failed", `Error deleting rewards:${error}`);
      setMessageAlert({
        message: error,
        status: false,
      });
    }
  };

  const handleClickEditModal = async (id) => {
    setOpenModalEdit(true);
    const filterListByID = listData.filter((data) => data.id === id);
    setDataEdit(filterListByID[0]);
  };

  const handleClickViewModal = async (id) => {
    const filterListByID = listData.filter((data) => data.id === id);
    setDataEdit(filterListByID[0]);
  };

  const handleGetDataModalAdd = async (status, data, users) => {
    const paramDataAdd = {
      name: data?.name,
      description: data?.description,
      type: data?.type,
      status: "ACTIVE",
      users: users ?? [],
    };
    if (status === "success") {
      const response = await JourneyRepo.addJourney(paramDataAdd);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
        fetchData();
        setOpenModalAdd(false);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
    }
  };

  const handleEditStatus = async (dataStatus, id) => {
    const paramDataEdit = {
      id: id,
      status: dataStatus,
    };
    const response = await JourneyRepo.editStatus(paramDataEdit);
    if (response.status === 200) {
      setMessageAlert({
        message: response.data.message,
        status: response.data.status,
      });
      setTimeout(() => {
        setMessageAlert({ message: "", status: null });
      }, 1000);
      fetchData();
      setOpenModalEdit(false);
      setDataEdit(null);
      setId(null);
    } else {
      setMessageAlert({
        message: response.data.message,
        status: false,
      });
      setTimeout(() => {
        setMessageAlert({ message: "", status: null });
      }, 1000);
    }
  };

  const handleGetDataModalEdit = async (status, data, users) => {
    const paramDataEdit = {
      id: id || data.id,
      name: data.name,
      description: data.description,
      type: data.type,
      users: users ?? [],
    };

    if (status === "success") {
      const response = await JourneyRepo.editJourney(paramDataEdit);
      if (response.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        fetchData();
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
          setOpenModalEdit(false);
          setDataEdit(null);
          setId(null);
        }, 2000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
    }
  };

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Journey</TitleMasterAdmin>
        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <SearchBar
              onChangeSearch={onChangeSearch}
              placeholder={"Search Journey"}
            />
            <ButtonAddJourney onClick={() => setOpenModalAdd(true)} />
          </div>
        </div>
        <TabelJourney
          dataSource={listData}
          columns={columns}
          isLoading={loading}
        />
        <div className="w-full flex justify-center mt-5">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
          />
        </div>
        <ModalJourney
          instanceForm={form}
          getData={handleGetDataModalAdd}
          dataEdit={null}
          openModal={openModalAdd}
          onClick={() => {
            setOpenModalAdd(false);
            form.resetFields();
          }}
          title={"Add"}
        />
        <ModalJourney
          instanceForm={form}
          getData={handleGetDataModalEdit}
          dataEdit={dataEdit}
          openModal={openModalEdit}
          onClick={() => {
            setOpenModalEdit(false);
            form.resetFields();
            setDataEdit(null);
          }}
          title={"Edit"}
        />
        <ModalJourney
          instanceForm={form}
          getData={() => {}}
          dataEdit={dataEdit}
          openModal={openModalView}
          onClick={() => {
            /* setOpenModalEdit(false);
              form.resetFields();*/
            setDataEdit(null);
            setOpenModalView(false);
            form.resetFields();
          }}
          title={"View"}
        />
        <Modal
          forceRender
          width={315}
          footer={null}
          closable={false}
          keyboard={false}
          open={openModalDelete}
          onOk={handleClickDeleteModal}
          onCancel={() => setOpenModalDelete(false)}
        >
          <div className="flex flex-col justify-center items-center gap-[30px] text-center">
            <p>Are you sure?</p>
            <p className="text-center">
              Do you really want to delete these records? This process cannot be
              undone
            </p>
            <div className="flex gap-[10px]">
              <Button onClick={() => setOpenModalDelete(false)}>Cancel</Button>
              <Button onClick={handleClickDeleteModal} type="primary" danger>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

const Journey = () => {
  return (
    <JourneyContextProvider>
      <JourneyComponent />
    </JourneyContextProvider>
  );
};

export default Journey;
