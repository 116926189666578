import React, { useEffect } from "react";
import CardCertificate from "./Card";
import { Input, Pagination, Result } from "antd";

const ContentCertificate = (props) => {
  const {
    issuedData,
    revokedData,
    certificateTypes,
    handleClickFilter,
    filterActive,
    isLoading,
    isSuccess,
    handleInput,
    currentPage,
    totalItem,
    maxSizeData,
    onChange,
  } = props;

  useEffect(() => {}, [filterActive]);

  return (
    <div className="w-[100%]">
      <div className="md:hidden flex justify-end content-center mt-5 px-5 w-full">
        <Input
          className="w-[150px] p-1 text-xxs"
          placeholder="Search Here"
          suffix={
            <img
              src="/assets/icons/search-icon.svg"
              height={10}
              width={10}
              alt="icon-search"
            />
          }
          onChange={(event) => handleInput("formSearch", event.target.value)}
        />
      </div>
      <div className="flex justify-start items-center mx-10 py-3">
        <button
          className={`border-solid border-2 border-blue-950 rounded-3xl w-[100px] h-[20px] md:h-[30px] text-xxs md:text-xs font-medium mx-1 ${
            filterActive === "all"
              ? "bg-blue-950 text-white"
              : "bg-white text-blue-950"
          }`}
          onClick={(event) => event.preventDefault(handleClickFilter("all"))}
        >
          All
        </button>
        <div className="p-0 m-0">|</div>
        {certificateTypes?.map((item) => (
          <button
            key={item.key}
            className={`border-solid border-2 border-blue-950 rounded-3xl w-[100px] h-[20px] md:h-[30px] text-xxs md:text-xs font-medium mx-1 text-blue-950  ${
              filterActive === item.key
                ? "bg-blue-950 text-white"
                : "bg-white text-blue-950"
            }`}
            onClick={(event) =>
              event.preventDefault(handleClickFilter(item.key))
            }
          >
            {item?.label}
          </button>
        ))}
      </div>
      {!isLoading &&
      isSuccess &&
      issuedData.length === 0 &&
      revokedData.length === 0 ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry Data Not Found"
          className="p-0 mt-4"
        />
      ) : (
        <div className="md:w-full relative">
          <div className="flex flex-wrap justify-evenly py-1 min-h-[500px]">
            {issuedData.map((items) => (
              <CardCertificate key={items.id}>
                <CardCertificate.Header data={items} />
                <CardCertificate.Body
                  title={items?.title}
                  expiredDate={items?.expiredDate}
                />
              </CardCertificate>
            ))}
            {revokedData.map((items) => (
              <CardCertificate key={items.id}>
                <CardCertificate.Header data={items} />
                <CardCertificate.Body
                  title={items?.title}
                  revokedDate={items?.revokeDate}
                />
              </CardCertificate>
            ))}
          </div>
          <div className="w-full flex justify-center absolute bottom-50 left-0 right-0">
            <Pagination
              current={currentPage}
              onChange={onChange}
              pageSize={maxSizeData}
              total={totalItem}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentCertificate;
