import apiClient from "../../shared/api/api.client";

export class JourneyService {
  get = {
    async journeyEvents({ pageParam = 0, queryKey }) {
      const params = queryKey[1];
      try {
        return apiClient.instance.get("/event/journey-user", {
          params: {
            page: pageParam,
            ...params,
          },
        }).then(res => res.data);
      } catch (error) {
        throw error;
      }
    },
  };
}
