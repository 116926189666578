import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Modal, Select } from "antd";
import { statusOptions } from "./column.state";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
const { RangePicker } = DatePicker;

export const AddProjectForm = ({
  show,
  closeModal,
  loading = false,
  handleAdd = () => {},
  handleEditForm = (f, v) => {},
  editingForm,
  handleEdit,
  isEditing,
}) => {
  const handleRangePickerChange = (dates, dateStrings) => {
    if (!dates) {
      handleEditForm("endDate", dayjs());
      handleEditForm("startDate", dayjs());
    } else {
      handleEditForm("endDate", dates[1]);
      handleEditForm("startDate", dates[0]);
    }
  };
  const [errorMessages, setErrorMessage] = useState({});
  const [isValidating, setIsValidating] = useState(false);

  // makes for onBlur
  const validateEmpty = (editingForm) => {
    const errObj = {};
    Object.entries(editingForm).reduce((errors, [key, value]) => {
      if (value === null || value === undefined || value === "") {
        // Customize the error message based on the field being validated
        if (key === "endDate" || key === "startDate") {
          const errorMessage = `Field '${key}' is required.`;
          errObj["date"] = errorMessage;
        } else {
          const errorMessage = `Field '${key}' is required.`;
          //errors.push(errorMessage);
          errObj[key] = errorMessage;
        }
      }
      setErrorMessage((prev) => ({ ...errObj }));
    }, []);

    return Object.keys(errObj).length === 0;
  };
  const onFinish = (values) => {
    // console.log("is finish");
    setErrorMessage({});
    setIsValidating(false);
    if (isEditing) {
      handleEdit(values);
    } else {
      handleAdd(values);
    }
  };

  useEffect(() => {
    isValidating && validateEmpty(editingForm);
  }, [editingForm]);

  const objectIsEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <>
      {console.log(errorMessages)}
      <Modal
        width={900}
        maskClosable={false}
        open={show}
        onCancel={() => {
          setErrorMessage({});
          setIsValidating(false);
          closeModal(false);
        }}
        footer={null}
        closeIcon={true}
        zIndex={999999}
        className="add-form-container-modal"
      >
        <div className="flex flex-col  w-full mb-4">
          <div className="">
            <p className="text-[20px] font-weight-600 mb-5">Add Project</p>
          </div>
          <div>
            <CustomInputContainer
              title={"Name"}
              errorMessage={errorMessages.name}
            >
              <input
                type="text"
                className="border rounded-md w-full p-2"
                value={editingForm?.name}
                placeholder="Project Name"
                onChange={(e) => {
                  handleEditForm("name", e.target.value);
                }}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Description"}
              errorMessage={errorMessages.description}
            >
              <TextArea
                rows={4}
                placeholder="Project Description"
                value={editingForm?.description}
                onChange={(e) => {
                  handleEditForm("description", e.target.value);
                }}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Description"}
              errorMessage={errorMessages.date}
            >
              <RangePicker
                className="w-full"
                value={[
                  dayjs(editingForm.startDate),
                  dayjs(editingForm.endDate),
                ]}
                disabledDate={(current) => {
                  return current && current < dayjs();
                }}
                onChange={handleRangePickerChange}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Status"}
              errorMessage={errorMessages.status}
            >
              <Select
                size="large"
                className="w-full"
                value={editingForm?.status}
                defaultValue={statusOptions["NEW"]}
                onChange={(v) => {
                  handleEditForm("status", v);
                }}
                placeholder={"Choose Status"}
                options={statusOptions}
              />
            </CustomInputContainer>
          </div>
          <div className="flex items-end justify-end">
            <Button
              loading={loading}
              className="bg-[#DD0000] text-white w-[200px] py-1 font-bold rounded ms-3"
              onClick={() => {
                /* if (isValidating && !!errorMessages) {
                  onFinish(editingForm);
                } else {
                  validateEmpty(editingForm);
                  setIsValidating(true);
                } */
                setIsValidating(() => {
                  return true;
                });
                // validateEmpty(editingForm);
                if (validateEmpty(editingForm)) {
                  onFinish(editingForm);
                }

                // when add click, call HandleAdd to push
              }}
            >
              Save
            </Button>
            <Button
              loading={loading}
              className="bg-white text-red w-[200px] py-1 font-bold rounded ms-3"
              onClick={() => {
                // when add click, call HandleAdd to push
                setErrorMessage({});
                closeModal(false);
                setIsValidating(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const CustomInputContainer = ({ title, errorMessage, children }, props) => {
  return (
    <div className="flex justify-between my-1" {...props}>
      <div className="w-[150px]">
        <p>
          {title}
          <span className="text-[red]">*</span>
        </p>
      </div>
      <div className="w-full flex flex-col gap-1">
        {children}
        {errorMessage && errorMessage !== "" ? (
          <p className="text-[red]">{errorMessage}</p>
        ) : null}
      </div>
    </div>
  );
};
