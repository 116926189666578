import React from "react";
import { Dropdown, Button } from "antd";
import { ConfirmDialog } from "./ConfirmDialog";
/* import { ConfirmDialog } from "./ConfirmDialog";
 */
export const talentIssueColumns = (
  handleView = (id, isEdit) => {},
  setProjectTitle,
  handleDelete = (v) => {}
) => {
  return [
    {
      title: "Role Name",
      dataIndex: [
        "historyProject",
        "historyClientTalent",
        "userRole",
        "roleCompany",
        "name",
      ],
      key: "id",
      align: "center",
      width: 150,
    },
    {
      title: "Client Name",
      dataIndex: [
        "historyProject",
        "historyClientTalent",
        "clientCompany",
        "name",
      ],
      key: "id",
      width: 120,
      align: "center",
      render: (_, record) => {
        return (
          <div className="remark-cell">
            {record.historyProject.historyClientTalent.clientCompany.name}
          </div>
        );
      },
    },
    {
      title: "Project Name",
      dataIndex: ["historyProject", "name"],
      key: "id",
      width: 150,
      align: "center",
      render: (_, record) => {
        return <div className="remark-cell">{record.historyProject.name}</div>;
      },
    },
    {
      title: "PIC",
      dataIndex: ["pic"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return <div className="remark-cell">{record.pic}</div>;
      },
    },
    {
      title: "Tech Stack",
      dataIndex: ["techStack"],
      key: "id",
      align: "center",
      width: 150,
      render: (_, record) => {
        return <div className="remark-cell">{record.techStack}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: ["type", "name"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return <div className="remark-cell">{record.type.name}</div>;
      },
    },
    {
      title: "Description",
      dataIndex: ["description"],
      key: "id",
      width: 300,
      render: (_, record) => {
        return <div className="remark-cell">{record.description}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      align: "center",
      render: (v, row) => {
        const styleData = statusOptions.find(
          (item) => item.value === row.status
        );
        return (
          <div className="flex justify-center w-full">
            <div
              className={`w-[100px] px-1 justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
            >
              {v}
            </div>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      align: "center",
      render: (v, row) => {
        const items = [
          {
            key: "1",
            label: (
              <button
                className="bg-[#DD0000] text-white w-[100px] px-2 py-1 font-bold rounded ms-3"
                onClick={() => {
                  handleView(row.id, false);
                }}
              >
                View
              </button>
            ),
          },
          {
            key: "2",
            label: (
              <button
                className="bg-[#DD0000] text-white w-[100px] px-2 py-1 font-bold rounded ms-3"
                onClick={() => {
                  // handleView(row.id);
                  /* setProjectTitle({
                  clientName: row.clientCompany.name,
                  roleName: row.userRole.roleCompany.name,
                }); */
                  handleView(row.id, true);
                  /* setProjectTitle({
                  clientName: row.clientCompany.name,
                  roleName: row.userRole.roleCompany.name,
                }); */
                }}
              >
                Update
              </button>
            ),
          },
          {
            key: "3",
            label: (
              <ConfirmDialog
                title="Do you want to delete?"
                content=""
                okText="Delete"
                cancelText="Cancel"
                handleConfirm={() => {
                  handleDelete(row.id);
                }}
                buttonText={"Delete"}
              />
            ),
          },
        ];
        return (
          <div className="flex justify-center">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <Button>Action</Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];
};

export const statusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "SOLVE",
    label: "SOLVE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "REJECT",
    label: "REJECT",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
  {
    value: "NEXT_SPRINT",
    label: "NEXT_SPRINT",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
];
