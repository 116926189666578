import React, { useEffect } from "react";
import { message } from "antd";

const AlertMiniPopUp = ({ messageAlert, isSuccess }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const alertPopUp = () => {
    messageApi.open({
      type: isSuccess ? "success" : "error",
      content: messageAlert,
      duration: 4,
    });
  };

  useEffect(() => {
    if (messageAlert !== "") {
      alertPopUp();
    }
  }, [messageAlert]);

  return <>{contextHolder}</>;
};

export default AlertMiniPopUp;
