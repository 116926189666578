import React from "react";
import TalentJourney from "./components/TalentJourney";
import TalentBar from "./components/TalentBar";

const DashboardTalent = () => {
  return (
    <div className="w-full h-screen px-5 py-5">
      <div className="flex justify-center md:hidden">
        <p className="text-xl font-bold text-[#4C4C4C] mt-5">Dashboard</p>
      </div>
      <TalentBar />
      <TalentJourney />
    </div>
  );
};

export default DashboardTalent;
