import React from "react";

const index = ({ className, color }) => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.68426 5.05273H11.3158M5.52637 7.94747H12.4737M5.52637 14.8947C5.52637 15.816 5.89234 16.6996 6.54379 17.351C7.19523 18.0024 8.07877 18.3684 9.00005 18.3684C9.92133 18.3684 10.8049 18.0024 11.4563 17.351C12.1078 16.6996 12.4737 15.816 12.4737 14.8947C12.4737 13.9735 12.1078 13.0899 11.4563 12.4385C10.8049 11.787 9.92133 11.4211 9.00005 11.4211C8.07877 11.4211 7.19523 11.787 6.54379 12.4385C5.89234 13.0899 5.52637 13.9735 5.52637 14.8947Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.68433 18.3682V22.9997L9.00012 21.8418L11.3159 22.9997V18.3682"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29828 19.5263H3.59653C2.87998 19.5263 2.19278 19.2823 1.6861 18.848C1.17942 18.4137 0.894775 17.8247 0.894775 17.2105V3.31579C0.894775 2.7016 1.17942 2.11257 1.6861 1.67828C2.19278 1.24398 2.87998 1 3.59653 1H14.4035C15.1201 1 15.8073 1.24398 16.314 1.67828C16.8207 2.11257 17.1053 2.7016 17.1053 3.31579V17.2105C17.1053 17.8247 16.8207 18.4137 16.314 18.848C15.8073 19.2823 15.1201 19.5263 14.4035 19.5263H11.7018"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default index;
