import React, { useEffect, useState } from "react";
import TalentJourneyRepo from "../../../repositores/talentRepo/TalentJourneyTalentRepo";
import TalentHeader from "../../../components/general/TalentHeader";
import { talentJourneyTerminColumns } from "./column.state";
import { message, Select, Spin } from "antd";

import AlertUtility from "../../../components/Alert/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import RouteTalent from "../../../services/RouteTalent";
import { BackArrow } from "./components/Button";
import { CollapseComponent } from "./components/Collapse";

const TalentJourney = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [terminData, setTerminData] = useState([]);
  const [terminProgress, setTerminProgress] = useState("");
  const [levelData, setLevelData] = useState([]);
  const [level, setLevel] = useState("");
  const [matkulId, setMatkulId] = useState("");
  const location = useLocation();

  const getData = async (lv) => {
    setLoading(true);
    await TalentJourneyRepo.getTalentJourneyTermin(location.state?.courseId, lv)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setTerminProgress(result.data.percentageProgress);
        setTerminData(result.data.data);
      })
      .catch((error) => {
        setTerminData([]);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLevel = async (page = 0) => {
    setLoading(true);
    var size = 10;
    await TalentJourneyRepo.getTalentJourneyLevel(page, size, "LMS_LEVEL")
      .then(async (result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }

        let dt = result.data.data.content.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        dt.sort((a, b) => a.label.localeCompare(b.label));
        setLevelData(dt);
        const juniorLevelId = result.data.data.content.find(
          (item) => item.name === "JUNIOR"
        )?.id;

        await setLevel(juniorLevelId || "");

        getData(juniorLevelId || "");
      })
      .catch((error) => {
        setLevelData([]);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenTopic = (material) => {
    navigate(RouteTalent.talentJourneyTopic, {
      state: {
        material: material,
        course: location.state?.course,
        id: location.state?.id,
        courseId: location.state?.courseId,
        matkulId: matkulId,
      },
    });
  };

  const actionBack = () => {
    navigate(RouteTalent.talentJourney);
  };

  useEffect(() => {
    getLevel(0);
  }, []);

  const onChange = async (value) => {
    await setLevel(value);
    getData(value);
  };
  const onSearch = (value) => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"Talent Journey Termin"} subDescription={""} />
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center w-full my-4">
          <BackArrow actionBack={actionBack} />
          <div className="flex flex-col md:flex-row gap-2 items-start md:items-center mt-4 md:mt-0">
            <label htmlFor="level" className="block md:mr-2">
              Choose Level
            </label>
            <Select
              className="w-full md:w-52"
              showSearch
              placeholder="Choose level"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={levelData}
              defaultValue={{
                label: "JUNIOR",
              }}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="my-2 font-semibold text-lg">
            Course: {location.state?.course}
          </div>
          <div className="my-2 font-semibold text-lg">
            Progress {terminProgress}
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : (
          <>
            <div className="h-[calc(80vh-150px)] overflow-auto">
              <CollapseComponent
                headerData={terminData}
                columns={talentJourneyTerminColumns((material) => {
                  handleOpenTopic(material);
                })}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TalentJourney;
