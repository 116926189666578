import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getHistoryClientTalent = async (
  page = 0,
  size = 10,
  roleName = undefined,
  name = undefined
) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof roleName !== "undefined" && roleName.length) {
    params["name"] = roleName;
  }
  if (typeof name !== "undefined" && name.length) {
    params["status"] = name;
  }
  const url = `${API_BASE}/history-project/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getDetailHistoryClientTalent = async (
  page = 0,
  size = 10,
  name = undefined,
  id
) => {
  let params = {
    page: page + 1,
    size: size,
  };

  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/history-project/history-client/${id}?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getClientListHistory = async (page = 0, size = 10, roleName = undefined, name = undefined) => {
  let params = {
    page: page ,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  if (typeof roleName != "undefined" && roleName.length) {
    params["roleName"] = roleName;
  }
  const url = `${API_BASE}/history-client-talent/list?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addHistoryClientTalent = async (body) => {
  const url = `${API_BASE}/history-project`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const editHistoryClientTalent = async (body, id) => {
  const url = `${API_BASE}/history-project/${id}`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const deleteHistoryClientTalent = async (id) => {
  const url = `${API_BASE}/history-project/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
};

const addHistoryClient = async(payload) => {
  const url = `${API_BASE}/history-client-talent`;
  const response = await CallApi({ url, method: "POST", data: payload });
  return response;
}
const updateHistoryClient = async(payload) => {
  const url = `${API_BASE}/history-client-talent`;
  const response = await CallApi({ url, method: "PUT", data: payload });
  return response;
}
const deleteHistoryClient = async(id) => {
  const url = `${API_BASE}/history-client-talent/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
}

const HistoryClientRepo = {
  addHistoryClientTalent,
  deleteHistoryClientTalent,
  editHistoryClientTalent,
  getHistoryClientTalent,
  getDetailHistoryClientTalent,
  getClientListHistory,
  addHistoryClient,
  updateHistoryClient,
  deleteHistoryClient
};

export default HistoryClientRepo;
