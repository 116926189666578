export const SERVICE_LOOKUP_EVENT_TYPE = "EVENT_TYPE"
export const SERVICE_LOOKUP_EVENT_TASK = "TASK_EVENT"
export const SERVICE_LOOKUP_TYPE_CERTIFICATE = "TYPE_CERTIFICATE"
export const SARVICE_LOOKUP_EVENT_JOURNEY = "MASTER_EVENT_JOURNEY"

export const SARVICE_LOOKUP_TYPE_BOOLEAN = "TYPE_BOOLEAN"
export const SARVICE_LOOKUP_TYPE_INTEREST = "TYPE_INTERESTED"
export const SARVICE_LOOKUP_TYPE_PENILAIAN = "TYPE_PENILAIAN_TL"

export const EVENT_STATUS = {
    onGoing: "ON-GOING",
    finish: "FINISHED",
    participant: "participant"
}