import { Modal } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EventCard } from "./EventCard";
import { useEventDetails } from "../hooks/useEventDetails";

export const EventCardModal = ({ children, eventId }) => {
  const params = useParams();

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    query: { refetch, isError, isFetching, data },
  } = useEventDetails(eventId);

  function closeModal() {
    setIsModalOpen(false);
    navigate({
      pathname: `/talent/journey`,
    });
  }

  function openModal() {
    refetch();
    setIsModalOpen(true);
  }

  function navigateTo() {
    navigate({
      pathname: `/talent/journey/events/${eventId}`,
    });
  }

  useEffect(() => {
    if (params.eventId === eventId) openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, params.eventId]);

  useEffect(() => {
    if (isModalOpen && isError) closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isModalOpen]);

  return (
    <Fragment>
      {children(navigateTo)}
      <Modal
        centered
        closeIcon={false}
        destroyOnClose
        footer={null}
        closable={false}
        keyboard={false}
        modalRender={() => (
          <EventCard
            data={data}
            isLoading={isFetching}
            closeModal={closeModal}
            refetchData={refetch}
          />
        )}
        open={isModalOpen}
        width={600}
        wrapClassName="py-10"
        onCancel={closeModal}
      />
    </Fragment>
  );
};
