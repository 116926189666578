import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Button,
  Pagination,
  Modal,
  message,
  Select,
  DatePicker,
} from "antd";
import TableDataEvent from "./components/TableDataEvent";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import MasterEventRepo from "../../../../repositores/adminRepo/MasterEventRepo";
import FormDataMasterEvent from "./components/FormEvent";
import FormParticipant from "./components/FormParticipant";
import { isNullOrEmpty } from "../../../../utils/utils";
import "./MasterEvent.css";
import dayjs from "dayjs";
import { EVENT_STATUS } from "../../../../utils/constant";
import FormFinishEvent from "./components/FormFinish";
import AlertMiniPopUp from "../../componets_global/AlertMiniPopUp";

const { RangePicker } = DatePicker;

const { Option } = Select;

const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const MasterEvent = () => {
  const defaultForm = {
    event: {
      name: null,
      startDate: null,
      endDate: null,
      masterEvent: {
        id: "",
      },
    },
    participants: [],
    isAllTalent: false,
  };
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [datesRange, setDates] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalItem, setTotalItem] = useState(0);
  const [search, setSearch] = useState(undefined);
  const [selectedMasterId, setSelectedMasterId] = useState(undefined);
  const [pageEvent, setPageEvent] = useState(1);
  const [loadingMoreEvent, setLoadingMoreEvent] = useState(false);
  const [totalElementEvent, setTotalElementEvent] = useState(-1);
  const [optionsEvent, setOptionsEvent] = useState([]);

  const [stepForm, setStepForm] = useState(0);
  const [formType, setFormType] = useState("add");
  const [showModalForm, setShowModalForm] = useState(false);

  const [formMounted, setFormMounted] = useState(false);
  const [formDataEvent, setFormDataEvent] = useState({ ...defaultForm });
  const [counterForm, setCounterForm] = useState(1);
  const [disabledStepOne, setDisabledStepOne] = useState(true);
  const [listParticipant, setParticipant] = useState({
    participant: [],
    comittee: [],
  });

  const [showSelectEvent, setShowSelectEvent] = useState(false);
  const [listOngoingEvent, setListOngoingEvent] = useState([]);
  const [selectedOnGoingEvent, setSelectedOnGoingEvent] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [showSelectEventToDelete, setShowSelectEventToDelete] = useState(false);
  const [showSelectEventToFinish, setShowSelectEventToFinish] = useState(false);
  const [payloadEventFinish, setPayloadEventFinish] = useState(null);
  const [counterFormFinsh, setCounterFormFinsh] = useState(0);

  const [isDetailEvent, setIsDetailEvent] = useState(false);
  const [listCertificate, setListCertificate] = useState([]);

  const [qrEvent, setQrEvent] = useState({
    id: null,
    name: "",
  });
  const [isViewQrOpen, setIsViewQrOpen] = useState(false);
  const qrUrlCopyRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    fetchData();

    return () => {
      setLoading(false);
    };
  }, [page, datesRange, search, selectedMasterId]);

  useEffect(() => {
    handleFetchMoreEvent(1, 10);
  }, []);

  async function fetchData() {
    setLimit(20);
    setLoading(true);
    let response;
    if (!datesRange) {
      response = await MasterEventRepo.getListEvent(
        page,
        limit,
        search,
        selectedMasterId
      );
    } else {
      response = await MasterEventRepo.getListEvent(
        page,
        limit,
        search,
        selectedMasterId,
        datesRange[0],
        datesRange[1]
      );
    }

    let tempData = [];
    if (response?.data?.data?.content) {
      await response?.data?.data?.content?.forEach((content) => {
        const findIndex = tempData.findIndex(
          (dt) => dt.id === content.masterEvent.id
        );
        if (findIndex < 0) {
          tempData.push({ ...content.masterEvent, events: [] });
        }
      });
      if (tempData.length) {
        await response?.data?.data?.content?.forEach((content) => {
          const masterEventId = content.masterEvent.id;
          delete content.masterEvent;
          tempData.map((event, index) => {
            if (masterEventId === event.id) {
              const findIndex = event.events.findIndex(
                (dt) => dt.id === content.id
              );
              if (findIndex < 0) {
                event.events.push({
                  ...content,
                  rowNumber: index + 1,
                  masterEventId: event.id,
                });
              }
            }
          });
        });
        tempData.map((event) => {
          event.events.map((dtEvent, index) => {
            dtEvent.rowNumber = index + 1;
          });
        });
      }
    }
    setDataTable(tempData);
    setTotalItem(response?.data?.data?.totalElements);
    setLoading(false);
  }

  const onChangePage = (page) => {
    setPage(page);
  };

  const handleFetchMoreEvent = async (page, limit) => {
    if (optionsEvent.length < totalElementEvent || totalElementEvent === -1) {
      setLoadingMoreEvent(true);
      const response = await MasterEventRepo.getListJourney(page - 1, limit);
      const tempOptions = response.data?.data?.content || [];
      setOptionsEvent((prevOptions) => [...prevOptions, ...tempOptions]);
      setTotalElementEvent(response.data?.data?.totalElements);
      setPageEvent(page + 1);
      setLoadingMoreEvent(false);
    }
  };

  const handleSearch = async (value) => {
    setLoadingMoreEvent(true);

    if (value === "") {
      await handleFetchMoreEvent(1, 10);
    } else {
      const response = await MasterEventRepo.getListJourney(0, 10, value);
      const filteredJourney = response.data?.data?.content || [];
      setOptionsEvent(filteredJourney);
      setPage(1);
    }
    setLoadingMoreEvent(false);
  };

  const handleChangeMasterEvent = (value) => {
    setSelectedMasterId(value);
    handleFetchMoreEvent(1, 10);
  };

  const handleClear = () => {
    setSelectedMasterId(undefined);
    fetchData();
  };

  const handleScrollEvent = (event) => {
    event.stopPropagation();

    if (!loadingMoreEvent) {
      handleFetchMoreEvent(pageEvent, 10);
    }
  };

  const addEvent = () => {
    setFormDataEvent(defaultForm);
    setParticipant({
      participant: [],
      comittee: [],
    });
    setShowModalForm(true);
    setStepForm(0);
    let tempCounter = counterForm + 1;
    setCounterForm(tempCounter);
    setFormType("add");
  };

  const refreshForm = () => {
    setFormDataEvent(defaultForm);
    setParticipant({
      participant: [],
      comittee: [],
    });
    setStepForm(0);
    let tempCounter = counterForm + 1;
    setCounterForm(tempCounter);
    setShowModalForm(false);
    setFormMounted(false);
  };

  const handleCloseModal = () => {
    if (formMounted) {
      refreshForm();
    } else {
      setShowModalForm(false);
    }
  };

  const handleChangeRangeDate = (data) => {
    if (data) {
      setDates([
        dayjs(data[0]).format("YYYY-MM-DD"),
        dayjs(data[1]).format("YYYY-MM-DD"),
      ]);
      setPage(1);
    } else {
      setDates(null);
    }
  };

  const getDataEvent = (step, data) => {
    let tempForm = { ...formDataEvent };
    switch (step) {
      case 1:
        setParticipant({
          participant: data.participant,
          comittee: data.comittee,
        });
        let tempParticipant = [];
        let tempCommittee = [];
        if (data.participant?.length) {
          data.participant.forEach((participant) => {
            tempParticipant.push({
              user: { id: participant.id },
              isCommittee: false,
              isDeleted:
                typeof participant.isDeleted === "undefined"
                  ? false
                  : participant.isDeleted,
            });
          });
        }
        if (data.committee?.length) {
          data.committee?.forEach((comittee) => {
            tempParticipant.push({
              user: { id: comittee.id },
              isCommittee: true,
              isDeleted:
                typeof comittee.isDeleted === "undefined"
                  ? false
                  : comittee.isDeleted,
            });
          });
        }
        tempForm.participants = tempParticipant;
        tempForm.isAllTalent = data.isAllTalent;
        break;

      default:
        tempForm.event.name = data.getFieldValue("eventName");
        tempForm.event.startDate = data.getFieldValue("startDate");
        tempForm.event.endDate = data.getFieldValue("endDate");
        tempForm.event.startTime = data
          .getFieldValue("startTime")
          ?.format("HH:mm");
        tempForm.event.endTime = data.getFieldValue("endTime")?.format("HH:mm");
        tempForm.event.masterEvent = { id: data.getFieldValue("eventId") };

        if (
          isNullOrEmpty(data.getFieldValue("eventName")) ||
          isNullOrEmpty(data.getFieldValue("startDate")) ||
          isNullOrEmpty(data.getFieldValue("endDate")) ||
          isNullOrEmpty(data.getFieldValue("startTime")) ||
          isNullOrEmpty(
            data.getFieldValue("endTime") ||
              isNullOrEmpty(data.getFieldValue("eventId"))
          )
        ) {
          setDisabledStepOne(true);
        } else {
          setDisabledStepOne(false);
        }
        break;
    }
    setFormDataEvent(tempForm);
  };

  const handleBackStep = () => {
    let tempStep = stepForm;
    setStepForm(tempStep - 1);
  };

  const handleNextStep = () => {
    let tempStep = stepForm;
    setStepForm(tempStep + 1);
  };

  const selectEdit = (data) => {
    let commitee = [];
    let participants = [];
    let tempCounter = counterForm + 1;
    setCounterForm(tempCounter);
    data.participants.forEach((participant) => {
      if (participant.type === EVENT_STATUS.participant) {
        participants.push({
          id: participant.user.id,
          fullname: participant.user.fullname,
        });
      } else {
        commitee.push({
          id: participant.user.id,
          fullname: participant.user.fullname,
        });
      }
    });
    setParticipant({ participant: participants, comittee: commitee });
    let splitStartDateArr = data.startDate.split("/");
    let startDate =
      splitStartDateArr[2] +
      "-" +
      splitStartDateArr[1] +
      "-" +
      splitStartDateArr[0];

    let splitEndDateArr = data.endDate.split("/");
    let endDate =
      splitEndDateArr[2] + "-" + splitEndDateArr[1] + "-" + splitEndDateArr[0];
    setFormDataEvent({
      event: {
        id: data.id,
        name: data.name,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        startTime: data?.startTime,
        endTime: data?.endTime,
        masterEvent: {
          id: data?.masterEventId,
        },
      },
      participants: [],
    });
    setSelectedOnGoingEvent(data);
    setFormType("edit");

    OpenFormChooseOnGoingEvent(true);
  };

  const selectDelete = (data) => {
    let commitee = [];
    let participants = [];
    let tempCounter = counterForm + 1;
    setCounterForm(tempCounter);
    data.participants.forEach((participant) => {
      if (participant.type === EVENT_STATUS.participant) {
        participants.push({
          id: participant.user.id,
          fullname: participant.user.fullname,
        });
      } else {
        commitee.push({
          id: participant.user.id,
          fullname: participant.user.fullname,
        });
      }
    });
    setParticipant({ participant: participants, comittee: commitee });
    let splitStartDateArr = data.startDate.split("/");
    let startDate =
      splitStartDateArr[2] +
      "-" +
      splitStartDateArr[1] +
      "-" +
      splitStartDateArr[0];

    let splitEndDateArr = data.endDate.split("/");
    let endDate =
      splitEndDateArr[2] + "-" + splitEndDateArr[1] + "-" + splitEndDateArr[0];
    setFormDataEvent({
      event: {
        id: data.id,
        name: data.name,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        startTime: data?.startTime,
        endTime: data?.endTime,
        masterEvent: {
          id: data?.masterEventId,
        },
      },
      participants: [],
    });
    setSelectedOnGoingEvent(data);
    // let dataEvents = [...data.events]
    // setListOngoingEvent([])
    // if (dataEvents.length) {
    //     setListOngoingEvent(dataEvents.filter(event => event.status === EVENT_STATUS.onGoing))
    // }
    // setSelectedEvent(data)
    setShowSelectEventToDelete(true);
  };

  const doSubmit = async () => {
    const tempPayload = { ...formDataEvent };
    tempPayload.event.startDate = dayjs(formDataEvent.event.startDate).format(
      "DD/MM/YYYY"
    );
    tempPayload.event.endDate = dayjs(formDataEvent.event.endDate).format(
      "DD/MM/YYYY"
    );
    setLoading(true);
    let responseApi = null;
    if (formType === "add") {
      responseApi = await MasterEventRepo.addEvent(tempPayload);
    } else {
      responseApi = await MasterEventRepo.editEvent(tempPayload);
      OpenFormChooseOnGoingEvent(false);
      setFormType("add");
    }
    if (
      responseApi?.data?.data?.status === 200 ||
      responseApi?.data?.status === 200
    ) {
      messageApi.open({
        type: "success",
        content: responseApi?.data?.data?.message || responseApi?.data?.message,
      });
      refreshForm();
      const response = await MasterEventRepo.getListEvent(page, limit, search);
      let tempData = [];
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content) => {
          const findIndex = tempData.findIndex(
            (dt) => dt.id === content.masterEvent.id
          );
          if (findIndex < 0) {
            tempData.push({ ...content.masterEvent, events: [] });
          }
        });
        if (tempData.length) {
          await response?.data?.data?.content?.forEach((content, index) => {
            const masterEventId = content.masterEvent.id;
            delete content.masterEvent;
            tempData.map((event, index) => {
              if (masterEventId === event.id) {
                const findIndex = event.events.findIndex(
                  (dt) => dt.id === content.id
                );
                if (findIndex < 0) {
                  event.events.push({
                    ...content,
                    rowNumber: index + 1,
                    masterEventId: event.id,
                  });
                }
              }
            });
          });
          tempData.map((event) => {
            event.events.map((dtEvent, index) => {
              dtEvent.rowNumber = index + 1;
            });
          });
        }
      }
      setDataTable(tempData);
      setTotalItem(response.data.data.totalElements);
      setLoading(false);
    } else {
      messageApi.open({
        type: "error",
        content: responseApi?.data?.data?.message || responseApi?.data?.message,
      });
      refreshForm();
      setLoading(false);
    }
  };

  const onChangeSearch = (el) => {
    setLoading(true);
    setTimeout(() => {
      setSearch(el.target.value);
      setPage(1);
    }, 2000);
  };

  const OpenFormChooseOnGoingEvent = (isOpen) => {
    if (!isOpen) {
      setSelectedEvent(null);
      setSelectedOnGoingEvent(null);
      refreshForm();
    }
    setShowSelectEvent(isOpen);
  };

  const handleChangeEvent = (value) => {
    const indexEvent = listOngoingEvent.findIndex(
      (event) => event.id === value
    );
    const dataEvent = listOngoingEvent[indexEvent];
    let commitee = [];
    let participants = [];
    dataEvent.participants.forEach((participant) => {
      if (participant.type === EVENT_STATUS.participant) {
        participants.push({
          id: participant.user.id,
          fullname: participant.user.fullname,
        });
      } else {
        commitee.push({
          id: participant.user.id,
          fullname: participant.user.fullname,
        });
      }
    });
    setParticipant({ participant: participants, comittee: commitee });
    let splitStartDateArr = dataEvent.startDate.split("/");
    let startDate =
      splitStartDateArr[2] +
      "-" +
      splitStartDateArr[1] +
      "-" +
      splitStartDateArr[0];

    let splitEndDateArr = dataEvent.endDate.split("/");
    let endDate =
      splitEndDateArr[2] + "-" + splitEndDateArr[1] + "-" + splitEndDateArr[0];
    setFormDataEvent({
      event: {
        id: dataEvent.id,
        name: dataEvent.name,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        startTime: undefined,
        endTime: undefined,
        masterEvent: {
          id: selectedEvent.id,
        },
      },
      participants: [],
    });
    setSelectedOnGoingEvent(dataEvent);
  };

  const doDelete = async () => {
    setLoading(true);
    const responseDelete = await MasterEventRepo.deleteEvent(
      selectedOnGoingEvent.id
    );
    if (responseDelete?.data?.status === 200) {
      messageApi.open({
        type: "success",
        content:
          responseDelete?.data?.data?.message || responseDelete?.data?.message,
      });
    } else {
      messageApi.open({
        type: "error",
        content:
          responseDelete?.data?.data?.message || responseDelete?.data?.message,
      });
    }
    refreshForm();
    cancelDelete();
    const response = await MasterEventRepo.getListEvent(page, limit, search);
    let tempData = [];
    if (response?.data?.data?.content) {
      await response?.data?.data?.content?.forEach((content) => {
        const findIndex = tempData.findIndex(
          (dt) => dt.id === content.masterEvent.id
        );
        if (findIndex < 0) {
          tempData.push({ ...content.masterEvent, events: [] });
        }
      });
      if (tempData.length) {
        await response?.data?.data?.content?.forEach((content) => {
          const masterEventId = content.masterEvent.id;
          delete content.masterEvent;
          tempData.map((event, index) => {
            if (masterEventId === event.id) {
              const findIndex = event.events.findIndex(
                (dt) => dt.id === content.id
              );
              if (findIndex < 0) {
                event.events.push({
                  ...content,
                  rowNumber: index + 1,
                  masterEventId: event.id,
                });
              }
            }
          });
        });
        tempData.map((event) => {
          event.events.map((dtEvent, index) => {
            dtEvent.rowNumber = index + 1;
          });
        });
      }
    }
    setDataTable(tempData);
    setTotalItem(response.data.data.totalElements);
    setLoading(false);
  };

  const cancelDelete = () => {
    refreshForm();
    setSelectedOnGoingEvent(null);
    setSelectedEvent(null);
    setListOngoingEvent([]);
    setShowSelectEventToDelete(false);
  };

  const selectToFinish = (data) => {
    setIsDetailEvent(false);
    if (!isNullOrEmpty(data)) {
      const dataEvent = { ...data };
      let splitStartDateArr = dataEvent.startDate.split("/");
      let startDate =
        splitStartDateArr[2] +
        "-" +
        splitStartDateArr[1] +
        "-" +
        splitStartDateArr[0];
      let tempCounter = counterFormFinsh;
      setCounterFormFinsh(tempCounter + 1);
      let splitEndDateArr = dataEvent.endDate.split("/");
      let endDate =
        splitEndDateArr[2] +
        "-" +
        splitEndDateArr[1] +
        "-" +
        splitEndDateArr[0];
      dataEvent.startDate = startDate;
      dataEvent.endDate = endDate;
      setSelectedEvent(dataEvent);
      setShowSelectEventToFinish(true);
    }
  };

  const cancelFinish = () => {
    setShowSelectEventToFinish(false);
    setSelectedEvent(null);
    let tempCounter = counterFormFinsh;
    setCounterFormFinsh(tempCounter + 1);
  };

  const doFinish = async () => {
    setLoading(true);
    let params = payloadEventFinish;
    if (isNullOrEmpty(payloadEventFinish)) {
      params = {
        championUser: [],
      };
    }
    const responseFinish = await MasterEventRepo.finishEvent(
      params,
      selectedEvent.id
    );
    if (responseFinish?.data?.status === 200) {
      messageApi.open({
        type: "success",
        content:
          responseFinish?.data?.data?.message || responseFinish?.data?.message,
      });
      cancelFinish();
      setSelectedEvent(null);
      setShowSelectEventToFinish(false);
    } else {
      messageApi.open({
        type: "error",
        content:
          responseFinish?.data?.data?.message || responseFinish?.data?.message,
      });
    }
    const response = await MasterEventRepo.getListEvent(page, limit, search);
    let tempData = [];
    if (response?.data?.data?.content) {
      await response?.data?.data?.content?.forEach((content) => {
        const findIndex = tempData.findIndex(
          (dt) => dt.id === content.masterEvent.id
        );
        if (findIndex < 0) {
          tempData.push({ ...content.masterEvent, events: [] });
        }
      });
      if (tempData.length) {
        await response?.data?.data?.content?.forEach((content) => {
          const masterEventId = content.masterEvent.id;
          delete content.masterEvent;
          tempData.map((event, index) => {
            if (masterEventId === event.id) {
              const findIndex = event.events.findIndex(
                (dt) => dt.id === content.id
              );
              if (findIndex < 0) {
                event.events.push({
                  ...content,
                  rowNumber: index + 1,
                  masterEventId: event.id,
                });
              }
            }
          });
        });
        tempData.map((event) => {
          event.events.map((dtEvent, index) => {
            dtEvent.rowNumber = index + 1;
          });
        });
      }
    }
    setDataTable(tempData);
    setTotalItem(response.data.data.totalElements);
    setLoading(false);
  };

  const getDataFinishEvent = (data) => {
    setPayloadEventFinish(data);
  };

  const selectToDetail = (data) => {
    setIsDetailEvent(true);

    if (!isNullOrEmpty(data.id)) {
      navigate("/admin/event/" + data.id);
    }
  };

  const handleViewQR = async (id, name) => {
    if (isViewQrOpen) return;

    setQrEvent({ id, name });
    setIsViewQrOpen(true);
  };

  const handleViewQrClose = () => {
    setIsViewQrOpen(false);
  };

  const handleDownloadQrCode = async () => {
    if (!qrEvent.id) return;

    await MasterEventRepo.viewQrEvent(qrEvent.id)
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Attendance Event - ${qrEvent?.name}.png`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Failed to download image");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const copyToClipboard = async () => {
    try {
      if (qrUrlCopyRef.current && qrUrlCopyRef.current?.textContent) {
        await navigator.clipboard.writeText(qrUrlCopyRef.current?.textContent);
        setIsCopied(true);
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5" id="formDataEvent">
        <h4 className="text-[32px] font-bold">Event</h4>
        <div className="flex flex-col mb-4 justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <RangePicker onChange={handleChangeRangeDate} />
            <Select
              onChange={handleChangeMasterEvent}
              placeholder="Select Master Event"
              dropdownRender={(menu) => (
                <div onWheel={handleScrollEvent}>{menu}</div>
              )}
              value={selectedMasterId}
              onSearch={handleSearch}
              loading={loadingMoreEvent}
              showSearch
              allowClear
              onClear={handleClear}
              filterOption={false}
              optionFilterProp="children"
              defaultActiveFirstOption={false}
            >
              {optionsEvent.map((item, idx) => (
                <Option key={item.id + "-" + idx} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-row gap-4 lg:mb-0">
            <Input
              placeholder="Search Here"
              className="w-full lg:w-[200px] max-w-[400px]"
              onChange={onChangeSearch}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
            <Button
              onClick={addEvent}
              type="primary"
              danger
              className="mr-1 bg-[#DD0000]"
              style={{ backgroundColor: "#DD0000" }}
            >
              Add Event
            </Button>
          </div>
        </div>
        <TableDataEvent
          isLoading={loading}
          dataTable={dataTable}
          selectEdit={(data) => selectEdit(data)}
          selectDelete={(data) => selectDelete(data)}
          selectToFinish={(data) => selectToFinish(data)}
          selectToDetail={(data) => selectToDetail(data)}
          handleViewQR={handleViewQR}
        />
        <div className="w-full flex justify-center my-2 pt-2 pb-10">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
            showSizeChanger={false}
          />
        </div>
        <Modal
          forceRender
          onCancel={handleCloseModal}
          open={showModalForm}
          footer={
            <>
              <div className={stepForm > 0 ? "flex justify-between" : ""}>
                {stepForm > 0 ? (
                  formMounted && (
                    <Button key="back" danger onClick={handleBackStep}>
                      Previous
                    </Button>
                  )
                ) : (
                  <></>
                )}

                {stepForm < 1
                  ? formMounted && (
                      <Button
                        type="primary"
                        onClick={handleNextStep}
                        style={{ backgroundColor: "#DD0000", color: "white" }}
                        disabled={disabledStepOne}
                      >
                        Next
                      </Button>
                    )
                  : formMounted && (
                      <Button
                        type="primary"
                        style={{ backgroundColor: "#DD0000", color: "white" }}
                        disabled={formDataEvent.participants.length === 0}
                        onClick={() => doSubmit()}
                      >
                        Submit
                      </Button>
                    )}
              </div>
            </>
          }
        >
          {stepForm === 0 || stepForm === 2 ? (
            <FormDataMasterEvent
              key={"step1" + counterForm}
              getDataMasterEvent={(step, data) => getDataEvent(step, data)}
              formType={formType}
              getStateMount={(val) => setFormMounted(val)}
              defaultFormValue={formDataEvent}
            />
          ) : (
            <></>
          )}
          {stepForm === 1 || stepForm === 2 ? (
            <FormParticipant
              key={"step2" + counterForm}
              getDataParticipant={(step, data) => getDataEvent(step, data)}
              formType={formType}
              getStateMount={(val) => setFormMounted(val)}
              defaultFormValue={listParticipant}
            />
          ) : (
            <></>
          )}
        </Modal>

        <Modal
          forceRender
          onCancel={() => OpenFormChooseOnGoingEvent(false)}
          open={showSelectEvent}
          footer={
            <>
              {isNullOrEmpty(selectedOnGoingEvent) && (
                <div className="w-full mt-[30px] mb-[30px] text-left">
                  <Select
                    key="updateSelection"
                    style={{ width: "100%" }}
                    onChange={handleChangeEvent}
                    placeholder="Choose Event"
                  >
                    {listOngoingEvent.map((i) => (
                      <Option key={"updateSelection" + i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              {selectedOnGoingEvent && (
                <>
                  <FormDataMasterEvent
                    key={"step11" + counterForm}
                    getDataMasterEvent={(step, data) =>
                      getDataEvent(step, data)
                    }
                    formType={formType ?? "add"}
                    getStateMount={(val) => setFormMounted(val)}
                    defaultFormValue={formDataEvent}
                  />
                  <FormParticipant
                    key={"step21" + counterForm}
                    getDataParticipant={(step, data) =>
                      getDataEvent(step, data)
                    }
                    formType={formType ?? "add"}
                    getStateMount={(val) => setFormMounted(val)}
                    defaultFormValue={listParticipant}
                  />
                </>
              )}
              <div className="flex justify-between">
                <Button
                  key="back"
                  danger
                  onClick={() => OpenFormChooseOnGoingEvent(false)}
                >
                  Cancel
                </Button>
                {selectedOnGoingEvent && (
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#DD0000", color: "white" }}
                    onClick={() => doSubmit()}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </>
          }
        ></Modal>

        <Modal
          forceRender
          onCancel={() => cancelDelete()}
          open={showSelectEventToDelete}
          footer={
            <>
              <div className="flex justify-between mt-[20px]">
                <Button key="back" danger onClick={() => cancelDelete()}>
                  Cancel
                </Button>
                {selectedOnGoingEvent && (
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#DD0000", color: "white" }}
                    onClick={() => doDelete()}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </>
          }
        >
          {isNullOrEmpty(selectedOnGoingEvent) && (
            <div className="w-full mt-[30px] mb-[30px] text-left">
              <Select
                key="deleteSelection"
                style={{ width: "100%" }}
                onChange={handleChangeEvent}
                placeholder="Choose Event"
              >
                {listOngoingEvent.map((i) => (
                  <Option key={"deleteSelection" + i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {selectedOnGoingEvent && (
            <>
              <FormDataMasterEvent
                key={"deleteSection" + counterForm}
                getDataMasterEvent={(step, data) => getDataEvent(step, data)}
                formType={"formType"}
                getStateMount={(val) => setFormMounted(val)}
                defaultFormValue={formDataEvent}
                isDisabled={true}
                isWillDelete={true}
              />
            </>
          )}
        </Modal>

        <Modal
          onCancel={() => cancelFinish()}
          open={showSelectEventToFinish}
          footer={
            <>
              {!isDetailEvent && (
                <div className="flex justify-between mt-[20px]">
                  <Button key="back" danger onClick={() => cancelFinish()}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#DD0000", color: "white" }}
                    onClick={() => doFinish()}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </>
          }
        >
          <FormFinishEvent
            key={"formfinish-" + counterFormFinsh}
            defaultFormValue={selectedEvent}
            getDataFinishEvent={(data) => getDataFinishEvent(data)}
            isDisabled={isDetailEvent}
            isFinish={isDetailEvent}
            listCertification={listCertificate}
          />
        </Modal>

        <Modal
          title={null}
          open={isViewQrOpen}
          onCancel={handleViewQrClose}
          footer={null}
          width={350}
          closeIcon={false}
        >
          {qrEvent?.id && (
            <div className="flex flex-col justify-center pt-30">
              <div className="w-[100%] p-7  mx-auto">
                <img
                  src={`${API_BASE}/event/generateQr?width=300&height=300&text=${process.env.REACT_APP_BASE_FE}attendance-verification/${qrEvent.id}`}
                  alt="view qr"
                />
              </div>
              <div className="flex justify-center">
                {isCopied && (
                  <AlertMiniPopUp
                    isSuccess={isCopied}
                    messageAlert={"Copied"}
                  />
                )}
              </div>
              <div className="flex justify-start items-center my-5 gap-1 w-[100%]">
                <p
                  className="truncate"
                  ref={qrUrlCopyRef}
                >{`${API_BASE}/event/generateQr?width=300&height=300&text=${process.env.REACT_APP_BASE_FE}attendance-verification/${qrEvent.id}`}</p>
                <img
                  src="/assets/icons/copy.png"
                  alt="copy-logo"
                  width={15}
                  height={15}
                  onClick={copyToClipboard}
                  className="cursor-pointer"
                />
              </div>
              <Button
                key={"submit"}
                className="text-primary border border-primary font-semibold"
                onClick={handleDownloadQrCode}
              >
                Download
              </Button>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default MasterEvent;
