import React from "react";
import { Button, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const ChecklistSkillColumns = (handleView) => {
  return [
    {
      title: "No.",
      dataIndex: ["course", "name"],
      key: "id",
      align: "center",
      width: 70,
      render: (v, row, id) => {
        return id + 1;
      },
    },
    {
      title: "interviewer",
      dataIndex: ["interviewerName"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return <div className="remark-cell">{record.interviewerName}</div>;
      },
    },
    {
      title: "Name",
      dataIndex: ["talent", "fullname"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return <div className="remark-cell">{record.talent.fullname}</div>;
      },
    },
    {
      title: "Account",
      dataIndex: ["retroInterviewClient", "account", "name"],
      key: "id",
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <div className="remark-cell">
            {record.retroInterviewClient.account.name}
          </div>
        );
      },
    },
    {
      title: "Position",
      dataIndex: ["retroInterviewClient", "role", "name"],
      key: "id",
      align: "center",
      width: 200,
      render: (_, record) => {
        return (
          <div className="remark-cell">
            {record.retroInterviewClient.role.name}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      align: "center",
      render: (v, row) => {
        const styleData = statusOptions.find(
          (item) => item.value === row.status
        );
        return (
          <div className="flex justify-center">
            <div
              className={`w-[100%] justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
            >
              {v}
            </div>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      align: "center",
      render: (v, row) => {
        return (
          <div>
            <button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                handleView(row.id);
              }}
            >
              View
            </button>
          </div>
        );
      },
    },
  ];
};

export const RetroCandidateInterviewColumns = (
  handleCheck = (v) => {},
  getUrlCourseId = () => {}
) => {
  return [
    {
      title: "No.",
      dataIndex: ["course", "name"],
      key: "id",
      width: 70,
      align: "center",
      render: (v, row, id) => {
        return id + 1;
      },
    },
    {
      title: "Retro Question",
      dataIndex: ["retroInterviewClientDetail", "question"],
      key: "id",
      width: "25%",
      align: "center",
      render: (_, record) => {
        return (
          <div className="remark-cell">
            {record.retroInterviewClientDetail.question}
          </div>
        );
      },
    },
    {
      title: "Course name",
      dataIndex: ["retroInterviewClientDetail", "course", "name"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return (
          <div className="remark-cell">
            {record.retroInterviewClientDetail?.course?.name}
          </div>
        );
      },
    },
    {
      title: "Mata Kuliah",
      dataIndex: ["retroInterviewClientDetail", "coursesMatkull", "matkul"],
      key: "id",
      align: "center",
      render: (_, record) => {
        return (
          <div className="remark-cell">
            {record.retroInterviewClientDetail?.coursesMatkull?.matkul}
          </div>
        );
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "id",
      align: "center",
      width: "100px",
      render: (v, row) => {
        const courseId = row.retroInterviewClientDetail?.course?.id;
        const matkulId = row?.retroInterviewClientDetail?.coursesMatkull?.id;
        return (
          <div className="flex gap-1 justify-center">
            {row ? (
              <div>
                <Button
                  className="w-full"
                  type="primary"
                  //ghost
                  /*  href={""}
                  target="_blank" */
                  disabled={false}
                  onClick={() => {
                    // handleProgress(row.id);
                    getUrlCourseId(
                      courseId,
                      matkulId,
                      row.retroInterviewClientDetail?.course
                    );
                  }}
                >
                  URL
                </Button>
              </div>
            ) : (
              <></>
            )}
            {/* 
            {row.subMatkul.linkInternet ? (
              <div>
                <Button
                  className="w-full"
                  type="primary"
                  primary
                  href={row.subMatkul.linkInternet}
                  target="_blank"
                  onClick={() => {
                    handleProgress(row.id);
                  }}
                >
                  URL 2
                </Button>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        );
      },
    },
    {
      title: "Score",
      dataIndex: ["score"],
      key: "id",
      width: "100px",
      align: "center",
    },
    {
      title: "I've understood",
      dataIndex: [""],
      key: "id",
      align: "center",
      render: (v, row) => {
        return (
          <Switch
            onChange={(v) => {
              handleCheck(row.id);
            }}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultValue={row.isTalentUnderstand}
          />
        );
      },
    },
  ];
};

// status: NEW,SEND,PENDING, PASSED, FAILED
export const statusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "PASSED",
    label: "PASSED",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "FAILED",
    label: "FAILED",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
  {
    value: "PENDING",
    label: "PENDING",
    color: "text-[#616060]",
    bgColor: "bg-[#c2c2c2]",
  },
];
