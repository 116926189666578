import React, { useEffect, useState } from "react";
import SkeletonTable from "../../../components/general/SkeletonTable";
import { Modal, Pagination, Table } from "antd";
import AlertMiniPopUp from "../../admin/componets_global/AlertMiniPopUp";
import "./index.css";
import { AddProjectForm } from "./AddProjectForm";

export const ViewHistory = ({
  updateSuccess,
  setUpdateSuccess,
  show,
  form,
  closeModal,
  loading = false,
  changePagination,
  data,
  columns,
  roleName = "",
  clientName = "",
  total = 0,
  page = 0,
  search = "",
  handleSearch = () => {},
  handleAdd = (c, v) => {},
  handleEdit = () => {},
  editingForm,
  handleEditForm = (f, v) => {},
  handleResetForm,
  showForm,
  setShowForm,
  isEditing,
}) => {
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const totalData = total;

  useEffect(() => {
    if (!showForm) handleResetForm();
  }, [showForm]);

  return (
    <>
      <AddProjectForm
        form={form}
        show={showForm}
        updateSuccess={updateSuccess}
        setUpdateSuccess={setUpdateSuccess}
        closeModal={setShowForm}
        handleAdd={handleAdd}
        handleEditForm={handleEditForm}
        handleEdit={handleEdit}
        editingForm={editingForm}
        isEditing={isEditing}
      />
      <Modal
        width="90%"
        open={show}
        onCancel={() => closeModal(false)}
        footer={null}
        closeIcon={false}
      >
        {messageAlert?.message !== "" && (
          <AlertMiniPopUp
            messageAlert={messageAlert.message}
            isSuccess={messageAlert.status}
          />
        )}

        <div className="flex flex-col md:flex-row justify-between w-full mb-4">
          <div className="mb-4 md:mb-0">
            <p className="text-[20px] font-weight-600 mb-5">List Project</p>
            <div className="flex gap-5">
              <p className="text-[16px] font-weight-600 w-32 flex justify-between">
                <span>Role Name</span>
                <span>:</span>
              </p>
              <p className="text-[16px] font-weight-600">{roleName}</p>
            </div>
            <div className="flex gap-5">
              <p className="text-[16px] font-weight-600 w-32 flex justify-between">
                <span>Client Name</span>
                <span>:</span>
              </p>
              <p className="text-[16px] font-weight-600">{clientName}</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-end justify-end gap-3">
            <input
              type="text"
              placeholder="Search Role Name..."
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              className="border rounded-md w-full md:w-3/5 py-1 px-2"
            />
            <button
              className="bg-[#DD0000] text-white w-full md:w-[200px] py-1 font-bold rounded"
              onClick={() => {
                setShowForm(true);
              }}
            >
              Add
            </button>
          </div>
        </div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                sticky={true}
                scroll={{
                  y: 470,
                  x: 1200,
                }}
              />
            </div>
            <Pagination
              current={page + 1}
              total={totalData}
              showSizeChanger={false}
              onChange={(p) => changePagination(p)}
              className="flex justify-center"
            />
          </>
        )}
      </Modal>
    </>
  );
};
