import { Card, Input } from "antd";
import React, { useEffect, useState } from "react";

const ListChamp = ({ champ }) => {
  return (
    <Card className="mb-4 shadow">
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-2 ms-6 me-5 md:me-10">{champ.rownum}</div>
        <div className="col-span-6 flex items-center">
          <div className="w-[54px] h-[54px] md:w-[64px] md:h-[64px] bg-[#E53737] border-[3px] rounded-full border-[#fff] shadow-lg flex justify-center items-center mr-6 aspect-square">
            <div className="md:text-3xl text-2xl font-bold text-white">
              {champ.fullName[0].toUpperCase()}
            </div>
          </div>
          <div>
            <div className="font-semibold text-xs md:text-base text-[#4c4c4c]">
              {champ.fullName}
            </div>
            <div className="text-sm text-[#a8a8a8]">{champ.role || ""}</div>
          </div>
        </div>
        <div className="col-span-4 flex items-center justify-end mr-6">
          <div className="w-[35px] h-[35px] bg-[#D0CBFF] text-[#4F40F4] font-bold flex justify-center items-center mr-2 rounded-full text-sm aspect-square">
            XP
          </div>
          <div className="text-[#4C4C4C] font-bold">{champ.totalXp}</div>
        </div>
      </div>
    </Card>
  );
};

const ChampionList = ({ dataTalents }) => {
  const [resultData, setResultData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setResultData(dataTalents);
  }, [dataTalents]);

  useEffect(() => {
    const results = dataTalents.filter((talent) => {
      return talent.fullName.toLowerCase().includes(search.toLowerCase());
    });
    setResultData(results);
  }, [search]);

  return (
    <div className="mt-4 pb-6 px-2 md:px-8">
      <div className="mb-4 flex justify-between">
        <div className="font-bold">Leaderboard</div>
        <div>
          <Input
            className="w-full"
            placeholder="Enter your username"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={
              <img
                src="/assets/icons/search-icon.svg"
                className="cursor-pointer"
                alt="icon-search"
              />
            }
          />
        </div>
      </div>
      <div className="max-h-[500px] overflow-auto">
        {resultData &&
          resultData.map((champ, idx) => {
            return <ListChamp key={champ.id} champ={champ} idx={idx} />;
          })}
        {resultData.length === 0 ? (
          <Card className="shadow mb-2">
            <div className="text-center text-red-500 font-bold">
              Data not found.
            </div>
          </Card>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ChampionList;
