import React, { useState, useEffect } from "react";
import { Modal, Select, Switch, message } from "antd";
import { roleOptions, defaultTalent } from "./columns.state";
import AlertUtility from "../../../../components/Alert/Alert";
import MasterUserManagementRepo from "../../../../repositores/adminRepo/UserManagement";
import AlertMiniPopUp from "../../componets_global/AlertMiniPopUp";

const TitleModal = () => {
  return (
    <div className="mb-8 font-medium">
      <div className="mb-1 text-xl text-[#4C4C4C]">Edit User</div>
      <div className="text-xs text-[#A8A8A8]">Enter your data below!</div>
    </div>
  );
};

const SubmitButton = ({ onSubmit }) => {
  return (
    <div className="flex justify-end">
      <button
        onClick={() => onSubmit()}
        className={`rounded-md bg-[#DD0000] font-semibold text-white py-2 px-8`}
      >
        Submit
      </button>
    </div>
  );
};

const ModalEdit = ({ show, closeModal, talent }) => {
  const [dataTalent, setDataTalent] = useState(defaultTalent);
  const [messageAlert,setMessageAlert]= useState({message:"",status:"false"});

  useEffect(() => {
    if (show) {
      setDataTalent({
        name: talent.name,
        id: talent.key,
        email: talent.email,
        role: talent.roleId,
        status: talent.status.toLowerCase() == "active" ? true : false,
      });
    } else {
      setDataTalent(defaultTalent);
    }
  }, [show]);
  useEffect(()=>{},[messageAlert]) 

  const changeName = (name) => {
    setDataTalent((v) => {
      return {
        ...v,
        name,
      };
    });
  };

  const changeStatus = () => {
    setDataTalent((v) => {
      return {
        ...v,
        status: !v.status,
      };
    });
  };

  const changeRole = (role) => {
    setDataTalent((v) => {
      return {
        ...v,
        role,
      };
    });
  };

  const editUser = async () => {
    try {
      const payload = {
        id: dataTalent.id,
        fullname: dataTalent.name,
        enabled: dataTalent.status,
        roleId: dataTalent.role,
      };
      const { data } = await MasterUserManagementRepo.adminEditUser(payload);

      // if (data.status != 200) {
      //   AlertUtility.ErrorAlert({ title: data.message });
      // } else {
      //   AlertUtility.SuccessAlert({ title: data.message });
      // }
      if(data.status === 200){
        console.log(data)
          setMessageAlert({message:data?.message,status:true})
          setTimeout(() => {
              setMessageAlert({message:"",status:null})
          }, 1000);
      } else {
          console.log(data)
          setMessageAlert({message:data?.message ,status:false})
          setTimeout(() => {
              setMessageAlert({message:"",status:null})
          }, 1000);
      }
    } catch (error) {
      AlertUtility.ErrorAlert({ title: error.response.message });
    } finally { 
      setTimeout(()=>{
        closeModal(true);
      },2000)
      
    }
  };

  const onSubmit = () => {
    if (dataTalent.name == "") {
      console.log(dataTalent)
      setMessageAlert({message:dataTalent.message,status:true})
      setTimeout(() => {
          setMessageAlert({message:"",status:null})
      }, 1000)
    }

    editUser();
  };

  return (
    <>
      <Modal open={show} onCancel={() => closeModal(false)} footer={null}>
      {messageAlert?.message !=="" &&(
            <AlertMiniPopUp
            messageAlert={messageAlert.message}
            isSuccess={messageAlert.status}/>
         )}
        {/* title */}
        <TitleModal />        
        {/* main */}
        <div>
          <div className="flex items-center mb-2">
            <div className="w-2/6 font-medium">Name</div>
            <input
              type="text"
              className="border rounded-md w-full p-2"
              value={dataTalent.name}
              onChange={(e) => changeName(e.target.value)}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className="w-2/6 font-medium">Email</div>
            <input
              type="email"
              className="border rounded-md w-full p-2"
              disabled
              value={dataTalent.email}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className="w-2/6 font-medium">Role</div>
            <Select
              size="large"
              className="w-full"
              value={dataTalent.role}
              onChange={(v) => changeRole(v)}
              options={roleOptions}
            />
          </div>
          <div className="flex items-center mb-6">
            <div className="w-2/6 font-medium">Status</div>
            <div className="w-full">
              <Switch
                checked={dataTalent.status}
                style={{
                  backgroundColor: dataTalent.status ? "#DD0000" : "#979797",
                }}
                onChange={() => changeStatus()}
              />
            </div>
          </div>
        </div>

        {/* submit button */}
        <SubmitButton onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export default ModalEdit;
