import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Base from "./_router/Base";
import LogoRepo from "./repositores/adminRepo/LogoRepo";

const queryClient = new QueryClient();

function App() {
  const changeFavicon = (url) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const fetchAndSetFavicon = async () => {
    try {
      const response = await LogoRepo.getLogoFavicon();
      const logos = response.data?.data?.content;
      if (logos && logos.length > 0) {
        const imageFavicon = logos[0].name;
        changeFavicon(imageFavicon);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTitleApplication = async () => {
    try {
      const response = await LogoRepo.titleNotToken();
      const title = response.data?.data?.content;
      document.title = title[0].name;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTitleApplication();
    fetchAndSetFavicon();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Base />
    </QueryClientProvider>
  );
}

export default App;
