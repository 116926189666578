import { LeftSquareTwoTone, RightSquareTwoTone } from "@ant-design/icons";
import { Pagination } from 'antd';
import { useEffect } from "react";

const HistoryPagination = ({ nextPage, previousPage, currentPage, totalPages, onNextPage, onPrevPage }) => {


    return (
        <div className='flex justify-center py-3'>
            <button
            className='px-4'
            onClick={event => {
                //chcek if fisrt
                onPrevPage(event);
            }}
            disabled={previousPage}
            >
                <LeftSquareTwoTone
                twoToneColor={(!previousPage ? "#fc0303" : "#9ca3af" )}
                style={{ fontSize:25 }}
                />
            </button>
            {currentPage + 1} of {totalPages}
            <button
            className='px-4'
            onClick={event => {
                //check if last
                onNextPage(event);
            }}
            disabled={nextPage}
            >
                <RightSquareTwoTone
                twoToneColor={(!nextPage ? "#fc0303" : "#9ca3af" )}
                style={{ fontSize:25 }}
                />
            </button>
        </div>
    )
}

export const AntdPagination = ({ historyData, onPageChange }) => {
    
    function handlePageChange(pageNumber) {
        onPageChange(pageNumber);
    }
    
    useEffect(() => {
    }, [historyData]);
    /* 
    numberOfElements, item per page
    totalElement, total all item
    totalPages, total page
    */
    
    return (
        <div>
            <Pagination
            current={historyData.number + 1}
            total={(historyData.totalElements)}
            pageSize={historyData.size}
            onChange={handlePageChange}
            />
        </div>
    );
}

export default HistoryPagination;