import CallApi from "../../services/Request-Helper";

const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

/**
 * get challenge category 
 * @returns 
 */

const getListData = async (page=1,limit=10,search="") => {
    const url = `${API_BASE}/quiz-header/list?page=${page}&size=${limit}&title=${search}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const createTask = async (body) => {
    const url = `${API_BASE}/quiz-header`;
    const response = await CallApi({ url, method: "POST", data: body });
    return response;
}

const updateTask = async (body) => {
    const url = `${API_BASE}/quiz-header`;
    const response = await CallApi({ url, method: "PUT", data: body });
    return response;
}

const deleteTask = async (id) => {
    const body ={
        id: id
    }
    const url = `${API_BASE}/quiz-header`;
    const response = await CallApi({ url, method: "DELETE", data: body});
    return response;
}

const MasterTask = {
    getListData,
    createTask,
    deleteTask,
    updateTask
}

export default MasterTask