import React from "react";
import { Table } from "antd";

const TableListHistories = ({ dataTable, selectToDetail }) => {
  function formatDateTime(date) {
    let tempDate = new Date(date);

    let dateFormated = `${addLeadingZero(tempDate.getDate())}/${addLeadingZero(
      tempDate.getMonth() + 1
    )}/${tempDate.getFullYear()}`;
    return `${dateFormated}`;
  }

  function addLeadingZero(number) {
    return number < 10 ? `0${number}` : `${number}`;
  }

  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "5%",
    },
    {
      title: "Talent Name",
      dataIndex: "user",
      key: "user",
      width: "15%",
      render: (_, record) => {
        return (
          <span>{record?.user?.fullname ? record?.user?.fullname : "-"}</span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: ["user", "username"],
      key: "user",
      width: "15%",
    },
    {
      title: "Course Name",
      dataIndex: "course",
      key: "course",
      width: "10%",
      render: (_, record) => {
        return <span>{record?.course?.name ? record?.course?.name : "-"}</span>;
      },
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      width: "10%",
      render: (_, record) => {
        return <span>{record?.batch?.name ? record?.batch?.name : "-"}</span>;
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      width: "10%",
      render: (_, record) => {
        if (record?.startDate) {
          let date = formatDateTime(record?.startDate);
          return <span>{date}</span>;
        }
        return "-";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      width: "10%",
      render: (_, record) => {
        if (record?.endDate) {
          let date = formatDateTime(record?.endDate);
          return <span>{date}</span>;
        }
        return "-";
      },
    },
    {
      title: "Percentage Courses",
      dataIndex: "percentageCourses",
      key: "percentageCourses",
      align: "center",
      width: "10%",
      render: (_, record) => {
        return (
          <span>
            {record?.percentageCourses ? record?.percentageCourses : "-"}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            <div
              onClick={() => {
                selectToDetail(record);
              }}
              className="mr-[30px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">View Score</span>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={dataTable}
        columns={columns}
        pagination={false}
        rowKey="id"
        scroll={{
          x: 1700,
          y: 520,
        }}
      />
    </>
  );
};

export default TableListHistories;
