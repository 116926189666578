import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListCourses = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/course/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addCourse = async (payload) => {
  const url = `${API_BASE}/course`;
  const response = await CallApi({ url, method: "POST", data: payload });
  return response;
};

const updateCourse = async (payload) => {
  const url = `${API_BASE}/course`;
  const response = await CallApi({ url, method: "PUT", data: payload });
  return response;
};

const deleteCourse = async (id) => {
  const body = {
    id: id,
  };
  const url = `${API_BASE}/course/${id}`;
  const response = await CallApi({ url, method: "DELETE", data: body });
  return response;
};

const getCourse = async (id) => {
  const url = `${API_BASE}/course/${id}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getMatkulByCourse = async (
  courseId,
  page = 0,
  size = 10,
  termin = undefined,
  levelID = undefined,
  matkul = undefined
) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof termin != "undefined" && termin) {
    params["termin"] = termin;
  }
  if (typeof levelID != "undefined" && levelID.length) {
    params["levelID"] = levelID;
  }
  if (typeof matkul != "undefined" && matkul.length) {
    params["matkul"] = matkul;
  }
  const url = `${API_BASE}/matkul/list-admin/courseid/${courseId}?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addMatkul = async (payload) => {
  const url = `${API_BASE}/matkul`;
  const response = await CallApi({ url, method: "POST", data: payload });
  return response;
};

const updateMatkul = async (payload) => {
  const url = `${API_BASE}/matkul`;
  const response = await CallApi({ url, method: "PUT", data: payload });
  return response;
};

const deleteMatkul = async (id) => {
  const body = {
    id: id,
  };
  const url = `${API_BASE}/matkul/${id}`;
  const response = await CallApi({ url, method: "DELETE", data: body });
  return response;
};

const getMatkul = async (id) => {
  const url = `${API_BASE}/matkul/${id}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListSubMatkul = async (id, page = 0, size = 10, name = undefined) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["subMatkul"] = name;
  }
  const url = `${API_BASE}/sub-matkul/list-admin/matkulId/${id}?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addSubMatkul = async (payload) => {
  const url = `${API_BASE}/sub-matkul`;
  const response = await CallApi({ url, method: "POST", data: payload });
  return response;
};

const updateSubMatkul = async (payload) => {
  const url = `${API_BASE}/sub-matkul`;
  const response = await CallApi({ url, method: "PUT", data: payload });
  return response;
};

const deleteSubMatkul = async (id) => {
  const body = {
    id: id,
  };
  const url = `${API_BASE}/sub-matkul/${id}`;
  const response = await CallApi({ url, method: "DELETE", data: body });
  return response;
};

const getListlevel = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page,
    size: size,
    type: "LMS_LEVEL",
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListHistories = async (
  page = 0,
  size = 10,
  courseName = undefined,
  batchName = undefined,
  talentName = undefined,
  startDate,
  endDate
) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof courseName != "undefined" && courseName.length) {
    params["courseName"] = courseName;
  }
  if (typeof batchName != "undefined" && batchName.length) {
    params["batchName"] = batchName;
  }
  if (typeof talentName != "undefined" && talentName.length) {
    params["talentName"] = talentName;
  }
  if (startDate) {
    params["startDate"] = startDate;
  }
  if (endDate) {
    params["endDate"] = endDate;
  }
  const url = `${API_BASE}/history-courses/list-admin?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const injectAssign = async (payload) => {
  const url = `${API_BASE}/user-courses/injectUserCourse`;
  const response = await CallApi({
    url,
    method: "POST",
    data: payload,
    type: true,
  });
  return response;
};

const injectCourse = async (payload, type) => {
  let url =
    type === "course"
      ? `${API_BASE}/inject/ug/lms-enhance`
      : `${API_BASE}/inject/ug/pilihan-ganda`;
  const response = await CallApi({
    url,
    method: "POST",
    data: payload,
    type: true,
  });
  return response;
};

const getDetailHistory = async (courseId, userId, page = 0, size = 10) => {
  let params = {
    page: page,
    size: size,
  };
  const url = `${API_BASE}/report/course/list-admin/course-id/${courseId}/user-id/${userId}?${createSearchParams(
    params
  )}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getDetailSubMatkul = async (page = 0, size = 10, subMatkulId) => {
  let params = {
    page: page,
    size: size,
  };
  const url = `${API_BASE}/sub-matkull-pg/list-admin/sub-matkul/${subMatkulId}?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const updateQuestionSubMatkul = async (payload) => {
  const url = `${API_BASE}/sub-matkull-pg/update-admin`;
  const response = await CallApi({ url, method: "PUT", data: payload });
  return response;
};

const getDownloadFileHistory = async (startDate, endDate) => {
  let params = {
    startDate: startDate,
    endDate: endDate,
  };

  if (startDate) {
    params["startDate"] = startDate;
  }
  if (endDate) {
    params["endDate"] = endDate;
  }

  const url = `${API_BASE}/history-courses/list-admin/download?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const AdminLmsRepo = {
  getListCourses,
  getDownloadFileHistory,
  addCourse,
  updateCourse,
  deleteCourse,
  getCourse,
  getMatkulByCourse,
  getMatkul,
  addMatkul,
  updateMatkul,
  deleteMatkul,
  getListSubMatkul,
  addSubMatkul,
  updateSubMatkul,
  deleteSubMatkul,
  getListlevel,
  getListHistories,
  injectAssign,
  getDetailHistory,
  injectCourse,
  getDetailSubMatkul,
  updateQuestionSubMatkul,
};

export default AdminLmsRepo;
