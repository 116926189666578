import React, { useEffect, useState } from "react";
import CertificateTable from "./components/Table";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import ActionBarMaster from "../componets_global/ActionBarMaster";
import ModalCertificate from "./components/Modal";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Modal, Pagination, message } from "antd";
import {
  resetStateForm,
  changeState,
  changeStatusOverview,
  getAllCertificate,
  addCertificateTemplate,
  editCertificateTemplate,
  deleteCertificateTemplate,
  getCertificateById,
  getServiceLookup,
  previewCertificateTemplate,
} from "../../../features/cerificateTemplateSlice";
import { ExclamationCircleFilled } from "@ant-design/icons";

const CertificateTemplate = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const {
    certificates,
    formSearch,
    isLoading,
    isOverview,
    pagination,
    templates,
    types,
    valueForm,
  } = useSelector((state) => state.certificateTemplate);

  const fetchAllCertificate = () => {
    let params = {
      currentPage: pagination?.currentPage,
      limit: pagination?.limit,
    };
    if (formSearch.length > 0) params.keyword = formSearch;
    dispatch(getAllCertificate(params));
  };

  const onChangePagination = (current) => {
    let nextPagination = pagination;
    nextPagination = { ...nextPagination, currentPage: current };
    dispatch(
      changeState({
        stateProp: "pagination",
        valueProp: nextPagination,
      })
    );
  };

  const showAddModal = async () => {
    await dispatch(resetStateForm());
    await setOpenModalAdd(true);
  };
  const handleCancelModalAdd = () => {
    form.resetFields();
    setOpenModalAdd(false);
  };

  const showEditModal = async (id) => {
    await dispatch(getCertificateById(id));
    await setOpenModalEdit(true);
  };

  const handleCancelModalEdit = async () => {
    await form.resetFields();
    await setOpenModalEdit(false);
  };

  const handleNextButton = () => {
    dispatch(changeStatusOverview());
  };

  const handleSubmitAdd = async () => {
    const responseAddCertificate = await dispatch(
      addCertificateTemplate(valueForm)
    );
    const isSuccess = responseAddCertificate?.payload?.status === 200;
    const messageContent = responseAddCertificate?.payload?.message;
    messageApi.open({
      type: isSuccess ? "success" : "error",
      content: messageContent,
    });
    await dispatch(resetStateForm());
    await handleCancelModalAdd();
    setTimeout(() => {
      fetchAllCertificate();
    }, 2000);
  };

  const handlePreviousButton = () => {
    dispatch(changeStatusOverview());
  };

  const showDeleteConfirm = (id) => {
    setOpenModalDelete(true);
    setDeleteId(id);
  };

  const handleDeleteCancel = () => {
    setOpenModalDelete(false);
  };

  const handleDeleteConfirm = async () => {
    if (!isLoading) {
      const responseDelete = await dispatch(
        deleteCertificateTemplate(deleteId)
      );
      const isSuccess = responseDelete?.payload?.status === 200;
      const messageContent = responseDelete?.payload?.message;
      messageApi.open({
        type: isSuccess ? "success" : "error",
        content: messageContent,
      });
      setTimeout(() => {
        fetchAllCertificate();
      }, 2000);
    }
    setOpenModalDelete(false);
  };

  const handleSubmitEdit = async () => {
    const responseEditCertificate = await dispatch(
      editCertificateTemplate(valueForm)
    );
    const isSuccess = responseEditCertificate?.payload?.status === 200;
    const messageContent = responseEditCertificate?.payload?.message;
    messageApi.open({
      type: isSuccess ? "success" : "error",
      content: messageContent,
    });
    await dispatch(resetStateForm());
    await handleCancelModalEdit();
    setTimeout(() => {
      fetchAllCertificate();
    }, 2000);
  };

  const actionPreview = (url) => {
    dispatch(previewCertificateTemplate(url));
  };

  const changeValueState = (event) => {
    dispatch(
      changeState({ stateProp: "formSearch", valueProp: event.target.value })
    );
    if (formSearch) {
      let page = pagination.currentPage >= 0;
      const updatedPagination = { ...pagination, currentPage: page };
      dispatch(
        changeState({ stateProp: "pagination", valueProp: updatedPagination })
      );
    }
  };

  useEffect(() => {
    fetchAllCertificate();
    dispatch(getServiceLookup("TEMPLATE_CERTIFICATE"));
    dispatch(getServiceLookup("TYPE_CERTIFICATE"));
  }, [formSearch, pagination.currentPage]);

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Certificates Template</TitleMasterAdmin>
        <ActionBarMaster
          buttonTitle="Add Certificate"
          placeholder="Search Certificate"
          actionButton={showAddModal}
          onSearch={changeValueState}
        />
        <CertificateTable
          data={certificates}
          actionEdit={showEditModal}
          actionDelete={showDeleteConfirm}
          actionPreview={actionPreview}
        />
        <div className="flex justify-center mt-5">
          <Pagination
            defaultCurrent={pagination?.currentPage}
            total={pagination?.totalData}
            defaultPageSize={pagination?.limit}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        </div>
        <Modal
          open={openModalDelete}
          onOk={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          width={500}
          footer={null}
          closable={false}
          keyboard={false}
        >
          <div className="flex items-center gap-2">
            <ExclamationCircleFilled
              style={{ fontSize: "20px", color: "#faad14" }}
            />
            <p className="text-black text-[16px] font-medium">
              Are you sure to delete this Certificate Template ?
            </p>
          </div>
          <div className="flex justify-end mt-5 gap-[10px]">
            <Button onClick={() => setOpenModalDelete(false)}>No</Button>
            <Button onClick={handleDeleteConfirm} type="primary" danger>
              Yes
            </Button>
          </div>
        </Modal>
        {!isLoading && (
          <ModalCertificate
            nameForm="addCertificate"
            open={openModalAdd}
            instanceForm={form}
            handleCancel={handleCancelModalAdd}
            modalTitle={
              !isOverview ? "Add Certificate" : "Add Certificate - Overview"
            }
            instruction={
              !isOverview
                ? "Enter your data below!"
                : "Please check your data below!"
            }
            buttonActionTitle={!isOverview ? "Next" : "Submit"}
            buttonAction={!isOverview ? handleNextButton : handleSubmitAdd}
            buttonPrevious={handlePreviousButton}
            templateList={templates}
            typeList={types}
          />
        )}
        {!isLoading && (
          <ModalCertificate
            nameForm="editCertificate"
            open={openModalEdit}
            instanceForm={form}
            handleCancel={handleCancelModalEdit}
            modalTitle={"Edit Certificate"}
            instruction="Edit your data below!"
            buttonActionTitle="Submit"
            buttonAction={handleSubmitEdit}
            valueEdit={valueForm}
            templateList={templates}
            typeList={types}
          />
        )}
      </div>
    </>
  );
};

export default CertificateTemplate;
