import { Form, message, Pagination, Modal, Input, Button, Radio, Select, Table, Card, Flex, Typography, Progress, Menu } from "antd";
import React, { useEffect, useState } from "react";
import TitleMasterAdmin from "../../componets_global/TitleMaster";
import deleteIcon from "../../../../assets/icons/delete.png";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import "./QuizPage.css"

const { Option } = Select;
const { Title } = Typography;

const QuizData = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [formCategory] = Form.useForm();
    const navigate = useNavigate();
    const [titleModal, setTitleModal] = useState("Add Category");
    const [formType, setFormType] = useState("add");
    const [detailCategory, setDetailCategory] = useState(undefined);
    const [valueCategoryType, setValueCategoryType] = useState(1);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const [parentSelected, setParentSelected] = useState(undefined)
    const [dataSubCategory, setDataSubCategory] = useState([])

    const dummyData = [
        {
            key: 1,
            id: 1,
            name: "Javascript Basic",
            totalAssign: 2,
            status: 'POSTED',
            avarageResult: 50,
            child: [
                {
                    key: 1,
                    id: 1,
                    parentId: 1,
                    question: `<p>What is the output of the following JavaScript code?</p><br/><pre style="background-color: #fbf4f4;padding: 19px; "> console.log(typeof null); </pre>`,
                    answers: [
                        {
                            id: 1,
                            questionId: 1,
                            text: `"null"`,
                            isTrue: false,
                        },
                        {
                            id: 2,
                            questionId: 1,
                            text: `"object"`,
                            isTrue: true,
                        },
                        {
                            id: 3,
                            questionId: 1,
                            text: `"undefined"`,
                            isTrue: false,
                        },
                        {
                            id: 4,
                            questionId: 1,
                            text: `"number"`,
                            isTrue: false,
                        },
                        {
                            id: 5,
                            questionId: 1,
                            text: `"function"`,
                            isTrue: false,
                        }
                    ]
                },
                {
                    key: 2,
                    id: 2,
                    parentId: 1,
                    question: `Which of the following is the correct way to declare a variable in JavaScript?`,
                    answers: [
                        {
                            id: 1,
                            questionId: 2,
                            text: `var myVariable;`,
                            isTrue: true,
                        },
                        {
                            id: 2,
                            questionId: 2,
                            text: `declare myVariable;`,
                            isTrue: false,
                        },
                        {
                            id: 3,
                            questionId: 2,
                            text: `create myVariable`,
                            isTrue: false,
                        },
                        {
                            id: 4,
                            questionId: 2,
                            text: `myVariable var;`,
                            isTrue: false,
                        },
                        {
                            id: 5,
                            questionId: 2,
                            text: `func myVariable;`,
                            isTrue: false,
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            key: 2,
            status: 'DRAFT',
            name: "Framework React JS Fundamental"
        },
    ]

    const dummyDataCategory = [
        {
            key: 1,
            id: 1,
            name: "Parent Category 1",
            child: [
                {
                    key: 1,
                    id: 4,
                    parentId: 1,
                    name: "sub 1 of parent category 1"
                },
                {
                    id: 5,
                    key: 2,
                    parentId: 1,
                    name: "sub 2 of parent category 1"
                }
            ]
        },
        {
            id: 2,
            key: 2,
            name: "Parent Category 2",
            child: []
        },
        {
            id: 3,
            key: 3,
            name: "Parent Category 3",
            child: []
        }
    ]
    let dummyDataSubCategory = []


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '',
            dataIndex: '',
            align: 'center',
            key: 'x',
            render: (_, record) => {
                const onClickMenuAdd = (e, _record) => {
                    if (e.key == '1') {
                        setIsOpenModal(true)
                    } else if (e.key == '2') {
                        createQuestion(_record.id)
                    } else if (e.key == '7') {
                        reportQuiz(_record.id)
                    }
                }
                let childMenuDraft = [
                    {
                        label: 'Edit',
                        key: '1',
                    },
                    {
                        label: 'Add new Question',
                        key: '2',
                    },
                    {
                        label: 'Reuse from question bank',
                        key: '3',
                    },
                    {
                        label: 'Import Question',
                        key: '4',
                    },
                    {
                        label: 'Delete',
                        key: '5',
                    },
                ]

                let childMenuPosted = [
                    {
                        label: 'Assign',
                        key: '6',
                    },
                    {
                        label: 'Detail',
                        key: '7',
                    },
                ]
                return (
                    <div className="flex justify-end pr-[14px]">

                        <div>
                            <Menu
                                mode="vertical"
                                onClick={(e) => {
                                    onClickMenuAdd(e, record)
                                }}
                                items={[
                                    {
                                        label: 'Action',
                                        key: 'menu-1',
                                        children: record.status == 'DRAFT' ? childMenuDraft : childMenuPosted,
                                    },
                                ]}
                                defaultSelectedKeys={['menu-1']}
                            />
                        </div>
                    </div>
                );
            },
        },
    ];

    const columnsDetail = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            dataIndex: 'detail',
            key: 'detail',
            align: 'center'
        },
    ]
    const dataDummyDetail = [
        {
            name: "Total Question",
            detail: 20
        },
        {
            name: "Total Participant",
            detail: 15
        },
        {
            name: "PIC",
            detail: "John Doe"
        }
    ]

    const columnsChild = [
        {
            title: '',
            dataIndex: 'question',
            key: 'question',
            render: (_, record) => {
                return (
                    <>
                        <div className="flex flex-col justify-start lg:flex-row gap-4">
                            <Card>
                                <div>
                                    <Progress type="circle" format={() => 'Status'} percent={100}
                                        success={{
                                            percent: 30,
                                        }} />
                                    <br />
                                    <Flex gap="small" className="mt-[20px]">
                                        <div className="h-[10px] w-[10px] bg-[#1676ff] mt-[6px]"></div>
                                        <span>In Progress 10</span>
                                    </Flex>
                                    <Flex gap="small">
                                        <div className="h-[10px] w-[10px] bg-[#52c41a] mt-[6px]"></div>
                                        <span>Completed 5</span>
                                    </Flex>
                                </div>
                            </Card>
                            <Card className="w-full">
                                <Table dataSource={dataDummyDetail} columns={columnsDetail} pagination={false}></Table>
                            </Card>
                        </div>
                    </>
                )
            }
        },
    ];

    const closeModal = (modal = "form") => {
        setIsOpenModal(false)
    };
    const showAddModal = (id) => {
        setIsOpenModal(true)
    };

    const onChangeType = (el) => {
        setValueCategoryType(el.target.value);
    };

    const createQuestion = (id) => {
        navigate({
            pathname: `/admin/question-bank/form`,
        });
    }

    const reportQuiz = (id) => {
        navigate({
            pathname: `/admin/quiz/report/${id}`
        })
    }

    const onChangePage = (el) => {

    };

    const handleMenuClick = (e) => {
    };


    const handleScrollEventAccount = (event) => {

    };



    const onSelected = (el) => {
        dummyDataCategory.forEach(data => {
            if (data.id == el) {
                if (data.child.length) {
                    data.child.forEach(_child => {
                        dummyDataSubCategory.push(_child)
                    })
                } else {
                    dummyDataSubCategory = []
                }
            }
        })
        setDataSubCategory(dummyDataSubCategory)
        setParentSelected(el)

    }


    useEffect(async () => {

    }, []);

    return (
        <>
            {contextHolder}
            <div className="w-full h-screen px-4 py-5">
                <TitleMasterAdmin>Quiz List</TitleMasterAdmin>
                <Flex gap="middle" className="mt-[30px]">
                    <Card className="h-[150px] w-[20%]">
                        <span role="img" aria-label="file-sync" style={{ fontSize: "30px;" }}><svg viewBox="64 64 896 896" focusable="false" data-icon="file-sync" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M296 256c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm192 200v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8zm-48 396H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm104.1-115.6c1.8-34.5 16.2-66.8 40.8-91.4 26.2-26.2 62-41 99.1-41 37.4 0 72.6 14.6 99.1 41 3.2 3.2 6.3 6.6 9.2 10.1L769.2 673a8 8 0 003 14.1l93.3 22.5c5 1.2 9.8-2.6 9.9-7.7l.6-95.4a8 8 0 00-12.9-6.4l-20.3 15.8C805.4 569.6 748.1 540 684 540c-109.9 0-199.6 86.9-204 195.7-.2 4.5 3.5 8.3 8 8.3h48.1c4.3 0 7.8-3.3 8-7.6zM880 744h-48.1c-4.3 0-7.8 3.3-8 7.6-1.8 34.5-16.2 66.8-40.8 91.4-26.2 26.2-62 41-99.1 41-37.4 0-72.6-14.6-99.1-41-3.2-3.2-6.3-6.6-9.2-10.1l23.1-17.9a8 8 0 00-3-14.1l-93.3-22.5c-5-1.2-9.8 2.6-9.9 7.7l-.6 95.4a8 8 0 0012.9 6.4l20.3-15.8C562.6 918.4 619.9 948 684 948c109.9 0 199.6-86.9 204-195.7.2-4.5-3.5-8.3-8-8.3z"></path></svg></span>
                        <div className="pt-[5px]">
                            Quiz in Progress
                        </div>
                        <Title level={2}>17</Title>
                        <Progress percent={17} />
                    </Card>
                    <Card className="h-[150px] w-[20%]">
                        <span role="img" aria-label="file-sync" style={{ fontSize: "30px;" }}><svg viewBox="64 64 896 896" focusable="false" data-icon="file-protect" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M644.7 669.2a7.92 7.92 0 00-6.5-3.3H594c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.8-5.3 0-12.7-6.5-12.7h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-22.9-31.9zM688 306v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm184 458H208V148h560v296c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h312c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm402.6-320.8l-192-66.7c-.9-.3-1.7-.4-2.6-.4s-1.8.1-2.6.4l-192 66.7a7.96 7.96 0 00-5.4 7.5v251.1c0 2.5 1.1 4.8 3.1 6.3l192 150.2c1.4 1.1 3.2 1.7 4.9 1.7s3.5-.6 4.9-1.7l192-150.2c1.9-1.5 3.1-3.8 3.1-6.3V538.7c0-3.4-2.2-6.4-5.4-7.5zM826 763.7L688 871.6 550 763.7V577l138-48 138 48v186.7z"></path></svg></span>
                        <div className="pt-[5px]">
                            Quiz completed
                        </div>
                        <Title level={2}>90</Title>
                        <Progress percent={90} strokeColor={'green'} />

                    </Card>
                </Flex>

                <div className="flex flex-col justify-end lg:flex-row gap-4">

                    <div className="flex flex-row gap-4 lg:mb-0">
                        <Input
                            className="w-[300px]"
                            placeholder="Search"
                            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
                        />
                        <Select
                            className="w-full"
                            showSearch
                            placeholder="Choose a Parent Category"
                            optionFilterProp="children"
                            dropdownRender={(menu) => (
                                <div onWheel={handleScrollEventAccount}>{menu}</div>
                            )}
                        >
                            {dummyData.map((valParent) => (
                                <Option key={valParent.id} value={valParent.id}>
                                    {valParent.name}
                                </Option>
                            ))}
                        </Select>
                        <ActionBarButtonOnly
                            buttonTitle="Add Quiz"
                            placeholder="Add Quiz"
                            actionButton={showAddModal}
                        />
                    </div>
                </div>

                <div className="mt-[20px] w-full">
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="pl-[40px] w-full">
                                    <Table id="childTableQuizNoHover" columns={columnsChild} dataSource={record.child} pagination={{ pageSize: 1 }}></Table>
                                </div>
                            )
                        }}
                        pagination={false}
                        dataSource={dummyData}
                    />
                </div>
                <div className="flex justify-center mt-5">

                    <Pagination
                        current={page}
                        onChange={onChangePage}
                        pageSize={limit}
                        total={totalItem}
                        showSizeChanger={false}
                    />
                </div>
            </div>
            <Modal
                onCancel={() => closeModal()}
                title={titleModal}
                open={isOpenModal}
                footer={
                    <>
                        <div className="flex justify-between mt-[20px]">
                            <Button key="back" danger onClick={() => closeModal()}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "#DD0000", color: "white" }}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                }
            >
                <div className="container p-[10px] my-[30px]">
                    <Form
                        labelCol={{ flex: "110px" }}
                        labelAlign="left"
                        form={formCategory}
                        colon={false}
                        wrapperCol={{ flex: 1 }}
                    >
                        <Form.Item

                            label="Parent Category"
                            name="parentCategory"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select Parent Category",
                                },
                            ]}
                        >
                            <Select
                                className="w-full"
                                showSearch
                                placeholder="Choose a Parent Category"
                                optionFilterProp="children"
                                onChange={onSelected}
                                dropdownRender={(menu) => (
                                    <div onWheel={handleScrollEventAccount}>{menu}</div>
                                )}
                            >
                                {dummyDataCategory.map((valParent) => (
                                    <Option key={valParent.id} value={valParent.id}>
                                        {valParent.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {
                            parentSelected !== undefined ? (
                                <>
                                    <Form.Item

                                        label="Category"
                                        name="category"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Select Category",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="w-full"
                                            showSearch
                                            placeholder="Choose a Category"
                                            optionFilterProp="children"
                                            dropdownRender={(menu) => (
                                                <div onWheel={handleScrollEventAccount}>{menu}</div>
                                            )}
                                        >
                                            {dataSubCategory.map(_child =>
                                            (<Option key={'child' + _child.id} value={_child.id}>
                                                {_child.name}
                                            </Option>)
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Quiz Name"
                                        name="quizName"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input quiz name",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Please input quiz name"
                                        />
                                    </Form.Item>
                                </>
                            ) : <></>
                        }


                    </Form>
                </div>
            </Modal>
        </>
    );
};

const ActionBarButtonOnly = ({ buttonTitle, actionButton }) => {
    return (
        <div className=" flex justify-center">
            {actionButton && (
                <div
                    onClick={actionButton}
                    className="bg-red-600  w-[150px] h-[30px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
                >
                    {buttonTitle}
                </div>
            )}
        </div>
    );
};

export default QuizData;
