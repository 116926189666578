import React, { createContext, useEffect, useState } from "react";
import { sideBarMenu, stateColor } from "./sidebar/data";

const TalentHelperContext = createContext();

const TalentHelperProvider = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState(sideBarMenu["dashboard"]);
  const [isActive, setIsActive] = useState("Dashboard");
  const [profileId, setProfileId] = useState("");
  const [user, setUser] = useState({});

  const selectMenu = (menu) => {
    setIsActive(menu);
    setSelectedMenu(sideBarMenu[menu]);
  };

  const handleUser = (data) => {
    if (!data) return;
    setUser(data);
  };

  useEffect(() => {}, [selectedMenu]);

  return (
    <TalentHelperContext.Provider
      value={{
        isActive,
        selectedMenu,
        selectMenu,
        setProfileId,
        profileId,
        sideBarMenu,
        stateColor,
        user,
        handleUser,
      }}
    >
      {children}
    </TalentHelperContext.Provider>
  );
};

export { TalentHelperContext, TalentHelperProvider };
