import apiClient from "../../../../shared/api/api.client";

export class EventService {
  get = {
    async eventDetails({ queryKey }) {
      const params = queryKey[1];
      try {
        return apiClient.instance.get(`/event/journey-user/${params.id}`).then(res => res.data);
      } catch (error) {
        throw error;
      }
    },
  };

  post = {
    async verificationUri(fileUrl, { eventId, taskId }) {
      try {
        return apiClient.instance.post(
          `/event/journey/events/${eventId}/tasks/${taskId}/verification`,
          { url: fileUrl }
        ).then(res => res.data);
      } catch (error) {
        throw error;
      }
    },
  };

  put = {
    async claimRewards(eventId) {
      try {
        return apiClient.instance.put(
          `/event-participant-task/user-claim-reward/event/${eventId}`
        ).then(res => res.data);
      } catch (error) {
        throw error;
      }
    },
  };
}
