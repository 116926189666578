import { QuizPage } from "../submodules/quiz/page/QuizPage";
import { JourneyPage } from "./JourneyPage";

export const JourneyRoutes = {
  BasePath: "/talent/journey",
  Pages: [
    /**
     * @note This is the index page that shows the list of events journey.
     */
    {
      url: "",
      component: <JourneyPage />,
    },
    /**
     * @note This is the page where a specific event modal is opened.
     */
    {
      url: "events/:eventId",
      component: <JourneyPage />,
    },
    /**
     * @note This is the page where a specific event modal is opened and the
     * verification uploader is being shown.
     */
    {
      url: "events/:eventId/verify/:attendanceOrCertificate/:taskId",
      component: <JourneyPage />,
    },
  ],
  /**
   * @note This is the page for a quiz whom id was taken from the related
   * event.
   */
  QuizPage: {
    url: "events/:eventId/quizes/:quizId",
    component: <QuizPage />,
  },
};

export const createDefaultRouteForJourney = () => {
  return JourneyRoutes.BasePath.replace(":enablerOrDeveloper", "developer");
};
