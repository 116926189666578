import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListBadges = async (page = 0, size = 10, name = undefined) => {
    let params = {
        page: page,
        size: size
    }
    if (typeof name != 'undefined' && name.length) {
        params['name'] = name
    }
    const url = `${API_BASE}/badges/list?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const editBadges = async (body) => {
    const url = `${API_BASE}/badges`;
    const response = await CallApi({ url, method: "PUT", data: body });
    return response;
}

const BadgesRepo = {
    getListBadges,
    editBadges
}

export default BadgesRepo