import React, { useState } from "react";
import { Modal, Typography, Form, Input } from "antd";

const ModalEditBadges = (props) => {
  const {
    open,
    handleCancel,
    formBadges,
    valueEdit,
    modalTitle,
    instruction,
    buttonAction,
  } = props;

  const { Text } = Typography;

  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = () => {
    const { name, xp_point } = formBadges.getFieldsValue();
    const isNameValid = Boolean(name);
    const isXpPointValid = Boolean(xp_point);
    setIsFormValid(isNameValid && isXpPointValid);
  };

  return (
    <div className="bg">
      <Modal open={open} footer={null} onCancel={handleCancel}>
        <div className="mb-8">
          <h1 className="text-2xl font-bold">{modalTitle}</h1>
          <Text className="text-xs" type="secondary">
            {instruction}
          </Text>
        </div>
        <div>
          <Form
            name="basic"
            labelAlign="left"
            form={formBadges}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 17 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={valueEdit}
            colon={false}
            autoComplete="off"
          >
            <Form.Item label="Level" name="level" style={{ align: "left" }}>
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input placeholder="Enter name" onChange={checkFormValidity} />
            </Form.Item>

            <Form.Item
              label="XP Point"
              name="xp_point"
              rules={[
                {
                  required: true,
                  message: "XP Point is required",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Input XP Point"
                onChange={checkFormValidity}
              />
            </Form.Item>

            <Form.Item className="flex justify-end px-6">
              <button
                className={`${
                  !isFormValid ? "bg-slate-400" : "bg-red-600"
                }  w-[150px] h-[30px] text-white rounded`}
                disabled={!isFormValid}
              >
                {buttonAction}
              </button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalEditBadges;
