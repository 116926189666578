import React, { useState, useEffect } from "react";
import { Pagination, Table } from "antd";
import { COLUMNS_PENDING } from "./columns.state";
import AlertUtility from "../../../../components/Alert/Alert";
import SkeletonTable from "../../../../components/general/SkeletonTable";
import MasterUserManagementRepo from "../../../../repositores/adminRepo/UserManagement";

const UserPending = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const [totalData, setTotalData] = useState(0);

  const onClickResend = (record) => {
    setLoading(true);
    resendInviteUser(record.email);
  };

  const resendInviteUser = async (email) => {
    try {
      const urlFe = `${process.env.REACT_APP_BASE_FE}activate-account?email=${email}`;
      const payload = {
        email,
        urlFe,
      };
      const { data } = await MasterUserManagementRepo.adminResendInvite(
        payload
      );
      if (data.status != 200) {
        AlertUtility.ErrorAlert({ title: data.message });
      } else {
        AlertUtility.SuccessAlert({
          title: "Success resend. Please check your email!.",
        });
      }
    } catch (error) {
      AlertUtility.ErrorAlert({ title: error.response.message });
    } finally {
      getDataUser(page);
    }
  };

  const getDataUser = async (p = 0) => {
    try {
      setLoading(true);
      const { data } = await MasterUserManagementRepo.getUserPending(p);
      const result = data?.data?.content?.map((user) => {
        return {
          key: user.id,
          image: "",
          name: user.fullname,
          email: user.username,
          status: user.status,
          action: "",
        };
      });
      setPage(data?.data?.number);
      setDataUser(result);
      setTotalData(data?.data?.totalElements);
    } catch (error) {
      setPage(0);
      setDataUser(0);
      setDataUser([]);
      AlertUtility.ErrorAlert({ title: error.response?.message });
    } finally {
      setLoading(false);
    }
  };

  const changePagination = (p) => {
    getDataUser(p - 1);
  };

  const renderColumnsButton = () => {
    columns[4].render = (_, record) => (
      <div className="flex justify-center w-full">
        <div
          onClick={() => onClickResend(record)}
          className={`items-center justify-center rounded bg-[#DD0000] text-white font-semibold flex w-10/12 py-1 cursor-pointer`}
        >
          <img src="/assets/icons/resend.svg" className="mr-2" /> Resend
        </div>
      </div>
    );
  };

  useEffect(() => {
    getDataUser();
    renderColumnsButton();
  }, []);

  const columns = COLUMNS_PENDING;

  return (
    <div className="pb-6">
      {loading ? (
        <SkeletonTable />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={dataUser}
            pagination={false}
            className="font-semibold mb-4"
          ></Table>
          <Pagination
            current={page + 1}
            total={totalData}
            showSizeChanger={false}
            onChange={(p) => changePagination(p)}
            className="flex justify-center"
          />
        </>
      )}
    </div>
  );
};

export default UserPending;
