import { DetailContainer } from "./DetailContainer";
import { EventAttribute } from "../EventAttribute";

const Champion = ({ label, winner: { name, coin, exp } }) => {
  return (
    <div className="grid grid-cols-4">
      <p className="text-xs col-span-1">{label}</p>
      <p className="text-xs col-span-2">{name}</p>
      <div className="grid grid-cols-2 gap-4">
        <EventAttribute
          image={{ alt: "coin", url: "/assets/icons/coin.svg" }}
          label={{ color: "text-[#FE9923]", text: `${coin} coin` }}
        />
        <EventAttribute
          image={{ alt: "xp", url: "/assets/icons/xp.svg" }}
          label={{ color: "text-[#4F40F4]", text: exp }}
        />
      </div>
    </div>
  );
};

export const Champions = ({ champions }) => {
  return (
    <DetailContainer title={"Champion"}>
      <div className="flex items-center gap-4">
        <div className="flex-1 flex flex-col gap-2">
          {champions?.length ? (
            champions.map((champion, key) => (
              <Champion
                key={`${key}-${champion.label}`}
                label={champion.label}
                winner={{
                  name: champion.name,
                  coin: champion.coin,
                  exp: champion.exp,
                }}
              />
            ))
          ) : (
            <p className="text-xs">-</p>
          )}
        </div>
      </div>
    </DetailContainer>
  );
};
