import { useEffect, useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";

export const QuizResult = ({ result }) => {
  const params = useParams();

  const circle = useRef(null);

  const resultInPercentage = useMemo(() => {
    return (result.correctAnswers * 100) / result.numberOfQuestions;
  }, [result.correctAnswers, result.numberOfQuestions]);

  useEffect(() => {
    if (circle.current) {
      const roundRadius = circle.current
        .querySelector("circle")
        .getAttribute("r");
      const roundPercent = circle.current.dataset.percent;
      const roundCircum = 2 * roundRadius * Math.PI;
      const roundDraw = (roundPercent * roundCircum) / 100;

      circle.current.style["stroke-dasharray"] = roundDraw + " 999";
    }
  }, []);

  return (
    <div className="mt-[120px] max-w-[860px] mx-auto">
      <h1 className="text-white text-center text-[64px] font-extrabold">
        Here is your result!
      </h1>
      <div className="relative flex items-center justify-center my-4">
        <svg
          ref={circle}
          className="-rotate-90 transition-all duration-1000 ease-in-out fill-none stroke-secondary stroke-[12px]"
          style={{ strokeLinecap: "round", strokeDasharray: "0 999" }}
          viewBox="0 0 150 150"
          width="300"
          height="300"
          data-percent={resultInPercentage}
        >
          <circle cx="75" cy="75" r="60" />
        </svg>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <p className="text-white text-[80px] font-bold">
            {result.correctAnswers}/{result.numberOfQuestions}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="flex flex-col gap-1">
          <p className="text-white text-2xl font-bold">Grade Result</p>
          <div className="flex items-center justify-center text-2xl font-bold bg-white rounded-full py-1 px-8">
            <span className="text-secondary">
              {result.gradeResult.actualPercentage}/
            </span>
            <span className="text-primary">
              {result.gradeResult.totalPercentage}%
            </span>
          </div>
        </div>
        <Link to={{ pathname: `/talent/journey` }}>
          <button className="inline-flex items-center gap-4 py-2 px-12 bg-secondary rounded-[20px]">
            <span className="text-white font-bold">Go back to journey</span>
          </button>
        </Link>
      </div>
    </div>
  );
};
