import { Form, Input, Select } from "antd";
import { useState, useEffect, useRef } from "react";
import ServiceLookUpRepo from "../../../../../repositores/ServiceLookupRepo";
import MasterEventRepo from "../../../../../repositores/adminRepo/MasterEventRepo";
import BoxUpload from "../../../../../assets/icons/box-upload.png";
import { isNullOrEmpty } from "../../../../../utils/utils";

const { TextArea } = Input;
const { Option } = Select;

const FormMasterEvent = ({
  formType = "add",
  getDataMasterEvent,
  setFileUpload,
  defaultFormValue,
  getStateMount,
}) => {
  const RefInputFile = useRef(null);
  let titleModal =
    formType === "add" ? "Add Master Event" : "Edit Master Event";
  let subTitle =
    formType === "add"
      ? "Enter your data below!"
      : "Please enter your data below";
  const [formEvent] = Form.useForm();
  const [onProcess, setOnProcess] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const defaultValuePreRequisite = Array.isArray(
      defaultFormValue.preReiqusition
    )
      ? defaultFormValue.preReiqusition.map((item) => item?.name)
      : [];
    let preReiqusitionValue =
      formType === "add"
        ? defaultFormValue?.preReiqusition
        : defaultValuePreRequisite;
    const initializeForm = async () => {
      formEvent.setFieldsValue({
        name: defaultFormValue?.name,
        eventType: defaultFormValue?.eventType.id
          ? defaultFormValue?.eventType.id
          : null,
        image: defaultFormValue?.image,
        grade: defaultFormValue?.grade,
        gc: defaultFormValue?.gc,
        xp: defaultFormValue?.xp,
        journey: defaultFormValue?.journey?.id
          ? defaultFormValue?.journey.id
          : null,
        certificate: defaultFormValue?.certificate?.id
          ? defaultFormValue?.certificate.id
          : null,
        preReiqusition: preReiqusitionValue,
        description: defaultFormValue?.description,
      });

      await Promise.all([
        handleFetchMoreType(1, 20),
        handleFetchMoreJourney(1, 20),
        handleFetchMorePq(1, 20),
        handleFetchMoreCertificate(1, 20),
      ]);

      if (isMounted) {
        getStateMount(true);
        setSelectedPq(defaultValuePreRequisite);
      }
    };

    const loadData = async () => {
      await initializeForm();
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, []);

  const [pageType, setPageType] = useState(1);
  const [totalPageType, setTotalPageType] = useState(100);
  const [optionsType, setOptionsType] = useState([]);
  const [loadingMoreType, setLoadingMoreType] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [totalElementType, setTotalElementType] = useState(-1);

  const handleScrollType = (event) => {
    event.stopPropagation();

    if (!loadingMoreType) {
      handleFetchMoreType(pageType, 20);
    }
  };
  const handleFetchMoreType = async (page, limit) => {
    if (
      pageType - 1 <= totalPageType &&
      optionsType?.length !== totalElementType
    ) {
      setLoadingMoreType(true);
      try {
        const response = await ServiceLookUpRepo.getServiceLookupEventType(
          page,
          limit
        );

        let tempOptions = optionsType;
        if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
            tempOptions.push(typeEvent);
          });
          setOptionsType(tempOptions);
          setTotalPageType(response.data?.data?.totalPages);
          setTotalElementType(response.data?.data?.totalElements);
        }
        setPageType(page + 1);
        setLoadingMoreType(false);
      } catch (error) {
        setLoadingMoreType(false);
      }
    }
  };
  const handleChangeType = (value) => {
    setSelectedType(value);
    getDataMasterEvent(0, formEvent);
  };

  const [pageJourney, setPageJourney] = useState(1);
  const [totalPageJourney, setTotalPageJourney] = useState(100);
  const [optionsJourney, setOptionsJourney] = useState([]);
  const [loadingMoreJourney, setLoadingMoreJourney] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState(undefined);
  const [totalElementJourney, setTotalElementJourney] = useState(-1);

  const handleScrollJourney = (event) => {
    event.stopPropagation();

    if (!loadingMoreJourney) {
      handleFetchMoreJourney(pageJourney, 20);
    }
  };
  const handleFetchMoreJourney = async (page, limit) => {
    if (
      pageJourney - 1 <= totalPageJourney &&
      optionsJourney?.length !== totalElementJourney
    ) {
      setLoadingMoreJourney(true);
      try {
        const response = await ServiceLookUpRepo.getServiceLookupEventJourney(
          page,
          limit
        );

        let tempOptions = optionsJourney;
        if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
            tempOptions.push(typeEvent);
          });
          setOptionsJourney(tempOptions);
          setTotalPageJourney(response.data?.data?.totalPages);
          setTotalElementJourney(response.data?.data?.totalElements);
        }
        setPageJourney(page + 1);
        setLoadingMoreJourney(false);
      } catch (error) {
        setLoadingMoreJourney(false);
      }
    }
  };
  const handleChangeJourney = (value) => {
    setSelectedJourney(value);
    getDataMasterEvent(0, formEvent);
  };

  const [pagePq, setPagePq] = useState(1);
  const [totalPagePq, setTotalPagePq] = useState(100);
  const [optionsPq, setOptionsPq] = useState([]);
  const [loadingMorePq, setLoadingMorePq] = useState(false);
  const [selectedPq, setSelectedPq] = useState([]);
  const [totalElementPq, setTotalElementPq] = useState(-1);

  const handleScrollPq = (event) => {
    event.stopPropagation();

    if (!loadingMorePq) {
      handleFetchMorePq(pagePq, 20);
    }
  };
  const handleFetchMorePq = async (page, limit) => {
    if (pagePq - 1 <= totalPagePq && optionsPq?.length !== totalElementPq) {
      setLoadingMoreJourney(true);
      try {
        const response = await MasterEventRepo.getListMasterEvent(page, limit);

        let tempOptions = optionsPq;
        if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
            const indexExist = optionsPq.findIndex(
              (e) => e.id === typeEvent.id
            );
            if (indexExist < 0) {
              tempOptions.push(typeEvent);
            }
          });
          setOptionsPq(tempOptions);
          setTotalPagePq(response.data?.data?.totalPages);
          setTotalElementPq(response.data?.data?.totalElements);
        }
        setPagePq(page + 1);
        setLoadingMorePq(false);
      } catch (error) {
        setLoadingMorePq(false);
      }
    }
  };
  const handleChangePq = (value) => {
    setSelectedPq(value);
    getDataMasterEvent(0, formEvent);
  };
  const handleChangeValueForm = () => {
    getDataMasterEvent(0, formEvent);
  };

  const handleKeyDown = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  const handleChangeValueFormTextArea = (val) => {
    if (!isNullOrEmpty(val.target.value)) {
      formEvent.setFieldValue("description", val.target.value);
      getDataMasterEvent(0, formEvent);
    } else {
      formEvent.setFieldValue("description", null);
      getDataMasterEvent(0, formEvent);
    }
  };

  const [pageCertificate, setPageCertificate] = useState(1);
  const [totalPageCertificate, setTotalPageCertificate] = useState(100);
  const [optionsCertificate, setOptionsCertificate] = useState([]);
  const [loadingMoreCertificate, setLoadingMoreCertificate] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(undefined);
  const [totalElementCertificate, setTotalElementCertificate] = useState(-1);

  const handleScrollCertificate = (event) => {
    event.stopPropagation();

    if (!loadingMoreCertificate) {
      handleFetchMoreCertificate(pageCertificate, 20);
    }
  };
  const handleFetchMoreCertificate = async (page, limit) => {
    if (
      pageCertificate - 1 <= totalPageCertificate &&
      optionsCertificate.length !== totalElementCertificate
    ) {
      setLoadingMoreCertificate(true);
      try {
        const response = await MasterEventRepo.getCertificate(page, limit);

        let tempOptions = optionsCertificate;
        if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
            tempOptions.push(typeEvent);
          });
          setOptionsCertificate(tempOptions);
          setTotalPageCertificate(response.data?.data?.totalPages);
          setTotalElementCertificate(response.data?.data?.totalElements);
        }
        setPageCertificate(page + 1);
        setLoadingMoreCertificate(false);
      } catch (error) {
        setLoadingMoreCertificate(false);
      }
    }
  };
  const handleChangeCertificate = (value) => {
    setSelectedCertificate(value);
    getDataMasterEvent(0, formEvent);
  };

  const clickFileUpload = () => {
    RefInputFile.current.click();
  };

  const handleGetFileUpload = (e) => {
    if (e?.target?.files?.length) {
      formEvent.setFieldValue("image", e.target.files[0].name);
      setFileUpload(e.target.files[0]);
    } else {
      formEvent.setFieldValue("image", null);
      setFileUpload(undefined);
    }
  };

  const validatePastedNumber = (e, fieldName) => {
    const pastedValue = e.clipboardData.getData("text");
    if (parseFloat(pastedValue) < 0) {
      e.preventDefault();
      const positiveVal = Math.abs(parseFloat(pastedValue));

      e.target.value = positiveVal;
      formEvent.setFieldValue(fieldName, positiveVal);
    }
  };

  return (
    <>
      <div className="flex flex-col p-6">
        <h1 className="text-[22px] font-bold text-neutral-700">{titleModal}</h1>
        <p className="text-xs text-neutral-500">{subTitle}</p>
        <div className="w-full mt-[20px]">
          <Form
            layout="horizontal"
            labelCol={{ xs: { span: 24 }, sm: { flex: "140px" } }}
            labelAlign="left"
            form={formEvent}
            colon={false}
            wrapperCol={{ flex: 1 }}
          >
            <Form.Item
              label="Master Event Name"
              name="name"
              initialValue={defaultFormValue.name}
              rules={[
                {
                  required: true,
                  message: "Please input Master Event name",
                },
              ]}
            >
              <Input
                onChange={handleChangeValueForm}
                placeholder="Enter event name"
                disabled={onProcess}
              />
            </Form.Item>
            <Form.Item
              label="Event Type"
              name="eventType"
              rules={[
                {
                  required: true,
                  message: "Please select Event Type",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleChangeType}
                placeholder="Select event type"
                disabled={onProcess}
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollType}>{menu}</div>
                )}
                loading={loadingMoreType}
                filterOption={false}
                defaultActiveFirstOption={false}
                className={selectedJourney === null ? "placeholder-select" : ""}
              >
                {optionsType?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Image" name="image">
              <Input
                disabled={onProcess}
                onClick={clickFileUpload}
                readOnly
                placeholder="Upload event image"
                suffix={<img src={BoxUpload} alt="icon-upload" />}
              />
            </Form.Item>
            <input
              disabled={onProcess}
              onChange={handleGetFileUpload}
              type="file"
              ref={RefInputFile}
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
            />
            <div className="flex">
              <div className="w-[80%]">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (value > 100) {
                          return Promise.reject(
                            "Number can't be more than 100"
                          );
                        } else if (value === "") {
                          return Promise.reject("Please input Passing Grade");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label="Passing Grade"
                  name="grade"
                >
                  <Input
                    onPaste={(e) => {
                      validatePastedNumber(e, "grade");
                    }}
                    disabled={onProcess}
                    onChange={handleChangeValueForm}
                    placeholder="input passing grade"
                    type="number"
                    max={100}
                    onKeyDown={handleKeyDown}
                    onKeyUp={(e) => {
                      if (parseInt(e.target.value) > 100) {
                        formEvent.setFieldValue("grade", 0);
                      }
                    }}
                    min={0}
                    maxLength={3}
                    /* onBlur={(e) => {
                      if (parseInt(e.target.value) > 100) {
                        formEvent.setFieldValue("grade",0);
                      }
                    }} */
                  />
                </Form.Item>
              </div>
              <div className="ml-[20px] pt-11 lg:pt-[5px]">%</div>
            </div>
            <div className="flex">
              <div className="w-[80%]">
                <Form.Item
                  label={<label>Coin</label>}
                  name="gc"
                  rules={[
                    {
                      required: true,
                      message: "Please input Coin",
                    },
                  ]}
                >
                  <Input
                    onPaste={(e) => {
                      validatePastedNumber(e, "gc");
                    }}
                    disabled={onProcess}
                    onChange={handleChangeValueForm}
                    placeholder="Input coins"
                    type="number"
                    onKeyDown={handleKeyDown}
                    min={0}
                  />
                </Form.Item>
              </div>
              <div className="ml-[20px] pt-11 lg:pt-[5px]">Coins</div>
            </div>
            <div className="flex">
              <div className="w-[80%]">
                <Form.Item
                  label={<label>XP</label>}
                  name="xp"
                  rules={[
                    {
                      required: true,
                      message: "Please input XP",
                    },
                  ]}
                >
                  <Input
                    onPaste={(e) => {
                      validatePastedNumber(e, "xp");
                    }}
                    disabled={onProcess}
                    onChange={handleChangeValueForm}
                    placeholder="Input xp points"
                    type="number"
                    onKeyDown={handleKeyDown}
                    min={0}
                  />
                </Form.Item>
              </div>
              <div className="ml-[20px] pt-11 lg:pt-[5px]">Points</div>
            </div>
            <Form.Item
              label="journey"
              name="journey"
              rules={[
                {
                  required: true,
                  message: "Please select Journey",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleChangeJourney}
                placeholder="Select event journey"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollJourney}>{menu}</div>
                )}
                loading={loadingMoreJourney}
                disabled={onProcess}
                filterOption={false}
                defaultActiveFirstOption={false}
                className={!selectedJourney ? "placeholder-select" : ""}
              >
                {optionsJourney.map((i) => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Pre-requisition"
              name="preReiqusition"
              initialValue={selectedPq}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                disabled={onProcess}
                onChange={handleChangePq}
                placeholder="Select Pre-requisition"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollPq}>{menu}</div>
                )}
                loading={loadingMorePq}
                filterOption={false}
                defaultActiveFirstOption={false}
                className={selectedPq === null ? "placeholder-select" : ""}
              >
                {optionsPq.map((i) => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Certificate" name="certificate">
              <Select
                style={{ width: "100%" }}
                onChange={handleChangeCertificate}
                disabled={onProcess}
                placeholder="Select Certificate"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollCertificate}>{menu}</div>
                )}
                loading={loadingMoreCertificate}
                filterOption={false}
                defaultActiveFirstOption={false}
                className={
                  selectedCertificate === null ? "placeholder-select" : ""
                }
              >
                {optionsCertificate.map((i) => (
                  <Option key={i.id} value={i.id}>
                    {i.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "100 characters minimum and maximum 500 characters",
                },
              ]}
            >
              <TextArea
                disabled={onProcess}
                value={formEvent.getFieldValue("description")}
                onChange={handleChangeValueFormTextArea}
                placeholder="Input event description"
                maxLength={500}
                minLength={100}
              />

              {formEvent.getFieldError("description")}
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default FormMasterEvent;
