import React, { useEffect, useState, useRef } from "react";
import { Tabs, ConfigProvider, Form, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TitleMaster from "../../componets_global/TitleMaster";
import CertificateIssuedTable from "./components/Table";
import ModalCertificateIssued from "./components/Modal";
import {
  resetStateForm,
  resetStatusFeedback,
  getAllCertificateIssued,
  getOneCertificateIssued,
  revokeCertififcate,
  previewCertificate,
  onChangeState,
} from "../../../../features/certificateIssuedSlice";
import ActionBarMaster from "../../componets_global/ActionBarMaster";
import AlertMiniPopUp from "../../componets_global/AlertMiniPopUp";

const CertificateIssued = () => {
  const [formAnt] = Form.useForm();
  const dispatch = useDispatch();

  let typeAlert = useRef();
  const [openModal, setModal] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const {
    isLoading,
    isAlert,
    isSuccess,
    isError,
    message,
    issuedData,
    revokedData,
    formDetails,
    keywordSearch,
    pagination,
    activeTab,
    sortParams,
  } = useSelector((state) => state.certificateIssued);

  const handleCancelModal = () => {
    setModal(false);
    setViewDetail(false);
  };

  const changeActiveTab = (value) => {
    dispatch(resetStatusFeedback);
    dispatch(onChangeState({ stateProp: "activeTab", valueProp: value }));
    dispatch(onChangeState({ stateProp: "keywordSearch", valueProp: "" }));
  };

  const openRevoke = async (id) => {
    dispatch(getOneCertificateIssued(id));
    setModal(true);
    if (activeTab === "issued") setViewDetail(false);
    if (activeTab === "revoke") setViewDetail(true);
  };

  const submitRevoke = () => {
    dispatch(
      revokeCertififcate({
        id: formDetails.key,
        revokeReason: formDetails.reason,
      })
    );
    formAnt.resetFields();
    dispatch(resetStateForm);
    handleCancelModal();
    dispatch(getAllCertificateIssued(activeTab.toUpperCase()));
  };

  const handlePreview = (id) => {
    dispatch(previewCertificate(id));
  };

  useEffect(() => {
    getAllCertificate();
  }, [activeTab, keywordSearch, pagination.currentPage, sortParams]);

  useEffect(() => {
    if (isAlert) {
      if (isError) typeAlert.current = "error";
      else if (isSuccess) typeAlert.current = "success";
    }
  }, [isAlert]);

  const onChangeKeyword = (event) => {
    dispatch(
      onChangeState({
        stateProp: "keywordSearch",
        valueProp: event.target.value,
      })
    );
    if (keywordSearch) {
      let page = pagination.currentPage >= 0;
      const updatedPagination = { ...pagination, currentPage: page };
      dispatch(
        onChangeState({ stateProp: "pagination", valueProp: updatedPagination })
      );
    }
  };

  const getAllCertificate = () => {
    let params = {
      currentPage: pagination?.currentPage,
      limit: pagination?.limit,
      tabs: activeTab,
    };
    if (sortParams.orderBy && sortParams.orderType) {
      params.sort = {
        orderBy: sortParams.orderBy,
        orderType: sortParams.orderType,
      };
    }
    if (keywordSearch.length > 0) params.keyword = keywordSearch;
    dispatch(getAllCertificateIssued(params));
  };

  const items = [
    {
      key: "issued",
      label: <h1 className="font-semibold">Issued</h1>,
      children: (
        <CertificateIssuedTable
          tabs="issued"
          data={issuedData}
          openRevoke={openRevoke}
          actionPreview={handlePreview}
        />
      ),
    },
    {
      key: "revoke",
      label: <h1 className="font-semibold">Revoked</h1>,
      children: (
        <CertificateIssuedTable
          tabs="revoke"
          data={revokedData}
          actionDetails={openRevoke}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-screen px-4 py-5">
      {isAlert && (
        <AlertMiniPopUp
          isAlert={isAlert}
          messageAlert={message}
          isSuccess={isSuccess}
        />
      )}
      <TitleMaster>Certificates</TitleMaster>
      <ActionBarMaster
        buttonTitle="Search"
        placeholder="Search by Issued to"
        onSearch={onChangeKeyword}
      />
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#DC2626",
              inkBarColor: "#DC2626",
            },
          },
        }}
      >
        <Spin spinning={isLoading} size="large" tip="Loading">
          <Tabs
            defaultActiveKey={activeTab}
            items={items}
            onChange={changeActiveTab}
          />
        </Spin>
      </ConfigProvider>
      {!isLoading && isSuccess && !isError && (
        <ModalCertificateIssued
          open={openModal}
          handleCancel={handleCancelModal}
          modalTitle="Revoke Certificate"
          instruction="Enter your data below"
          nameForm="revokeForm"
          instanceForm={formAnt}
          isDetails={viewDetail}
          dataForm={formDetails}
          buttonAction={submitRevoke}
        />
      )}
    </div>
  );
};

export default CertificateIssued;
