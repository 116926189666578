import { Collapse, Spin } from "antd";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useVerificationUploader } from "../hooks/useVerificationUploader";
import { EventDetails } from "./EventDetails";
import { EventSummary } from "./EventSummary";
import { EventThumbnail } from "./EventThumbnail";
import { EventVerificationUploader } from "./EventVerificationUploader";

export const EventCard = ({ data, isLoading, closeModal, refetchData }) => {
  const location = useLocation();

  const navigate = useNavigate();

  const {
    shouldRenderUploader,
    verificationUploaderType,
    hideVerificationUploader,
    showVerificationUploader,
  } = useVerificationUploader();

  const [isToggled, setIsToggled] = useState(
    new URLSearchParams(location.search).get("expanded") === "true"
  );

  function onChangeHandler(key) {
    setIsToggled(!!key.length);
    navigate({
      pathname: location.pathname,
      ...(key.length && {
        search: new URLSearchParams({ expanded: true }).toString(),
      }),
    });
  }

  function onHideVerificationUploader() {
    setIsToggled(true);
    hideVerificationUploader();
  }

  // Prepare the items for the Collapse component
  const collapseItems = [
    {
      key: "panel",
      label: (
        <EventSummary
          eventName={data?.data?.name}
          eventTag={data?.data?.eventType || "Tipe event"}
          eventStatus={data?.data?.status}
          eventSummary={data?.data?.summary}
          isToggled={isToggled}
        />
      ),
      children: (
        <EventDetails
          description={data?.data?.description}
          eventId={data?.data?.id}
          details={data?.data?.details}
          status={data?.data?.status}
          closeModal={closeModal}
          refetch={refetchData}
          uploadAttendance={(taskId) =>
            showVerificationUploader.forAttendance(taskId)
          }
          uploadCertificate={(taskId) =>
            showVerificationUploader.forCertificate(taskId)
          }
        />
      ),
      showArrow: false,
    },
  ];

  return (
    <div className="relative w-full flex flex-col bg-white rounded-3xl overflow-hidden pointer-events-auto">
      {isLoading ? (
        <div className="flex items-center justify-center py-12">
          <Spin />
        </div>
      ) : (
        <Fragment>
          <EventThumbnail thumbnail={data?.data?.summary?.urlImage}>
            <EventThumbnail.CTAButton
              shouldRenderUploader={shouldRenderUploader}
              closeModal={closeModal}
              hideVerificationUploader={onHideVerificationUploader}
            />
          </EventThumbnail>
          {shouldRenderUploader ? (
            <EventVerificationUploader
              onVerificationUploaded={() => {
                onHideVerificationUploader();
                refetchData();
              }}
              type={verificationUploaderType}
            />
          ) : (
            <Collapse
              accordion
              bordered={false}
              activeKey={isToggled ? "panel" : null}
              ghost
              onChange={onChangeHandler}
              items={collapseItems} // Use the items prop instead of children
            />
          )}
        </Fragment>
      )}
    </div>
  );
};
