import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListInterviewCandidate = async (
  page = 0,
  size = 10,
  interviewerName = undefined,
  candidateName = undefined,
  position = undefined,
  type = undefined
) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof interviewerName !== "undefined" && interviewerName.length) {
    params["interviewerName"] = interviewerName;
  }
  if (typeof candidateName !== "undefined" && candidateName.length) {
    params["candidateName"] = candidateName;
  }
  if (typeof position !== "undefined" && position.length) {
    params["position"] = position;
  }
  if (typeof type !== "undefined" && type.length) {
    params["type"] = type;
  }
  const url = `${API_BASE}/retro-interview-candidate/list-admin?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addInterviewCandidate = async (body) => {
  const url = `${API_BASE}/retro-interview-candidate/admin-create`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const editInterviewCandidate = async (body) => {
  const url = `${API_BASE}/retro-interview-candidate/admin-update`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const deleteInterviewCandidate = async (id) => {
  const url = `${API_BASE}/retro-interview-candidate/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
};

const getRetroById = async (id) => {
  const url = `${API_BASE}/retro-interview-candidate/get-id/${id}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
}

const exportData = async (startDate, endDate) => {
  const url = `${API_BASE}/report/course/list-admin?page=0&size=1000&startDate=${startDate}&endDate=${endDate}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
}

const InterviewCandidateRepo = {
  addInterviewCandidate,
  deleteInterviewCandidate,
  editInterviewCandidate,
  getListInterviewCandidate,
  getRetroById,
  exportData
};

export default InterviewCandidateRepo;
