import React from "react";
import { Table } from "antd";
import SkeletonTable from "../master/Events/components/SkeletonTable";

const TableContent = ({ dataSource, columns, isLoading }) => {
  if (isLoading) {
    return <SkeletonTable />;
  }
  return (
    <div className="mt-[10px] w-full">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        sticky={true}
        rowKey="id"
        scroll={{
          x: 1300,
          y: 520,
        }}
      />
    </div>
  );
};

export default TableContent;
