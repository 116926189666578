import { useContext } from "react";
import "./header.css";
import { TalentHelperContext } from "../talentPage/TalentContext";

const TalentHeader = ({
  title,
  subDescription,
  imageSrc,
  className = "",
  ...props
}) => {
  const { user } = useContext(TalentHelperContext);

  return (
    <>
      <div
        className={`bg-transparent w-full md:h-[110px] flex flex-col md:flex-row justify-between items-center px-4 ${className}`}
        {...props}
      >
        <div className="flex justify-start items-center gap-2 mb-4 md:mb-0">
          {imageSrc && (
            <div className="hidden md:block">
              <img
                src={`${imageSrc}`}
                width={100}
                height={100}
                alt="header-certificates"
                className="object-contain"
              />
            </div>
          )}
          <div className="text-dark-grey flex flex-col justify-center text-center md:text-left">
            <p className="text-header-1 text-xl md:text-2xl">{title}</p>
            <p className="hidden md:block font-20-regular">{subDescription}</p>
          </div>
        </div>

        <div className="flex items-center gap-x-2">
          <div className="text-center md:text-left">
            <div className="font-24-bold-600 text-lg md:text-xl">
              {user?.fullname}
            </div>
            <div className="font-20-regular text-sm md:text-base">
              {user?.username}
            </div>
          </div>
          <div className="border border-dark-grey rounded-full flex items-center justify-center">
            <img
              src={`/assets/icons/picture.png`}
              alt={`xp`}
              className="w-[50px] h-[50px] md:w-[75px] md:h-[75px] object-cover"
            />
          </div>
        </div>
      </div>
      <hr className="style-two" />
    </>
  );
};

export default TalentHeader;
