import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { message, Pagination, Spin  } from "antd";
import IconArrowLeft from "../../../assets/icons/IconArrowLeft.png"

import AdminLmsRepo from "../../../repositores/adminRepo/LmsRepo";
import TableListDetailHistory from "./components/tableListDetailHistory";

const DetailHistoryIdp = () => {

    const navigate = useNavigate();
    let { courseId, userId } = useParams();
    
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(true);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);

    const [bounchPage, setBounchPage] = useState(undefined);


    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page])

    async function fetchData() {
        setLimit(10);
        try {
            let tempData = [];
            let response = await AdminLmsRepo.getDetailHistory(courseId, userId);
            if (response?.data?.data?.content) { 
                await response?.data?.data?.content?.forEach((content, index) => {
                    content.number = (limit*(page-1)) + (index+1)
                    tempData.push(content)
                })
                setDataTable(tempData);
                setTotalItem(response?.data?.data?.totalElements);
                
            } else {
                setDataTable([]);
                setTotalItem(0);
            }
            setLoading(false);
            setLoadingData(false);
        } catch (error) {
            setLoading(false);
            setLoadingData(false);
            messageApi.open({
                type: 'error',
                content: "Failed to get data mata kuliah" ,
            });
        }
    }

    const goBack = () => {
        navigate('/admin/history-course')
    }
    const onChangePage = (page) => {
        setLoadingData(true);
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setPage(page)
        }, 1000))
    }

    if(loading) {
        return (
            <>
                <div className="container w-full" id="courseDetail">
                    <div className="w-[100%] text-center">
                        <Spin size="large" fullscreen />
                    </div>
                </div>
            </>
        )
    }

    const talentName = () => {
        if(dataTable && dataTable.length) {
            return dataTable?.[0]?.fullName
        }
        return ""
    }

    const courseName = () => {
        if(dataTable && dataTable.length) {
            return dataTable?.[0]?.courseName
        }
        return ""
    }

    return  (
        <>
            {contextHolder}
            <div className="w-full" id="courseDetail">
                <div className="w-[100%] px-[20px] py-[20px] flex">
                    <div className="mt-[6px] mr-[20px]">
                        <button onClick={goBack} >
                            <img width={48} src={IconArrowLeft} alt="icon" />
                        </button>
                    </div>
                    <div>
                        <h4 className="text-[32px] font-bold">Detail History</h4>
                        <p>{talentName()} / {courseName()}</p>
                    </div>
                </div>

                {
                    loadingData ? <>
                        <div className="w-full text-center mt-[20px]">
                            <p>Please wait...</p>
                        </div>
                    </> : 
                    <>
                        <div className="mt-[20px] ml-[10px] w-full px-[20px]">
                            <TableListDetailHistory
                                dataTable={dataTable}
                            />
                            <div className="w-full flex justify-center my-2 pt-2 pb-10">
                                <Pagination
                                    current={page}
                                    onChange={onChangePage}
                                    pageSize={limit}
                                    total={totalItem}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default DetailHistoryIdp