import CallApi from "../../services/Request-Helper";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getLogoGlobal = async () => {
  const url = `${API_BASE}/lookup/list/?page=0&size=10&type=LOGO_GLOBAL_FE`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getLogoNotToken = async () => {
  const url = `${API_BASE}/lookup/list-not-token/?page=0&size=10&type=LOGO_GLOBAL_FE`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const titleNotToken = async () => {
  const url = `${API_BASE}/lookup/list-not-token?page=0&size=10&type=APPLICATION_NAME`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getLogoFavicon = async () => {
  const url = `${API_BASE}/lookup/list-not-token/?page=0&size=10&type=LOGO_GLOBAL_FE_ICON_WEB`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const LogoRepo = {
  getLogoGlobal,
  getLogoNotToken,
  getLogoFavicon,
  titleNotToken,
};

export default LogoRepo;
