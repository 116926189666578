import React, { useEffect } from "react";
import { Table } from "antd";
import SkeletonTable from "../../master/Events/components/SkeletonTable";

const TableRedeemHistory = ({ dataSource, isLoading }) => {
  useEffect(() => {}, [dataSource]);

  if (isLoading) {
    return <SkeletonTable />;
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Rewards",
      dataIndex: "rewards",
      key: "rewards",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <div className="mt-[10px] w-full">
      <Table
        columns={columns}
        pagination={false}
        dataSource={dataSource}
        sticky={true}
        scroll={{
          x: 900,
          y: 520,
        }}
      />
    </div>
  );
};

export default TableRedeemHistory;
