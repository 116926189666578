import React from "react";
import { Skeleton, Space } from "antd";

const SkeletonTable = () => {
  const renderList = () => {
    const listItems = [];
    for (let i = 0; i < 3; i++) {
      listItems.push(
        <Space className="m-3" key={i}>
          <Skeleton.Avatar className="mr-2" />
          <Skeleton.Input />
          <Skeleton.Input />
          <Skeleton.Input />
          <Skeleton.Input />
          <Skeleton.Input />
        </Space>
      );
    }

    return listItems;
  };

  return <div className="my-3">{renderList()}</div>;
};

export default SkeletonTable;
