import axios from "axios";

const normalizeBaseUrl = (baseUrl) => {
  if (baseUrl.endsWith("/")) return baseUrl.slice(0, -1);
  return baseUrl;
};

class ApiClient {
  /**
   * @type {import("axios").AxiosInstance}
   */
  instance;

  constructor() {
    this.instance = axios.create({
      baseURL: `${normalizeBaseUrl(process.env.REACT_APP_BASE)}/api/v1`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    this.addRequestInterceptor();
    this.addResponseInterceptor();
  }

  addRequestInterceptor() {
    if (!this.instance) return;
    this.instance.interceptors.request.use(
      (request) => {
        const token = localStorage.getItem("token") || null;
        request.headers.Authorization = token ? `Bearer ${token}` : '';
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  addResponseInterceptor() {
    if (!this.instance) return;
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if(error.response) {
          if(error.response.status === 401) {
            //console.error('Unauthorized Error:', error.response.error, error.response.error_description);
            localStorage.clear();
            window.location.replace('/');
          }
        }
        
        return Promise.reject(error);
      }
    );
  }
}

/**
 * Instantiating this class will create
 * a singleton instance that will be
 * reused exactly the way it was instantiated.
 */
const apiClient = new ApiClient();

export default apiClient;
