import React from "react";

const ButtonAddJourney = ({ onClick }) => {
  return (
    <div
      className="bg-red-600 w-full ml-auto text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[30px] font-bold"
      onClick={onClick}
    >
      Create Journey
    </div>
  );
};

export default ButtonAddJourney;
