import React from "react";
import { Table } from "antd";

const CertificateTable = ({
  data,
  actionEdit,
  actionDelete,
  actionPreview,
}) => {
  const getIdCertificate = (actionTask, selectedId) => {
    if (actionTask === "delete") actionDelete(selectedId);
    else if (actionTask === "edit") actionEdit(selectedId);
    else if (actionTask === "preview") actionPreview(selectedId);
  };

  const columns = [
    {
      title: "Certificate",
      dataIndex: "certificate",
      key: "certificate",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
    },
    {
      title: "Signer",
      dataIndex: "signer",
      key: "signer",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div className="flex flex-row">
          <button
            className="px-3 text-blue-900 pointer-cursor font-medium"
            onClick={(event) =>
              event.preventDefault(getIdCertificate("preview", data.image))
            }
          >
            Preview
          </button>
          {/* <div 
                    className='bg-white flex text-blue-600 w-[60px] justify-center cursor-ponter'
                    >
                        <Link to='#' >Preview</Link>
                    </div> */}
          <button
            className="bg-white flex border-2 border-red-600 text-red-600 w-[60px] justify-center rounded-[7px] cursor-ponter"
            onClick={(event) =>
              event.preventDefault(getIdCertificate("edit", data.key))
            }
          >
            Edit
          </button>
          <img
            src="/assets/icons/delete.svg"
            alt=""
            className="ml-4 cursor-pointer"
            onClick={() => getIdCertificate("delete", data.key)}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="mt-[10px] w-full">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        sticky={true}
        scroll={{
          x: true,
          y: 520,
        }}
      />
    </div>
  );
};

export default CertificateTable;
