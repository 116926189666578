import React, { createContext, useEffect, useState } from "react";
import MasterUserManagementRepo from "../../../../repositores/adminRepo/UserManagement";

const JourneyContext = createContext();

const JourneyContextProvider = ({ children }) => {
  const [participantsOption, setParticipantsOption] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [participantsExist, setParticipantsExist] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState([{
    label: '',
    value: ''
  }]);

  const [currentUsersList, setCurrentUsersList] = useState([]);

  useEffect(() => {
    handleOptions();
  }, []);
  
  useEffect(() => {
    /* if(participants.length >= 0) {
      console.log('swa', participants);
      setParticipantsExist((prev) => [...prev, ...participants]);
    } */
  }, [participants]);

  useEffect(() => {
  }, [participantsExist]);
  
  useEffect(() => {
    let existingParticipants = currentUsersList.map((item, index) => {
      return {
        "label": item?.user?.fullname,
        "value": item.user.id,
        "key": item?.id
      }
    });

    setParticipantsExist((prev) => [...prev, ...existingParticipants]);
  }, [currentUsersList]);

  const handleOptions = async (page = 1, limit = 20, searchKeyword = "") => {
    await MasterUserManagementRepo.getUserManagement(page - 1, limit, searchKeyword)
      .then((response) => {
        setCurrentPage(page);
        let tempOpt = response.data.data.content?.map((participant) => {
          return {
            label: participant.fullname,
            value: participant.id
          }
        });
        setOptions(tempOpt);
        setParticipantsOption(response.data.data.content);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSearch = (keyword) => {
    if(keyword  === "") {
      handleOptions();
    }else {
      handleOptions(1, 20, keyword);
    }
  };

  function generateUniqueID() {
    const timestamp = new Date().getTime(); // Get the current timestamp
    const randomPart = Math.floor(Math.random() * 1000000); // Generate a random number
  
    // Combine the timestamp and random number to create a unique ID
    const uniqueID = `${timestamp}-${randomPart}`;
  
    return uniqueID;
  }
  
  const handleChangeUser = (value) => {
    /* value = participants id */
    //setParticipants(value);
    let tempOptions = []; // selected participant id
    value?.map((newItem) => {
      return options.find((item) => item.value === newItem);
    })?.filter((item) => {
      return item !== undefined;
    }).forEach((item) => {
      if(!tempOptions.find((opt) => opt.id === item.value)) {
        tempOptions.push({...item, key: generateUniqueID()});
      }else {
      }
    });
    setParticipants(tempOptions);
  };
  
  const handleAddParticipant = async () => {}
  
  const handleRemoveParticipant = async (key) => {
    let value = participantsExist.filter(item => item.key !== key);
    setParticipantsExist(() => value);
  }
  

  return (
    <JourneyContext.Provider
      value={{ participantsExist, setParticipantsExist, handleOptions, handleSearch, setParticipants, handleRemoveParticipant, handleChangeUser, currentUsersList, setCurrentUsersList, options, participants, participantsOption, setSearchKeyword, searchKeyword }}
    >
      {children}
    </JourneyContext.Provider>
  );
};

export { JourneyContext, JourneyContextProvider };
