import React, { useEffect, useState, useRef } from "react";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import {
  Button,
  Form,
  Modal,
  Pagination,
  message,
  DatePicker,
  Select,
  Space,
} from "antd";
import InterviewCandidateRepo from "../../../repositores/adminRepo/InterviewCandidateRepo";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";
import SearchBar from "../componets_global/SearchBar";
import TableContent from "../componets_global/TableContent";
import ModalInterviewCandidate from "./ModalInterviewCandidate";
import { useNavigate } from "react-router-dom";
import { FilterFilled } from "@ant-design/icons";
import { isNullOrEmpty } from "../../../utils/utils";
const { RangePicker } = DatePicker;
const { Option } = Select;

const InterviewCandidate = () => {
  const selectInput = useRef(null);
  const searchInput = useRef(null);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [listData, setListData] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const [id, setId] = useState();
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isCopied, setIsCopied] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [modalExport, setModalExport] = useState(false);
  const [rangeDate, setRangeDate] = useState(undefined);
  const [refreshCounterDaterange, setRefreshCounterDaterange] = useState(0);
  const [filterType, setFilterType] = useState("");

  const [searchValue, setSearchValue] = useState({
    interviewerName: "",
    candidateName: "",
    position: "",
  });

  const getColumnSelectProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Select
          ref={selectInput}
          placeholder="Select Type"
          value={selectedKeys[0]}
          defaultValue={isNullOrEmpty(filterType) ? undefined : filterType}
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        >
          <Option value="CANDIDATE">Candidate</Option>
          <Option value="TALENT">Talent</Option>
        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => handleFilterType(selectedKeys)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Filter
          </Button>
          <Button
            onClick={(e) => {
              handleFilterType([]);
              setFilterType("");
              clearFilters(e);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => {
      return (
        <FilterFilled
          style={{
            color: !isNullOrEmpty(filterType) ? "#1677ff" : undefined,
          }}
        />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 300);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const handleFilterType = async (value) => {
    const valueSearch = value && value[0] && value[0].length ? value[0] : "";
    setFilterType(valueSearch);
    try {
      setPage(1);
      setLoading(true);
      setListData(null);
      const response = await InterviewCandidateRepo.getListInterviewCandidate(
        0,
        10,
        searchValue.interviewerName,
        searchValue.candidateName,
        searchValue.position,
        valueSearch
      );
      setListData(response.data.data.content);
      setTotalItem(response?.data?.data?.totalElements);
      setLoading(false);
    } catch (error) {
      console.error("Error searching data: ", error);
    }
  };

  const onChangeSearch = async (el, field) => {
    switch (field) {
      case "interviewerName":
        setSearchValue((prevVal) => {
          return {
            ...prevVal,
            ...{ interviewerName: el.target.value },
          };
        });
        break;
      case "candidateName":
        setSearchValue((prevVal) => {
          return {
            ...prevVal,
            ...{ candidateName: el.target.value },
          };
        });
        break;
      case "position":
        setSearchValue((prevVal) => {
          return {
            ...prevVal,
            ...{ position: el.target.value },
          };
        });
        break;

      default:
        break;
    }
    try {
      setPage(1);
      setLoading(true);
      setListData(null);
      const response = await InterviewCandidateRepo.getListInterviewCandidate(
        0,
        10,
        field === "interviewerName" ? el.target.value : "",
        field === "candidateName" ? el.target.value : "",
        field === "position" ? el.target.value : "",
        filterType
      );
      setListData(response.data.data.content);
      setTotalItem(response?.data?.data?.totalElements);
      setLoading(false);
    } catch (error) {
      console.error("Error searching data: ", error);
    }
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const handleClickDeleteModal = async () => {
    try {
      const response = await InterviewCandidateRepo.deleteInterviewCandidate(
        id
      );
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
      fetchData();
      setOpenModalDelete(false);
    } catch (error) {
      console.error("Error deleting rewards:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLimit(10);
      setLoading(true);
      const response = await InterviewCandidateRepo.getListInterviewCandidate(
        page - 1,
        limit
      );

      if (response.status === 200) {
        setTotalItem(response?.data?.data?.totalElements);
        setListData(response?.data.data.content);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching interview candidate data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const columns = [
    {
      title: "User / Interviewer",
      dataIndex: "interviewerName",
      key: "interviewerName",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "candidateName",
      key: "candidateName",
      align: "center",
      render: (_, row) => {
        return <p>{row?.candidateName || row?.talent?.fullname || "-"}</p>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
      ...getColumnSelectProps("type"),
    },
    {
      title: "Account",
      dataIndex: ["retroInterviewClient", "account", "name"],
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Position",
      dataIndex: ["retroInterviewClient", "role", "name"],
      key: "position",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      align: "center",
      render: (v) => {
        let textColorClass = "";
        let bgColorClass = "";

        if (v === "PASSED") {
          textColorClass = "text-[#43ae3b]";
          bgColorClass = "bg-[#c4fccb]";
        } else if (v === "FAILED") {
          textColorClass = "text-[#ae3b3b]";
          bgColorClass = "bg-[#fcc4c4]";
        }

        const statusText = v !== null ? v.toUpperCase() : "-";

        if (v === "-") {
          return (
            <div className="flex justify-center w-full">
              <div
                className={`w-[100px] px-1 justify-center text-center rounded`}
              >
                {statusText}
              </div>
            </div>
          );
        }

        return (
          <div className="flex justify-center w-full">
            <div
              className={`w-[100px] px-1 justify-center text-center rounded ${textColorClass} ${bgColorClass}`}
            >
              {statusText}
            </div>
          </div>
        );
      },
    },
    {
      title: "URL",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id, row) => {
        return (
          <Button
            type="primary"
            //target="_blank"
            // href={`/form-interview-talent/${row.retroInterviewClient.id}`}
            className="text-xs px-2"
            // ref={qrUrlCopyRef}
            onClick={(e) =>
              handleUrlRedirect(row.id, row.retroInterviewCandidateDetails)
            }
          >
            Copy URL
          </Button>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (id) => {
        return (
          <div className="flex gap-3">
            <Button
              danger
              size="small"
              onClick={() => {
                handleClickEditModal(id);
                setId(id);
              }}
              className="w-full"
            >
              <span className="text-red-600">Edit</span>
            </Button>
            <Button
              className="w-full"
              type="primary"
              danger
              size="small"
              onClick={() => {
                setId(id);
                setOpenModalDelete(true);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const handleClickEditModal = async (id) => {
    setOpenModalEdit(true);
    const filterListByID = listData.filter((data) => data.id === id);
    setDataEdit(filterListByID[0]);
  };

  const handleUrlRedirect = async (
    retroClientId,
    listRetroInterviewCandidateDetails
  ) => {
    try {
      if (retroClientId) {
        const url = `${window.location.origin}/form-interview-talent/${retroClientId}`; // local
        //const url = `${process.env.REACT_APP_BASE_FE}form-interview-talent/${retroClientId}`;
        await navigator.clipboard.writeText(url).then(() => {
          messageApi.open({
            type: "success",
            content: "Url Copied",
          });
        });
        //setIsCopied(true);
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  const handleGetDataModalAdd = async (
    status,
    data,
    selectedAccount,
    selectedRole
  ) => {
    const reqBody = {
      retroInterviewClient: {
        id: selectedAccount,
      },
      interviewerName: data?.interviewerName,
      status: data?.status,
      candidateName: data?.candidateName,
      talent: {
        id: selectedRole,
      },
      type: data?.type,
    };

    if (status === "success") {
      const response = await InterviewCandidateRepo.addInterviewCandidate(
        reqBody
      );
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
        fetchData();
        setOpenModalAdd(false);
        form.resetFields();
      } else {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
      }
    }
  };

  const handleGetDataModalEdit = async (
    status,
    data,
    selectedAccount,
    selectedRole
  ) => {
    const reqBody = {
      id: id || data.id,
      retroInterviewClient: {
        id: selectedAccount,
      },
      talent: {
        id: selectedRole,
      },
      interviewerName: data?.interviewerName,
      status: data?.status,
      candidateName: data?.candidateName,
      type: data?.type,
    };

    if (status === "success") {
      const response = await InterviewCandidateRepo.editInterviewCandidate(
        reqBody
      );
      if (response.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        fetchData();
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
          setOpenModalEdit(false);
          setDataEdit(null);
          setId(null);
        }, 2000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
    }
  };

  const handleExportData = async () => {
    setLoading(true);
    setModalExport(false);

    try {
      setLoading(true);

      if (!rangeDate) {
        throw new Error("Please select date range");
      }

      const { data } = await InterviewCandidateRepo.exportData(
        rangeDate?.[0],
        rangeDate?.[1]
      );
      const blob = new Blob([data]);
      let url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.setAttribute("download", "template.csv");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.message,
      });
    } finally {
      setRefreshCounterDaterange((prevValue) => prevValue + 1);
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Interview Candidate</TitleMasterAdmin>
        <div className="flex gap-x-2 justify-end">
          <div className="flex flex-col gap-y-2 gap-x-2 md:flex-row">
            <Button
              className="bg-red-600 w-auto h-auto text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer font-bold"
              onClick={() => setModalExport(true)}
              disabled={loading}
            >
              Export Data
            </Button>
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "candidateName")}
              placeholder={"Search Candidate"}
            />
          </div>
          <div className="flex flex-col gap-y-2 gap-x-2 md:flex-row">
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "interviewerName")}
              placeholder={"Search Interviewer"}
            />
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "position")}
              placeholder={"Search Position"}
            />
          </div>
          <div className="flex flex-row gap-y-2 gap-x-2 md:flex-row md:order-2">
            <button
              className="bg-red-600 w-auto px-2 text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer font-bold"
              onClick={() => setOpenModalAdd(true)}
            >
              Add Data
            </button>
          </div>
        </div>
        <TableContent
          dataSource={listData}
          columns={columns}
          isLoading={loading}
        />
        <div className="w-full flex justify-center mt-5">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
            showSizeChanger={false}
          />
        </div>
        <ModalInterviewCandidate
          instanceForm={form}
          getData={(status, data, selectedRole, selectedAccount) =>
            handleGetDataModalAdd(status, data, selectedRole, selectedAccount)
          }
          dataEdit={null}
          openModal={openModalAdd}
          onClick={() => {
            setOpenModalAdd(false);
            form.resetFields();
          }}
          title={"Add"}
        />
        <ModalInterviewCandidate
          instanceForm={form}
          getData={(status, data, selectedRole, selectedAccount) =>
            handleGetDataModalEdit(status, data, selectedRole, selectedAccount)
          }
          dataEdit={dataEdit}
          openModal={openModalEdit}
          onClick={() => {
            setOpenModalEdit(false);
            form.resetFields();
            setDataEdit(null);
          }}
          title={"Edit"}
        />
        <Modal
          forceRender
          width={315}
          footer={null}
          closable={false}
          keyboard={false}
          open={openModalDelete}
          onOk={handleClickDeleteModal}
          onCancel={() => setOpenModalDelete(false)}
        >
          <div className="flex flex-col justify-center items-center gap-[30px] text-center">
            <p>Are you sure?</p>
            <p className="text-center">
              Do you really want to delete these records? This process cannot be
              undone
            </p>
            <div className="flex gap-[10px]">
              <Button onClick={() => setOpenModalDelete(false)}>Cancel</Button>
              <Button onClick={handleClickDeleteModal} type="primary" danger>
                Delete
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          title={"Export Data"}
          forceRender
          width={315}
          footer={null}
          closable={false}
          keyboard={false}
          open={modalExport}
          onCancel={() => {
            setRangeDate([]);
            setModalExport(false);
            setRefreshCounterDaterange((prevValue) => prevValue + 1);
          }}
        >
          <div>
            <div>
              <label> Date Range</label>
              <RangePicker
                key={refreshCounterDaterange}
                onChange={(_, dateString) => {
                  setRangeDate(dateString);
                }}
              />
            </div>

            <div className="flex gap-[20px] mt-[20px] justify-between">
              <Button
                onClick={() => {
                  setRangeDate([]);
                  setModalExport(false);
                  setRefreshCounterDaterange((prevValue) => prevValue + 1);
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleExportData} type="primary" danger>
                Export
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default InterviewCandidate;
