import React, { useContext, useEffect, useState } from "react";
import TalentJourneyRepo from "../../../repositores/talentRepo/TalentJourneyTalentRepo";
import TalentHeader from "../../../components/general/TalentHeader";
import SkeletonTable from "../../../components/general/SkeletonTable";
import { talentJourneyTopicColumns } from "./column.state";
import { Table, message, Typography } from "antd";
import { TalentHelperContext } from "../../../components/talentPage/TalentContext";
import AlertUtility from "../../../components/Alert/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import RouteTalent from "../../../services/RouteTalent";
import { BackArrow } from "./components/Button";
import { PopUp } from "./components/PopUp";

const { Title } = Typography;

const TalentJourneyTopic = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [topicData, setTopicData] = useState([]);
  const [topicDetail, setTopicDetail] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isUas, setIsUas] = useState("");
  const [isUts, setIsUts] = useState("");
  const { selectMenu } = useContext(TalentHelperContext);
  const location = useLocation();
  const [multiChoiceOptions, setMultiChoiceOptions] = useState([]);
  const [multiChoiceAnswers, setMultiChoiceAnswers] = useState([]);

  const handleMultiChoiceAswers = (id, value) => {
    setMultiChoiceAnswers((prev) => [...prev, { id: id, opsiTrue: value }]);
  };

  const getTopic = async (pageNumber = 0, searchValue) => {
    setLoading(true);
    const pageSize = 12;
    await TalentJourneyRepo.getTalentJourneyTopic(
      pageNumber,
      pageSize,
      location.state?.id,
      location.state?.material.id
    )
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setTopicData(result.data.data.content);
        setPage(result.data.data.number);
        setTotalData(result.data.data.totalElements);
        setIsUas(result.data.data.isGoUAS);
        setIsUts(result.data.data.isGoUTS);
      })
      .catch((error) => {
        setTopicData([]);
        setPage(0);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actionBack = () => {
    navigate(RouteTalent.talentJourneyTermin, {
      state: {
        id: location.state?.id,
        course: location.state?.course,
        courseId: location.state?.courseId,
      },
    });
  };

  const handleSubmit = async (id, desc, feedback) => {
    setLoading(true);
    const param = {
      id: id,
      description: desc,
      feedback: feedback,
    };

    await TalentJourneyRepo.SubmitUTSUAS(param)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }

        setShow(false);
      })
      .catch((error) => {
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(async () => {
        await TalentJourneyRepo.submitMultiChoice(id, multiChoiceAnswers)
          .then((res) => {
            getTopic();
          })
          .catch((error) => console.error(error))
          .finally(() => {
            setMultiChoiceAnswers(() => []);
            setLoading(false);
          });
      });

    //if(multiChoiceAnswers.length === multiChoiceOptions.length) {}
  };

  const handleProgressLearning = async (id, desc) => {
    const param = {
      id: id,
    };

    await TalentJourneyRepo.SubmitProgress(param);

    getTopic(page);
  };

  useEffect(async () => {
    getTopic(page);
    selectMenu("Talent Journey");
  }, []);

  useEffect(() => {
    if (
      topicDetail?.subMatkul?.id &&
      topicDetail?.subMatkul?.id !== "" &&
      show
    ) {
      getMultiChoiceOptions(topicDetail?.subMatkul.id);
    }
  }, [show]);

  const getMultiChoiceOptions = async (id = "") => {
    if (id === "") return;
    await TalentJourneyRepo.getMultiChoice(id)
      .then((res) => {
        //if(!res.data.data.content) return;
        setMultiChoiceOptions(res.data.data.content);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmitPGAnwers = async (body) => {};

  return (
    <>
      <PopUp
        open={show}
        setOpen={() => {
          setMultiChoiceAnswers(() => []);
          setShow(false);
        }}
        title={modalTitle}
        description={topicDetail.description}
        setDescription={() => {}}
        feedback={topicDetail.feedback}
        setFeedback={() => {}}
        data={topicDetail}
        handleSubmit={(id, desc, feedback) => {
          /* if (multiChoiceAnswers.length === multiChoiceOptions.length) {
            handleSubmit(id, desc, feedback);
          }else {
          } */
          handleSubmit(id, desc, feedback);
        }}
        handleMultiChoiceAswers={(id, value) => {
          handleMultiChoiceAswers(id, value);
        }}
        multiChoiceOptions={multiChoiceOptions}
        isLoading={loading}
      />
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"Talent Journey Topic"} subDescription={""} />
        <div className="flex justify-start w-full my-4">
          <BackArrow actionBack={actionBack} />
        </div>
        <div>
          <Title level={3}>{location.state?.material.matkul}</Title>
        </div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={talentJourneyTopicColumns(
                  false,
                  (row) => {
                    setShow(true);
                    setModalTitle(row.subMatkul.topik);
                    setTopicDetail(row);
                  },
                  (id) => {
                    handleProgressLearning(id);
                  },
                  isUas,
                  isUts
                )}
                dataSource={topicData}
                pagination={false}
                sticky={true}
                scroll={{
                  y: 470,
                  x: 1000,
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TalentJourneyTopic;
