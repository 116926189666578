import CallApi from "../services/Request-Helper";
import { createSearchParams } from "../utils/utils";
import { SERVICE_LOOKUP_EVENT_TYPE, 
    SARVICE_LOOKUP_EVENT_JOURNEY,
    SERVICE_LOOKUP_EVENT_TASK,
    SERVICE_LOOKUP_TYPE_CERTIFICATE,
    SARVICE_LOOKUP_TYPE_BOOLEAN,
    SARVICE_LOOKUP_TYPE_INTEREST,
    SARVICE_LOOKUP_TYPE_PENILAIAN
} from "../utils/constant";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const getServiceLookupEventType = async (page, size) => {
    let params = {
        page: page,
        size: size,
        type: SERVICE_LOOKUP_EVENT_TYPE
    }
    const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getServiceLookupEventJourney = async (page, size) => {
    let params = {
        page: page,
        size: size,
        type: SARVICE_LOOKUP_EVENT_JOURNEY
    }
    const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getServiceLookupTaskEvent = async (page, size) => {
    let params = {
        page: page,
        size: size,
        type: SERVICE_LOOKUP_EVENT_TASK
    }
    const url = `${API_BASE}/lookup/list-master-event-type?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getServiceLookupCertificate = async (page, size) => {
    let params = {
        page: page,
        size: size,
        type: SERVICE_LOOKUP_TYPE_CERTIFICATE
    }
    const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getServiceLookupOnBoardTypeBoolean = async (page, size) => {
    let params = {
        page: page,
        size: size,
        type: SARVICE_LOOKUP_TYPE_BOOLEAN
    }
    const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getServiceLookupOnBoardTypeInterest = async (page, size) => {
    let params = {
        page: page,
        size: size,
        type: SARVICE_LOOKUP_TYPE_INTEREST
    }
    const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getServiceLookupOnBoardTypePenilaian = async (page, size) => {
    let params = {
        page: page,
        size: size,
        type: SARVICE_LOOKUP_TYPE_PENILAIAN
    }
    const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}



const ServiceLookUpRepo = {
    getServiceLookupEventType,
    getServiceLookupEventJourney,
    getServiceLookupTaskEvent,
    getServiceLookupCertificate,
    getServiceLookupOnBoardTypeBoolean,
    getServiceLookupOnBoardTypeInterest,
    getServiceLookupOnBoardTypePenilaian
}

export default ServiceLookUpRepo