import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const getClientHistory = async (page = 0, size = 10, roleName = undefined, name = undefined) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  if (typeof roleName != "undefined" && roleName.length) {
    params["roleName"] = roleName;
  }
  const url = `${API_BASE}/history-client-talent/list-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getClientHistoryId = async (uuid) => {
  const url = `${API_BASE}/history-client-talent/${uuid}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getProjectHistory = async (page = 0, size = 10, name = undefined, id) => {
  let params = {
    page: page + 1,
    size: size,
  };

  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/history-project/history-client/${id}?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addProjectHistory = async (body) => {
  const url = `${API_BASE}/history-project`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const editProjectHistory = async (body) => {
  const url = `${API_BASE}/history-project`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const deleteProjectHistory = async (id) => {
  const url = `${API_BASE}/history-project/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
};

const ProjectHistory = {
  getClientHistory,
  addProjectHistory,
  editProjectHistory,
  deleteProjectHistory,
  getClientHistoryId,
  getProjectHistory,
};

export default ProjectHistory;
