export const EventCertificate = ({
  certificateName,
  certificateUrl,
  downloadCertificate,
}) => {
  return (
    <button
      className="flex-shrink-0 max-w-[150px] rounded-full py-1 px-3 border border-black text-left"
      disabled={!certificateUrl}
      onClick={downloadCertificate}
    >
      <span className="line-clamp-1 text-black text-xs">{certificateName}</span>
    </button>
  );
};
