import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker, message, Pagination, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import AdminLmsRepo from "../../../repositores/adminRepo/LmsRepo";
import TableListHistories from "./components/tableListHistories";
import ModalImportAssign from "./components/modalImportAssign";
import AlertUtility from "../../../components/Alert/Alert";

const HistoriesLms = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [searchCourse, setSearchCourse] = useState(undefined);
  const [searchBatch, setSearchBatch] = useState(undefined);
  const [searchTalent, setSearchTalent] = useState(undefined);
  const [filterTrigger, setFilterTrigger] = useState(0);
  const [datesRange, setDatesRange] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dataTable, setDataTable] = useState([]);
  const [totalItem, setTotalItem] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);

  const [bounchPage, setBounchPage] = useState(undefined);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterTrigger, refreshCounter, datesRange]);

  async function fetchData() {
    setLimit(10);
    try {
      let tempData = [];
      let response = await AdminLmsRepo.getListHistories(
        page,
        limit,
        searchCourse,
        searchBatch,
        searchTalent,
        datesRange?.[0],
        datesRange?.[1]
      );
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content, index) => {
          content.number = limit * (page - 1) + (index + 1);
          tempData.push(content);
        });
        setDataTable(tempData);
        setTotalItem(response?.data?.data?.totalElements);
      } else {
        setDataTable([]);
        setTotalItem(0);
      }
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
      messageApi.open({
        type: "error",
        content: "Failed to get Data event",
      });
    }
  }

  const onChangePage = (el) => {
    setLoadingData(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setPage(el);
      }, 1000)
    );
  };

  const onChangeSearch = (type, el) => {
    setLoadingData(true);
    if (type === "Course") {
      setSearchCourse(el.target.value);
    } else if (type === "Batch") {
      setSearchBatch(el.target.value);
    } else if (type === "Talent") {
      setSearchTalent(el.target.value);
    }
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setFilterTrigger((prevVal) => prevVal + 1);
        setPage(1);
      }, 2000)
    );
  };

  const selectToDetail = (data) => {
    navigate(
      "/admin/history-course/" + data?.course?.id + "/" + data?.user?.id
    );
  };

  const closeModal = (isSuccess) => {
    if (isSuccess) {
      setLoadingData(true);
      clearTimeout(bounchPage);
      setBounchPage(
        setTimeout(() => {
          const counter = refreshCounter + 1;
          setRefreshCounter(counter);
        }, 1000)
      );
    }
    setIsOpenModal(false);
  };
  const doAssign = () => {
    setIsOpenModal(true);
  };

  const handleChangeRangeDate = (dates) => {
    if (dates) {
      setDatesRange([
        dates[0].startOf("day").format("YYYY-MM-DD"),
        dates[1].endOf("day").format("YYYY-MM-DD"),
      ]);
    } else {
      setDatesRange(null);
    }
  };

  const downloadExcelTemplate = async () => {
    try {
      setLoading(true);

      if (!datesRange) {
        throw new Error("Please select date range");
      }

      const { data } = await AdminLmsRepo.getDownloadFileHistory(
        datesRange?.[0],
        datesRange?.[1]
      );
      const blob = new Blob([data]);
      let url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.setAttribute("download", "template.csv");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      AlertUtility.ErrorAlert({ title: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="w-full  h-screen px-4 py-5" id="courseList">
        <div className="text-center md:text-left mb-4">
          <h4 className="text-2xl md:text-4xl font-bold">
            Histories Talent IDP
          </h4>
        </div>

        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <RangePicker onChange={handleChangeRangeDate} />
            <Button
              type="primary"
              danger
              onClick={() => downloadExcelTemplate()}
            >
              Download File
            </Button>
          </div>
          <div className="flex flex-row gap-4 lg:mb-0">
            <Input
              className="w-full lg:w-[200px] max-w-[400px]"
              placeholder="Search by Course Name"
              disabled={loading}
              onChange={(el) => onChangeSearch("Course", el)}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
            <Input
              className="w-full lg:w-[200px] max-w-[400px]"
              placeholder="Search by Batch"
              disabled={loading}
              onChange={(el) => onChangeSearch("Batch", el)}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
          </div>
          <div className="flex flex-row gap-4 lg:mb-0">
            <Input
              className="w-full lg:w-[200px] max-w-[400px]"
              placeholder="Search by Talent Name"
              disabled={loading}
              onChange={(el) => onChangeSearch("Talent", el)}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
            <Button
              onClick={() => doAssign()}
              type="primary"
              disabled={loading}
              danger
              className="mr-1 bg-[#DD0000]"
              style={{ backgroundColor: "#DD0000" }}
            >
              Assign
            </Button>
          </div>
        </div>
        {loading || loadingData ? (
          <>
            <div className="w-full text-center mt-[20px]">
              <p>Please wait...</p>
            </div>
          </>
        ) : (
          <div className="mt-[20px] w-full">
            <TableListHistories
              isLoading={loading}
              dataTable={dataTable}
              selectToDetail={(data) => selectToDetail(data)}
            />
            <div className="w-full flex justify-center my-2 pt-2 pb-10">
              <Pagination
                current={page}
                onChange={onChangePage}
                pageSize={limit}
                total={totalItem}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </div>
      <ModalImportAssign
        show={isOpenModal}
        closeModal={(isSuccess) => closeModal(isSuccess)}
      />
    </>
  );
};
export default HistoriesLms;
