import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import MasterDashboardRepo from "../../../../repositores/adminRepo/DashboardRepo";
import { capitalizeEachWord } from "../../../../utils/utils";
import { LeftSquareTwoTone, RightSquareTwoTone } from "@ant-design/icons";

const Information = ({ talent }) => {
  return (
    <div className="my-8 flex justify-between">
      {/* name */}
      <div className="flex items-center grow">
        <div className="w-[64px] h-[64px] text-center bg-[#d9d9d9] border-[3px] rounded-full border-[#fff] shadow-lg items-center flex justify-center mr-2">
          <div className="text-3xl font-bold text-[#979797]">
            {talent.fullName[0].toUpperCase()}
          </div>
        </div>
        <div>
          <div className="font-semibold text-[#4c4c4c]">
            {" "}
            {talent.fullName}{" "}
          </div>
        </div>
      </div>
      {/* xp */}
      <div className="mr-6 flex items-center">
        <div className="w-[30px] h-[30px] rounded-full bg-[#D0CBFF] text-[#4F40F4] font-bold items-center flex justify-center mr-2 text-sm">
          XP
        </div>
        <div className="text-[#4C4C4C] font-medium"> {talent.totalXp} </div>
      </div>
      {/* icon */}
      <div className="mr-8 flex items-center">
        <img src="/assets/images/coin.svg" alt="" className="mr-2" width={30} />
        <div className="text-[#4C4C4C] font-medium"> {talent.totalGc} </div>
      </div>
    </div>
  );
};

const Rank = ({ dataRank }) => {
  return (
    <div className="mb-8">
      <div className="font-bold mb-1">Current Rank</div>
      <div className="">
        {dataRank?.rank || 0}/{dataRank?.total || 0}
      </div>
    </div>
  );
};

const Certificate = ({ certificates }) => {
  return (
    <div className="mb-8">
      <div className="font-bold mb-2">Certificates</div>
      {certificates.length > 0 ? (
        certificates.map((cert, i) => {
          return (
            <div className="mb-1" key={i}>
              {cert.event.name}
            </div>
          );
        })
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

const Champion = ({ champions }) => {
  return (
    <div>
      <div className="font-bold mb-2">Champion</div>
      {champions.length > 0 ? (
        champions.map((champ) => {
          return (
            <div className="flex justify-between mb-1" key={champ.id}>
              <div> {capitalizeEachWord(champ.type)} </div>
              <div>{capitalizeEachWord(champ.title)}</div>
            </div>
          );
        })
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

const History = ({ histories , toggleHistoryModal}) => {
  return (
    <div className="mb-3">
      <div className="flex flex-row justify-between items-center">
        <div className="text-lg font-bold">
          <p>Coin History</p>
        </div>
        <div
          className="cursor-pointer font-bold text-blue-600"
          onClick={() => {
            toggleHistoryModal(true);
          }}
        >
          <p>See All</p>
        </div>
      </div>
      <div className="">
        {histories && histories?.map((data, index) => {
          return (
            <div
              key={index}
              className="font-bold flex justify-between items-center"
            >
              <p>
                {data?.status === "IN"
                  ? data?.event?.name
                  : data?.voucher?.description}
              </p>
              <div className="flex items-center justify-end ">
              
                <img
                  src="/assets/images/coin.svg"
                  alt=""
                  className="mr-2"
                  width={30}
                />
                <p
                        className={` flex flex-row justify-start gap-2 items-center ${
                          data?.status === "IN" ? "text-green" : "text-primary"
                        }`}
                      >
                        <span>
                          <img
                            width={15}
                            height={15}
                            src={
                              data?.status === "IN"
                                ? "/assets/icons/plus.png"
                                : "/assets/icons/minus.png"
                            }
                          />
                        </span>
                        <span>{data.gcTotal}</span>
                      </p>
              </div>
            </div>
          );
        })}
        {histories.length <= 0 && "-"}
      </div>
    </div>
  );
};

const ModalDetail = ({ show, closeModal, currentTalent, showHistory, toggleHistoryModal }) => {
  const [certificates, setCertificates] = useState([]);
  const [champions, setChampions] = useState([]);
  const [dataRank, setDataRank] = useState(null);
  const [dataHistory, setDataHistory] = useState([]);

  const handleHistory = async (profileId) => {
    if (!profileId) return;
    await MasterDashboardRepo.getCoinHistory(0, 5, profileId)
      .then((res) => {
        //handleHistoryData(res.data.data);
        setDataHistory(res.data.data.content);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleMoreHistory = () => {
    handleHistory(currentTalent.id);
  };

  const getDetailLeaderboard = async () => {
    try {
      const { data } = await MasterDashboardRepo.getDetailLeaderboard(
        currentTalent.id
      );
      setDataRank({
        rank: data.rangked,
        total: data.totalUser,
      });
      setCertificates(data.listCertificate);
      setChampions(data.listChampion);
    } catch (error) {
      setDataRank(null);
      setCertificates([]);
      setChampions([]);
    }
  };

  useEffect(() => {
    if (show) {
      getDetailLeaderboard();
      handleMoreHistory();
    }
  }, [show]);

  return (
    <>
    <Modal open={show} onCancel={closeModal} footer={null} title="User Detail">
      <Information talent={currentTalent} />
      <Rank dataRank={dataRank} />
      <History histories={dataHistory} toggleHistoryModal={toggleHistoryModal}/>
      <Certificate certificates={certificates} />
      <Champion champions={champions} />
    </Modal>
    <ModalHistory show={showHistory} talentProfile={currentTalent} toggleHistoryModal={toggleHistoryModal}></ModalHistory>
    </>
  );
};

const ModalHistory = ({ show, talentProfile, toggleHistoryModal }) => {
  const [dataHistory, setDataHistory] = useState([]);
  const [page, setPage] = useState(0);
  
  const handleHistory = async (profileId) => {
    if (!profileId) return;
    await MasterDashboardRepo.getCoinHistory(page, 10, profileId)
      .then((res) => {
        setDataHistory(() => {
          return res.data.data;
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };
  
  useEffect(() => {
    if(show) {
      handleHistory(talentProfile.id)
    }
  }, [show])
  
  useEffect(() => {
    if(show) {
      handleHistory(talentProfile.id)
    }
  }, [page]);
  
  const handleNextPageChangeVoucher = (e) => {
		e.preventDefault();
		if(dataHistory?.last) return;
		let nextPage = dataHistory?.number+1;
		setPage(() => {
			return nextPage;
		})
	}
	
	const handlePrevPageChangeVoucher = (e) => {
		e.preventDefault();
		if(dataHistory?.number <= 0) return;
		let prev = dataHistory?.number-1;
		setPage(() => {
			return prev;
		})
	}
  
  
  return (
    <Modal open={show} onCancel={() => {
      toggleHistoryModal(false);
      setPage(0);
    }} footer={null}
    >
      <div className="mt-5">
        {dataHistory.content && dataHistory?.content?.map((data, index) => {
          return (
            <div
              key={index}
              className="font-bold flex justify-between items-center"
            >
              <p>
                {data?.status === "IN"
                  ? data?.event?.name
                  : data?.voucher?.description}
              </p>
              <div className="flex items-center justify-end ">
                <img
                  src="/assets/images/coin.svg"
                  alt=""
                  className="mr-2"
                  width={30}
                />
                <p
                        className={` flex flex-row justify-start gap-2 items-center ${
                          data?.status === "IN" ? "text-green" : "text-primary"
                        }`}
                      >
                        <span>
                          <img
                            width={15}
                            height={15}
                            src={
                              data?.status === "IN"
                                ? "/assets/icons/plus.png"
                                : "/assets/icons/minus.png"
                            }
                          />
                        </span>
                        <span>{data.gcTotal}</span>
                      </p>
              </div>
            </div>
          );
        })}
        {dataHistory.content?.length <= 0 && "-"}
      </div>
      <ModalHistoryPagination
      nextPage={dataHistory.last}
      previousPage={dataHistory.first}
      currentPage={dataHistory.number}
      totalPages={dataHistory.totalPages}
      onNextPage={handleNextPageChangeVoucher}
      onPrevPage={handlePrevPageChangeVoucher}
      />
    </Modal>
  );
};

const ModalHistoryPagination = ({ nextPage, previousPage, currentPage, totalPages, onNextPage, onPrevPage }) => {

  return (
      <div className='flex justify-center py-3'>
          <button
          className='px-4'
          onClick={event => {
              //chcek if fisrt
              onPrevPage(event);
          }}
          disabled={previousPage}
          >
              <LeftSquareTwoTone
              twoToneColor={(!previousPage ? "#fc0303" : "#9ca3af" )}
              style={{ fontSize:25 }}
              />
          </button>
          {currentPage + 1} of {totalPages}
          <button
          className='px-4'
          onClick={event => {
              //check if last
              onNextPage(event);
          }}
          disabled={nextPage}
          >
              <RightSquareTwoTone
              twoToneColor={(!nextPage ? "#fc0303" : "#9ca3af" )}
              style={{ fontSize:25 }}
              />
          </button>
      </div>
  )
}

export default ModalDetail;
