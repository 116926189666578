import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListOnboarding = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/onboard-sop/list-admin?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const createOrUpdateOnBoard = async (payload) => {
    let url = `${API_BASE}/onboard-sop/create-update-admin`;
    const response = await CallApi({ url, method: "POST", data: payload, type: true  });
    return response;
};

const getFile = async (fileName) => {
  let url = `${API_BASE}/showFile/${fileName}`;
  // const response = await CallApi({ url, method: "GET"  });
  return url;
}

const getListOneMonthSupervised = async (page = 0, size = 10, name = undefined, client = undefined, status = undefined, tlStatus = undefined) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["talentName"] = name;
  }
  if (typeof client != "undefined" && client.length) {
    params["clientName"] = client;
  }
  if (typeof status != "undefined" && status.length) {
    params["status"] = status;
  }
  if (typeof tlStatus != "undefined" && tlStatus.length) {
    params["statusByTL"] = tlStatus;
  }
  const url = `${API_BASE}/one-month-supervised/list-admin?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addOrUpdateOms = async (body) => {
  const url = `${API_BASE}/one-month-supervised/add-update-admin`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
}

const getListDetailOneMonthSupervised = async (id, page = 0, size = 20, name = undefined) => {
  let params = {
    page: page,
    size: size,
  };
  const url = `${API_BASE}/one-month-supervised/list-detail-admin/${id}?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const updateDetailOneMonthSupervised = async (body) => {
  const url = `${API_BASE}/one-month-supervised/add-update-detail-admin`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
}

const sendEmail = async (id) => {
  const url = `${API_BASE}/one-month-supervised/send-email-reminder/${id}`;
  const response = await CallApi({ url, method: "POST" });
  return response;
}

const Onboarding = {
    getListOnboarding,
    createOrUpdateOnBoard,
    getFile,
    getListOneMonthSupervised,
    addOrUpdateOms,
    getListDetailOneMonthSupervised,
    updateDetailOneMonthSupervised,
    sendEmail
};
  
export default Onboarding;