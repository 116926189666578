import { Fragment, React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import RouteAdm from "../../../services/routeAdm";
import AlertUtility from "../../Alert/Alert";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../helper/routeName";
import DashboardTalentRepo from "../../../repositores/talentRepo/DashboardTalentRepo";
import LogoRepo from "../../../repositores/adminRepo/LogoRepo";
import {
  LuAlignJustify,
  LuChevronLeft,
  LuChevronRight,
  LuLogOut,
  LuX,
} from "react-icons/lu";

const Sidebar = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [logoGlobal, setLogoGlobal] = useState(null);
  const navigate = useNavigate();
  const [menuStates, setMenuStates] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleSubMenu = (title) => {
    setMenuStates((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  const onClickLogout = () => {
    AlertUtility.confirmAlert({
      text: "You want to logout?",
      confirmText: "Yes, logout!",
      onConfirm: logoutUser,
    });
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate(RouteName.login);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await DashboardTalentRepo.getTalentProfile();
        const responseLogo = await LogoRepo.getLogoGlobal();
        const Logo = responseLogo.data?.data?.content;
        if (response.data.data?.roles[0].name !== "ROLE_ADMIN") {
          logoutUser();
        }

        if (isMounted) {
          setName(response?.data?.data?.fullname);
          setEmail(response?.data?.data?.username);
          setLogoGlobal(Logo);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Menus = [
    {
      title: "Dashboard",
      src: "dashboard",
      route: RouteAdm.dashboard,
      hasChild: false,
    },
    {
      title: "Leaderboard",
      src: "leaderboard",
      route: RouteAdm.leaderboard,
      hasChild: false,
    },
    {
      title: "User Management",
      src: "usermanagement",
      route: RouteAdm.userManagement,
      hasChild: false,
    },
    {
      title: "Certificates",
      src: "certif",
      route: RouteAdm.certificateIssued,
      hasChild: false,
    },
    {
      title: "Redeem History",
      src: "redeem",
      route: RouteAdm.redeemHistory,
      hasChild: false,
    },
    {
      title: "Friday Booster",
      src: "redeem",
      route: RouteAdm.reportFridayBooster,
      hasChild: false,
    },
    {
      title: "Data Master",
      src: "datamaster",
      route: "",
      hasChild: true,
      Childs: [
        {
          title: "Master Event",
          src: "masterevent",
          route: RouteAdm.masterEvent,
        },
        {
          title: "Event",
          src: "event",
          route: RouteAdm.event,
        },
        {
          title: "Task",
          src: "task",
          route: RouteAdm.task,
        },
        {
          title: "Certificate Template",
          src: "certificatetemplate",
          route: RouteAdm.certificateTemplate,
        },
        {
          title: "Badges",
          src: "badges",
          route: RouteAdm.badges,
        },
        {
          title: "Reward",
          src: "reward",
          route: RouteAdm.rewards,
        },
        {
          title: "Journey",
          src: "journey",
          route: RouteAdm.journey,
        },
        {
          title: "Client",
          src: "client",
          route: RouteAdm.clientDataMaster,
        },
        {
          title: "Role",
          src: "role",
          route: RouteAdm.roleDataMaster,
        },
      ],
    },
    {
      title: "Management Talent",
      src: "datamaster",
      route: "",
      hasChild: true,
      Childs: [
        {
          title: "Status Talent",
          src: "statustalent",
          route: RouteAdm.statusTalent,
        },
        {
          title: "Placement Talent History",
          src: "placementtalenthistory",
          route: RouteAdm.placementTalentHistory,
        },
        {
          title: "Client History",
          src: "adminClienthistory",
          route: RouteAdm.admClientHistory,
        },
      ],
    },
    {
      title: "TA",
      src: "datamaster",
      route: "",
      hasChild: true,
      Childs: [
        {
          title: "Checklist Interview Client",
          src: "interviewtalent",
          route: RouteAdm.checklistInterviewClient,
        },
        {
          title: "Checklist Interview Candidate",
          src: "interviewcandidate",
          route: RouteAdm.checklistInterviewCandidate,
        },
      ],
    },
    {
      title: "LMS",
      src: "iconBook",
      route: "",
      hasChild: true,
      Childs: [
        {
          title: "Categories",
          src: "iconBook",
          route: RouteAdm.admQuizCategories,
        },
        {
          title: "Question Bank",
          src: "iconBook",
          route: RouteAdm.admQuestionBank,
        },
        {
          title: "Quiz",
          src: "iconBook",
          route: RouteAdm.admQuizData,
        },
        {
          title: "Courses",
          src: "iconBook",
          route: RouteAdm.admLmsCourse,
        },
        {
          title: "History",
          src: "iconBook",
          route: RouteAdm.admLmsHistories,
        },
      ],
    },
    {
      title: "Onboarding",
      src: "datamaster",
      route: "",
      hasChild: true,
      Childs: [
        {
          title: "Onboarding",
          src: "iconBook",
          route: RouteAdm.admOnboarding,
        },
        {
          title: "1 Month Supervise",
          src: "iconBook",
          route: RouteAdm.admOMS,
        },
      ],
    },
  ];

  return (
    <div className="relative">
      {/* Overlay */}
      {openDrawer && (
        <div
          onClick={toggleDrawer}
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
        />
      )}

      {/* Sidebar for Desktop */}
      <div
        className={`${
          isCollapsed ? "w-[80px]" : "w-[250px]"
        } bg-heritageGreen h-screen py-5 pt-8 relative duration-300 bg-white border-r-2 border-r-lightgrey overflow-visible md:block hidden`}
      >
        <div className="flex items-center justify-between px-4">
          <NavLink to={RouteAdm.dashboard}>
            {logoGlobal?.map((item) => (
              <img key={item.id} alt={item.id} src={item.name} />
            ))}
          </NavLink>
          <button
            onClick={toggleSidebar}
            className={`focus:outline-none absolute border-2 bg-white rounded-full right-[-15px] ${
              isCollapsed ? "top-6" : "top-12"
            }`}
          >
            {isCollapsed ? (
              <LuChevronRight className="text-2xl" />
            ) : (
              <LuChevronLeft className="text-2xl" />
            )}
          </button>
        </div>
        <ul className="pt-6">
          <div className="flex flex-col justify-between h-screen pb-32 overflow-y-auto">
            <div>
              {Menus.map((Menu, index) => (
                <Fragment key={index}>
                  {Menu.hasChild ? (
                    <div
                      className={`relative group ${
                        isCollapsed ? "justify-center" : "ml-4"
                      } flex flex-col font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30`}
                    >
                      <div
                        className={`${
                          isCollapsed ? "flex-col text-center" : "flex-row"
                        } text-sm flex items-center gap-x-1 mb-1`}
                        onClick={() => toggleSubMenu(Menu.title)}
                      >
                        <div className="h-5 w-5 flex">
                          <img
                            src={`/assets/icons/${Menu.src}.png`}
                            alt={`${Menu.title}`}
                          />
                          {isCollapsed && (
                            <div className="ml-auto text-center">
                              <LuChevronRight size={20} />
                            </div>
                          )}
                        </div>
                        <span
                          className={`${
                            isCollapsed
                              ? "text-[10px] text-center mt-1"
                              : "origin-left duration-200 ml-2"
                          }`}
                        >
                          {Menu.title}
                        </span>

                        <div
                          className={`${
                            menuStates[Menu.title]
                              ? "transition-width duration-300 ease rotate-180"
                              : "transition-width duration-300 ease rotate-0"
                          } ${isCollapsed ? "hidden" : "ml-auto"}`}
                        >
                          <img
                            src={`/assets/icons/arrow-down.svg`}
                            alt={`${Menu.title}`}
                          />
                        </div>
                      </div>
                      {/* Child menu for collapsed sidebar */}
                      {isCollapsed && (
                        <div
                          className="fixed top-0 left-[80px] hidden group-hover:block bg-white shadow-lg z-20 min-w-[150px]"
                          style={{
                            top: `calc(${index * 50}px + 10px)`,
                          }}
                        >
                          {Menu.Childs?.map((arr, indx) => (
                            <NavLink
                              key={`${index}-${indx}`}
                              to={arr.route}
                              className="text-sm block py-2 px-4 hover:bg-lightgrey"
                            >
                              {arr.title}
                            </NavLink>
                          ))}
                        </div>
                      )}

                      {!isCollapsed && menuStates[Menu.title] && (
                        <>
                          {Menu.Childs?.map((arr, indx) => (
                            <NavLink
                              key={`${index}-${indx}`}
                              to={arr.route}
                              className={
                                "transition-opacity ease-in duration-700 opacity-100 border-l-2 ml-2 pl-4 pt-2"
                              }
                            >
                              <div className="text-[12px]">{arr.title}</div>
                            </NavLink>
                          ))}
                        </>
                      )}
                    </div>
                  ) : (
                    <NavLink
                      to={Menu.route}
                      className={`${
                        isCollapsed ? "justify-center items-center" : "ml-4"
                      } font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex flex-col`}
                    >
                      <div
                        className={`${
                          isCollapsed
                            ? "text-center"
                            : "flex items-center gap-x-1"
                        }`}
                      >
                        <div className="h-5 w-5">
                          <img
                            src={`/assets/icons/${Menu.src}.svg`}
                            alt={`${Menu.title}`}
                          />
                        </div>
                        {!isCollapsed && (
                          <span className={`origin-left duration-200 ml-2`}>
                            {Menu.title}
                          </span>
                        )}
                      </div>
                      {isCollapsed && (
                        <span className="text-[10px] text-center mt-1">
                          {Menu.title}
                        </span>
                      )}
                    </NavLink>
                  )}
                </Fragment>
              ))}
            </div>
            {/* Logout and Profile */}
            <div>
              <div
                onClick={onClickLogout}
                className="font-normal my-1 ml-4 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex"
              >
                <div
                  className={`${
                    isCollapsed ? "flex-col text-center" : "flex-row"
                  } text-sm flex items-center gap-x-1 mb-1`}
                >
                  <div className="h-5 w-5">
                    <LuLogOut size={22} color="grey" />
                  </div>
                  <span
                    className={`${
                      isCollapsed
                        ? "text-[10px] text-center mt-1"
                        : "origin-left duration-200 ml-2"
                    }`}
                  >
                    Log Out
                  </span>
                </div>
              </div>
              <div
                className={`${
                  isCollapsed ? "flex-col mx-4" : "flex-row mx-4"
                } text-sm flex mb-1`}
              >
                <div className="h-10 w-10">
                  <img src={`/assets/images/vincent.svg`} alt="profile" />
                </div>
                <span
                  className={`${
                    isCollapsed
                      ? "text-[10px] mt-1"
                      : "origin-left duration-200 ml-2"
                  }`}
                >
                  <div className="text-[12px] text-grey-400">{name}</div>
                  <div className="text-[12px] text-grey-400 break-words">
                    {email}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </ul>
      </div>

      {/* Mobile Drawer */}
      <div
        className={`fixed top-0 left-0 h-full bg-white shadow-lg z-50 transform transition-transform ${
          openDrawer ? "translate-x-0" : "-translate-x-full"
        } md:hidden`}
      >
        <div className="relative w-64 h-full p-4">
          <div className="flex items-center justify-between">
            <NavLink to={RouteAdm.dashboard}>
              {logoGlobal?.map((item) => (
                <img key={item.id} alt={item.id} src={item.name} />
              ))}
            </NavLink>
          </div>
          <ul className="pt-6">
            <div className="flex flex-col justify-between h-full overflow-y-auto">
              <div>
                {Menus.map((Menu, index) => (
                  <Fragment key={index}>
                    {Menu.hasChild ? (
                      <div className="relative group flex flex-col font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30">
                        <div
                          className="text-sm flex items-center gap-x-1 mb-1"
                          onClick={() => toggleSubMenu(Menu.title)}
                        >
                          <div className="h-5 w-5 flex">
                            <img
                              src={`/assets/icons/${Menu.src}.png`}
                              alt={`${Menu.title}`}
                            />
                          </div>
                          <span className="ml-2">{Menu.title}</span>

                          <div
                            className={`${
                              menuStates[Menu.title]
                                ? "transition-transform duration-300 ease-in transform rotate-180"
                                : "transition-transform duration-300 ease-in transform rotate-0"
                            } ml-auto`}
                          >
                            <img
                              src={`/assets/icons/arrow-down.svg`}
                              alt={`${Menu.title}`}
                            />
                          </div>
                        </div>
                        {/* Show child menu if the submenu is open */}
                        {menuStates[Menu.title] && (
                          <div className="ml-2 pl-4">
                            {Menu.Childs?.map((arr, indx) => (
                              <NavLink
                                key={`${index}-${indx}`}
                                to={arr.route}
                                className="block py-2 text-sm text-gray-700 hover:bg-lightgrey"
                              >
                                {arr.title}
                              </NavLink>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        to={Menu.route}
                        className="font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex items-center"
                      >
                        <div className="h-5 w-5">
                          <img
                            src={`/assets/icons/${Menu.src}.svg`}
                            alt={`${Menu.title}`}
                          />
                        </div>
                        <span className="ml-2">{Menu.title}</span>
                      </NavLink>
                    )}
                  </Fragment>
                ))}
              </div>
              {/* Logout and Profile */}
              <div>
                <div
                  onClick={onClickLogout}
                  className="font-normal text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex items-center"
                >
                  <div className="h-5 w-5">
                    <LuLogOut size={22} color="grey" />
                  </div>
                  <span className="ml-2">Log Out</span>
                </div>
                <div
                  onClick={(e) => undefined}
                  className="font-normal text-black hover:bg-white hover:bg-opacity-30 flex items-center"
                >
                  <div className="h-10 w-10">
                    <img src={`/assets/images/vincent.svg`} alt="profile" />
                  </div>
                  <span className="ml-2">
                    <div className="text-[12px] text-grey-400">{name}</div>
                    <div className="text-[12px] text-grey-400">{email}</div>
                  </span>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <button
        onClick={toggleDrawer}
        className="md:hidden fixed top-2 right-4 text-gray-600 bg-transparent shadow-lg rounded-full p-2 z-50"
      >
        {openDrawer ? <LuX size={30} /> : <LuAlignJustify size={30} />}
      </button>
    </div>
  );
};

export default Sidebar;
