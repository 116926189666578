import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row, Modal, Pagination, Skeleton } from "antd";
import dayjs from "dayjs";
import AlertMiniPopUp from "../../admin/componets_global/AlertMiniPopUp";
import Coin from "../../../assets/icons/coin.png";
import MasterVoucher from "../../../repositores/adminRepo/VoucherRepo";
import { TalentHelperContext } from "../../../components/talentPage/TalentContext";
import TalentHeader from "../../../components/general/TalentHeader";
import "./reward.css";
import CustomModal from "./components/CustomModal";

function Reward() {
  const { selectMenu } = useContext(TalentHelperContext);
  const [totalcoins, setTotalCoins] = useState(100);
  const [level, setLevel] = useState(5);
  const [loading, setLoading] = useState(true);
  const [modalredeem, setModalRedeem] = useState(false);
  const [modalreward, setModalReward] = useState(false);
  const [dataTableVoucher, setDataTableVoucher] = useState(null);
  const [dataTableMyReward, setDataTableMyReward] = useState(null);
  const [totalItemVoucher, setTotalItemVoucher] = useState(0);
  const [totalItemMyReward, setTotalItemMyReward] = useState(0);
  const [activeTab, setActiveTab] = useState("redeemCoins");
  const [limit, setLimit] = useState(9);
  const [search, setSearch] = useState(undefined);
  const [tabPage, setTabPage] = useState({
    redeemCoins: 1,
    myReward: 1,
  });
  const [page, setPage] = useState(1);
  const [voucherredeem, setVoucherRedeem] = useState([]);
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const [rewardDetail, setRewardDetail] = useState(null);

  const totalItem = () => {
    let sum = 0;
    let gc = 0;
    for (let key in voucherredeem) {
      sum += voucherredeem[key].count;
      gc += voucherredeem[key].count * voucherredeem[key].gc;
    }
    return { total: sum, gc: gc };
  };

  const incrementCount = (value) => {
    const found = voucherredeem.findIndex((item) => item.id === value.id);
    const accitem = totalItem().gc + value.gc > totalcoins;
    if (accitem) {
      return;
    }
    if (found !== -1) {
      let newarray = [...voucherredeem];
      newarray[found].count += 1;
      setVoucherRedeem(newarray);
    } else {
      setVoucherRedeem([
        ...voucherredeem,
        {
          id: value.id,
          gc: value.gc,
          image: value.image,
          description: value.description,
          currency: value.currency,
          nominal: value.nominal,
          count: 1,
        },
      ]);
    }
  };

  const decrementCount = (value) => {
    const found = voucherredeem.findIndex((item) => item.id === value.id);
    if (found !== -1) {
      if (voucherredeem[found].count === 1) {
        let newarray = voucherredeem.filter((item) => item.id !== value.id);
        setVoucherRedeem(newarray);
      } else {
        let newarray = [...voucherredeem];
        newarray[found].count -= 1;
        setVoucherRedeem(newarray);
      }
    } else {
      let newarray = voucherredeem.filter((item) => item.id !== value.id);
      setVoucherRedeem(newarray);
    }
  };

  const redeemVoucher = async () => {
    const submit = {
      voucher: {
        id: voucherredeem[0].id,
      },
      qty: voucherredeem[0].count,
    };
    const response = await MasterVoucher.postRedeemVoucher(submit);

    setModalRedeem(false);
    setVoucherRedeem([]);
    if (response.data.status === 200) {
      setMessageAlert({
        message: "Voucher successfully redeemed!",
        status: true,
      });
      setTimeout(() => {
        setMessageAlert({ message: "", status: null });
      }, 1000);
    } else {
      setMessageAlert({ message: response.data.message, status: false });
      setTimeout(() => {
        setMessageAlert({ message: "", status: null });
      }, 1000);
    }
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    setPage(tabPage[tabKey]);
  };

  const onChangePage = (page) => {
    setPage(page);
    setTabPage({ ...tabPage, [activeTab]: page });
  };

  const fetchTotalVoucher = async () => {
    setLoading(true);
    try {
      const response = await MasterVoucher.getListVoucher(page, limit, search);
      if (activeTab === "redeemCoins") {
        setDataTableVoucher(response.data.data?.content);
        setTotalItemVoucher(response.data.data?.totalElements);
      }
    } catch (error) {
      console.error("Error fetching total voucher:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMyReward = async () => {
    setLoading(true);
    try {
      const response = await MasterVoucher.getListMyReward(page, limit, search);
      if (activeTab === "myReward") {
        setDataTableMyReward(response?.data?.data?.content);
        setTotalItemMyReward(response.data?.data.totalElements);
      }
    } catch (error) {
      console.error("Error fetching total reward:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await MasterVoucher.getUser();
      setTotalCoins(response.data.data?.gcTotal);
      setLevel(parseInt(response.data.data?.badges.level));
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, [messageAlert]);

  const fetchData = async () => {
    setLoading(true);

    if (activeTab === "redeemCoins") {
      await fetchTotalVoucher();
      await fetchUser();
    } else if (activeTab === "myReward") {
      await fetchMyReward();
    }

    setLoading(false);
  };

  useEffect(() => {
    selectMenu("rewards");
    fetchData();
  }, [page, activeTab]);

  const openModal = () => {
    setModalRedeem(true);
  };

  const openMyReward = async (item) => {
    setRewardDetail(item);
    setModalReward(true);
  };

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="w-full overflow-auto min-h-screen">
        <TalentHeader
          title={"Rewards"}
          subDescription={"Redeem your coins for rewards!"}
          imageSrc={"/assets/images/reward-header-image.png"}
        />
        <div className="relative md:flex md:flex-col h-max bg-primary border-b-2 shadow-md">
          <div className="grid grid-cols-12 md:mx-[60px] mx-2 py-3 items-center">
            <div className="col-span-12 md:col-span-6 w-[100%] flex md:gap-14 gap-4 font-semibold text-sm">
              <button
                className={`flex flex-col px-2 justify-center ${
                  activeTab === "redeemCoins" ? "text-white " : "text-white"
                }`}
                onClick={() => handleTabChange("redeemCoins")}
              >
                Redeem Coins
                <hr
                  className={`
              ${activeTab === "redeemCoins" ? "tabs-button" : null}`}
                />
              </button>
              <button
                className={`flex flex-col px-2 justify-center ${
                  activeTab === "myReward" ? "text-white " : "text-white"
                }`}
                onClick={() => handleTabChange("myReward")}
              >
                My Reward
                <hr
                  className={`
              ${activeTab === "myReward" ? "tabs-button" : null}`}
                />
              </button>
            </div>
            <div className="md:col-span-6 hidden md:flex justify-end items-center text-sm font-bold gap-2.5">
              <p className="text-white">My Coins :</p>
              <img
                src={Coin}
                alt="icon-coin"
                className="w-4"
                width={20}
                height={20}
              />
              <span className="text-coin-yellow">{totalcoins}</span>
            </div>
          </div>
        </div>
        {activeTab === "redeemCoins" && (
          <>
            <div className="z-10 flex flex-col w-full relative md:hidden">
              <div className="flex justify-end absolute right-1 top-[-35px]">
                <p className="text-coin-yellow font-bold flex gap-3">
                  <img
                    src={Coin}
                    alt="icon-coin"
                    className="w-4"
                    width={20}
                    height={20}
                  />
                  <span>{totalcoins} Coins</span>
                </p>
              </div>
            </div>
            <div className="md:mx-[50px] mx-2 relative">
              <Row gutter={[16, 16]} className="mt-5">
                {dataTableVoucher &&
                  dataTableVoucher.map((item, i) => {
                    return (
                      <Col
                        key={`${item.id}+${i}`}
                        span={12}
                        sm={12}
                        md={8}
                        lg={8}
                      >
                        <Card className="rounded-[20px] h-full">
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Row gutter={[16, 16]}>
                              <Col
                                className="flex justify-center w-full"
                                sm={24}
                                md={24}
                                lg={12}
                              >
                                <img
                                  className="w-[150px] h-[100px]"
                                  src={item.urlImage}
                                  alt={item.urlImage}
                                />
                              </Col>
                              <Col span={24} sm={24} md={24} lg={12}>
                                <div className="md:h-[92px] h-[60px]">
                                  <div className="font-bold text-xs md:text-sm">
                                    <p>{item.description}</p>
                                    <p className="hidden">
                                      {item.currency} {item.nominal}
                                    </p>
                                  </div>
                                  <div className="flex font-bold text-xs md:text-sm mb-5">
                                    <img
                                      src={Coin}
                                      className="w-4 flex-initial mr-2"
                                      alt="icon-coin"
                                    />
                                    <p className="text-coin-yellow">
                                      {item.gc}
                                    </p>
                                  </div>
                                </div>
                                {voucherredeem.find(
                                  (value) => value.id === item.id
                                ) ? (
                                  <div className="flex p-2 items-center justify-center gap-4">
                                    <Button
                                      className="rounded-full relative"
                                      onClick={() => decrementCount(item)}
                                    >
                                      <div className="absolute inset-0 flex items-center justify-center">
                                        <span>-</span>
                                      </div>
                                    </Button>
                                    <h4 className="">
                                      {voucherredeem.find(
                                        (value) => value.id === item.id
                                      ) &&
                                        voucherredeem.find(
                                          (value) => value.id === item.id
                                        ).count}
                                    </h4>
                                    <Button
                                      className="rounded-full relative"
                                      disabled={
                                        voucherredeem.find(
                                          (value) => value.id === item.id
                                        ).count >= item.quota
                                      }
                                      onClick={() => incrementCount(item)}
                                    >
                                      <div className="absolute inset-0 flex items-center justify-center">
                                        <span>+</span>
                                      </div>
                                    </Button>
                                  </div>
                                ) : (
                                  <button
                                    className={`w-full rounded-[7px] h-7 md:h-[30px] lg:h-fit xl:h-[30px] 2xl:h-[30px] font-bold flex justify-center items-center text-[10px] md:text-2xxs lg:text-xxs 2xl:text-base ${
                                      item.level.level > level ||
                                      item.gc > totalcoins ||
                                      voucherredeem.length > 0 ||
                                      item.quota <= 0
                                        ? "bg-[#D5D5D5] text-dark-grey disabled:transform-none disabled:transition-none disabled:cursor-not-allowed hover:translate-x-1 hover:scale-110"
                                        : "bg-white text-primary hover:text-white  border-[#DD0000] border-2 hover:bg-[#ff0000]"
                                    }`}
                                    onClick={() => incrementCount(item)}
                                    disabled={
                                      item.level.level > level ||
                                      item.gc > totalcoins ||
                                      voucherredeem.length > 0 ||
                                      item.quota <= 0
                                    }
                                  >
                                    {item.level.level > level ? (
                                      <div className="flex gap-1 items-center">
                                        <img
                                          className="w-[13px] h-[13px]"
                                          src="/assets/images/icon-key.svg"
                                          alt="icon-key"
                                        />
                                        Unlock at Level {item.level.level}
                                      </div>
                                    ) : (
                                      "Redeem"
                                    )}
                                  </button>
                                )}
                              </Col>
                            </Row>
                          )}
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
              <div className="flex justify-center py-2">
                <Pagination
                  current={page}
                  onChange={onChangePage}
                  pageSize={limit}
                  total={totalItemVoucher}
                />
              </div>
            </div>
            {voucherredeem.length > 0 ? (
              <div className="fixed md:flex md:right-40 md:left-40 bottom-4 px-[25px] md:mx-auto w-full">
                <Button
                  className="md:mx-auto flex md:w-[500px] w-full"
                  style={{ backgroundColor: "#DD0000" }}
                  onClick={openModal}
                >
                  <div className="flex w-full">
                    <div
                      className="flex flex-nowrap"
                      style={{ color: "white" }}
                    >
                      {totalItem().total} item
                    </div>

                    <div className="flex flex-nowrap flex-1 flex-row-reverse">
                      <div className="flex-initial" style={{ color: "white" }}>
                        {totalItem().gc}
                      </div>
                      <img
                        src={Coin}
                        alt="icon-coin"
                        className="w-4 flex-initial mr-2"
                      />{" "}
                    </div>
                  </div>
                </Button>
              </div>
            ) : null}
          </>
        )}
        {activeTab === "myReward" && (
          <div className="md:mx-[50px] mx-2 relative">
            <Row gutter={[16, 16]} className="mt-5">
              {dataTableMyReward &&
                dataTableMyReward.map((item, i) => {
                  return (
                    <Col
                      key={`${item.id}+${i}`}
                      span={12}
                      sm={12}
                      md={8}
                      lg={8}
                    >
                      <Card className="rounded-[20px] h-full">
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <Row gutter={[16, 16]}>
                            <Col
                              className="flex justify-center w-full"
                              sm={24}
                              md={12}
                              lg={12}
                            >
                              <img
                                className="w-[150px] h-[100px]"
                                src={item.urlImage}
                                alt={item.urlImage}
                              />
                            </Col>
                            <Col span={24} sm={24} md={12} lg={12}>
                              <div className="md:h-[92px] h-[60px]">
                                <div className="font-bold text-xs md:text-sm">
                                  {item.description}
                                </div>
                                <div className="hidden font-bold text-xs md:text-sm">
                                  {item.currency} {item.nominal}
                                </div>
                                <div className="flex font-bold text-xs md:text-sm">
                                  <img
                                    src={Coin}
                                    alt="icon-coin"
                                    className="w-4 flex-initial mr-2"
                                  />
                                  {item.gc}
                                </div>
                                <div className="font-bold text-xs md:text-sm pb-2">
                                  Quantity : {item.quota}
                                </div>
                              </div>
                              <button
                                className="w-full md:w-fit lg:w-full rounded-[7px] border-[#DD0000] border-2 hover:bg-[#ff0000] hover:text-white text-primary font-bold text-xs md:text-xs sm:h-[30px] md:h-max lg:h-fit xl:h-[30px] 2xl:h-[30px] py-1.5"
                                onClick={() => openMyReward(item)}
                              >
                                View Details
                              </button>
                            </Col>
                          </Row>
                        )}
                      </Card>
                    </Col>
                  );
                })}
            </Row>
            <div className="flex justify-center py-2">
              <Pagination
                current={page}
                onChange={onChangePage}
                pageSize={limit}
                total={totalItemMyReward}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        open={modalredeem}
        onCancel={() => setModalRedeem(false)}
        title={
          <span className="font-bold md:mx-auto text-2xl">Redeem Reward</span>
        }
        footer={null}
      >
        <div className="font-bold mb-3">Item Detail</div>
        {voucherredeem.map((item) => {
          return (
            <Card key={item.id}>
              <Row gutter={8}>
                <Col span={6}>
                  <img src={item.image} alt={item.urlImage} />
                </Col>
                <Col span={12}>
                  <div className="font-bold">{item.description}</div>
                  <div className="font-bold">
                    {item.currency} {item.nominal}
                  </div>

                  <div className="text-xs  text-[#A8A8A8]">
                    Quantity : {item.count}
                  </div>
                </Col>
                <Col span={6} className="relative">
                  <div className="absolute bottom-0 right-0">
                    <div className="flex flex-end gap-3 items-center text-coin-yellow">
                      <img
                        src={Coin}
                        alt="icon-coin"
                        className="w-4 font-bold "
                      />
                      <p>{item.gc}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          );
        })}
        <div className="flex mt-12 font-bold">
          Balance
          <img
            src={Coin}
            alt="icon-coin"
            className="w-4 flex-initial ml-2 mr-1"
          />
          <p className="text-coin-yellow">{totalcoins}</p>
        </div>
        <div className="flex w-full ">
          <Button
            className="flex w-full mt-3 bg-[#DD0000] text-white font-bold"
            onClick={() => redeemVoucher()}
          >
            <div className="flex flex-nowrap ml-5 my-auto">
              {totalItem().total}
            </div>

            <div className="flex flex-nowrap flex-1 flex-row-reverse my-auto mr-5">
              <div className="flex-initial text-coin-yellow ">
                {totalItem().gc}
              </div>
              <img
                src={Coin}
                alt="icon-coin"
                className="w-4 flex-initial mr-2"
              />
            </div>
          </Button>
        </div>
      </Modal>
      <CustomModal
        show={modalreward}
        closeModal={() => setModalReward(false)}
        title="Reward Detail"
      >
        <div className="grid grid-cols-2 gap-4 pt-3">
          {/* First Column */}
          <div className="col-span-1 ">
            {/* Content for the first column */}
            <div className="mb-2 ">
              <p className="text-md font-bold ">Item detail</p>
            </div>
            <div className="mb-2 ">
              <p className="text-xs text-gray-400">Reward Name</p>
              <h3 className="font-semibold">{rewardDetail?.description}</h3>
            </div>
            <div className="mb-2 ">
              <p className="text-xs text-gray-400">Quantity</p>
              <h3 className="font-semibold">{rewardDetail?.quota}</h3>
            </div>
            <div className="mb-2 hidden">
              <p className="text-xs text-gray-400">Nominal</p>
              <h3 className="font-semibold">{rewardDetail?.nominal}</h3>
            </div>
            <div className="mb-2 ">
              <p className="text-xs text-gray-400">Coin Redeemed</p>
              <div className="grid grid-cols-6 gap-2 my-1">
                <img
                  src={Coin}
                  alt="icon-coin"
                  className=" col-span-1 w-4 flex-initial mr-2"
                />
                <h3 className="font-semibold">{rewardDetail?.gc}</h3>
              </div>
            </div>
            <div className="mb-2 ">
              <p className="text-xs text-gray-400">Level</p>
              <h3 className="font-semibold">{rewardDetail?.level.level}</h3>
            </div>
            <div className="">
              <p className="text-xs text-gray-400">Redeemed on</p>
              <h3 className="font-semibold">
                {dayjs(rewardDetail?.created_date).format("DD-MM-YYYY")}
              </h3>
            </div>
          </div>

          {/* Second Column */}
          <div className=" flex col-span-1 items-center justify-center">
            {/* Content for the second column */}
            <div className="border-2 border-gray-200 rounded-md">
              <img
                className="w-[150px] h-[150px]"
                src={rewardDetail?.urlImage}
                alt={rewardDetail?.urlImage}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default Reward;
