import React, { useState, useEffect } from "react";
import ChampionCard from "./components/ChampionCard";
import ChampionList from "./components/ChampionList";
import MasterDashboardRepo from "../../../repositores/adminRepo/DashboardRepo";

const Leaderboard = () => {
  const defaultData = {
    id: null,
    fullName: null,
    totalGc: 0,
    totalXp: 0,
  };

  const [dataChampions, setDataChampions] = useState([]);
  const [dataList, setDataList] = useState([]);

  const getDataLeaderboard = async () => {
    try {
      const { data } = await MasterDashboardRepo.getTopLeaderboard(100);
      mappingChampions(data.data.slice(0, 3));
      const topList = data.data.slice(3, 100);
      setDataList(topList);
    } catch (error) {
      setDataChampions([]);
      setDataList([]);
    }
  };

  const mappingChampions = (datas) => {
    // set default data if data talent < 3
    const champData = [];
    for (let i = 0; i < 3; i++) {
      if (datas[i]) {
        champData.push(datas[i]);
      } else {
        champData.push({ ...defaultData });
      }
    }

    champData[0].order = 2;
    champData[1].order = 1;
    champData[2].order = 3;
    champData.map((champ, i) => (champ.rank = i + 1));
    champData.sort((curr, next) => curr.order - next.order);
    setDataChampions(champData);
  };

  useEffect(() => {
    getDataLeaderboard();
  }, []);

  return (
    <div className="w-[100%]">
      <ChampionCard dataChampions={dataChampions} />
      <ChampionList dataTalents={dataList} />
    </div>
  );
};

export default Leaderboard;
