import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, Input } from "antd";
import RoleSkillTalent from "../../../repositores/talentRepo/RoleSkillTalentRepo";
const { Option } = Select;

const SkillForm = ({
  onClickSubmit = () => {},
  onDelete = () => {},
  onCancel = () => {},
  show,
  data = undefined,
  isEdit = false,
}) => {
  console.log(data);
  const [form] = Form.useForm();
  const [page, setPage] = useState(0);
  const [optionsRoles, setOptionsRoles] = useState([]);
  const [loadingMoreRole, setLoadingMoreRole] = useState(false);
  const [totalElementRole, setTotalElementRole] = useState(-1);

  const [pageSkill, setPageSkill] = useState(0);
  const [optionsSkill, setOptionsSkill] = useState([]);
  const [loadingMoreSkill, setLoadingMoreSkill] = useState(false);
  const [totalElementSkill, setTotalElementSkill] = useState(-1);

  const [pageCourse, setPageCourse] = useState(0);
  const [optionsCourse, setOptionsCourse] = useState([]);
  const [loadingMoreCourse, setLoadingMoreCourse] = useState(false);
  const [totalElementCourse, setTotalElementCourse] = useState(-1);

  const [selectedSkill, setSelectedSkill] = useState(undefined);
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [selectedCourse, setSelectedCourse] = useState(undefined);

  // ROLE
  const handleScrollRole = (event) => {
    event.stopPropagation();
    if (!loadingMoreRole) {
      fetchRoleMore(page, 10);
    }
  };

  const fetchRoleMore = async (page, limit) => {
    if (optionsRoles?.length !== totalElementRole || totalElementRole === -1) {
      setLoadingMoreRole(true);
      const response = await RoleSkillTalent.getListRolesEmployee(page, limit);
      const tempOptions = response.data?.data?.content || [];
      setOptionsRoles((prevOptions) => [...prevOptions, ...tempOptions]);
      setTotalElementRole(response.data?.data?.totalElements);
      setPage(page + 1);
      setLoadingMoreRole(false);
    }
  };

  const handleSearchRole = async (value) => {
    setLoadingMoreRole(true);
    if (value === "") {
      await fetchRoleMore(0, 10);
    } else {
      const response = await RoleSkillTalent.getListRolesEmployee(0, 10, value);
      const tempOptions = response.data?.data?.content || [];
      setOptionsRoles(tempOptions);
      setPage(1);
    }
    setLoadingMoreRole(false);
  };

  // SKILL
  const handleScrollSkill = (event) => {
    event.stopPropagation();
    if (!loadingMoreSkill) {
      fetchSkillMore(pageSkill, 10);
    }
  };

  const fetchSkillMore = async (page, limit) => {
    if (
      optionsSkill?.length !== totalElementSkill ||
      totalElementSkill === -1
    ) {
      setLoadingMoreSkill(true);
      const response = await RoleSkillTalent.getListSkills(page, limit);
      const tempOptions = response.data?.data?.content || [];
      setOptionsSkill((prevOptions) => [...prevOptions, ...tempOptions]);
      setTotalElementSkill(response.data?.data?.totalElements);
      setPageSkill(page + 1);
      setLoadingMoreSkill(false);
    }
  };

  const handleSearchSkill = async (value) => {
    setLoadingMoreSkill(true);
    if (value === "") {
      await fetchSkillMore(0, 10);
    } else {
      const response = await RoleSkillTalent.getListSkills(0, 10, value);
      const tempOptions = response.data?.data?.content || [];
      setOptionsSkill(tempOptions);
      setPageSkill(1);
    }
    setLoadingMoreSkill(false);
  };

  // COURSE
  const handleScrollCourse = (event) => {
    event.stopPropagation();
    if (!loadingMoreCourse) {
      fetchCourseMore(pageCourse, 10);
    }
  };

  const fetchCourseMore = async (page, limit) => {
    if (
      optionsCourse?.length !== totalElementCourse ||
      totalElementCourse === -1
    ) {
      setLoadingMoreCourse(true);
      const response = await RoleSkillTalent.getListHistoryCourse(page, limit);
      const tempOptions = response.data?.data?.content || [];
      setOptionsCourse((prevOptions) => [...prevOptions, ...tempOptions]);
      setTotalElementCourse(response.data?.data?.totalElements);
      setPageCourse(page + 1);
      setLoadingMoreCourse(false);
    }
  };

  const handleSearchCourse = async (value) => {
    setLoadingMoreCourse(true);
    if (value === "") {
      await fetchCourseMore(0, 10);
    } else {
      const response = await RoleSkillTalent.getListHistoryCourse(0, 10, value);
      const tempOptions = response.data?.data?.content || [];
      setOptionsCourse(tempOptions);
      setPageCourse(1);
    }
    setLoadingMoreCourse(false);
  };

  useEffect(() => {
    let isMounted = true;

    const initialFetch = async () => {
      if (isMounted) {
        await fetchRoleMore(0, 10);
        await fetchSkillMore(0, 10);
        await fetchCourseMore(0, 10);
      }
    };
    initialFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (data) {
      setSelectedRole(data.userRole.id);
      setSelectedSkill(data.skill.id);
      setSelectedCourse(data.usersCourses ? data.usersCourses.id : undefined);
    }
  }, [data]);

  if (!show) {
    form.resetFields();
    return <></>;
  }

  return (
    <>
      <Modal
        forceRender
        width={900}
        maskClosable={false}
        open={show}
        onCancel={() => {
          onCancel(false);
        }}
        footer={null}
        closeIcon={true}
        zIndex={999999}
        className="add-form-container-modal"
      >
        <div className="flex flex-col w-full mb-4">
          <div>
            <p className="text-[20px] font-weight-600 mb-5">
              {isEdit ? "Update Skill" : "Add Skill"}
            </p>
          </div>

          <Form
            layout="vertical"
            form={form}
            initialValues={{
              userRole: data ? data.userRole.roleCompany.name : undefined,
              skill: data ? data.skill.name : undefined,
              experienceBulan: data ? data.experienceBulan : undefined,
            }}
          >
            <Form.Item name="userRole" label="Role">
              <Select
                placeholder="Choose a Role"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollRole}>{menu}</div>
                )}
                disabled={isEdit}
                onChange={setSelectedRole}
                onSearch={handleSearchRole}
                loading={loadingMoreRole}
                showSearch
                filterOption={false}
                defaultActiveFirstOption={false}
                optionFilterProp="children"
              >
                {optionsRoles.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.roleCompany.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="skill"
              label="Skill"
              rules={[
                {
                  required: true,
                  message: "Skill is required!",
                },
              ]}
            >
              <Select
                value={selectedSkill}
                onChange={setSelectedSkill}
                showSearch
                loading={loadingMoreSkill}
                placeholder="Choose a Skill"
                onSearch={handleSearchSkill}
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollSkill}>{menu}</div>
                )}
                optionFilterProp="children"
              >
                {optionsSkill.map((skill, index) => (
                  <Option key={index} value={skill.id}>
                    {skill.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {isEdit && (
              <Form.Item
                name="course"
                label="Course Name"
                rules={[
                  {
                    required: true,
                    message: "Course is required!",
                  },
                ]}
              >
                <Select
                  value={selectedCourse}
                  onChange={setSelectedCourse}
                  className="w-full"
                  showSearch
                  loading={loadingMoreCourse}
                  placeholder="Choose a Course"
                  onSearch={handleSearchCourse}
                  dropdownRender={(menu) => (
                    <div onWheel={handleScrollCourse}>{menu}</div>
                  )}
                  optionFilterProp="children"
                >
                  {optionsCourse.map((course, index) => (
                    <Option key={index} value={course.id}>
                      {course.course.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              name="experienceBulan"
              label="Experience (Month)"
              rules={[
                {
                  required: true,
                  message: "Experience is Required!",
                },
              ]}
            >
              <Input placeholder="Input Experience" type="number" />
            </Form.Item>
          </Form>

          <div className="flex justify-between">
            <Button
              onClick={() => {
                const userRoleId = selectedRole || data.userRole?.id;
                const skillId = selectedSkill || data.skill?.id;
                const usersCourseId = selectedCourse || data?.usersCourses?.id;

                let body = {
                  userRole: { id: userRoleId },
                  skill: { id: skillId },
                  experienceBulan: parseInt(
                    form.getFieldValue("experienceBulan")
                  ),
                };

                if (isEdit) {
                  body = {
                    ...body,
                    usersCourses: usersCourseId
                      ? { id: usersCourseId }
                      : undefined,
                  };
                }

                onClickSubmit(body);
                form.resetFields();
                onCancel(false);
              }}
              className="bg-[#DD0000] text-white w-[200px] py-1 font-bold rounded ms-3"
            >
              {isEdit ? "Update" : "Submit"}
            </Button>

            {/* {isEdit && (
              <Button
                onClick={() => {
                  onDelete({
                    userRole: { id: selectedRole || data.userRole.id },
                    skill: { id: selectedSkill || data.skill.id },
                    usersCourses: selectedCourse
                      ? { id: selectedCourse || data.usersCourses.id }
                      : undefined,
                    experienceBulan: form.getFieldValue("experienceBulan"),
                  });
                  form.resetFields();
                  onCancel(false);
                }}
                className="bg-[#d5d1d1] text-black w-[200px] py-1 rounded ms-3"
              >
                Delete
              </Button>
            )} */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SkillForm;
