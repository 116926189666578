import { Avatar } from "antd";

import { DetailContainer } from "./DetailContainer";
import { Fragment } from "react";

export const Committee = ({ committee, total }) => {
  const maxAvatars = 6;

  return (
    <div className="flex-1">
      <DetailContainer title={`${total} Committee`}>
        <div className="flex items-center gap-1">
          {committee?.length ? (
            <Fragment>
              <Avatar.Group maxCount={maxAvatars}>
                {committee?.slice(0, maxAvatars).map((person) => (
                  <Avatar key={person.id} src={person.avatar_url} />
                ))}
              </Avatar.Group>
              {total > maxAvatars && (
                <p className="text-black text-xs font-medium">
                  {total - maxAvatars}+ More
                </p>
              )}
            </Fragment>
          ) : (
            <p className="text-black text-xs font-medium">-</p>
          )}
        </div>
      </DetailContainer>
    </div>
  );
};
