import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button } from "antd";
import RoleSkillTalent from "../../../repositores/talentRepo/RoleSkillTalentRepo";
import AlertUtility from "../../../components/Alert/Alert";

const { Option } = Select;

const RoleForm = ({
  onClickSubmit = () => {},
  onDelete = () => {},
  onCancel = () => {},
  show,
  data = undefined,
  isEdit = false,
}) => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(0);
  const [optionsRoles, setOptionsRoles] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalElementRole, setTotalElementRole] = useState(-1);

  const handleScrollRole = (event) => {
    event.stopPropagation();

    if (!loadingMore) {
      fetchRoleMore(page, 20);
    }
  };

  const fetchRoleMore = async (page, limit) => {
    if (optionsRoles.length < totalElementRole || totalElementRole === -1) {
      setLoadingMore(true);
      try {
        const response = await RoleSkillTalent.getListRoles(page, limit);
        const tempOptions = response?.data?.data?.content || [];
        setOptionsRoles((prevOptions) => [...prevOptions, ...tempOptions]);
        setTotalElementRole(response.data?.data?.totalElements);
        setPage(page + 1);
        setLoadingMore(false);
      } catch (error) {
        AlertUtility.ErrorAlert({ title: "Failed Get Data" });
        setLoadingMore(false);
      }
    }
  };

  const handleSearchRole = async (value) => {
    setLoadingMore(true);

    if (value === "") {
      await fetchRoleMore(0, 20);
    } else {
      const response = await RoleSkillTalent.getListRoles(0, 20, value);
      const tempOptions = response.data?.data?.content || [];
      setOptionsRoles(tempOptions);
      setPage(1);
    }
    setLoadingMore(false);
  };

  useEffect(() => {
    let isMounted = true;

    const initialFetch = async () => {
      if (isMounted) {
        await fetchRoleMore(0, 20);
      }
    };

    initialFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (show) {
      if (isEdit) {
        form.setFieldsValue({ roleCompany: data?.name });
      } else {
        form.resetFields();
      }
    }
  }, [show, isEdit, data, form]);

  const handleModalClose = () => {
    form.resetFields();
    onCancel(false);
  };

  const handleFormSubmit = () => {
    onClickSubmit({ roleCompany: form.getFieldValue("roleCompany") });
    form.resetFields();
    handleModalClose();
  };

  if (!show) {
    return null;
  }

  return (
    <Modal
      forceRender
      width={900}
      maskClosable={false}
      open={show}
      onCancel={handleModalClose}
      footer={null}
      closeIcon={true}
      zIndex={999999}
      className="add-form-container-modal"
    >
      <div className="flex flex-col w-full mb-4">
        <div className="">
          <p className="text-[20px] font-weight-600 mb-5">
            {isEdit ? "Update Role" : "Add Role"}
          </p>
        </div>

        <Form form={form}>
          <Form.Item
            name="roleCompany"
            label="Role"
            rules={[{ required: true, message: "Role is Required!" }]}
          >
            <Select
              placeholder="Choose a Role"
              dropdownRender={(menu) => (
                <div onWheel={handleScrollRole}>{menu}</div>
              )}
              onSearch={handleSearchRole}
              loading={loadingMore}
              showSearch
              filterOption={false}
              optionFilterProp="children"
              defaultActiveFirstOption={false}
            >
              {optionsRoles.map((role, index) => (
                <Option key={index} value={role.id}>
                  {role?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <div className="flex justify-between">
          <Button
            onClick={handleFormSubmit}
            className="bg-[#DD0000] text-white w-[200px] py-1 font-bold rounded ms-3"
          >
            {isEdit ? "Update" : "Submit"}
          </Button>

          {isEdit && (
            <Button
              onClick={() => {
                onDelete({ roleCompany: form.getFieldValue("roleCompany") });
                form.resetFields();
                handleModalClose();
              }}
              className="bg-[#d5d1d1] text-black w-[200px] py-1 rounded ms-3"
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RoleForm;
