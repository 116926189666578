import { EventTag } from "./EventTag";
import { EventStatus } from "./EventStatus";
import { EventAttribute } from "./EventAttribute";
import { useMemo } from "react";
import { DateUtil } from "../../../../shared/utils/date.utils";

const moment = new DateUtil().useMoment();

const ViewToggler = ({ isToggled }) => {
  return (
    <button className="flex-shrink-0 inline-flex items-center gap-2">
      <span className="text-secondary font-semibold">
        View {isToggled ? "Less" : "More"}
      </span>
      <img
        alt="chevron-down"
        className={`w-3 object-cover ${
          isToggled ? "rotate-180" : "rotate-0"
        } transition-transform ease-out duration-300`}
        src="/assets/icons/chevron-down.svg"
      />
    </button>
  );
};


export const EventSummary = ({
  eventName,
  eventTag,
  eventStatus,
  eventSummary,
  isToggled,
}) => {
  const getEventDate = useMemo(() => {
    if (eventSummary.startDate && eventSummary.endDate) {
      return `${moment(eventSummary.startDate).format(
        "DD MMM YYYY"
      )} - ${moment(eventSummary.endDate).format("DD MMM YYYY")}`;
    }

    if (eventSummary.startDate || eventSummary.endDate) {
      if (eventSummary.startDate) {
        return moment(eventSummary.startDate).format("DD MMM YYYY");
      }
      if (eventSummary.endDate) {
        return moment(eventSummary.endDate).format("DD MMM YYYY");
      }
    }

    return "-";
  }, [eventSummary.endDate, eventSummary.startDate]);

  const getEventTime = useMemo(() => {
    if (eventSummary.startTime) return eventSummary.startTime;
    return "-";
  }, [eventSummary.startTime]);

  return (
    <header className="flex flex-col gap-4 pl-3 pr-2 pb-2 cursor-auto">
      <div className="flex flex-col items-start gap-1">
        <div className="flex items-center justify-between self-stretch gap-4">
          <h2 className="line-clamp-1 text-black text-xl font-semibold">
            {eventName}
          </h2>
          <ViewToggler isToggled={isToggled} />
        </div>
        <EventTag tag={eventTag} />
      </div>
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <EventAttribute
              image={{ alt: "calendar", url: "/assets/icons/red-calendar.svg" }}
              label={{ text: getEventDate }}
            />
            <EventAttribute
              image={{ alt: "clock", url: "/assets/icons/red-clock.svg" }}
              label={{ text: getEventTime }}
            />
          </div>
          <div className="flex items-center gap-4">
            <EventAttribute
              image={{ alt: "coin", url: "/assets/icons/coin.svg" }}
              label={{
                color: "text-[#FE9923]",
                text: `${eventSummary.coin} coin`,
              }}
            />
            <EventAttribute
              image={{ alt: "xp", url: "/assets/icons/xp.svg" }}
              label={{ color: "text-[#4F40F4]", text: eventSummary.exp }}
            />
          </div>
        </div>
        <EventStatus status={eventStatus} />
      </div>
    </header>
  );
};
