import React from "react";
import { Input } from "antd";

const SearchBar = ({ onChangeSearch, placeholder }) => {
  return (
    <Input
      className="w-full lg:w-[250px] max-w-[400px]"
      placeholder={placeholder}
      onChange={onChangeSearch}
      suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
    />
  );
};

export default SearchBar;
