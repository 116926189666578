import React from 'react'

const Title = () => {
  
  const name = localStorage.getItem('name') || "Admin"

  return (
    <div className='mb-10'>
      <div className='text-2xl font-bold'>Hello, { name }</div>
      <div className='text-[#A8A8A8] font-semibold text-base'>Track talent progress here</div>
    </div>
  )
}

export default Title