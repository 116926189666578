import { Form, Modal, Input, Button, Select } from "antd";
import React, { useState, useEffect } from "react";
import { isNullOrEmpty } from "../../../utils/utils";

const { TextArea } = Input;

const { Option } = Select;
const RetroQuestionForm = ({
  openModal,
  handleModal,
  questionData,
  retroId,
  recordId = "",
  formQuestion,
  title = "title",
  listCourse = [],
  listMatkul = [],
  handleConfirm = () => {},
  action,
  loadingMore,
  handleSearch = () => {},
  loadingMoreMatkul,
  loadingSearchMatkul,
  idCourse,
  selectedMatkul,
  handleSearchMatkul = () => {},
  handleScrollEventMatkul = () => {},
  onSelectCourse = () => {},
}) => {
  let selectedData =
    recordId !== "" && recordId
      ? questionData.filter((item) => item?.id === recordId)[0]
      : questionData.filter(
          (item) => item?.retroInterviewClient?.id === retroId
        )[0];
  //const [selectedData] = useState({});
  useEffect(() => {}, []);
  const fieldKey = `question-${selectedData?.key}`;

  useEffect(() => {});
  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleModal}
        footer={null}
        //width={1000}
        centered
        destroyOnClose
      >
        <div>
          <p className="text-[20px] font-[500] text-[#4C4C4C]">
            {action === "edit" ? "Edit" : "New"} Interview Question
          </p>
          <p className="text-[12px] font-[500] text-[#A8A8A8] mb-[40px]">
            {action === "edit" ? "Edit" : "New"} your data below!
          </p>
          <Form
            name={title}
            layout="vertical"
            form={formQuestion}
            labelAlign="left"
            //labelCol={{ span: 6 }}
            //style={{ maxWidth: 1000 }}

            onFinish={(props) => {
              const body = {
                retroId: retroId,
                courseId: props.course,
                matkulId: props?.coursesMatkull,
                question: props.question,
                noQuestion:
                  action === "edit"
                    ? selectedData?.noQuestion
                    : props.noQuestion,
              };
              handleConfirm({
                ...body,
                id: action === "edit" ? selectedData?.id : "",
              });
            }}
            onFinishFailed={(props) => {
              console.log("failed", props);
            }}
            autoComplete="off"
          >
            <Form.Item
              name={"noQuestion"}
              label={"Question Number"}
              rules={[{ required: true, message: "This field is required" }]}
              initialValue={action === "edit" ? selectedData?.noQuestion : ""}
            >
              <Input
                className="w-100"
                maxLength={100}
                name={"noQuestion"}
                type="number"
                value={action === "edit" ? selectedData?.noQuestion : ""}
                placeholder="Input question number"
              />
            </Form.Item>
            <Form.Item
              name={"question"}
              label={"Question"}
              rules={[{ required: true, message: "This field is required" }]}
              initialValue={action === "edit" ? selectedData?.question : ""}
            >
              <TextArea
                className="w-100"
                maxLength={100}
                rows={5}
                name={"question"}
                type="text"
                placeholder="Input question"
                value={action === "edit" ? selectedData?.question : ""}
              />
            </Form.Item>
            <Form.Item
              name={"course"}
              label={"Course"}
              initialValue={action === "edit" ? selectedData?.course?.id : null}
            >
              <Select
                className="w-100"
                showSearch
                placeholder="Select a course"
                value={action === "edit" ? selectedData?.course?.id : undefined}
                onSearch={(val) => handleSearch(val, selectedData?.key)}
                onChange={(val) => {
                  selectedMatkul = undefined;
                  formQuestion?.setFieldValue("coursesMatkull", null);
                  onSelectCourse(val);
                }}
                optionFilterProp="children"
                loading={loadingMore[selectedData?.key]}
                /* dropdownRender={(menu) => (
                <div onWheel={handleScrollEventCourse}>{menu}</div>
              )} */
              >
                {listCourse.map((course, index) => (
                  <Option key={index} value={course.id}>
                    {course.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={"coursesMatkull"}
              label={"Mata Kuliah"}
              initialValue={action === "edit" ? selectedMatkul?.id : null}
            >
              <Select
                className="w-100"
                showSearch
                disabled={!idCourse}
                placeholder="Select a matkul"
                onSearch={(val) => handleSearchMatkul(val, selectedData?.key)}
                optionFilterProp="children"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollEventMatkul}>{menu}</div>
                )}
              >
                {action === "edit" && selectedMatkul ? (
                  <Option value={selectedMatkul?.id}>
                    {selectedMatkul?.matkul}
                  </Option>
                ) : (
                  <></>
                )}
                {listMatkul.map((el, index) => (
                  <Option key={index} value={el.id}>
                    {el.matkul}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <div className="flex justify-center">
                <Button
                  className="bg-red-500 radius-[7px] w-40 h-[30px]"
                  style={{ backgroundColor: "red" }}
                  type="primary"
                  htmlType="submit"
                >
                  <p className="text-[12px] font-[500] text-white">Update</p>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default RetroQuestionForm;
