import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";

function AdminLayout() {
  return (
    <main className="flex flex-col h-screen">
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-1 overflow-x-auto overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
}

export default AdminLayout;
