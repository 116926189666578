import React, { useState } from "react";
import ModalImport from "./components/ModalImport";
import ModalInvite from "./components/ModalInvite";
import UserManagement from "./components/UserManagement";
import UserPending from "./components/UserPending";

const ButtonsInviteUser = () => {
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  const closeModalImport = () => {
    setShowImport(false);
  };

  return (
    <div className="flex space-x-2 mt-4 sm:mt-0">
      <div
        role="button"
        className="text-[#DD0000] font-bold w-full"
        onClick={() => setShowImport(true)}
      >
        Import Data
      </div>
      <button
        className="bg-[#DD0000] text-white w-[200px] py-1 font-bold rounded"
        onClick={() => setShow(true)}
      >
        Invite User
      </button>

      <ModalInvite show={show} closeModal={closeModal} />
      <ModalImport show={showImport} closeModal={closeModalImport} />
    </div>
  );
};

const TabIndex = () => {
  const [tab, setTab] = useState("user");

  return (
    <div className="w-full h-screen px-4 py-5">
      <div className="text-2xl font-bold mb-6">User Management</div>
      {/* tab */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <div className="flex font-bold w-full sm:w-2/4 mb-4 sm:mb-0">
          <div
            className={`mr-8 ${
              tab === "user"
                ? "border-b-2 pb-1 text-[#DD0000] border-[#DD0000]"
                : "cursor-pointer"
            }`}
            onClick={() => setTab("user")}
          >
            User Management
          </div>
          <div
            className={`${
              tab === "pending"
                ? "border-b-2 pb-1 text-[#DD0000] border-[#DD0000]"
                : "cursor-pointer"
            }`}
            onClick={() => setTab("pending")}
          >
            Pending
          </div>
        </div>
        {tab === "user" ? <ButtonsInviteUser /> : ""}
      </div>

      {/* table */}
      {tab === "user" ? <UserManagement /> : <UserPending />}
    </div>
  );
};

export default TabIndex;
