import "../MasterEvent.css";

const PercentageEventMaster = ({ percentage }) => (
  <>
    <div className="wrapper-percentage-event h-[35px]">
      <p className="font-semibold text-center">{percentage}%</p>
    </div>
  </>
);
export default PercentageEventMaster;
