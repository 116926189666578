import React, { useContext, useEffect, useState } from "react";
import TalentJourneyRepo from "../../../repositores/talentRepo/TalentJourneyTalentRepo";
import TalentHeader from "../../../components/general/TalentHeader";
import { talentJourneyColumns } from "./column.state";
import { Pagination, Table, message, Button, Modal, Skeleton } from "antd";
import { TalentHelperContext } from "../../../components/talentPage/TalentContext";
import AlertUtility from "../../../components/Alert/Alert";
import { useNavigate } from "react-router-dom";
import RouteTalent from "../../../services/RouteTalent";
import ModalAssignForMe from "./components/ModalAssignForMe";

const SkeletonTable = ({ rows = 5, columns = 9 }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <th
                key={colIndex}
                className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <Skeleton.Input active size="small" className="w-24" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} className="px-4 py-4 whitespace-nowrap">
                  <Skeleton.Input active size="default" className="w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TalentJourney = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [clientData, setClientData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const { selectMenu } = useContext(TalentHelperContext);

  const [showModalAssignMe, setShowModalAssignMe] = useState(false);
  const [counterKeyModal, setCounterKeyModal] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState(undefined);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const changePagination = (pageNumber) => {
    getHistory(pageNumber - 1, search);
  };

  const getHistory = async (pageNumber = 0, searchValue) => {
    setLoading(true);
    const pageSize = 10;
    await TalentJourneyRepo.getTalentJourney(pageNumber, pageSize, searchValue)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setClientData(result.data.data.content);
        setPage(result.data.data.number);
        setTotalData(result.data.data.totalElements);
      })
      .catch((error) => {
        setClientData([]);
        setPage(0);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (searchValue) => {
    getHistory(page, searchValue);
  };

  const handleOpenTermins = (id, course, courseId) => {
    navigate(RouteTalent.talentJourneyTermin, {
      state: {
        id: id,
        course: course,
        courseId: courseId,
      },
    });
  };

  const handleConfirm = async () => {
    setIsOpenConfirm(false);
    setLoading(true);
    try {
      const response = await TalentJourneyRepo.assignMeCourse(
        selectedCourse?.id
      );
      if (response?.data?.status === 200) {
        AlertUtility.SuccessAlert({
          title: `${selectedCourse?.name} has been assigned!`,
        });
        setCounterKeyModal((prevCounter) => prevCounter + 1);
        getHistory();
      }
    } catch (error) {
      AlertUtility.ErrorAlert({
        title: error?.response?.message || "Please try again later!",
      });
    }
  };

  useEffect(() => {
    getHistory(page);
    selectMenu("Talent Journey");
  }, []);

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"Talent Journey"} />
        <div className="flex justify-end w-full my-4">
          <Button
            onClick={() => {
              setShowModalAssignMe(true);
            }}
            type="primary"
            danger
            className="mr-1 bg-[#DD0000]"
            style={{ backgroundColor: "#DD0000" }}
          >
            Assign Course For Me
          </Button>
          <input
            type="text"
            placeholder="Search Course Name"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              handleSearch(e.target.value);
            }}
            className="border rounded-md w-full md:w-[250px] py-1 px-2"
          />
        </div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={talentJourneyColumns((id, course, courseId) => {
                  handleOpenTermins(id, course, courseId);
                })}
                dataSource={clientData}
                pagination={false}
                sticky={true}
                scroll={{
                  y: 470,
                  x: 1200,
                }}
              />
            </div>
            <Pagination
              current={page + 1}
              total={totalData}
              showSizeChanger={false}
              onChange={(p) => changePagination(p)}
              className="flex justify-center mt-2"
            />
          </>
        )}
      </div>

      <ModalAssignForMe
        key={counterKeyModal}
        show={showModalAssignMe}
        closeModal={() => {
          setShowModalAssignMe(false);
          setCounterKeyModal((prevCounter) => prevCounter + 1);
        }}
        fireConfirm={(data) => {
          setSelectedCourse(data);
          setShowModalAssignMe(false);
          setTimeout(() => {
            setIsOpenConfirm(true);
          }, 200);
        }}
      />

      <Modal
        onCancel={() => {
          setIsOpenConfirm(false);
          setTimeout(() => {
            setShowModalAssignMe(true);
          }, 200);
        }}
        title="Confirm the course"
        open={isOpenConfirm}
        footer={
          <>
            <div className="flex justify-between mt-[20px]">
              <Button
                key="back"
                danger
                onClick={() => {
                  setIsOpenConfirm(false);
                  setTimeout(() => {
                    setShowModalAssignMe(true);
                  }, 200);
                }}
              >
                No
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#DD0000", color: "white" }}
                onClick={() => handleConfirm()}
              >
                Yes
              </Button>
            </div>
          </>
        }
      >
        <div className="container p-[10px] my-[30px]">
          <p>
            Are you sure want to enroll this course `
            <b>{selectedCourse?.name}</b>` ?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default TalentJourney;
