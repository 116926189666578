import IconArrowLeft from "../../../../assets/icons/IconArrowLeft.png"
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MasterEventRepo from "../../../../repositores/adminRepo/MasterEventRepo";
import { message, Tabs, ConfigProvider, Modal, Input, Table, Button  } from "antd";
import { CalendarTwoTone, ClockCircleTwoTone, ExportOutlined, InboxOutlined } from '@ant-design/icons';
import Coin from '../../../../assets/icons/coin.png'
import dayjs from 'dayjs';
import { isNullOrEmpty } from "../../../../utils/utils";
import DetailTabsComponent from "./components/TabDetail";
import ParticipantTabsComponent from "./components/TabParticipant";
import { useNavigate } from "react-router-dom";

const DetailEventPage = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    let { id } = useParams();
    const [detailEvent, setDetailEvent] = useState(null)
    const [loading, setLoading] = useState(true)
    const [counterKey, setCounterKey] = useState(0)
    const [activeTab, setActiveTab] = useState(1)
    const [committe, setComittee] = useState([])
    const [detailParticipant, setDetailParticipant] = useState(null)
    const [showDetail, setShowDetail] = useState(false)

    const ChampionTabsComponent = ParticipantTabsComponent
    useEffect(() => {
        fetchData()
    },[])

    async function fetchData() {
        try {
          setLoading(true)
          const response = await MasterEventRepo.getDetailEvent(id)
          if (!response.data.data) throw new Error ()
          if(response.data.data.participants.length) {
            let tempCommittee = []
            response.data.data.participants.forEach(participant => {
                if(participant.isCommittee) {
                    tempCommittee.push(participant)
                }
            });
            setComittee(tempCommittee)
          }
          setDetailEvent(response.data.data)
        }
        catch (error) {
            messageApi.open({
                type: 'error',
                content: "Failed to get Data event" ,
            });
        } finally {
          setLoading(false)
        }
    }

    const goBack = () => {
        navigate('/admin/event')
    }

    const formatedDate = (date) => {
        if(!isNullOrEmpty(date)) {
            let arrDate = date.split('/')
            return dayjs(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`).format('DD MMM YYYY')
        }

        return 'n/b'
    }

    const formatedTime = (time) => {
        if(!isNullOrEmpty(time)) {
            let arrTime = time.split(':')
            return `${arrTime[0]}:${arrTime[1]}`
        }

        return 'n/b'
    }

    const navigateTo = (key) => {
        onChange(key)
    }

    const onChange = (key) => {
        let tempCounter = counterKey
        setCounterKey(tempCounter+1)
        setActiveTab(key)
    };

    const selectToDetail = (data) => {
        setDetailParticipant(data)
        setShowDetail(true)
    }

    const handleCloseModal = () => {
        setShowDetail(false)
        setDetailParticipant(null)
    }

    const openUrl = (url) => {
        if (!isNullOrEmpty(url)) {
          window.open(url, "_BLANK");
        }
      };

    const items = [
        {
          key: '1',
          label: 'Details',
          children: <DetailTabsComponent  detailEvent={detailEvent} committe={committe} navigateToParticipant={() => navigateTo(2)} />,
        },
        {
          key: '2',
          label: 'Participant',
          children: <ParticipantTabsComponent key={`participant-${counterKey}`} detailEvent={detailEvent} selectToDetail={selectToDetail}  />,
        },
        {
          key: '3',
          label: 'Champion',
          children: <ChampionTabsComponent key={`champion-${counterKey}`} detailEvent={detailEvent} isChampion={true} />,
        },
      ];

    if(loading) {
        return (<>
            <div className="w-full text-center">
                <p>Please wait...</p>
            </div>
        </>)
    }

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            itemActiveColor: '#d30101',
                            itemSelectedColor: '#d30101',
                            itemHoverColor: '#d30101',
                            inkBarColor: '#d30101'
                        },
                    },
                }}
            >
                <div className="container" id="DetailEventPage">
                    <div className="w-[100%] px-[50px] py-[20px] flex">
                        <div className="mt-[6px] mr-[20px]">
                            <button onClick={goBack}>
                                <img width={32} src={IconArrowLeft} alt="icon" />
                            </button>
                        </div>
                        <div>
                            <h4 className="text-[24px] ">Detail Event</h4>
                        </div>
                    </div>
                    <div className="px-[50px]">
                        <div>
                            <h6 className="text-[28px] font-bold">{detailEvent?.masterEvent?.name}</h6>
                        </div>
                        <div>
                            <div className="flex justify-between">
                                <div className="flex">
                                    <div>
                                        <div className="flex">
                                            <CalendarTwoTone twoToneColor="#d30101" style={{marginTop: '5px'}}/>
                                            <span className="ml-[5px]">{formatedDate(detailEvent.startDate)} - {formatedDate(detailEvent.endDate)}</span>
                                        </div>
                                        
                                    </div>
                                    <div className="ml-[20px]">
                                        <div className="flex">
                                            <ClockCircleTwoTone twoToneColor="#d30101" style={{marginTop: '5px'}}/>
                                            <span className="ml-[5px]">{formatedTime(detailEvent.startTime)} - {formatedTime(detailEvent.endTime)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="rounded-full w-auto text-white bg-green px-5">
                                        Finished
                                    </div>
                                </div>
                            </div>
                            <div className="flex ">
                                <div className="flex">
                                    <img width={22} src={Coin} alt="coin" />
                                    <span className="ml-[5px]">{detailEvent.masterEvent.gc}</span>
                                </div>
                                <div className="flex ml-[30px]">
                                    <div className='rounded-full bg-[#D0CBFF] w-[20px] h-[20px] text-center text-[8pt] font-bold  text-[#4F40F4] pt-[2px] mt-[3px] '>XP</div>
                                    <span className="ml-[5px]">{detailEvent.masterEvent.xp}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-[50px] mt-[20px]">
                    
                        <Tabs defaultActiveKey="1" activeKey={`${activeTab}`} items={items} onChange={onChange} />
                    </div>
                </div>
            </ConfigProvider>
            <Modal
                forceRender
                onCancel={handleCloseModal}
                open={showDetail}
                footer={<></>}
            >
                <div className="w-full">
                    <h3 className="font-semibold text-[20px] ">{detailParticipant?.fullname} Details</h3>
                    <p>Please check your data below!</p>
                </div>
                <div className="w-full p-3 mt-[30px]">
                    <div className="flex">
                        <div className="mr-[5px] w-[40%] font-semibold">
                            Participant Name
                        </div>
                        <div className="mr-[5px] w-[60%]">
                            <Input className="w-full" disabled value={detailParticipant?.fullname} />
                        </div>
                    </div>
                    <div className="flex mt-[10px]">
                        <div className="mr-[5px] w-[40%] font-semibold">
                            Status
                        </div>
                        <div className="mr-[5px] w-[60%]">
                            {detailParticipant?.status}
                        </div>
                    </div>
                    <div className="flex mt-[10px]">
                        <div className="mr-[5px] w-[40%] font-semibold">
                            Grade Result
                        </div>
                        <div className="mr-[5px] w-[60%]">
                            {isNullOrEmpty(detailParticipant?.resultGrade) || Number(detailParticipant?.resultGrade) === 0 ? "-" : detailParticipant?.resultGrade}
                        </div>
                    </div>
                    <div className="mt-[10px]">
                        <TableTaskParticipant dataSrc={detailParticipant?.details} />
                    </div>
                    <div className="mt-[10px] w-full">
                        <p className="font-semibold ">Verification</p>
                        {
                            detailParticipant?.listCertification?.length ? 
                            <table className="w-full my-[20px]">
                                <tbody>
                                    {detailParticipant?.listCertification.map((certification, index) => (
                                    <tr key={"row-certification-" + index}>
                                        <td>{certification.fullname}</td>
                                        <td className="w-[20%] text-center">
                                        {isNullOrEmpty(certification.urlAttandance) ? (
                                            "-"
                                        ) : (
                                            <Button
                                            type="link"
                                            onClick={() =>
                                                openUrl(certification.urlAttandance)
                                            }
                                            >
                                            <div className="flex">
                                                <p>Attandance</p>
                                                <ExportOutlined
                                                style={{
                                                    fontSize: "10px",
                                                    marginLeft: "5px",
                                                    marginTop: "6px",
                                                }}
                                                />
                                            </div>
                                            </Button>
                                        )}
                                        </td>
                                        <td className="w-[20%] text-center">
                                        {isNullOrEmpty(certification.urlCertificate) ? (
                                            "-"
                                        ) : (
                                            <Button
                                            type="link"
                                            onClick={() =>
                                                openUrl(certification.urlCertificate)
                                            }
                                            >
                                            <div className="flex">
                                                <p>Certificate</p>
                                                <ExportOutlined
                                                style={{
                                                    fontSize: "10px",
                                                    marginLeft: "5px",
                                                    marginTop: "6px",
                                                }}
                                                />
                                            </div>
                                            </Button>
                                        )}
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table> :
                            <>
                                <div className="text-center w-full">
                                    <div>
                                        <InboxOutlined style={{ fontSize: '42px', color: '#80808036' }} />
                                    </div>
                                    <div>
                                        No Data
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

const TableTaskParticipant = ({dataSrc}) => {
    let dataTable = []
    if(dataSrc?.length > 0) {
        dataSrc.forEach(dt => {
            dataTable.push({
                title: dt?.masterEventTask?.type?.name === "Attendance" ? "Attendance" : dt?.masterEventTask?.task?.title,
                type: dt?.masterEventTask?.type?.name,
                grade: dt?.masterEventTask?.grade
            })
        })
    }
    const columns = [
        {
            title: 'Task',
            dataIndex: 'task',
            key: 'task',
            align: 'center',
            render: (_, record) => (
                <div className="text-center">
                    {record.task}
                </div>
            ),
        },
        {
            title: "Type",
            key: 'type',
            align: 'center',
            width: 170,
            render: (_, record) => (
                <div className="text-center">
                    {record.type}
                </div>
            ),
        },
        {
            title: 'Grade',
            key: 'grade',
            align: 'center',
            width: 170,
            render: (_, record) => (
                <div className="text-center">
                    {record.grade}
                </div>
            ),
        },
    ];

    return (<Table  columns={columns} dataSource={dataTable} pagination={false} />)
}

export default DetailEventPage;