import { Modal, Button, Form, Input } from "antd";
import React from "react";
const { TextArea } = Input;

const InputForm = ({
  open,
  handleClose,
  handleSubmit = () => {},
  handleEdit = () => {},
  handleDelete = () => {},
  purpose = "new",
  clientName = "",
  clientLocation = "",
  clientId = "",
  setClientLocationInputForm = (val) => {},
  setclientNameInputForm = (val) => {},
}) => {
  const [form] = Form.useForm();
  const purposeLabeling = {
    new: "New",
    edit: "Edit",
    delete: "Delete",
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        width={500}
        footer={null}
        closable={true}
        title={`${purposeLabeling[purpose]} Client`}
        destroyOnClose={true}
      >
        <div>
          <Form
            className="w-full"
            labelCol={{
              span: 9,
            }}
            preserve={false}
            form={form}
            autoComplete="off"
            onFinish={(e) => {
              if (purpose === "edit") {
                handleEdit(e.name, e.location);
              } else if (purpose === "delete") {
                handleDelete(clientId);
              } else {
                handleSubmit(e.name, e.location);
              }
              setClientLocationInputForm("");
              setclientNameInputForm("");
            }}
            onFinishFailed={(e) => {}}
          >
            {purpose !== "delete" && (
              <>
                <div className=" overflow-auto h-auto w-full my-3">
                  <Form.Item
                    name="name"
                    label="Name"
                    initialValue={clientName}
                    className=""
                    labelAlign="left"
                  >
                    <Input
                      type="text"
                      placeholder="Write client name"
                      name="name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="location"
                    label="Location"
                    initialValue={clientLocation}
                    className=""
                    labelAlign="left"
                  >
                    <TextArea
                      rows={4}
                      placeholder="Write your location"
                      name="location"
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {purpose === "delete" && <>
            <p>hapus client dengan nama <span className="font-bold">{clientName}</span>?</p>
            </>}
            <div className="flex justify-end mt-5 gap-[10px]">
              <Button onClick={() => handleClose()}>No</Button>
              <Button type="primary" htmlType="submit">
                Yes
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default InputForm;
