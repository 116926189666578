import React, { useEffect, useState } from "react";
import moment from "moment";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import { Button, Form, Modal, Pagination } from "antd";
import deleteIcon from "../../../assets/icons/delete.png";
import "./index.css";
import PlacementHistoryRepo from "../../../repositores/adminRepo/PlacementHistoryRepo";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";
import SearchBar from "../componets_global/SearchBar";
import TableContent from "./components/TableContent";
import ModalPlacementHistory from "./components/ModalPlacementHistory";

const PlacementHistory = () => {
  const [form] = Form.useForm();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [listData, setListData] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const [id, setId] = useState();
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const columns = [
    {
      title: "Date Test",
      dataIndex: "testDate",
      key: "testDate",
      align: "center",
      render: (v) => {
        return <p>{moment(v).format("DD-MMM-YYYY")}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      align: "center",
      render: (v) => {
        let textColorClass = "";
        let bgColorClass = "";

        if (v === "passed") {
          textColorClass = "text-[#43ae3b]";
          bgColorClass = "bg-[#c4fccb]";
        } else if (v === "failed" || v === "Failed") {
          textColorClass = "text-[#ae3b3b]";
          bgColorClass = "bg-[#fcc4c4]";
        }

        const statusText = v !== null ? v.toUpperCase() : "-";

        if (v === "-") {
          return (
            <div className="flex justify-center w-full">
              <div
                className={`w-[100px] px-1 justify-center text-center rounded`}
              >
                {statusText}
              </div>
            </div>
          );
        }

        return (
          <div className="flex justify-center w-full">
            <div
              className={`w-[100px] px-1 justify-center text-center rounded ${textColorClass} ${bgColorClass}`}
            >
              {statusText}
            </div>
          </div>
        );
      },
    },
    {
      title: "Talent Name",
      dataIndex: ["user", "fullname"],
      key: "talentName",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Feedback from PIC",
      dataIndex: "feedbackFromPIC",
      key: "feedbackFromPIC",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Remark by Talent",
      dataIndex: "feedbackFromTalent",
      key: "feedbackFromTalent",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id) => {
        return (
          <div className="flex">
            <div
              onClick={() => {
                handleClickEditModal(id);
                setId(id);
              }}
              className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-red-600">Edit</span>
            </div>
            <img
              className="cursor-pointer"
              src={deleteIcon}
              alt="delete"
              onClick={() => {
                setId(id);
                setOpenModalDelete(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleGetDataModalEdit = async (status, data, selectedUser) => {
    const reqBody = {
      id: id || data.id,
      testDate: data?.testDate,
      clientName: data?.clientName,
      feedbackFromPIC: data?.feedbackFromPIC,
      status: data?.status,
      user: {
        id: selectedUser,
      },
    };

    if (status === "success") {
      const response = await PlacementHistoryRepo.editPlacementTestAdmin(
        reqBody
      );
      if (response.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        fetchData();
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
          setOpenModalEdit(false);
          setDataEdit(null);
          setId(null);
        }, 2000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
    }
  };

  const handleGetDataModalAdd = async (status, data, selectedUser) => {
    const paramDataAdd = {
      testDate: data?.testDate,
      clientName: data?.clientName,
      feedbackFromPIC: data?.feedbackFromPIC,
      status: data?.status,
      user: {
        id: selectedUser,
      },
    };

    if (status === "success") {
      const response = await PlacementHistoryRepo.addPlacementTestAdmin(
        paramDataAdd
      );
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
        fetchData();
        setOpenModalAdd(false);
        form.resetFields();
      } else {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        console.log("Error addData", response);
      }
    }
  };

  const handleClickEditModal = async (id) => {
    setOpenModalEdit(true);
    const filterListByID = listData.filter((data) => data.id === id);
    setDataEdit(filterListByID[0]);
  };

  const handleClickDeleteModal = async () => {
    try {
      const response = await PlacementHistoryRepo.deletePlacementTestAdmin(id);
      console.log(response);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
      fetchData();
      setOpenModalDelete(false);
    } catch (error) {
      console.error("Error deleting rewards:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLimit(10);
      setLoading(true);
      const response = await PlacementHistoryRepo.getListPlacementTestAdmin(
        page,
        limit
      );

      if (response.status === 200) {
        setTotalItem(response?.data?.data?.totalElements);
        setListData(response?.data.data.content);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching rewards data:", error);
    }
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeSearch = async (el, field) => {
    try {
      setPage(1);
      setLoading(true);
      setListData(null);
      const response = await PlacementHistoryRepo.getListPlacementTestAdmin(
        1,
        10,
        field === "clientName" ? el.target.value : null,
        field === "talentName" ? el.target.value : null,
        field === "status" ? el.target.value : null
      );
      setListData(response.data.data.content);
      setLoading(false);
    } catch (error) {
      console.error("Error searching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Placement Test History</TitleMasterAdmin>
        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "clientName")}
              placeholder={"Search Client Name"}
            />
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "talentName")}
              placeholder={"Search Talent Name"}
            />
          </div>
          <div className="flex flex-row gap-4 mb-4 lg:mb-0">
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "status")}
              placeholder={"Search Status"}
            />
            <button
              className="bg-red-600 w-[120px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[30px] font-bold"
              onClick={() => setOpenModalAdd(true)}
            >
              Add Data
            </button>
          </div>
        </div>
        <TableContent
          dataSource={listData}
          columns={columns}
          isLoading={loading}
        />
        <div className="w-full flex justify-center mt-5">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
          />
        </div>
        <ModalPlacementHistory
          instanceForm={form}
          getData={(status, data, selectedUser) =>
            handleGetDataModalAdd(status, data, selectedUser)
          }
          dataEdit={null}
          openModal={openModalAdd}
          onClick={() => {
            setOpenModalAdd(false);
            form.resetFields();
          }}
          title={"Add"}
        />
        <ModalPlacementHistory
          instanceForm={form}
          getData={(status, data, selectedUser) =>
            handleGetDataModalEdit(status, data, selectedUser)
          }
          dataEdit={dataEdit}
          openModal={openModalEdit}
          onClick={() => {
            setOpenModalEdit(false);
            form.resetFields();
            setDataEdit(null);
          }}
          title={"Edit"}
        />
        <Modal
          forceRender
          width={315}
          footer={null}
          closable={false}
          keyboard={false}
          open={openModalDelete}
          onOk={handleClickDeleteModal}
          onCancel={() => setOpenModalDelete(false)}
        >
          <div className="flex flex-col justify-center items-center gap-[30px] text-center">
            <p>Are you sure?</p>
            <p className="text-center">
              Do you really want to delete these records? This process cannot be
              undone
            </p>
            <div className="flex gap-[10px]">
              <Button onClick={() => setOpenModalDelete(false)}>Cancel</Button>
              <Button onClick={handleClickDeleteModal} type="primary" danger>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PlacementHistory;
