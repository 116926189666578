import { useMemo, useState } from "react";
import { QuizService } from "../services/quiz.service";
import { useNavigate, useParams } from "react-router-dom";

const quizService = new QuizService();

export const useQuiz = ({ questions: quizQuestions }) => {
  const navigate = useNavigate();

  const params = useParams();

  const [answers, setAnswers] = useState([]);

  const [quizResult, setQuizResult] = useState({});

  const [questionIndex, setQuestionIndex] = useState(0);

  const [quizState, setQuizState] = useState("introduction");

  const currentQuestion = useMemo(
    () => quizQuestions[questionIndex],
    [questionIndex, quizQuestions]
  );

  const canGoToNextQuestion = useMemo(() => {
    return Boolean(answers[questionIndex]) !== false;
  }, [answers, questionIndex]);

  const isFirstQuestion = useMemo(() => {
    return questionIndex === 0;
  }, [questionIndex]);

  const isLastQuestion = useMemo(() => {
    return questionIndex === quizQuestions.length - 1;
  }, [questionIndex, quizQuestions.length]);

  const isQuizConfirmed = useMemo(() => {
    return quizState === "confirmed";
  }, [quizState]);

  const isQuizIntroduction = useMemo(() => {
    return quizState === "introduction";
  }, [quizState]);

  const isQuizStarted = useMemo(() => {
    return quizState === "started";
  }, [quizState]);

  const isQuizSubmitted = useMemo(() => {
    return quizState === "submitted";
  }, [quizState]);

  function startTakingQuiz() {
    setQuizState("started");
  }

  function goBackFromConfirming() {
    setQuizState("started");
  }

  function goToNextQuestion() {
    if (isLastQuestion) {
      confirmAnswers();
      return null;
    }

    setQuestionIndex((prevQuestionIndex) => prevQuestionIndex + 1);
  }

  function goToPrevQuestion() {
    setQuestionIndex((prevQuestionIndex) => prevQuestionIndex - 1);
  }

  function chooseAnswer(answer) {
    setAnswers((prevAnswers) => {
      prevAnswers[questionIndex] = {
        questionId: quizQuestions[questionIndex].id,
        answerId: answer.id,
      };
      return [...prevAnswers];
    });
  }

  function confirmAnswers() {
    setQuizState("confirmed");
  }

  async function submitAnswers() {
    try {
      const taskId = params.quizId;
      const eventId = params.eventId;

      const result = await quizService.post.quizAnswers(
        answers,
        taskId,
        eventId
      );

      if (result.status !== 200) {
        alert(result.message);
        return navigate({
          pathname: `/talent/journey`,
        });
      }

      setQuizResult(result.data);
      setQuizState("submitted");
    } catch (error) {
      alert("An error occured");
    }
  }

  return {
    answers,
    canGoToNextQuestion,
    currentQuestion,
    currentQuestionIndex: questionIndex,
    isFirstQuestion,
    isLastQuestion,
    isQuizConfirmed,
    isQuizIntroduction,
    isQuizStarted,
    isQuizSubmitted,
    quizResult,
    chooseAnswer,
    goBackFromConfirming,
    goToNextQuestion,
    goToPrevQuestion,
    startTakingQuiz,
    submitAnswers,
  };
};
