import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const BASE_PATH = `placement-test-talent`;

const getListPlacementTestAdmin = async (
  page,
  size,
  clientName,
  talentName,
  status
) => {
  let params = {
    page: page.toString(),
    size: size,
  };
  if (clientName) {
    params["clientName"] = clientName;
  }
  if (talentName) {
    params["talentName"] = talentName;
  }
  if (status) {
    params["status"] = status;
  }

  const url = `${API_BASE}/${BASE_PATH}/list-admin?${createSearchParams(
    params
  )}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getDetailPlacementTestAdmin = async (
  page = 0,
  size = 10,
  name = undefined,
  id
) => {
  let params = {
    page: page + 1,
    size: size,
  };

  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/${BASE_PATH}/${id}?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addPlacementTestAdmin = async (body) => {
  const url = `${API_BASE}/${BASE_PATH}/admin-create`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const editPlacementTestAdmin = async (body) => {
  const url = `${API_BASE}/${BASE_PATH}/admin-update`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const deletePlacementTestAdmin = async (id) => {
  const url = `${API_BASE}/${BASE_PATH}/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
};

const PlacementHistoryRepo = {
  addPlacementTestAdmin,
  editPlacementTestAdmin,
  getListPlacementTestAdmin,
  getDetailPlacementTestAdmin,
  deletePlacementTestAdmin,
};

export default PlacementHistoryRepo;
