import React, { useEffect, useState } from "react";
import TalentHeader from "../../../components/general/TalentHeader";
import ChecklistSkillInterview from "../../../repositores/talentRepo/ChecklistSkillInterviewRepo";
import { ChecklistSkillColumns } from "./column.state";
import { Table, Skeleton } from "antd";
import AdminDetailView from "./AdminDetailView";
import { useNavigate } from "react-router-dom";
import RouteTalent from "../../../services/RouteTalent";

const SkeletonTable = ({ rows = 5, columns = 7 }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <th
                key={colIndex}
                className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <Skeleton.Input active size="small" className="w-24" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} className="px-4 py-4 whitespace-nowrap">
                  <Skeleton.Input active size="default" className="w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ChecklistSkill = () => {
  const navigate = useNavigate();
  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [retroInterview, setRetroInterview] = useState([]);
  const [tableProfile, setTableProfile] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);
    // retroInterviewCandidateDetails
    await ChecklistSkillInterview.getListAdmin(0, 10)
      .then((res) => {
        setAdminList(res.data.data.content);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmitCheck = async (id) => {
    setLoading(true);
    await ChecklistSkillInterview.submitChecked(id)
      .then((res) => {})
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
        window.location.reload();
      });
  };

  const handleOpenTopic = (courseId, materialId, course) => {
    //const itemData = adminList.filter((data) => data.id === );

    navigate(RouteTalent.talentJourneyTopic, {
      state: {
        material: { id: materialId },
        id: courseId,
        course: course.name,
        courseId: course.id,
        matkulId: materialId,
      },
    });
  };
  /* 
  const actionBack = () => {
    navigate(RouteTalent.talentJourneyTermin, {
      state: {
        id: location.state?.id,
        course: location.state?.course,
        courseId: location.state?.courseId,
      },
    });
  }; */

  const getUrlCourseId = async (courseId, matkulId, course) => {
    setLoading(true);
    await ChecklistSkillInterview.getUserURLCourseId(courseId, matkulId)
      .then((res) => {
        const { userCourseId, matkulId } = res.data.data;
        handleOpenTopic(userCourseId, matkulId, course);
        /* 
        {
          "userCourseId": "513017fa-9756-4385-8831-43e2341dbed8",
          "matkulId": "ce0e81d5-ae3c-4e8c-acee-9c96fae2d21c"
        }
        */
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  };

  return (
    <>
      <div className="w-full h-screen px-4 py-5">
        <AdminDetailView
          show={show}
          closePopUp={() => {
            setShow(false);
          }}
          modalTitle={""}
          tableData={retroInterview}
          getUrlCourseId={(c, m, s) => {
            getUrlCourseId(c, m, s);
          }}
          handleSubmitCheck={(i) => handleSubmitCheck(i)}
          profile={tableProfile}
        />
        <TalentHeader title={"Checklist Skill Interview"} />
        {loading ? (
          <div className="flex items-center justify-center py-12 z-[9999999999]">
            <SkeletonTable />
          </div>
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={ChecklistSkillColumns((id, isEdit) => {
                  // handleView
                  setShow(true);
                  const adminById = adminList.filter((data) => data.id === id);
                  setTableProfile(() => ({
                    ...adminById[0].retroInterviewClient,
                  }));
                  setRetroInterview(() => [
                    ...adminById[0]?.retroInterviewCandidateDetails,
                  ]);
                })}
                dataSource={adminList}
                pagination={false}
                sticky={true}
                className="font-semibold mt-2"
                scroll={{
                  y: 470,
                  x: 900,
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ChecklistSkill;
