import React, { useState, useEffect } from "react";
import { message, Button, Table, Pagination } from "antd";
import Onboarding from "../../../repositores/adminRepo/OnboardRepo";
import ModalAddOnboard from "./components/ModalAddOnboard";

const AdminOnboarding = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [searchName, setSearchName] = useState(undefined);

  const [dataTable, setDataTable] = useState([]);
  const [bounchPage, setBounchPage] = useState(undefined);

  const [showModal, setShowModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [typeOnboardForm, setTypeOnboardForm] = useState("add");

  const [counterKey, setCounterKey] = useState(0);

  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
    },
    {
      title: "Activate",
      dataIndex: "status",
      key: "id",
      align: "center",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 200,
      render: (_, record) => (
        <div className="flex justify-center space-x-2 sm:space-x-4 md:space-x-6">
          <div
            onClick={() => {
              getViewFile(record);
            }}
            className="border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer sm:h-8"
          >
            <span className="text-grey text-xs sm:text-sm">View File</span>
          </div>
          <div
            onClick={() => {
              handleUpdateOnboard(record);
            }}
            className="bg-red-600 border-red-600 px-[15px] text-sm flex justify-center items-center w-[auto] rounded-[7px] cursor-pointer sm:h-8"
          >
            <span className="text-white text-xs sm:text-sm">Edit</span>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshCounter]);

  async function fetchData() {
    setLimit(10);
    try {
      let tempData = [];
      let response = await Onboarding.getListOnboarding(
        page,
        limit,
        searchName
      );
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content, index) => {
          content.number = (page > 0 ? limit * (page - 1) : 0) + (index + 1);
          tempData.push(content);
        });
        setDataTable(tempData);
        setTotalItem(response?.data?.data?.totalElements);
      } else {
        setDataTable([]);
        setTotalItem(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Failed to get Data Onboarding",
      });
    }
  }

  const getViewFile = async (record) => {
    const rep = record.fileName;
    window.open(rep, "_blank");
    messageApi.open({
      type: "success",
      content: "Success Downloaded File",
    });
  };

  const onChangePage = (el) => {
    setLoading(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setPage(el);
      }, 1000)
    );
  };

  const handleAddOnboard = () => {
    setCounterKey((preVal) => preVal + 1);
    setTypeOnboardForm("add");
    setDefaultValue(null);
    setShowModal(true);
  };

  const handleUpdateOnboard = (data) => {
    setCounterKey((preVal) => preVal + 1);
    setTypeOnboardForm("edit");
    setDefaultValue(data);
    setShowModal(true);
  };

  const handleCloseModal = (data, isSuccess) => {
    setShowModal(false);
    setCounterKey((preVal) => preVal + 1);
    if (isSuccess) {
      setRefreshCounter((preVal) => preVal + 1);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <div className="text-center md:text-left">
          <h4 className="text-2xl md:text-4xl font-bold">Onboarding</h4>
        </div>
        <div className="flex flex-wrap gap-2 justify-end mt-4 md:mt-0">
          <Button
            onClick={handleAddOnboard}
            type="primary"
            disabled={loading}
            className="bg-[#DD0000] border-red-600 text-white md:w-auto"
            style={{ backgroundColor: "#DD0000" }}
          >
            Add Onboard
          </Button>
        </div>

        <div className="mt-6 w-full">
          <Table
            dataSource={dataTable}
            columns={columns}
            pagination={false}
            rowKey="id"
            scroll={{ x: true, y: 520 }}
          />
        </div>
        <div className="w-full flex justify-center my-4 pt-2 pb-10">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
            showSizeChanger={false}
          />
        </div>
      </div>

      <ModalAddOnboard
        key={"addOnboard" + counterKey}
        show={showModal}
        closeModal={handleCloseModal}
        type={typeOnboardForm}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default AdminOnboarding;
