import { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useVerificationUploader = () => {
  const location = useLocation();

  const params = useParams();

  const navigate = useNavigate();

  const [shouldRenderUploader, setShouldRenderUploader] = useState(
    location.pathname.includes("verify")
  );

  const [verificationUploaderType, setVerificationUploaderType] = useState(
    location.pathname.split("/verify/")[1]
  );

  const redirectPath = useMemo(
    () =>
      `/talent/journey/events/${params.eventId}`,
    [params.enablerOrDeveloper, params.eventId]
  );

  function showVerificationUploader(taskId) {
    setShouldRenderUploader(true);

    return {
      forCertificate() {
        setVerificationUploaderType("certificate");
        navigate(`${redirectPath}/verify/certificate/${taskId}`);
      },
      forAttendance() {
        setVerificationUploaderType("attendance");
        navigate(`${redirectPath}/verify/attendance/${taskId}`);
      },
    };
  }

  function hideVerificationUploader() {
    setShouldRenderUploader(false);
    navigate({
      pathname: redirectPath,
      search: new URLSearchParams({ expanded: true }).toString(),
    });
  }

  return {
    shouldRenderUploader,
    verificationUploaderType,
    showVerificationUploader: {
      forAttendance: (taskId) => showVerificationUploader(taskId).forAttendance(),
      forCertificate: (taskId) => showVerificationUploader(taskId).forCertificate(),
    },
    hideVerificationUploader,
  };
};
