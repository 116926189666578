export const QuizConfirmation = ({ goToPrevQuestion, submitAnswers }) => {
  return (
    <div className="absolute left-[100px] right-[100px] top-1/2 -translate-y-1/2">
      <h1 className="text-white text-[100px] font-extrabold">Hey,</h1>
      <p className="text-white text-[40px] font-light">
        Just wanted to drop a quick note to double-check your answer to make
        sure it's all good.
        <br />
        <br />
        Can you confirm that for me?
      </p>
      <footer className="flex items-center gap-16 mt-20">
        <button
          className="inline-flex items-center gap-4 py-2 px-12 bg-secondary rounded-[20px]"
          onClick={submitAnswers}
        >
          <span className="text-white font-bold">Yes, I confirm</span>
        </button>
        <button className="text-white font-bold" onClick={goToPrevQuestion}>
          Go Back
        </button>
      </footer>
    </div>
  );
};
