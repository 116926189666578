import Swal from "sweetalert2";

const SuccessAlert = ({ title }) => {
    Swal.fire({
        icon: 'success',
        text: title,
        showConfirmButton: false,
        timer: 2000
    })
}

const ErrorAlert = ({ title }) => {
    Swal.fire({
        icon: 'error',
        text: title,
        showConfirmButton: true,
    })
}

const LoadingAlert = () => {
    Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        },
    })
}

const confirmAlert = ({text, confirmText, onConfirm}) => {
    Swal.fire({
        title: 'Are you sure?',
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmText
      }).then((result) => {
        if (result.isConfirmed) {
          onConfirm()
        }
      })
}

const AlertUtility = {
    SuccessAlert,
    ErrorAlert,
    LoadingAlert,
    confirmAlert
}

export default AlertUtility;