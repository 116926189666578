import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "../styles/journeyStyle.css"; // Import your custom CSS
import { useLocation } from "react-router-dom";

export const JourneyInformation = ({
  journeyData,
  changePathTo,
  getCurrentSlideIndex,
}) => {
  const sliderRef = useRef(null);
  const location = useLocation();
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: Math.min(journeyData?.length, 7),
    slidesToScroll: 1,
    swipeToSlide: true,
    rows: 1,
    afterChange: getCurrentSlideIndex,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 slide at a time on small screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 slides at a time on medium screens
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5, // Show 5 slides at a time on larger screens
        },
      },
    ],
  };

  let path = location.state?.journeyId;

  const [activeJourney, setActiveJourney] = useState("");

  useEffect(() => {
    let slideIndex = journeyData?.findIndex((item) => item.id === path);
    toSlide(slideIndex, path);
  }, [journeyData]);

  const handleSwipe = (event) => {
    if (event.deltaY < 0) {
      // Scroll up (previous slide)
      sliderRef.current.slickPrev();
    } else if (event.deltaY > 0) {
      // Scroll down (next slide)
      sliderRef.current.slickNext();
    }
  };

  const CustomNextArrow = () => {
    return (
      <button
        className="md:hidden flex justify-center items-center p-3 w-8 h-8 bg-gray-700 text-white rounded-full absolute right-0 top-3 z-10"
        onClick={() => {
          let currentIndex = journeyData?.findIndex(
            (item) => item.id === activeJourney
          );
          if (currentIndex + 1 > journeyData.length - 1) currentIndex = 0;
          else currentIndex = currentIndex + 1;
          setActiveJourney(journeyData[currentIndex]?.id);
          sliderRef?.current?.slickNext();
        }}
      >
        <RightOutlined />
      </button>
    );
  };

  const CustomPrevArrow = () => {
    return (
      <button
        className="md:hidden flex justify-center items-center p-3 w-8 h-8 bg-gray-700 text-white rounded-full absolute left-0 top-3 z-10"
        onClick={() => {
          let currentIndex = journeyData?.findIndex(
            (item) => item.id === activeJourney
          );
          if (currentIndex - 1 < 0) currentIndex = journeyData.length - 1;
          else currentIndex = currentIndex - 1;
          setActiveJourney(journeyData[currentIndex]?.id);
          sliderRef?.current?.slickPrev();
        }}
      >
        <LeftOutlined />
      </button>
    );
  };

  const toSlide = (slideIndex, path) => {
    let indexTo = slideIndex < 0 ? 0 : slideIndex;
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(indexTo);
    }
    setActiveJourney(journeyData[indexTo]?.id);
    changePathTo(path);
  };

  useEffect(() => {
    if (sliderRef?.current && sliderRef?.current?.innerSlider?.list) {
      sliderRef?.current?.innerSlider?.list?.addEventListener(
        "wheel",
        handleSwipe
      );
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sliderRef.current?.innerSlider?.list?.removeEventListener(
        "wheel",
        handleSwipe
      );
    };
  }, [sliderRef]);

  return (
    <div className="relative flex flex-col items-center bg-primary">
      <div className="relative w-full">
        <CustomPrevArrow />
        <div className="w-full" onWheel={handleSwipe}>
          {journeyData.length > 0 && (
            <Slider ref={sliderRef} {...settings}>
              {journeyData?.map((item, index) => {
                return (
                  <div
                    key={item?.id}
                    className="react-slide-item h-12 cursor-pointer flex items-center justify-center"
                  >
                    <button
                      key={item?.id}
                      disabled={activeJourney === item?.id}
                      className={`md:flex flex-col px-2 text-white text-center ${
                        activeJourney === item?.id ? "font-semibold" : ""
                      }`}
                      onClick={() => toSlide(index, item?.id)}
                    >
                      {item?.name?.toUpperCase()}
                      <hr
                        className={`
                        ${activeJourney === item?.id ? "tabs-button" : null}`}
                      />
                    </button>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
        <CustomNextArrow />
      </div>
    </div>
  );
};
