import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const getListMasterEvent = async (
  page = 0,
  size = 10,
  name = undefined,
  masterEventId = undefined
) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  if (typeof masterEventId !== "undefined" && masterEventId.length) {
    params["idJourney"] = masterEventId;
  }
  const url = `${API_BASE}/master-event/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListEvent = async (
  page = 0,
  size = 10,
  name = undefined,
  masterEventId = undefined,
  startDate = undefined,
  endDate = undefined
) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  if (typeof masterEventId !== "undefined" && masterEventId.length) {
    params["masterEventId"] = masterEventId;
  }
  if (typeof startDate != "undefined" && startDate.length) {
    params["startDate"] = startDate;
  }
  if (typeof endDate != "undefined" && endDate.length) {
    params["endDate"] = endDate;
  }

  params["orderby"] = "name";

  const url = `${API_BASE}/event/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getDetailEvent = async (id) => {
  const url = `${API_BASE}/event/${id}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const deleteMasterEvent = async (id) => {
  let payload = {
    id: id,
  };
  const url = `${API_BASE}/master-event`;
  const response = await CallApi({ url, method: "DELETE", data: payload });
  return response;
};

const addMasterEvent = async (payload) => {
  const url = `${API_BASE}/master-event`;
  const response = await CallApi({ url, method: "POST", data: payload });
  return response;
};

const updateMasterEvent = async (payload) => {
  const url = `${API_BASE}/master-event`;
  const response = await CallApi({ url, method: "PUT", data: payload });
  return response;
};

const getQuizHeader = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/quiz-header/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getCertificate = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/certificate?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const uploadImage = async (file, existFile = undefined) => {
  let formData = new FormData();
  formData.append("file", file, file.name);
  const url = `${API_BASE}/upload${
    existFile ? "/file-existing/" + existFile : ""
  }`;
  const response = await CallApi({
    url,
    method: "POST",
    data: formData,
    type: true,
  });
  return response;
};

const addEvent = async (payload) => {
  const url = `${API_BASE}/event`;
  const response = await CallApi({ url, method: "POST", data: payload });
  return response;
};

const editEvent = async (payload) => {
  const url = `${API_BASE}/event`;
  const response = await CallApi({ url, method: "PUT", data: payload });
  return response;
};

const deleteEvent = async (id) => {
  const url = `${API_BASE}/event`;
  const response = await CallApi({ url, method: "DELETE", data: { id: id } });
  return response;
};

const finishEvent = async (payload, id) => {
  const url = `${API_BASE}/event/status-finish/${id}`;
  const response = await CallApi({ url, method: "POST", data: payload });
  return response;
};

const ListParticipanEvents = async (params, id) => {
  const url = `${API_BASE}/event-participant-task/events/${id}?${createSearchParams(
    params
  )}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const viewQrEvent = async (id) => {
  const url = `${API_BASE}/event/generateQr?width=300&height=300&text=${process.env.REACT_APP_BASE_FE}attendance-verification/${id}`;
  const response = await CallApi({ url, method: "GET", type: "qr" });
  return response;
};

const getListJourney = async (
  page = 0,
  size = 10,
  name = "",
  type = "MASTER_EVENT_JOURNEY"
) => {
  let url = `${API_BASE}/lookup/list?page=${page}&size=${size}&type=${type}`;
  if (name !== "") {
    url += `&name=${name}`;
  }

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const importCsvUser = async (data) => {
  const url = `${API_BASE}/inject/event-participant`;

  const response = await CallApi({ url, method: "POST", data, type: true });
  return response;
};

const MasterEventRepo = {
  getListMasterEvent,
  deleteMasterEvent,
  addMasterEvent,
  getQuizHeader,
  getCertificate,
  updateMasterEvent,
  uploadImage,
  getListEvent,
  addEvent,
  editEvent,
  deleteEvent,
  finishEvent,
  getDetailEvent,
  ListParticipanEvents,
  viewQrEvent,
  getListJourney,
  importCsvUser,
};

export default MasterEventRepo;
