import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthRepo from "../../repositores/authRepo/LoginRepo";
import RouteName from "../../helper/routeName";
import AlertMiniPopUp from "../admin/componets_global/AlertMiniPopUp";
import LogoRepo from "../../repositores/adminRepo/LogoRepo";
import { useGoogleLogin } from "@react-oauth/google";
import Swal from "sweetalert2";

const AttendanceVerification = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [logoGlobal, setLogoGlobal] = useState(null);

  const [mounted, setMounted] = useState(true);

  const getLogo = async () => {
    try {
      const response = await LogoRepo.getLogoNotToken();
      const logo = response.data?.data?.content;
      setLogoGlobal(logo);
    } catch (error) {
      console.log(error);
    }
  };

  const resetAlert = () => {
    setAlertShow(false);
    setAlertMessage("");
    setAlertSuccess(false);
  };

  const successLogin = (data) => {
    if (data.status !== 200) {
      setAlertShow(true);
      setAlertMessage(data.message);
      setAlertSuccess(false);
      setTimeout(() => {
        resetAlert();
      }, 2000);
      return;
    }

    navigate(RouteName.attendanceRecorded, {
      state: logoGlobal,
    });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: ({ access_token }) => checkGoogleLogin(access_token),
  });

  const checkGoogleLogin = async (accessToken) => {
    try {
      const { data } = await AuthRepo.attendanceVerificationNew({
        eventId,
        accessToken,
      });
      successLogin(data);
    } catch (error) {
      if (mounted) {
        handleLoginError(error);
      }
      handleLoginError(error);
    }
  };

  const handleLoginError = (error) => {
    const err = error.response;
    if (err) {
      showErrorMessage("Error", err.message);
    }
  };

  const showErrorMessage = (title, message) => {
    Swal.fire({
      title,
      toast: true,
      text: message,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      color: "#DD0000",
    });
  };

  useEffect(() => {
    getLogo();
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <>
      <div className="flex justify-center">
        {alertShow && (
          <AlertMiniPopUp
            messageAlert={alertMessage}
            isSuccess={alertSuccess}
          />
        )}
        {logoGlobal?.map((item) => (
          <img key={item.id} alt={item.id} src={item.name} className="mb-8" />
        ))}
      </div>
      <div className="flex justify-center attendance-home-img">
        <img
          src={require("../../assets/images/att-img.svg").default}
          alt="attendace home screen"
        />
      </div>
      <div className="text-2xl md:text-3xl font-bold text-[#4C4C4C] mb-2">
        Attendance Verification
      </div>
      <div className="text-xs md:text-base text-[#B4B4B4] mb-6">
        Let's ensure you're with us! Verify your attendance
      </div>
      <div
        className="bg-white border-gray-300 rounded-xl border-2 rounded p-2 cursor-pointer flex justify-center gap-4 cursor-pointer"
        onClick={() => loginGoogle()}
      >
        <img src="/assets/icons/google.svg" alt="logo-google" width={20} />
        <div className="text-center font-medium"> Sign in with Google</div>
      </div>
      <div className="rounded-xl att-box w-full my-8 p-2 text-left flex flex-row gap-2">
        <div className="flex-none w-6">
          <img
            src={require("../../assets/images/att-desc-icon.svg").default}
            alt="attendance description icon"
          />
        </div>
        <div className="flex-initial text-xs md:text-base att-desc-color">
          <strong>To verify your attendance</strong>, simply click on the button
          above & sign in using your Google account. If there's anything that's
          not working for you, please don't hesitate to reach out to our team.
        </div>
      </div>
    </>
  );
};

export default AttendanceVerification;
