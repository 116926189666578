import { Pagination, message, Input } from "antd";
import React, { useEffect, useState } from "react";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import ClientTable from "./components/ClientTable";
import InputForm from "./components/InputForm";
import RoleDataMasterRepo from "../../../repositores/adminRepo/RoleDataMasterRepo";

const RoleDataMaster = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [responseData, setClients] = useState({});
  const [nameKeyword, setNameKeyword] = useState("");
  const [descriptionKeyword, setDescriptionKeyword] = useState("");
  const [typeKeyword, setTypeKeyword] = useState("");
  const [formShow, setFormShow] = useState(false);
  const [purpose, setPurpose] = useState("new");
  const [roleNameInputForm, setRoleNameInputForm] = useState("");
  const [roleType, setRoleType] = useState("");
  const [roleDescriptionInputForm, setRoleDescriptionInputForm] = useState("");
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const showEditModal = (id) => {
    const data = responseData.content?.filter((data) => data.id === id)[0];
    setEditId(() => id);
    setRoleNameInputForm(data.name);
    setRoleDescriptionInputForm(data.description);
    setRoleType(data.type);
    setPurpose((prev) => "edit");
    setFormShow(true);
  };
  const showDeleteConfirm = (id) => {
    const data = responseData.content?.filter((data) => data.id === id)[0];
    setRoleNameInputForm(data.name);
    setRoleDescriptionInputForm(data.location);
    setDeleteId(id);
    setPurpose((prev) => "delete");
    setFormShow(true);
  };
  const showAddModal = (id) => {
    setPurpose((prev) => "new");
    setFormShow(true);
  };

  const onChangePagination = async (page, total) => {
    await handleGetClientList(page, total);
  };

  const handleGetClientList = async (page, total) => {
    const nextPage = page;
    await RoleDataMasterRepo.getRoleList(
      nextPage,
      total,
      nameKeyword,
      descriptionKeyword,
      typeKeyword
    )
      .then((res) => {
        setClients(() => res.data.data);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        // window.location.reload();
      });
  };

  const handleCreate = async (name, type, description) => {
    await RoleDataMasterRepo.submitRole({ name, description, type })
      .then((res) => {
        setFormShow(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        window.location.reload();
      });
  };

  const handleEdit = async (name, type, description) => {
    await RoleDataMasterRepo.updateRole({ id: editId, name, description, type })
      .then((res) => {
        //console.log("client added", res.data.data);
        setFormShow(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        window.location.reload();
      });
  };

  const handleDelete = async () => {
    await RoleDataMasterRepo.deleteRole(deleteId)
      .then((res) => {
        //console.log("client added", res.data.data);
        setFormShow(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        window.location.reload();
      });
  };

  useEffect(async () => {
    handleGetClientList(responseData.number || 0, responseData?.size || 10);
  }, [nameKeyword, descriptionKeyword, typeKeyword]);

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Role</TitleMasterAdmin>
        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <ActionBarSearchOnly
              buttonTitle="Add Client"
              placeholder="Search Client"
              onSearch={(v) => {
                setNameKeyword((prev) => v.target.value);
              }}
            />
            <ActionBarSearchOnly
              buttonTitle="Add Client"
              placeholder="Search Description"
              onSearch={(v) => {
                setDescriptionKeyword((prev) => v.target.value);
              }}
            />
          </div>
          <div className="flex flex-row gap-4 lg:mb-0">
            <ActionBarSearchOnly
              buttonTitle="Add Client"
              placeholder="Search Type"
              onSearch={(v) => {
                setTypeKeyword((prev) => v.target.value);
              }}
            />
            <ActionBarButtonOnly
              buttonTitle="Add Client"
              placeholder="Search Type"
              actionButton={showAddModal}
            />
          </div>
        </div>
        <ClientTable
          data={responseData?.content}
          actionEdit={showEditModal}
          actionDelete={showDeleteConfirm}
        />
        <div className="flex justify-center mt-5">
          <Pagination
            current={responseData?.number}
            total={responseData?.totalElements}
            defaultPageSize={responseData?.size}
            pageSize={responseData?.size}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        </div>
        <InputForm
          open={formShow}
          handleClose={() => {
            setFormShow(false);
          }}
          purpose={purpose}
          handleSubmit={handleCreate}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          roleName={roleNameInputForm}
          description={roleDescriptionInputForm}
          type={roleType}
          setRoleNameInputForm={(v) => setRoleNameInputForm(v)}
          setdescriptionInputForm={(v) => setRoleDescriptionInputForm(v)}
          setTypeInputForm={(v) => setRoleType(v)}
        />
      </div>
    </>
  );
};

const ActionBarSearchOnly = ({
  buttonTitle,
  actionButton,
  onSearch,
  placeholder,
}) => {
  return (
    <Input
      className="w-full lg:w-[200px] max-w-[400px]"
      placeholder={placeholder}
      suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
      onChange={onSearch}
    />
  );
};

const ActionBarButtonOnly = ({ buttonTitle, actionButton }) => {
  return (
    <div className=" flex justify-center">
      {actionButton && (
        <div
          onClick={actionButton}
          className="bg-red-600  w-[150px] h-[30px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
        >
          {buttonTitle}
        </div>
      )}
    </div>
  );
};

export default RoleDataMaster;
