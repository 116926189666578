import React, { useState } from "react";
import { Button, Modal } from "antd";

export const ConfirmDialog = ({
  handleConfirm,
  handleCancel,
  show,
  handleShow,
  title = "",
  content = "",
  okText,
  cancelText,
  buttonText,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const OnClickHandle = () =>
    Modal.confirm({
      title: title,
      content: content,
      okText: okText, // Custom label for the OK button
      okType: "danger", // Button type for the OK button
      okButtonProps: {
        style: { color: "white", backgroundColor: "#DD0000" },
      },
      onOk() {
        // Handle the deletion logic here
        handleConfirm();
      },
      onCancel() {
        // Handle cancellation or do nothing
      },
    });
  return (
    <>
      <Button
        className="w-[100px] py-1 font-bold rounded ms-3"
        onClick={OnClickHandle}
      >
        {buttonText}
      </Button>
    </>
  );
};
/* 
const ModalDialog = () => {
  return (
    <>
      <Modal
        title={buttonText}
        open={isModalOpen}
        onOk={handleConfirm()}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      ></Modal>
    </>
  );
};
 */