import CallApi from '../../services/Request-Helper'
import { createSearchParams } from '../../utils/utils'
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`
const getListVoucher = async (page = 0, size = 10, name = undefined) => {
	let params = {
		page: page,
		size: size,
	}
	if (typeof name != 'undefined' && name.length) {
		params['name'] = name
	}
	const url = `${API_BASE}/voucher/list-active?${createSearchParams(params)}`

	const response = await CallApi({ url, method: 'GET' })

	return response
}
const getListMyReward = async (page = 0, size = 10, name = undefined) => {
	let params = {
		page: page,
		size: size,
	}
	if (typeof name != 'undefined' && name.length) {
		params['name'] = name
	}
	const url = `${API_BASE}/voucher/list-talent/?${createSearchParams(params)}`

	const response = await CallApi({ url, method: 'GET' })

	return response
}
const getUser = async () => {
	const url = `${API_BASE}/badges/user-level`
	const response = await CallApi({ url, method: 'GET' })
	return response
}
const postRedeemVoucher = async (body) => {
	const url = `${API_BASE}/redeem-coins`
	const response = await CallApi({ url, method: 'POST', data: body })
	return response
}

const getVoucher = async (param) => {
	const url = `${API_BASE}/voucher/${param}`
	const response = await CallApi({url, method:'GET'})
	return response
}

const MasterVoucher = {
	getListVoucher,
	getListMyReward,
	getUser,
	postRedeemVoucher,
	getVoucher,
}

export default MasterVoucher
