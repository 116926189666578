import React from "react";
import { Table, Button } from "antd";
import deleteIcon from "../../../../assets/icons/delete.png";

const TableListSubMatkul = ({ dataTable, selectDelete, selectEdit, goToDetail }) => {
    const columns = [
        {
            title: "No",
            dataIndex: "number",
            key: "number",
            align: "center",
            width: '5%'
        },
        {
            title: "No.Sub Mata Kuliah",
            dataIndex: "topikNo",
            key: "topikNo",
            align: "center",
            width: '10%',
        },
        {
            title: "Sub Mata Kuliah",
            dataIndex: "topik",
            key: "topik",
        },
        {
            title: "Time Practice",
            dataIndex: "timePraktek",
            align: "center",
            key: "timePraktek",
        },
        {
            title: "Time Daring",
            dataIndex: "timeDaring",
            align: "center",
            key: "timeDaring",
        },
        {
            title: "Link File",
            dataIndex: "linkFileName",
            key: "linkFileName",
            align: "center",
            render: (_, record) => {
              if(record?.linkFileName && record?.linkFileName?.toUpperCase() !== 'N/A') {
                return (
                  <>
                    <Button type="link" onClick={() => {onClickNavigateTo(record?.linkFileName)}}>
                        Link
                    </Button>
                  </>
                )
              }
              return <span>-</span>
            }
        },
        {
            title: "Link Internet",
            dataIndex: "linkInternet",
            key: "linkInternet",
            align: "center",
            render: (_, record) => {
              if(record?.linkInternet && record?.linkInternet?.toUpperCase() !== 'N/A') {
                return (
                  <>
                    <Button type="link" onClick={() => {onClickNavigateTo(record?.linkInternet)}}>
                        Link
                    </Button>
                  </>
                )
              }

              return <span>-</span>
            }
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: '20%',
            render: (_, record) => {
                return (
                  <div className="flex justify-end">
                    {
                      record.isAssessment ? (<>
                        <div
                          onClick={() => {goToDetail(record.id)}}
                          className="mr-[30px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
                        >
                          <span className="text-grey">View Detail</span>
                        </div>
                      </>) : <></>
                    }
                    <div
                      onClick={() => {
                        selectEdit(record);
                      }}
                      className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
                    >
                      <span className="text-red-600">Edit</span>
                    </div>
                    <div>
                      <img
                        className="cursor-pointer"
                        src={deleteIcon}
                        alt="delete"
                        onClick={() => {
                          selectDelete(record);
                        }}
                      />
                    </div>
                  </div>
                );
              },
        },
    ]

    const onClickNavigateTo = (url) => {
      window.open(
        url,
        '_blank' 
      );
      
    }

    return (
        <>
            <Table
                dataSource={dataTable}
                columns={columns}
                pagination={false}
                rowKey="id"
            />
        </>
    )
}

export default TableListSubMatkul