import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CallApi from "../services/Request-Helper";

const host = process.env.REACT_APP_BASE;
const initialState = {
  valueForm: {
    id: "",
    title: "",
    background: "",
    type: "",
    validityPeriod: "",
    signerName: "",
    signerPosition: "",
    signature: "",
  },
  formSearch: "",
  certificates: [],
  templates: [],
  types: [],
  isOverview: false,
  isLoading: false,
  isLoadingService: false,
  isSuccess: false,
  isError: false,
  isAlert: false,

  pagination: {
    currentPage: 1,
    totalData: 1,
    limit: 10,
  },
};

export const getAllCertificate = createAsyncThunk(
  "admin/certificate-template",
  async (input, thunkAPi) => {
    const { currentPage, limit, keyword } = input;
    const titleQuery = keyword ? `&title=${encodeURIComponent(keyword)}` : "";
    const page = currentPage >= 0 ? currentPage - 1 : 0;
    try {
      const response = await CallApi({
        url: `${host}api/v1/certificate?page=${page}&size=${limit}${titleQuery}`,
        method: "GET",
      });
      if (response.data.status === 200) {
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get all Certificate Template";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const getCertificateById = createAsyncThunk(
  "admin/certificate-template/id",
  async (id, thunkAPi) => {
    try {
      const response = await CallApi({
        url: `${host}api/v1/certificate/${id}`,
        method: "GET",
      });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get one Certificate Template";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const addCertificateTemplate = createAsyncThunk(
  "admin/certificate-template/add",
  async (inputForm, thunkAPi) => {
    try {
      const body = {
        title: inputForm.title,
        expiredDate: inputForm.validityPeriod,
        signerName: inputForm.signerName,
        signerPosition: inputForm.signerPosition,
        signature: inputForm.signature,
        template: inputForm.background,
        typeCertificate: inputForm.type,
      };
      const response = await CallApi({
        url: `${host}api/v1/certificate`,
        method: "POST",
        data: body,
      });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot add new Certificate Template";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const editCertificateTemplate = createAsyncThunk(
  "admin/certificate-template/update",
  async (inputForm, thunkAPi) => {
    try {
      const body = {
        title: inputForm.title,
        expiredDate: inputForm.validityPeriod,
        signerName: inputForm.signerName,
        signerPosition: inputForm.signerPosition,
        signature: inputForm.signature,
        template: inputForm.background,
        typeCertificate: inputForm.type,
      };
      const response = await CallApi({
        url: `${host}api/v1/certificate/${inputForm.id}`,
        method: "PUT",
        data: body,
      });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot update Certificate Template";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const deleteCertificateTemplate = createAsyncThunk(
  "admin/certificate-template/destroy",
  async (uuid, thunkAPi) => {
    try {
      const response = await CallApi({
        url: `${host}api/v1/certificate`,
        method: "DELETE",
        data: { id: uuid },
      });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot add new Certificate Template";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const getServiceLookup = createAsyncThunk(
  "admin/certificate-template/template",
  async (thunkAPi) => {
    try {
      const responseTemplate = await CallApi({
        url: `${host}api/v1/lookup/list?page=0&size=10&type=TEMPLATE_CERTIFICATE`,
        method: "GET",
      });
      const responseType = await CallApi({
        url: `${host}api/v1/lookup/list?page=0&size=10&type=type_CERTIFICATE`,
        method: "GET",
      });
      if (
        responseTemplate.data.status === 200 &&
        responseType.data.status === 200
      ) {
        return {
          template: responseTemplate.data.data.content,
          type: responseType.data.data.content,
        };
      }
      throw new Error("System failed, cannot get Certificate Template Support");
    } catch (error) {
      const message = "System failed, cannot get Certificate Template Support";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const previewCertificateTemplate = createAsyncThunk(
  "admin/certificate-template/preview",
  async (url, thunkAPi) => {
    try {
      window.open(`${url}`, "_blank");
    } catch (error) {
      const message = "System failed, cannot preview Certificate Template";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const certificateTemplateSlice = createSlice({
  name: "certificateTemplate",
  initialState,
  reducers: {
    resetStateForm: (state) => {
      state.valueForm = {
        id: "",
        title: "",
        background: "",
        type: "",
        validityPeriod: "",
        signerName: "",
        signerPosition: "",
        signature: "",
      };
      state.isOverview = false;
    },
    overideStateForm: (state, action) => {
      state.valueForm[action.payload.stateName] = action.payload.value;
    },
    changeState: (state, action) => {
      state[action.payload.stateProp] = action.payload.valueProp;
    },
    changeStatusOverview: (state) => {
      state.isOverview = !state.isOverview;
    },
    resetStatusFeedback: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    },
  },
  extraReducers: (builder) => {
    // FIND ALL
    builder.addCase(getAllCertificate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCertificate.fulfilled, (state, action) => {
      state.certificates = [];
      const originalData = action.payload?.data?.content;
      originalData.map((item) => {
        const convertItem = {
          key: item?.id,
          certificate: item?.title,
          type: item?.typeCertificate?.name,
          template: item?.template?.name,
          signer: `${item?.signerName} (${item?.signerPosition})`,
          image: item?.urlImage,
          action: "",
        };
        state.pagination.currentPage = action.payload.data.number + 1;
        state.pagination.totalData = action.payload?.data?.totalElements;
        state.certificates = [...state.certificates, convertItem];
      });

      state.isLoading = false;
    });
    builder.addCase(getAllCertificate.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isAlert = true;
      state.messageAlert = "Failed to get All Certificate Template";
    });

    // FIND ONE
    builder.addCase(getCertificateById.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(getCertificateById.fulfilled, (state, action) => {
      state.valueForm = {
        id: action?.payload?.data?.id,
        title: action?.payload?.data?.title,
        background: action?.payload?.data?.template?.name,
        type: action?.payload?.data?.typeCertificate?.name,
        validityPeriod: action?.payload?.data?.expiredDate,
        signerName: action?.payload?.data?.signerName,
        signerPosition: action?.payload?.data?.signerPosition,
        signature: action?.payload?.data?.signature,
      };
      state.pagination = {
        ...state.pagination,
        currentPage: action.payload.data.number + 1,
        totalData: action.payload.data.totalElements,
      };
      state.isSuccess = true;
      state.isError = false;
      state.isLoading = false;
    });
    builder.addCase(getCertificateById.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isAlert = true;
      state.messageAlert = "Failed to get one Certificate Template";
    });

    //CREATE
    builder.addCase(addCertificateTemplate.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(addCertificateTemplate.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.isAlert = true;
      state.messageAlert = "Your Data Added Successful!";
      getAllCertificate();
    });
    builder.addCase(addCertificateTemplate.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isAlert = true;
      state.messageAlert = "Ups! Something went wrong...";
    });

    //UPDATE
    builder.addCase(editCertificateTemplate.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(editCertificateTemplate.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.isAlert = true;
      state.messageAlert = "Success to update Certificate template";
      getAllCertificate();
    });
    builder.addCase(editCertificateTemplate.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isAlert = true;
      state.messageAlert = "Failed to update Certificate Template";
    });

    //DELETE
    builder.addCase(deleteCertificateTemplate.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(deleteCertificateTemplate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.isAlert = true;
      state.messageAlert = action?.payload?.message;
    });
    builder.addCase(deleteCertificateTemplate.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isAlert = true;
      state.messageAlert = "Failed to delete Certificate Template";
    });

    //SERVICE LOOKUP
    builder.addCase(getServiceLookup.pending, (state) => {
      state.isLoadingService = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(getServiceLookup.fulfilled, (state, action) => {
      state.templates = [];
      state.types = [];
      action.payload.template.map((item) => {
        const convertItem = {
          key: item?.id,
          value: item?.name,
          label: item?.name,
        };
        state.templates = [...state.templates, convertItem];
      });

      action.payload.type.map((item) => {
        const convertItem = {
          key: item?.id,
          value: item?.name,
          label: item?.name,
        };
        state.types = [...state.types, convertItem];
      });

      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(getServiceLookup.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isAlert = true;
      state.messageAlert = "Failed to Service Lookup Certificate Template";
    });

    //PREVIEW
    builder.addCase(previewCertificateTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(previewCertificateTemplate.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(previewCertificateTemplate.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isAlert = true;
      state.messageAlert = "Failed to preview Certificate Template";
    });
  },
});

export const {
  resetStateForm,
  changeState,
  overideStateForm,
  changeStatusOverview,
  resetStatusFeedback,
} = certificateTemplateSlice.actions;
export default certificateTemplateSlice.reducer;
