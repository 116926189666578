import { useState, useEffect } from "react";
import { EventService } from "../services/event.service";
import { Certificates } from "./event-details/Certificates";
import { Champions } from "./event-details/Champions";
import { Committee } from "./event-details/Committee";
import { InvitedPeople } from "./event-details/InvitedPeople";
import { Prerequisites } from "./event-details/Prerequisites";
import { TaskToBeDone } from "./event-details/TaskToBeDone";
import AlertMiniPopUp from "./../../../../../pages/admin/componets_global/AlertMiniPopUp";

const eventService = new EventService();

const Divider = () => {
  return <div className="border-b border-[#8B8B8B66]" />;
};

export const EventDetails = ({
  description,
  eventId,
  details,
  status,
  closeModal,
  refetchData,
  uploadAttendance,
  uploadCertificate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });

  async function claimRewardsHandler() {
    const response = await eventService.put.claimRewards(eventId);
    setIsLoading(true);
    try {
      if (response.status == 200) {
        setMessageAlert({
          message: "Rewards claimed successfully!",
          status: true,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
        // closeModal();
      } else {
        setMessageAlert({ message: response?.message, status: false });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {}, [messageAlert]);

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="flex flex-col gap-4 px-2 -mt-2">
        <Divider />
        <p className="text-sm break-all text-justify">{description}</p>
        <div className="flex items-start justify-between gap-4">
          <InvitedPeople people={details.invited_people} total={details.invitedPeopleTotal}/>
          <Committee committee={details.committee} total={details.committeeTotal}/>
        </div>
        <Prerequisites prerequisites={details.prerequisites} />
        <Divider />
        <TaskToBeDone
          tasks={details.tasks}
          showCertificateVerificationUploader={uploadCertificate}
          showAttendanceVerificationUploader={uploadAttendance}
          status={status}
        />
        <Divider />
        <Certificates certificates={details.certificates} />
        <Champions champions={details.champions} />
        {status == "CLAIMABLE" && (
          <button
            className="inline-flex items-center justify-center gap-4 bg-primary rounded py-2 px-3 text-white text-xs font-medium disabled:opacity-50"
            disabled={isLoading}
            onClick={claimRewardsHandler}
          >
            Claim rewards
          </button>
        )}
      </div>
    </>
  );
};
