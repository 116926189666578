import dayjs from "dayjs";
import moment from "moment";
import React from "react";

export const clientHistoryColumns = (
  handleView = (id) => {},
  setProjectTitle
) => {
  return [
    {
      title: "Talent Name",
      dataIndex: ["historyClientTalent", "userRole", "user", "fullname"],
      key: "id",
      align: "center",
    },
    {
      title: "Role Name",
      dataIndex: ["historyClientTalent", "userRole", "roleCompany", "name"],
      key: "id",
      align: "center",
    },
    {
      title: "Client Name",
      dataIndex: ["historyClientTalent", "clientCompany", "name"],
      key: "id",
      align: "center",
    },
    {
      title: "Location",
      dataIndex: ["historyClientTalent", "clientCompany", "location"],
      key: "id",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      align: "center",
      render: (v, row) => {
        const styleData = clientStatusOptions.find(
          (item) => item.value === row.status
        );

        return (
          <div className="flex justify-center">
            {v.toLowerCase() === "active" ? (
              <div
                className={`w-full justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
              >
                {v}
              </div>
            ) : (
              <div className="w-full text-center rounded bg-[#FFCFCF] text-[#DD0000]">
                {v}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{moment(v).format("DD-MMM-YYYY")}</p>;
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{moment(v).format("DD-MMM-YYYY")}</p>;
      },
    },
    {
      title: "Status Talent",
      dataIndex: "statusTalent",
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Total Days",
      dataIndex: "totalDays",
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      align: "center",
      render: (v, row) => {
        return (
          <div>
            <button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                handleView(row.historyClientTalent.id);
                setProjectTitle({
                  clientName: row.historyClientTalent.clientCompany.name,
                  roleName: row.historyClientTalent.userRole.roleCompany.name,
                });
              }}
            >
              View Project
            </button>
          </div>
        );
      },
    },
  ];
};

export const clientHistoryDetailColumns = () => {
  return [
    {
      title: "Talent Name",
      dataIndex: ["historyClientTalent", "userRole", "user", "fullname"],
      key: "id",
      align: "center",
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (v, row) => {
        return <p>{v}</p>;
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      render: (v, row) => {
        return <p>{dayjs(v).format("YYYY-MMM-DD")}</p>;
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      render: (v, row) => {
        return <p>{dayjs(v).format("YYYY-MMM-DD")}</p>;
      },
    },
    {
      title: "Project Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "auto",
      render: (v, row) => {
        return <p>{v}</p>;
      },
    },
    {
      title: "Status Project",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (v, row) => {
        const styleData = statusOptions.find(
          (item) => item.value === row.status
        );
        return (
          <div className="flex justify-center">
            <div
              className={`w-[100px] px-1 justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
            >
              {v}
            </div>
          </div>
        );
      },
    },
  ];
};

export const statusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "SOLVE",
    label: "SOLVE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "HOLD",
    label: "HOLD",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
];

export const clientStatusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "SOLVE",
    label: "SOLVE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "REJECT",
    label: "REJECT",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
];
