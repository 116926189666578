import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getClientList = async (page = 0, size = 0, clientName = "", location = "") => {
    let params = {
        page: page + 1,
        size: size,
        name: clientName,
        location: location,
      };
      const url = `${API_BASE}/client/list?${createSearchParams(
        params
      )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
}

const submitClient = async (body) => {
  const url = `${API_BASE}/client/create`;
  const response = await CallApi({ url, method: "POST", data: body });
  return  response;
}

const updateClient = async (body) => {
  const url = `${API_BASE}/client/update`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return  response;
}

const deleteClient = async (id) => {
  const url = `${API_BASE}/client/delete/${id}`;
  const response = await CallApi({ url, method: "PUT" });
  return  response;
}

const ClientDataMasterRepo = {
    getClientList,
    submitClient,
    updateClient,
    deleteClient
}

export default ClientDataMasterRepo;