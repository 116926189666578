import React, { useEffect } from "react";
import { Modal, Form, Button, Input, DatePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const PlacementTalentHistoryForm = ({
  onClickSubmit = () => {},
  onCancel = () => {},
  show,
  data = undefined,
  isEdit = false,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        testDate: moment(data.testDate),
        clientName: data.clientName,
        feedbackFromPIC: data.feedbackFromPIC,
        feedbackFromTalent: data.feedbackFromTalent,
      });
    }
  }, [data, form]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onClickSubmit({
        id: data?.id || "",
        feedbackFromTalent: values.feedbackFromTalent,
        user: {
          id: values.userId || "",
        },
      });
      form.resetFields();
      onCancel(false);
    });
  };

  // if (!show) {
  //   form.resetFields();
  //   return <></>;
  // }

  return (
    <Modal
      forceRender
      width={900}
      maskClosable={false}
      open={show}
      onCancel={() => {
        onCancel(false);
      }}
      centered
      footer={null}
      closeIcon={true}
      zIndex={999999}
      className="add-form-container-modal"
    >
      <div className="flex flex-col w-full mb-4">
        <p className="text-[20px] font-weight-600 mb-5">
          {isEdit ? "Update Placement History" : null}
        </p>
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Test Date"
            name="testDate"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Input Date",
              },
            ]}
          >
            <DatePicker
              placeholder="Pick test date"
              style={{ width: "100%" }}
              disabled
              format="MM-DD-YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Client Name"
            name="clientName"
            rules={[
              {
                required: true,
                message: "Enter client name",
              },
            ]}
          >
            <Input placeholder="Enter client name" disabled />
          </Form.Item>
          <Form.Item
            label="Feedback From PIC"
            name="feedbackFromPIC"
            rules={[
              {
                required: true,
                message: "Enter feedback from PIC",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Feedback From PIC Here" disabled />
          </Form.Item>
          <Form.Item
            label="Remark By Talent"
            name="feedbackFromTalent"
            rules={[
              {
                required: true,
                message: "Enter feedback by Talent",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Feedback by Talent Here" />
          </Form.Item>
        </Form>
        <div className="flex justify-between">
          <Button
            onClick={handleSubmit}
            className="bg-[#DD0000] text-white w-[200px] py-1 font-bold rounded ms-3"
          >
            {isEdit ? "Update" : null}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PlacementTalentHistoryForm;
