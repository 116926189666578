import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form, Input, Pagination, Table } from "antd";
import LogoRepo from "../../repositores/adminRepo/LogoRepo";
import InterviewClientRepo from "../../repositores/adminRepo/InterviewClientRepo";
import SkeletonTable from "../../components/general/SkeletonTable";
// import TableInterviewCandidate from "../admin/componets_global/TableInterviewCandidate";

const FormInterviewTalent = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { id } = useParams();
  const [logoGlobal, setLogoGlobal] = useState(null);
  const [interviewClientDetail, setInterviewClientDetail] = useState(null);
  const [loadingTable, setLoadingTable] = useState(true);
  const [page, setPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const limit = 10;
  const [listData] = useState(location?.state?.listData || [])
  const [typeForm, setTypeForm] = useState('TALENT')

  const getLogo = async () => {
    try {
      const response = await LogoRepo.getLogoNotToken();
      const logo = response.data?.data?.content;
      setLogoGlobal(logo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  useEffect(() => {
    const fetchInterviewClientDetail = async () => {
      try {
        const response = await InterviewClientRepo.getDetailInterviewClientNoToken(id);
        if(response.data.status === 200 && response.status) {
          setInterviewClientDetail(response.data.data);
        }
        setTotalItem(response.data?.data?.totalElements || 0);
        setLoadingTable(false);
      } catch (error) {
        console.error("Error fetching interview client detail: ", error);
      }
    };

    if (id) {
      fetchInterviewClientDetail();
    }
  }, [id]);

  useEffect(() => {
    setTypeForm(interviewClientDetail?.type)
    form.setFieldsValue({
      interviewerName: interviewClientDetail?.interviewerName,
      talent: interviewClientDetail?.talent?.fullname,
      candidateName: interviewClientDetail?.candidateName,
      account: interviewClientDetail?.retroInterviewClient?.account?.name,
      position: interviewClientDetail?.retroInterviewClient?.role?.name,
    });
  }, [form, interviewClientDetail]);

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: '10%',
      render: (_, row) => {
        const number = row?.retroInterviewClientDetail?.noQuestion || '-';
        return <div>{number}</div>
      },
    },
    {
      title: "Question",
      dataIndex: "retroInterviewClientDetail",
      key: "retroInterviewClientDetail",
      align: "center",
      render: (v, row) => {
        const question = row?.retroInterviewClientDetail?.question || '-';
        return (
          <div style={{maxHeight: '100px', overflow: 'auto'}}>
            <p className="text-start">{question}</p>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page) => {
    setPage(page - 1);
  };

  return (
    <>
      <div className="flex justify-center">
        {logoGlobal?.map((item) => (
          <img key={item.id} alt={item.id} src={item.name} className="mb-8" />
        ))}
      </div>
      <div className="text-2xl md:text-3xl font-bold text-[#4C4C4C] mb-6">
        Retro Interview
      </div>
      <Form
        form={form}
        colon={false}
        labelAlign="left"
        labelCol={{ span: 8 }}
        style={{ maxWidth: 700 }}
        autoComplete="off"
        initialValues={interviewClientDetail}
        className="pointer-events-none"
      >
        <Form.Item label="User / Interview" name="interviewerName">
          <Input placeholder="Enter User Interview" />
        </Form.Item>
        <Form.Item label="Talent Name" name="talent" hidden={typeForm !== "TALENT"}>
          <Input placeholder="Enter Talent Name" />
        </Form.Item>
        <Form.Item label="Candidate Name" name="candidateName" hidden={typeForm === "TALENT"}>
          <Input placeholder="Enter Candidate Name" />
        </Form.Item>
        <Form.Item label="Account" name="account">
          <Input placeholder="Enter Account Name" />
        </Form.Item>
        <Form.Item label="Position" name="position">
          <Input placeholder="Enter Position" />
        </Form.Item>
        <h3 className="mb-3 text-start">List Retro Question</h3>
      </Form>
      <div style={{maxHeight: '300px'}}>
        <TableInterviewCandidate
          dataSource={interviewClientDetail?.retroInterviewCandidateDetails}
          columns={columns}
          isLoading={loadingTable}
        />
      </div>
      <div className="w-full flex justify-center my-5">
        <Pagination
          current={page + 1}
          onChange={onChangePage}
          pageSize={limit}
          total={totalItem}
          showSizeChanger={false}
        />
      </div>
    </>
  );
};

const TableInterviewCandidate = ({ dataSource, columns, isLoading }) => {
  if (isLoading) {
    return <SkeletonTable />;
  }
  return (
    <div className="mt-[10px] ">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size="small"
        rowKey="id"
      />
    </div>
  );
};

export default FormInterviewTalent;
