import React, { useContext, useEffect, useState } from "react";
import { Card, Row, Col, Button } from "antd";
import DashboardTalentRepo from "../../../../repositores/talentRepo/DashboardTalentRepo";
import { useNavigate } from "react-router-dom";
import RouteTalent from "../../../../services/RouteTalent";
import { TalentHelperContext } from "../../../../components/talentPage/TalentContext";
import { changeState } from "../../../../features/certificateTalentSlice";
import { useDispatch } from "react-redux";
const host = process.env.REACT_APP_BASE;

const TalentJourney = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userJourney, setUserJourney] = useState({
    allJourney: 0,
  });
  const [onGoing, setOnGoing] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const { selectMenu } = useContext(TalentHelperContext);
  const getDataTalent = async () => {
    try {
      const { data } = await DashboardTalentRepo.getDashboardTalent();
      const { totalAllJourney } = data.data;
      setUserJourney((uJourney) => ({
        ...uJourney,
        allJourney: totalAllJourney,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getJourneyTalent = async () => {
    try {
      const { data } = await DashboardTalentRepo.getTalentJourney();
      const { content } = data.data;
      setOnGoing(data.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCertificate = async () => {
    try {
      const { data } = await DashboardTalentRepo.getCertificateAll();
      const { content } = data.data;
      setCertificateList(data.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const callMycertificate = () => {
    dispatch(changeState({ stateProp: "activeTab", valueProp: "certificate" }));
    navigate(RouteTalent.certificate);
  };

  function formatDateTime(date) {
    let tempDate = new Date(date);

    let dateFormated = `${tempDate.getDate()} ${getMonthName(
      tempDate.getMonth()
    )} ${tempDate.getFullYear()}`;
    let timeFormated = `${addLeadingZero(tempDate.getHours())}:${addLeadingZero(
      tempDate.getMinutes()
    )}:${addLeadingZero(tempDate.getSeconds())}`;
    return `${dateFormated}, ${timeFormated}`;
  }

  function addLeadingZero(number) {
    return number < 10 ? `0${number}` : `${number}`;
  }

  function getMonthName(month) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month];
  }

  useEffect(() => {
    getDataTalent();
    getJourneyTalent();
    //getIssuedCertificateTalent();
    //getRevokedCertificate();
    getAllCertificate();
    selectMenu("dashboard");
  }, []);

  return (
    <>
      <div>
        <Row gutter={[16, 16]}>
          <Col md={9} span={24}>
            <p className="text-xl font-bold mb-8">My Journey</p>
            <Card className="card-shadow">
              <div className="flex justify-between flex-row-reverse md:block">
                <div className="flex justify-center">
                  <img
                    src={`/assets/images/journey.png`}
                    alt={`journey`}
                    className="w-[8rem] h-[8rem] md:w-[200px] md:h-[200px]"
                  />
                </div>
                <div className="border p-[20px] flex-col gap-[10px] card-shadow  hidden md:block">
                  <p className="text-lg font-semibold">Total Finished Events</p>
                  <Row
                    gutter={[8, 5]}
                    className="hidden md:flex w-[200px] md:w-full"
                  >
                    <Col span={24}>
                      <p className="text-[#070A52] text-xl md:text-4xl font-bold">
                        {userJourney.allJourney}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="flex flex-col justify-center md:hidden gap-3">
                  <p className="text-lg font-semibold block md:hidden">
                    Total Finished Events
                  </p>
                  <p className="text-[#070A52] text-3xl md:text-4xl font-bold">
                    {userJourney.allJourney}
                  </p>
                </div>
              </div>

              <p className="mt-2 text-lg font-semibold mb-5">Ongoing Events</p>

              {/* ongoingEvent */}
              <ul>
                {onGoing.slice(0, 3).map((ongoing) => (
                  <li key={ongoing.id} style={{ marginBottom: "1.2rem" }}>
                    <Card className="card-shadow">
                      <div className="flex justify-between items-center">
                        <div className="p-2 text-xs border rounded-md flex justify-center items-center">
                          {ongoing.masterEvent.eventType.name}
                        </div>
                        <p className="text-xs">
                          {ongoing.masterEvent.journey.name}
                        </p>
                      </div>
                      <p className="text-[#000000] text-lg font-bold mt-3">
                        {ongoing.name}
                      </p>
                      <p className="text-[#A8A8A8] text-xs">
                        3 out of 5 task completed.
                      </p>
                      <div className="flex justify-between items-end">
                        <p className="text-[#000000] text-xs font-semibold mt-3">
                          Rewards
                        </p>
                        <div className="flex items-center">
                          <img
                            src={`/assets/icons/coin.png`}
                            alt={`coin`}
                            className="w-4 h-4"
                          />
                          <p className="text-[#FE9923] ml-2">
                            {ongoing.masterEvent.gc} Coin
                          </p>
                          <p className="text-[#4F40F4] ml-3 mr-1">XP</p>
                          <p className="text-[#4F40F4]">
                            {ongoing.masterEvent.xp}
                          </p>
                        </div>
                      </div>
                      <Button
                        className="mt-[1.2rem] w-full"
                        style={{ background: "#DD0000", color: "white" }}
                        onClick={() =>
                          navigate(`/talent/journey/events/${ongoing.id}`, {
                            state: {
                              journeyId:
                                ongoing?.masterEvent?.journey?.id || "",
                            },
                          })
                        }
                      >
                        Continue
                      </Button>
                    </Card>
                  </li>
                ))}
              </ul>
            </Card>
          </Col>
          <Col md={15} span={24}>
            <div className="flex justify-between font-weight-700  text-dark-grey items-center md:mb-[20px]">
              <p className="text-primary-20">Recent Certificates</p>
              <button onClick={callMycertificate}>
                <p className="text-size-16">View All</p>
              </button>
            </div>
            <div className="bg-transparent border-0 m-0 p-0">
              <Row>
                <Col span={24}>
                  <ul>
                    {certificateList.slice(0, 7).map((issued) => (
                      <li key={issued.id} style={{ marginBottom: "1.2rem" }}>
                        <div className="recent-certificate-item">
                          {issued?.revokeBy ? (
                            <div
                              className="bg-white flex justify-center items-center card-shadow w-[68px] h-[68px]"
                              style={{
                                borderRadius: "10px",
                              }}
                            >
                              <img
                                src={`/assets/icons/revoked-icon.svg`}
                                className="w-auto h-auto"
                                alt="icon-revoked"
                              />
                            </div>
                          ) : (
                            <div
                              className="card-shadow rounded-[10px] w-[68px] h-[68px]"
                              alt="image"
                            >
                              {/* <img 
                            src={`${host}api/v1/certificate/generate/${issued.id}`}
                            className="w-full h-full border-0"
                            /> */}
                              <img
                                //src={`${host}api/v1/certificate/generate/${issued.id}`}
                                src="https://img.freepik.com/free-vector/gradient-golden-luxury-certificate-template_23-2149031875.jpg?w=996&t=st=1694528764~exp=1694529364~hmac=13c9596852895dc9f6b52438fb9a0e711b2cc6e833f5b1bbe1ca6eff07a8bb61"
                                className="h-full w-full rounded-[10px]"
                                alt="icon"
                              />
                            </div>
                          )}
                          <div className="flex flex-col justify-between w-full">
                            <div className="w-full">
                              <p className="text-dark-grey text-lg font-bold mt-3">
                                {issued.certificate.title}
                              </p>
                            </div>
                            <div
                              className={`w-full flex justify-between flex-row `}
                            >
                              <div className="flex justify-between w-full">
                                <p className="text-xs mt-2 mb-1 text-grey font-weight-500 text-size-16">
                                  {formatDateTime(issued.created_date)}
                                </p>
                              </div>
                              <p
                                className={`text-size-16 font-weight-500 ${
                                  issued?.revokeBy
                                    ? "text-primary"
                                    : "text-dark-grey"
                                }`}
                              >
                                {issued?.revokeBy ? "Revoked" : "Issued"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <p className="text-lg font-semibold mb-5">
                  Revoked Certificate
                </p>
                <button
                  // onClick={() => navigate(RouteTalent.certificate)}
                  onClick={callRevokeCertificate}
                  component={"My Reward"}
                >
                  <p className="text-[#070A52] mb-5 text-sm font-semibold">
                    View All
                  </p>
                </button>
              </div>
              <Row>
                <Col span={24}>
                  <ul>
                    {revokedCertificate.slice(0, 3).map((revoked) => (
                      <li key={revoked.id} style={{ marginBottom: "1.2rem" }}>
                        <Card>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="p-2 text-xs"
                              style={{
                                border: "1px solid #E0E0E0",
                                borderRadius: "0.5rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {revoked.certificate.typeCertificate.name}
                            </div>
                            <p className="text-[#A8A8A8] text-xs font-semibold mt-2 mb-1">
                              {revoked.certificate.certificate_id}
                            </p>
                          </div>
                          <p className="text-[#070A52] text-lg font-bold mt-3">
                            {revoked.certificate.title}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p className="text-[#A8A8A8] text-xs mt-2 mb-1">
                              From '{revoked.event.name}'
                            </p>
                            <p className="text-xs mt-2 mb-1">
                              Revoked at {revoked.certificate.expiredDate}
                            </p>
                          </div>
                        </Card>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row> */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TalentJourney;
