import { EventCertificate } from "../EventCertificate";
import { DetailContainer } from "./DetailContainer";

export const Certificates = ({ certificates }) => {
  return (
    <DetailContainer title={"Certificates"}>
      <div className="flex flex-wrap items-center gap-1">
        {certificates?.length ? (
          certificates.map((certificate, key) => (
            <EventCertificate
              key={`${key}-${certificate.id}`}
              certificateName={certificate.name}
            />
          ))
        ) : (
          <p className="text-black text-xs font-medium">-</p>
        )}
      </div>
    </DetailContainer>
  );
};
