import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CallApi from "../services/Request-Helper";

const host = process.env.REACT_APP_BASE;
const initialState = {
  formSearch: "",
  maxSizeData: 6,
  currentPage: 0,
  lastPage: 0,
  totalItem: 0,
  previousPage: false,
  nextPage: false,
  activeTab: "certificate",
  selectedStatusIssued: "",
  selectedStatusRevoked: "",
  certificatesIssued: [],
  certificatesRevoked: [],
  types: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  isAlert: false,
  message: "",
};

export const getAllTalentCertificateIssued = createAsyncThunk(
  "talent/certificate-issued",
  async (input, thunkApi) => {
    try {
      const { current_page, type, keyword } = input;
      const response = await CallApi({
        url: `${host}api/v1/issued-certificate/user?page=${current_page}&size=${
          initialState.maxSizeData
        }${type ? "&TYPE_CERTIFICATE=" + type : ""}${
          input?.keyword ? "&eventName=" + keyword : ""
        }`,
        method: "GET",
      });
      if (response.data.status === 200) {
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get all Certificate Issued";
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getAllTalentCertificateRevoked = createAsyncThunk(
  "talent/certificate-revoked",
  async (input, thunkApi) => {
    try {
      const { current_page, type, keyword } = input;
      const response = await CallApi({
        url: `${host}api/v1/issued-certificate/user-revoke?page=${current_page}&size=${
          initialState.maxSizeData
        }${type ? "&TYPE_CERTIFICATE=" + type : ""}${
          keyword ? "&eventName=" + keyword : ""
        }`,
        method: "GET",
      });
      if (response.data.status === 200) {
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get all Certificate Issued";
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getServiceLookup = createAsyncThunk(
  "tealent/certificate-talent/service",
  async (thunkAPi) => {
    try {
      const response = await CallApi({
        url: `${host}api/v1/lookup/list?page=0&size=10&type=type_CERTIFICATE`,
        method: "GET",
      });
      if (response.data.status === 200) {
        return response.data.data.content;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get type certificate";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const certificateTalentSlice = createSlice({
  name: "certificateTalent",
  initialState,
  reducers: {
    changeState: (state, action) => {
      state[action.payload.stateProp] = action.payload.valueProp;
    },
    resetPageProperty: (state) => {
      state.currentPage = 0;
      state.lastPage = 0;
      state.previousPage = false;
      state.nextPage = false;
    },
  },
  extraReducers: (builder) => {
    //FIND ALL ISSUED
    builder.addCase(getAllTalentCertificateIssued.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllTalentCertificateIssued.fulfilled,
      (state, action) => {
        state.certificatesIssued = [];
        state.certificatesRevoked = [];
        const originalData = action.payload?.data?.content;
        originalData.map((item) => {
          const convertItem = {
            id: item?.id,
            key: item?.certificate?.certificate_id,
            title: item?.certificate?.title,
            type: item?.certificate?.typeCertificate?.name,
            eventName: item?.event?.name,
            issuedDate: item?.issuedDate,
            expiredDate: item?.certificate?.expiredDate,
            image:
              item?.certificate?.urlImage ||
              "https://img.freepik.com/free-vector/gradient-golden-luxury-certificate-template_23-2149031875.jpg?w=996&t=st=1694528764~exp=1694529364~hmac=13c9596852895dc9f6b52438fb9a0e711b2cc6e833f5b1bbe1ca6eff07a8bb61",
          };
          state.certificatesIssued = [...state.certificatesIssued, convertItem];
        });
        state.isLoading = false;
        state.isSuccess = true;
        // state.isError = false;
        state.currentPage = action.payload?.data?.number;
        state.lastPage = action.payload?.data?.totalPages;
        state.previousPage = state.currentPage - 1 >= 0;
        state.nextPage = state.currentPage + 1 < state.lastPage;
        state.totalItem = action.payload?.data?.totalElements;
      }
    );
    builder.addCase(getAllTalentCertificateIssued.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.isAlert = true;
      state.message = "Failed to get all your Certificate issued";
    });

    //FIND ALL REVOKED
    builder.addCase(getAllTalentCertificateRevoked.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllTalentCertificateRevoked.fulfilled,
      (state, action) => {
        state.certificatesRevoked = [];
        state.certificatesIssued = [];
        const originalData = action.payload?.data?.content;
        originalData.map((item) => {
          const convertItem = {
            id: item?.id,
            key: item?.certificate?.certificate_id,
            title: item?.certificate?.title,
            type: item?.certificate?.typeCertificate?.name,
            eventName: item?.event?.name,
            issuedDate: item?.issuedDate,
            expiredDate: item?.expiredDate,
            revokeDate: item?.revokeDate,
            reason: item?.revokeReason,
            image:
              item?.certificate?.urlImage ||
              "https://img.freepik.com/free-vector/gradient-golden-luxury-certificate-template_23-2149031875.jpg?w=996&t=st=1694528764~exp=1694529364~hmac=13c9596852895dc9f6b52438fb9a0e711b2cc6e833f5b1bbe1ca6eff07a8bb61",
          };
          state.certificatesRevoked = [
            ...state.certificatesRevoked,
            convertItem,
          ];
        });
        state.isLoading = false;
        state.isSuccess = true;
        // state.isError = false;
        state.currentPage = action.payload?.data?.number;
        state.lastPage = action.payload?.data?.totalPages;
        state.previousPage = state.currentPage - 1 >= 0;
        state.nextPage = state.currentPage + 1 < state.lastPage;
        state.totalItem = action.payload?.data?.totalElements;
      }
    );
    builder.addCase(getAllTalentCertificateRevoked.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.isAlert = true;
      state.message = "Failed to get all your Certificate issued";
    });

    //SERVICE LOOKUP
    builder.addCase(getServiceLookup.pending, (state) => {
      state.isLoadingService = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(getServiceLookup.fulfilled, (state, action) => {
      state.isSuccess = true;
      state.types = [];
      action.payload.map((item) => {
        const convertItem = {
          key: item?.id,
          value: item?.name,
          label: item?.name,
        };
        state.types = [...state.types, convertItem];
      });

      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(getServiceLookup.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isAlert = true;
      state.message = "Failed to Service Lookup Certificate Template";
    });
  },
});

export const { changeState, resetPageProperty } =
  certificateTalentSlice.actions;
export default certificateTalentSlice.reducer;
