import React from 'react'

const ButtonAddRewards = ({ onClick }) => {
  return (
    <div>
      <div className='bg-red-600 w-[165px] ml-auto text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[30px] font-bold' onClick={onClick}>Add Data</div>
    </div>
  )
}

export default ButtonAddRewards