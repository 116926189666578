import { Modal, Button, Form, Input } from "antd";
import React from "react";
const { TextArea } = Input;

const InputForm = ({
  open,
  handleClose,
  handleSubmit = () => {},
  handleEdit = () => {},
  handleDelete = () => {},
  purpose = "new",
  roleName = "",
  description = "",
  type="",
  clientId = "",
  setdescriptionInputForm = (val) => {},
  setTypeInputForm=() => {},
  setRoleNameInputForm = (val) => {},
}) => {
  const [form] = Form.useForm();
  const purposeLabeling = {
    new: "New",
    edit: "Edit",
    delete: "Delete",
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        width={500}
        footer={null}
        closable={true}
        title={`${purposeLabeling[purpose]} Role`}
        destroyOnClose={true}
      >
        <div>
          <Form
            className="w-full"
            labelCol={{
              span: 9,
            }}
            preserve={false}
            form={form}
            autoComplete="off"
            onFinish={(e) => {
              if (purpose === "edit") {
                handleEdit(e.name, e.type, e.description);
              } else if (purpose === "delete") {
                handleDelete(clientId);
              } else {
                handleSubmit(e.name, e.type, e.description);
              }
              setdescriptionInputForm("");
              setRoleNameInputForm("");
              setTypeInputForm("")
            }}
          >
            {purpose !== "delete" && (
              <>
                <div className=" overflow-auto h-auto w-full my-3">
                  <Form.Item
                    name="name"
                    label="Name"
                    initialValue={roleName}
                    className=""
                    labelAlign="left"
                  >
                    <Input
                      type="text"
                      placeholder="Write role name"
                      name="name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="type"
                    label="Group"
                    initialValue={type}
                    className=""
                    labelAlign="left"
                  >
                    <Input
                      type="text"
                      placeholder="Write role type"
                      name="Type"
                    />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="Description"
                    initialValue={description}
                    className=""
                    labelAlign="left"
                  >
                    <TextArea
                      rows={4}
                      placeholder="Write role description"
                      name="description"
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {purpose === "delete" && <>
            <p>hapus client dengan nama <span className="font-bold">{roleName}</span>?</p>
            </>}
            <div className="flex justify-end mt-5 gap-[10px]">
              <Button onClick={() => handleClose()}>No</Button>
              <Button type="primary" htmlType="submit">
                Yes
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default InputForm;
