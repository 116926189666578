import { Collapse, Table } from "antd";

const { Panel } = Collapse;

export const CollapseComponent = ({ headerData, bodyData, columns }) => {
  return (
    <div className="talent-journey-collapse">
      <Collapse expandIconPosition="end">
        {headerData.map((header, i) => (
          <Panel header={header.termin.description} key={header.termin.id}>
            <Table
              columns={columns}
              dataSource={header.matkul}
              pagination={false}
              sticky={true}
              scroll={{
                y: 470,
                x: 1000,
              }}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
