export const DetailContainer = ({ title, children, slot }) => {
  return (
    <section className="flex flex-col gap-2">
      <header className="flex items-center gap-8">
        <h2 className="text-black text-xs font-medium">{title}</h2>
        {slot && slot.header}
      </header>
      <section>{children}</section>
    </section>
  );
};
