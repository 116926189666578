import CallApi from "../../services/Request-Helper";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const getDashboardTotal = async () => {
    const url = `${API_BASE}/event/dashboard-total`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}
const getTopLeaderboard = async (size = 10) => {
    const url = `${API_BASE}/event-participant-task/top-leaderboard?size=${size}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}
const getDetailLeaderboard = async (id) => {
    const url = `${API_BASE}/event-participant-task/top-leaderboard-detail/${id}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getCoinHistory = async (page = 0, size = 100, id) => {
    const url = `${API_BASE}/redeem-coins/list-history-coin?page=${page}&size=${size}&userId=${id}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const MasterDashboardRepo = {
    getDashboardTotal,
    getTopLeaderboard,
    getDetailLeaderboard,
    getCoinHistory
}

export default MasterDashboardRepo