import React, { forwardRef, useImperativeHandle, useState, useEffect, } from "react";
import { Form, Input, Select } from "antd";
import AlertUtility from "../../../../components/Alert/Alert";
import AdminLmsRepo from "../../../../repositores/adminRepo/LmsRepo";

const { Option } = Select;
const FormMataKuliah = forwardRef((props, ref) => {
    const [formMatkul] = Form.useForm();

    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(100);
    const [optionsLevel, setOptionsLevel] = useState([]);
    const [totalElementLevel, setTotalElementLevel] = useState(-1);
    const [onSearchOption, setOnSearchOption] = useState(false)

    useImperativeHandle(ref, () => ({
      validateFields() { 
        return formMatkul.validateFields()
      },
      getFieldValue(name) {
        return formMatkul.getFieldValue(name)
      },
      resetForm() {
        formMatkul.resetFields()
      }

    }));

    useEffect(() => {
        setPage(1)
        handleFetchMore(1)
        if(props.formType === 'edit') {
            formMatkul.setFieldValue('level', props?.dataMatkul?.level?.id)
            formMatkul.setFieldValue('matkul', props?.dataMatkul?.matkul)
            formMatkul.setFieldValue('matkulNo', props?.dataMatkul?.matkulNo)
            formMatkul.setFieldValue('termin', props?.dataMatkul?.termin)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleScroll = (event) => {
        event.stopPropagation();
    
        if (!loadingMore && !onSearchOption) {
          handleFetchMore(page);
        }
    };

    const handleFetchMore = async (page) => {
        if (
            page - 1 <= totalPage &&
            optionsLevel?.length !== totalElementLevel
        ) {
            setLoadingMore(true);
            try {
                const response = await AdminLmsRepo.getListlevel(page, 20, undefined);
                let tempOptions = optionsLevel;
                if (response.data?.data?.content?.length) {
                await response.data?.data?.content?.forEach((typeEvent) => {
                    tempOptions.push(typeEvent);
                });
                setOptionsLevel(tempOptions);
                setTotalPage(response.data?.data?.totalPages);
                setTotalElementLevel(response.data?.data?.totalElements);
                }
                setPage(page + 1);
                setLoadingMore(false);
                setOnSearchOption(false);
            } catch (error) {
                AlertUtility.ErrorAlert({ title: "Failed Get Data" });
                setLoadingMore(false);
            }
        } 
    };

    

    return (
        <>
            <Form
                labelCol={{ flex: "150px" }}
                labelAlign="left"
                form={formMatkul}
                colon={false}
                wrapperCol={{ flex: 1 }}
            >
                <Form.Item
                    label="Level"
                    name="level"
                    rules={[
                        {
                            required: true,
                            message: "Please select level",
                        },
                    ]}
                >
                    <Select
                        style={{ width: "100%" }}
                        placeholder="Choose level"
                        dropdownRender={(menu) => (
                            <div onWheel={handleScroll}>{menu}</div>
                        )}
                        loading={loadingMore}
                        filterOption={false}
                        defaultActiveFirstOption={false}
                    >
                        {
                            loadingMore ? 
                            <>
                                <Option disabled>Please wait...</Option>
                            </>
                            : 
                                optionsLevel.map((i) => (
                                    <Option key={i.id} value={i.id}>
                                        {i.name}
                                    </Option>
                                ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Termin"
                    name="termin"
                    rules={[
                        {
                            required: true,
                            message: "Please input termin",
                        },
                    ]}
                >
                    <Input placeholder="Please input termin" type="number" min={0} />
                </Form.Item>
                <Form.Item
                    label="Mata Kuliah Number"
                    name="matkulNo"
                    rules={[
                        {
                            required: true,
                            message: "Please input Mata Kuliah Number",
                        },
                    ]}
                >
                    <Input placeholder="Please input Mata Kuliah Number" type="number" min={0}/>
                </Form.Item>

                <Form.Item
                    label="Mata Kuliah"
                    name="matkul"
                    rules={[
                        {
                            required: true,
                            message: "Please input Mata Kuliah",
                        },
                    ]}
                >
                    <Input placeholder="Please input Mata Kuliah" />
                </Form.Item>
            </Form>
        </>
    )
})

export default FormMataKuliah