import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Modal, Select } from "antd";
import dayjs from "dayjs";
import AlertUtility from "../../../components/Alert/Alert";
import InterviewClientRepo from "../../../repositores/adminRepo/InterviewClientRepo";
import RoleSkillTalent from "../../../repositores/talentRepo/RoleSkillTalentRepo";
const { RangePicker } = DatePicker;
const { Option } = Select;

const FormClientHistory = ({
  show,
  closeModalClient,
  defaultValue,
  type,
  doSubmit,
}) => {
  const [formClientHistory] = Form.useForm();

  const [loadingMoreClient, setLoadingMoreClient] = useState(false);
  const [pageClient, setPageClient] = useState(1);
  const [totalPageClient, setTotalPageClient] = useState(100);
  const [optionsClient, setOptionsClient] = useState([]);
  const [totalElementClient, setTotalElementClient] = useState(-1);
  const [onSearchOptionClient, setOnSearchOptionClient] = useState(false);
  const [searchOptionClient, setSearchOptionClient] = useState("");
  const [debounceOptionClient, setDebounceOptionClient] = useState(undefined);
  const [debounceScrollOptionClient, setDebounceScrollOptionClient] =
    useState(undefined);

  const [loadingMoreRole, setLoadingMoreRole] = useState(false);
  const [pageRole, setPageRole] = useState(1);
  const [totalPageRole, setTotalPageRole] = useState(100);
  const [optionsRole, setOptionsRole] = useState([]);
  const [totalElementRole, setTotalElementRole] = useState(-1);
  const [onSearchOptionRole, setOnSearchOptionRole] = useState(false);
  const [searchOptionRole, setSearchOptionRole] = useState("");
  const [debounceOptionRole, setDebounceOptionRole] = useState(undefined);
  const [debounceScrollOptionRole, setDebounceScrollOptionRole] =
    useState(undefined);

  useEffect(() => {
    // setPageClient(1)
    // handleFetchMoreClient(1)
    // setPageRole(1)
    // handleFetchMoreRole(1)
    if (type === "edit") {
      formClientHistory.setFieldValue("role", defaultValue?.role?.id);
      formClientHistory.setFieldValue("client", defaultValue?.client?.id);
      formClientHistory.setFieldValue("date", defaultValue?.dates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageClient(1);
    handleFetchMoreClient(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptionClient]);
  useEffect(() => {
    setPageRole(1);
    handleFetchMoreRole(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptionRole]);

  const handleScrollClient = (event) => {
    let pos =
      (event.target.scrollTop || event.target.scrollTop) +
      event.target.offsetHeight;
    let max = 32;
    if (pos === max) {
      if (
        pageClient - 1 <= totalPageClient &&
        optionsClient?.length !== totalElementClient
      ) {
        setLoadingMoreClient(true);
      }
      clearTimeout(debounceScrollOptionClient);
      setDebounceScrollOptionClient(
        setTimeout(() => {
          event.stopPropagation();
          if (!onSearchOptionClient) {
            handleFetchMoreClient(pageClient);
          }
        }, 2000)
      );
    }
  };

  const handleFetchMoreClient = async (page) => {
    if (
      page - 1 <= totalPageClient &&
      optionsClient?.length !== totalElementClient
    ) {
      // setLoadingMoreClient(true);
      try {
        const response = await InterviewClientRepo.getListAccount(
          page - 1,
          20,
          searchOptionClient
        );
        let tempOptions = optionsClient;
        if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
            tempOptions.push(typeEvent);
          });
          setOptionsClient(tempOptions);
          setTotalPageClient(response.data?.data?.totalPages);
          setTotalElementClient(response.data?.data?.totalElements);
        }
        setPageClient(page + 1);
        setLoadingMoreClient(false);
        setOnSearchOptionClient(false);
      } catch (error) {
        AlertUtility.ErrorAlert({ title: "Failed Get Data" });
        setLoadingMoreClient(false);
      }
    }
  };

  const handleScrollRole = (event) => {
    let pos =
      (event.target.scrollTop || event.target.scrollTop) +
      event.target.offsetHeight;
    let max = 32;
    if (
      pageRole - 1 <= totalPageRole &&
      optionsRole?.length !== totalElementRole
    ) {
      setLoadingMoreRole(true);
    }
    if (pos === max) {
      clearTimeout(debounceScrollOptionRole);
      setDebounceScrollOptionRole(
        setTimeout(() => {
          event.stopPropagation();
          if (!onSearchOptionRole) {
            handleFetchMoreRole(pageRole);
          }
        }, 2000)
      );
    }
  };

  const handleFetchMoreRole = async (page) => {
    if (page - 1 <= totalPageRole && optionsRole?.length !== totalElementRole) {
      // setLoadingMoreClient(true);
      try {
        const response = await RoleSkillTalent.getListRolesEmployee(
          page - 2,
          20,
          searchOptionRole
        );
        let tempOptions = optionsRole;
        if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
            tempOptions.push(typeEvent);
          });
          setOptionsRole(tempOptions);
          setTotalPageRole(response.data?.data?.totalPages);
          setTotalElementRole(response.data?.data?.totalElements);
        }
        setPageRole(page + 1);
        setLoadingMoreRole(false);
        setOnSearchOptionRole(false);
      } catch (error) {
        AlertUtility.ErrorAlert({ title: "Failed Get Data" });
        setLoadingMoreRole(false);
      }
    }
  };

  const handleSubmit = () => {
    formClientHistory
      .validateFields()
      .then(() => {
        let payload = {
          clientCompany: {
            id: formClientHistory.getFieldValue("client"),
          },
          userRole: {
            id: formClientHistory.getFieldValue("role"),
          },
          startDate: dayjs(formClientHistory.getFieldValue("date")[0]).format(
            "YYYY-MM-DD"
          ),
          endDate: dayjs(formClientHistory.getFieldValue("date")[1]).format(
            "YYYY-MM-DD"
          ),
        };
        doSubmit(payload);
      })
      .catch(() => {});
  };

  const closeModal = () => {
    closeModalClient();
  };

  const doSearchClient = (input) => {
    setOnSearchOptionClient(true);
    // setLoadingMoreClient(true)
    clearTimeout(debounceOptionClient);
    setDebounceOptionClient(
      setTimeout(() => {
        setOptionsClient([]);
        setSearchOptionClient(input);
      }, 2000)
    );
  };

  const doSearchRole = (input) => {
    setOnSearchOptionRole(true);
    // setLoadingMoreRole(true)
    clearTimeout(debounceOptionRole);
    setDebounceOptionRole(
      setTimeout(() => {
        setOptionsRole([]);
        setSearchOptionRole(input);
      }, 2000)
    );
  };

  return (
    <>
      <Modal
        open={show}
        onCancel={() => {
          closeModal();
        }}
        footer={
          <>
            <div className="flex justify-between mt-[20px]">
              <Button
                key="back"
                danger
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#DD0000", color: "white" }}
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </div>
          </>
        }
      >
        <div className="mb-8 font-medium">
          <div className="text-xl text-[#4C4C4C]">
            {type === "add" ? "Add" : "Edit"} Data{" "}
          </div>
        </div>
        <div className="my-[30px]">
          <Form
            labelCol={{ flex: "100px" }}
            labelAlign="left"
            form={formClientHistory}
            colon={false}
            wrapperCol={{ flex: 1 }}
          >
            <Form.Item
              label="Client"
              name="client"
              rules={[
                {
                  required: true,
                  message: "Please select client",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Choose Client"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollClient}>{menu}</div>
                )}
                loading={loadingMoreClient || onSearchOptionClient}
                showSearch
                filterOption={false}
                onSearch={(input) => {
                  doSearchClient(input);
                }}
                defaultActiveFirstOption={false}
              >
                {onSearchOptionClient ? (
                  <>
                    <Option disabled value="onload search">
                      Please wait...
                    </Option>
                  </>
                ) : (
                  optionsClient.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))
                )}
                {loadingMoreClient ? (
                  <>
                    <Option disabled value="onload scroll">
                      Please wait...
                    </Option>
                  </>
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>

            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select role",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Choose Role"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollRole}>{menu}</div>
                )}
                loading={loadingMoreRole || onSearchOptionRole}
                showSearch
                filterOption={false}
                onSearch={(input) => {
                  doSearchRole(input);
                }}
                defaultActiveFirstOption={false}
              >
                {onSearchOptionRole ? (
                  <>
                    <Option disabled>Please wait...</Option>
                  </>
                ) : (
                  optionsRole.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.roleCompany.name}
                    </Option>
                  ))
                )}
                {loadingMoreRole ? (
                  <>
                    <Option disabled value="onload scroll">
                      Please wait...
                    </Option>
                  </>
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select start date and end date",
                },
              ]}
            >
              <RangePicker className="w-full" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default FormClientHistory;
