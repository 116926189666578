import React from "react";
import { Input } from "antd";

const ActionBarMaster = ({ onSearch, placeholder }) => {
  return (
    <Input
      className="w-full lg:w-[300px] max-w-[400px]"
      placeholder={placeholder}
      suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
      onChange={onSearch}
    />
  );
};

export default ActionBarMaster;
