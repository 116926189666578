import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Modal, Select, Input } from "antd";
import { statusOptions } from "./column.state";
import dayjs from "dayjs";
import AlertUtility from "../../../components/Alert/Alert";
import ProjectHistoryRepo from "../../../repositores/talentRepo/ClientHistoryTalentRepo";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

export const AddForm = ({
  show,
  closeModal,
  loading = false,
  handleAdd = () => {},
  handleEditForm = (f, v) => {},
  editingForm,
  handleEdit,
  isEditing,
  handleResetForm,
  projectList,
  handleLoadMore = () => {},
  handleProjectSearch = (v) => {},
  typeOptions,
}) => {
  const [errorMessages, setErrorMessage] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResetToggle, setSearchResetToggle] = useState(false);
  const [totalUserPoint, setTotalUserPoint] = useState(true);

  const [loadingMoreClient, setLoadingMoreClient] = useState(false);
  const [pageClient, setPageClient] = useState(1);
  const [totalPageClient, setTotalPageClient] = useState(100);
  const [optionsClient, setOptionsClient] = useState([]);
  const [totalElementClient, setTotalElementClient] = useState(-1);
  const [onSearchOptionClient, setOnSearchOptionClient] = useState(false)
  const [searchOptionClient, setSearchOptionClient] = useState("")
  const [debounceOptionClient, setDebounceOptionClient] = useState(undefined)
  const [debounceScrollOptionClient, setDebounceScrollOptionClient] = useState(undefined)


  // makes for onBlur
  const validateEmpty = (editingForm) => {
    const errObj = {};
    Object.entries(editingForm).reduce((errors, [key, value]) => {
      if (value === null || value === undefined || value === "") {
        // Customize the error message based on the field being validated
        const errorMessage = `Field '${key}' is required.`;
        //errors.push(errorMessage);
        errObj[key] = errorMessage;
      }
      setErrorMessage({ ...errObj });
    }, []);
  };

  const validateEmptyOnChange = (fieldName, fieldValue, setErrorMessage) => {
    const newErrorMessage = {};

    if (fieldValue === null || fieldValue === undefined || fieldValue === "") {
      // Customize the error message based on the field being validated
      const errorMessage = `Field '${fieldName}' is required.`;
      newErrorMessage[fieldName] = errorMessage;
    } else {
      // Clear the error message if the field is not empty
      newErrorMessage[fieldName] = null;
    }

    setErrorMessage(newErrorMessage);
  };
  const onFinish = (values) => {
    const { type: removedKey, ...rest } = values;
    var val = { ...rest, type: { id: values.type } };
    if (isEditing) {
      handleEdit(val);
    } else {
      handleAdd(val);
    }
  };

  useEffect(() => {
    isValidating && validateEmpty(editingForm);
    editingForm.type === "c3cf4706-e8a9-4d8f-8847-b99edc844282"
      ? setTotalUserPoint(false)
      : setTotalUserPoint(true);
  }, [editingForm]);

  useEffect(() => {
    setPageClient(1)
    handleFetchMoreClient(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchOptionClient])

const handleScrollClient = (event) => {
  let pos = (event.target.scrollTop || event.target.scrollTop) + event.target.offsetHeight;
  let max = 32;
  if(pos === max) {
      if (
          pageClient - 1 <= totalPageClient &&
          optionsClient?.length !== totalElementClient
      ) {
          setLoadingMoreClient(true);
      }
      clearTimeout(debounceScrollOptionClient)
      setDebounceScrollOptionClient(setTimeout(() => {
          event.stopPropagation();
          if (!onSearchOptionClient) {
            handleFetchMoreClient(pageClient);
          }
      }, 2000))
  } 
};

const handleFetchMoreClient = async (page) => {
  if (
      page - 1 <= totalPageClient &&
      optionsClient?.length !== totalElementClient
  ) {
      // setLoadingMoreClient(true);
      try {
          const response = await ProjectHistoryRepo.getClientHistory(page - 1, 20, undefined ,searchOptionClient);
          let tempOptions = optionsClient;
          if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
              tempOptions.push(typeEvent);
          });
          setOptionsClient(tempOptions);
          setTotalPageClient(response.data?.data?.totalPages);
          setTotalElementClient(response.data?.data?.totalElements);
          }
          setPageClient(page + 1);
          setLoadingMoreClient(false);
          setOnSearchOptionClient(false);
      } catch (error) {
          AlertUtility.ErrorAlert({ title: "Failed Get Data" });
          setLoadingMoreClient(false);
      }
  } 
};

const doSearchClient = (input) => {
  setOnSearchOptionClient(true)
  // setLoadingMoreClient(true)
  clearTimeout(debounceOptionClient)
  setDebounceOptionClient(setTimeout(() => {
      setOptionsClient([])
      setSearchOptionClient(input)
  }, 2000))
}

  /* useEffect(() => {
    if(isValidating && !!errorMessages) {
      onFinish(editingForm);
    }
  }, [isValidating]); */

  return (
    <Modal
      forceRender
      width={900}
      maskClosable={false}
      open={show}
      onCancel={() => {
        closeModal(false);
      }}
      footer={null}
      closeIcon={true}
      zIndex={999999}
      className="add-form-container-modal"
    >
      <div>
        <div className="flex flex-col w-full mb-4">
          <div className="">
            <p className="text-[20px] font-weight-600 mb-5">
              {isEditing ? "Edit Project" : "Add Project"}
            </p>
          </div>

          <div>
            <CustomInputContainer
              title={"Client Name"}
              errorMessage={errorMessages?.name}
              editingForm={editingForm}
            >
              <Select
                  style={{ width: "100%" }}
                  placeholder="Choose Client"
                  value={editingForm?.historyClientTalent?.id}
                  dropdownRender={(menu) => (
                      <div onWheel={handleScrollClient}>{menu}</div>
                  )}
                  loading={loadingMoreClient || onSearchOptionClient}
                  showSearch
                  filterOption={false}
                  onChange={(v) => {
                    handleEditForm("historyClientTalent", {id: v});
                  }}
                  onSearch={(input) => {
                      doSearchClient(input)
                  }}
                  defaultActiveFirstOption={false}
              >
                  {
                      onSearchOptionClient ? 
                      <>
                          <Option disabled value="onload search">Please wait...</Option>
                      </>
                      : 
                          optionsClient.map((i) => (
                              <Option key={i.id} value={i.id}>
                                  {i.clientCompany.name}
                              </Option>
                          ))
                  }
                  {
                      loadingMoreClient ? 
                      <>
                          <Option disabled value="onload scroll">Please wait...</Option>
                      </>
                      : 
                      <></>
                  }
              </Select>
              
            </CustomInputContainer>

            <CustomInputContainer
              title={"Project Name"}
              errorMessage={errorMessages?.name}
              editingForm={editingForm}
            >
              <Select
                showSearch={true}
                optionFilterProp="children"
                size="large"
                className="w-full"
                onSearch={(val) => {
                  handleProjectSearch(val);
                }}
                placeholder={"Choose Project Name"}
                options={projectList}
                value={editingForm?.name}
                filterOption={(input, option) => {
                  return (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                onChange={(v) => {
                  handleEditForm("name", v);
                }}
                onPopupScroll={handleLoadMore}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Type"}
              errorMessage={errorMessages?.type}
              editingForm={editingForm}
            >
              <Select
                size="large"
                className="w-full"
                placeholder={"Choose Type"}
                value={editingForm.type}
                options={typeOptions}
                onChange={(v) => {
                  handleEditForm("type", v);

                  v === "c3cf4706-e8a9-4d8f-8847-b99edc844282"
                    ? setTotalUserPoint(false)
                    : setTotalUserPoint(true);
                }}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"PIC"}
              errorMessage={errorMessages?.description}
              editingForm={editingForm}
            >
              <Input
                placeholder="PIC"
                value={editingForm?.pic}
                onChange={(v) => {
                  handleEditForm("pic", v.target.value);
                }}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Tech Stack"}
              errorMessage={errorMessages?.description}
              editingForm={editingForm}
            >
              <Input
                placeholder="Tech Stack"
                value={editingForm?.techStack}
                onChange={(v) => {
                  handleEditForm("techStack", v.target.value);
                }}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Description"}
              errorMessage={errorMessages?.description}
              editingForm={editingForm}
            >
              <TextArea
                rows={4}
                placeholder="Project Description"
                value={editingForm?.description}
                onChange={(v) => {
                  handleEditForm("description", v.target.value);
                }}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Status"}
              errorMessage={errorMessages?.status}
              editingForm={editingForm}
            >
              <Select
                size="large"
                className="w-full"
                placeholder={"Choose Status"}
                value={editingForm.status}
                options={statusOptions}
                onChange={(v) => {
                  handleEditForm("status", v);
                }}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Start Date"}
              errorMessage={errorMessages?.startDate}
              editingForm={editingForm}
            >
              <DatePicker
                className="w-full"
                // disabledDate={(current) => current && current < dayjs()}
                onChange={(date, stringDate) => {
                  handleEditForm("startDate", date);
                }}
                value={
                  editingForm?.startDate
                    ? dayjs(editingForm?.startDate)
                    : dayjs()
                }
                // defaultValue={dayjs()}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"End Date"}
              errorMessage={errorMessages?.endDate}
              editingForm={editingForm}
            >
              <DatePicker
                className="w-full"
                disabledDate={(current) => current && current < dayjs()}
                onChange={(date, stringDate) => {
                  handleEditForm("endDate", date);
                }}
                value={
                  editingForm?.endDate ? dayjs(editingForm?.endDate) : dayjs()
                }
                // defaultValue={dayjs()}
              />
            </CustomInputContainer>

            <CustomInputContainer
              title={"Total User Point"}
              errorMessage={errorMessages?.totalPoint}
              editingForm={editingForm}
            >
              <Input
                placeholder="Total User Point"
                value={editingForm?.totalPoint}
                onChange={(v) => {
                  handleEditForm("totalPoint", v.target.value);
                }}
                disabled={totalUserPoint}
              />
            </CustomInputContainer>
          </div>
          <div className="flex items-end justify-end">
            <Button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              htmlType="submit"
              loading={loading}
              onClick={() => {
                setIsValidating(() => {
                  return true;
                });
                validateEmpty(editingForm);
                if (!!errorMessages) {
                  onFinish(editingForm);
                } /*  else {
                  validateEmpty(editingForm);
                } */
              }}
            >
              Save
            </Button>
            <Button
              className="bg-white text-red w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                setErrorMessage({});
                closeModal(false);
                setIsValidating(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const CustomInputContainer = (
  { title, errorMessage, editingForm, validatingFlag, children },
  props
) => {
  return (
    <div className="flex justify-between my-1" {...props}>
      <div className="w-[150px]">
        <p>
          {title}
          <span className="text-[red]">*</span>
        </p>
      </div>
      <div className="w-full flex flex-col gap-1">
        {children}
        {errorMessage && errorMessage !== "" ? (
          <p className="text-[red]">{errorMessage}</p>
        ) : null}
      </div>
    </div>
  );
};
