import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CallApi from "../services/Request-Helper";

const host = process.env.REACT_APP_BASE;
const initialState = {
  formDetails: {
    key: "",
    title: "",
    type: "",
    issuedTo: "",
    date: "",
    reason: "",
    revoked: "",
    revokeDate: "",
  },
  activeTab: "issued",
  issuedData: [],
  revokedData: [],
  keywordSearch: "",
  sortParams: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  isAlert: false,
  message: "",
  pagination: {
    currentPage: 1,
    totalData: 1,
    limit: 10,
  },
};

export const getAllCertificateIssued = createAsyncThunk(
  "admin/certificate-issued",
  async (input, thunkApi) => {
    try {
      const { currentPage, limit, tabs, type, sort } = input;
      const response = await CallApi({
        url: `${host}api/v1/issued-certificate/list?page=${
          currentPage - 1
        }&size=${limit}&status=${tabs}${type ? "&type=" + type : ""}${
          sort
            ? "&orderBy=" + sort.orderBy + "&orderType=" + sort.orderType
            : ""
        }${input.keyword ? "&fullname=" + input.keyword : ""}`,
        method: "GET",
      });
      if (response.data.status === 200) {
        response.data.data.status = input.tabs;
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get all Certificate Issued";
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getOneCertificateIssued = createAsyncThunk(
  "admin/certificate-issued/find-one",
  async (id, thunkApi) => {
    try {
      const response = await CallApi({
        url: `${host}api/v1/issued-certificate/admin/${id}`,
        method: "GET",
      });
      if (response.data.status === 200) {
        return response.data.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get one Certificate Issued";
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const revokeCertififcate = createAsyncThunk(
  "admin/certificate-issued/revoke",
  async (body, thunkApi) => {
    try {
      const response = await CallApi({
        url: `${host}api/v1/issued-certificate/revoke-user-by-admin`,
        method: "PUT",
        data: body,
      });
      if (response.data.status === 200) {
        return response.data.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot revoke Certificate Issued";
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const previewCertificate = createAsyncThunk(
  "admin/certificate-issued/preview",
  async (id, thunkAPi) => {
    try {
      const response = await CallApi({
        url: `${host}api/v1/certificate/generate/${id}`,
        method: "GET",
      });
      if (response.status === 200) {
        window.open(`${host}api/v1/certificate/generate/${id}`, "_blank");
        return response.data;
      }
    } catch (error) {
      const message = "System failed, cannot preview Certificate issued";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const searchCertificate = createAsyncThunk(
  "admin/certificate-issued/search",
  async (value, thunkAPi) => {
    try {
      const { keyword, status } = value;
      const response = await CallApi({
        url: `${host}api/v1/issued-certificate/list?page=0&size=10&fullname=${keyword}&status=${status}`,
        method: "GET",
      });
      if (response.data.status === 200) {
        response.data.data.status = status;
        if (response.data.data.content.length === 0) throw new Error(response);
        return response.data;
      }
      throw new Error(response);
    } catch (error) {
      const message = "System failed, cannot get all filter Certificate issued";
      return thunkAPi.rejectWithValue(message);
    }
  }
);

export const certificateIssuedSlice = createSlice({
  name: "certificateIssued",
  initialState,
  reducers: {
    resetStateForm: (state) => {
      state.formDetails = {
        key: "",
        title: "",
        type: "",
        issuedTo: "",
        date: "",
        reason: "",
        revoked: "",
        revokeDate: "",
      };
    },
    resetStatusFeedback: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
    },
    setReason: (state, action) => {
      state.formDetails.reason = action.payload;
    },
    onChangeState: (state, action) => {
      state[action.payload.stateProp] = action.payload.valueProp;
    },
  },
  extraReducers: (builder) => {
    //FIND ALL
    builder.addCase(getAllCertificateIssued.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCertificateIssued.fulfilled, (state, action) => {
      if (action.payload?.data?.status === "issued") {
        state.issuedData = [];
        let tempIssuedData = [];
        const originalData = action.payload?.data?.content;
        originalData.map((item) => {
          tempIssuedData = [
            ...tempIssuedData,
            {
              key: item?.id,
              id: item?.certificate?.certificate_id,
              title: item?.certificate?.title,
              type: item?.certificate?.typeCertificate?.name,
              issuedTo: item?.issuedTo.fullname,
              date: item?.issuedDate,
              action: "",
            },
          ];
        });
        state.issuedData = tempIssuedData;
      } else if (action.payload?.data?.status === "revoke") {
        state.revokedData = [];
        let tempRevokeData = [];
        const originalData = action.payload?.data?.content;
        originalData.map((item) => {
          tempRevokeData = [
            ...tempRevokeData,
            {
              key: item?.id,
              id: item?.certificate?.certificate_id,
              title: item?.certificate?.title,
              type: item?.certificate?.typeCertificate?.name,
              issuedTo: item?.issuedTo.fullname,
              revoked: item?.revokeDate,
              action: "",
            },
          ];
        });
        state.revokedData = tempRevokeData;
      }

      // if (action.payload.data.number === 0) state.pagination.currentPage = action.payload.data.number + 2;
      state.pagination.currentPage = action.payload.data.number + 1;
      state.pagination.totalData = action.payload.data.totalElements;
      state.isLoading = false;
    });
    //issue karena ketika di klik submit reason ini juga tampil, shinga di koment saja
    // builder.addCase (getAllCertificateIssued.rejected, (state)  => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.isSuccess = false;
    //     state.isAlert = true;
    //     state.message = "Failed to get All Certificate issued"
    // });

    //FIND ONE
    builder.addCase(getOneCertificateIssued.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.message = "";
    });
    builder.addCase(getOneCertificateIssued.fulfilled, (state, action) => {
      // resetStatusFeedback()
      resetStateForm();
      state.formDetails = {
        key: action?.payload?.id,
        title: action?.payload?.certificate?.title,
        type: action?.payload?.certificate?.typeCertificate?.name,
        issuedTo: action?.payload?.issuedTo.fullname,
        date: action?.payload?.issuedDate,
        reason: action?.payload?.revokeReason,
        revoked: action?.payload?.status,
        revokeDate: action?.payload?.revokeDate,
      };

      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(getOneCertificateIssued.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.isAlert = true;
      state.message = "Failed to get Certificate issued";
    });

    //UPDATE REVOKE
    builder.addCase(revokeCertififcate.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(revokeCertififcate.fulfilled, (state) => {
      // resetStatusFeedback()
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.isAlert = true;
      state.message = "Success to revoke Certificate template";
    });
    builder.addCase(revokeCertififcate.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.isAlert = true;
      state.message = "Failed to revoke Certificate Template";
    });

    //PREVIEW
    builder.addCase(previewCertificate.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(previewCertificate.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(previewCertificate.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.isAlert = true;
      state.message = "Failed to preview Certificate";
    });

    //SEARCH
    builder.addCase(searchCertificate.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.isAlert = false;
      state.messageAlert = "";
    });
    builder.addCase(searchCertificate.fulfilled, (state, action) => {
      if (action.payload?.data?.status === "ISSUED") {
        state.issuedData = [];
        const originalData = action.payload?.data?.content;
        originalData.map((item) => {
          const convertItem = {
            key: item?.id,
            title: item?.certificate?.title,
            type: item?.certificate?.typeCertificate?.name,
            issuedTo: item?.issuedTo.fullname,
            date: item?.issuedDate,
            action: "",
          };
          state.issuedData = [...state.issuedData, convertItem];
        });
      } else if (action.payload?.data?.status === "REVOKE") {
        state.revokedData = [];
        const originalData = action.payload?.data?.content;
        originalData.map((item) => {
          const convertItem = {
            key: item?.id,
            title: item?.certificate?.title,
            type: item?.certificate?.typeCertificate?.name,
            issuedTo: item?.issuedTo.fullname,
            revoked: item?.revokeBy.fullname,
            action: "",
          };
          state.revokedData = [...state.revokedData, convertItem];
        });
      }

      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(searchCertificate.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.isAlert = true;
      state.message = "Sorry, Data not found";
    });
  },
});

export const { resetStateForm, resetStatusFeedback, setReason, onChangeState } =
  certificateIssuedSlice.actions;
export default certificateIssuedSlice.reducer;
