import React from "react";
import { Table, Image } from "antd";
import SkeletonTable from "../../master/Events/components/SkeletonTable";

const BadgesTable = ({ isLoading, dataTable, actionEdit }) => {
  const columns = [
    {
      title: "Badges",
      dataIndex: "badges",
      key: "badges",
      render: (_, record) => <Image width={50} src={record.url} />,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "XP Point",
      dataIndex: "xp",
      key: "xp",
    },
    {
      title: "Last Edited By",
      dataIndex: "edited_by",
      key: "edited_by",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div className="flex flex-row">
          <button
            className="bg-white flex border-2 border-red-600 text-red-600 w-[60px] justify-center rounded-[7px] cursor-ponter"
            onClick={() => actionEdit(record)}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <SkeletonTable />;
  }

  return (
    <div className="mt-[10px] w-full">
      <Table
        columns={columns}
        dataSource={dataTable.map((item) => ({
          ...item,
          key: item.id,
          edited_by: item?.updatedBy?.fullname ?? "",
        }))}
        pagination={false}
        sticky={true}
        scroll={{
          x: 800,
          y: 520,
        }}
      />
    </div>
  );
};

export default BadgesTable;
