import React, { useEffect, useRef, useState } from "react";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import { Button, Form, Modal, Pagination } from "antd";
import InterviewClientRepo from "../../../repositores/adminRepo/InterviewClientRepo";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";
import SearchBar from "../componets_global/SearchBar";
import TableContent from "../componets_global/TableContent";
import ModalInterviewClient from "./ModalInterviewClient";
import RetroQuestionForm from "./RetroQuestionForm";

const InterviewClient = () => {
  const [form] = Form.useForm();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [listData, setListData] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const [id, setId] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const qrUrlCopyRef = useRef(null);
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [questionOpen, setQuestionOpen] = useState(false);
  const [formQuestion] = Form.useForm();

  const [searchAccount, setSearchAccount] = useState("");
  const [searchPosition, setSearchPosition] = useState("");
  const [triggerCountSearch, setTriggerCountSearch] = useState(1);

  const [debounce, setDebounce] = useState(undefined);

  const onChangeSearch = async (el, field) => {
    try {
      setLoading(true);

      if (field === "account") {
        setSearchAccount(el.target.value);
      }
      if (field === "position") {
        setSearchPosition(el.target.value);
      }
      clearTimeout(debounce);
      setDebounce(
        setTimeout(async () => {
          setPage(1);
          setTriggerCountSearch((prevVal) => prevVal + 1);
        }, 2000)
      );
    } catch (error) {
      console.error("Error searching data: ", error);
      setLoading(false);
    }
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const handleClickDeleteModal = async () => {
    try {
      const response = await InterviewClientRepo.deleteInterviewClient(id);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
      fetchData();
      setOpenModalDelete(false);
    } catch (error) {
      console.error("Error deleting rewards:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLimit(10);
      setLoading(true);
      const response = await InterviewClientRepo.getListInterviewClient(
        page - 1,
        limit,
        searchAccount,
        searchPosition
      );

      if (response.status === 200) {
        setTotalItem(response?.data?.data?.totalElements);
        setListData(response?.data.data.content);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching rewards data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, triggerCountSearch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  const handleCopyUrl = async (id) => {
    try {
      const item = listData.find((data) => data.id === id);
      if (item) {
        const url = `${process.env.REACT_APP_BASE_FE}form-interview-talent/${item.id}`;
        await navigator.clipboard.writeText(url);
        setIsCopied(true);
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  const columns = [
    {
      title: "Account",
      dataIndex: ["account", "name"],
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Interviewer Name",
      dataIndex: "interviewerName",
      key: "interviewerName",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Position",
      dataIndex: ["role", "name"],
      key: "position",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "PIC TA",
      dataIndex: "picTa",
      key: "picTa",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    } /* ,
    {
      title: "Get URL",
      dataIndex: "id",
      key: "getUrl",
      align: "center",
      render: (id) => {
        return (
          <Button
            type="primary"
            className="text-xs px-2"
            ref={qrUrlCopyRef}
            onClick={() => handleCopyUrl(id)}
          >
            Copy URL
          </Button>
        );
      },
    } */,

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => {
        return (
          <div className="flex gap-3">
            <Button
              danger
              onClick={() => {
                handleClickEditModal(id);
                setId(id);
              }}
              className="w-full"
            >
              <span className="text-red-600">Edit</span>
            </Button>
            <Button
              className="w-full"
              type="primary"
              danger
              onClick={() => {
                setId(id);
                setOpenModalDelete(true);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const handleClickEditModal = async (id) => {
    setOpenModalEdit(true);
    const filterListByID = listData.filter((data) => data.id === id);
    setDataEdit(filterListByID[0]);
  };

  const handleGetDataModalAdd = async (
    status,
    data,
    selectedRole,
    selectedAccount
  ) => {
    const reqBody = {
      account: {
        id: selectedAccount,
      },
      role: {
        id: selectedRole,
      },
      picTa: data?.picTa,
      interviewerName: data?.interviewerName,
      projectName: data?.projectName,
    };

    if (status === "success") {
      const response = await InterviewClientRepo.addInterviewClient(reqBody);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
        fetchData();
        setOpenModalAdd(false);
        form.resetFields();
      } else {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
      }
    }
  };

  const handleGetDataModalEdit = async (
    status,
    data,
    selectedRole,
    selectedAccount
  ) => {
    const reqBody = {
      id: id || data.id,
      account: {
        id: selectedAccount,
      },
      role: {
        id: selectedRole,
      },
      picTa: data?.picTa,
      interviewerName: data?.interviewerName,
      projectName: data?.projectName,
    };

    if (status === "success") {
      const response = await InterviewClientRepo.editInterviewClient(reqBody);
      if (response.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        fetchData();
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
          setOpenModalEdit(false);
          setDataEdit(null);
          setId(null);
        }, 2000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
    }
  };

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      {isCopied && (
        <AlertMiniPopUp isSuccess={isCopied} messageAlert={"Copied"} />
      )}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Interview Client</TitleMasterAdmin>
        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "account")}
              placeholder={"Search Account"}
            />
            <SearchBar
              onChangeSearch={(e) => onChangeSearch(e, "position")}
              placeholder={"Search Position"}
            />
          </div>
          <div className="flex flex-row gap-4 mb-2 lg:mb-0">
            <button
              className="bg-red-600 w-[120px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[30px] font-bold"
              onClick={() => setOpenModalAdd(true)}
            >
              Add Data
            </button>
          </div>
        </div>
        <TableContent
          className="styleTabel"
          dataSource={listData}
          columns={columns}
          isLoading={loading}
        />
        <div className="w-full flex justify-center mt-5">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
            showSizeChanger={false}
          />
        </div>
        <ModalInterviewClient
          instanceForm={form}
          getData={(status, data, selectedRole, selectedAccount) =>
            handleGetDataModalAdd(status, data, selectedRole, selectedAccount)
          }
          dataEdit={null}
          openModal={openModalAdd}
          onClick={() => {
            setOpenModalAdd(false);
            form.resetFields();
          }}
          title={"Add"}
        />
        <ModalInterviewClient
          instanceForm={form}
          getData={(status, data, selectedRole, selectedAccount) =>
            handleGetDataModalEdit(status, data, selectedRole, selectedAccount)
          }
          dataEdit={dataEdit}
          openModal={openModalEdit}
          onClick={() => {
            setOpenModalEdit(false);
            form.resetFields();
            setDataEdit(null);
          }}
          title={"Edit"}
        />
        <Modal
          forceRender
          width={315}
          footer={null}
          closable={false}
          keyboard={false}
          open={openModalDelete}
          onOk={handleClickDeleteModal}
          onCancel={() => setOpenModalDelete(false)}
        >
          <div className="flex flex-col justify-center items-center gap-[30px] text-center">
            <p>Are you sure?</p>
            <p className="text-center">
              Do you really want to delete these records? This process cannot be
              undone
            </p>
            <div className="flex gap-[10px]">
              <Button onClick={() => setOpenModalDelete(false)}>Cancel</Button>
              <Button onClick={handleClickDeleteModal} type="primary" danger>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default InterviewClient;
