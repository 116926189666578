import { Fragment } from "react";
import { QuizOption } from "./QuizOption";

export const QuizQuestion = ({
  canGoToNextQuestion,
  chosenAnswer,
  isFirstQuestion,
  isLastQuestion,
  label,
  question,
  options,
  chooseAnswer,
  goToNextQuestion,
  goToPrevQuestion,
}) => {
  return (
    <div className="mt-[120px] max-w-[860px] mx-auto">
      <header className="mb-12">
        <h1 className="text-white text-center text-[40px] font-bold">
          {label}
        </h1>
        <p className="text-white text-center text-2xl font-bold mt-16">
          {question}
        </p>
      </header>
      <ul className="flex flex-col gap-4">
        {options.map((option, index) => (
          <Fragment key={index}>
            <QuizOption
              answer={option.answer}
              isOptionChosen={chosenAnswer?.answerId === option.id}
              chooseAnswer={() => chooseAnswer(option)}
            />
          </Fragment>
        ))}
      </ul>
      <footer className="flex items-center gap-16 justify-end mt-20">
        {!isFirstQuestion && (
          <button className="text-white" onClick={goToPrevQuestion}>
            Previous
          </button>
        )}
        <button
          className="inline-flex items-center gap-4 py-2 px-12 bg-secondary rounded-[20px] disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!canGoToNextQuestion}
          onClick={goToNextQuestion}
        >
          <span className="text-white font-bold">
            {isLastQuestion ? "Finish" : "Next"}
          </span>
          {!isLastQuestion && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M1 8H15M15 8L8 1M15 8L8 15"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </footer>
    </div>
  );
};
