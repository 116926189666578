import React from "react";
import { Table } from "antd";
import deleteIcon from "../../../../assets/icons/delete.png";

const TableCourses = ({
  dataTable,
  selectDelete,
  selectEdit,
  selectToDetail,
}) => {
  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "5%",
    },
    {
      title: "Course Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            <div
              onClick={() => {
                selectToDetail(record);
              }}
              className="mr-[30px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">View Detail</span>
            </div>
            <div
              onClick={() => {
                selectEdit(record);
              }}
              className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-red-600">Edit</span>
            </div>
            <img
              className="cursor-pointer"
              src={deleteIcon}
              alt="delete"
              onClick={() => {
                selectDelete(record);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        sticky
        dataSource={dataTable}
        columns={columns}
        pagination={false}
        rowKey="id"
        scroll={{ x: 1100, y: 520 }}
      />
    </>
  );
};

export default TableCourses;
