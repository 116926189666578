import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListRoles = async (page = 0, size = 10, name = undefined) => {
    let params = {
      page: page + 1,
      size: size,
    };
    if (typeof name != "undefined" && name.length) {
      params["name"] = name;
    }
    const url = `${API_BASE}/role/list?${createSearchParams(
      params
    )}`;
  
    const response = await CallApi({ url, method: "GET" });
    return response;
};

const getListRolesEmployee = async (page = 0, size = 10, name = undefined) => {
    let params = {
        page: page + 1,
        size: size,
    };
    if (typeof name != "undefined" && name.length) {
        params["roleName"] = name;
    }
    const url = `${API_BASE}/employee/role/list-user?${createSearchParams(
        params
    )}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
};

const addRoleEmployee = async (body) => {
    const url = `${API_BASE}/employee/role`;
    const response = await CallApi({ url, method: "POST", data: body });
    return response;
};

const deleteRoleEmployee = async (body) => {
    const url = `${API_BASE}/employee/role`;
    const response = await CallApi({ url, method: "DELETE", data: body });
    return response;
};

const updateRoleEmployee = async (body) => {
    const url = `${API_BASE}/employee/role`;
    const response = await CallApi({ url, method: "PUT", data: body });
    return response;
};

const getListSkills = async (page = 0, size = 10, name = undefined) => {
    let params = {
        page: page + 1,
        size: size,
    };
    if (typeof name != "undefined" && name.length) {
        params["name"] = name;
    }

    const url = `${API_BASE}/skill/list?${createSearchParams(
        params
    )}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
};

const getListSkillEmployee = async (page = 0, size = 10, name = undefined, nameSkill = undefined) => {
    let params = {
        page: page + 1,
        size: size,
    };
    if (typeof name != "undefined" && name.length) {
        params["roleName"] = name;
    }

    if (typeof nameSkill != "undefined" && nameSkill.length) {
        params["skillName"] = nameSkill;
    }
    const url = `${API_BASE}/employee/skill/list-user?${createSearchParams(
        params
    )}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
};

const addSkillEmployee = async (body) => {
    const url = `${API_BASE}/employee/skill`;
    const response = await CallApi({ url, method: "POST", data: body });
    return response;
};

const deleteSkillEmployee = async (body) => {
    const url = `${API_BASE}/employee/skill`;
    const response = await CallApi({ url, method: "DELETE", data: body });
    return response;
};

const updateSkillEmployee = async (body) => {
    const url = `${API_BASE}/employee/skill`;
    const response = await CallApi({ url, method: "PUT", data: body });
    return response;
};

const getListHistoryCourse = async (page = 0, size = 10, name = undefined) => {
    let params = {
        page: page + 1,
        size: size,
    };
    if (typeof name != "undefined" && name.length) {
        params["courseName"] = name;
    }

    const url = `${API_BASE}/history-courses/list-user?${createSearchParams(
        params
    )}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}


const RoleSkillTalent = {
    getListRoles,
    getListRolesEmployee,
    addRoleEmployee,
    deleteRoleEmployee,
    updateRoleEmployee,
    getListSkills,
    getListSkillEmployee,
    addSkillEmployee,
    deleteSkillEmployee,
    updateSkillEmployee,
    getListHistoryCourse
};

export default RoleSkillTalent;
