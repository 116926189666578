import React, {useRef, useEffect, useState} from "react";
import { Button, Input, Space, Table, Select } from "antd";
import deleteIcon from "../../../../assets/icons/delete.png";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { isNullOrEmpty } from "../../../../utils/utils";
import AdminLmsRepo from "../../../../repositores/adminRepo/LmsRepo";

const { Option } = Select;

const TableMatkul = ({ dataTable, selectDelete, selectEdit, selectToDetail, doFilter, doClearFilter, defaultValueFilter, filteredData }) => {
  const searchInput = useRef(null);
  const selectInput = useRef(null);

  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(100);
  const [optionsLevel, setOptionsLevel] = useState([]);
  const [totalElementLevel, setTotalElementLevel] = useState(-1);
  const [onSearchOption, setOnSearchOption] = useState(false)

  useEffect(() => {
    handleFetchMore(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleScroll = (event) => {
    event.stopPropagation();

    if (!loadingMore && !onSearchOption) {
      handleFetchMore(page);
    }
};

  const handleFetchMore = async (page) => {
    if (
        page - 1 <= totalPage &&
        optionsLevel?.length !== totalElementLevel
    ) {
        setLoadingMore(true);
        try {
            const response = await AdminLmsRepo.getListlevel(page, 20, undefined);
            let tempOptions = optionsLevel;
            if (response.data?.data?.content?.length) {
            await response.data?.data?.content?.forEach((typeEvent) => {
                tempOptions.push(typeEvent);
            });
            setOptionsLevel(tempOptions);
            setTotalPage(response.data?.data?.totalPages);
            setTotalElementLevel(response.data?.data?.totalElements);
            }
            setPage(page + 1);
            setLoadingMore(false);
            setOnSearchOption(false);
        } catch (error) {
            setLoadingMore(false);
        }
    } 
};

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    doFilter(selectedKeys[0], dataIndex)
  };
  const handleReset = (dataIndex) => {
    doClearFilter(undefined, dataIndex)
  };

  const getColumnSearchProps = (dataIndex, inputType = 'text') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          type={inputType}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          defaultValue={ isNullOrEmpty(defaultValueFilter()[dataIndex]) ? '' : defaultValueFilter()[dataIndex]}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => {
      return (
        <FilterFilled style={{
          color: filteredData()[dataIndex] ? '#1677ff' : undefined,
        }}/>
      )
    },
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return text
    }
  });

  const getColumnSelectProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Select
          ref={selectInput}
          placeholder="Search to Select"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          defaultValue={ isNullOrEmpty(defaultValueFilter()[dataIndex]) ? undefined : defaultValueFilter()[dataIndex]}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        >
          {
              loadingMore ? 
              <>
                  <Option disabled>Please wait...</Option>
              </>
              : 
                optionsLevel.map((i) => (
                    <Option key={i.id} value={i.id}>
                        {i.name}
                    </Option>
                ))
          }

        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => {
      return (
        <FilterFilled style={{
          color: filteredData()[dataIndex] ? '#1677ff' : undefined,
        }}/>
      )
    },
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return text
    }
  });

  const columns = [
        {
            title: "No",
            dataIndex: "number",
            key: "number",
            align: "center",
            width: '5%'
        },
        {
            title: "Termin",
            dataIndex: "termin",
            key: "termin",
            align: "center",
            width: '5%',
            ...getColumnSearchProps('termin', 'number'),
        },
        
        {
            title: "No Mata kuliah",
            dataIndex: "matkulNo",
            key: "matkulNo",
            align: "center",
            width: '10%',
        },
        {
            title: "Mata Kuliah",
            dataIndex: "matkul",
            key: "matkul",
        },
        {
            title: "Level",
            dataIndex: ["level","name"],
            key: "level",
            width: '5%',
            render: (_, row) => {
                return (
                    <span>{row?.name}</span>
                )
            },
            ...getColumnSelectProps('level')
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: '20%',
            render: (_, record) => {
                return (
                  <div className="flex justify-center">
                    <div
                      onClick={() => {
                        selectToDetail(record);
                      }}
                      className="mr-[30px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
                    >
                      <span className="text-grey">View Detail</span>
                    </div>
                    <div
                      onClick={() => {
                        selectEdit(record);
                      }}
                      className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
                    >
                      <span className="text-red-600">Edit</span>
                    </div>
                    <img
                      className="cursor-pointer"
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => {
                        selectDelete(record);
                      }}
                    />
                  </div>
                );
              },
        },
    ]

    return (
        <>
            <Table
                dataSource={dataTable}
                columns={columns}
                pagination={false}
                rowKey="id"
            />
        </>
    )
}

export default TableMatkul