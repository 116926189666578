import React, { useEffect, useState } from "react";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import { Button, Form, Modal, Switch, Pagination } from "antd";
import deleteIcon from "../../../assets/icons/delete.png";
import "./index.css";
import ModalRewards from "./components/ModalRewards";
import TabelContent from "./components/TabelContent";
import ButtonAddRewards from "./components/ButtonAddRewards";
import RewardsRepo from "../../../repositores/adminRepo/RewardsRepo";
import BadgesRepo from "../../../repositores/adminRepo/BadgesRepo";
import SearchBar from "../componets_global/SearchBar";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";

const Rewards = () => {
  const [form] = Form.useForm();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [listData, setListData] = useState();
  const [listLevel, setListLevel] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const [id, setId] = useState();
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const columns = [
    {
      title: "Icon",
      dataIndex: "urlImage",
      key: "urlImage",
      align: "center",
      render: (data) => (
        <img
          src={data}
          alt={data}
          className="w-[100px] h-[100px] overflow-hidden"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      key: "nominal",
      align: "center",
      render: (nominal) => {
        return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
    },
    {
      title: "Coin",
      dataIndex: "gc",
      key: "gc",
      align: "center",
    },
    {
      title: "Stock",
      dataIndex: "quota",
      key: "quota",
      align: "center",
    },
    {
      title: "Level Required",
      dataIndex: "level",
      key: "level",
      align: "center",
      render: (props) => {
        return props.level;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status, data) => {
        return (
          <Switch
            onClick={(dataClick) =>
              handleGetDataModalEdit(
                "success",
                data,
                dataClick ? "ACTIVE" : "INACTIVE"
              )
            }
            checked={status.toUpperCase() === "ACTIVE"}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id) => {
        return (
          <div className="flex">
            <div
              onClick={() => {
                handleClickEditModal(id);
                setId(id);
              }}
              className="mr-[30px] border-2 decoration-red-600 border-red-600 w-[65px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-red-600">Edit</span>
            </div>
            <img
              className="cursor-pointer"
              src={deleteIcon}
              alt="delete"
              onClick={() => {
                setId(id);
                setOpenModalDelete(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    try {
      setLimit(10);
      setLoading(true);
      const response = await RewardsRepo.getRewards(page, limit);

      if (response.status === 200) {
        setTotalItem(response?.data?.data?.totalElements);
        setListData(response?.data.data.content);
      }

      const responseListLevel = await BadgesRepo.getListBadges(1, 10);

      if (responseListLevel.status === 200) {
        setListLevel(responseListLevel?.data.data.content);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching rewards data:", error);
    }
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeSearch = async (el) => {
    try {
      setPage(1);
      setLoading(true);
      setListData(null);
      const response = await RewardsRepo.getRewards(1, 10, el.target.value);
      setListData(response.data.data.content);
      setLoading(false);
    } catch (error) {
      console.error("Error searching data: ", error);
    }
  };

  const handleClickDeleteModal = async () => {
    try {
      const response = await RewardsRepo.deleteRewards({ id });
      console.log(response);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
      fetchData();
      setOpenModalDelete(false);
    } catch (error) {
      console.error("Error deleting rewards:", error);
    }
  };

  const handleClickEditModal = async (id) => {
    setOpenModalEdit(true);
    const filterListByID = listData.filter((data) => data.id === id);
    setDataEdit(filterListByID[0]);
  };

  const handleGetDataModalAdd = async (status, data) => {
    const paramDataAdd = {
      description: data?.name,
      currency: "IDR",
      nominal: data?.nominal,
      gc: data?.coin,
      status: "ACTIVE",
      image: data?.icon?.file.response.data.fileName,
      quota: data?.stock,
      level: {
        id: data["level required"],
      },
    };
    if (status === "success") {
      const response = await RewardsRepo.addRewards(paramDataAdd);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
        fetchData();
        setOpenModalAdd(false);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        console.log("Error addData", response);
      }
    }
  };

  const handleGetDataModalEdit = async (status, data, dataStatus) => {
    const paramDataEdit = {
      id: id || data.id,
      description: data.name || data.description,
      currency: "IDR",
      nominal: data.nominal,
      gc: data.coin || data.gc,
      status: dataStatus || dataEdit?.status,
      image:
        data.icon?.file?.response?.data.fileName ||
        dataEdit?.image ||
        data?.image,
      quota: data.stock || data.quota,
      level: {
        id: data.level?.id || data["level required"] || dataEdit?.level?.id,
      },
    };

    if (status === "success") {
      const response = await RewardsRepo.editRewards(paramDataEdit);
      if (response.data.status === 200) {
        setMessageAlert({
          message: response.data.message,
          status: response.data.status,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
        fetchData();
        setOpenModalEdit(false);
        setDataEdit(null);
        setId(null);
      } else {
        setMessageAlert({
          message: response.data.message,
          status: false,
        });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }
    }
  };

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Rewards</TitleMasterAdmin>
        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <SearchBar
              onChangeSearch={onChangeSearch}
              placeholder={"Search Name"}
            />
            <ButtonAddRewards onClick={() => setOpenModalAdd(true)} />
          </div>
        </div>
        <TabelContent
          dataSource={listData}
          columns={columns}
          isLoading={loading}
        />
        <div className="w-full flex justify-center mt-5">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
          />
        </div>
        <ModalRewards
          intanceForm={form}
          listLevel={listLevel}
          getData={handleGetDataModalAdd}
          dataEdit={null}
          openModal={openModalAdd}
          onClick={() => {
            setOpenModalAdd(false);
            form.resetFields();
          }}
          title={"Add"}
        />
        <ModalRewards
          intanceForm={form}
          listLevel={listLevel}
          getData={handleGetDataModalEdit}
          dataEdit={dataEdit}
          openModal={openModalEdit}
          onClick={() => {
            setOpenModalEdit(false);
            form.resetFields();
            setDataEdit(null);
          }}
          title={"Edit"}
        />
        <Modal
          forceRender
          width={315}
          footer={null}
          closable={false}
          keyboard={false}
          open={openModalDelete}
          onOk={handleClickDeleteModal}
          onCancel={() => setOpenModalDelete(false)}
        >
          <div className="flex flex-col justify-center items-center gap-[30px] text-center">
            <p>Are you sure?</p>
            <p className="text-center">
              Do you really want to delete these records? This process cannot be
              undone
            </p>
            <div className="flex gap-[10px]">
              <Button onClick={() => setOpenModalDelete(false)}>Cancel</Button>
              <Button onClick={handleClickDeleteModal} type="primary" danger>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Rewards;
