import React, { useEffect, useState } from "react";
import Input from "./components/Input";
import MasterTask from "../../../../repositores/adminRepo/MasterTaskRepo";
import { useLocation, useNavigate } from "react-router-dom";
import RouteAdm from "../../../../services/routeAdm";
import { Radio } from "antd";
import AlertMiniPopUp from "../../componets_global/AlertMiniPopUp";

const AddTask = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [radioValues, setRadioValues] = useState({});
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [nextQuestionId, setNextQuestionId] = useState(2);
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });

  const [dataHeader, setDataHeader] = useState({
    title: "",
    description: "",
  });
  const [correctAnswer, setCorrectAnswer] = useState([
    {
      id: "",
      answer: "",
    },
  ]);
  const [question, setQuestion] = useState([
    {
      id: "",
      myId: 1,
      question: "",
      isDeleted: false,
      answer: [
        {
          answer: "",
          alphabet: "A",
          isAnswer: false,
        },
        {
          answer: "",
          alphabet: "B",
          isAnswer: false,
        },
        {
          answer: "",
          alphabet: "C",
          isAnswer: false,
        },
        {
          answer: "",
          alphabet: "D",
          isAnswer: false,
        },
      ],
    },
  ]);

  useEffect(() => {
    if (location.state?.screen === "EDIT") {
      setDataHeader({
        title: location.state.data.title,
        description: location.state.data.description,
      });

      location.state?.data?.question.map((res, index) => {
        res.myId = index + 1;
        return res;
      });

      const newCorrectAnswers = [];
      setQuestion(location.state?.data?.question);
      location.state?.data?.question.map((item) => {
        item.answer.map((val) => {
          if (val.isAnswer === true) {
            newCorrectAnswers.push({
              id: item.id,
              answer: val.alphabet,
            });
          }
        });
      });
      setCorrectAnswer(newCorrectAnswers);
      setIsEditMode(true);

      const initialValues = {};
      location.state?.data?.question.forEach((q) => {
        initialValues[q.id] =
          q.answer.find((ans) => ans.isAnswer)?.alphabet || undefined;
      });
      setRadioValues(initialValues);
    } else {
      setIsEditMode(false);
    }
  }, [location]);

  useEffect(() => {}, [messageAlert]);

  useEffect(() => {}, [correctAnswer]);

  const appendQuestion = (id) => {
    let newQuestion = {
      id: (nextQuestionId + 1).toString(),
      myId: id + 1,
      question: "",
      isDeleted: false,
      answer: [
        {
          answer: "",
          alphabet: "A",
          isAnswer: false,
        },
        {
          answer: "",
          alphabet: "B",
          isAnswer: false,
        },
        {
          answer: "",
          alphabet: "C",
          isAnswer: false,
        },
        {
          answer: "",
          alphabet: "D",
          isAnswer: false,
        },
      ],
    };
    const updatedQuestions = [...question, newQuestion];
    setQuestion(updatedQuestions);
    setNextQuestionId(nextQuestionId + 1);
  };

  const removeQuestion = (id) => {
    if (question.length !== id && location.state?.screen !== "EDIT") {
      const array = [...question];
      const index = question.findIndex((arr) => arr.id === id);
      if (index !== -1) {
        array[index].isDeleted = true;
        setQuestion(array);
        setDeletedQuestions({
          ...deletedQuestions,
          [id]: array[index],
        });
      }
    } else if (location.state?.screen === "EDIT") {
      if (question.length > 1) {
        const array = [...question];
        const find = question.find((arr) => arr.id === id);
        const index = question.findIndex((arr) => arr.id === id);
        find.isDeleted = true;
        //array.splice(index, 1);
        setQuestion(() => array);
        setDeletedQuestions(() => {
          return {
            ...deletedQuestions,
            [id]: find,
          };
        });
      }
    }
  };

  const duplicateQuestion = (idToDuplicate) => {
    const maxMyId = Math.max(...question.map((q) => q.myId));
    const newMyId = maxMyId + 1;
    const questionToDuplicate = question.find((q) => q.id === idToDuplicate);

    if (questionToDuplicate) {
      setNextQuestionId(nextQuestionId + 1);

      const duplicatedQuestion = {
        ...questionToDuplicate,
        id: newMyId.toString(),
        myId: newMyId,
        answer: questionToDuplicate.answer.map((ans) => ({
          ...ans,
        })),
      };

      const updatedRadioValues = { ...radioValues };
      updatedRadioValues[duplicatedQuestion.id] =
        duplicatedQuestion.answer.find((ans) => ans.isAnswer)?.alphabet ||
        undefined;
      setRadioValues(updatedRadioValues);

      const correctAnswerIndex = duplicatedQuestion.answer.findIndex(
        (ans) => ans.isAnswer === true
      );

      if (correctAnswerIndex !== -1) {
        duplicatedQuestion.answer[correctAnswerIndex].isAnswer = true;
      }

      const updatedQuestions = [...question, duplicatedQuestion];
      setRadioValues(updatedRadioValues);
      setQuestion(updatedQuestions);
    }
  };

  const insertHeader = (data) => {
    setDataHeader({
      ...dataHeader,
      [data.target.name]: data.target.value,
    });
  };

  const insertDynamicQuestion = (data, myId) => {
    setQuestion((prevQuestion) =>
      prevQuestion.map((q) =>
        q.id === myId ? { ...q, question: data.target.value } : q
      )
    );
  };

  const insertDynamicAnswer = (data, myId, key) => {
    let array = question.find((res) => res.myId === myId);
    let answer = array.answer.find((arr) => arr.alphabet === key);
    answer.answer = data.target.value;
  };

  const trueDynamicAnswer = (myId, key) => {
    const updatedRadioValues = { ...radioValues };
    updatedRadioValues[myId] = key;
    setRadioValues(updatedRadioValues);
    const updatedQuestions = [...question];
    const questionIndex = updatedQuestions.findIndex((q) => q.id === myId);

    updatedQuestions[questionIndex].answer.forEach((ans) => {
      ans.isAnswer = false;
    });

    const answerIndex = updatedQuestions[questionIndex].answer.findIndex(
      (ans) => ans.alphabet === key
    );

    updatedQuestions[questionIndex].answer[answerIndex].isAnswer = true;
    setQuestion(updatedQuestions);

    if (isEditMode) {
      const updatedCorrectAnswers = [...correctAnswer];
      const correctAnswerIndex = updatedCorrectAnswers.findIndex(
        (ans) => ans.id === myId
      );
      if (correctAnswerIndex !== -1) {
        updatedCorrectAnswers[correctAnswerIndex].answer = key;
      } else {
        updatedCorrectAnswers.push({
          id: myId,
          answer: key,
        });
      }
      setCorrectAnswer(updatedCorrectAnswers);
    } else {
      const updatedCorrectAnswers = [...correctAnswer];
      const correctAnswerIndex = updatedCorrectAnswers.findIndex(
        (ans) => ans.id === myId
      );
      if (correctAnswerIndex !== -1) {
        updatedCorrectAnswers[correctAnswerIndex].answer = key;
      } else {
        updatedCorrectAnswers.push({
          id: myId,
          answer: key,
        });
      }
      setCorrectAnswer(updatedCorrectAnswers);
    }
  };

  const saveData = async () => {
    let tempQuest = [];
    question.map((res, index) => {
      let tempAnws = [];
      res.answer.map((arr, indx) => {
        if (location.state?.screen !== "EDIT") {
          const answ = {
            answer: arr.answer,
            alphabet: arr.alphabet,
            isAnswer: arr.isAnswer,
          };
          tempAnws.push(answ);
        } else {
          const answ = {
            id: arr.id,
            answer: arr.answer,
            alphabet: arr.alphabet,
            isAnswer: arr.isAnswer,
          };
          tempAnws.push(answ);
        }
      });
      if (location.state?.screen !== "EDIT") {
        const quest = {
          question: res.question,
          answer: tempAnws,
          isDeleted: res.isDeleted,
        };
        tempQuest.push(quest);
      } else {
        const quest = {
          question: res.question,
          answer: tempAnws,
          isDeleted: res.isDeleted,
        };
        if (res.created_date) {
          quest.id = res?.id;
        }
        tempQuest.push(quest);
      }
    });
    if (location.state?.screen !== "EDIT") {
      const postData = {
        title: dataHeader.title,
        description: dataHeader.description,
        question: tempQuest,
      };
      const response = await MasterTask.createTask(postData);
      if (response.data?.status === 200) {
        setMessageAlert({ message: response.data.message, status: true });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
          navigate(RouteAdm.task);
        }, 2000);
      }
    } else {
      const postData = {
        id: location.state?.data?.id,
        title: dataHeader.title,
        description: dataHeader.description,
        question: tempQuest,
      };

      const response = await MasterTask.updateTask(postData);
      if (response.data?.status === 200) {
        setMessageAlert({ message: response.data.message, status: true });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
          navigate(RouteAdm.task);
        }, 2000);
      }
    }
  };

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}

      <div className="container">
        <div className="bg-white w-[100%] p-[40px]">
          <div className="mb-10">
            <div className="text-2xl font-bold">Task</div>
          </div>
        </div>
        <div className="bg-red-100 w-[100%] h-auto">
          <div className="bg-red-100 w-[100%] h-full mt-[-50px] pb-40 px-5 md:px-20 pt-5">
            <div className="flex flex-row justify-between">
              <div
                className="bg-white flex text-black w-[80px] h-[30px] justify-center items-center rounded-[7px] cursor-pointer"
                onClick={() => navigate(RouteAdm.task)}
              >
                <img
                  src="/assets/icons/arrow-back.svg"
                  alt=""
                  className="cursor-pointer w-4 h-4"
                />
                <span className="text-sm ml-2">Back</span>
              </div>
              <div
                className="bg-red-600 flex text-white w-[80px] h-[30px] justify-center items-center rounded-[7px] cursor-pointer"
                onClick={saveData}
              >
                <img
                  src="/assets/icons/download.svg"
                  alt=""
                  className="cursor-pointer w-4 h-4"
                />
                <span className="text-sm ml-2">Save</span>
              </div>
            </div>
            <div
              className="bg-white w-[100%] h-[150px] mt-10 rounded-[20px]"
              ref={(el) => {
                if (el) {
                  el.style.height = "auto";
                  el.style.height = el.scrollHeight + "px";
                }
              }}
            >
              <div className="bg-red-500 w-full h-5 rounded-t-[20px]"></div>
              <div className="px-10 mt-4 w-full flex flex-col">
                <Input
                  inputTitle={"title"}
                  value={dataHeader.title}
                  wmyIdth={"450px"}
                  placeholder={"Task Title"}
                  sizeText={32}
                  styleInput="input-title"
                  onChange={(data) => insertHeader(data)}
                />
                <textarea
                  rows="1"
                  ref={(el) => {
                    if (el) {
                      el.style.height = "auto";
                      el.style.height = el.scrollHeight + "px";
                    }
                  }}
                  name="description"
                  className="block mb-3 w-full text-grey rounded-lg border-transparent outline-none text-2xl  focus:border-transparent focus:ring-0 dark:placeholder-gray-400 dark:text-black "
                  value={dataHeader.description}
                  onChange={(data) => insertHeader(data)}
                  placeholder={"Task Description"}
                />
              </div>
            </div>
            {question.map((arr, index) => {
              const isLastQuestion = question.length === 1;
              const uniqueKey = `${index}-${arr.myId || ""}-${arr.id || ""}`;
              return (
                <React.Fragment key={uniqueKey}>
                  {arr.isDeleted === false && (
                    <div className=" flex flex-row bg-white w-[100%] h-[100%] mt-10 rounded-[20px] pb-5 relative">
                      <div className="bg-red-500 w-4 h-full rounded-l-[20px] absolute" />
                      <div className="ml-10 mt-5 w-[100%] flex flex-col">
                        <div className="border-b-2 border-[#A8A8A8] w-[93%]">
                          <textarea
                            id={arr.id}
                            rows="1"
                            ref={(el) => {
                              if (el) {
                                el.style.height = "auto";
                                el.style.height = el.scrollHeight + "px";
                              }
                            }}
                            value={arr.question}
                            onChange={(e) => {
                              insertDynamicQuestion(e, arr.id);
                            }}
                            className="block p-2.5 w-full text-grey rounded-lg border-transparent outline-none text-2xl  focus:border-transparent focus:ring-0 dark:placeholder-gray-400 dark:text-black"
                            placeholder={`Question ${index + 1} `}
                          />
                        </div>
                        <Radio.Group
                          name={`radioGroup-${arr.id}`}
                          value={radioValues[arr.id]}
                          onChange={(e) => {
                            const updatedRadioValues = { ...radioValues };
                            updatedRadioValues[arr.id] = e.target.value;
                            setRadioValues(updatedRadioValues);
                          }}
                        >
                          {arr?.answer.map((res, myId) => (
                            <div key={myId} className="mt-2 flex items-center">
                              <Radio
                                value={res.alphabet}
                                onClick={(event) => {
                                  event.preventDefault();
                                  trueDynamicAnswer(arr.id, res.alphabet);
                                }}
                              >
                                <Input
                                  value={res.answer}
                                  // width={"450px"}
                                  placeholder={`Answer ${res.alphabet}`}
                                  sizeText={18}
                                  styleInput="input-answer"
                                  onChange={(data) =>
                                    insertDynamicAnswer(
                                      data,
                                      arr.myId,
                                      res.alphabet
                                    )
                                  }
                                />
                              </Radio>
                            </div>
                          ))}
                        </Radio.Group>
                        <div className="border-b-2 border-[#A8A8A8] w-[93%] mt-4" />
                        <div className="flex flex-row w-[93%] justify-end mt-4">
                          <button
                            className={`${
                              isLastQuestion
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } mr-4 flex justify-center items-center`}
                            onClick={() => removeQuestion(arr.id)}
                            disabled={isLastQuestion}
                          >
                            <img
                              src="/assets/icons/delete.svg"
                              alt=""
                              className="w-4 h-4 mr-2"
                            />
                            <span>Delete</span>
                          </button>
                          <div
                            className="flex justify-center items-center cursor-pointer"
                            onClick={() => duplicateQuestion(arr.id)}
                          >
                            <img
                              src="/assets/icons/duplicate.svg"
                              alt=""
                              className="w-4 h-4 mr-2"
                            />
                            <span>Duplicate</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {question?.length === index + 1 && (
                    <div
                      onClick={() => appendQuestion(arr.myId)}
                      className="bg-white flex w-[100%] border-2 border-dashed h-[50px] mt-10 rounded-[10px] justify-center items-center cursor-pointer"
                    >
                      <img
                        src="/assets/icons/add.svg"
                        alt=""
                        className="cursor-pointer w-4 h-4"
                      />
                      <span className="ml-2 text-sm"> Add New Question</span>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTask;
