import React from "react";
import Title from "./components/Title";
import TopLeaderboard from "./components/TopLeaderboard";
import TalentInformation from "./components/TalentInformation";

const Dashboard = () => {
  return (
    <>
      <div className="w-[100%] p-12">
        <Title />
        <TalentInformation />
        <TopLeaderboard />
      </div>
    </>
  );
};

export default Dashboard;
