import React from "react";

const index = ({width = "20", height = "20", className = "", color = "#4C4C4C"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2336_5863)">
        <path
          d="M10 13.5713V19.2856"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 19.2856H15"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00007 0.714355H0.714355V5.00007C0.714355 7.367 2.63314 9.28578 5.00007 9.28578V0.714355Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 0.714355H19.2857V5.00007C19.2857 7.367 17.367 9.28578 15 9.28578V0.714355Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 0.714355V8.5715C5 11.3329 7.23857 13.5715 10 13.5715C12.7614 13.5715 15 11.3329 15 8.5715V0.714355H5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2336_5863">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default index;
