import React, { useState, useEffect } from "react";
import TableRedeemHistory from "./components/Table";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import ActionBarMaster from "../componets_global/ActionBarMaster";
import { DownloadOutlined } from "@ant-design/icons";
import { Pagination, Space } from "antd";
import RedeemHistoryRepo from "../../../repositores/adminRepo/RedeemHistoryRepo";

const host = process.env.REACT_APP_BASE;

const RedeemHistory = () => {
  const [dataHistories, setDataHistories] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    try {
      setLimit(10);
      setIsLoading(true);
      const response = await RedeemHistoryRepo.getListHistory(page, limit);

      if (response.status === 200) {
        const tempData = mapRedeemData(response?.data.data.content);
        setTotalItem(response?.data?.data?.totalElements);
        setDataHistories(tempData);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching rewards data:", error);
    }
  };

  const mapRedeemData = (data) => {
    return data.map((item, index) => ({
      key: index,
      name: item?.user?.fullname,
      email: item?.user?.username,
      rewards: item?.voucher?.description,
      quantity: item?.qty,
      date: item?.created_date,
    }));
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeSearch = async (el) => {
    try {
      setPage(1);
      setIsLoading(true);
      setDataHistories(null);
      const response = await RedeemHistoryRepo.getListHistory(
        page,
        limit,
        el.target.value
      );
      const tempData = mapRedeemData(response?.data.data.content);
      setDataHistories(tempData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data: ", error);
    }
  };

  const handleDownload = () => {
    setIsLoading(true);
    window.location.assign(
      `${host}api/v1/redeem-coins/generate-file`,
      "_blank"
    );
    fetchData();
    setIsLoading(false);
  };

  return (
    <div className="w-full h-screen px-4 py-5">
      <TitleMasterAdmin>Redeem History</TitleMasterAdmin>
      <ActionBarMaster
        buttonTitle={
          <Space>
            <DownloadOutlined className="flex py-1" /> Download
          </Space>
        }
        actionButton={handleDownload}
        onSearch={onChangeSearch}
        placeholder="Search Name"
      />
      <TableRedeemHistory dataSource={dataHistories} isLoading={isLoading} />
      <div className="w-full flex justify-center mt-5">
        <Pagination
          current={page}
          onChange={onChangePage}
          showSizeChanger={false}
          pageSize={limit}
          total={totalItem}
          className="flex justify-center"
        />
      </div>
    </div>
  );
};

export default RedeemHistory;
