import { CustomPlaceholder } from "react-placeholder-image";

export const EventThumbnail = ({ children, thumbnail }) => {
  const EventThumbnailOverlay = () => {
    return (
      <div className="absolute bottom-0 w-full h-[50%] bg-gradient-to-b from-transparent to-white" />
    );
  };

  return (
    <header className="relative w-full h-[175px]">
      <EventThumbnailOverlay />
      {thumbnail === null ? (
        <CustomPlaceholder
          className="placeholder-image"
          width={600}
          height={175}
          text="No Image"
        />
      ) : (
        <img
          alt="event-thumbnail"
          className="w-full h-full object-cover"
          // src="/assets/images/event-thumbnail.jpeg"
          src={thumbnail}
        />
      )}
      {children}
    </header>
  );
};

EventThumbnail.CTAButton = ({
  shouldRenderUploader,
  hideVerificationUploader,
  closeModal,
}) => {
  return (
    <button
      className={`absolute top-8 bg-red-700${
        shouldRenderUploader ? "left-8" : "right-8"
      }`}
      onClick={shouldRenderUploader ? hideVerificationUploader : closeModal}
    >
      {/* <img
        src={`/assets/icons/${
          shouldRenderUploader ? "arrow-back-circle" : "x"
        }.svg`}
        alt={`${shouldRenderUploader ? "arrow-back-circle" : "x"}`}
      /> */}
    </button>
  );
};
