import React, { useEffect, useState } from "react";
import TaskTable from "./components/Table";
import ActionBar from "./components/ActionBar";
import Title from "./components/Title";
import MasterTask from "../../../../repositores/adminRepo/MasterTaskRepo";
import { Pagination, Modal, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import AlertMiniPopUp from "../../componets_global/AlertMiniPopUp";

const Task = () => {
  const [page, setPage] = useState(0);
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [search, setSearch] = useState(undefined);
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });

  const [listTask, setListTask] = useState([]);

  const initialFetch = async () => {
    const response = await MasterTask.getListData(page, limit, search);
    if (response.data.status === 200) {
      setListTask(response?.data?.data?.content);
      setTotalItem(response?.data?.data?.totalElements);
      setLoading(false);
    }
  };

  const onChangePage = (page) => {
    setPage(page - 1);
  };

  const showDeleteConfirm = (id) => {
    setOpenModalDelete(true);
    setDeleteId(id);
  };

  const handleDeleteCancel = () => {
    setOpenModalDelete(false);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    let response = await MasterTask.deleteTask(deleteId);
    if (response.data.status === 200) {
      const response = await MasterTask.getListData(page, limit, search);
      setMessageAlert({ message: response?.data.message, status: true });
      setTimeout(() => {
        setMessageAlert({ message: "", status: null });
      }, 1000);
      setListTask(response?.data?.data?.content);
      setLoading(false);
      setToggleRefresh(!toggleRefresh);
    } else {
      setMessageAlert({ message: response?.data?.message, status: false });
      setTimeout(() => {
        setMessageAlert({ message: "", status: null });
      }, 1000);
      setLoading(false);
      setToggleRefresh(!toggleRefresh);
    }
    setOpenModalDelete(false);
  };

  useEffect(() => {
    initialFetch();
  }, [page, search, limit]);

  useEffect(() => {}, [messageAlert]);

  const onSearch = (data) => {
    setSearch(data.target.value);
  };

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="w-full h-screen px-4 py-5">
        <Title />
        <ActionBar onSearch={onSearch} />
        <TaskTable
          DataTable={listTask.map((item) => ({
            ...item,
            key: item.id,
          }))}
          onDelete={showDeleteConfirm}
          isLoading={loading}
        />
        <div className="w-full flex justify-center mt-5">
          <Pagination
            current={page + 1}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
          />
        </div>
        <Modal
          open={openModalDelete}
          onOk={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          width={350}
          footer={null}
          closable={false}
          keyboard={false}
        >
          <div className="flex items-center gap-2">
            <ExclamationCircleFilled
              style={{ fontSize: "20px", color: "#faad14" }}
            />
            <p className="text-black text-[16px] font-medium">
              Are you sure to delete this Task ?
            </p>
          </div>
          <div className="flex justify-end mt-5 gap-[10px]">
            <Button onClick={() => setOpenModalDelete(false)}>No</Button>
            <Button onClick={handleDeleteConfirm} type="primary" danger>
              Yes
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Task;
