import React from "react";
import { Button, Table, Image, Space } from "antd";
import Coin from "../../../../../assets/icons/coin.png";
import { DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import SkeletonTable from "./SkeletonTable";

const expandedRowRender = (data) => {
  const columns = [
    {
      title: "",
      dataIndex: "task",
      key: "task",
      render: (_, record) => {
        return (
          <div className="flex justify-between w-[80%]">
            <div className="pl-[35px]">{record?.task?.title}</div>
            <div>{record?.type?.name}</div>
            <div>{record?.grade}%</div>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.map((item) => ({
        ...item,
        key: item.id,
      }))}
      pagination={false}
    />
  );
};

function formatDateTime(date) {
  let tempDate = new Date(date);

  let dateFormated = `${addLeadingZero(tempDate.getDate())}/${addLeadingZero(
    tempDate.getMonth() + 1
  )}/${tempDate.getFullYear()}`;
  let timeFormated = `${addLeadingZero(tempDate.getHours())}:${addLeadingZero(
    tempDate.getMinutes()
  )}:${addLeadingZero(tempDate.getSeconds())}`;
  return `${dateFormated}, ${timeFormated}`;
}

function addLeadingZero(number) {
  return number < 10 ? `0${number}` : `${number}`;
}

const TableEvent = ({ dataTable, isLoading, selectDelete, selectEdit }) => {
  const columns = [
    {
      title: "Created Date",
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (_, record) => (
        <div className="flex justify-center">
          <div className="pt-[5px] text-dark-grey">
            {record.created_date ? formatDateTime(record.created_date) : "-"}
          </div>
        </div>
      ),
    },
    {
      title: "Master Event Name",
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            <div className="pt-[5px] ">{record.name}</div>
          </div>
        );
      },
    },
    {
      title: "Master Event Type",
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (_, record) => (
        <div className="flex justify-between pr-[20px]">
          <div className="pt-[5px]  text-dark-grey">
            {record.eventType.name}
          </div>
        </div>
      ),
    },
    {
      title: "Journey Type",
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (_, record) => (
        <div className="flex justify-center">
          <div className="pt-[5px]  text-dark-grey">{record.journey.name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex justify-start gap-3 items-center">
          <CheckCircleOutlined className="text-primary" />
          <div>Passing Grade</div>
        </div>
      ),
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (_, record) => (
        <div className="flex justify-center">
          <div className="pt-[5px]  text-dark-grey">{record.grade}%</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex justify-between items-center">
          <div className="rounded-full bg-[#D0CBFF] w-[20px] h-[20px] text-center text-[#4F40F4] pt-1 mr-2">
            <p className="text-xxs">XP</p>
          </div>
          <div>XP</div>
        </div>
      ),
      dataIndex: "id",
      align: "center",
      render: (_, record) => (
        <div className="flex justify-center">
          <div className="flex">
            <p className="pt-1">{record.xp}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex justify-between pr-[20px]">
          <div className="flex ml-[25px]">
            <div>
              <Image width={30} src={Coin} />
            </div>
            <p className="pt-1 ml-2">Coin</p>
          </div>
        </div>
      ),
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (_, record) => (
        <Space className="flex justify-center items-center">{record.gc}</Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div>
          <Button danger className="mr-1" onClick={() => selectEdit(record)}>
            Edit
          </Button>
          <Button type="text" onClick={() => selectDelete(record)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];
  if (dataTable) {
    dataTable.forEach((_data) => {
      _data.key = _data.id;
      if (_data.masterEventTasks && _data.masterEventTasks.length) {
        _data.masterEventTasks.forEach((_task) => (_task.key = _task.id));
      }
    });
  }

  if (isLoading) {
    return <SkeletonTable />;
  }

  return (
    <Table
      expandable={{
        loading: isLoading,
        defaultExpandAllRows: true,
        expandIcon: ({ expanded }) => (expanded ? <></> : <></>),
        expandedRowRender: (record) =>
          expandedRowRender(record.masterEventTasks),
      }}
      columns={columns}
      dataSource={dataTable?.map((item) => ({
        ...item,
        key: item.id,
      }))}
      pagination={false}
    />
  );
};
export default TableEvent;
