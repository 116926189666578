import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { Form, Input, Select } from "antd";
const { TextArea } = Input;

const FormOneMonthSupervised = forwardRef((props, ref) => {
  const [formOneMonth] = Form.useForm();
  const [labelColFlex, setLabelColFlex] = useState("150px");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setLabelColFlex("80px");
    } else {
      setLabelColFlex("150px");
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    validateFields() {
      return formOneMonth.validateFields();
    },
    getFieldValue(name) {
      return formOneMonth.getFieldValue(name);
    },
    resetForm() {
      formOneMonth.resetFields();
    },
  }));

  const optStatus = [
    {
      value: "NEW",
      label: "NEW",
    },
    {
      value: "DONE",
      label: "DONE",
    },
    {
      value: "INPROGRESS",
      label: "INPROGRESS",
    },
  ];

  useEffect(() => {
    if (props.formType === "edit" && props.dataEdit) {
      formOneMonth.setFieldsValue({
        pic: props.dataEdit.pic,
        status: props.dataEdit.status,
        remarkByTalent: props.dataEdit.remarkByTalent,
      });
    }
  }, [props.formType, props.dataEdit]);

  return (
    <Form
      labelCol={{ flex: labelColFlex }}
      labelAlign="left"
      form={formOneMonth}
      colon={false}
      wrapperCol={{ flex: 1 }}
    >
      <Form.Item
        label="PIC"
        name="pic"
        rules={[
          {
            required: true,
            message: "Please input PIC",
          },
        ]}
      >
        <Input placeholder="Please input PIC" type="text" />
      </Form.Item>
      <Form.Item
        label="Status"
        name="status"
        rules={[
          {
            required: true,
            message: "Please select status",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Choose status"
          options={optStatus}
        />
      </Form.Item>
      <Form.Item
        label="Talent Remarks"
        name="remarkByTalent"
        rules={[
          {
            message: "Please input Talent Remarks",
          },
        ]}
      >
        <TextArea placeholder="Please input Talent Remarks" />
      </Form.Item>
    </Form>
  );
});

export default FormOneMonthSupervised;
