import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const BASE_PATH = `retro-interview-candidate`;

const getListAdmin = async (
  page = 0,
  size = 10,
  interviewerName = "",
  candidateName = "",
  position = ""
) => {
  let params = {
    page: page + 1,
    size: size,
    interviewerName: interviewerName,
    candidateName: candidateName,
    position: position,
  };
  /* if (typeof name != "undefined" && name.length) {
      params["description"] = name;
    } */
  const url = `${API_BASE}/${BASE_PATH}/list-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const submitChecked = async (id) => {
  /* {{host}}/v1/retro-interview-candidate/user-cheklis-skill */
  const body = {
    id: id,
  };
  const url = `${API_BASE}/${BASE_PATH}/user-cheklis-skill`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
  /* id */
};
/*   const submitMultiChoice = async ( id, body) => {
    const url = `${API_BASE}/sub-matkull-pg/user-submit-pg/users-courses-exam/${id}`;
    const response = await CallApi({ url, method: "PUT", data: body });
    return response;
  } */
const getUserURLCourseId = async (courseId = "", matkulId = "") => {
  const url = `${API_BASE}/${BASE_PATH}/user-get-url-course/${courseId}/matkulId/${matkulId}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const ChecklistSkillInterview = {
  getListAdmin,
  submitChecked,
  getUserURLCourseId
};

export default ChecklistSkillInterview;
