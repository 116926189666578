import Swal from "sweetalert2";
import React, { useState,useEffect } from "react";
import { Modal } from "antd";
import AlertUtility from "../../../../components/Alert/Alert";
import MasterUserManagementRepo from "../../../../repositores/adminRepo/UserManagement";
import AlertMiniPopUp from "../../componets_global/AlertMiniPopUp"

const ModalInvite = ({ show, closeModal }) => {

  const baseUrl = process.env.REACT_APP_BASE
  const [disable, setDisable] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messageAlert,setMessageAlert]= useState({message:"",status:"false"});
  useEffect(()=>{},[messageAlert])  
  const onInviteUser = () => {
    if (name == "" || email == "") {
      return setMessageAlert({
        message: "Name or Email cannot be empty!",
      });
    }
    
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.match(validRegex)) {
      return setMessageAlert({
        message: "Email must be a valid email!",
      }); 
    }

    inviteUser();
  };

  const inviteUser = async () => {
    setDisable(true);
    // Swal.fire({
    //   title: "Process",
    //   toast: true,
    //   text: "Please wait a moment...",
    //   position: "top-end",
    //   showConfirmButton: false,
    //   timer: 2000,
    // });
    try {
      const urlFe = `${process.env.REACT_APP_BASE_FE}activate-account?email=${email}`
      const payload = {
        email,
        fullName: name,
        password: "password",
        urlFe,
      };
      const { data } = await MasterUserManagementRepo.adminInviteUser(payload);
      if (data.status !== 200) {
        setMessageAlert({ message:data?.message ,status:false });
        setTimeout(() => {
          setMessageAlert({message:"",status:null})
      }, 1000);
      } else {
        setMessageAlert({ message: "Please check email to activate account!" ,status:true });
        setTimeout(() => {
          setMessageAlert({message:"",status:null})
      }, 1000);
        onCloseModal()
      }
    } catch (error) {
      setMessageAlert({ message: error?.response.message });
    } finally {
      setDisable(false);
    }
  };

  const onCloseModal = () => {
    setEmail("")
    setName("")
    closeModal()
  }

  return (
    <>
    {messageAlert?.message !=="" &&(
            <AlertMiniPopUp
            messageAlert={messageAlert.message}
            isSuccess={messageAlert.status}/>
         )}
    <Modal open={show} onCancel={() => onCloseModal()} footer={null}>
      <div className="mb-8 font-medium">
        <div className="mb-1 text-xl text-[#4C4C4C]">Invite User</div>
        <div className="text-xs text-[#A8A8A8]">Enter your data below!</div>
      </div>
      <div>
        <div className="flex items-center mb-2">
          <div className="w-2/6 font-medium">
              Full Name<span className="text-red-500">*</span>
          </div>
          <input 
            type="text" 
            className="border rounded-md w-full p-2" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
          />
        </div>
        <div className="flex items-center mb-6">
          <div className="w-2/6 font-medium">
              Email<span className="text-red-500">*</span>
          </div>
          <input 
            type="email" 
            className="border rounded-md w-full p-2" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => onInviteUser()}
            className={`rounded-md bg-[#DD0000] font-semibold text-white py-2 px-8 ${disable ? 'pointer-events-none opacity-50' : ''}`}
          >
            Invite
          </button>
        </div>
      </div>
    </Modal>
    </>
  );
};

export default ModalInvite;
