import { Button, Form, Input, Modal, Select, Space, Table, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext, useEffect } from "react";
import { JourneyContext } from "../contexts/journeyContext";
import { CloseCircleOutlined } from "@ant-design/icons";

const ModalJourney = ({
  openModal,
  onClick,
  title,
  getData,
  dataEdit,
  instanceForm,
}) => {
  const {
    handleSearch,
    options,
    setParticipants,
    participants,
    handleChangeUser,
    currentUsersList,
    setParticipantsExist,
    handleRemoveParticipant,
    participantsExist,
  } = useContext(JourneyContext);

  useEffect(() => {
    instanceForm.setFieldsValue({
      name: dataEdit?.name,
      description: dataEdit?.description,
      type: dataEdit?.type,
    });
  }, [dataEdit, instanceForm, title]);

  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      //setParticipants([]);
      //handleDeselectAll();
      if (participants.length > 0) {
        setParticipantsExist((prev) => [...prev, ...participants]);
      }
      setParticipants([]);
    }
  };

  const userViewColumn = [
    {
      title: (
        <div className="flex justify-start gap-3 items-center">
          <div>Event Name</div>
          <div className="cursor-pointer">
            <img
              src="/assets/icons/filter.svg"
              alt="icon-filter"
              width={15}
              height={14}
            />
          </div>
        </div>
      ),
      key: "id",
      dataIndex: "id",
      render: (value, record) => {
        return <Space>{record.eventName ?? "-"}</Space>;
      },
    },
    {
      title: (
        <div className="flex justify-start gap-3 items-center">
          <div>Participant Name</div>
          <div className="cursor-pointer">
            <img
              src="/assets/icons/filter.svg"
              alt="icon-filter"
              width={15}
              height={14}
            />
          </div>
        </div>
      ),
      key: "id",
      dataIndex: "id",
      render: (value, record) => {
        return <Space>{record.user?.fullname}</Space>;
      },
      /* onFilter: (value, record) => record.event.indexOf(value) === 0, */
    },
  ];

  return (
    <Modal
      open={openModal}
      onOk={onClick}
      onCancel={() => {
        setParticipants([]);
        onClick();
      }}
      footer={null}
    >
      <div>
        <p className="text-[20px] font-[500] text-[#4C4C4C]">{title} Journey</p>
        <p className="text-[12px] font-[500] text-[#A8A8A8] mb-[40px]">
          Enter your data below!
        </p>
        <Form
          name={title}
          form={instanceForm}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 17 }}
          style={{ maxWidth: 600 }}
          onFinish={(props) =>
            getData(
              "success",
              props,
              participantsExist.map((item) => ({ id: item.value }))
            )
          }
          onFinishFailed={(props) => getData("failed", props)}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Enter name",
              },
            ]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Enter description",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Description Goes Here" />
          </Form.Item>
          {title.toLowerCase() === "edit" ? (
            <>
              <Form.Item label="Participants" name="Participants">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                >
                  <Select
                    mode="multiple"
                    filterOption={false}
                    allowClear
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="Add Participant"
                    onChange={handleChangeUser}
                    onSearch={handleSearch}
                    optionFilterProp="children"
                    options={options}
                    defaultActiveFirstOption={false}
                    notFoundContent={null}
                    value={participants}
                    dropdownRender={(menu) => <div>{menu}</div>}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                  />
                </Space>
              </Form.Item>

              <Form.Item>
                <Space size={[0, 8]} wrap className="mb-[50px]">
                  {participantsExist.length ? (
                    participantsExist.map((participant, index) => {
                      if (!participant.isDeleted) {
                        return (
                          <Tag key={"tagid-" + index + "-" + participant.key}>
                            <div className="flex">
                              <p className="pt-[6px]">{participant.label}</p>
                              <Button
                                type="link"
                                style={{
                                  padding: "0",
                                  color: "black",
                                  marginLeft: "10px",
                                  paddingBottom: "",
                                }}
                                onClick={() => {
                                  handleRemoveParticipant(participant.key);
                                }}
                              >
                                <CloseCircleOutlined />
                              </Button>
                            </div>
                          </Tag>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </Space>
              </Form.Item>
            </>
          ) : null}
          {title.toLowerCase() === "view" ? (
            <Table
              dataSource={currentUsersList}
              columns={userViewColumn}
              pagination={{ defaultPageSize: 5, defaultCurrent: 1 }}
            />
          ) : null}
          {title.toLowerCase() !== "view" ? (
            <Form.Item>
              <div className="flex w-[450px]">
                <Button
                  className="bg-red-500 radius-[7px] w-[125px] h-[30px] ml-auto"
                  style={{ backgroundColor: "red" }}
                  type="primary"
                  htmlType="submit"
                >
                  <p className="text-[12px] font-[500] text-white">{title}</p>
                </Button>
              </div>
            </Form.Item>
          ) : null}
        </Form>
      </div>
    </Modal>
  );
};

export default ModalJourney;
