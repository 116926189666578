import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import "../index.css";
import UploadIcon from "../../../../assets/icons/box-upload.png";

const ModalRewards = ({
  openModal,
  onClick,
  title,
  listLevel,
  getData,
  dataEdit,
  intanceForm,
}) => {
  const [fileImage, setFileImage] = useState(null);
  const { Option } = Select;

  useEffect(() => {
    intanceForm.setFieldsValue({
      name: dataEdit?.description || "",
      nominal: dataEdit?.nominal || "",
      coin: dataEdit?.gc || "",
      icon: dataEdit?.urlImage || "",
      // "status": "INACTIVE" || '',
      stock: dataEdit?.quota || "",
      "level required": dataEdit?.level.id || "",
    });
    if (dataEdit) {
      setFileImage({
        uid: "-1",
        name: dataEdit?.image,
        status: "done",
        url: dataEdit?.image,
      });
    }
  }, [dataEdit, intanceForm, title]);

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_BASE}api/v1/upload`,
    headers: {
      authorization: "authorization-text",
    },
    fileList: fileImage && [fileImage],
    maxCount: 1,
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setFileImage({
          uid: info.file.uid,
          name: info.file.name,
          status: "done",
          url: info.file.name,
        });
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") {
        setFileImage(undefined);
      }
    },
  };

  return (
    <Modal open={openModal} onOk={onClick} onCancel={onClick} footer={null}>
      <div>
        <p className="text-[20px] font-[500] text-[#4C4C4C]">{title} Rewards</p>
        <p className="text-[12px] font-[500] text-[#A8A8A8] mb-[40px]">
          Enter your data below!
        </p>
        <Form
          name={title}
          form={intanceForm}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 17 }}
          style={{ maxWidth: 600 }}
          onFinish={(props) => getData("success", props)}
          onFinishFailed={(props) => getData("failed", props)}
          autoComplete="off"
        >
          <Form.Item
            label="Icon"
            name="icon"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Input reward icon",
              },
            ]}
          >
            <Upload {...props}>
              <Input
                className="text-[#ff0000]"
                readOnly
                placeholder={"Input reward icon"}
                suffix={
                  <img
                    src={UploadIcon}
                    alt="UploadButton"
                    className="w-[14px] h-[14px] ml-[10px]"
                  />
                }
              />
            </Upload>
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Enter rewards name",
              },
            ]}
          >
            <Input placeholder="Enter rewards name" />
          </Form.Item>

          <Form.Item
            label="Nominal"
            name="nominal"
            rules={[
              {
                required: true,
                message: "Enter rewards nominal",
              },
            ]}
          >
            <InputNumber
              className="w-full custom-input-number"
              step={null}
              min={1}
              placeholder="Enter rewards nominal"
            />
          </Form.Item>

          <Form.Item
            label="Coin"
            name="coin"
            rules={[
              {
                required: true,
                message: "Enter coin",
              },
            ]}
          >
            <InputNumber
              className="w-full custom-input-number"
              step={null}
              min={1}
              placeholder="Enter coin"
            />
          </Form.Item>

          <Form.Item
            label="Stock"
            name="stock"
            rules={[
              {
                required: true,
                message: "Enter stock",
              },
            ]}
          >
            <InputNumber
              className="w-full custom-input-number"
              step={null}
              min={0}
              placeholder="Enter stock"
            />
          </Form.Item>

          <Form.Item
            label="Level Required"
            name="level required"
            rules={[
              {
                required: true,
                message: "Enter level required",
              },
            ]}
          >
            <Select placeholder="Enter level required">
              {listLevel
                ?.sort((a, b) => a.level - b.level)
                .map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.level}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div className="flex w-[450px]">
              <Button
                className="bg-red-500 radius-[7px] w-[125px] h-[30px] ml-auto"
                style={{ backgroundColor: "red" }}
                type="primary"
                htmlType="submit"
              >
                <p className="text-[12px] font-[500] text-white">Next</p>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalRewards;
