import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import Reward from './pages/talent/Reward/reward';
// import Sertifikat from "./pages/talent/certificate/Certificate";
// import Dashboard from "./pages/admin/dashboard/Dashboard";

const root = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>  */}
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <Provider store={store}>
        {/* <Dashboard/> */}
        <App />
        {/* <Reward/> */}
        {/* <Sertifikat /> */}
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
