import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import MasterUserManagementRepo from "../../../../repositores/adminRepo/UserManagement";
import dayjs from "dayjs";
import "dayjs/locale/es-us";

const { Option } = Select;

const ModalPlacementHistory = ({
  openModal,
  onClick,
  title,
  getData,
  dataEdit,
  instanceForm,
}) => {
  const [selectedUser, setSelectedUser] = useState(dataEdit?.user?.id || []);
  const [optionsUser, setOptionsUser] = useState([]);
  const [page, setPage] = useState(1);
  const [totalElementUser, setTotalElementUser] = useState(-1);
  const [mounted, setMounted] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    instanceForm.setFieldsValue({
      testDate: dataEdit?.testDate ? dayjs(dataEdit.testDate) : null,
      clientName: dataEdit?.clientName,
      feedbackFromPIC: dataEdit?.feedbackFromPIC,
      status: dataEdit?.status,
      user: dataEdit?.user?.fullname,
    });
    setSelectedUser(dataEdit?.user?.id);
  }, [dataEdit, instanceForm, title]);

  useEffect(() => {
    if (!mounted) {
      handleFetchMoreUser(page, 20);
    }
    return () => {
      setMounted(true);
    };
  }, [mounted, page]);

  const handleScrollEvent = (event) => {
    event.stopPropagation();

    if (!loadingMore) {
      handleFetchMoreUser(page, 20);
    }
  };

  const handleSearchUser = async (value) => {
    setLoadingMore(true);

    if (value === "") {
      await handleFetchMoreUser(1, 10);
    } else {
      const response = await MasterUserManagementRepo.getUserManagement(
        0,
        10,
        value
      );
      const tempOptions = response.data?.data?.content || [];
      setOptionsUser(tempOptions);
      setPage(1);
    }
    setLoadingMore(false);
  };

  const handleFetchMoreUser = async (page, limit) => {
    if (optionsUser.length < totalElementUser || totalElementUser === -1) {
      const response = await MasterUserManagementRepo.getUserManagement(
        page - 1,
        limit
      );
      const tempOptions = response?.data?.data?.content || [];
      setOptionsUser((prevOptions) => [...prevOptions, ...tempOptions]);
      setLoadingMore(false);
      setTotalElementUser(response.data?.data?.totalElements);
      setPage(page + 1);
    }
  };

  return (
    <Modal
      open={openModal}
      onOk={onClick}
      onCancel={onClick}
      footer={null}
      width={600}
      centered
    >
      <div>
        <p className="text-[20px] font-[500] text-[#4C4C4C]">
          {title} Placement Test History
        </p>
        <p className="text-[12px] font-[500] text-[#A8A8A8] mb-[40px]">
          Enter your data below!
        </p>
        <Form
          name={title}
          form={instanceForm}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 6 }}
          style={{ maxWidth: 600 }}
          onFinish={(props) => getData("success", props, selectedUser)}
          onFinishFailed={(props) => getData("failed", props, selectedUser)}
          autoComplete="off"
        >
          <Form.Item label="Talent Name" name="user">
            <Select
              className="w-full"
              value={selectedUser}
              onChange={(val) => {
                setSelectedUser(val);
              }}
              showSearch
              placeholder="Choose a Talent Name"
              loading={loadingMore}
              onSearch={handleSearchUser}
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <div onWheel={handleScrollEvent}>{menu}</div>
              )}
            >
              {optionsUser.map((user) => {
                return (
                  <Option key={user.id} value={user.id}>
                    {user.fullname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Status" name="status">
            <Select placeholder="Select status">
              <Option value="NEW">NEW</Option>
              <Option value="PASSED">PASSED</Option>
              <Option value="FAILED">FAILED</Option>
              <Option value="CANCELED">CANCELED</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Test Date"
            name="testDate"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Input Date",
              },
            ]}
          >
            <DatePicker
              placeholder="Pick test date"
              style={{ width: "100%" }}
              // onChange={onChangeDatePicker}
              format="DD-MM-YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Client Name"
            name="clientName"
            rules={[
              {
                required: true,
                message: "Enter client name",
              },
            ]}
          >
            <Input placeholder="Enter client name" />
          </Form.Item>
          <Form.Item
            label="Feedback From PIC"
            name="feedbackFromPIC"
            rules={[
              {
                required: true,
                message: "Enter feedback from PIC",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Feedback From PIC Here" />
          </Form.Item>
          {title.toLowerCase() !== "view" ? (
            <Form.Item>
              <div className="flex justify-center">
                <Button
                  className="bg-red-500 radius-[7px] w-40 h-[30px]"
                  style={{ backgroundColor: "red" }}
                  type="primary"
                  htmlType="submit"
                >
                  <p className="text-[12px] font-[500] text-white">
                    {dataEdit ? "Update" : "Create"}
                  </p>
                </Button>
              </div>
            </Form.Item>
          ) : null}
        </Form>
      </div>
    </Modal>
  );
};

export default ModalPlacementHistory;
