import moment from "moment";

export class DateUtil {
  /** @type {moment} */
  instance
  
  useMoment() {
    this.instance = moment;
    return this.instance;
  }
}
