import React from "react";

export const EventAttribute = ({
  image: { alt, url },
  label: { text, color = "text-[#4C4C4C]" },
}) => {
  return (
    <div className="inline-flex items-center gap-2">
      <img alt={alt} className="w-4 h-4 object-cover" src={url} />
      <p className={`${color} text-[6px] md:text-xs font-medium`}>{text}</p>
    </div>
  );
};
