export const JourneyLinePath = () => {
  return (
    <div className="hidden md:block">
      <svg
        className="absolute left-0 top-1/2 -translate-y-1/2 w-full"
        height="50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0"
          y1="0"
          x2="9999"
          y2="0"
          stroke="#EAEAEA"
          strokeWidth="6"
          strokeLinecap="round"
          strokeDasharray="20 20"
        />
      </svg>
    </div>
  );
};
