import { Modal, Button, Form, Input, Space, Radio } from "antd";
import { useEffect, useState } from "react";

const { TextArea } = Input;

export const PopUp = ({
  open,
  setOpen,
  handleSubmit = (id, desc) => {},
  title,
  description,
  setDescription = (val) => {},
  feedback,
  setFeedback = (val) => {},
  data,
  isLoading,
  multiChoiceOptions = [],
  handleMultiChoiceAswers = (id, value) => {},
}) => {
  const [form] = Form.useForm();

  useEffect(() => {}, []);

  return (
    <Modal
      className="talent-journey-modal"
      title={title}
      centered
      width={1000}
      open={open}
      footer={[]}
      onCancel={() => {
        setOpen(false);
      }}
      destroyOnClose={true}
    >
      <Form
        className="w-full"
        labelCol={{
          span: 9,
        }}
        preserve={false}
        form={form}
        autoComplete="off"
        onFinish={(e) => {
          handleSubmit(data.id, e.url, e.feedback);
          setDescription("");
          setFeedback("");
        }}
        onFinishFailed={(e) => {
          // setVal("asdasdqweqweqwe");
        }}
      >
        <div className=" overflow-auto h-[600px] w-full my-3">
          <Form.Item
            name="url"
            label="Write your answer/URL if any"
            initialValue={description}
            className=""
            labelAlign="left"
          >
            <TextArea rows={4} placeholder="Write your answer/URL" name="url" />
          </Form.Item>

          <Form.Item
            name="feedback"
            label="Give us your feedback"
            initialValue={feedback}
            labelAlign="left"
          >
            <TextArea
              rows={4}
              placeholder="Give us your feedback"
              name="feedback"
              value={data.feedback}
            />
          </Form.Item>

            <Form.Item
                name="nilai"
                label="Nilai"
                initialValue={data.nilai}
                labelAlign="left"
            >
              <Input name="nilai" value={data.nilai} disabled />
            </Form.Item>

            <Form.Item
              name="status"
              label="Status"
              labelAlign="left"
              initialValue={data.nilaiStatus}
            >
              <Input name="status" value={data.nilaiStatus} disabled />
            </Form.Item>

            <Form.Item
              name="fasil"
              label="Feedback by Fasilitator"
              labelAlign="left"
              initialValue={data.feedbackByFacil}
            >
              <TextArea
                rows={4}
                name="fasil"
                value={data.feedbackByFacil}
                disabled
              />
            </Form.Item>

            
          <p>Multiple choice:</p>
          <div className="w-full  border mb-5">
            {multiChoiceOptions.length > 0 &&
              multiChoiceOptions?.map((choice, i) => {
                const valuesForm = {
                  options: [
                    { value: "A", label: choice?.opsiA },
                    { value: "B", label: choice?.opsiB },
                    { value: "C", label: choice?.opsiC },
                    { value: "D", label: choice?.opsiD },
                    { value: "E", label: choice?.opsiE },
                  ],
                  initialValue: 0, // Set initial selection to "Option B"
                  onChange: (selectedValue) => {
                    console.log("Selected value:", selectedValue);
                    // Perform additional actions based on the selected value (optional)
                  },
                };
                return (
                  <MyScrollableRadioForm
                    key={choice.id}
                    numbering={i + 1}
                    {...valuesForm}
                    id={choice.id}
                    questionText={choice?.soal}
                    handleMultiChoiceAswers={handleMultiChoiceAswers}
                  />
                );
              })}
          </div>
        </div>
        <Form.Item>
          {/* {data.feedback !== null || data.description !== null ? (
            <Space className="flex justify-end">
              <Button
                type="danger"
                onClick={() => {
                  setOpen(false);
                }}
                danger
              >
                Close
              </Button>
            </Space>
          ) : ( */}
          <Space className="flex justify-end">
            {isLoading ? (
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#1677ff", color: "#fff" }}
                loading
              >
                Submit
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#1677ff", color: "#fff" }}
              >
                Submit
              </Button>
            )}
          </Space>
          {/* )} */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const MyScrollableRadioForm = ({
  id,
  options,
  initialValue,
  numbering,
  onChange,
  questionText,
  handleMultiChoiceAswers = (id, value) => {},
}) => {
  const [value, setValue] = useState(null); // Set default value

  const handleRadioChange = (e) => {
    setValue(e.target.value);
    onChange?.(e.target.value); // Optional callback for external handling
    handleMultiChoiceAswers(id, e.target.value);
  };

  // Calculate overflow height based on options length
  const scrollableHeight = Math.min(options.length * 40, 200); // Adjust as needed

  return (
    <div name="requiredMarkValue" className="flex flex-col px-10 my-5">
      <div className="mb-3">
        {numbering}. {questionText}
      </div>
      <Radio.Group onChange={handleRadioChange} value={value}>
        {options.map((option, index) => (
          <Radio key={index} value={option.value} className="w-full">
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};
