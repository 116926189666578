import { Card } from "antd";
import ModalDetail from "./ModalDetail";
import React, { useState, useEffect } from "react";
import MasterDashboardRepo from "../../../../repositores/adminRepo/DashboardRepo";

const CardTalent = ({ talent, idx }) => {
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const closeModal = () => setShow(false);

  const toggleHistoryModal = (show) => {
    setShowHistory(show);
  };

  return (
    <Card className="shadow-md mb-4">
      <div className="flex flex-col items-center md:flex-row md:items-center">
        {/* crown */}
        <div className="relative flex-none mb-4 md:mb-0 md:mr-8 flex justify-center md:justify-start w-full md:w-auto">
          {idx <= 2 ? (
            <img
              src={`/assets/images/${idx === 0 ? "crown" : "crown-silver"}.svg`}
              alt=""
              className="mx-auto md:mx-0"
            />
          ) : (
            <div className="w-[34px]"></div>
          )}
          <div
            className={`absolute top-0 bottom-0 right-0 left-0 m-auto text-center font-bold ${
              idx <= 2 ? "text-white" : ""
            }`}
          >
            {idx + 1}
          </div>
        </div>
        {/* name */}
        <div className="grow mb-4 md:mb-0 w-full md:w-auto flex justify-center md:justify-start">
          <div className="flex items-center">
            <div className="w-[64px] h-[64px] text-center bg-[#d9d9d9] border-[3px] rounded-full border-[#fff] shadow-lg items-center flex justify-center mr-2">
              <div className="text-3xl font-bold text-[#979797]">
                {talent.fullName[0].toUpperCase()}
              </div>
            </div>
            <div>
              <div className="font-semibold text-[#4c4c4c]">
                {talent.fullName}
              </div>
            </div>
          </div>
        </div>
        {/* xp */}
        <div className="flex items-center mb-4 md:mb-0 md:mr-6 w-full md:w-[100px] justify-between md:justify-start">
          <div className="w-[35px] h-[35px] rounded-full bg-[#D0CBFF] text-[#4F40F4] font-bold items-center flex justify-center mr-2 text-sm">
            XP
          </div>
          <div className="text-[#4C4C4C] font-bold">{talent.totalXp}</div>
        </div>
        {/* icon */}
        <div className="flex items-center mb-4 md:mb-0 md:mr-8 w-full md:w-[100px] justify-between md:justify-start">
          <img src="/assets/images/coin.svg" alt="" className="mr-2" />
          <div className="text-[#4C4C4C] font-bold">{talent.totalGc}</div>
        </div>
        {/* detail */}
        <div className="flex justify-center md:justify-end w-full md:w-auto">
          <div
            className="border rounded px-3 py-1 border-[#DD0000] text-[#DD0000] font-bold cursor-pointer"
            onClick={() => setShow(true)}
          >
            Detail
          </div>
        </div>
      </div>

      <ModalDetail
        showHistory={showHistory}
        toggleHistoryModal={toggleHistoryModal}
        show={show}
        closeModal={closeModal}
        currentTalent={talent}
      />
    </Card>
  );
};

const TopLeaderboard = () => {
  const [dataTalents, setDataTalents] = useState([]);

  const getDataLeaderboard = async () => {
    try {
      const { data } = await MasterDashboardRepo.getTopLeaderboard();
      setDataTalents(data.data);
    } catch (error) {
      setDataTalents([]);
    }
  };

  useEffect(() => {
    getDataLeaderboard();
  }, []);

  return (
    <>
      <div className="text-xl font-semibold text-center mb-8">
        Top 10 Leaderboard
      </div>
      {dataTalents &&
        dataTalents.map((talent, idx) => {
          return <CardTalent idx={idx} talent={talent} key={idx} />;
        })}
    </>
  );
};

export default TopLeaderboard;
