import React from "react";
import { Button, Table, Image } from "antd";
import PercentageEventMaster from "./PercentageEventMaster";
import Coin from "../../../../../assets/icons/coin.png";
import userCircleSingle from "../../../../../assets/icons/userCircleSingle.png";
import { DeleteOutlined } from "@ant-design/icons";
import SkeletonTable from "./SkeletonTable";
import { EVENT_STATUS } from "../../../../../utils/constant";

const expandedRowRender = (
  data,
  selectToFinish,
  selectToDetail,
  selectDelete,
  selectEdit,
  handleViewQR
) => {
  const columns = [
    {
      title: "",
      dataIndex: "task",
      key: "task",
      render: (_, record) => {
        let startTimeArr =
          record?.startTime && record?.startTime.length
            ? record.startTime.split(":")
            : [];
        let endTimeArr =
          record?.endTime && record?.endTime.length
            ? record.endTime.split(":")
            : [];
        return (
          <div className="flex justify-between w-[100%]">
            <div className="w-[20px]">{record.rowNumber}</div>
            <div>{record.name}</div>
            <div className="w-[200px]">
              {record.startDate} - {record.endDate}
            </div>
            <div className="w-[152px]">
              {startTimeArr.length
                ? `${startTimeArr[0]}:${startTimeArr[1]}`
                : ""}{" "}
              {endTimeArr.length ? `- ${endTimeArr[0]}:${endTimeArr[1]}` : ""}
            </div>
            <div className="flex justify-between w-[60px]">
              <img
                className="w-[20px] h-[20px]"
                alt="icon-user"
                src={userCircleSingle}
              />
              <p className="ml-[10px]">{record.participants.length}</p>
            </div>
            <div className="w-[100px] text-center">
              {record.status === EVENT_STATUS.onGoing ? "On-Going" : "Finish"}
            </div>
            <div>
              {record.status === EVENT_STATUS.onGoing ? (
                <div className="text-right">
                  <Button
                    onClick={() => {
                      handleViewQR(record.id, record.name);
                    }}
                    className="mr-1 font-bold border-none text-[#4B4DED] shadow-none"
                  >
                    View QR
                  </Button>
                  <Button
                    danger
                    className="mr-1"
                    onClick={() => selectEdit(record)}
                  >
                    Edit
                  </Button>
                  <Button
                    danger
                    style={{ backgroundColor: "#DD0000", color: "white" }}
                    onClick={() => selectToFinish(record)}
                  >
                    Finish
                  </Button>
                  <Button type="text" onClick={() => selectDelete(record)}>
                    <DeleteOutlined />
                  </Button>
                </div>
              ) : (
                <div className="w-[200px] text-right">
                  <Button danger onClick={() => selectToDetail(record)}>
                    Detail
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.map((item) => ({
        ...item,
        key: item.id,
      }))}
      pagination={false}
    />
  );
};

const TableDataEvent = ({
  dataTable,
  isLoading,
  selectDelete,
  selectEdit,
  selectToFinish,
  selectToDetail,
  handleViewQR,
}) => {
  const columns = [
    {
      title: "Event",
      dataIndex: "name",
      width: "45%",
      key: "name",
      render: (_, record) => {
        return (
          <div className="flex justify-between pr-[20px]">
            <div className="pt-[5px] font-bold">{record.name}</div>
            <PercentageEventMaster percentage={record.grade} />
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (
        <div className="flex justify-between">
          <div className="pt-[5px] font-bold text-[#DD0000]">
            {record.eventType.name}
          </div>
          <div className="font-semibold flex justify-between">
            <div className="rounded-full bg-[#D0CBFF] w-[30px] h-[30px] text-center text-[#4F40F4] pt-1 mr-2">
              XP
            </div>
            <p className="pt-1">{record.xp}</p>
            <div className="flex ml-[25px]">
              <Image width={30} src={Coin} />
              <p className="pt-1 ml-2">{record.gc}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 170,
      render: (_, record) => {
        let showAction = false;
        if (record?.events?.length) {
          const indexEventOngoing = record?.events.findIndex(
            (event) => event.status === EVENT_STATUS.onGoing
          );
          if (indexEventOngoing > -1) {
            showAction = true;
          }
        }
        if (!showAction) {
          return <></>;
        }
        return (
          <div>
            {/* <Button danger className="mr-1" onClick={() => selectEdit(record)}>
              Edit
            </Button>
            <Button type="text" onClick={() => selectDelete(record)}>
              <DeleteOutlined />
            </Button> */}
          </div>
        );
      },
    },
  ];
  if (dataTable) {
    dataTable.forEach((_data) => {
      _data.key = _data.id;
      if (_data.masterEventTasks && _data.masterEventTasks.length) {
        _data.masterEventTasks.forEach((_task) => (_task.key = _task.id));
      }
    });
  }

  if (isLoading) {
    return <SkeletonTable />;
  }

  return (
    <Table
      expandable={{
        loading: isLoading,
        defaultExpandAllRows: true,
        expandIcon: ({ expanded }) => (expanded ? <></> : <></>),
        expandedRowRender: (record) =>
          expandedRowRender(
            record.events,
            selectToFinish,
            selectToDetail,
            selectDelete,
            selectEdit,
            handleViewQR
          ),
      }}
      columns={columns}
      dataSource={dataTable.map((item) => ({
        ...item,
        key: item.id,
      }))}
      pagination={false}
    />
  );
};
export default TableDataEvent;
