export const dataInformation = [
  {
    title: 'Active Talent',
    number: 0,
    icon: 'active-talent',
  },
  {
    title: 'On-Going Event',
    number: 0,
    icon: 'ongoing-event'
  },
  {
    title: 'Total Event',
    number: 0,
    icon: 'total-event'
  },
  {
    title: 'This Month Event',
    number: 0,
    icon: 'month-event'
  },
]