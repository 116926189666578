import { useMemo } from "react";

export const EventStatus = ({ status }) => {
  const getEventStatus = useMemo(() => {
    switch (status) {
      case "FINISHED-EVENT":
      case "FINISHED":
        return {
          background: "bg-green",
          text: "Event Finished",
        };
      case "CLAIMABLE":
        return {
          background: "bg-primary",
          text: "Claim Rewards",
        };
      case "FAILED":
        return {
          background: "bg-grey",
          text: "Failed",
        };
      case "ON-GOING":
      case "ON_GOING":
        return {
          background: "bg-secondary",
          text: "On-Going",
        };
      case "LOCKED":
        return {
          background: "bg-grey",
          text: "Locked",
        };
      case "UP-COMING":
      case "UP_COMING":
        return {
          background: "bg-secondary",
          text: "Upcoming Event",
        };
      default:
        throw new Error("No event status declared!");
    }
  }, [status]);

  return (
    <div
      className={`flex-shrink-0 w-[100px] md:w-[180px] inline-flex items-center justify-center py-2 rounded-full ${getEventStatus.background}`}
    >
      <span className="text-white text-xs font-medium">
        {getEventStatus.text}
      </span>
    </div>
  );
};
