import React from "react";

const UserChamp = ({ champ, idx }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center  ${
        champ.order !== 2 ? "mt-6" : ""
      } `}
    >
      <div className={`text-white mb-2 ${idx === 1 ? "hidden" : ""} `}>
        {champ.rank}
      </div>
      <div className="relative w-full md:w-[140px] md:h-[140px]">
        <img
          src="/assets/images/splash.png"
          alt=""
          width={150}
          className={` ${
            idx === 1 ? "block" : "hidden"
          } absolute top-[-5px] md:top-[-10px]`}
        />
        <img
          src="/assets/images/crown-leaderboard.png"
          alt="icon-leaderboard"
          style={{ width: "60px", height: "60px" }}
          className={` ${
            idx === 1 ? "block" : "hidden"
          } absolute top-[-20px] left-10 md:top-[-16px] md:right-0 md:left-auto z-5`}
        />
        <div className="rounded-full w-[68px] h-[68px] md:w-[100px] md:h-[100px] border-4 border-white border-opacity-[.3] mb-2 m-auto scale-90 z-20">
          <div className="w-[60px] h-[60px] md:w-[93px] md:h-[93px] text-center bg-[#d9d9d9] border-[3px] rounded-full border-[#fff] shadow-lg items-center flex justify-center mr-2">
            <div className="text-lg md:text-3xl font-bold text-[#979797]">
              {champ.fullName != null ? champ.fullName[0].toUpperCase() : "A"}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-1 text-black font-bold text-sm md:text-lg">
        {champ.fullName}
      </div>
      <div className="mb-1 text-black text-sm">{champ.role || "-"}</div>
      <div className="mb-1 flex items-center">
        <div className="w-[29px] h-[29px] bg-[#D0CBFF] rounded-full flex items-center justify-center mr-2">
          <span className="text-[#4F40F4] text-sm font-semibold">XP</span>
        </div>
        <div className="text-black font-bold" style={{ textAlign: "center" }}>
          {champ.totalXp}
        </div>
      </div>
    </div>
  );
};

const ChampionCard = ({ dataChampions }) => {
  return (
    <div
      className=" bg-transparent
    bg-no-repeat bg-cover bg-center h-[300px] md:h-[335px]
    bg-[url('../public/assets/images/header-gradient.svg')]"
    >
      <div className="flex justify-evenly">
        {dataChampions &&
          dataChampions.map((champ, idx) => {
            return <UserChamp key={idx} champ={champ} idx={idx} />;
          })}
      </div>
    </div>
  );
};

export default ChampionCard;
