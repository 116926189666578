import { Card } from "antd";
import React from "react";

const ListChamp = ({ champ, idx }) => {
  return (
    <Card className="mb-3 border-b-2 rounded-2xl border-2 shadow-md">
      <div className="flex justify-between h-full">
        <div className="flex items-center gap-3 justify-start">
          <div className="">{champ.rownum}</div>
          <div className="">
            <div className="flex items-center gap-3">
              <div className="md:hidden block">
                <img
                  className="rounded-full"
                  alt="icon-user"
                  src="/assets/images/person-placeholder.png"
                  height={50}
                  width={50}
                />
              </div>
              <div className="hidden w-[64px] h-[64px] text-center bg-[#E53737] border-[3px] rounded-full border-[#fff] shadow-lg items-center md:flex justify-center mr-6">
                <div className="text-3xl font-bold text-white">
                  {champ.fullName[0].toUpperCase()}
                </div>
              </div>
              <div>
                <div className="font-semibold text-[#4c4c4c]">
                  <p className="text-2xxs md:text-xl">{champ.fullName}</p>
                  <p className="text-xxs md:text-xl text-[#a8a8a8]">
                    {champ.role || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex items-center justify-end gap-3">
          <div className="w-[25px] h-[25px] md:w-[45px] md:h-[45px] rounded-full bg-[#D0CBFF] text-[#4F40F4] font-bold items-center flex justify-center  text-xxs md:text-xl">
            XP
          </div>
          <div className="text-[#4C4C4C] md:font-bold text-xs md:text-sm">
            {champ.totalXp}
          </div>
        </div>
      </div>
    </Card>
  );
};

const ChampionList = ({ resultData }) => {
  return (
    <div className="pb-6 px-5 md:px-[60px] pt-1 rounded-t-3xl h-full overflow-auto">
      <div className="flex flex-col overflow-y-auto gap-3 ">
        {resultData &&
          resultData.map((champ, idx) => {
            return <ListChamp key={champ.id} champ={champ} idx={idx} />;
          })}
        {resultData.length === 0 ? (
          <Card className="shadow mb-2">
            <div className="text-center text-red-500 font-bold">
              Data not found.
            </div>
          </Card>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ChampionList;
