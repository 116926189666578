import React from "react";
import { Input } from "antd";

const UserChamp = ({ champ, idx }) => {
  const rankProps = {
    1: {
      position: "mt-[140px] lg:mt-[100px]",
      badge: "/assets/images/rank-second.svg",
      border: "bg-gradient-to-r from-yellow-300 to-yellow-600",
      gap: "mt-[15px] lg:mt-[50px]",
    }, //2
    2: {
      position: "mt-[51.5px] lg:mt-[30px]",
      badge: "/assets/images/rank-first.svg",
      border: "bg-gradient-to-r from-yellow-300 to-yellow-600",
      gap: "mt-[90px] lg:mt-[80px]",
    }, //1
    3: {
      position: "mt-[194px] lg:mt-[150px]",
      badge: "/assets/images/rank-third.svg",
      border: "bg-gradient-to-r from-yellow-300 to-yellow-600",
      gap: "mt-[15px] lg:mt-[20px]",
    }, //3
  };

  return (
    <div
      className={`flex flex-col items-center flex-1 justify-center h-fit z-20 ${
        rankProps[champ.order].position
      } `}
    >
      <div
        className={`relative w-[100px] h-[100px] md:w-[120px] md:h-[120px] lg:w-[100px] lg:h-[100px] rounded-full text-center shadow-lg items-center flex justify-center ${
          rankProps[champ.order].border
        } lg:p-[8px]`}
      >
        <p className="text-3xl lg:text-2xl font-bold text-[#979797] bg-white w-full h-full rounded-full flex justify-center items-center">
          {champ.fullName != null ? champ.fullName[0].toUpperCase() : "A"}
        </p>
        <div className="absolute -bottom-3.5">
          <img
            src={rankProps[champ.order]?.badge}
            alt="icon-rank"
            className="w-[48px] h-[42px] lg:w-[40px] lg:h-[35px]"
          />
        </div>
      </div>
      <div
        className={`${
          rankProps[champ.order]?.gap
        } mb-1 text-white font-weight-600 text-sm lg:text-size-18 text-center`}
      >
        {champ.fullName}
      </div>
      <div className="mb-1 flex items-center">
        <div className="w-[29px] h-[29px] bg-[#D0CBFF] rounded-full flex items-center justify-center mr-2">
          <span className="text-[#4F40F4] text-sm font-semibold">XP</span>
        </div>
        <div className="text-white font-bold" style={{ textAlign: "center" }}>
          {champ.totalXp}
        </div>
      </div>
    </div>
  );
};

const ChampionCard = ({ dataChampions, handleSearch, searchKeyword }) => {
  return (
    <>
      <div
        className="relative flex w-full justify-center bg-primary h-[457px] md:h-[400px] bg-center bg-no-repeat"
        style={{
          backgroundImage: "url('/assets/images/leaderboard-header.svg')",
          backgroundSize: "cover",
        }}
      >
        <div className="lg:w-[724.002px] w-full flex justify-center absolute top-0 h-full left-0 md:left-[50%] md:translate-x-[-50%] z-20">
          {dataChampions &&
            dataChampions.map((champ, idx) => {
              return <UserChamp key={idx} champ={champ} idx={idx} />;
            })}
        </div>
        <img
          src="/assets/images/Stage.svg"
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "724.002px",
            maxHeight: "291.802px",
          }}
          alt="icon-stage"
          className="absolute bottom-0 left-[50%] translate-x-[-50%] z-10"
        />
        <div className="absolute bottom-0 left-0 w-full h-[58px] bg-[#BB1B1B] border-t-[5px] border-[#901F1F] z-5"></div>
      </div>
      <div className="my-4 flex flex-col items-center space-y-4 md:flex-row md:justify-between md:px-[90px] px-[20px]">
        <div className="text-dark-grey text-size-20 font-weight-600 text-center w-full md:w-auto">
          Leaderboard
        </div>
        <div className="w-full md:w-auto">
          <Input
            placeholder="Search..."
            value={searchKeyword}
            onChange={(e) => {
              handleSearch(e);
            }}
            className="rounded-lg"
            prefix={
              <img
                src="/assets/icons/search-icon.svg"
                alt="icon-search"
                className="cursor-pointer"
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default ChampionCard;
