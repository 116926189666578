const handleDateFormating = (date) => {
    const tempDate = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
    };

    const timeOpt = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const formatted = tempDate
      .toLocaleDateString("id-ID", options)
      .concat(", ", tempDate.toLocaleString("id-ID", timeOpt))
      .replace(/\./g, ":");
    return formatted;
};

export {
    handleDateFormating
}