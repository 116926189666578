import { Form, Select, Button, Checkbox, ConfigProvider } from "antd";
import { useState, useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

import MasterUserManagementRepo from "../../../../../repositores/adminRepo/UserManagement";
import { SearchSelectBox } from "./SearchBox";
const { Option } = Select;

const FormParticipant = ({
  formType = "add",
  getStateMount,
  getDataParticipant,
  defaultFormValue,
}) => {
  let titleModal = formType === "add" ? "Add Event" : "";
  let subTitle = formType === "add" ? "Enter your data below!" : "";
  const [openFormParticipant, setOpenFormParticipant] = useState(false);
  const [isCommitee, setIsCommittee] = useState(false);
  const [listComitte, setListComitte] = useState([]);
  const [listParticipant, setListParticipant] = useState([]);
  const [pageUser, setPageUser] = useState(1);
  const [totalPageUser, setTotalPageUser] = useState(100);
  const [optionsUser, setOptionsUser] = useState([]);
  const [loadingMoreUser, setLoadingMoreUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [totalElementUser, setTotalElementUser] = useState(-1);
  const [counterDiv, setCounterDiv] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSelectAllC, setSelectAllC] = useState(false);
  const [isSelectAllP, setSelectAllP] = useState(false);
  const [isSavedc, setSavedC] = useState(true);
  const [isSavedP, setSavedP] = useState(true);
  const [searchEmailUser, setSearchEmailUser] = useState([]);

  useEffect(() => {
    if (!mounted) {
      if (defaultFormValue.comittee?.length) {
        setListComitte(defaultFormValue.comittee);
      }
      if (defaultFormValue.participant?.length) {
        setListParticipant(defaultFormValue.participant);
      }
      handleFetchMoreUser(pageUser, 20);
    }
    return () => {
      setMounted(true);
    };
  }, [mounted, pageUser]);

  const handleFormAddParticipant = async (isCommitte, isOpen) => {
    if (isOpen) {
      await handleFetchMoreUser(1, 20);
    }
    setIsCommittee(isCommitte);
    setOpenFormParticipant(isOpen);
  };

  const handleScrollUser = (event) => {
    event.stopPropagation();

    if (!loadingMoreUser) {
      handleFetchMoreUser(pageUser, 20);
    }
  };

  const handleFetchMoreUser = async (page, limit) => {
    if (optionsUser.length < totalElementUser || totalElementUser === -1) {
      setLoadingMoreUser(true);
      const response = await MasterUserManagementRepo.getUserManagement(
        page - 1,
        limit
      );
      const tempOptions = response?.data?.data?.content || [];
      setOptionsUser((prevOptions) => [...prevOptions, ...tempOptions]);
      setTotalPageUser(response.data?.data?.totalPages);
      setTotalElementUser(response.data?.data?.totalElements);
      setPageUser(page + 1);
      setLoadingMoreUser(false);
    }
  };

  const handleSearchEmail = async (value) => {
    let arr = [];
    for (let i = 0; i < value.length; i++) {
      const response = await MasterUserManagementRepo.getUserManagement(
        0,
        20,
        value[i]
      );
      const filteredUsers = response.data?.data?.content || [];
      arr.push(filteredUsers[0]);
    }
    setSearchEmailUser(arr);
  };

  const handleSearch = async (value) => {
    setLoadingMoreUser(true);

    if (value === "") {
      await handleFetchMoreUser(1, 20);
    } else {
      const response = await MasterUserManagementRepo.getUserManagement(
        0,
        20,
        value
      );
      const filteredUsers = response.data?.data?.content || [];
      setSelectedUser((prevSelected) => [...prevSelected, ...filteredUsers]);
      setOptionsUser(filteredUsers);
    }
    setLoadingMoreUser(false);
  };

  const handleChangeUser = (value) => {
    setSelectedUser(value);
    let tempOptions = [...selectedOptions];
    value
      ?.map((newItem) => {
        return optionsUser.find((item) => item.id === newItem);
      })
      ?.filter((item) => {
        return item !== undefined;
      })
      .forEach((item) => {
        if (!tempOptions.find((opt) => opt.id === item.id)) {
          tempOptions.push(item);
        } else {
        }
      });
    setSelectedOptions(tempOptions);
    handleFetchMoreUser(1, 20);
  };

  useEffect(() => {}, [selectedOptions]);

  const doSave = () => {
    console.log(listComitte);
    console.log(listParticipant);
    let tempCommittees = [...listComitte];
    let tempParticipants = [...listParticipant];

    for (const user of selectedUser) {
      let selectedUserData = selectedOptions.find((opt) => opt.id === user);

      if (!selectedUserData) {
        continue;
      }

      /* if exist, check if is deleted, set false if true */
      selectedUserData.isDeleted = false;

      if (isCommitee) {
        if (!tempCommittees.some((u) => u.id === selectedUserData.id)) {
          tempCommittees.push(selectedUserData);
        }
      } else {
        if (!tempParticipants.some((u) => u.id === selectedUserData.id)) {
          tempParticipants.push(selectedUserData);
        }
      }
    }

    if (isCommitee) {
      setListComitte(tempCommittees);
    } else {
      setListParticipant(tempParticipants);
    }

    const participantData = {
      participant: tempParticipants,
      committee: tempCommittees,
      isAllTalent: isSelectAllP,
    };

    console.log(isSelectAllP);

    getDataParticipant(1, participantData);
    setOpenFormParticipant(false);
  };

  const removeParticipant = (index, type) => {
    const tempParticipants =
      type === "committee" ? listComitte : listParticipant;

    if (formType === "add") {
      tempParticipants.splice(index, 1);
    } else {
      tempParticipants[index]["isDeleted"] = true;
    }

    if (type === "committee") {
      setListComitte([...tempParticipants]);
    } else {
      setListParticipant([...tempParticipants]);
    }

    setCounterDiv(counterDiv + 1);
    const participantData = {
      participant: listParticipant,
      committee: listComitte,
    };

    getDataParticipant(1, participantData);
  };

  if (openFormParticipant) {
    return (
      <>
        <div className="w-full mt-[20px] mb-[100px] text-left">
          {formType === "add" && (
            <>
              <h3 className="font-semibold text-[20px] ">{titleModal}</h3>
              <p>{subTitle}</p>
            </>
          )}
          <p className="font-semibold text-[16px]">
            {isCommitee ? "Committee" : "Participant"}
          </p>
          <div>
            <Form.Item>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                onChange={handleChangeUser}
                placeholder={
                  isCommitee ? "Select Committee" : "Select Participant"
                }
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollUser}>{menu}</div>
                )}
                onSearch={handleSearch}
                loading={loadingMoreUser}
                showSearch
                filterOption={"false"}
                optionFilterProp="children"
                defaultActiveFirstOption={false}
                className={selectedUser === null ? "placeholder-select" : ""}
              >
                {optionsUser.map((i, index) => (
                  <Option key={i.id + "-" + index} value={i.id}>
                    {i.fullname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="flex justify-between ">
            <Button
              type="link"
              danger
              onClick={() => handleFormAddParticipant(false, false)}
            >
              Cancel
            </Button>
            <Button danger onClick={() => doSave()}>
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="w-full text-left">
        <h3 className="font-semibold text-[20px] ">{titleModal}</h3>
        <p>{subTitle}</p>
        <div className="mt-[20px]">
          <div className="flex justify-between items-center">
            <div className="font-semibold text-[16px]">Committees</div>
            <div className="flex items-center">
              {/* <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#dd0000",
                    colorPrimaryBorder: "#dd0000",
                  },
                }}
              >
                <Checkbox
                  onChange={() => {
                    setSelectAllC(!isSelectAllC);
                    setSavedC(false);
                    setListComitte([]);
                  }}
                  className="my-2 h-9 flex items-center font-medium"
                  style={{ color: "#4C4C4C" }}
                >
                  Select All
                </Checkbox>
              </ConfigProvider> */}
              <Button
                className="my-2"
                danger
                onClick={() => {
                  setSavedC(true);
                  doSave();
                }}
                disabled={isSavedc}
              >
                Save
              </Button>
            </div>
          </div>
          <SearchSelectBox
            data={optionsUser}
            onChangeInput={async (e) => {
              await handleSearch(e);
            }}
            saved={isSavedc}
            placeholder={"Select committee here..."}
            alertSelectAll={"All committees have already been selected"}
            checkedListCP={listComitte}
            setListCP={(e) => {
              // console.log(e);
              // Array.isArray(e[0]) ? setListComitte(e[0]) :
              setListComitte(e);
            }}
            setSaved={(e) => {
              setSavedC(false);
            }}
            handleSearchEmail={(e) => {
              handleSearchEmail(e);
            }}
            searchEmailData={searchEmailUser}
          />
        </div>

        <div className="mt-[20px]">
          <div className="flex justify-between items-center">
            <div className="font-semibold text-[16px]">Participants</div>
            <div className="flex items-center">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#dd0000",
                    colorPrimaryBorder: "#dd0000",
                  },
                }}
              >
                <Checkbox
                  onChange={() => {
                    setSelectAllP(!isSelectAllP);
                    setSavedP(false);
                    setListParticipant([]);
                  }}
                  className="my-2 h-9 flex items-center font-medium"
                  style={{ color: "#4C4C4C" }}
                >
                  Select All
                </Checkbox>
              </ConfigProvider>
              <Button
                danger
                onClick={() => {
                  setSavedP(true);
                  doSave();
                }}
                disabled={isSavedP}
              >
                Save
              </Button>
            </div>
          </div>
          <SearchSelectBox
            data={optionsUser}
            onChangeInput={async (e) => {
              await handleSearch(e);
            }}
            saved={isSavedP}
            selectedAll={isSelectAllP}
            placeholder={"Select participant here..."}
            alertSelectAll={"All participants have already been selected"}
            checkedListCP={listParticipant}
            setListCP={(e) => {
              console.log(e);
              setListParticipant(e);
            }}
            setSaved={() => {
              setSavedP(false);
            }}
            handleSearchEmail={(e) => {
              handleSearchEmail(e);
            }}
            searchEmailData={searchEmailUser}
          />
        </div>
      </div>
    </>
  );
};

export default FormParticipant;
