import React, { useContext, useEffect, useState } from "react";
import { TalentHelperContext } from "../../../components/talentPage/TalentContext";
import DashboardTalentRepo from "../../../repositores/talentRepo/DashboardTalentRepo";
import HistoryDetail from "./HistoryDetail";
import HistoryHeader from "../../../components/general/TalentHeader";
import { AntdPagination } from "./HistoryPagination";

const Index = () => {
  const { profileId, selectMenu } = useContext(TalentHelperContext);
  const [historyData, setHistoryData] = useState([]);
  const [searchKeyword, setsearchKeyword] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    getCoinsHistory();
  }, [profileId, page]);

  useEffect(() => {
    let delayDebounceFn;

    if (!!searchKeyword) {
      if (searchKeyword?.length > 1) {
        delayDebounceFn = setTimeout(() => {
          setPage(() => 0);
          getCoinsHistory();
        }, 1000);
      }
    } else {
      setPage(() => 0);
      getCoinsHistory();
    }

    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyword]);

  const handleSearchKeyword = (keyword) => {
    setsearchKeyword(keyword);
  };

  const getCoinsHistory = async () => {
    if (!profileId) return;
    await DashboardTalentRepo.getCoinHistory(page, 6, profileId, searchKeyword)
      .then((res) => {
        handleHistoryData(res.data.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleHistoryData = (data) => {
    setHistoryData(data);
  };

  const onPageChange = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  useEffect(() => {
    selectMenu("history");
  }, []);

  return (
    <div className="flex flex-col w-full">
      {/* header */}
      <HistoryHeader
        title={"Coin History"}
        subDescription={"Let's see the latest transaction!"}
        imageSrc={"/assets/images/history-header-image.png"}
      />

      {/* content body */}
      {historyData?.content ? (
        <HistoryDetail
          historyData={historyData}
          searchKeyword={searchKeyword}
          handleSearchKeyword={handleSearchKeyword}
        />
      ) : (
        <div className="flex flex-col mt-[100px] w-full items-center px-4 lg:px-0">
          <div className="w-full">
            <img
              src={"/assets/images/history-nodata.svg"}
              alt="icon-not-found"
              className="w-[680px] h-[377px] mx-auto"
            />
          </div>
          <div className="flex flex-col items-center gap-[15px]">
            <p className="text-dark-grey font-weight-500 text-size-68">
              No Results Found
            </p>
            <p className="text-grey font-weight-500 text-size-24">
              No data found. Try again later.
            </p>
          </div>
        </div>
      )}

      <div className="flex justify-center py-2">
        {historyData?.content && (
          <AntdPagination
            historyData={historyData}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
