import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListInterviewClient = async (
  page = 0,
  size = 10,
  account = undefined,
  position = undefined
) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof account !== "undefined" && account.length) {
    params["account"] = account;
  }
  if (typeof position !== "undefined" && position.length) {
    params["position"] = position;
  }
  const url = `${API_BASE}/retro-interview-client/list-admin?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListDetailRetroInterviewClient = async (page = 0, size = 10, id) => {
  let params = {
    page: page,
    size: size,
  };
  const url = `${API_BASE}/retro-interview-client-detail/list-admin/retro-clientid/${id}?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListRole = async (page = 0, size = 10, name = "") => {
  let url = `${API_BASE}/role/list?page=${page}&size=${size}`;
  if (name !== "") {
    url += `&name=${name}`;
  }

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListAccount = async (page = 0, size = 10, name = "") => {
  let url = `${API_BASE}/client/list?page=${page}&size=${size}`;
  if (name !== "") {
    url += `&name=${name}`;
  }

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListRolesEmployee = async (page = 0, size = 10, name = undefined) => {
  let params = {
      page: page + 1,
      size: size,
  };
  if (typeof name != "undefined" && name.length) {
      params["roleName"] = name;
  }
  const url = `${API_BASE}/role/list?${createSearchParams(
      params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListCourse = async (page = 0, size = 10, name = "") => {
  let url = `${API_BASE}/course/list?page=${page}&size=${size}`;
  if (name !== "") {
    url += `&name=${name}`;
  }

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListMataKuliah = async (page = 0, size = 10, id, name = "") => {
  let url = `${API_BASE}/matkul/list-admin/courseid/${id}?page=${page}&size=${size}`;
  if (name !== "") {
    url += `&matkul=${name}`;
  }
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addInterviewClient = async (body) => {
  const url = `${API_BASE}/retro-interview-client/admin-create`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const editInterviewClient = async (body) => {
  const url = `${API_BASE}/retro-interview-client/admin-update`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const deleteInterviewClient = async (id) => {
  const url = `${API_BASE}/retro-interview-client/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
};

const getDetailInterviewClient = async (id) => {
  const url = `${API_BASE}/retro-interview-client-detail/list-admin/retro-clientid/${id}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getDetailInterviewClientNoToken = async (id) => {
  const url = `${API_BASE}/retro-interview-candidate/get-id/${id}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const editQuestionClient = async (body) => {
  const url = `${API_BASE}/retro-interview-client-detail/admin-create-update`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
}

const deleteQuestionClient = async (id) => {
  const url = `${API_BASE}/retro-interview-client-detail/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
}



const InterviewClientRepo = {
  addInterviewClient,
  deleteInterviewClient,
  editInterviewClient,
  getDetailInterviewClient,
  getListInterviewClient,
  getListDetailRetroInterviewClient,
  getListRole,
  getListAccount,
  getListCourse,
  getListMataKuliah,
  editQuestionClient,
  deleteQuestionClient,
  getDetailInterviewClientNoToken,
  getListRolesEmployee
};

export default InterviewClientRepo;
