import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/es-us";
import { overideStateForm } from "../../../../features/cerificateTemplateSlice";
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Radio,
  Space,
  DatePicker,
} from "antd";

const ModalCertificate = (props) => {
  const dispatch = useDispatch();
  const {
    nameForm,
    instanceForm,
    open,
    handleCancel,
    modalTitle,
    instruction,
    buttonActionTitle,
    buttonAction,
    buttonPrevious,
    valueEdit,
    templateList,
    typeList,
  } = props;

  const { isOverview, valueForm } = useSelector(
    (state) => state.certificateTemplate
  );

  const { Text } = Typography;

  const [buttonDisable, setButtonDisable] = useState(true);

  const onChangeDatePicker = (date, dateString) => {
    const formatedDate = date?.format("MM/DD/YYYY");
    dispatch(
      overideStateForm({ stateName: "validityPeriod", value: formatedDate })
    );
  };
  const onChangeSelect = (value) => {
    dispatch(overideStateForm({ stateName: "background", value: value }));
  };

  useEffect(() => {
    instanceForm.setFieldsValue({
      titleCertificate: valueEdit?.title,
      background: valueEdit?.background || null,
      type: valueEdit?.type,
      validityPeriod: valueEdit?.validityPeriod
        ? dayjs(valueEdit.validityPeriod, "DD/MM/YYYY")
        : null,
      signerName: valueEdit?.signerName,
      signerPosition: valueEdit?.signerPosition,
      signature: valueEdit?.signature,
    });
  }, []);

  useEffect(() => {
    if (
      valueForm.title !== "" &&
      valueForm.background !== "" &&
      valueForm.valueFormtype !== "" &&
      valueForm.validityPeriod !== "" &&
      valueForm.signerName !== "" &&
      valueForm.signerPosition !== "" &&
      valueForm.signature !== ""
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [valueForm]);

  return (
    <div>
      <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        style={{ top: 20 }}
      >
        <div className="mb-8">
          <h1 className="text-2xl font-bold">{modalTitle}</h1>
          <Text className="text-xs" type="secondary">
            {instruction}
          </Text>
        </div>
        <div>
          <Form
            name={nameForm}
            form={instanceForm}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 17 }}
            style={{ maxWidth: 600 }}
            disabled={isOverview}
            autoComplete="off"
          >
            <Form.Item
              label="Title"
              name="titleCertificate"
              rules={[
                {
                  required: true,
                  message: "Please input Title Certificate",
                },
              ]}
            >
              <Input
                placeholder="Enter certificate name"
                onChange={(event) =>
                  dispatch(
                    overideStateForm({
                      stateName: "title",
                      value: event.target.value,
                    })
                  )
                }
              />
            </Form.Item>

            <Form.Item
              label="Background"
              name="background"
              rules={[
                {
                  required: true,
                  message: "Please select template Certificate",
                },
              ]}
            >
              <Select
                options={templateList}
                placeholder="Select certificate background"
                onChange={onChangeSelect}
              />
            </Form.Item>

            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please choose type Certificate",
                },
              ]}
            >
              <Radio.Group
                onChange={(event) =>
                  dispatch(
                    overideStateForm({
                      stateName: "type",
                      value: event.target.value,
                    })
                  )
                }
              >
                <div className="flex overflow-x-auto h-[140px] p-3">
                  <Space direction="vertical">
                    {typeList.map((item) => {
                      return (
                        <Radio key={item.key} value={item.value}>
                          <Text type="secondary">{item.label}</Text>
                        </Radio>
                      );
                    })}
                  </Space>
                </div>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Validity Period"
              name="validityPeriod"
              rules={[
                {
                  required: true,
                  message: "Please choose date Certificate",
                },
              ]}
            >
              <DatePicker
                placeholder="Pick validity period"
                style={{ width: 330 }}
                onChange={onChangeDatePicker}
              />
            </Form.Item>

            <Form.Item
              label="Signer Name"
              name="signerName"
              rules={[
                {
                  required: true,
                  message: "Please input Signer name",
                },
              ]}
            >
              <Input
                placeholder="Enter signer name"
                onChange={(event) =>
                  dispatch(
                    overideStateForm({
                      stateName: "signerName",
                      value: event.target.value,
                    })
                  )
                }
              />
            </Form.Item>

            <Form.Item
              label="Signer Position"
              name="signerPosition"
              rules={[
                {
                  required: true,
                  message: "Please input Signer position",
                },
              ]}
            >
              <Input
                placeholder="Enter signer position"
                onChange={(event) =>
                  dispatch(
                    overideStateForm({
                      stateName: "signerPosition",
                      value: event.target.value,
                    })
                  )
                }
              />
            </Form.Item>

            <Form.Item
              label="Signature"
              name="signature"
              rules={[
                {
                  required: true,
                  message: "Please input Signature Certificate",
                },
              ]}
            >
              <Input
                placeholder="Input signature signer"
                onChange={(event) =>
                  dispatch(
                    overideStateForm({
                      stateName: "signature",
                      value: event.target.value,
                    })
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              {isOverview && (
                <div className="flex flex-row place-content-end justify-between w-[450px]">
                  <button
                    className="bg-white w-[150px] h-[30px] text-red-600 rounded border border-red-600"
                    onClick={buttonPrevious}
                  >
                    Previous
                  </button>
                  <button
                    className={`${
                      buttonDisable ? "bg-slate-400" : "bg-red-600"
                    } w-[150px] h-[30px] text-white rounded`}
                    disabled={buttonDisable}
                    onClick={buttonAction}
                  >
                    {buttonActionTitle}
                  </button>
                </div>
              )}
              {!isOverview && (
                <div className="flex flex-row place-content-end w-auto">
                  <button
                    className={`${
                      buttonDisable ? "bg-slate-400" : "bg-red-600"
                    } w-[150px] h-[30px] text-white rounded`}
                    disabled={buttonDisable}
                    onClick={buttonAction}
                  >
                    {buttonActionTitle}
                  </button>
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCertificate;
