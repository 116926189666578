import React, { useState, useEffect } from 'react';
import { dataInformation } from '../response.state';
import MasterDashboardRepo from '../../../../repositores/adminRepo/DashboardRepo';


const CardInformation = ({title, number, icon, index}) => {
  return (
    <div className={`flex py-2 px-8 ${index !== 3 ? 'md:border-e-[1px]' : ''}`}>
      <div className='w-[40px] h-[40px] rounded-full bg-[#FFEDED] mr-3 flex items-center justify-center'>
          <img
            alt=""
            src={`/assets/icons/${icon}.png`}
            width="24"
          />
      </div>
      <div>
        <div className='font-medium mb-1 text-[#4c4c4c]'>{title}</div>
        <div className='font-bold'>{number}</div>
      </div>
    </div>
  )
}

const TalentInformation = () => {

  const [dataTotal, setDataTotal] = useState([])

  const getDataTotal = async () => {
    try {
      const { data } = await MasterDashboardRepo.getDashboardTotal()
      const { activeTalent, ongoingEvent, thisMonthEvent, totalEvent } = data.data
      const tempData = structuredClone(dataInformation)
      tempData[0].number = activeTalent
      tempData[1].number = ongoingEvent
      tempData[2].number = totalEvent
      tempData[3].number = thisMonthEvent
      setDataTotal(tempData)
    } catch (error) {
      setDataTotal(dataInformation)
    } 
  }

  useEffect(() => {
    getDataTotal()
  }, [])

  return (
    <div className='mb-8'>
      <hr />
      <div className='md:flex sm:grid sm:grid-cols-2 md:justify-between my-2'>
        { 
          dataTotal.map( (info, idx) => {
            return (
              <CardInformation
                key={idx}
                title={info.title}
                number={info.number}
                icon={info.icon}
                index={idx}
              />
            )
          }) 
        }
      </div>
      <hr />
    </div>
  )
}

export default TalentInformation