import { Pagination, message } from "antd";
import React, { useEffect, useState } from "react";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import ActionBarMaster from "../componets_global/ActionBarMaster";
import ClientDataMasterRepo from "../../../repositores/adminRepo/ClientDataMasterRepo";
import ClientTable from "./components/ClientTable";
import InputForm from "./components/InputForm";

const ClientDataMaster = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [clients, setClients] = useState({});
  const [clientKeyword, setClientKeyword] = useState("");
  const [locationKeyword, setLocationKeyword] = useState("");
  const [formShow, setFormShow] = useState(false);
  const [purpose, setPurpose] = useState("new");
  const [clientNameInputForm, setclientNameInputForm] = useState("");
  const [clientLocationInputForm, setClientLocationInputForm] = useState("");
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const showEditModal = (id) => {
    const data = clients.content?.filter((data) => data.id === id)[0];
    setEditId(() => id);
    setclientNameInputForm(data.name);
    setClientLocationInputForm(data.location);
    setPurpose((prev) => "edit");
    setFormShow(true);
  };
  const showDeleteConfirm = (id) => {
    const data = clients.content?.filter((data) => data.id === id)[0];
    setclientNameInputForm(data.name);
    setClientLocationInputForm(data.location);
    setDeleteId(id);
    setPurpose((prev) => "delete");
    setFormShow(true);
  };
  const showAddModal = (id) => {
    setPurpose((prev) => "new");
    setFormShow(true);
  };

  const onChangePagination = async (page, total) => {
    await handleGetClientList(page, total);
  };

  const handleGetClientList = async (page, total) => {
    const nextPage = page;
    await ClientDataMasterRepo.getClientList(
      nextPage,
      total,
      clientKeyword,
      locationKeyword
    )
      .then((res) => {
        setClients(() => res.data.data);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        // window.location.reload();
      });
  };

  const handleCreate = async (name, location) => {
    await ClientDataMasterRepo.submitClient({ name, location })
      .then((res) => {
        // console.log("client added", res.data.data);
        setFormShow(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        window.location.reload();
      });
  };

  const handleEdit = async (name, location) => {
    await ClientDataMasterRepo.updateClient({ id: editId, name, location })
      .then((res) => {
        //console.log("client added", res.data.data);
        setFormShow(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        window.location.reload();
      });
  };

  const handleDelete = async () => {
    await ClientDataMasterRepo.deleteClient(deleteId)
      .then((res) => {
        //console.log("client added", res.data.data);
        setFormShow(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        window.location.reload();
      });
  };

  useEffect(async () => {
    handleGetClientList(clients.number || 0, clients?.size || 10);
  }, [clientKeyword, locationKeyword]);

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Client</TitleMasterAdmin>
        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <ActionBarMaster
              placeholder="Search Client"
              onSearch={(v) => {
                setClientKeyword((prev) => v.target.value);
              }}
            />
            <ActionBarMaster
              placeholder="Search Location"
              onSearch={(v) => {
                setLocationKeyword((prev) => v.target.value);
              }}
            />
          </div>
          <div
            onClick={showAddModal}
            className="bg-red-600 w-[150px] h-[30px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
          >
            Add Client
          </div>
        </div>
        <ClientTable
          data={clients?.content}
          actionEdit={showEditModal}
          actionDelete={showDeleteConfirm}
        />
        <div className="flex justify-center mt-5">
          <Pagination
            current={clients?.number}
            total={clients?.totalElements}
            defaultPageSize={clients?.size}
            pageSize={clients?.size}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        </div>
        <InputForm
          open={formShow}
          handleClose={() => {
            setFormShow(false);
          }}
          purpose={purpose}
          handleSubmit={handleCreate}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          clientName={clientNameInputForm}
          clientLocation={clientLocationInputForm}
          setclientNameInputForm={(v) => setclientNameInputForm(v)}
          setClientLocationInputForm={(v) => setClientLocationInputForm(v)}
        />
      </div>
    </>
  );
};

export default ClientDataMaster;
