import React, { useEffect, useState } from "react";
import { Button, DatePicker, Pagination } from "antd";
import TableContent from "../componets_global/TableContent";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import moment from "moment";
import FridayBoosterRepo from "../../../repositores/adminRepo/FridayBoosterRepo";
import AlertUtility from "../../../components/Alert/Alert";

const FridayBooster = () => {
  const { RangePicker } = DatePicker;
  const [datesRange, setDatesRange] = useState(null);
  const [listData, setListData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, [datesRange, page]);

  const fetchData = async () => {
    try {
      setLimit(10);
      setLoading(true);

      const response = await FridayBoosterRepo.getListReportFridayBooster(
        page - 1,
        limit,
        datesRange?.[0],
        datesRange?.[1]
      );

      if (response.status === 200) {
        setTotalItem(response.data.data.totalElements);
        setListData(response.data.data.content);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching report friday booster data:", error);
    }
  };

  const columns = [
    {
      title: "Fullname",
      dataIndex: ["participant", "fullname"],
      key: "fullname",
      align: "center",
      render: (v) => <p>{v || "-"}</p>,
    },
    {
      title: "Email",
      dataIndex: ["participant", "username"],
      key: "email",
      align: "center",
      render: (v) => <p>{v || "-"}</p>,
    },
    {
      title: "Event Name",
      dataIndex: ["event", "name"],
      key: "id",
      align: "center",
      render: (v) => <p>{v || "-"}</p>,
    },
    {
      title: "Start Date",
      dataIndex: ["event", "startDate"],
      key: "startDate",
      align: "center",
      render: (v) => (
        <p>
          {v !== null ? moment(v, "DD/MM/YYYY").format("DD-MMM-YYYY") : "-"}
        </p>
      ),
    },
    {
      title: "End Date",
      dataIndex: ["event", "endDate"],
      key: "endDate",
      align: "center",
      render: (v) => (
        <p>
          {v !== null ? moment(v, "DD/MM/YYYY").format("DD-MMM-YYYY") : "-"}
        </p>
      ),
    },
  ];

  const handleChangeRangeDate = (dates) => {
    if (dates) {
      setDatesRange([
        dates[0].startOf("day").format("YYYY-MM-DD"),
        dates[1].endOf("day").format("YYYY-MM-DD"),
      ]);
    } else {
      setDatesRange(null);
    }
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const downloadExcelTemplate = async () => {
    try {
      setLoading(true);

      if (!datesRange) {
        throw new Error("Please select date range");
      }

      const { data } = await FridayBoosterRepo.getDownloadFile(
        datesRange?.[0],
        datesRange?.[1]
      );
      const blob = new Blob([data]);
      let url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.setAttribute("download", "template.csv");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      AlertUtility.ErrorAlert({ title: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen px-4 py-5">
      <TitleMasterAdmin>Report Friday Booster</TitleMasterAdmin>
      <div className="flex justify-end gap-2 mb-4">
        <RangePicker onChange={handleChangeRangeDate} format="DD-MMM-YYYY" />
        <Button type="primary" danger onClick={() => downloadExcelTemplate()}>
          Download File
        </Button>
      </div>
      <TableContent
        className="styleTabel"
        dataSource={listData}
        columns={columns}
        isLoading={loading}
        showSizeChanger={false}
      />
      <div className="w-full flex justify-center mt-5">
        <Pagination
          current={page}
          onChange={onChangePage}
          pageSize={limit}
          total={totalItem}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default FridayBooster;
