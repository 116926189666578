import { useMemo } from "react";

export const useVerificationModal = (type) => {
  const isCertificateVerification = useMemo(
    () => type === "certificate",
    [type]
  );

  const modalTitle = useMemo(
    () =>
      `${
        isCertificateVerification ? "Certificate" : "Attendance"
      } Verification`,
    [isCertificateVerification]
  );

  const modalDescription = useMemo(
    () =>
      isCertificateVerification
        ? "Please attach a clear image of your certificate. Pdf, Png, jpeg & jpg are allowed or you can attach official certification link."
        : "Please attach a clear image of you. Png, jpeg & jpg are allowed",
    [isCertificateVerification]
  );

  const uploadType = useMemo(
    () => (isCertificateVerification ? "certificate" : "image"),
    [isCertificateVerification]
  );

  const uploadImage = useMemo(() => {
    return {
      alt: isCertificateVerification
        ? "certificate-uploader"
        : "image-uploader",
      src: isCertificateVerification
        ? "/assets/images/journeys/events/certificate-uploader.svg"
        : "/assets/images/journeys/events/image-uploader.svg",
    };
  }, [isCertificateVerification]);

  return {
    isCertificateVerification,
    modal: {
      title: modalTitle,
      description: modalDescription,
    },
    upload: {
      type: uploadType,
      image: uploadImage,
    },
  };
};
