import React from "react";

export const QuizOption = ({ answer, isOptionChosen, chooseAnswer }) => {
  return (
    <li>
      <button
        className={`block w-full text-left ${
          isOptionChosen
            ? "bg-secondary"
            : "bg-white/25 hover:bg-secondary"
        } rounded-[20px] py-3 px-6 transition ease-out duration-300`}
        onClick={chooseAnswer}
      >
        <span
          className="text-white text-xl font-medium"
          style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
        >
          {answer}
        </span>
      </button>
    </li>
  );
};
