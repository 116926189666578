import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const BASE_PATH = `history-issue-talent`;
const getIssueList = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["description"] = name;
  }
  const url = `${API_BASE}/${BASE_PATH}/list-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getProjectList = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/history-project/list-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const addIssue = async (body) => {
  const url = `${API_BASE}/${BASE_PATH}/create-update-user`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const getIssue = async (id) => {
  const url = `${API_BASE}/${BASE_PATH}/${id}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const editIssue = async (body) => {
  const url = `${API_BASE}/${BASE_PATH}/create-update-user`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const deleteIssue = async (id) => {
  const url = `${API_BASE}/${BASE_PATH}/${id}`;
  const response = await CallApi({ url, method: "DELETE", id });
  return response;
};

const getProjectType = async (page = 0, size = 10) => {
  let params = {
    page: page + 1,
    size: size,
    type: "TYPE_HISTORY_ISSUE_TALENT",
  };

  const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const TalentIssue = {
  getIssueList,
  addIssue,
  editIssue,
  deleteIssue,
  getIssue,
  getProjectList,
  getProjectType,
};

export default TalentIssue;
