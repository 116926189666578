import React, { useState, useEffect, useRef } from "react";
import TitleMasterAdmin from "../componets_global/TitleMaster";
import BadgesTable from "./components/table";
import ModalEditBadges from "./components/modal";
import SearchBar from "../componets_global/SearchBar";
import BadgesRepo from "../../../repositores/adminRepo/BadgesRepo";
import { Pagination, Form } from "antd";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";

const Badges = () => {
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [badgesForm] = Form.useForm();
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });

  const [idBadges, setIdBadges] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const searchRef = useRef();

  const fetchBadgesData = async () => {
    try {
      setLimit(10);
      setLoading(true);
      const response = await BadgesRepo.getListBadges(page, limit, searchRef);
      setDataTable(response?.data?.data?.content);
      setTotalItem(response?.data?.data?.totalElements);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const showEditModal = (item) => {
    setIdBadges(item.id);
    badgesForm.setFieldsValue({
      level: `Level ${item.level}`,
      name: item.name,
      xp_point: item.xp,
    });
    setOpenModalEdit(true);
  };

  useEffect(() => {
    fetchBadgesData();
  }, [page]);

  const handleCancelModalEdit = () => {
    setOpenModalEdit(false);
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeSearch = async (event) => {
    setPage(1);
    setLoading(true);
    try {
      const response = await BadgesRepo.getListBadges(
        page,
        limit,
        event.target.value
      );
      setDataTable(response.data.data.content);
      setTotalItem(response.data.data.totalElements);
      setLoading(false);
    } catch (error) {
      console.error("Error searching data: ", error);
    }
  };

  const handleSubmitEditBadges = async (value) => {
    setLoading(true);
    const submit = {
      id: idBadges,
      name: value.name,
      xp: value.xp_point,
    };

    try {
      const response = await BadgesRepo.editBadges(submit);

      if (response.data.status === 200) {
        setMessageAlert({ message: "Data edited successfully!", status: true });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      } else {
        setMessageAlert({ message: response?.data?.message, status: false });
        setTimeout(() => {
          setMessageAlert({ message: "", status: null });
        }, 1000);
      }

      const responseList = await BadgesRepo.getListBadges(
        page,
        limit,
        searchRef
      );
      setDataTable(responseList.data.data.content);
      setTotalItem(responseList.data.data.totalElements);
    } catch (error) {
      console.error("Error editing badges: ", error);
    } finally {
      setLoading(false);
      setOpenModalEdit(!openModalEdit);
    }
  };

  return (
    <>
      {messageAlert?.message !== "" && (
        <AlertMiniPopUp
          messageAlert={messageAlert.message}
          isSuccess={messageAlert.status}
        />
      )}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Badges</TitleMasterAdmin>
        <SearchBar
          onChangeSearch={onChangeSearch}
          placeholder={"Search Name"}
        />
        <BadgesTable
          isLoading={loading}
          dataTable={dataTable}
          actionEdit={showEditModal}
        />
        <div className="w-full flex justify-center mt-5">
          <Pagination
            current={page}
            onChange={onChangePage}
            pageSize={limit}
            total={totalItem}
          />
        </div>
        <ModalEditBadges
          open={openModalEdit}
          handleCancel={handleCancelModalEdit}
          formBadges={badgesForm}
          valueEdit={handleSubmitEditBadges}
          modalTitle={"Edit Badges"}
          instruction="Edit your data below!"
          buttonAction="Submit"
        />
      </div>
    </>
  );
};

export default Badges;
