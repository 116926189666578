import React, { useState, useEffect } from "react";
import { Button, Table, message, Pagination } from "antd";
import SkeletonTable from "./SkeletonTable";
import CheckedIcon from "../../../../../assets/icons/CheckedIcon.png";
import { isNullOrEmpty } from "../../../../../utils/utils";
import MasterEventRepo from "../../../../../repositores/adminRepo/MasterEventRepo";
import Coin from "../../../../../assets/icons/coin.png";
import ModalImport from "./ModalImport";

const ParticipantTabsComponent = ({
  detailEvent,
  isChampion = false,
  selectToDetail,
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [showImport, setShowImport] = useState(false);

  useEffect(() => {
    fetchData();
  }, [page, size]);

  const fetchData = async () => {
    let params = {
      page: page,
      size: size,
      isChampion: isChampion,
    };
    setLoading(true);
    try {
      const response = await MasterEventRepo.ListParticipanEvents(
        params,
        detailEvent.id
      );
      if (response?.data?.status === 200) {
        setDataTable(response?.data?.data?.content);
      } else {
        messageApi.open({
          type: "error",
          content: response?.data?.message || response?.data?.data?.message,
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "failed get Data",
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = (value) => {
    setPage(value);
  };

  if (loading) {
    return (
      <>
        {contextHolder}
        <SkeletonTable />
      </>
    );
  }

  const closeModalImport = () => {
    setShowImport(false);
  };

  return (
    <>
      {contextHolder}
      <ModalImport show={showImport} closeModal={closeModalImport} />
      <div className="flex justify-end">
        <Button
          type="primary"
          danger
          style={{ backgroundColor: "#DD0000" }}
          onClick={() => {
            setShowImport(true);
          }}
        >
          Import Data
        </Button>
      </div>
      <TableParticipantEvent
        dataTable={dataTable}
        isChampion={isChampion}
        selectToDetail={selectToDetail}
      />
      <div className="w-full flex justify-end my-5 pt-5 pb-10">
        <Pagination
          current={page}
          onChange={onChangePage}
          pageSize={size}
          total={totalItem}
        />
      </div>
    </>
  );
};

const TableParticipantEvent = ({
  dataTable,
  isChampion = false,
  selectToDetail,
}) => {
  const columns = [
    {
      title: "Participant Name",
      dataIndex: "fullname",
      width: "35%",
      key: "fullname",
      render: (_, record) => {
        return (
          <div className="flex justify-between pr-[20px]">
            <div className="pt-[5px] font-bold">{record.fullname}</div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "150px",
      render: (_, record) => <div className="text-center">{record.status}</div>,
    },
    {
      title: (
        <>
          <div className="flex">
            <div className="px-[5px]">
              <img src={CheckedIcon} alt="checked icon" />
            </div>
            <div className="px-[5px]">Passing Grade</div>
          </div>
        </>
      ),
      key: "parsingGrade",
      align: "center",
      width: 170,
      render: (_, record) => (
        <div className="text-center" key={`pg-${record.userId}`}>
          {!isNullOrEmpty(record.parsingGrade) && record.parsingGrade !== 0
            ? `${record.parsingGrade}%`
            : "-"}
        </div>
      ),
    },
    {
      title: "Grade Result",
      key: "resultGrade",
      align: "center",
      width: 170,
      render: (_, record) => (
        <div className="text-center" key={record.userId}>
          {!isNullOrEmpty(record.resultGrade) && record.resultGrade !== 0
            ? record.resultGrade
            : "-"}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 170,
      render: (_, record) => (
        <div>
          <Button danger onClick={() => selectToDetail(record)}>
            View
          </Button>
        </div>
      ),
    },
  ];
  const columnsChampions = [
    {
      title: "Participant Name",
      dataIndex: "fullname",
      width: "35%",
      key: "fullname",
      render: (_, record) => {
        return (
          <div className="flex justify-between pr-[20px]">
            <div className="pt-[5px] font-bold">{record.fullname}</div>
          </div>
        );
      },
    },
    {
      title: "Champion",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "auto",
      render: (_, record) => (
        <div className="text-center text-green">{record.title}</div>
      ),
    },
    {
      title: "Grade Result",
      key: "resultGrade",
      align: "center",
      width: 170,
      render: (_, record) => (
        <div className="text-center" key={record.userId}>
          {!isNullOrEmpty(record.resultGrade) && record.resultGrade !== 0
            ? record.resultGrade
            : "-"}
        </div>
      ),
    },
    {
      title: "Rewards",
      key: "extraGc",
      align: "center",
      width: 170,
      render: (_, record) => (
        <div className="text-center" key={`pg-${record.userId}`}>
          {!isNullOrEmpty(record.extraGc) && record.extraGc !== 0 ? (
            <>
              <div className="flex justify-center">
                <div className="mx-[5px]">{record.extraGc}</div>
                <img src={Coin} alt="coint ic" width={16} />
              </div>
            </>
          ) : (
            "0"
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={isChampion ? columnsChampions : columns}
      dataSource={dataTable}
      pagination={false}
    />
  );
};

export default ParticipantTabsComponent;
