import { Link, useParams } from "react-router-dom";

export const BackButton = () => {
  const params = useParams();

  return (
    <Link
      className="absolute left-[100px] top-[80px] inline-flex items-center gap-4"
      to={{
        pathname: `/talent/journey/events/${params.eventId}`,
        search: new URLSearchParams({ expanded: "true" }).toString(),
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
      >
        <circle cx="16.5" cy="16.5" r="16.5" fill="white" />
        <path
          d="M23 17H9M9 17L16 24M9 17L16 10"
          stroke="#070A52"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="text-white font-bold">Go Back</span>
    </Link>
  );
};
