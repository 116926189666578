import { useLocation, useNavigate } from "react-router-dom";

import { DetailContainer } from "./DetailContainer";

const Task = ({ name, progress: { current, total }, ...props }) => {
  if (name === "Certification") {
    return null;
  }
  return (
    <button
      {...props}
      className={`w-full flex items-center justify-between gap-4 rounded-full bg-[#F6F6F6] ${
        props.disabled ? "cursor-not-allowed" : "cursor-pointer"
      } disabled:opacity-50 px-5 py-3`}
      disabled={props.disabled}
    >
      <span className="text-black text-xs">{name}</span>
      <span className="text-secondary text-xs">
        <span>{current}/</span>
        <span className="text-primary">{total} %</span>
      </span>
    </button>
  );
};

export const TaskToBeDone = ({
  tasks,
  showCertificateVerificationUploader,
  showAttendanceVerificationUploader,
  status,
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  function onTaskClicked(task) {
    switch (task.type) {
      case "Attendance":
        showAttendanceVerificationUploader(task.id);
        break;
      case "Certification":
        showCertificateVerificationUploader(task.id);
        break;
      case "Pre Test":
      case "Post Test":
        navigate(`${location.pathname}/quizes/${task.id}`);
        break;
      default:
        throw new Error("No task type available!");
    }
  }

  return (
    <DetailContainer
      title={"Task to be Done"}
      slot={{
        header: (
          <div className="inline-flex items-center gap-2">
            <img
              alt="red-check-circle"
              className="w-4 h-4 object-cover"
              src="/assets/icons/red-check-circle.svg"
            />
            <span className="text-secondary text-xs font-medium">
              {tasks.total_progress || 0}%
            </span>
          </div>
        ),
      }}
    >
      <div className={`flex flex-col gap-2`}>
        {tasks.items?.length
          ? tasks.items.map((task) => (
              <Task
                disabled={
                  (task.name === "Attendance" && status === "ON-GOING") ||
                  ["FINISHED", "FAILED", "CLAIMABLE"].includes(status)
                }
                key={task.id}
                name={task.name}
                progress={task.progress}
                onClick={() => onTaskClicked(task)}
              />
            ))
          : "-"}
      </div>
    </DetailContainer>
  );
};
