import { useQuery } from "react-query";
import { EventService } from "../services/event.service";

const eventService = new EventService();

export const useEventDetails = (eventId) => {
  const query = useQuery(
    ["get-event-by-id", { id: eventId }],
    eventService.get.eventDetails,
    {
      enabled: false,
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    query,
  };
};
