import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, message, Pagination, Modal, Input, Button, Spin, Card, Radio   } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import IconArrowLeft from "../../../assets/icons/IconArrowLeft.png"

import AdminLmsRepo from "../../../repositores/adminRepo/LmsRepo";

const { TextArea } = Input;

const DetailSubMatkul = () => { 
    const [formQuestion] = Form.useForm();

    const navigate = useNavigate();
    let { id, matkulId, subMatkulId } = useParams();
    
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [collection, setCollection] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshDataCounter, setRefreshDataCounter] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const [bounchPage, setBounchPage] = useState(undefined);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [detailQuestion, setDetailQuestion] = useState(undefined)

    const [optionAnswer, setOptionAnswer] = useState([])

    const [topik, setTopik] = useState("")

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, refreshDataCounter])

    async function fetchData() {
        setLimit(10);
        try {
            let tempData = [];
            let response = await AdminLmsRepo.getDetailSubMatkul(
                page,
                limit,
                subMatkulId
            );
            if (response?.data?.data?.content) { 
                await response?.data?.data?.content?.forEach((content, index) => {
                    setTopik(content.subMatkul.topik)
                    content.number = (limit*(page-1)) + (index+1)
                    tempData.push(content)
                })
                setCollection(tempData);
                setTotalItem(response?.data?.data?.totalElements);
                
            } else {
                setCollection([]);
                setTotalItem(0);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: "Failed to get Data" ,
            });
        }
    }

    const goBack = () => {
        navigate(`/admin/course/${id}/mata-kuliah/${matkulId}`)
    }

    const editQuestion = (record) => {
        formQuestion.setFieldValue('question', record.soal)
        formQuestion.setFieldValue('answer', record.opsiTrue)
        setOptionAnswer({
            "A": record.opsiA,
            "B": record.opsiB,
            "C": record.opsiC,
            "D": record.opsiD,
            "E": record.opsiE,
        })
        setDetailQuestion(record)
        setIsOpenModal(true)
    }

    const onChangePage = (page) => {
        setLoading(true);
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setPage(page)
        }, 1000))
    }

    const closeModal = () => {
        setRefreshCounter((prevVal) => prevVal + 1)
        setIsOpenModal(false)
        setDetailQuestion(undefined)
    }

    const updateQuestion = () => {
        let bodyPayload = {
            "id": detailQuestion.id,
            "soalNo": detailQuestion.soalNo,
            "soal": formQuestion.getFieldValue("question"),
            "opsiA": optionAnswer["A"],
            "opsiB": optionAnswer["B"],
            "opsiC": optionAnswer["C"],
            "opsiD": optionAnswer["D"],
            "opsiE": optionAnswer["E"],
            "opsiTrue": formQuestion.getFieldValue("answer"),
            "type": detailQuestion.type
        }

        console.log('payload', bodyPayload)
        formQuestion.validateFields().then(async () => {
            
            let bodyPayload = {
                "subMatkul": {
                    "id": subMatkulId
                },
                "id": detailQuestion.id,
                "soalNo": detailQuestion.soalNo ? Number(detailQuestion.soalNo) + 1 : 2,
                "soal": formQuestion.getFieldValue("question"),
                "opsiA": optionAnswer["A"],
                "opsiB": optionAnswer["B"],
                "opsiC": optionAnswer["C"],
                "opsiD": optionAnswer["D"],
                "opsiE": optionAnswer["E"],
                "opsiTrue": formQuestion.getFieldValue("answer"),
                "type": detailQuestion.type
            }
    
            console.log('payload', bodyPayload)
            try {
                closeModal()
                setLoading(true)
                const response = await AdminLmsRepo.updateQuestionSubMatkul(bodyPayload)
                console.log('response ', response)
                if(response.data.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: "Successfully update data" ,
                    });
                    setRefreshDataCounter((prevVal) => prevVal + 1)


                } else {
                    throw response.data
                }
            } catch (error) {
                if(error?.message || error?.data?.message || error?.error) {
                    messageApi.open({
                        type: 'error',
                        content: error?.message || error?.data?.message || error?.error ,
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: "sorry we're having trouble adding data right now, please try again later" ,
                    });
                }
                setLoading(false);
            }
            
            
            
        }).catch(() => {})
    }

    const onChangeInputAnswer = (option, el) => {
        let tempInputAnswer = optionAnswer
        tempInputAnswer[option] = el.target.value
        setOptionAnswer(tempInputAnswer)
    }


    return (<>
        {contextHolder}
        <div className="w-full" id="subMatkulDetail">
            <div className="w-[100%] px-[20px] py-[20px] flex">
                <div className="mt-[6px] mr-[20px]">
                    <button onClick={goBack} >
                        <img width={48} src={IconArrowLeft} alt="icon" />
                    </button>
                </div>
                <div>
                    <h4 className="text-[32px] font-bold">{topik}</h4>
                </div>
            </div>

            {
                loading  ? (<>
                    <div className="w-full text-center mt-[20px]">
                        <p>Please wait...</p>
                    </div>
                </>) : 
                <div className="mt-[20px] ml-[10px] w-full px-[20px]">
                    {
                        collection.map((_content, index) => {
                            return (
                                <>
                                    <div className="p-[10px]">
                                        <Card>
                                            <div className="flex justify-end">
                                                <Button type="primary" style={{ backgroundColor: "#DD0000" }} onClick={() => {editQuestion(_content)}}>
                                                    Edit
                                                </Button>
                                            </div>
                                            <div className="flex ">
                                                <p className="mr-[10px]">{((page-1)*limit)+(index + 1)}.</p>
                                                <p>
                                                    {_content.soal}
                                                </p>
                                            </div>
                                            <div className="mt-[10px]">
                                                <Radio defaultChecked={_content.opsiTrue === "A"} disabled={true}>{_content.opsiA}</Radio> <br />
                                                <Radio defaultChecked={_content.opsiTrue === "B"} disabled={true}>{_content.opsiB}</Radio> <br />
                                                <Radio defaultChecked={_content.opsiTrue === "C"} disabled={true}>{_content.opsiC}</Radio> <br />
                                                <Radio defaultChecked={_content.opsiTrue === "D"} disabled={true}>{_content.opsiD}</Radio> <br />
                                                <Radio defaultChecked={_content.opsiTrue === "E"} disabled={true}>{_content.opsiE}</Radio> <br />
                                            </div>
                                        </Card>
                                    </div>
                                </>
                            )
                        })
                    }

                    <div className="w-full flex justify-center my-2 pt-2 pb-10">
                        <Pagination
                            current={page}
                            onChange={onChangePage}
                            pageSize={limit}
                            total={totalItem}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            }

        </div>

        <Modal
            onCancel={() => closeModal()}
            title="Update Question"
            open={isOpenModal}
            footer={
                <>
                    <div className="flex justify-between mt-[20px]">
                        <Button key="back" danger onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#DD0000", color: "white" }}
                            onClick={() => updateQuestion()}
                        >
                            Update
                        </Button>
                    </div>
                </>
            }
        >
            <div className="container p-[10px] my-[30px]" key={refreshCounter}>
                <Form
                    labelAlign="left"
                    layout="vertical"
                    form={formQuestion}
                >
                    <Form.Item
                        label="Question"
                        name="question"
                        rules={[
                            {
                                required: true,
                                message: "Please input Question",
                            },
                        ]}
                    >
                        <TextArea rows={6} placeholder="Please input Question" />
                    </Form.Item>
                    <Form.Item label="Option Answer" name="answer" className="w-full">
                        <Radio.Group className="w-full">
                            <div className="flex gap-[10px]">
                                <Radio value="A" className="mt-[5px]" style={{display: "content"}}></Radio>
                                <Input className="w-full"  onChange={(el) => {onChangeInputAnswer("A", el)}}  defaultValue={optionAnswer["A"]}></Input>
                            </div>

                            <div className="flex gap-[10px] mt-[5px]">
                                <Radio value="B" className="mt-[5px]" style={{display: "content"}}></Radio>
                                <Input className="w-full"  onChange={(el) => {onChangeInputAnswer("B", el)}}  defaultValue={optionAnswer["B"]}></Input>
                            </div>

                            <div className="flex gap-[10px] mt-[5px]">
                                <Radio value="C" className="mt-[5px]" style={{display: "content"}}></Radio>
                                <Input className="w-full"  onChange={(el) => {onChangeInputAnswer("C", el)}}  defaultValue={optionAnswer["B"]}></Input>
                            </div>

                            <div className="flex gap-[10px] mt-[5px]">
                                <Radio value="D" className="mt-[5px]" style={{display: "content"}}></Radio>
                                <Input className="w-full"  onChange={(el) => {onChangeInputAnswer("D", el)}}  defaultValue={optionAnswer["B"]}></Input>
                            </div>

                            <div className="flex gap-[10px] mt-[5px]">
                                <Radio value="E" className="mt-[5px]" style={{display: "content"}}></Radio>
                                <Input className="w-full"  onChange={(el) => {onChangeInputAnswer("E", el)}}  defaultValue={optionAnswer["B"]}></Input>
                            </div>
                           
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </div>
        </Modal>

    </>)
}

export default DetailSubMatkul