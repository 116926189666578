import React from "react";
import dayjs from "dayjs";
import moment from "moment";
import { ConfirmDialog } from "./ConfirmDialog";

export const clientHistoryColumns = (
  handleView = (id) => {},
  setProjectTitle
) => {
  return [
    {
      title: "Role Name",
      dataIndex: ["userRole", "roleCompany", "name"],
      key: "id",
      width: 200,
      align: "center",
    },
    {
      title: "Client Name",
      dataIndex: ["clientCompany", "name"],
      key: "id",
      width: 200,
      align: "center",
    },
    {
      title: "Location",
      dataIndex: ["clientCompany", "location"],
      key: "id",
      width: 200,
      align: "center",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      width: 100,
      align: "center",
      render: (v, row) => {
        const styleData = clientStatusOptions.find(
          (item) => item.value === row.status
        );

        return (
          <div className="flex justify-center">
            {v.toLowerCase() === "active" ? (
              <div
                className={`w-[76px] justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
              >
                {v}
              </div>
            ) : (
              <div className="w-[76px] text-center rounded bg-[#FFCFCF] text-[#DD0000]">
                {v}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "id",
      width: 120,
      align: "center",
      render: (v) => {
        return <p>{moment(v).format("YYYY-MMM-DD")}</p>;
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "id",
      width: 120,
      align: "center",
      render: (v) => {
        return <p>{moment(v).format("YYYY-MMM-DD")}</p>;
      },
    },
    {
      title: "Status Talent",
      dataIndex: "statusTalent",
      key: "id",
      width: 150,
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Total Days",
      dataIndex: "totalDays",
      key: "id",
      width: 120,
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      align: "center",
      render: (v, row) => {
        return (
          <div>
            <button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                handleView(row.id);
                setProjectTitle({
                  clientName: row.clientCompany.name,
                  roleName: row.userRole.roleCompany.name,
                });
              }}
            >
              View Project
            </button>
          </div>
        );
      },
    },
  ];
};

export const clientHistoryDetailColumns = (
  isEditing = false,
  handleEditing,
  handleEdit = (v) => {},
  handleDelete = (v) => {},
  handleFormUpdate = (n, v) => {},
  editingForm,
  editingId
) => {
  return [
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (v, row) => {
        return <p>{v}</p>;
        /* return isEditing && row.id === editingId ? (
          <input
            type="text"
            className="border p-1 rounded-md border-[#d9d9d9] px-2 w-full"
            value={editingForm ? editingForm.name : ""}
            onChange={(v) => {
              handleFormUpdate("name", v.target.value);
            }}
          />
        ) : (
          <p>{v}</p>
        ); */
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      render: (v, row) => {
        return <p>{dayjs(v).format("YYYY-MMM-DD")}</p>;
        /* return isEditing && row.id === editingId ? (
          <DatePicker
            onChange={(date, dateString) => {
              handleFormUpdate("startDate", date);
            }}
            value={dayjs(editingForm.startDate)}
            defaultValue={dayjs()}
          />
        ) : (
          <p>{dayjs(v).format("YYYY-MMM-DD")}</p>
        ); */
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      render: (v, row) => {
        return <p>{dayjs(v).format("YYYY-MMM-DD")}</p>;
        /* return isEditing && row.id === editingId ? (
          <DatePicker
            onChange={(date, dateString) => {
              handleFormUpdate("endDate", date);
            }}
            value={dayjs(editingForm.endDate)}
            defaultValue={dayjs().format("")}
          />
        ) : (
          <p>{dayjs(v).format("YYYY-MMM-DD")}</p>
        ); */
      },
    },
    {
      title: "Project Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "auto",
      render: (v, row) => {
        return <p>{v}</p>;
      },
    },
    {
      title: "Status Project",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (v, row) => {
        const styleData = statusOptions.find(
          (item) => item.value === row.status
        );
        return (
          <div className="flex justify-center">
            <div
              className={`w-[100px] px-1 justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
            >
              {v}
            </div>
            {/* {v.toLowerCase() === "new" ? (
              <div className="w-[76px] justify-center text-center rounded bg-[#D4FDD1] text-[#4BB543]">
                {v}
              </div>
            ) : (
              <div className="w-[76px] text-center rounded bg-[#FFCFCF] text-[#DD0000]">
                {v}
              </div>
            )} */}
          </div>
        );
        /* return isEditing && row.id === editingId ? (
          <Select
            size="large"
            className="w-full"
            value={statusOptions[editingForm?.status]}
            defaultValue={statusOptions["NEW"]}
            onChange={(v) => {
              handleFormUpdate("status", v);
            }}
            placeholder={"Choose Status"}
            options={statusOptions}
          />
        ) : (
          <div className="flex justify-center">
            {v ? (
              <div className="w-[76px] justify-center text-center rounded bg-[#D4FDD1] text-[#4BB543]">
                {v}
              </div>
            ) : (
              <div className="w-[76px] text-center rounded bg-[#FFCFCF] text-[#DD0000]">
                {v}
              </div>
            )}
          </div>
        ); */
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (v, row) => {
        return (
          <div className="flex">
            <button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                handleEdit(row.id);
              }}
            >
              Edit
            </button>
            <ConfirmDialog
              title="Do you want to delete?"
              content=""
              okText="Delete"
              cancelText="Cancel"
              handleConfirm={() => {
                handleDelete(row.id);
              }}
              buttonText={"Delete"}
            />
            {/* <button
              className="bg-[#DD0000] text-white w-[100px] py-1 font-bold rounded ms-3"
              onClick={() => {
                handleDelete(row.id);
              }}
            >
              Delete
            </button> */}
          </div>
        );
      },
    },
  ];
};

export const statusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "SOLVE",
    label: "SOLVE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "HOLD",
    label: "HOLD",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
];

export const clientStatusOptions = [
  {
    value: "NEW",
    label: "NEW",
    color: "text-[#3b69ae]",
    bgColor: "bg-[#c4dafc]",
  },
  {
    value: "ACTIVE",
    label: "ACTIVE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "SOLVE",
    label: "SOLVE",
    color: "text-[#43ae3b]",
    bgColor: "bg-[#c4fccb]",
  },
  {
    value: "INPROGRESS",
    label: "INPROGRESS",
    color: "text-[#ae843b]",
    bgColor: "bg-[#fce7c4]",
  },
  {
    value: "REJECT",
    label: "REJECT",
    color: "text-[#ae3b3b]",
    bgColor: "bg-[#fcc4c4]",
  },
];
