import { Modal, Table } from "antd";
import React from "react";
import { RetroCandidateInterviewColumns } from "./column.state";

const AdminDetailView = ({
  show,
  closePopUp,
  modalTitle,
  tableData,
  handleSubmitCheck,
  profile,
  getUrlCourseId = () => {},
}) => {
  return (
    <Modal
      className=""
      centered
      width={"80%"}
      open={show}
      footer={[]}
      onCancel={() => {
        closePopUp();
      }}
      destroyOnClose={true}
    >
      <div className="flex flex-col  text-xl my-5">
        <div className="flex gap-5">
          <div>
            Client Name <br />
            Postition
          </div>
          <div>
            : {profile?.account?.name} <br /> : {profile?.role?.name}
          </div>
        </div>
      </div>
      <div className="mt-[10px] w-full">
        <Table
          columns={RetroCandidateInterviewColumns(
            (id) => {
              handleSubmitCheck(id);
            },
            (courseId, matkulId, course) => {
              getUrlCourseId(courseId, matkulId, course);
            }
          )}
          dataSource={tableData}
          pagination={false}
          sticky={true}
          className="font-semibold mb-4"
          scroll={{
            y: 470,
            x: 1200,
          }}
        />
      </div>
    </Modal>
  );
};

export default AdminDetailView;
