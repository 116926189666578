import React, { useEffect, useState } from "react";
import { Card, Button, Progress } from "antd";
import "./style.css";
import DashboardTalentRepo from "../../../../repositores/talentRepo/DashboardTalentRepo";
import { Link, useNavigate } from "react-router-dom";
import RouteTalent from "../../../../services/RouteTalent";

const TalentBar = () => {
  const navigate = useNavigate();
  const [coin, setCoin] = useState(0);
  const [userRank, setUserRank] = useState({
    rank: 0,
    total: 0,
    position: 0,
  });
  const [userBadge, setUserBadge] = useState({
    badgeName: "",
    badgesXp: 0,
    badgeLevel: 0,
    badgesXpTotal: 0,
  });

  const name = localStorage.getItem("name");

  const getDataTalent = async () => {
    try {
      const { data } = await DashboardTalentRepo.getDashboardTalent();
      const { gcTotal, rangked, totalUser, badgesXp, badges } = data.data;
      const userPosition = totalUser - rangked;
      setCoin(gcTotal);
      setUserRank((user) => ({
        ...user,
        total: totalUser,
        rank: rangked,
        position: userPosition,
      }));
      setUserBadge((uBadge) => ({
        ...uBadge,
        badgeName: badges.name,
        badgesXp: badgesXp,
        badgeLevel: badges.level,
        badgesXpTotal: badges.xp,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataTalent();
  }, []);

  return (
    <div className="mb-5">
      <div className=" text-size-40 font-weight-600 mb-1 text-dark-grey  hidden md:block">
        Welcome, {name}
      </div>
      <div className="text-size-20 font-weight-500 hidden md:block md:mb-[35px] text-grey">
        Take a look at your journey.
      </div>

      {/* Updated Grid Layout */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card className="col-span-1 rounded-2xl border border-gray-300">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="badge-xp-container">
              <div className="badge" style={{ display: "flex" }}>
                <img
                  src={`/assets/icons/dashboard_badge.svg`}
                  alt="badge"
                  style={{ width: "40px", height: "40px" }}
                />
                <div className="ml-5">
                  <p className="text-size-14 font-weight-500">Current Badge</p>
                  <p className="font-weight-600 text-size-18">
                    {userBadge.badgeName}
                  </p>
                </div>
              </div>
              <div className="xp mt-5" style={{ display: "flex" }}>
                <img
                  src={`/assets/icons/xpIcon.svg`}
                  alt="xp"
                  style={{ width: "40px", height: "40px" }}
                />
                <div className="xp ml-5">
                  <p className="text-size-14 font-weight-500">Total XP</p>
                  <p className="font-weight-600 text-size-18">
                    {userBadge.badgesXp}
                  </p>
                </div>
              </div>
            </div>
            <div className="foto">
              <img
                src={`/assets/icons/picture.png`}
                alt="xp"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>

          <div className="level mt-5">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <p className="font-bold text-xl">Level {userBadge.badgeLevel}</p>
              <p className="text-dark-grey font-weight-500 text-size-10">
                {userBadge.badgesXp}/{userBadge.badgesXpTotal} XP
              </p>
            </div>
            <Progress
              strokeColor={"#DD0000"}
              percent={userBadge.badgesXp}
              showInfo={false}
            />
          </div>
        </Card>

        <Card className="col-span-1 rounded-2xl border border-gray-300">
          <div className="cardFront">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="font-bold text-xl">My Coins</p>
              <img
                src={`/assets/icons/help.png`}
                alt="help"
                style={{ width: "1rem", height: "1rem" }}
              />
            </div>
            <div className="mt-3 flex justify-start gap-4">
              <div className="flex justify-center gap-3">
                <img
                  src={`/assets/icons/coin.png`}
                  alt="coin"
                  style={{ width: "2rem" }}
                />
                <p className="text-[#FEA832] font-bold text-xl md:text-3xl">
                  {coin}
                </p>
              </div>
            </div>
            <p className="text-grey font-weight-500 text-size-16 mt-1">
              Exchange your coins for rewards
            </p>
            <div>
              <Link
                to={RouteTalent.coinHistory}
                className="text-[#4B4DED] font-weight-600 text-size-16"
              >
                View History{" "}
                <span className="font-weight-600 text-size-16">&gt;</span>
              </Link>
            </div>
          </div>
          <Button
            className="mt-[10%] md:mt-[12%] font-bold flex justify-center"
            style={{ background: "#DD0000", color: "white", width: "100%" }}
            onClick={() => navigate(RouteTalent.reward)}
          >
            Redeem Coins
          </Button>
        </Card>

        <Card className="col-span-1 rounded-2xl border border-gray-300">
          <p className="font-bold text-xl">My Rank</p>
          <div
            className="mt-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={`/assets/icons/rank.png`}
              alt="rank"
              style={{ width: "2rem" }}
            />
            <div style={{ display: "flex", alignItems: "end" }}>
              <p className="text-[#000000] font-weight-800 text-xl md:text-size-30 ml-5">
                {userRank.rank}
              </p>
              <p className="text-[#6C6FA9] font-weight-600 text-sm md:text-size-16">
                {" "}
                / {userRank.total}
              </p>
            </div>
          </div>
          <p className="text-[#969696] font-weight-500 text-size-16 mt-1">
            Wow, you're ahead of {userRank.position} others!
          </p>
          <Button
            className="hidden sm:block font-bold"
            style={{
              background: "#DD0000",
              color: "white",
              width: "100%",
              marginTop: "20%",
            }}
            onClick={() => navigate(RouteTalent.leaderboard)}
          >
            Let’s, Find Out Who Leads!
          </Button>
          <Button
            className="block sm:hidden font-bold"
            style={{
              background: "#DD0000",
              color: "white",
              width: "100%",
              marginTop: "13%",
            }}
            onClick={() => navigate(RouteTalent.leaderboard)}
          >
            Get More XP
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default TalentBar;
