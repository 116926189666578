import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Pagination } from "antd";
import { onChangeState } from "../../../../../features/certificateIssuedSlice";

const CertificateIssuedTable = ({
  data,
  openRevoke,
  actionDetails,
  actionPreview,
}) => {
  const dispatch = useDispatch();

  const { pagination, activeTab } = useSelector(
    (state) => state.certificateIssued
  );

  const getIdCertificate = (actionTask, selectedId) => {
    if (actionTask === "open") openRevoke(selectedId);
    else if (actionTask === "details") actionDetails(selectedId);
    else if (actionTask === "preview") actionPreview(selectedId);
  };

  const onChangeTable = async (pagination, filters, sorter, extra) => {
    if (sorter?.order) {
      dispatch(
        onChangeState({
          stateProp: "pagination.currentPage",
          valueProp: 0,
        })
      );
      let sortConfig = {};
      if (sorter.field === "issuedTo") {
        sortConfig.orderBy = "issuedTo.fullname";
      }
      if (sorter.field === "type") {
        sortConfig.orderBy = "certificate.typeCertificate.name";
      }
      if (sorter.field === "date") {
        sortConfig.orderBy = "issuedDate";
      }
      if (sorter.field === "revoked") {
        sortConfig.orderBy = "revokeDate";
      }
      sortConfig.orderType =
        sorter?.order === "ascend"
          ? "asc"
          : sorter?.order === "descend"
          ? "desc"
          : "";
      dispatch(
        onChangeState({ stateProp: "sortParams", valueProp: sortConfig })
      );
    }
  };

  const onChangePagination = (current, pageSize) => {
    let nextPagination = pagination;
    nextPagination = { ...nextPagination, currentPage: current };
    dispatch(
      onChangeState({
        stateProp: "pagination",
        valueProp: nextPagination,
      })
    );
  };

  const validData = data.map((item, idx) => ({
    ...item,
    id: item.id || `temp-${idx}`,
  }));

  const columns = [
    {
      title: activeTab === "issued" ? "Date Issued" : "Date Revoked",
      dataIndex: activeTab === "issued" ? "date" : "revoked",
      key: activeTab === "issued" ? "date" : "revoked",
      sorter: {},
      align: "center",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Certificate Title",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "Certificate Type",
      dataIndex: "type",
      key: "type",
      sorter: { onChangeTable },
      align: "center",
    },
    {
      title: "Issued To",
      dataIndex: "issuedTo",
      key: "issuedTo",
      sorter: { onChangeTable },
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, data) => (
        <div className="flex flex-row">
          {activeTab === "issued" && (
            <div className="flex flex-row">
              <button
                key={`preview-${data?.id}`}
                className="px-5 text-blue-900 pointer-cursor font-medium"
                onClick={(event) =>
                  event.preventDefault(getIdCertificate("preview", data?.key))
                }
              >
                Preview
              </button>
              <button
                key={`revoke-${data?.id}`}
                className="bg-red-600 flex border-2 text-white w-[80px] justify-center rounded-[7px] cursor-pointer"
                onClick={(event) =>
                  event.preventDefault(getIdCertificate("open", data?.key))
                }
              >
                Revoke
              </button>
            </div>
          )}
          {activeTab === "revoke" && (
            <div>
              <button
                key={`details-${data?.id}`}
                className="bg-white flex border-2 border-red-600 text-red-600 w-[80px] justify-center rounded-[7px] cursor-pointer"
                onClick={(event) =>
                  event.preventDefault(getIdCertificate("details", data?.id))
                }
              >
                Detail
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="w-full">
        <Table
          columns={columns}
          dataSource={validData}
          pagination={false}
          sticky={true}
          scroll={{
            x: 1200,
            y: 520,
          }}
          onChange={onChangeTable}
        />
      </div>
      <div className="flex justify-center mt-2">
        <Pagination
          defaultCurrent={pagination?.current_page}
          total={pagination?.totalData}
          defaultPageSize={pagination?.limit}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      </div>
    </>
  );
};

export default CertificateIssuedTable;
