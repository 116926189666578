import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListHistory = async (page, size, name) => {
  let params = {
    page: page.toString(),
    size: size,
    name: name,
  };
  if (typeof name != "undefined" && name.length) {
    params["description"] = name;
  }
  const url = `${API_BASE}/redeem-coins/list?${createSearchParams(params)}`;
  const response = await CallApi({ url, method: "GET" });

  return response;
};

const RedeemHistoryRepo = {
  getListHistory,
};

export default RedeemHistoryRepo;
