import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RouteName from "../../helper/routeName";
import { Form, Input } from "antd";
import Swal from "sweetalert2";
import AuthRepo from "../../repositores/authRepo/LoginRepo";
import LogoRepo from "../../repositores/adminRepo/LogoRepo";

const ForgotPassword = () => {
  const baseUrl = process.env.REACT_APP_BASE;
  const [email, setEmail] = useState("");
  const [logoGlobal, setLogoGlobal] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const back = "< Back To Login";

  const getLogo = async () => {
    try {
      const response = await LogoRepo.getLogoNotToken();
      const logo = response.data?.data?.content;
      setLogoGlobal(logo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  const keyupEvent = (e) => {
    if (e.charCode === 13) {
      onRequest();
    }
  };

  const onRequest = () => {
    if (email === "") {
      Swal.fire({
        title: "Error",
        text: "Email cannot be empty!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        color: "#DD0000",
      });
    } else {
      requestForgotPassword();
    }
  };

  const requestForgotPassword = async () => {
    try {
      const urlFe = `${baseUrl}set-new-password?email=${email}`;
      const { data } = await AuthRepo.forgotPassword({ email, urlFe });
      if (data.status !== 200) {
        Swal.fire({
          title: "Failed",
          toast: true,
          text: data.message,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          color: "#DD0000",
        });
      } else {
        setEmail("");
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Please check email to validate and change new password.",
        }).then(() => {
          navigate(RouteName.login);
        });
      }
    } catch (error) {
      const err = error.response;
      if (err) {
        Swal.fire({
          toast: true,
          text: err.message,
        });
      }
    }
  };

  return (
    <>
      <div className="flex justify-center">
        {logoGlobal?.map((item) => (
          <img key={item.id} alt={item.id} src={item.name} className="mb-8" />
        ))}
      </div>
      <div className="text-xl md:text-3xl font-bold text-[#4C4C4C] mb-2">
        Forgot Password
      </div>
      <div className="text-xs md:text-base text-[#4C4C4C] mb-8">
        Enter your email address associated with your account and we’ll send you
        a link to reset your password.
      </div>
      {/* main */}
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="email"
          value={email}
          onKeyUp={(e) => keyupEvent(e)}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          className="mb-3 font-medium"
        >
          <Input type="email" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item>
          <button
            onClick={() => onRequest()}
            className="w-full bg-[#DD0000] py-2 rounded-md text-white font-semibold mt-6"
          >
            Continue
          </button>
        </Form.Item>
      </Form>

      <div
        className="font-bold text-[#DD0000] my-5 cursor-pointer"
        onClick={() => navigate(RouteName.login)}
      >
        {back}
      </div>
      {/* end main */}
    </>
  );
};

export default ForgotPassword;
