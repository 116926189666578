import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, message, Pagination, Modal, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import AdminLmsRepo from "../../../repositores/adminRepo/LmsRepo";
import TableCourses from "./components/tableListCourses";
import ModalImportCourse from "./components/modalImportCourse";

const { TextArea } = Input;

const LmsCourse = () => {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const [searchCourse, setSearchCourse] = useState(undefined);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dataTable, setDataTable] = useState([]);
  const [totalItem, setTotalItem] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);

  const [bounchPage, setBounchPage] = useState(undefined);

  const [formCourse] = Form.useForm();
  const [titleModal, setTitleModal] = useState("Add Course");
  const [formType, setFormType] = useState("add");
  const [detailCourse, setDetailCourse] = useState(undefined);

  const [typeInject, setTypeInject] = useState("course");
  const [isOpenModalInject, setIsOpenModalInject] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchCourse, refreshCounter]);

  async function fetchData() {
    setLimit(10);
    try {
      let tempData = [];
      let response = await AdminLmsRepo.getListCourses(
        page,
        limit,
        searchCourse
      );
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content, index) => {
          content.number = limit * (page - 1) + (index + 1);
          tempData.push(content);
        });
        setDataTable(tempData);
        setTotalItem(response?.data?.data?.totalElements);
      } else {
        setDataTable([]);
        setTotalItem(0);
      }
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
      messageApi.open({
        type: "error",
        content: "Failed to get Data event",
      });
    }
  }

  async function postCourse(payload) {
    setLoadingData(true);
    formCourse.resetFields();
    setIsOpenModal(false);
    try {
      let response = await AdminLmsRepo.addCourse(payload);
      if (response.data.status === 200) {
        const counter = refreshCounter + 1;
        messageApi.open({
          type: "success",
          content: "Successfully add data",
        });
        setRefreshCounter(counter);
      } else {
        throw response.data;
      }
    } catch (error) {
      if (error?.message || error?.data?.message || error?.error) {
        messageApi.open({
          type: "error",
          content: error?.message || error?.data?.message || error?.error,
        });
      } else {
        messageApi.open({
          type: "error",
          content:
            "sorry we're having trouble adding data right now, please try again later",
        });
      }
      setLoadingData(false);
    }
  }

  async function putCourse(payload) {
    setLoadingData(true);
    formCourse.resetFields();
    setIsOpenModal(false);
    try {
      let response = await AdminLmsRepo.updateCourse(payload);
      if (response.data.status === 200) {
        const counter = refreshCounter + 1;
        messageApi.open({
          type: "success",
          content: "Successfully update data",
        });
        setRefreshCounter(counter);
      } else {
        throw response.data;
      }
    } catch (error) {
      if (error?.message || error?.data?.message || error?.error) {
        messageApi.open({
          type: "error",
          content: error?.message || error?.data?.message || error?.error,
        });
      } else {
        messageApi.open({
          type: "error",
          content:
            "sorry we're having trouble updating data right now, please try again later",
        });
      }
      setLoadingData(false);
    }
  }

  async function deleteCourse() {
    setLoadingData(true);
    setIsOpenConfirm(false);
    try {
      let response = await AdminLmsRepo.deleteCourse(detailCourse.id);
      if (response.data.status === 200) {
        const counter = refreshCounter + 1;
        messageApi.open({
          type: "success",
          content: "Successfully delete data",
        });
        setDetailCourse(undefined);
        if (dataTable.length === 1 && page > 1) {
          setPage(page - 1);
        } else {
          setRefreshCounter(counter);
        }
      } else {
        throw response.data;
      }
    } catch (error) {
      setDetailCourse(undefined);
      if (error?.message || error?.data?.message || error?.error) {
        messageApi.open({
          type: "error",
          content: error?.message || error?.data?.message || error?.error,
        });
      } else {
        messageApi.open({
          type: "error",
          content:
            "sorry we're having trouble deleting data right now, please try again later",
        });
      }
      setLoadingData(false);
    }
  }

  const onChangePage = (el) => {
    setLoadingData(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setPage(el);
      }, 1000)
    );
  };

  const onChangeSearch = (el) => {
    setLoadingData(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setSearchCourse(el.target.value);
        setPage(1);
      }, 2000)
    );
  };

  const selectEdit = (data) => {
    if (data) {
      setFormType("update");
      setTitleModal("Edit Course");
      formCourse.setFieldValue("name", data.name);
      formCourse.setFieldValue("description", data.description);
      setDetailCourse(data);
      setIsOpenModal(true);
    }
  };
  const selectDelete = (data) => {
    setDetailCourse(data);
    setIsOpenConfirm(true);
  };
  const selectToDetail = (data) => {
    navigate("/admin/course/" + data.id);
  };

  const closeModal = (modal = "form") => {
    if (modal === "form") {
      formCourse.resetFields();
      setIsOpenModal(false);
    } else {
      setDetailCourse(undefined);
      setIsOpenConfirm(false);
    }
  };
  const addCourse = () => {
    setFormType("add");
    setTitleModal("Add Course");
    setIsOpenModal(true);
  };

  const submitCourse = () => {
    formCourse
      .validateFields()
      .then(() => {
        let payload = {
          name: formCourse.getFieldValue("name"),
          description: formCourse.getFieldValue("description"),
        };
        if (formType === "add") {
          postCourse(payload);
        } else {
          payload.id = detailCourse.id;
          putCourse(payload);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5" id="courseList">
        <div className="text-center mb-2 md:text-left">
          <h4 className="text-2xl md:text-4xl font-bold">Courses</h4>
        </div>

        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 mb-4 lg:mb-0">
            <Button
              onClick={() => {
                setTypeInject("pg");
                setIsOpenModalInject(true);
              }}
              type="primary"
              disabled={loading}
              danger
              className="bg-[#DD0000]"
              style={{ backgroundColor: "#DD0000" }}
            >
              Upload Multiple Choice
            </Button>
            <Button
              onClick={() => {
                setTypeInject("course");
                setIsOpenModalInject(true);
              }}
              type="primary"
              disabled={loading}
              danger
              className="bg-[#DD0000]"
              style={{ backgroundColor: "#DD0000" }}
            >
              Upload Course
            </Button>
          </div>
          <div className="flex flex-row gap-4 mb-4 lg:mb-0">
            <Input
              className="w-full lg:w-[300px] max-w-[400px]"
              placeholder="Search"
              disabled={loading}
              onChange={onChangeSearch}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            />
            <Button
              onClick={() => addCourse()}
              type="primary"
              disabled={loading}
              danger
              className="mr-1 bg-[#DD0000]"
              style={{ backgroundColor: "#DD0000" }}
            >
              Add Course
            </Button>
          </div>
        </div>
        {loading || loadingData ? (
          <>
            <div className="w-full text-center mt-[20px]">
              <p>Please wait...</p>
            </div>
          </>
        ) : (
          <div className="mt-[20px] w-full">
            <TableCourses
              isLoading={loading}
              dataTable={dataTable}
              selectEdit={(data) => selectEdit(data)}
              selectDelete={(data) => selectDelete(data)}
              selectToDetail={(data) => selectToDetail(data)}
            />
            <div className="w-full flex justify-center my-2 pt-2 pb-10">
              <Pagination
                current={page}
                onChange={onChangePage}
                pageSize={limit}
                total={totalItem}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        onCancel={() => closeModal()}
        title={titleModal}
        open={isOpenModal}
        footer={
          <>
            <div className="flex justify-between mt-[20px]">
              <Button key="back" danger onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#DD0000", color: "white" }}
                onClick={() => submitCourse()}
              >
                Submit
              </Button>
            </div>
          </>
        }
      >
        <div className="container p-[10px] my-[30px]">
          <Form
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            form={formCourse}
            colon={false}
            wrapperCol={{ flex: 1 }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name",
                },
              ]}
            >
              <Input
                placeholder="Please input name"
                disabled={formType !== "add"}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input Description",
                },
              ]}
            >
              <TextArea rows={6} placeholder="Please input description" />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        onCancel={() => closeModal("confirm")}
        title="Delete the course"
        open={isOpenConfirm}
        footer={
          <>
            <div className="flex justify-between mt-[20px]">
              <Button key="back" danger onClick={() => closeModal("confirm")}>
                No
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#DD0000", color: "white" }}
                onClick={() => deleteCourse()}
              >
                Yes
              </Button>
            </div>
          </>
        }
      >
        <div className="container p-[10px] my-[30px]">
          <p>
            Are you sure to delete this `<b>{detailCourse?.name}</b>` ?
          </p>
        </div>
      </Modal>

      <ModalImportCourse
        show={isOpenModalInject}
        closeModal={(finish) => setIsOpenModalInject(finish)}
        type={typeInject}
      />
    </>
  );
};
export default LmsCourse;
