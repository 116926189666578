import React, { useEffect, useState } from "react";
import SkeletonTable from "../../../components/general/SkeletonTable";
import { Modal, Pagination, Table } from "antd";
import AlertMiniPopUp from "../componets_global/AlertMiniPopUp";
import { AddStatusTalent } from "./AddStatusTalent";

export const ViewStatusTalent = ({
  updateSuccess,
  setUpdateSuccess,
  show,
  form,
  closeModal,
  loading = false,
  changePagination,
  data,
  columns,
  roleName = "",
  clientName = "",
  total = 0,
  page = 0,
  search = "",
  handleSearch = () => {},
  handleAdd = (c, v) => {},
  handleEdit = () => {},
  editingForm,
  handleEditForm = (f, v) => {},
  handleResetForm,
  showForm,
  setShowForm,
  isEditing,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    status: "false",
  });
  const totalData = total;

  useEffect(() => {
    if (!showForm) handleResetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  return (
    <>
      <AddStatusTalent
        form={form}
        show={showForm}
        updateSuccess={updateSuccess}
        setUpdateSuccess={setUpdateSuccess}
        closeModal={setShowForm}
        handleAdd={handleAdd}
        handleEditForm={handleEditForm}
        handleEdit={handleEdit}
        editingForm={editingForm}
        isEditing={isEditing}
      />
      <Modal
        width={1200}
        open={show}
        onCancel={() => closeModal(false)}
        footer={null}
        closeIcon={false}
      >
        {messageAlert?.message !== "" && (
          <AlertMiniPopUp
            messageAlert={messageAlert.message}
            isSuccess={messageAlert.status}
          />
        )}

        <div className="flex flex-col justify-between w-full mb-4">
          <p className="text-[20px] font-weight-600 mb-5">List Project</p>
          <div className="flex gap-5">
            <p className="text-[16px] font-weight-600 w-32 flex justify-between">
              <span>Role Name</span>
              <span>:</span>
            </p>
            <p className="text-[16px] font-weight-600">{roleName}</p>
          </div>
          <div className="flex gap-5">
            <p className="text-[16px] font-weight-600 w-32 flex justify-between">
              <span>Client Name</span>
              <span>:</span>
            </p>
            <p className="text-[16px] font-weight-600">{clientName}</p>
          </div>
          <div className="flex items-end justify-end">
            <input
              type="text"
              placeholder="Search Project Name..."
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              className="border rounded-md w-[200px] py-1 px-2"
            />
          </div>
        </div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                sticky={true}
                rowKey={"id"}
                scroll={{
                  x: true,
                  y: 520,
                }}
              />
            </div>
            <Pagination
              current={page + 1}
              total={totalData}
              showSizeChanger={false}
              onChange={(p) => changePagination(p)}
              className="flex justify-center"
            />
          </>
        )}
      </Modal>
    </>
  );
};
