import { handleDateFormating } from "./utility";

const getIcon = (item) => {
  if (item.event && !item.isChampion) {
    return "/assets/icons/history-event.svg";
  } else if (item.isChampion) {
    return "/assets/icons/history-trophy.svg";
  } else {
    return "/assets/icons/history-gift.svg";
  }
};

const titleWording = (item) => {
  if (item.isChampion) {
    return (
      <p className="text-sm md:text-base lg:text-lg font-weight-500">
        You are{" "}
        <span className="font-weight-700 text-sm md:text-base lg:text-lg">
          “{item?.title}“
        </span>{" "}
        at {item?.event?.name}
      </p>
    );
  } else if (item.event && !item.isChampion) {
    return (
      <p className="text-sm md:text-base lg:text-lg font-weight-500">
        Complete Event{" "}
        <span className="font-weight-700 text-sm md:text-base lg:text-lg">
          “{item?.event?.name}”
        </span>
      </p>
    );
  } else {
    return (
      <p className="text-sm md:text-base lg:text-lg font-weight-500">
        {item.voucher?.description ?? item.event?.name}
      </p>
    );
  }
};

const HistoryDetail = ({ historyData }) => {
  return (
    <div className="flex flex-col gap-3 pt-10 flex-1">
      <div className="grid mx-auto gap-3 grid-cols-12 w-full px-3 md:px-16 overflow-y-auto">
        {historyData &&
          historyData?.content?.map((item, index) => {
            return (
              <div
                key={item.id}
                className="px-4 py-4 md:py-6 flex flex-row col-span-12 items-center justify-between gap-3 h-auto card-shadow"
              >
                <div className="flex justify-start gap-3">
                  <div
                    className="w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 flex justify-center mx-auto"
                    style={{
                      borderRadius: "20px",
                      background:
                        "linear-gradient(180deg, #F00 0%, rgba(255, 46, 0, 0.50) 100%)",
                    }}
                  >
                    {item && (
                      <img
                        src={getIcon(item)}
                        alt="your-img"
                        className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12"
                      />
                    )}
                  </div>
                  <div className="flex flex-col justify-center gap-1">
                    <CardItemsField
                      className="text-dark-grey font-bold text-sm md:text-base"
                      fieldContent={titleWording(item)}
                    />
                    <CardItemsField
                      className="text-grey font-bold text-xs md:text-sm"
                      fieldContent={handleDateFormating(item.created_date)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end w-auto">
                  <div className="flex gap-2 text-coin-yellow font-bold items-center">
                    <img
                      alt="coin"
                      className="w-6 h-6 md:w-8 md:h-8 object-cover"
                      src="/assets/images/coin.svg"
                    />
                    <p
                      className={`font-weight-400 text-sm md:text-base lg:text-lg flex flex-row justify-start gap-2 items-center ${
                        item?.status === "IN" ? "text-green" : "text-primary"
                      }`}
                    >
                      <span>
                        {item?.status === "IN" ? "+" : "-"} {item.gcTotal}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const CardItemsField = ({ title, fieldContent, ...props }) => (
  <div {...props}>
    <p>
      {title && <span>{title}</span>}
      <span>{fieldContent}</span>
    </p>
  </div>
);

export default HistoryDetail;
