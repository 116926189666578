import React from "react";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import RouteAdm from "../../../../../services/routeAdm";
import { isNullOrEmpty } from "../../../../../utils/utils";

const TaskTable = ({ DataTable, onDelete, isLoading }) => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      render: (_, record) => {
        if (isNullOrEmpty(record.description)) {
          return "-";
        }
        return <div className="remark-cell">{record.description}</div>;
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text) => <div>{text?.fullname}</div>,
    },
    {
      title: "Last Edited By",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      render: (text) => <div>{text?.fullname}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "id",
      render: (_, record) => (
        <div className="flex flex-row">
          <div
            className="bg-white flex border-2 border-red-600 text-red-600 w-[60px] justify-center items-center rounded-[7px] cursor-pointer"
            onClick={() =>
              navigate(RouteAdm.addTask, {
                state: { screen: "EDIT", data: record },
              })
            }
          >
            Edit
          </div>
          <img
            src="/assets/icons/delete.svg"
            alt=""
            className="ml-4 cursor-pointer"
            onClick={() => {
              onDelete(record.id);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="mt-[10px] w-full">
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={DataTable}
        pagination={false}
        sticky={true}
        scroll={{
          x: 800,
          y: 520,
        }}
      />
    </div>
  );
};

export default TaskTable;
