import { useMemo, useRef, useState } from "react";

import MasterEventRepo from "../../../../../repositores/adminRepo/MasterEventRepo";
import { EventService } from "../services/event.service";
import { useParams } from "react-router-dom";

const eventService = new EventService();

export const useFileUploader = ({ acceptedFileTypes }) => {
  const params = useParams();

  const inputRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);

  const isAnyFileUploaded = useMemo(() => {
    return Boolean(uploadedFile);
  }, [uploadedFile]);

  function uploadFile(file) {
    if (!file) return;

    const isFileTypeValid = acceptedFileTypes.includes(file.type);
    if (!isFileTypeValid) alert("Invalid file type");

    setUploadedFile(file);
  }

  async function uploadThenSubmitFile() {
    if (!uploadedFile) throw new Error("No file uploaded!");

    setIsUploading(true);
    try {
      const response = await MasterEventRepo.uploadImage(uploadedFile);

      const fileUri = response.data.data.fileDownloadUri;
      await submitFile(fileUri);
    } catch (error) {
      throw error;
    } finally {
      setIsUploading(false);
    }
  }

  async function submitFile(fileUrl) {
    if (!fileUrl) throw new Error("Please input a fileUrl!");

    setIsUploading(true);
    const eventId = params.eventId;
    const taskId = params.taskId;

    try {
      await eventService.post.verificationUri(fileUrl, {
        eventId,
        taskId,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsUploading(false);
    }
  }

  function detachFile() {
    if (inputRef.current) inputRef.current.value = null;
    setUploadedFile(null);
  }

  return {
    inputRef,
    isAnyFileUploaded,
    isUploading,
    uploadedFile,
    detachFile,
    submitFile,
    uploadFile,
    uploadThenSubmitFile,
  };
};
