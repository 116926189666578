import { useState, useEffect, useRef } from "react";
import { Form, Input, Select, TimePicker } from "antd";
import MasterEventRepo from "../../../../../repositores/adminRepo/MasterEventRepo";
import dayjs from "dayjs";
import AlertUtility from "../../../../../components/Alert/Alert";
const { TextArea } = Input;
const { Option } = Select;
const formatTimePicker = "HH:mm";

const FormDataMasterEvent = ({
  formType = "add",
  getStateMount,
  getDataMasterEvent,
  defaultFormValue,
  isDisabled = false,
  isWillDelete = false,
}) => {
  let titleModal =
    formType === "add" ? "Add Event" : isWillDelete ? "" : "Edit Event";
  let subTitle =
    formType === "add"
      ? "Enter your data below!"
      : isWillDelete
      ? ""
      : "Please enter your data below";
  const [formEvent] = Form.useForm();

  const [mounted, setMounted] = useState(false);
  const isMountedRef = useRef(false);

  useEffect(() => {
    let isMounted = true;
    isMountedRef.current = true;

    const fetchData = async () => {
      if (isMounted) {
        formEvent.setFieldValue(
          "eventId",
          defaultFormValue.event.masterEvent.id
            ? defaultFormValue.event.masterEvent.id
            : null
        );
        formEvent.setFieldValue("eventName", defaultFormValue.event.name);
        formEvent.setFieldValue("startDate", defaultFormValue.event.startDate);
        formEvent.setFieldValue("endDate", defaultFormValue.event.endDate);
        formEvent.setFieldValue(
          "startTime",
          defaultFormValue.event.startTime
            ? dayjs(defaultFormValue.event.startTime, formatTimePicker)
            : undefined
        );
        formEvent.setFieldValue(
          "endTime",
          defaultFormValue.event.startTime
            ? dayjs(defaultFormValue.event.endTime, formatTimePicker)
            : undefined
        );
        await handleFetchMoreEvent(1, 20);
        if (defaultFormValue.event.masterEvent.id) {
          handleChangeEvent(defaultFormValue.event.masterEvent.id);
        }

        if (isMountedRef.current) {
          setMounted(true);
          getStateMount(true);
        }
      }
    };

    fetchData();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const [pageEvent, setPageEvent] = useState(1);
  const [totalPageEvent, setTotalPageEvent] = useState(100);
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [loadingMoreEvent, setLoadingMoreEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [totalElementEvent, setTotalElementEvent] = useState(-1);

  const handleScrollEvent = (event) => {
    event.stopPropagation();

    if (!loadingMoreEvent) {
      handleFetchMoreEvent(pageEvent, 20);
    }
  };

  const handleFetchMoreEvent = async (page, limit) => {
    if (
      pageEvent - 1 <= totalPageEvent &&
      optionsEvent?.length !== totalElementEvent
    ) {
      setLoadingMoreEvent(true);
      try {
        const response = await MasterEventRepo.getListMasterEvent(page, 20);
        let tempOptions = optionsEvent;
        if (response.data?.data?.content?.length) {
          await response.data?.data?.content?.forEach((typeEvent) => {
            tempOptions.push(typeEvent);
          });
          setOptionsEvent(tempOptions);
          setTotalPageEvent(response.data?.data?.totalPages);
          setTotalElementEvent(response.data?.data?.totalElements);
        }
        setPageEvent(page + 1);
        setLoadingMoreEvent(false);
      } catch (error) {
        AlertUtility.ErrorAlert({ title: "Failed Get Data" });
        setLoadingMoreEvent(false);
      }
    }
  };
  const handleChangeEvent = (value) => {
    setSelectedEvent(value);
    let index = optionsEvent.findIndex((val) => val.id === value);
    formEvent.setFieldValue("name", optionsEvent[index]?.name);
    formEvent.setFieldValue("grade", optionsEvent[index]?.grade);
    formEvent.setFieldValue("gc", optionsEvent[index]?.gc);
    formEvent.setFieldValue("xp", optionsEvent[index]?.xp);
    formEvent.setFieldValue("description", optionsEvent[index]?.description);
    setOptionsJourney([optionsEvent[index]?.journey]);
    setOptionsPq(optionsEvent[index]?.preReiqusition);
    let tempId = [];
    optionsEvent[index]?.preReiqusition.map((val) => {
      tempId.push(val.id);
    });
    setListTaskForm(optionsEvent[index]?.masterEventTasks);
    setOptionsCertificate([optionsEvent[index]?.certificate]);
    formEvent.setFieldValue("journey", optionsEvent[index]?.journey?.id);
    formEvent.setFieldValue(
      "certificate",
      optionsEvent[index]?.certificate?.id
    );
    formEvent.setFieldValue("preReiqusition", tempId);
    getDataMasterEvent(0, formEvent);
  };

  const [optionsJourney, setOptionsJourney] = useState([]);
  const [optionsPq, setOptionsPq] = useState([]);
  const [optionsCertificate, setOptionsCertificate] = useState([]);
  const [listTaskForm, setListTaskForm] = useState([]);

  const handleChangeInput = (value) => {
    getDataMasterEvent(0, formEvent);
  };

  const validateEndDate = (rule, value) => {
    const startDate = formEvent.getFieldValue("startDate");
    if (startDate && value && dayjs(value).isBefore(dayjs(startDate))) {
      return Promise.reject("End date should not be before the start date.");
    }
    return Promise.resolve();
  };

  if (!mounted) {
    return (
      <>
        <div className="w-full text-center">
          <p>Please Wait...</p>
        </div>
      </>
    );
  }

  return (
    <>
      {mounted && (
        <div className="w-full text-left">
          <h3 className="font-semibold text-[20px] ">{titleModal}</h3>
          <p>{subTitle}</p>
          <div className="w-full mt-[20px]">
            <Form
              layout="horizontal"
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              form={formEvent}
              colon={false}
              // wrapperCol={{ flex: 1 }}
            >
              <Form.Item
                label="Event"
                name="eventId"
                rules={[
                  {
                    required: true,
                    message: "Please select Event",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  onChange={handleChangeEvent}
                  placeholder="Choose Event"
                  dropdownRender={(menu) => (
                    <div onWheel={handleScrollEvent}>{menu}</div>
                  )}
                  loading={loadingMoreEvent}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  disabled={isDisabled}
                >
                  {optionsEvent.map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="w-full">
                <Form.Item
                  label="Event Name"
                  name="eventName"
                  rules={[
                    {
                      required: true,
                      message: "Please input event name",
                    },
                  ]}
                >
                  <Input
                    disabled={isDisabled}
                    onChange={handleChangeInput}
                    placeholder="Enter name"
                    type="text"
                  />
                </Form.Item>
              </div>
              <div className="flex">
                <div className="w-1/2 md:w-[63%]">
                  <Form.Item
                    label="Date"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select Start Date",
                      },
                    ]}
                  >
                    <Input
                      disabled={isDisabled}
                      onChange={handleChangeInput}
                      placeholder="Start Date"
                      type="date"
                    />
                  </Form.Item>
                </div>
                <div className="ml-2 pt-[5px] form-event">-</div>
                <div className="ml-2 w-1/2 form-event md:w-[37%]">
                  <Form.Item
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select End Date",
                      },
                      {
                        validator: validateEndDate,
                      },
                    ]}
                  >
                    <Input
                      className="w-[full]"
                      disabled={isDisabled}
                      onChange={handleChangeInput}
                      placeholder="End Date"
                      type="date"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2 md:w-[63%]">
                  <Form.Item
                    label="Time"
                    name="startTime"
                    rules={[
                      {
                        required: true,
                        message: "Please select Start Time",
                      },
                    ]}
                  >
                    <TimePicker
                      disabled={isDisabled}
                      className="formTimePicker w-full"
                      onChange={handleChangeInput}
                      placeholder="Pick time"
                      format={formatTimePicker}
                    />
                  </Form.Item>
                </div>
                <div className="ml-[15px] form-event pt-[5px]">-</div>
                <div className="ml-[15px] w-1/2 form-event md:w-[37%]">
                  <Form.Item
                    name="endTime"
                    rules={[
                      {
                        required: true,
                        message: "Please select End Time",
                      },
                    ]}
                  >
                    <TimePicker
                      disabled={isDisabled}
                      className="formTimePicker w-full"
                      onChange={handleChangeInput}
                      placeholder="Pick time"
                      format={formatTimePicker}
                    />
                  </Form.Item>
                </div>
              </div>
              <hr />
              {formType === "add" && (
                <>
                  <div className="mt-[10px]">
                    <Form.Item label="Event Name" name="name">
                      <Input disabled placeholder="Input Name" type="text" />
                    </Form.Item>
                  </div>
                  <div className="flex mt-[10px]">
                    <div className="w-[80%]">
                      <Form.Item label="Passing Grade" name="grade">
                        <Input
                          disabled
                          placeholder="input passing grade"
                          type="number"
                        />
                      </Form.Item>
                    </div>
                    <div className="ml-[20px] pt-[5px] form-event">%</div>
                  </div>
                  <div className="flex">
                    <div className="w-[80%]">
                      <Form.Item label="Coin" name="gc">
                        <Input
                          disabled
                          placeholder="Input coins"
                          type="number"
                        />
                      </Form.Item>
                    </div>
                    <div className="ml-[20px] pt-[5px] form-event">Coins</div>
                  </div>
                  <div className="flex">
                    <div className="w-[80%]">
                      <Form.Item label="XP" name="xp">
                        <Input
                          placeholder="Input xp points"
                          type="number"
                          disabled
                        />
                      </Form.Item>
                    </div>
                    <div className="ml-[20px] pt-[5px] form-event">Points</div>
                  </div>
                  <Form.Item label="journey" name="journey">
                    <Select
                      style={{ width: "100%" }}
                      disabled
                      placeholder="Select event journey"
                    >
                      {optionsJourney?.map((i) => (
                        <Option key={i?.id} value={i?.id}>
                          {i?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Pre-requisition" name="preReiqusition">
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      disabled
                      placeholder="Select Pre-requisition"
                    >
                      {optionsPq?.map((i) => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Certificate" name="certificate">
                    <Select
                      style={{ width: "100%" }}
                      disabled
                      placeholder="Select Certificate"
                    >
                      {optionsCertificate?.map((i) => (
                        <Option key={i?.id} value={i?.id}>
                          {i?.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <TextArea
                      disabled
                      placeholder="Input event description"
                      maxLength={500}
                    />
                  </Form.Item>
                </>
              )}
            </Form>

            {formType === "add" && (
              <>
                <div className="mt-[20px]">
                  <p className="font-semibold text-[16px]">List of Task</p>
                </div>
                <table className="w-full border-collapse border border-[#DDDDDD] ">
                  <thead>
                    <tr>
                      <th className="text-center border border-[#DDDDDD] py-2">
                        No
                      </th>
                      <th className="border border-[#DDDDDD] pl-2 text-left">
                        Task
                      </th>
                      <th className="border border-[#DDDDDD] pl-2 text-left">
                        Type
                      </th>
                      <th className="text-center border border-[#DDDDDD]">
                        Grade
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mounted &&
                      listTaskForm?.map((taskForm, idx) => (
                        <tr key={taskForm.id + "" + idx}>
                          <td className="task-number-list text-center border border-[#DDDDDD] p-2">
                            {idx + 1}
                          </td>
                          <td className="border border-[#DDDDDD] col-task-list p-2">
                            {taskForm.task?.title}
                          </td>
                          <td
                            className="border border-[#DDDDDD] col-task-list p-2"
                            style={{ width: 150 }}
                          >
                            {taskForm.type.name}
                          </td>
                          <td
                            className="border border-[#DDDDDD] col-task-list p-2 text-center"
                            style={{ width: 100 }}
                          >
                            {taskForm.grade}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FormDataMasterEvent;
