import React from "react";
import { Button, Modal } from "antd";

export const ConfirmDialog = ({
  handleConfirm,
  handleCancel,
  show,
  handleShow,
  title = "",
  content = "",
  okText,
  cancelText,
  buttonText,
}) => {
  return (
    <>
      <Button
        className="bg-[#DD0000] text-white hover:text-white w-[100px] py-1 font-bold rounded ms-3"
        onClick={() => {
          Modal.confirm({
            title: title,
            content: content,
            okText: okText, // Custom label for the OK button
            okType: "danger", // Button type for the OK button
            okButtonProps: {
              style: { color: "white", backgroundColor: "#DD0000" },
            },
            onOk() {
              // Handle the deletion logic here
              //console.log("Item deleted!");
              handleConfirm();
            },
            onCancel() {
              // Handle cancellation or do nothing
              //console.log("Deletion canceled");
            },
          });
        }}
      >
        {buttonText}
      </Button>
    </>
  );
};
