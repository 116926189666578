import { useVerificationModal } from "../hooks/useVerificationModal";
import { FileMimetypesExpression } from "../../../../shared/utils/file.utils";
import { useMemo, useState } from "react";
import { useFileUploader } from "../hooks/useFileUploader";

export const EventVerificationUploader = ({ onVerificationUploaded, type }) => {
  const [fileUrl, setFileUrl] = useState("");

  const acceptedFileTypes = useMemo(() => {
    return [
      FileMimetypesExpression.JPEG,
      FileMimetypesExpression.JPG,
      FileMimetypesExpression.PDF,
      FileMimetypesExpression.PNG,
    ];
  }, []);

  const {
    inputRef,
    isAnyFileUploaded,
    isUploading,
    uploadedFile,
    detachFile,
    uploadFile,
    uploadThenSubmitFile,
    submitFile,
  } = useFileUploader({
    acceptedFileTypes: acceptedFileTypes.join(", "),
  });

  const { isCertificateVerification, modal, upload } =
    useVerificationModal(type);

  function onSubmitHandler() {
    const submissionPromise = isAnyFileUploaded
      ? uploadThenSubmitFile()
      : submitFile(fileUrl);

    submissionPromise.then(onVerificationUploaded).catch((error) => {
      alert("An error occured!");
      console.log({ error });
    });
  }

  return (
    <header className="flex flex-col gap-6 px-6 py-2 cursor-auto">
      <div className="flex flex-col items-start gap-1">
        <h2 className="text-black text-xl font-semibold">{modal.title}</h2>
        <p className="text-dark-grey text-xs">{modal.description}</p>
      </div>
      <div className="max-w-[360px] w-full mx-auto">
        <p className="text-secondary text-xs font-medium mb-3">
          Let's, upload your {upload.type}
        </p>
        <div className="relative w-full h-[240px] flex items-center justify-center border-2 border-dashed border-grey">
          <label className="w-full h-full flex items-center justify-center">
            <img alt={upload.image.alt} src={upload.image.src} />
            <input
              ref={inputRef}
              accept={acceptedFileTypes}
              className="hidden"
              type="file"
              onChange={(event) => uploadFile(event.target.files[0])}
            />
          </label>
          {uploadedFile && (
            <span className="absolute left-1/2 bottom-4 -translate-x-1/2 w-full text-center px-4">
              {uploadedFile.name} &nbsp;{" "}
              <button onClick={detachFile}>&times;</button>
            </span>
          )}
        </div>
        {isCertificateVerification && (
          <div className="flex flex-col items-center gap-5 mt-5">
            <p>Or</p>
            <input
              className="w-full py-2 px-5 rounded-full bg-[#F6F6F6] placeholder-dark-grey text-xs"
              placeholder="Paste your certification link here.."
              type="text"
              value={fileUrl}
              onChange={(event) => setFileUrl(event.target.value)}
            />
          </div>
        )}
      </div>
      <footer className="flex justify-center mb-[50px] mt-8">
        <button
          className="min-w-[160px] py-2 rounded-full bg-secondary disabled:opacity-50"
          disabled={
            (!isAnyFileUploaded && !fileUrl.trim().length) || isUploading
          }
          onClick={onSubmitHandler}
        >
          <span className="text-white font-medium">Submit</span>
        </button>
      </footer>
    </header>
  );
};
