import React, { useEffect, useState } from "react";
import {
  Pagination,
  message,
  Card,
  Col,
  Row,
  Modal,
  Button,
  Skeleton,
} from "antd";
import TalentHeader from "../../../components/general/TalentHeader";
import RoleSkillTalent from "../../../repositores/talentRepo/RoleSkillTalentRepo";
import { InboxOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import RoleForm from "./RoleForm";

const { Meta } = Card;

const styleCard = {
  background: "#67a8d991",
  padding: "8px 0",
};

const SkeletonCard = () => (
  <Card style={styleCard} hoverable>
    <Skeleton active title={false} paragraph={{ rows: 2 }} />
  </Card>
);

const RolesPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [contentRoles, setContentRoles] = useState([]);
  const [bouncSearch, setBounchSearch] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [selectData, setSelectData] = useState(undefined);
  const [counter, setCounter] = useState(0);

  const [openModalDelete, setOpenModalDelete] = useState(false);

  const perPage = 20;

  useEffect(() => {
    fetchListRole();
  }, [page, search]);

  const handleSearch = (value) => {
    setLoading(true);
    clearTimeout(bouncSearch);

    setBounchSearch(
      setTimeout(() => {
        if (search === value) {
          setLoading(false);
        }
        if (page > 1) {
          setPage(1);
        }
        setSearch(value);
      }, 2000)
    );
  };

  const handleFormSubmit = (value) => {
    if (isEdit) {
      updateRole({ id: selectData.id, roleCompany: { id: value.roleCompany } });
    } else {
      addRole({ roleCompany: { id: value.roleCompany } });
    }
  };

  const handleDeleteRole = () => {
    setOpenModalDelete(true);
  };

  const handleDeleteConfirm = () => {
    setOpenModalDelete(false);
    deleteRole();
    setIsEdit(false);
    setCounter(counter + 1);
    setSelectData(undefined);
  };

  const handleDeleteCancel = () => {
    setOpenModalDelete(false);
    setIsEdit(true);
    setShowModal(true);
  };

  const handleClickCard = (data) => {
    setSelectData(data);
    setIsEdit(true);
    setShowModal(true);
  };

  const addRole = (body) => {
    setLoading(true);
    RoleSkillTalent.addRoleEmployee(body)
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: resp?.data?.message ? resp?.data?.message : "Success",
          });
          if (page > 1) {
            setPage(1);
          } else {
            fetchListRole();
          }
        } else if (resp?.status === 200 && resp?.data?.status === 404) {
          messageApi.open({
            type: "error",
            content: resp?.data?.message
              ? resp?.data?.message
              : "sorry we're having trouble",
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: "sorry we're having trouble",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : "sorry we're having trouble",
        });
      });
  };

  const deleteRole = () => {
    setLoading(true);
    RoleSkillTalent.deleteRoleEmployee({ id: selectData.id })
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: resp?.data?.message ? resp?.data?.message : "Success",
          });
          if (page > 1) {
            setPage(1);
          } else {
            fetchListRole();
          }
        } else if (resp?.status === 200 && resp?.data?.status === 404) {
          messageApi.open({
            type: "error",
            content: resp?.data?.message
              ? resp?.data?.message
              : "sorry we're having trouble",
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: "sorry we're having trouble",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : "sorry we're having trouble",
        });
      });
  };

  const updateRole = (body) => {
    setLoading(true);
    RoleSkillTalent.updateRoleEmployee(body)
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: resp?.data?.message ? resp?.data?.message : "Success",
          });
          if (page > 1) {
            setPage(1);
          } else {
            fetchListRole();
          }
        } else if (resp?.status === 200 && resp?.data?.status === 404) {
          messageApi.open({
            type: "error",
            content: resp?.data?.message
              ? resp?.data?.message
              : "sorry we're having trouble",
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: "sorry we're having trouble",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : "sorry we're having trouble",
        });
      });
  };

  const fetchListRole = () => {
    setLoading(true);
    RoleSkillTalent.getListRolesEmployee(page - 1, perPage, search)
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.data?.content) {
          setContentRoles(resp.data.data.content);
          setTotalPage(
            resp?.data?.data?.totalElements
              ? resp?.data?.data?.totalElements
              : 1
          );
        } else {
          setTotalPage(1);
          setContentRoles([]);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : "sorry we're having trouble",
        });
        setTotalPage(1);
        setContentRoles([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const skeletonCount = perPage - (contentRoles.length % perPage);

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"Roles"} subDescription={""} />
        <div className="flex justify-end w-full my-4">
          <input
            type="text"
            placeholder="Search Role Name..."
            onChange={(e) => handleSearch(e.target.value)}
            className="border rounded-md w-full md:w-[250px] py-1 px-2"
          />
          <button
            className="bg-[#DD0000] text-white w-[200px] py-1 font-bold rounded ms-3"
            onClick={() => {
              setIsEdit(false);
              setSelectData(undefined);
              setShowModal(true);
            }}
          >
            Add
          </button>
        </div>
        {loading ? (
          <Row gutter={[16, 16]} justify="center">
            {Array.from({ length: skeletonCount }).map((_, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <SkeletonCard />
              </Col>
            ))}
          </Row>
        ) : (
          <>
            <Row gutter={[16, 16]} justify="center">
              {contentRoles?.length > 0 ? (
                contentRoles.map((val) => (
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    key={val.id}
                  >
                    <Card
                      style={styleCard}
                      hoverable
                      onClick={() => handleClickCard(val)}
                    >
                      <Meta
                        title={val.roleCompany?.name}
                        description={val.roleCompany?.description}
                      />
                    </Card>
                  </Col>
                ))
              ) : (
                <Col className="gutter-row" span={24}>
                  <div className="text-center w-full">
                    <div>
                      <InboxOutlined style={{ fontSize: "56px" }} />
                    </div>
                    <div>No Data</div>
                  </div>
                </Col>
              )}
            </Row>

            {totalPage > 1 && (
              <Pagination
                current={page}
                total={totalPage}
                pageSize={perPage}
                showSizeChanger={false}
                onChange={(p) => setPage(p)}
                className="flex justify-center mt-6"
              />
            )}
          </>
        )}
      </div>

      <RoleForm
        key={counter}
        show={showModal}
        isEdit={isEdit}
        data={
          selectData
            ? {
                id: selectData?.roleCompany?.id,
                name: selectData?.roleCompany?.name,
              }
            : undefined
        }
        onClickSubmit={(data) => {
          setCounter(counter + 1);
          handleFormSubmit(data);
        }}
        onCancel={(value) => {
          setIsEdit(false);
          setCounter(counter + 1);
          setShowModal(value);
        }}
        onDelete={() => {
          setIsEdit(false);
          setCounter(counter + 1);
          setShowModal(false);
          handleDeleteRole();
        }}
      />

      <Modal
        open={openModalDelete}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        width={350}
        footer={null}
        closable={false}
        keyboard={false}
      >
        <div className="flex items-center gap-2">
          <ExclamationCircleFilled
            style={{ fontSize: "20px", color: "#faad14" }}
          />
          <p className="text-black text-[16px] font-medium">
            Are you sure delete this Role ?
          </p>
        </div>
        <div className="flex justify-end mt-5 gap-[10px]">
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button onClick={handleDeleteConfirm} type="primary" danger>
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RolesPage;
