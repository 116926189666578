import React from "react";
import "./Input.css";

const Input = ({
  onChange,
  width,
  placeholder,
  sizeText,
  styleInput,
  inputTitle,
  value,
}) => {
  return (
    <div className={styleInput} style={{ width: width, fontSize: sizeText }}>
      <input
        name={inputTitle}
        defaultValue={value}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        style={{ width: "100%" }}
      />
      <span className="underline-animation" />
    </div>
  );
};

export default Input;
