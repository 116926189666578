import React from "react";

const JourneyAvatar = () => {
  return (
    <div className="flex justify-end">
      <div className="flex flex-col items-center md:items-start md:mr-4">
        <div className="talk-bubble tri-right round right-in mb-4 md:mb-0 md:max-w-xs max-w-sm">
          <div className="talktext">
            <p className="text-base md:text-xl font-medium">Hi There!</p>
            <div className="text-xs md:text-base font-normal leading-tight">
              <p>You've made up good progress.</p>
              <p>Keep it up!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0">
        <img
          className="w-40 md:w-56 h-auto md:my-0 my-4"
          src="/assets/images/avatar-talent.png"
          alt="Avatar"
        />
      </div>
    </div>
  );
};

export default JourneyAvatar;
