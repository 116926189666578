import { Fragment, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  LuAlignJustify,
  LuChevronLeft,
  LuChevronRight,
  LuLogOut,
  LuSettings,
  LuX,
} from "react-icons/lu";
import "./Sidebar.css";
import AlertUtility from "../../Alert/Alert";
import RouteName from "../../../helper/routeName";
import DashboardTalentRepo from "../../../repositores/talentRepo/DashboardTalentRepo";
import LogoRepo from "../../../repositores/adminRepo/LogoRepo";
import { TalentHelperContext } from "../TalentContext";
import { stateColor } from "./data";
import RouteTalent from "../../../services/RouteTalent";

const Sidebar = () => {
  const [logoGlobal, setLogoGlobal] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [menuStates, setMenuStates] = useState({});
  const [showMonthSuperviseTL, setShowMonthSuperviseTL] = useState(false);

  const navigate = useNavigate();
  const {
    isActive,
    setProfileId,
    sideBarMenu: menus,
    handleUser,
  } = useContext(TalentHelperContext);

  const toggleSidebar = () => setIsCollapsed((prev) => !prev);
  const toggleDrawer = () => setOpenDrawer((prev) => !prev);
  const toggleSubMenu = (title) => {
    setMenuStates((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  useEffect(() => {
    const userTl = localStorage.getItem("user_tl");
    if (userTl === "true") {
      setShowMonthSuperviseTL(true);
    } else {
      setShowMonthSuperviseTL(false);
    }

    getDataTalent();
    getLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update menus with conditional rendering
  const updatedSideBarMenu = {
    ...menus,
    talentOnboarding: {
      ...menus.talentOnboarding,
      Childs: showMonthSuperviseTL
        ? [
            ...menus.talentOnboarding.Childs,
            {
              title: "1 Month Supervise TL",
              src: "talent1MSuperviseTL",
              route: RouteTalent.talentOneMonthSupervisedTL,
            },
          ]
        : menus.talentOnboarding.Childs.filter(
            (child) => child.src !== "talent1MSuperviseTL"
          ),
    },
  };

  const onClickLogout = () => {
    AlertUtility.confirmAlert({
      text: "You want to logout?",
      confirmText: "Yes, logout!",
      onConfirm: logoutUser,
    });
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate(RouteName.login);
  };

  const getDataTalent = async () => {
    const response = await DashboardTalentRepo.getTalentProfile();
    if (response.data?.data?.roles[0].name === "ROLE_ADMIN") {
      logoutUser();
    }
    setProfileId(response.data.data?.id);
    handleUser(response.data.data);
  };

  const getLogo = async () => {
    const responseLogo = await LogoRepo.getLogoGlobal();
    setLogoGlobal(responseLogo?.data?.data?.content);
  };

  useEffect(() => {
    getDataTalent();
    getLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative">
      {/* Overlay */}
      {openDrawer && (
        <div
          onClick={toggleDrawer}
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
        />
      )}

      {/* Sidebar for Desktop */}
      <div
        className={`${
          isCollapsed ? "w-[80px]" : "w-[250px]"
        } bg-white h-screen py-5 pt-8 relative duration-300 border-r-2 border-r-lightgrey overflow-visible md:block hidden`}
      >
        <div className="flex items-center justify-between px-4">
          <NavLink to={RouteTalent.dashboard}>
            {logoGlobal?.map((item) => (
              <img key={item.id} alt={item.id} src={item.name} />
            ))}
          </NavLink>
          <button
            onClick={toggleSidebar}
            className={`focus:outline-none absolute border-2 bg-white rounded-full right-[-15px] z-10 ${
              isCollapsed ? "top-6" : "top-12"
            }`}
          >
            {isCollapsed ? (
              <LuChevronRight className="text-2xl" />
            ) : (
              <LuChevronLeft className="text-2xl" />
            )}
          </button>
        </div>
        <ul className="pt-6">
          <div className="flex flex-col justify-between h-screen pb-32 overflow-y-auto">
            <div>
              {Object.keys(updatedSideBarMenu).map((Menu, index) => (
                <Fragment key={index}>
                  {updatedSideBarMenu[Menu].hasChild ? (
                    <div
                      className={`relative group ${
                        isCollapsed ? "justify-center" : "ml-4"
                      } flex flex-col font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30`}
                    >
                      <div
                        className={`${
                          isCollapsed ? "flex-col text-center" : "flex-row"
                        } text-sm flex items-center gap-x-1 mb-1`}
                        onClick={() =>
                          toggleSubMenu(updatedSideBarMenu[Menu].title)
                        }
                      >
                        <div className="h-5 w-5 flex">
                          {updatedSideBarMenu[Menu]?.icon(
                            "w-[20px] h-[20px]",
                            isActive.toLowerCase() ===
                              updatedSideBarMenu[Menu].title?.toLowerCase()
                              ? stateColor.active
                              : stateColor.inactive
                          )}
                        </div>
                        <span
                          className={`${
                            isCollapsed
                              ? "text-[10px] text-center mt-1"
                              : "origin-left duration-200 ml-2"
                          }`}
                        >
                          {updatedSideBarMenu[Menu].title}
                        </span>
                        <div
                          className={`${
                            menuStates[updatedSideBarMenu[Menu].title]
                              ? "transition-width duration-300 ease rotate-180"
                              : "transition-width duration-300 ease rotate-0"
                          } ${isCollapsed ? "hidden" : "ml-auto"}`}
                        >
                          <img
                            src="/assets/icons/arrow-down.svg"
                            alt={updatedSideBarMenu[Menu].title}
                          />
                        </div>
                      </div>
                      {isCollapsed && (
                        <div
                          className="fixed top-0 left-[80px] hidden group-hover:block bg-white shadow-lg z-20 min-w-[150px]"
                          style={{ top: `calc(${index * 70}px + 10px)` }}
                        >
                          {updatedSideBarMenu[Menu].Childs?.map((arr, indx) => (
                            <NavLink
                              key={`${index}-${indx}`}
                              to={arr.route}
                              className="text-sm block py-2 px-4 hover:bg-lightgrey"
                            >
                              {arr.title}
                            </NavLink>
                          ))}
                        </div>
                      )}
                      {isCollapsed && (
                        <LuChevronRight className="absolute right-0 top-3 text-gray-500" />
                      )}
                      {!isCollapsed &&
                        menuStates[updatedSideBarMenu[Menu].title] && (
                          <>
                            {updatedSideBarMenu[Menu].Childs?.map(
                              (arr, indx) => (
                                <NavLink
                                  key={`${index}-${indx}`}
                                  to={arr.route}
                                  className="transition-opacity ease-in duration-700 opacity-100 border-l-2 ml-2 pl-4 pt-2"
                                >
                                  <div className="text-[12px]">{arr.title}</div>
                                </NavLink>
                              )
                            )}
                          </>
                        )}
                    </div>
                  ) : (
                    <NavLink
                      to={updatedSideBarMenu[Menu].route}
                      className={`${
                        isCollapsed ? "justify-center items-center" : "ml-4"
                      } font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex flex-col`}
                    >
                      <div
                        className={`${
                          isCollapsed
                            ? "text-center"
                            : "flex items-center gap-x-1"
                        }`}
                      >
                        <div className="h-5 w-5">
                          {updatedSideBarMenu[Menu]?.icon(
                            "w-[20px] h-[20px]",
                            isActive.toLowerCase() ===
                              updatedSideBarMenu[Menu].title?.toLowerCase()
                              ? stateColor.active
                              : stateColor.inactive
                          )}
                        </div>
                        {!isCollapsed && (
                          <span className="origin-left duration-200 ml-2">
                            {updatedSideBarMenu[Menu].title}
                          </span>
                        )}
                      </div>
                      {isCollapsed && (
                        <span className="text-[10px] text-center mt-1">
                          {updatedSideBarMenu[Menu].title}
                        </span>
                      )}
                    </NavLink>
                  )}
                </Fragment>
              ))}
            </div>
            {/* Logout and Settings */}
            <div>
              <div
                className={`${
                  isCollapsed ? "ml-[8px]" : "ml-4"
                } font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex`}
              >
                <div
                  className={`${
                    isCollapsed ? "flex-col text-center" : "flex-row"
                  } text-sm flex items-center gap-x-1 mb-1`}
                >
                  <div className="h-5 w-5">
                    <LuSettings size={22} />
                  </div>
                  <span
                    className={`${
                      isCollapsed
                        ? "text-[10px] text-center mt-1"
                        : "origin-left duration-200 ml-2"
                    }`}
                  >
                    Settings
                  </span>
                </div>
              </div>

              <div
                onClick={onClickLogout}
                className={`${
                  isCollapsed ? "ml-[8px]" : "ml-4"
                } font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex`}
              >
                <div
                  className={`${
                    isCollapsed ? "flex-col text-center" : "flex-row"
                  } text-sm flex items-center gap-x-1 mb-1`}
                >
                  <div className="h-5 w-5">
                    <LuLogOut size={22} />
                  </div>
                  <span
                    className={`${
                      isCollapsed
                        ? "text-[10px] text-center mt-1"
                        : "origin-left duration-200 ml-2"
                    }`}
                  >
                    Log Out
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>

      {/* Mobile Drawer */}
      <div
        className={`fixed top-0 left-0 h-full bg-white shadow-lg z-50 transform transition-transform ${
          openDrawer ? "translate-x-0" : "-translate-x-full"
        } md:hidden`}
      >
        <div className="relative w-64 h-full p-4">
          <div className="items-center justify-between">
            <NavLink to={RouteTalent.dashboard}>
              {logoGlobal?.map((item) => (
                <img key={item.id} alt={item.id} src={item.name} />
              ))}
            </NavLink>
          </div>
          <ul className="pt-6">
            <div className="flex flex-col justify-between h-full overflow-y-auto">
              <div>
                {Object.keys(updatedSideBarMenu).map((Menu, index) => (
                  <Fragment key={index}>
                    {updatedSideBarMenu[Menu].hasChild ? (
                      <div className="relative group ml-4 flex flex-col font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30">
                        <div
                          className="flex-row text-sm flex items-center gap-x-1 mb-1"
                          onClick={() =>
                            toggleSubMenu(updatedSideBarMenu[Menu].title)
                          }
                        >
                          <div className="h-5 w-5 flex">
                            {updatedSideBarMenu[Menu]?.icon(
                              "w-[20px] h-[20px]",
                              isActive.toLowerCase() ===
                                updatedSideBarMenu[Menu].title?.toLowerCase()
                                ? stateColor.active
                                : stateColor.inactive
                            )}
                          </div>
                          <span className={"origin-left duration-200 ml-2"}>
                            {updatedSideBarMenu[Menu].title}
                          </span>
                          <div
                            className={`${
                              menuStates[updatedSideBarMenu[Menu].title]
                                ? "transition-width duration-300 ease rotate-180"
                                : "transition-width duration-300 ease rotate-0"
                            } "ml-auto"`}
                          >
                            <img
                              src="/assets/icons/arrow-down.svg"
                              alt={updatedSideBarMenu[Menu].title}
                            />
                          </div>
                        </div>
                        {menuStates[updatedSideBarMenu[Menu].title] && (
                          <>
                            {updatedSideBarMenu[Menu].Childs?.map(
                              (arr, indx) => (
                                <NavLink
                                  key={`${index}-${indx}`}
                                  to={arr.route}
                                  className="transition-opacity ease-in duration-700 opacity-100 border-l-2 ml-2 pl-4 pt-2"
                                >
                                  <div className="text-[12px]">{arr.title}</div>
                                </NavLink>
                              )
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        to={updatedSideBarMenu[Menu].route}
                        className="ml-4 font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex flex-col"
                      >
                        <div className="flex items-center gap-x-1">
                          <div className="h-5 w-5">
                            {updatedSideBarMenu[Menu]?.icon(
                              "w-[20px] h-[20px]",
                              isActive.toLowerCase() ===
                                updatedSideBarMenu[Menu].title?.toLowerCase()
                                ? stateColor.active
                                : stateColor.inactive
                            )}
                          </div>
                          <span className="origin-left duration-200 ml-2">
                            {updatedSideBarMenu[Menu].title}
                          </span>
                        </div>
                      </NavLink>
                    )}
                  </Fragment>
                ))}
              </div>

              {/* Logout and Settings */}
              <div>
                <div className="ml-4 font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex">
                  <div className="flex-row text-sm flex items-center gap-x-1 mb-1">
                    <div className="h-5 w-5">
                      <LuSettings size={22} />
                    </div>
                    <span className="origin-left duration-200 ml-2">
                      Settings
                    </span>
                  </div>
                </div>

                <div
                  onClick={onClickLogout}
                  className="ml-4 font-normal my-1 text-black p-2 cursor-pointer hover:bg-white hover:bg-opacity-30 flex"
                >
                  <div className="flex-row text-sm flex items-center gap-x-1 mb-1">
                    <div className="h-5 w-5">
                      <LuLogOut size={22} />
                    </div>
                    <span className="origin-left duration-200 ml-2">
                      Log Out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <button
        onClick={toggleDrawer}
        className="md:hidden fixed top-2 right-4 text-gray-600 bg-transparent shadow-lg rounded-full p-2 z-50"
      >
        {openDrawer ? <LuX size={30} /> : <LuAlignJustify size={30} />}
      </button>
    </div>
  );
};

export default Sidebar;
