import React, { useEffect, useState } from "react";
import { Button, Pagination, Skeleton, Table, message } from "antd";
import PlacementTalentHistoryRepo from "../../../repositores/talentRepo/PlacementTalentHistoryRepo";
import TalentHeader from "../../../components/general/TalentHeader";
import moment from "moment";
import PlacementTalentHistoryForm from "./PlacementTalentHistoryForm";

const SkeletonTable = ({ rows = 5, columns = 6 }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <th
                key={colIndex}
                className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <Skeleton.Input active size="small" className="w-24" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} className="px-4 py-4 whitespace-nowrap">
                  <Skeleton.Input active size="default" className="w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PlacementTalentHistory = () => {
  const [searchClient, setSearchClient] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [bouncSearch, setBounchSearch] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [selectData, setSelectData] = useState(undefined);
  const [counter, setCounter] = useState(0);

  const perPage = 10;

  const fetchListPlacementTalentHistory = async () => {
    setLoading(true);
    await PlacementTalentHistoryRepo.getPlacementTestTalent(
      page - 1,
      perPage,
      searchClient
    )
      .then((response) => {
        if (response?.status === 200 && response?.data?.data?.content) {
          setDataTable(response.data.data.content);
          setTotalPage(
            response?.data?.data?.totalElements &&
              response?.data?.data?.totalElements > 0
              ? response?.data?.data?.totalElements
              : 1
          );
        } else {
          setTotalPage(1);
          setDataTable([]);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : `sorry we're having trouble`,
        });
        setTotalPage(1);
        setDataTable([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePlacementHistory = (body) => {
    setLoading(true);
    PlacementTalentHistoryRepo.updatePlacementTestHistory(body)
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: resp?.data?.message ? resp?.data?.message : `Success`,
          });
          if (page > 1) {
            setPage(1);
          } else {
            fetchListPlacementTalentHistory();
          }
        } else if (resp?.status === 200 && resp?.data?.status === 404) {
          messageApi.open({
            type: "error",
            content: resp?.data?.message
              ? resp?.data?.message
              : `sorry we're having trouble`,
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: `sorry we're having trouble`,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : `sorry we're having trouble`,
        });
      });
  };

  const handleSearchClient = (value) => {
    setLoading(true);
    clearTimeout(bouncSearch);

    setBounchSearch(
      setTimeout(() => {
        if (searchClient === value) {
          setLoading(false);
        }
        if (page > 1) {
          setPage(1);
        }
        setSearchClient(value);
      }, 2000)
    );
  };

  const handleFormSubmit = (value) => {
    if (isEdit) {
      let body = {
        feedbackFromTalent: value.feedbackFromTalent,
        id: selectData.id,
        user: {
          id: selectData.user.id,
        },
      };
      updatePlacementHistory(body);
    }
  };

  const handleEdit = (value) => {
    console.log("value ", value);
    setSelectData(value);
    setIsEdit(true);
    setShowModal(true);
  };

  useEffect(() => {
    fetchListPlacementTalentHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchClient]);

  const columns = [
    {
      title: "Date Test",
      dataIndex: "testDate",
      key: "testDate",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? moment(v).format("DD-MMM-YYYY") : "-"}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "id",
      align: "center",
      render: (v, row) => {
        const styleData = statusOptions.find(
          (item) => item.value === row.status
        );
        return (
          <div className="flex justify-center w-full">
            <div
              className={`w-[100px] px-1 justify-center text-center rounded ${styleData?.bgColor} ${styleData?.color}`}
            >
              {v !== null ? v : "-"}
            </div>
          </div>
        );
      },
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Feedback from PIC",
      dataIndex: "feedbackFromPIC",
      key: "feedbackFromPIC",
      align: "center",
      width: 200,
      render: (_, record) => {
        return (
          <p>
            {record !== null ? (
              <div className="remark-cell">{record.feedbackFromPIC}</div>
            ) : (
              "-"
            )}
          </p>
        );
      },
    },
    {
      title: "Remark by Talent",
      dataIndex: "feedbackFromTalent",
      key: "feedbackFromTalent",
      align: "center",
      width: 200,
      render: (_, record) => {
        return (
          <p>
            {record !== null ? (
              <div className="remark-cell">{record.feedbackFromTalent}</div>
            ) : (
              "-"
            )}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div>
          <Button
            danger
            className="mr-1"
            onClick={() => {
              handleEdit(record);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const statusOptions = [
    {
      value: "PASSED",
      label: "PASSED",
      color: "text-[#3b69ae]",
      bgColor: "bg-[#c4dafc]",
    },
    {
      value: "FAILED",
      label: "FAILED",
      color: "text-[#43ae3b]",
      bgColor: "bg-[#c4fccb]",
    },
    // {
    //   value: "INPROGRESS",
    //   label: "INPROGRESS",
    //   color: "text-[#ae843b]",
    //   bgColor: "bg-[#fce7c4]",
    // },
    // {
    //   value: "REJECT",
    //   label: "REJECT",
    //   color: "text-[#ae3b3b]",
    //   bgColor: "bg-[#fcc4c4]",
    // },
    // {
    //   value: "NEXT_SPRINT",
    //   label: "NEXT_SPRINT",
    //   color: "text-[#ae3b3b]",
    //   bgColor: "bg-[#fcc4c4]",
    // },
  ];

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"Placement Test History"} />
        <div className="flex justify-end w-full my-4">
          <input
            type="text"
            placeholder="Search Client"
            onChange={(e) => handleSearchClient(e.target.value)}
            className="border rounded-md w-full md:w-[250px] py-1 px-2"
          />
        </div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={columns}
                dataSource={dataTable?.map((item) => ({
                  ...item,
                  key: item.id,
                }))}
                pagination={false}
                scroll={{
                  y: 470,
                  x: 900,
                }}
              />
            </div>
            <Pagination
              current={page}
              total={totalPage}
              pageSize={perPage}
              showSizeChanger={false}
              onChange={(p) => setPage(p)}
              className="flex justify-center mt-2"
            />
          </>
        )}
      </div>
      <PlacementTalentHistoryForm
        isEdit={isEdit}
        key={counter}
        show={showModal}
        data={selectData}
        onClickSubmit={(data) => {
          setCounter(counter + 1);
          handleFormSubmit(data);
        }}
        onCancel={(value) => {
          setIsEdit(false);
          setCounter(counter + 1);
          setShowModal(value);
          setSelectData(undefined);
        }}
      />
    </>
  );
};

export default PlacementTalentHistory;
