import { Form, message, Pagination, Modal, Input, Button, Radio, Select, Table, Tag, Card, Flex, Typography, Space } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleMasterAdmin from "../../componets_global/TitleMaster";
import deleteIcon from "../../../../assets/icons/delete.png";
import { SearchOutlined } from "@ant-design/icons";
import { Editor } from '@tinymce/tinymce-react';
const { Title } = Typography;
const { Option } = Select;

const AdminQuizDetail = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [formCategory] = Form.useForm();
    const [titleModal, setTitleModal] = useState("Add Category");
    const [formType, setFormType] = useState("add");
    const [detailCategory, setDetailCategory] = useState(undefined);
    const [valueCategoryType, setValueCategoryType] = useState(1);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const editorRef = useRef(null);

    const dummyData = [
        {
            id: 1,
            key: 1,
            name: "John Doe",
            score: 80,
            duration: '01:30:00',
            date: 'Mon 21 Oct 2024'
        },
        {
            id: 2,
            key: 2,
            name: "Jim Green",
            score: 80,
            duration: '01:30:00',
            date: 'Mon 21 Oct 2024'
        },
        {
            id: 3,
            key: 3,
            name: "Edward",
            score: 80,
            duration: '01:30:00',
            date: 'Mon 21 Oct 2024'
        }
    ]

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_, record) => {
                return (
                    <>
                        <Space size="middle">
                            <a>Answers</a>
                        </Space>
                    </>
                )
            }
        }
    ]


    const closeModal = (modal = "form") => {
        setIsOpenModal(false)
    };
    const showAddModal = (id) => {
    };

    const onChangeType = (el) => {
        setValueCategoryType(el.target.value);
    };



    const onChangePage = (el) => {

    };

    const handleScrollEventAccount = (event) => {

    };

    useEffect(async () => {

    }, []);

    return (
        <>
            {contextHolder}
            <div className="w-full h-screen px-4 py-5">
                <TitleMasterAdmin>Javascript Basic</TitleMasterAdmin>

                <div className="mt-[20px] w-full">
                    <Card className="h-[150px] w-[20%]">
                        <div className="pt-[5px]">
                            Avarage
                        </div>
                        <Title level={2}>75</Title>
                    </Card>
                </div>

                <div className="mt-[20px] w-full">
                    <Table columns={columns} dataSource={dummyData} pagination={false}></Table>
                </div>


                <div className="pb-[30px] flex gap-4">
                    <div className="bg-white flex w-[100%] border-2 h-[50px] mt-10 rounded-[10px] justify-center items-center cursor-pointer">
                        <span className="ml-2 text-sm"> Back</span>
                    </div>
                </div>

            </div>

        </>
    );
};

const ActionBarSearchOnly = ({
    buttonTitle,
    actionButton,
    onSearch,
    placeholder,
}) => {
    return (
        <Input
            className="w-full lg:w-[200px] max-w-[400px]"
            placeholder={placeholder}
            suffix={<img src="/assets/icons/search-icon.svg" alt="" />}
            onChange={onSearch}
        />
    );
};

const ActionBarButtonOnly = ({ buttonTitle, actionButton }) => {
    return (
        <div className=" flex justify-center">
            {actionButton && (
                <div
                    onClick={actionButton}
                    className="bg-red-600  w-[150px] h-[30px] text-white text-sm flex justify-center items-center rounded-[7px] cursor-pointer"
                >
                    {buttonTitle}
                </div>
            )}
        </div>
    );
};

export default AdminQuizDetail;
