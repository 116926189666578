export const clientTableColumn = (handleGetId) => {
  return [
    {
      title: "No.",
      dataIndex: "",
      key: "id",
      render: (v, row, i) => {
        return i + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div className="flex flex-row">
          <button
            className="bg-white flex border-2 border-red-600 text-red-600 w-[60px] justify-center rounded-[7px] cursor-ponter"
            onClick={(event) =>
              event.preventDefault(handleGetId("edit", data.id))
            }
          >
            Edit
          </button>
          <img
            src="/assets/icons/delete.svg"
            alt=""
            className="ml-4 cursor-pointer"
            onClick={() => handleGetId("delete", data.id)}
          />
        </div>
      ),
    },
  ];
};
