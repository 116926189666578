import CallApi from "../../services/Request-Helper";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const loginUser = async ({ email, password }) => {
  const url = `${API_BASE}/login-user`;

  const data = { email, password };
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const loginWithGoogle = async ({ accessToken }) => {
  const url = `${API_BASE}/login-user/signin_google`;

  const data = { accessToken };
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const forgotPassword = async ({ email, urlFe }) => {
  const url = `${API_BASE}/forget-password/send`;

  const data = { email, urlFe };
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const changePassword = async ({ email, newPassword, confirmNewPassword }) => {
  const url = `${API_BASE}/forget-password/change-password`;

  const data = { email, newPassword, confirmNewPassword };
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const attendanceVerification = async ({ email, password, eventId }) => {
  const url = `${API_BASE}/event-participant-task/attandance/events/${eventId}`;

  const data = { email, password };
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const attendanceVerificationNew = async ({ eventId, accessToken }) => {
  const url = `${API_BASE}/event-participant-task/attandance/events/${eventId}/${accessToken}`;

  const response = await CallApi({ url, method: "POST" });
  return response;
};

const AuthRepo = {
  loginUser,
  loginWithGoogle,
  forgotPassword,
  changePassword,
  attendanceVerification,
  attendanceVerificationNew,
};

export default AuthRepo;
