import React from "react";

const index = ({
  className = "",
  color = "#4C4C4C",
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6647_13291)">
        <path
          d="M11.8721 7.65505C11.8011 7.45441 11.6917 7.27196 11.5526 7.11659C11.2568 6.78611 10.827 6.57812 10.3486 6.57812H9.0982C8.30184 6.57812 7.65625 7.2237 7.65625 8.02008C7.65625 8.69771 8.12809 9.28392 8.79007 9.42872L10.6938 9.84516C11.4354 10.0074 11.964 10.6646 11.964 11.4237C11.964 12.3159 11.2407 13.0397 10.3486 13.0397H9.27165C8.5683 13.0397 7.96994 12.5902 7.74817 11.9628"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.80859 6.57878V4.96338"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.80859 14.6554V13.04"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.07422 18.9982V15.5366H6.53576"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.8447 8.34375C19.706 12.924 16.9031 17.4648 12.3269 18.6909C8.82444 19.6294 5.25075 18.367 3.07422 15.7515"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.15506 11.6561C0.293767 7.07588 3.09667 2.53508 7.67287 1.3089C11.1751 0.370484 14.7486 1.63266 16.9252 4.24783"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9264 1.00244V4.46398H13.4648"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6647_13291">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default index;
