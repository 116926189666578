import React from "react";

const index = ({ className, color }) => {
  return (
    <svg
      width="20"
      height="20"
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2336_5869)">
        <path
          d="M10 16.6328H19.2857"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6326 13.9795L19.2857 16.6326L16.6326 19.2856"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.68434 4.69436C7.78338 4.69436 8.67434 3.8034 8.67434 2.70436C8.67434 1.60531 7.78338 0.714355 6.68434 0.714355C5.58529 0.714355 4.69434 1.60531 4.69434 2.70436C4.69434 3.8034 5.58529 4.69436 6.68434 4.69436Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.99 11.3272C13.089 11.3272 13.98 10.4362 13.98 9.33717C13.98 8.23812 13.089 7.34717 11.99 7.34717C10.891 7.34717 10 8.23812 10 9.33717C10 10.4362 10.891 11.3272 11.99 11.3272Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.01002 18.6231C9.10907 18.6231 10 17.7321 10 16.6331C10 15.534 9.10907 14.6431 8.01002 14.6431C6.91097 14.6431 6.02002 15.534 6.02002 16.6331C6.02002 17.7321 6.91097 18.6231 8.01002 18.6231Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0001 9.3374H4.36233C2.34761 9.3374 0.714355 10.9707 0.714355 12.9854C0.714355 15.0001 2.34761 16.6334 4.36233 16.6334H6.0205"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9797 9.33726H15.9696C17.8011 9.33726 19.2859 7.85249 19.2859 6.02092C19.2859 4.18936 17.8011 2.70459 15.9696 2.70459H8.67358"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.69397 2.70459H0.714355"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2336_5869">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default index;
