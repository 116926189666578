import { Button, Modal, Spin } from "antd";
import React, { useState } from "react";
import AlertUtility from "../../../../components/Alert/Alert";
import AdminLmsRepo from "../../../../repositores/adminRepo/LmsRepo";
import { saveAs } from "file-saver";

const ModalImportCourse = ({ show, closeModal, type }) => {
  const fileType = [".csv", ".xls", ".xlsx"];
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState(null);

  const onChangeFile = (input) => {
    const value = input.target.value;
    const selectedFile = input.target.files[0];
    const type = value.substring(value.lastIndexOf("."));
    if (!fileType.includes(type)) {
      deleteFile()
    } else {
      setFileData(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const deleteFile = () => {
    setFileData(null);
    setFileName("");
  }

  const uploadDocument = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
      formData.append("file", fileData);
      if(type === 'pg') {
        formData.append("sheetNumber", 0);
      } else {
        formData.append("sheetNo", 0);
      }
      const { data } = await AdminLmsRepo.injectCourse(formData, type)
      if (data.status == 200) {
        AlertUtility.SuccessAlert({title: "Import data succesfully"})
        closeModal(false)
      } else {
        throw data.message
      }
    } catch (error) {
        if(typeof error === 'undefined' ) {
            AlertUtility.ErrorAlert({title: 'Import data failed, please try again later!'})
        } else {
            AlertUtility.ErrorAlert({title: error})
        }
    } finally {
      setLoading(false)
      deleteFile()
    }
  }

  const downloadExcelTemplate = async () => {
    try {
        setLoading(true)
        const filePath = type === 'course' ? process.env.PUBLIC_URL + "/assets/Template-LMS-Upload-Data-Course.xlsx" : process.env.PUBLIC_URL + "/assets/Template-LMS-Upload-Data-PG.xlsx";
        saveAs(filePath, type === 'course' ? "Template-LMS-Upload-Data-Course.xlsx" : "Template-LMS-Upload-Data-PG.xlsx");
    } catch (error) {
      AlertUtility.ErrorAlert({title: error.message})
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={show} onCancel={() => closeModal(false)} footer={null}>
      {
        loading ?
        <div className="flex items-center justify-center py-8">
          <Spin />
        </div>  :
        <>
          <div className="mb-8 font-medium">
            <div className="text-xl text-[#4C4C4C]">Import Data {type === 'course' ? 'Course' : 'Multiple Choice'}</div>
            <div className="text-xs text-[#A8A8A8] mb-2">
              You can get the template & import here!
            </div>
            <div 
              className="text-[10px] text-[#1d6f42] bg-white rounded w-1/5 text-center flex items-center justify-center cursor-pointer border-[#1d6f42] border-[1px] p-1"
              onClick={() => downloadExcelTemplate()}
            >
              <img src="/assets/icons/excel.svg" alt="download" width={10} className="me-1" /> 
              Download
            </div>
          </div>
          <div className="p-5 rounded border-[1px] border-solid border-[#DDDDDD]">
            <div className="text-[#000]">Let's import data</div>
            <div className="text-[#A8A8A8] text-xs mb-4">
              Make sure you have used the template provided above
            </div>
            <div className="max-w-xl mb-3">
              <label className="flex justify-center items-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <span className="flex flex-col items-center">
                  <img src="/assets/icons/upload.svg" alt="upload" width="35px" />
                  <span className="font-medium text-gray-600">
                    Click here!
                  </span>
                </span>
                <input
                  type="file"
                  name="file_upload"
                  className="hidden"
                  accept={fileType.join(",")}
                  onChange={(e) => onChangeFile(e)}
                />
              </label>
            </div>
            {
              fileName != "" ? 
                <div className="flex items-center mb-2"> 
                  <div className="me-4">{ fileName }</div>
                  <div 
                    className="text-red-500 text-xs cursor-pointer hover:font-semibold"
                    onClick={() => deleteFile()}
                  >
                    Delete
                  </div>
                </div> :
                <></>
            }
            <div className="flex justify-end">
              <Button
                className="bg-[#DD0000] text-white font-semibold"
                disabled={fileName === ""}
                onClick={() => uploadDocument()}
              >Submit</Button>
            </div>
          </div>
        </>
      }
    </Modal>
  );
};

export default ModalImportCourse;
