import React from "react";
import { useLocation } from "react-router-dom";

const AttendanceRecorded = () => {
  const location = useLocation();

  return (
    <>
      <div className="flex justify-center">
        {location.state?.map((item) => (
          <img key={item.id} alt={item.id} src={item.name} className="mb-8" />
        ))}
      </div>
      <div className="flex justify-center py-10">
        <img
          src={require("../../assets/images/att-rec-img.svg").default}
          alt="telkom-logo"
        />
      </div>
      <div className="text-xl md:text-2xl font-bold text-[#47B357] mb-2 att-rec-title-color">
        Awesome! you’re recorded!{" "}
      </div>
      <div className="text-xs md:text-base mb-6 att-rec-desc-color">
        Thank you for joining us at the event, we look forward to seeing you
        again soon!{" "}
      </div>
    </>
  );
};

export default AttendanceRecorded;
