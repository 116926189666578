import React, { useEffect, useState } from "react";
import HistoryClientRepo from "../../../repositores/adminRepo/HistoryClientRepo";
import {
  clientHistoryColumns,
  clientHistoryDetailColumns,
} from "./column.state";
import { Form, Pagination, Table, message } from "antd";
import AlertUtility from "../../../components/Alert/Alert";
import dayjs from "dayjs";
import { ViewStatusTalent } from "./ViewStatusTalent";
import TitleMasterAdmin from "../componets_global/TitleMaster";

const StatusTalent = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [projectPage, setProjectPage] = useState(0);
  const [search, setSearch] = useState("");
  const [searchTalent, setSearchTalent] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  const [clientData, setClientData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [editingForm, setEditingForm] = useState({
    name: "",
    description: "",
    status: "NEW",
    startDate: new Date(),
    endDate: new Date(),
    historyClientTalent: {
      id: "",
    },
    clientCompany: {
      id: "",
    },
  });
  const [projectTitle, setProjectTitle] = useState({
    clientName: "",
    roleName: "",
  });
  const [clientHistoryId, setClientHistoryId] = useState("");
  const [editingId, setEditingId] = useState("");
  const [projectTotalData, setProjectTotalData] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  useEffect(() => {
    if (!showModal) {
      handleResetForm();
      setIsEditing(false);
      setShowForm(false);
    }
  }, [showModal]);

  useEffect(() => {
    if (!isEditing) {
      handleResetForm();
    } else {
      const data = projectData.find((item) => item.id === editingId);
      if (data) {
        setEditingForm((prevForm) => ({
          ...prevForm,
          id: data.id,
          name: data.name,
          description: data.description,
          status: data.status,
          startDate: dayjs(data.startDate),
          endDate: dayjs(data.endDate),
        }));
        setShowForm(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (!showForm) setIsEditing(false);
  }, [showForm]);

  useEffect(() => {
    getHistory(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTalent, search, page]);

  useEffect(() => {
    if (clientHistoryId === "") return;
    getProjectHistory(projectPage, clientHistoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientHistoryId]);

  useEffect(() => {
    getProjectHistory(projectPage, clientHistoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSearch]);

  const handleInputChange = (name, value) => {
    // Assuming you have an input field with a name attribute
    handleFormUpdate({ [name]: value });
  };

  const handleFormUpdate = (updatedValues) => {
    setEditingForm((prevForm) => ({
      ...prevForm,
      ...updatedValues,
    }));
  };

  const handleResetForm = () => {
    setEditingForm(() => ({
      name: "",
      description: "",
      status: "NEW",
      startDate: new Date(),
      endDate: new Date(),
    }));
  };

  const changePagination = (pageNumber) => {
    getHistory(pageNumber - 1);
  };

  const changeHistoryPagination = (pageNumber) => {
    getProjectHistory(pageNumber - 1, clientHistoryId);
  };

  const getHistory = async (pageNumber = 0) => {
    setLoading(true);
    const pageSize = 10;
    await HistoryClientRepo.getHistoryClientTalent(
      pageNumber,
      pageSize,
      searchTalent,
      search
    )
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setClientData(result.data.data.content);
        setPage(result.data.data.number);
        setTotalData(result.data.data.totalElements);
      })
      .catch((error) => {
        setClientData([]);
        setPage(0);
        //setDataUser(0);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProjectHistory = async (pageNumber = 0, id) => {
    if (!id || id === "") return;
    setLoading(true);
    const pageSize = 10;
    await HistoryClientRepo.getDetailHistoryClientTalent(
      pageNumber,
      pageSize,
      projectSearch,
      id
    )
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        setProjectData(result?.data?.data?.content);
        setProjectPage(result.data.data.number);
        setProjectTotalData(result.data.data.totalElements);
        setShowForm(false);
        handleResetForm();
      })
      .catch((error) => {
        setProjectData([]);
        setProjectPage(0);
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAdd = async (form) => {
    if (!clientHistoryId || clientHistoryId === "") return;
    const entity = {
      ...editingForm,
      ...form,
      historyClientTalent: {
        id: clientHistoryId,
      },
      /*       clientCompany: {
        id: companyId,
      }, */
    };
    setLoading(true);
    setUpdateSuccess(false);
    await HistoryClientRepo.addHistoryClientTalent(entity)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        handleResetForm();
        setEditingForm({});
        setEditingId("");
        setShowForm(false);
        getProjectHistory(projectPage, clientHistoryId);
        setUpdateSuccess(() => {
          return true;
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleEdit = async (form) => {
    if (!clientHistoryId || clientHistoryId === "") return;
    const entity = {
      ...editingForm,
      ...form,
      historyClientTalent: {
        id: clientHistoryId,
      },
    };
    setLoading(true);
    setUpdateSuccess(false);

    await HistoryClientRepo.editHistoryClientTalent(entity)
      .then((result) => {
        if (result.data.status !== 200) {
          messageApi.open({
            type: "error",
            content:
              result.data.status !== 500
                ? result.data.message
                : result.data.error,
          });
          return;
        }
        getProjectHistory(projectPage, clientHistoryId);
        handleResetForm();
        setEditingForm(false);
        setEditingId("");
        setShowForm(false);
        setUpdateSuccess(true);
      })
      .catch((error) => {
        AlertUtility.ErrorAlert({ title: error.response?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TitleMasterAdmin>Status Talent</TitleMasterAdmin>
        <div className="flex flex-col justify-end lg:flex-row gap-4">
          <div className="flex flex-row gap-4 lg:mb-0">
            <input
              type="text"
              placeholder="Search Status"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="border rounded-md w-full py-1 px-2"
            />
            <input
              type="text"
              placeholder="Search Talent Name..."
              value={searchTalent}
              onChange={(e) => setSearchTalent(e.target.value)}
              className="border rounded-md w-full py-1 px-2"
            />
          </div>
          {/* <button
                className="bg-[#DD0000] text-white w-[200px] py-1 font-bold rounded ms-3"
                onClick={() => {}}
              >
                Add
              </button> */}
        </div>
        {
          /* loading ? (
              <SkeletonTable />
            ) : */ <>
            <div className="mt-[10px] w-full">
              <Table
                columns={clientHistoryColumns((id) => {
                  setClientHistoryId(id);
                  setShowModal(true);
                }, setProjectTitle)}
                dataSource={clientData}
                pagination={false}
                sticky={true}
                rowKey={"id"}
                scroll={{
                  x: 1500,
                  y: 520,
                }}
              />
            </div>
            <ViewStatusTalent
              form={form}
              updateSucess={updateSuccess}
              setUpdateSuccess={setUpdateSuccess}
              showForm={showForm}
              setShowForm={setShowForm}
              clientName={projectTitle?.clientName}
              roleName={projectTitle?.roleName}
              show={showModal}
              closeModal={setShowModal}
              data={projectData}
              loading={loading}
              page={projectPage}
              total={projectTotalData}
              search={projectSearch}
              handleSearch={(v) => {
                setProjectSearch(v);
              }}
              isEditing={isEditing}
              changePagination={changeHistoryPagination}
              columns={clientHistoryDetailColumns()}
              handleAdd={(clientId, companyId) => {
                handleAdd(clientId, companyId);
              }}
              handleEdit={(v) => {
                handleEdit(v);
              }}
              editingForm={editingForm}
              handleEditForm={handleInputChange}
              handleResetForm={handleResetForm}
            />
            <div className="w-full flex justify-center mt-5">
              <Pagination
                current={page + 1}
                total={totalData}
                showSizeChanger={false}
                onChange={(p) => changePagination(p)}
                className="flex justify-center"
              />
            </div>
          </>
        }
      </div>
    </>
  );
};

export default StatusTalent;
