import React, { useState, useEffect } from "react";
import { Form, Input, Select, TimePicker, Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { isNullOrEmpty } from "../../../../../utils/utils";

const formatTimePicker = "HH:mm";
const { Option } = Select;

const getTitleAndSubtitle = (formType, isFinish) => {
  let titleModal = formType === "finish" ? "Finish Event" : "Edit Event";
  let subTitle =
    formType === "finish"
      ? "Finalize event result!"
      : "Please check your data below!";
  if (isFinish) {
    titleModal = "Event Details";
    subTitle = "Please check your data below!";
  }
  return { titleModal, subTitle };
};

const setInitialFormValues = (formEvent, defaultFormValue) => {
  formEvent.setFieldsValue({
    name: defaultFormValue?.name,
    startDate: defaultFormValue?.startDate,
    endDate: defaultFormValue?.endDate,
    startTime: defaultFormValue?.startTime
      ? dayjs(defaultFormValue?.startTime, formatTimePicker)
      : undefined,
    endTime: defaultFormValue?.endTime
      ? dayjs(defaultFormValue?.endTime, formatTimePicker)
      : undefined,
  });
};

const FormFinishEvent = ({
  formType = "finish",
  defaultFormValue,
  isDisabled,
  getDataFinishEvent,
  listCertification,
  isFinish = false,
}) => {
  const [formEvent] = Form.useForm();
  const [listParticipant, setListParticipant] = useState([]);
  const [listWinner, setListWinner] = useState([
    {
      title: "",
      participant: {
        user: {
          id: "",
        },
      },
      gc: undefined,
      xp: undefined,
    },
  ]);

  useEffect(() => {
    setInitialFormValues(formEvent, defaultFormValue);
    setListParticipant(defaultFormValue?.participants);
    if (isFinish) {
      setListWinner([]);
      const champions = listParticipant?.filter(
        (participant) => participant?.isChampion
      );
      setListWinner(champions);
    }
  }, [formEvent, defaultFormValue, isFinish, listParticipant]);

  useEffect(() => {
    listWinner.forEach((winner, index) => {
      formEvent.setFieldsValue({
        [`title-${index}`]: winner?.title,
        [`gc-${index}`]: winner?.gc,
        [`xp-${index}`]: winner?.xp,
        [`champion-${index}`]: winner?.participant?.user?.id,
      });
    });
  }, [formEvent, listWinner]);

  const handleAddRow = () => {
    if (listWinner.length !== listParticipant.length) {
      const temp = [...listWinner];
      temp.push({
        title: "",
        id: "",
        extraGc: undefined,
        extraXp: undefined,
      });
      setListWinner(temp);
    }
  };

  const handleChange = () => {
    const listChampion = { championUser: [] };
    if (listWinner.length) {
      listWinner.forEach((winner, index) => {
        listChampion.championUser.push({
          title: formEvent.getFieldValue(`title-${index}`),
          id: formEvent.getFieldValue(`champion-${index}`),
          extraGc: formEvent.getFieldValue(`gc-${index}`),
          extraXp: formEvent.getFieldValue(`xp-${index}`),
          isChampion: true,
        });
      });
    }
    getDataFinishEvent(listChampion);
  };

  const openUrl = (url) => {
    if (!isNullOrEmpty(url)) {
      window.open(url, "_BLANK");
    }
  };

  const { titleModal, subTitle } = getTitleAndSubtitle(formType, isFinish);

  return (
    <>
      <div className="w-full text-left">
        <h3 className="font-semibold text-[20px] ">{titleModal}</h3>
        <p>{subTitle}</p>

        <div className="w-full mt-[20px]">
          <Form
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            form={formEvent}
            colon={false}
            wrapperCol={{ flex: 1 }}
          >
            <Form.Item
              label="Event Name"
              rules={[
                {
                  required: true,
                  message: "Event Name is required",
                },
              ]}
              name="name"
            >
              <Input disabled placeholder="Input Name" type="text" />
            </Form.Item>
            <div className="flex mt-[10px]">
              <div className="w-[80%]">
                <Form.Item
                  label="Date"
                  rules={[
                    { required: true, message: "Start Date is required" },
                  ]}
                  name="startDate"
                >
                  <Input disabled placeholder="Start Date" type="date" />
                </Form.Item>
              </div>
              <div className="ml-[20px] pt-[5px]">-</div>
              <div className="ml-[20px] pt-[5px]">
                <Form.Item
                  name="endDate"
                  rules={[{ required: true, message: "End Date is required" }]}
                >
                  <Input disabled placeholder="Start Date" type="date" />
                </Form.Item>
              </div>
            </div>
            <div className="flex mt-[10px]">
              <div className="w-[60%]">
                <Form.Item
                  label={
                    <label>
                      Time
                      {!isFinish && <span style={{ color: "red" }}>*</span>}
                    </label>
                  }
                  rules={[
                    { required: true, message: "Start Time is required" },
                  ]}
                  name="startTime"
                >
                  <TimePicker
                    disabled
                    className="formTimePicker"
                    placeholder="Pick time"
                    style={{ width: "100%" }}
                    format={formatTimePicker}
                  />
                </Form.Item>
              </div>
              <div className="ml-[20px] pt-[5px]">-</div>
              <div className="ml-[20px] pt-[5px] w-[30%]">
                <Form.Item
                  name="endTime"
                  rules={[{ required: true, message: "End Time is required" }]}
                >
                  <TimePicker
                    disabled
                    className="formTimePicker"
                    placeholder="Pick time"
                    format={formatTimePicker}
                  />
                </Form.Item>
              </div>
            </div>
            <hr />
            <div className="flex justify-between mt-[20px] mb-[20px]">
              <p className="font-semibold text-[16px]">
                {isFinish ? "Champions" : `Let’s set the champion!`}
              </p>
              {!isFinish ? (
                <Button type="link" danger onClick={() => handleAddRow()}>
                  Add Row
                </Button>
              ) : (
                ""
              )}
            </div>
            {listWinner.length > 0 &&
              listWinner.map((winner, index) => {
                return (
                  <React.Fragment key={"winner-list-" + index}>
                    <div className="w-full">
                      <div className="flex w-full justify-between">
                        <div className="w-[40%] pr-[10px]">
                          <Form.Item
                            name={"title-" + index}
                            initialValue={isFinish ? winner.title : ""}
                          >
                            <Input
                              onChange={handleChange}
                              disabled={isDisabled}
                              placeholder="Title"
                              type="text"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-[60%] pl-[10px]">
                          <Form.Item name={"champion-" + index}>
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Choose the champion"
                              disabled={isDisabled}
                              onChange={handleChange}
                            >
                              {listParticipant?.map((i) => (
                                <Option key={i.id} value={i.id}>
                                  {i.user.fullname}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <div className="flex">
                            <div className="w-full pr-[5px]">
                              <Form.Item name={"gc-" + index}>
                                <Input
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  placeholder="Coin"
                                  type="number"
                                />
                              </Form.Item>
                            </div>
                            <div className="w-full pl-[5px]">
                              <Form.Item name={"xp-" + index}>
                                <Input
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  placeholder="XP"
                                  type="number"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            {isFinish && listCertification?.length > 0 && (
              <>
                <hr />
                <div className="mt-[20px]">
                  <p className="font-semibold text-[16px]">Verification</p>
                </div>
                <table className="w-full my-[20px]">
                  <tbody>
                    {listCertification.map((certification, index) => (
                      <tr key={"row-certification-" + index}>
                        <td>{certification.fullname}</td>
                        <td className="w-[20%] text-center">
                          {isNullOrEmpty(certification.urlAttandance) ? (
                            "-"
                          ) : (
                            <Button
                              type="link"
                              onClick={() =>
                                openUrl(certification.urlAttandance)
                              }
                            >
                              <div className="flex">
                                <p>Attandance</p>
                                <ExportOutlined
                                  style={{
                                    fontSize: "10px",
                                    marginLeft: "5px",
                                    marginTop: "6px",
                                  }}
                                />
                              </div>
                            </Button>
                          )}
                        </td>
                        <td className="w-[20%] text-center">
                          {isNullOrEmpty(certification.urlCertificate) ? (
                            "-"
                          ) : (
                            <Button
                              type="link"
                              onClick={() =>
                                openUrl(certification.urlCertificate)
                              }
                            >
                              <div className="flex">
                                <p>Certificate</p>
                                <ExportOutlined
                                  style={{
                                    fontSize: "10px",
                                    marginLeft: "5px",
                                    marginTop: "6px",
                                  }}
                                />
                              </div>
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default FormFinishEvent;
