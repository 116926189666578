import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import {
  message,
  Input,
  Button,
  Table,
  Card,
  Flex,
  Select,
  Checkbox,
} from "antd";
import IconArrowLeft from "../../../assets/icons/IconArrowLeft.png";
import Onboarding from "../../../repositores/talentRepo/OnboardRepo";
import ServiceLookUpRepo from "../../../repositores/ServiceLookupRepo";

const { TextArea } = Input;

const DatailOneMonthSupervisedTL = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [listDetail, setListDetail] = useState([]);

  const [listBoolean, setListBoolean] = useState([]);
  const [listInterest, setListInterest] = useState([]);
  const [listPenilaian, setListPenilaian] = useState([]);

  const [detailOms, setDetailOms] = useState({});
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);

  const typeInterest = "INTEREST";
  const typeBoolean = "BOOLEAN";
  const typePenilaian = "PENILAIAN";

  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "10%",
    },
    {
      title: "Chekllist",
      dataIndex: "chekllist",
      key: "id",
      width: "22%",
    },
    {
      title: "W1",
      dataIndex: "w1",
      key: "id",
      width: 200,
      align: "center",
      render: (_, record) => {
        if (record?.oneMonthSupervisedData?.type === typeInterest) {
          const tempFilter = listInterest.filter(
            (val) => val.value === record?.w1?.id
          );
          let defValueInterest = null;
          if (tempFilter.length > 0) {
            defValueInterest = record?.w1?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                defaultValue={defValueInterest}
                options={listInterest}
                disabled={loading}
                placeholder="Please Select"
                onChange={(val) => {
                  record.w1.id = val;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typeBoolean) {
          let getValueChecked = listBoolean.filter(
            (val) => val.value === record?.w1?.id
          );
          let idTrue = listBoolean.filter((val) => val.label === "TRUE");
          let idFalse = listBoolean.filter((val) => val.label === "FALSE");
          return (
            <>
              <Checkbox
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                checked={getValueChecked[0].name === "TRUE"}
                disabled={loading}
                onChange={(val) => {
                  record.w1.id = val.target.checked
                    ? idTrue[0]?.value
                    : idFalse[0]?.value;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typePenilaian) {
          const tempFilter = listPenilaian.filter(
            (val) => val.value === record?.w1?.id
          );
          let defValuePenilaian = null;
          if (tempFilter.length > 0) {
            defValuePenilaian = record?.w1?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                placeholder="Please Select"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                defaultValue={defValuePenilaian}
                disabled={loading}
                options={listPenilaian}
                onChange={(val) => {
                  record.w1.id = val;
                }}
              />
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "W2",
      dataIndex: "w2",
      key: "id",
      width: 200,
      align: "center",
      render: (_, record) => {
        if (record?.oneMonthSupervisedData?.type === typeInterest) {
          const tempFilter = listInterest.filter(
            (val) => val.value === record?.w2?.id
          );
          let defValueInterest = null;
          if (tempFilter.length > 0) {
            defValueInterest = record?.w2?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                defaultValue={defValueInterest}
                options={listInterest}
                disabled={loading}
                placeholder="Please Select"
                onChange={(val) => {
                  record.w2.id = val;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typeBoolean) {
          let getValueChecked = listBoolean.filter(
            (val) => val.value === record?.w2?.id
          );
          let idTrue = listBoolean.filter((val) => val.label === "TRUE");
          let idFalse = listBoolean.filter((val) => val.label === "FALSE");
          return (
            <>
              <Checkbox
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                checked={getValueChecked[0].name === "TRUE"}
                disabled={loading}
                onChange={(val) => {
                  record.w2.id = val.target.checked
                    ? idTrue[0]?.value
                    : idFalse[0]?.value;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typePenilaian) {
          const tempFilter = listPenilaian.filter(
            (val) => val.value === record?.w2?.id
          );
          let defValuePenilaian = null;
          if (tempFilter.length > 0) {
            defValuePenilaian = record?.w2?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                disabled={loading}
                defaultValue={defValuePenilaian}
                options={listPenilaian}
                placeholder="Please Select"
                onChange={(val) => {
                  record.w2.id = val;
                }}
              />
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "W3",
      dataIndex: "w3",
      key: "id",
      width: 200,
      align: "center",
      render: (_, record) => {
        if (record?.oneMonthSupervisedData?.type === typeInterest) {
          const tempFilter = listInterest.filter(
            (val) => val.value === record?.w3?.id
          );
          let defValueInterest = null;
          if (tempFilter.length > 0) {
            defValueInterest = record?.w3?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                defaultValue={defValueInterest}
                disabled={loading}
                options={listInterest}
                placeholder="Please Select"
                onChange={(val) => {
                  record.w3.id = val;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typeBoolean) {
          let getValueChecked = listBoolean.filter(
            (val) => val.value === record?.w3?.id
          );
          let idTrue = listBoolean.filter((val) => val.label === "TRUE");
          let idFalse = listBoolean.filter((val) => val.label === "FALSE");
          return (
            <>
              <Checkbox
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                checked={getValueChecked[0].name === "TRUE"}
                disabled={loading}
                onChange={(val) => {
                  record.w3.id = val.target.checked
                    ? idTrue[0]?.value
                    : idFalse[0]?.value;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typePenilaian) {
          const tempFilter = listPenilaian.filter(
            (val) => val.value === record?.w3?.id
          );
          let defValuePenilaian = null;
          if (tempFilter.length > 0) {
            defValuePenilaian = record?.w3?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                defaultValue={defValuePenilaian}
                disabled={loading}
                options={listPenilaian}
                placeholder="Please Select"
                onChange={(val) => {
                  record.w3.id = val;
                }}
              />
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "W4",
      dataIndex: "w4",
      key: "id",
      width: 200,
      align: "center",
      render: (_, record) => {
        if (record?.oneMonthSupervisedData?.type === typeInterest) {
          const tempFilter = listInterest.filter(
            (val) => val.value === record?.w4?.id
          );
          let defValueInterest = null;
          if (tempFilter.length > 0) {
            defValueInterest = record?.w4?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                disabled={loading}
                defaultValue={defValueInterest}
                options={listInterest}
                placeholder="Please Select"
                onChange={(val) => {
                  record.w4.id = val;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typeBoolean) {
          let getValueChecked = listBoolean.filter(
            (val) => val.value === record?.w4?.id
          );
          let idTrue = listBoolean.filter((val) => val.label === "TRUE");
          let idFalse = listBoolean.filter((val) => val.label === "FALSE");
          return (
            <>
              <Checkbox
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                disabled={loading}
                checked={getValueChecked[0].name === "TRUE"}
                onChange={(val) => {
                  record.w4.id = val.target.checked
                    ? idTrue[0]?.value
                    : idFalse[0]?.value;
                }}
              />
            </>
          );
        } else if (record?.oneMonthSupervisedData?.type === typePenilaian) {
          const tempFilter = listPenilaian.filter(
            (val) => val.value === record?.w4?.id
          );
          let defValuePenilaian = null;
          if (tempFilter.length > 0) {
            defValuePenilaian = record?.w4?.id;
          }
          return (
            <>
              <Select
                className="w-full"
                key={record.id + "_" + record?.oneMonthSupervisedData?.id}
                defaultValue={defValuePenilaian}
                disabled={loading}
                options={listPenilaian}
                placeholder="Please Select"
                onChange={(val) => {
                  record.w4.id = val;
                }}
              />
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "Remark By TL",
      dataIndex: "remarkByTL",
      key: "id",
      width: "30%",
      render: (_, record) => {
        return (
          <>
            <TextArea
              rows={3}
              placeholder="Input Remark By TL"
              disabled={loading}
              defaultValue={record?.remarkByTL}
              onChange={(val) => {
                record.remarkByTL = val.target.value;
              }}
            />
          </>
        );
      },
    },
  ];

  const goBack = () => {
    if (!loading) {
      localStorage.removeItem("_oms");
      navigate("/talent/one-month-supervised-team-leader");
    }
  };

  useEffect(() => {
    let detailsOms = localStorage.getItem("_oms");
    if (typeof detailsOms === "undefined" || detailsOms === null) {
      goBack();
    } else {
      setDetailOms(JSON.parse(detailsOms));
      // localStorage.removeItem('_oms')
    }
    fetchServiceLookup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  async function fetchData() {
    setLimit(10);
    try {
      let response = await Onboarding.getListDetailOneMonthSupervisedTL(
        id,
        page,
        limit
      );
      let tempData = [];
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content, index) => {
          content.number = (page > 0 ? limit * (page - 1) : 0) + (index + 1);
          if (content.w1 === null) {
            content.w1 = { id: null };
          }
          if (content.w2 === null) {
            content.w2 = { id: null };
          }
          if (content.w3 === null) {
            content.w3 = { id: null };
          }
          if (content.w4 === null) {
            content.w4 = { id: null };
          }
          tempData.push(content);
        });
        setTotalItem(response?.data?.data?.totalElements);
        setListDetail(tempData);
      } else {
        setTotalItem(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Failed to get Data Onboarding",
      });
    }
  }

  async function fetchServiceLookup() {
    let responseBoolean =
      await ServiceLookUpRepo.getServiceLookupOnBoardTypeBoolean(1, 20);
    let tempListBoolean = [
      {
        value: "",
        label: "Please Select",
        disabled: true,
      },
    ];
    if (responseBoolean?.data?.data?.content.length) {
      responseBoolean?.data?.data?.content.forEach((val) => {
        tempListBoolean.push({
          value: val.id,
          label: val.name,
        });
      });
    }
    setListBoolean(tempListBoolean);

    let responseInterest =
      await ServiceLookUpRepo.getServiceLookupOnBoardTypeInterest(1, 20);
    let tempListInterest = [
      {
        value: "",
        label: "Please Select",
        disabled: true,
      },
    ];
    if (responseInterest?.data?.data?.content.length) {
      responseInterest?.data?.data?.content.forEach((val) => {
        tempListInterest.push({
          value: val.id,
          label: val.description,
        });
      });
    }
    setListInterest(tempListInterest);

    let responsePenilaian =
      await ServiceLookUpRepo.getServiceLookupOnBoardTypePenilaian(1, 20);
    let tempListPenilaian = [
      {
        value: "",
        label: "Please Select",
        disabled: true,
      },
    ];
    if (responsePenilaian?.data?.data?.content.length) {
      responsePenilaian?.data?.data?.content.forEach((val) => {
        tempListPenilaian.push({
          value: val.id,
          label: val.description,
        });
      });
    }
    setListPenilaian(tempListPenilaian);
    fetchData();
  }

  const updateDetail = async () => {
    setLoading(true);
    let payload = [];
    listDetail.forEach((dt) => {
      payload.push({
        id: dt.id,
        remarkByTL: dt.remarkByTL,
        w1: dt.w1.id != null ? { id: dt.w1.id } : null,
        w2: dt.w2.id != null ? { id: dt.w2.id } : null,
        w3: dt.w3.id != null ? { id: dt.w3.id } : null,
        w4: dt.w4.id != null ? { id: dt.w4.id } : null,
      });
    });
    try {
      let response = await Onboarding.updateDetailOneMonthSupervisedTL(payload);
      if (response.data.status === 200) {
        const counter = refreshCounter + 1;
        messageApi.open({
          type: "success",
          content: "Successfully Update data",
        });
        setRefreshCounter(counter);
      } else {
        throw response.data;
      }
    } catch (error) {
      if (error?.message || error?.data?.message || error?.error) {
        messageApi.open({
          type: "error",
          content: error?.message || error?.data?.message || error?.error,
        });
      } else {
        messageApi.open({
          type: "error",
          content:
            "sorry we're having trouble adding data right now, please try again later",
        });
      }
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="w-full px-[20px] py-[20px]" id="omsDetail">
        <div className="mt-[6px] mr-[20px]">
          <button onClick={goBack}>
            <img width={48} src={IconArrowLeft} alt="icon" />
          </button>
        </div>
        <div>
          <h4 className="text-[32px] font-bold">
            Detail One Month Supervised Team Leader
          </h4>
        </div>

        <Card>
          <Flex>
            <div className="w-[50%]">
              <b>Talent Name</b> <br />
              {detailOms?.historyClientTalent?.userRole?.user?.fullname}
              <br />
              <b>Client</b> <br />
              {detailOms?.historyClientTalent?.clientCompany?.name}
              <br />
            </div>
            <div>
              <b>TL Name</b> <br />
              {detailOms?.tl?.fullname}
            </div>
          </Flex>
          <hr className="mt-[20px]" />
          <Flex>
            <div className="w-[50%] pr-[10px]">
              <b>Talent Remark</b> <br />
              {detailOms?.remarkByTalent}
            </div>
            <div>
              <b>TL Remark</b> <br />
              {detailOms?.remarkByTL}
            </div>
          </Flex>
        </Card>

        <div className="mt-[20px] w-full">
          <Table
            dataSource={listDetail}
            columns={columns}
            pagination={false}
            sticky
            scroll={{
              y: 470,
              x: 1200,
            }}
            rowKey="id"
          />
        </div>

        <div className="flex gap-x-6 justify-between px-[20px] pb-[200px]">
          <div
            onClick={() => {
              goBack();
            }}
            className="mr-[30px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
          >
            <span className="text-grey">Cancel</span>
          </div>
          <Button
            onClick={() => updateDetail()}
            type="primary"
            disabled={loading}
            danger
            className="mr-1 bg-[#DD0000]"
            style={{ backgroundColor: "#DD0000" }}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
};
export default DatailOneMonthSupervisedTL;
