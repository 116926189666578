import { EventCertificate } from "../EventCertificate";
import { DetailContainer } from "./DetailContainer";

export const Prerequisites = ({ prerequisites }) => {
  return (
    <DetailContainer title={"Prerequsites"}>
      <div className="flex flex-wrap items-center gap-1">
        {prerequisites?.length ? (
          prerequisites.map((certificate) => (
            <EventCertificate
              key={certificate.id}
              certificateName={certificate.name}
            />
          ))
        ) : (
          <p className="text-black text-xs font-medium">-</p>
        )}
      </div>
    </DetailContainer>
  );
};
