import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import RouteName from "../../helper/routeName";
import { Form, Input } from "antd";
import AuthRepo from "../../repositores/authRepo/LoginRepo";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

const ActivateAccount = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const emailParams = searchParams.get("email");
    if (emailParams) {
      setEmail(emailParams);
    } else {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Email not found. Please enter page by clicking link from email",
      }).then(() => {
        backToLogin();
      });
    }
  }, []);

  const backToLogin = () => {
    navigate(RouteName.login);
  };

  const onClickActivate = () => {
    if (password !== confirmPassword) {
      Swal.fire({
        title: "Error",
        text: "Password and confirm password are different!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        color: "#DD0000",
      });
    } else {
      changePassword();
    }
  };

  const changePassword = async () => {
    try {
      const { data } = await AuthRepo.changePassword({
        email,
        newPassword: password,
        confirmNewPassword: confirmPassword,
      });
      if (data.status != 200) {
        Swal.fire({
          title: "Failed",
          toast: true,
          text: data.message,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          color: "#DD0000",
        });
      } else {
        setEmail("");
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Password successfully changed.",
        }).then(() => {
          navigate(RouteName.login);
        });
      }
    } catch (error) {
      const err = error.response;
      if (err) {
        Swal.fire({
          toast: true,
          text: err.message,
        });
      }
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <img
          src="/assets/images/logoTelkomsel-backup.svg"
          alt="telkom-logo"
          className="mb-8"
        />
      </div>
      <div className="text-xl md:text-3xl font-bold text-[#4C4C4C] mb-2">
        {pathname === "/activate-account"
          ? "Activate New Account"
          : "Forgot Password"}
      </div>
      <div className="text-xs md:text-base text-[#4C4C4C] mb-8">
        Welcome! Please set a new password.
      </div>
      {/* main */}
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="new-password"
          label="New Password"
          className="mb-3 font-medium"
        >
          <Input.Password
            value={password}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item
          name="confirm-password"
          label="Confirm Password"
          className="mb-10 font-medium"
        >
          <Input.Password
            value={confirmPassword}
            placeholder="Confirm your password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item>
          <button
            onClick={() => onClickActivate()}
            className="w-full bg-[#DD0000] py-2 rounded-md text-white font-semibold"
          >
            Activate
          </button>
        </Form.Item>
      </Form>
      {/* end main */}
    </>
  );
};

export default ActivateAccount;
