import React, { useState, useEffect,  useRef } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { message, Pagination, Modal, Input, Button, Spin  } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import IconArrowLeft from "../../../assets/icons/IconArrowLeft.png"

import AdminLmsRepo from "../../../repositores/adminRepo/LmsRepo";
import TableMatkul from "./components/tableListMatkul";
import FormMataKuliah from "./components/formMatkul";
import { isNullOrEmpty } from "../../../utils/utils";

const MataKuliah = () => {
    const formMatkulRef = useRef();

    const navigate = useNavigate();
    let { id } = useParams();
    
    const [messageApi, contextHolder] = message.useMessage();

    const [detailCourse, setDetailCourse] = useState(undefined)

    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(true);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);

    const [bounchPage, setBounchPage] = useState(undefined);

    const [searchMatkul, setSearchMatkul] = useState(undefined);
    const [termin, setTermin] = useState(undefined);
    const [levelId, setLevelId] = useState(undefined);
    const [filterTrigger, setFilterTrigger] = useState(0)

    const [titleModal, setTitleModal] = useState('Add Mata Kuliah');
    const [formType, setFormType] = useState('add')
    const [detailMatkul, setDetailMatkul] = useState(undefined)

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const [counterChildRefresh, setCounterChildRefresh] = useState(1)

    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, filterTrigger, refreshCounter])

    async function fetchData() {
        setLimit(10);
        try {
            let tempData = [];
            if(typeof detailCourse === 'undefined') {
                let responseDetail = await AdminLmsRepo.getCourse(id)
                if(responseDetail?.data?.data?.status === 200) {
                    setDetailCourse(responseDetail?.data?.data?.data)
                } else {
                    throw responseDetail?.data?.data?.data
                }
            } 
            let response = await AdminLmsRepo.getMatkulByCourse(
                id,
                page,
                limit,
                termin,
                levelId,
                searchMatkul
            );
            if (response?.data?.data?.content) { 
                await response?.data?.data?.content?.forEach((content, index) => {
                    content.number = (limit*(page-1)) + (index+1)
                    tempData.push(content)
                })
                setDataTable(tempData);
                setTotalItem(response?.data?.data?.totalElements);
                
            } else {
                setDataTable([]);
                setTotalItem(0);
                throw response
            }
            setLoading(false);
            setLoadingData(false);
        } catch (error) {
            setLoading(false);
            setLoadingData(false);
            if(error?.data?.error) {
                messageApi.open({
                    type: 'error',
                    content: error?.data?.error ,
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: "Failed to get data mata kuliah" ,
                });
            }
        }
    }

    async function postMatKul(payload) {
        setLoadingData(true);
        formMatkulRef.current.resetForm()
        let counterC = counterChildRefresh
        setCounterChildRefresh(counterC+1)
        setIsOpenModal(false);
        try {
            let response = await AdminLmsRepo.addMatkul(payload)
            if(response.data.status === 200) {
                const counter = refreshCounter + 1
                messageApi.open({
                    type: 'success',
                    content: "Successfully add data" ,
                });
                setRefreshCounter(counter)
            } else {
                throw response.data
            }
        } catch (error) {
            if(error?.message || error?.data?.message || error?.error) {
                messageApi.open({
                    type: 'error',
                    content: error?.message || error?.data?.message || error?.error ,
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: "sorry we're having trouble adding data right now, please try again later" ,
                });
            }
            setLoadingData(false);
        }
    }

    async function putMatKul(payload) {
        setLoadingData(true);
        formMatkulRef.current.resetForm()
        let counterC = counterChildRefresh
        setCounterChildRefresh(counterC+1)
        setIsOpenModal(false);
        try {
            let response = await AdminLmsRepo.updateMatkul(payload)
            if(response.data.status === 200) {
                const counter = refreshCounter + 1
                messageApi.open({
                    type: 'success',
                    content: "Successfully update data" ,
                });
                setRefreshCounter(counter)
            } else {
                throw response.data
            }
        } catch (error) {
            if(error?.message || error?.data?.message || error?.error) {
                messageApi.open({
                    type: 'error',
                    content: error?.message || error?.data?.message || error?.error ,
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: "sorry we're having trouble updating data right now, please try again later" ,
                });
            }
            setLoadingData(false);
        }
    }

    async function deleteMatkul() {
        setLoadingData(true);
        setIsOpenConfirm(false);
        try {
            let response = await AdminLmsRepo.deleteMatkul(detailMatkul.id)
            if(response.data.status === 200) {
                const counter = refreshCounter + 1
                messageApi.open({
                    type: 'success',
                    content: "Successfully delete data" ,
                });
                setDetailCourse(undefined)
                if(dataTable.length == 1 && page > 1) {
                    setPage(page - 1)
                } else {
                    setRefreshCounter(counter)
                }
            } else {
                throw response.data
            }
        } catch (error) {
            setDetailCourse(undefined)
            if(error?.message || error?.data?.message || error?.error) {
                messageApi.open({
                    type: 'error',
                    content: error?.message || error?.data?.message || error?.error ,
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: "sorry we're having trouble deleting data right now, please try again later" ,
                });
            }
            setLoadingData(false);
        }
    }

    

    const goBack = () => {
        navigate('/admin/course')
    }

    const onChangeSearch = (el) => { 
        setLoadingData(true);
        setSearchMatkul(el.target.value);
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setFilterTrigger((prevValue) => prevValue + 1)
            setPage(1);
        }, 2000))
    }
    const addMatkul = () => {
        let counterC = counterChildRefresh
        setCounterChildRefresh(counterC+1)
        setFormType('add');
        setTitleModal('Add Mata Kuliah')
        setIsOpenModal(true);
    }

    const selectEdit = (data) => {
        let counterC = counterChildRefresh
        setCounterChildRefresh(counterC+1)
        setDetailMatkul(data)
        setFormType('edit');
        setTitleModal('Update Mata Kuliah')
        setIsOpenModal(true);
    }
    const selectDelete = (data) => {
        setDetailMatkul(data)
        setIsOpenConfirm(true)
    }

    const selectToDetail = (data) => {
        navigate(`/admin/course/${id}/mata-kuliah/${data.id}`)
    }

    const onChangePage = (page) => {
        setLoadingData(true);
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setPage(page)
        }, 1000))
    }

    const closeModal = (modal = 'form') => {
        let counter = counterChildRefresh
        setCounterChildRefresh(counter+1)
        if(modal === 'form') {
            formMatkulRef.current.resetForm()
            setIsOpenModal(false);
        } else {
            setDetailMatkul(undefined);
            setIsOpenConfirm(false);
        }
    }

    const doFilter = (data, dataKey) => {
        setLoadingData(true);
        clearTimeout(bounchPage);
        if(dataKey === 'termin') {
            setTermin(data)
        }
        if(dataKey === 'level') {
            setLevelId(data)
        }
        setBounchPage(setTimeout(() => {
            setFilterTrigger((prevValue) => prevValue + 1)
            setPage(1)
        }, 1000))
    }

    const submitMatkul = () => {
        formMatkulRef.current.validateFields().then(() => {
            let payload = {
                course: {
                    id: id
                },
                level: {
                    id: formMatkulRef.current.getFieldValue('level')
                },
                termin: formMatkulRef.current.getFieldValue('termin'),
                matkul: formMatkulRef.current.getFieldValue('matkul'),
                matkulNo: formMatkulRef.current.getFieldValue('matkulNo'),
            }
            if(formType === 'add') {
                postMatKul(payload)
            } else {
                payload.id = detailMatkul.id
                putMatKul(payload)
            }
            
        }).catch(() => {})
    }

    if(loading) {
        return (
            <>
                <div className="container w-full" id="courseDetail">
                    <div className="w-[100%] text-center">
                        <Spin size="large" fullscreen />
                    </div>
                </div>
            </>
        )
    }

    return  (
        <>
            {contextHolder}
            <div className="w-full" id="courseDetail">
                <div className="w-[100%] px-[20px] py-[20px] flex">
                    <div className="mt-[6px] mr-[20px]">
                        <button onClick={goBack} >
                            <img width={48} src={IconArrowLeft} alt="icon" />
                        </button>
                    </div>
                    <div>
                        <h4 className="text-[32px] font-bold">{detailCourse?.name}</h4>
                        <p>{detailCourse?.description}</p>
                    </div>
                </div>
                <div className="flex gap-x-6 justify-end px-[20px]">
                    <Input
                        className="w-[300px]"
                        placeholder="Search"
                        disabled={loading}
                        onChange={onChangeSearch}
                        suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
                    />
                    <Button
                        onClick={() => addMatkul()}
                        type="primary"
                        disabled={loading}
                        danger
                        className="mr-1 bg-[#DD0000]"
                        style={{ backgroundColor: "#DD0000" }}
                        >
                        Add Mata Kuliah
                    </Button>
                </div>

                {
                    loadingData ? <>
                        <div className="w-full text-center mt-[20px]">
                            <p>Please wait...</p>
                        </div>
                    </> : 
                    <>
                        <div className="mt-[20px] ml-[10px] w-full px-[20px]">
                            <TableMatkul
                                isLoading={loading}
                                dataTable={dataTable}
                                selectEdit={(data) => selectEdit(data)}
                                selectDelete={(data) => selectDelete(data)}
                                selectToDetail={(data) => selectToDetail(data)}
                                doFilter={(data, dataKey) => doFilter(data, dataKey)}
                                doClearFilter={(data, dataKey) => doFilter(data, dataKey)}
                                defaultValueFilter={() => {
                                    return {
                                        termin: termin,
                                        level: levelId
                                    }
                                }}
                                filteredData={() => {
                                    return {
                                        termin: isNullOrEmpty(termin) ? false : true,
                                        level: isNullOrEmpty(levelId) ? false : true
                                    }
                                }}
                            />
                            <div className="w-full flex justify-center my-2 pt-2 pb-10">
                                <Pagination
                                    current={page}
                                    onChange={onChangePage}
                                    pageSize={limit}
                                    total={totalItem}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>

            <Modal
                onCancel={() => closeModal()}
                title={titleModal}
                open={isOpenModal}
                footer={
                    <>
                        <div className="flex justify-between mt-[20px]">
                            <Button key="back" danger onClick={() => closeModal()}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "#DD0000", color: "white" }}
                                onClick={() => submitMatkul()}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                }
            >
                <div className="container p-[10px] my-[30px]">
                    <FormMataKuliah key={counterChildRefresh} ref={formMatkulRef} formType={formType} dataMatkul={detailMatkul}   />
                </div>

            </Modal>

            <Modal
                onCancel={() => closeModal('confirm')}
                title="Delete Mata Kuliah"
                open={isOpenConfirm}
                footer={
                    <>
                        <div className="flex justify-between mt-[20px]">
                            <Button key="back" danger onClick={() => closeModal('confirm')}>
                                No
                            </Button>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "#DD0000", color: "white" }}
                                onClick={() => deleteMatkul()}
                            >
                                Yes
                            </Button>
                        </div>
                    </>
                }
            >
                <div className="container p-[10px] my-[30px]">
                    <p>Are you sure to delete this `<b>{detailMatkul?.matkul}</b>` ?</p>
                </div>
            </Modal>
        </>
    )
}

export default MataKuliah