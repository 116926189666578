import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Select } from "antd";
import AlertUtility from "../../../../components/Alert/Alert";
import AdminLmsRepo from "../../../../repositores/adminRepo/LmsRepo";

const { Option } = Select;

const ModalAssignForMe = ({ show, closeModal, fireConfirm }) => {
    const [formAssignee] = Form.useForm();

    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(100);
    const [optionsCourse, setOptionsCourse] = useState([]);
    const [totalElementCourse, setTotalElementCourse] = useState(-1);
    const [searchOption, setSearchOption] = useState("")
    const [onSearchOption, setOnSearchOption] = useState(false)
    const [debounce, setDebounce] = useState(undefined)
    const [fetchCounter, setFetchCounter] = useState(1)

    const handleScroll = (event) => {
        event.stopPropagation();
    
        if (!loadingMore && !onSearchOption) {
          handleFetchMore(page);
        }
    };

    useEffect(() => {
        handleFetchSearch("")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFetchMore = async (page) => {
        if (
            page - 1 <= totalPage &&
            optionsCourse?.length !== totalElementCourse
        ) {
            setLoadingMore(true);
            try {
                const response = await AdminLmsRepo.getListCourses(page, 20, searchOption);
                let tempOptions = optionsCourse;
                if (response.data?.data?.content?.length) {
                    await response.data?.data?.content?.forEach((typeEvent) => {
                        tempOptions.push(typeEvent);
                    });
                    setFetchCounter((prevVal) => prevVal + 1)
                    setOptionsCourse(tempOptions);
                    setTotalPage(response.data?.data?.totalPages);
                    setTotalElementCourse(response.data?.data?.totalElements);
                }
                setPage(page + 1);
                setLoadingMore(false);
                setOnSearchOption(false);
            } catch (error) {
                AlertUtility.ErrorAlert({ title: "Failed Get Data" });
                setLoadingMore(false);
            }
        }
    };

    const handleFetchSearch = async (value) => {
        try {
            const response = await AdminLmsRepo.getListCourses(page, 20, value);
            let tempOptions = [];
            if (response.data?.data?.content?.length) {
                await response.data?.data?.content?.forEach((typeEvent) => {
                    tempOptions.push(typeEvent);
                });
                setFetchCounter((prevVal) => prevVal + 1)
                setOptionsCourse(tempOptions);
                setTotalPage(response.data?.data?.totalPages);
                setTotalElementCourse(response.data?.data?.totalElements);
                setSearchOption(value)
            }
            setLoadingMore(false);
            setOnSearchOption(false);
        } catch (error) {
            AlertUtility.ErrorAlert({ title: "Failed Get Data" });
            setLoadingMore(false);
        }
    }

    const handleFireConfirm = () => {
        formAssignee.validateFields().then(() => {
            fireConfirm({
                id: formAssignee.getFieldValue('course').split("|")[0],
                name: formAssignee.getFieldValue('course').split("|")[1]
            })
        }).catch(() => { })

    }

    const handleSearch = (value) => {
        clearTimeout(debounce)
        setLoadingMore(true);
        setOnSearchOption(true);
        
        setPage(1)
        setDebounce(setTimeout(() => {
            handleFetchSearch(value)
        }, 2000))
    };

    return (
        <Modal 
            maskClosable={false}
            open={show} 
            onCancel={() => closeModal(false)} 
            footer={
                <>
                    <div className="flex justify-between mt-[20px]">
                        <Button key="back" danger onClick={() => closeModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#DD0000", color: "white" }}
                            onClick={() => handleFireConfirm()}
                        >
                            Submit
                        </Button>
                    </div>
                </>
            }>
                <div className="container p-[10px] my-[30px]">
                    <Form
                        labelCol={{ flex: "150px" }}
                        labelAlign="left"
                        form={formAssignee}
                        colon={false}
                        wrapperCol={{ flex: 1 }}
                    >
                        <Form.Item
                            label="Course"
                            name="course"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select course",
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Choose course"
                                dropdownRender={(menu) => (
                                    <div onWheel={handleScroll}>{menu}</div>
                                )}
                                loading={loadingMore}
                                showSearch
                                onSearch={handleSearch}
                                optionFilterProp="children"
                                filterOption={false}
                                defaultActiveFirstOption={false}
                            >
                                {
                                    loadingMore  ? 
                                    <>
                                        <Option disabled value="">Please wait...</Option>
                                    </>
                                    : 
                                    optionsCourse.map((i) => (
                                        <Option key={i.id+"-"+fetchCounter} value={`${i.id}|${i.name}`}>
                                            {i.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                    </Form>
                </div>
        
        </Modal>
    );
};

export default ModalAssignForMe;
