import CallApi from "../../../services/Request-Helper";
import { createSearchParams } from "../../../utils/utils";

const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListJourney = async (page, size, name) => {
    let params = {
      page: page.toString(),
      size: size,
      name: name,
    };
    if (typeof name != "undefined" && name.length) {
      params["name"] = name;
    }
    const url = `${API_BASE}/lookup/list-user-journey?${createSearchParams(
      params
    )}&type=MASTER_EVENT_JOURNEY`;
    const response = await CallApi({ url, method: "GET" });
  
    return response;
  };
  
  
export default {
    getListJourney
};