import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message, Button, Table, Pagination } from "antd";
import Onboarding from "../../../repositores/talentRepo/OnboardRepo";

const TalentOnboarding = () => {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(1);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [searchName, setSearchName] = useState(undefined);

  const [dataTable, setDataTable] = useState([]);
  const [bounchPage, setBounchPage] = useState(undefined);

  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "20%",
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            <div
              onClick={() => {
                getViewFile(record);
              }}
              className="mr-[30px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">View File</span>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshCounter]);

  async function fetchData() {
    setLimit(10);
    try {
      let tempData = [];
      let response = await Onboarding.getListOnboarding(
        page,
        limit,
        searchName
      );
      if (response?.data?.data?.content) {
        await response?.data?.data?.content?.forEach((content, index) => {
          content.number = (page > 0 ? limit * (page - 1) : 0) + (index + 1);
          tempData.push(content);
        });
        setDataTable(tempData);
        setTotalItem(response?.data?.data?.totalElements);
      } else {
        setDataTable([]);
        setTotalItem(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Failed to get Data Onboarding",
      });
    }
  }

  const getViewFile = async (record) => {
    // const rep = await Onboarding.getFile(record.fileName)
    const rep = record.fileName;
    window.open(rep, "_blank");
    messageApi.open({
      type: "success",
      content: "Success Downloaded File",
    });
  };

  const onChangePage = (el) => {
    setLoading(true);
    clearTimeout(bounchPage);
    setBounchPage(
      setTimeout(() => {
        setPage(el);
      }, 1000)
    );
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <div className="w-[100%] px-[50px] py-[20px]">
          <h4 className="text-[32px] font-bold">Onboarding</h4>
        </div>

        <div className="w-full">
          <Table
            dataSource={dataTable}
            columns={columns}
            pagination={false}
            scroll={{
              y: 470,
              x: 800,
            }}
          />
          <div className="w-full flex justify-center my-2 pt-2 pb-10">
            <Pagination
              current={page}
              onChange={onChangePage}
              pageSize={limit}
              total={totalItem}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default TalentOnboarding;
