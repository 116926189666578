import React, { useEffect, useState } from "react";
import { Pagination, message, Table, Modal, Button, Skeleton } from "antd";
// import SkeletonTable from "../../../components/general/SkeletonTable";
import TalentHeader from "../../../components/general/TalentHeader";
import RoleSkillTalent from "../../../repositores/talentRepo/RoleSkillTalentRepo";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import SkillForm from "./SkillForm";

const SkeletonTable = () => {
  return (
    <div className="overflow-x-auto">
      {/* Skeleton for table header */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 p-4 mb-4">
        <div className="col-span-1 md:col-span-1">
          <Skeleton.Input active size="large" className="w-full" />
        </div>
        <div className="col-span-1 md:col-span-1">
          <Skeleton.Input active size="large" className="w-full" />
        </div>
        <div className="col-span-1 md:col-span-1">
          <Skeleton.Input active size="large" className="w-full" />
        </div>
        <div className="col-span-1 md:col-span-1">
          <Skeleton.Input active size="large" className="w-full" />
        </div>
        <div className="col-span-1 md:col-span-1">
          <Skeleton.Input active size="large" className="w-full" />
        </div>
      </div>

      {/* Skeleton for table rows */}
      <div className="space-y-4 p-4">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div className="col-span-1 md:col-span-1">
              <Skeleton.Input active size="large" className="w-full" />
            </div>
            <div className="col-span-1 md:col-span-1">
              <Skeleton.Input active size="large" className="w-full" />
            </div>
            <div className="col-span-1 md:col-span-1">
              <Skeleton.Input active size="large" className="w-full" />
            </div>
            <div className="col-span-1 md:col-span-1">
              <Skeleton.Input active size="large" className="w-full" />
            </div>
            <div className="col-span-1 md:col-span-1">
              <Skeleton.Input active size="large" className="w-full" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SkillsPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [searchRole, setSearchRole] = useState(undefined);
  const [searchSkill, setSearchSkill] = useState(undefined);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [bouncSearch, setBounchSearch] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [selectData, setSelectData] = useState(undefined);
  const [counter, setCounter] = useState(0);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const perPage = 10;

  const columns = [
    {
      title: "ROLE",
      dataIndex: "userRole",
      key: "userRole",
      width: "20%",
      align: "center",
      render: (_, record) => {
        if (record?.userRole?.roleCompany?.name) {
          return <>{record.userRole.roleCompany.name}</>;
        }

        return <>-</>;
      },
    },
    {
      title: "SKILL",
      dataIndex: "skill",
      key: "skill",
      align: "center",
      width: "20%",
      render: (_, record) => {
        return <>{record.skill.name}</>;
      },
    },
    {
      title: "COURSE",
      dataIndex: "course",
      key: "userCourses",
      align: "center",
      width: "20%",
      render: (_, record) => {
        if (record?.usersCourses?.course?.name) {
          return <div> {record?.usersCourses?.course?.name}</div>;
        }
        return <div>-</div>;
      },
    },
    {
      title: "EXPERIENCE",
      dataIndex: "experience",
      key: "experience",
      align: "center",
      width: "15%",
      render: (_, record) => {
        return <>{record.experienceBulan} Month</>;
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "200px",
      render: (_, record) => (
        <div>
          <Button
            danger
            className="mr-1"
            onClick={() => {
              handleEdit(record);
            }}
          >
            Edit
          </Button>
          <Button
            type="text"
            onClick={() => {
              handleOpenDelete(record);
            }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchListSkill();
  }, [page, searchRole, searchSkill]);

  const handleSearchRole = (value) => {
    setLoading(true);
    clearTimeout(bouncSearch);

    setBounchSearch(
      setTimeout(() => {
        if (searchRole === value) {
          setLoading(false);
        }
        if (page > 1) {
          setPage(1);
        }
        setSearchRole(value);
      }, 2000)
    );
  };

  const handleSearchSkill = (value) => {
    setLoading(true);
    clearTimeout(bouncSearch);

    setBounchSearch(
      setTimeout(() => {
        if (searchSkill === value) {
          setLoading(false);
        }
        if (page > 1) {
          setPage(1);
        }
        setSearchSkill(value);
      }, 2000)
    );
  };

  const fetchListSkill = () => {
    setLoading(true);
    RoleSkillTalent.getListSkillEmployee(
      page - 1,
      perPage,
      searchRole,
      searchSkill
    )
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.data?.content) {
          setDataTable(resp.data.data.content);
          setTotalPage(
            resp?.data?.data?.totalElements &&
              resp?.data?.data?.totalElements > 0
              ? resp?.data?.data?.totalElements
              : 1
          );
        } else {
          setTotalPage(1);
          setDataTable([]);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : `sorry we're having trouble`,
        });
        setTotalPage(1);
        setDataTable([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFormSubmit = (value) => {
    if (isEdit) {
      let body = {
        id: selectData.id,
        experienceBulan: value.experienceBulan,
        skill: value.skill,
        usersCourses: value.usersCourses,
      };
      updateSkill(body);
    } else {
      addSkill(value);
    }
  };

  const handleOpenDelete = (value) => {
    setSelectData(value);
    setOpenModalDelete(true);
  };

  const handleEdit = (value) => {
    console.log("value ", value);
    setSelectData(value);
    setIsEdit(true);
    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    deleteSkill();
  };

  const handleDeleteCancel = () => {
    setOpenModalDelete(false);
    setSelectData(undefined);
  };

  const addSkill = (body) => {
    setLoading(true);
    RoleSkillTalent.addSkillEmployee(body)
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: resp?.data?.message ? resp?.data?.message : `Success`,
          });
          if (page > 1) {
            setPage(1);
          } else {
            fetchListSkill();
          }
        } else if (resp?.status === 200 && resp?.data?.status === 404) {
          messageApi.open({
            type: "error",
            content: resp?.data?.message
              ? resp?.data?.message
              : `sorry we're having trouble`,
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: `sorry we're having trouble`,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : `sorry we're having trouble`,
        });
      });
  };

  const deleteSkill = () => {
    setLoading(true);
    RoleSkillTalent.deleteSkillEmployee({ id: selectData.id })
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: resp?.data?.message ? resp?.data?.message : `Success`,
          });
          setOpenModalDelete(false);
          if (page > 1) {
            setPage(1);
          } else {
            fetchListSkill();
          }
        } else if (resp?.status === 200 && resp?.data?.status === 404) {
          messageApi.open({
            type: "error",
            content: resp?.data?.message
              ? resp?.data?.message
              : `sorry we're having trouble`,
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: `sorry we're having trouble`,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : `sorry we're having trouble`,
        });
      });
  };

  const updateSkill = (body) => {
    setLoading(true);
    RoleSkillTalent.updateSkillEmployee(body)
      .then((resp) => {
        if (resp?.status === 200 && resp?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: resp?.data?.message ? resp?.data?.message : `Success`,
          });
          if (page > 1) {
            setPage(1);
          } else {
            fetchListSkill();
          }
        } else if (resp?.status === 200 && resp?.data?.status === 404) {
          messageApi.open({
            type: "error",
            content: resp?.data?.message
              ? resp?.data?.message
              : `sorry we're having trouble`,
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: `sorry we're having trouble`,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message
            ? error?.message
            : `sorry we're having trouble`,
        });
      });
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-screen px-4 py-5">
        <TalentHeader title={"Skills"} />
        <div className="flex justify-end w-full my-4 gap-2">
          <input
            type="text"
            placeholder="Search Role"
            onChange={(e) => handleSearchRole(e.target.value)}
            className="border rounded-md w-full md:w-[250px] py-1 px-2"
          />
          <input
            type="text"
            placeholder="Search Skill"
            onChange={(e) => handleSearchSkill(e.target.value)}
            className="border rounded-md w-full md:w-[250px] py-1 px-2"
          />
          <button
            className="bg-[#DD0000] text-white w-full md:w-[200px] py-1 font-bold rounded ms-3"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Add
          </button>
        </div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <div className="mt-[10px] w-full">
              <Table
                columns={columns}
                dataSource={dataTable?.map((item) => ({
                  ...item,
                  key: item.id,
                }))}
                pagination={false}
                sticky={true}
                scroll={{
                  y: 470,
                  x: 1000,
                }}
              />
            </div>
            {totalPage > 1 ? (
              <Pagination
                current={page}
                total={totalPage}
                pageSize={perPage}
                showSizeChanger={false}
                onChange={(p) => setPage(p)}
                className="flex justify-center mt-[10px]"
              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <SkillForm
        isEdit={isEdit}
        key={counter}
        show={showModal}
        data={selectData}
        onClickSubmit={(data) => {
          setCounter(counter + 1);
          handleFormSubmit(data);
        }}
        onCancel={(value) => {
          setIsEdit(false);
          setCounter(counter + 1);
          setShowModal(value);
          setSelectData(undefined);
        }}
      />

      <Modal
        open={openModalDelete}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        width={350}
        footer={null}
        closable={false}
        keyboard={false}
      >
        <div className="flex items-center gap-2">
          <ExclamationCircleFilled
            style={{ fontSize: "20px", color: "#faad14" }}
          />
          <p className="text-black text-[16px] font-medium">
            Are you sure delete this Skill {selectData?.skill?.name}
          </p>
        </div>
        <div className="flex justify-end mt-5 gap-[10px]">
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button onClick={handleDeleteConfirm} type="primary" danger>
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SkillsPage;
