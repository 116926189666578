// ROUTE PAGE

const login = "/";
const activateAccount = "/activate-account";
const forgotPassword = "/forgot-password";
const setNewPassword = "/set-new-password";
const attendanceVerification = "/attendance-verification/:eventId";
const attendanceRecorded = "/attendance-recorded";
const formInterviewTalent = "/form-interview-talent/:id";

const RouteName = {
  login,
  forgotPassword,
  activateAccount,
  setNewPassword,
  attendanceVerification,
  attendanceRecorded,
  formInterviewTalent,
};

export default RouteName;
