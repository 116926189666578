import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";

const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getTalentJourney = async (page = 0, size = 10, courseName) => {
  let params = {
    page: page + 1,
    size: size,
  };

  if (typeof courseName != "undefined" && courseName.length) {
    params["courseName"] = courseName;
  }

  const url = `${API_BASE}/history-courses/list-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getTalentJourneyId = async (uuid) => {
  const url = `${API_BASE}/history-courses/${uuid}`;
  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getTalentJourneyTermin = async (courseId, level, termin = 1) => {
  const url = `${API_BASE}/user-courses/list-matkul/course/${courseId}/level/${level}/termin/${termin}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getTalentJourneyLevel = async (page = 0, size = 10, type) => {
  let params = {
    page: page + 1,
    size: size,
  };

  if (typeof type != "undefined" && type.length) {
    params["type"] = type;
  }

  const url = `${API_BASE}/lookup/list?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getTalentJourneyTopic = async (
  page = 0,
  size = 10,
  courseId,
  matkulId
) => {
  let params = {
    page: page + 1,
    size: size,
  };

  if (typeof courseId != "undefined" && courseId.length) {
    params["userCourseId"] = courseId;
  }

  if (typeof matkulId != "undefined" && matkulId.length) {
    params["matkulId"] = matkulId;
  }
 // get id for pg from here
  const url = `${API_BASE}/user-courses/list-submatkul-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const SubmitUTSUAS = async (body) => {
  const url = `${API_BASE}/user-courses/submit-user`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const SubmitProgress = async (body) => {
  const url = `${API_BASE}/user-courses/progress-user`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const getMultiChoice = async (id = '64d2bca0-3d5b-45d4-8412-4ac1d4547828', page = 0, size = 10) => {
  const url = `${API_BASE}/sub-matkull-pg/list-user/sub-matkul/${id}`;
  const response = await CallApi({url, method: 'GET'});
  return response;
}

const submitMultiChoice = async ( id, body) => {
  const url = `${API_BASE}/sub-matkull-pg/user-submit-pg/users-courses-exam/${id}`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
}

const assignMeCourse = async (id) => {
  const url = `${API_BASE}/user-courses/injectUserCourse-assingbytalent/courseId/${id}`;
  const response = await CallApi({ url, method: "POST" });
  return response;
}

const TalentJourneyRepo = {
  getTalentJourney,
  getTalentJourneyId,
  getTalentJourneyTermin,
  getTalentJourneyLevel,
  getTalentJourneyTopic,
  SubmitUTSUAS,
  SubmitProgress,
  getMultiChoice,
  submitMultiChoice,
  assignMeCourse
};

export default TalentJourneyRepo;
