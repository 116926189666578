import React from "react";
import AlertMiniPopUp from "../../admin/componets_global/AlertMiniPopUp";
import { useSelector } from "react-redux";
import TalentHeader from "../../../components/general/TalentHeader";

const LayoutCertificate = ({ children }) => {
  const { message, isAlert, isSuccess } = useSelector(
    (state) => state.certificateTalent
  );
  return (
    <React.Fragment>
      {isAlert && (
        <AlertMiniPopUp
          isAlert={isAlert}
          messageAlert={message}
          isSuccess={isSuccess}
        />
      )}
      <div className="overflow-auto h-screen w-full">
        <TalentHeader
          title={"Certificates"}
          subDescription={"Here’s all your obtained certificate below!"}
          imageSrc={"/assets/images/certificate-header-image.png"}
        />
        <main>{children}</main>
      </div>
    </React.Fragment>
  );
};

export default LayoutCertificate;
