export const QuizIntroduction = ({
  overviewMessage,
  overviewTitle,
  startTakingQuiz,
}) => {
  return (
    <div className="absolute left-[100px] right-[100px] top-1/2 -translate-y-1/2 flex items-center gap-16">
      <img
        alt="quiz-mock"
        className="w-[460px] object-cover"
        src="/assets/images/quiz-mock.png"
      />
      <section className="flex flex-col gap-5">
        <h1 className="text-white text-[40px] font-bold">
          {overviewTitle || "Business Automation Expert Level Quiz"}
        </h1>
        <p className="text-white text-xl font-medium">
          {overviewMessage ||
            "In today's rapidly evolving business landscape, automation has become a pivotal driver of efficiency and growth. This quiz delves into the core concepts of business automation, guiding you through the strategies and technologies that can streamline processes, enhance productivity, and elevate your business to new heights."}
        </p>
        <button
          className="self-start flex items-center gap-8 py-3 px-8 bg-secondary rounded-[20px]"
          onClick={startTakingQuiz}
        >
          <span className="text-white font-bold">Take the quiz</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M1 8H15M15 8L8 1M15 8L8 15"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </section>
    </div>
  );
};
