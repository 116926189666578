import { ArrowLeftOutlined } from "@ant-design/icons";

export const BackArrow = ({ actionBack }) => {
  return (
    <div
      className="flex flex-row bold items-center"
      style={{ cursor: "pointer" }}
      onClick={actionBack}
    >
      <button className="border-2 border-black rounded-full grid justify-items-center items-center h-[30px] w-[30px]">
        <ArrowLeftOutlined twoToneColor="#fc0303" style={{ fontSize: 16 }} />
      </button>
      <p className="px-2 font-bold">Back</p>
    </div>
  );
};
