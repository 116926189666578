function formatDateTime(date) {
  let tempDate = new Date(date);

  let dateFormated = `${addLeadingZero(tempDate.getDate())}/${addLeadingZero(
    tempDate.getMonth() + 1
  )}/${tempDate.getFullYear()}`;
  return `${dateFormated}`;
}

function addLeadingZero(number) {
  return number < 10 ? `0${number}` : `${number}`;
}

export const generateColumn = (action) => {
  return [
    {
      title: "Role Name",
      dataIndex: ["userRole", "roleCompany", "name"],
      key: "id",
    },
    {
      title: "Talent Name",
      dataIndex: ["userRole", "user", "fullname"],
      key: "id",
    },
    {
        title: "Client Name",
        dataIndex: ["clientCompany", "name"],
        key: "id",
      },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      align: 'center',
      render: (v, row) => {
        return (
          <div className="flex justify-center">
            {v.toLowerCase() === "active" ? (
              <div
                className={`w-[76px] justify-center text-center rounded bg-[#c4fccb] text-[#43ae3b]`}
              >
                {v}
              </div>
            ) : (
              <div className="w-[76px] text-center rounded bg-[#FFCFCF] text-[#DD0000]">
                {v}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      width: "10%",
      render: (_, record) => {
        if (record?.startDate) {
          let date = formatDateTime(record?.startDate);
          return <span>{date}</span>;
        }
        return "-";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      width: "10%",
      render: (_, record) => {
        if (record?.endDate) {
          let date = formatDateTime(record?.endDate);
          return <span>{date}</span>;
        }
        return "-";
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            
            <div
              onClick={() => {
                if(typeof action.onDelete !== 'undefined') {
                  action.onEdit(record)
                }
              }}
              className="mr-[10px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">Edit</span>
            </div>
            
            <div
              onClick={() => {
                if(typeof action.onDelete !== 'undefined') {
                  action.onDelete(record)
                }
              }}
              className="mr-[10px] border-2 decoration-grey-600 border-grey-600 w-[auto] p-[12px] text-sm flex justify-center items-center rounded-[7px] cursor-pointer h-[25px]"
            >
              <span className="text-grey">Delete</span>
            </div>
          </div>
        );
      },
    },
  ];
}