export const JourneyEvent = ({ eventName, children, ...restProps }) => {
  return (
    <div className="flex flex-col w-full max-w-[200px]">
      <button {...restProps} className="flex justify-center">
        {children}
      </button>
      <div className="flex justify-center w-full h-[75px] py-[5px]">
        <span className="text-center text-base md:text-lg lg:text-xl text-[#4C4C4C] font-semibold">
          {eventName}
        </span>
      </div>
    </div>
  );
};

JourneyEvent.FinishedEvent = ({ image }) => {
  return (
    <div className="relative flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full bg-[#30D686] border-2 border-[#00B25C]">
      <img
        alt="finished"
        className="w-12 h-12 md:w-15 md:h-15 aspect-square object-cover"
        src={image}
      />
    </div>
  );
};

JourneyEvent.ClaimableEvent = ({ image }) => {
  return (
    <div className="relative">
      <div className="relative flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full bg-[#30D686] border-2 border-[#00B25C]">
        <img
          alt="claimable"
          className="w-12 h-12 md:w-15 md:h-15 aspect-square object-cover"
          src={image}
        />
      </div>
      <img
        alt="claimable-reward"
        className="absolute -top-6 -right-6 md:-top-8 md:-right-8 w-10 md:w-14"
        src="/assets/images/journeys/badges/claimable-reward.svg"
      />
    </div>
  );
};

JourneyEvent.FailedEvent = () => {
  return (
    <div className="relative flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full bg-[#E53737] border-2 border-[#C31B1B]">
      <img
        alt="failed"
        className="w-12 h-12 md:w-15 md:h-15 aspect-square object-cover"
        src="/assets/images/journeys/badges/Failed-Icon.svg"
      />
    </div>
  );
};

JourneyEvent.OnGoingEvent = ({ image }) => {
  return (
    <div className="relative flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full bg-[#494FDF] border-2 border-[#272ED7]">
      <img
        alt="on-going"
        className="w-12 h-12 md:w-15 md:h-15 aspect-square object-cover"
        src={image}
      />
    </div>
  );
};

JourneyEvent.LockedEvent = ({ image }) => {
  return (
    <div className="relative flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full bg-[#5E5E5E] border-2 border-[#535353]">
      <img
        alt="locked"
        className="w-12 h-12 md:w-15 md:h-15 aspect-square object-cover"
        src={image}
      />
      <span className="absolute w-6 h-6 md:w-8 md:h-8 bg-[#5E5E5E] rounded-full border-2 -top-3 -right-3">
        <img
          alt="locked-icon"
          className="w-full h-full aspect-square object-cover"
          src="/assets/images/journeys/badges/locked.svg"
        />
      </span>
    </div>
  );
};

JourneyEvent.UpComingEvent = ({ image }) => {
  return (
    <div className="relative flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full bg-[#5E5E5E] border-2 border-[#535353]">
      <img
        alt="up-coming"
        className="w-12 h-12 md:w-15 md:h-15 aspect-square object-cover"
        src={image}
      />
    </div>
  );
};
