import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Form, message, Pagination, Modal, Input, Button, Spin  } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import IconArrowLeft from "../../../assets/icons/IconArrowLeft.png"

import AdminLmsRepo from "../../../repositores/adminRepo/LmsRepo";

import TableListSubMatkul from "./components/tableListSubMatkul";

const SubMataKuliah = () => {
    const navigate = useNavigate();
    let { id, matkulId } = useParams();
    const [messageApi, contextHolder] = message.useMessage();

    const [detailCourse, setDetailCourse] = useState(undefined)
    const [detailMatKul, setDetailMatKul] = useState(undefined)

    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(true);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [dataTable, setDataTable] = useState([]);
    const [totalItem, setTotalItem] = useState(1);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const [bounchPage, setBounchPage] = useState(undefined);
    const [searchSubMatkul, setSearchSubMatkul] = useState(undefined);

    const [formSubMatkul] = Form.useForm();
    const [titleModal, setTitleModal] = useState('Add Sub Mata Kuliah');
    const [formType, setFormType] = useState('add')

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const [detailSubMatkul, setDetailSubMatkul] = useState(undefined)


    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, searchSubMatkul, refreshCounter])

    async function fetchData() {
        setLimit(12);
        try {
            let tempData = [];
            if(typeof detailCourse === 'undefined') {
                let responseDetail = await AdminLmsRepo.getCourse(id)
                if(responseDetail?.data?.data?.status === 200) {
                    setDetailCourse(responseDetail?.data?.data?.data)
                } else {
                    throw responseDetail?.data?.data?.data
                }
            }
            if(typeof detailMatKul === 'undefined') {
                let responseDetail = await AdminLmsRepo.getMatkul(matkulId)
                if(responseDetail?.data?.status === 200) {
                    setDetailMatKul(responseDetail?.data?.data)
                } else {
                    throw responseDetail?.data?.data?.data
                }
            } 



            let response = await AdminLmsRepo.getListSubMatkul(
                matkulId,
                page,
                limit,
                searchSubMatkul
            );
            if (response?.data?.data?.content) { 
                await response?.data?.data?.content?.forEach((content, index) => {
                    content.number = (limit*(page-1)) + (index+1)
                    tempData.push(content)
                })
                setDataTable(tempData);
                setTotalItem(response?.data?.data?.totalElements);
                
            } else {
                setDataTable([]);
                setTotalItem(0);
            }
            setLoading(false);
            setLoadingData(false);
        } catch (error) {
            setLoading(false);
            setLoadingData(false);
            messageApi.open({
                type: 'error',
                content: "Failed to get data mata kuliah" ,
            });
        }
    }

    async function postCourse(payload) {
        setLoadingData(true);
        formSubMatkul.resetFields();
        setIsOpenModal(false);
        try {
            let response = await AdminLmsRepo.addSubMatkul(payload)
            if(response.data.status === 200) {
                const counter = refreshCounter + 1
                messageApi.open({
                    type: 'success',
                    content: "Successfully add data" ,
                });
                setRefreshCounter(counter)
            } else {
                throw response.data
            }
        } catch (error) {
            if(error?.message || error?.data?.message || error?.error) {
                messageApi.open({
                    type: 'error',
                    content: error?.message || error?.data?.message || error?.error ,
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: "sorry we're having trouble adding data right now, please try again later" ,
                });
            }
            setLoadingData(false);
        }
    }

    async function putCourse(payload) {
        setLoadingData(true);
        formSubMatkul.resetFields();
        setIsOpenModal(false);
        try {
            let response = await AdminLmsRepo.updateSubMatkul(payload)
            if(response.data.status === 200) {
                const counter = refreshCounter + 1
                messageApi.open({
                    type: 'success',
                    content: "Successfully update data" ,
                });
                setRefreshCounter(counter)
            } else {
                throw response.data
            }
        } catch (error) {
            if(error?.message || error?.data?.message || error?.error) {
                messageApi.open({
                    type: 'error',
                    content: error?.message || error?.data?.message || error?.error ,
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: "sorry we're having trouble updating data right now, please try again later" ,
                });
            }
            setLoadingData(false);
        }
    }

    async function deleteSubMatkul() {
        setLoadingData(true);
        setIsOpenConfirm(false);
        try {
            let response = await AdminLmsRepo.deleteSubMatkul(detailSubMatkul.id)
            if(response.data.status === 200) {
                const counter = refreshCounter + 1
                messageApi.open({
                    type: 'success',
                    content: "Successfully delete data" ,
                });
                setDetailSubMatkul(undefined)
                if(dataTable.length == 1 && page > 1) {
                    setPage(page - 1)
                } else {
                    setRefreshCounter(counter)
                }
            } else {
                throw response.data
            }
        } catch (error) {
            setDetailSubMatkul(undefined)
            if(error?.message || error?.data?.message || error?.error) {
                messageApi.open({
                    type: 'error',
                    content: error?.message || error?.data?.message || error?.error ,
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: "sorry we're having trouble deleting data right now, please try again later" ,
                });
            }
            setLoadingData(false);
        }
    }

    const goBack = () => {
        navigate('/admin/course/'+id)
    }

    const goToDetail = (subMatkulId) => {
        navigate(`/admin/course/${id}/mata-kuliah/${matkulId}/sub/${subMatkulId}`)
    }

    const onChangePage = (page) => {
        setLoadingData(true);
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setPage(page)
        }, 1000))
    }
    const onChangeSearch = (el) => {
        setLoadingData(true);
        clearTimeout(bounchPage);
        setBounchPage(setTimeout(() => {
            setSearchSubMatkul(el.target.value);
            setPage(1);
        }, 2000))
    }

    const addSubMatkul = () => {
        setFormType('add')
        setTitleModal('Add Course')
        setIsOpenModal(true);
    }

    const closeModal = (modal = 'form') => {
        if(modal === 'form') {
            formSubMatkul.resetFields();
            setIsOpenModal(false);
        } else {
            setDetailSubMatkul(undefined);
            setIsOpenConfirm(false);
        }
    }

    const selectEdit = (data) => {
        if(data) {
            const dataLinkFileName = data?.linkFileName?.toUpperCase() !== 'N/A' ? data.linkFileName : ''
            const dataLinkInternet = data?.linkInternet?.toUpperCase() !== 'N/A' ? data.linkInternet : ''
            setFormType('update')
            setTitleModal('Edit Sub Mata Kuliah')
            formSubMatkul.setFieldValue('topik', data?.topik)
            formSubMatkul.setFieldValue('topikNo', data?.topikNo)
            formSubMatkul.setFieldValue('timePraktek', data?.timePraktek)
            formSubMatkul.setFieldValue('timeDaring', data?.timeDaring)
            formSubMatkul.setFieldValue('fileName', data?.fileName)
            formSubMatkul.setFieldValue('linkFileName', dataLinkFileName)
            formSubMatkul.setFieldValue('linkInternet', dataLinkInternet)
            setDetailSubMatkul(data)
            setIsOpenModal(true);
        }
    }
    const selectDelete = (data) => {
        setDetailSubMatkul(data)
        setIsOpenConfirm(true)
    }

    const submitSubMatkul = () => {
        formSubMatkul.validateFields().then(() => {
            let payload = {
                matkul: {
                    id: matkulId
                },
                topik: formSubMatkul.getFieldValue('topik'),
                topikNo: formSubMatkul.getFieldValue('topikNo'),
                timePraktek: formSubMatkul.getFieldValue('timePraktek'),
                timeDaring: formSubMatkul.getFieldValue('timeDaring'),
                fileName: formSubMatkul.getFieldValue('fileName'),
                linkFileName: formSubMatkul.getFieldValue('linkFileName'),
                linkInternet: formSubMatkul.getFieldValue('linkInternet'),
            }
            if(formType === 'add') {
                postCourse(payload)
            } else {
                payload.id = detailSubMatkul.id
                putCourse(payload)
            }
            
        }).catch(() => {})
    }


    if(loading) {
        return (
            <>
                <div className="container w-full" id="courseDetail">
                    <div className="w-[100%] text-center">
                        <Spin size="large" fullscreen />
                    </div>
                </div>
            </>
        )
    }
    return (
    <>
        {contextHolder}
        <div className="w-full" id="courseDetail">
                <div className="w-[100%] px-[20px] py-[20px] flex">
                    <div className="mt-[6px] mr-[20px]">
                        <button onClick={goBack} >
                            <img width={48} src={IconArrowLeft} alt="icon" />
                        </button>
                    </div>
                    <div>
                        <h4 className="text-[32px] font-bold">
                            {detailCourse?.name} / <span className="text-[18px]">{detailMatKul?.matkul}</span>
                        </h4>
                        <p>{detailCourse?.description}</p>
                    </div>
                    
                </div>
                <div className="flex gap-x-6 justify-end px-[20px]">
                    <Input
                        className="w-[300px]"
                        placeholder="Search"
                        disabled={loading}
                        onChange={onChangeSearch}
                        suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
                    />
                    <Button
                        onClick={() => addSubMatkul()}
                        type="primary"
                        disabled={loading}
                        danger
                        className="mr-1 bg-[#DD0000]"
                        style={{ backgroundColor: "#DD0000" }}
                        >
                        Add Sub Mata Kuliah
                    </Button>
                </div>

                {
                    loadingData ? <>
                        <div className="w-full text-center mt-[20px]">
                            <p>Please wait...</p>
                        </div>
                    </> : 
                    <>
                        <div className="mt-[20px] ml-[10px] w-full px-[20px]">
                            <TableListSubMatkul
                                isLoading={loading}
                                dataTable={dataTable}
                                selectEdit={(data) => selectEdit(data)}
                                selectDelete={(data) => selectDelete(data)}
                                goToDetail={(id) => goToDetail(id)}
                            />
                            <div className="w-full flex justify-center my-2 pt-2 pb-10">
                                <Pagination
                                    current={page}
                                    onChange={onChangePage}
                                    pageSize={limit}
                                    total={totalItem}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    </>
                }

                <Modal
                    onCancel={() => closeModal()}
                    title={titleModal}
                    open={isOpenModal}
                    footer={
                        <>
                            <div className="flex justify-between mt-[20px]">
                                <Button key="back" danger onClick={() => closeModal()}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    style={{ backgroundColor: "#DD0000", color: "white" }}
                                    onClick={() => submitSubMatkul()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </>
                    }
                >
                    <div className="container p-[10px] my-[30px]">
                        <Form
                            labelCol={{ flex: "150px" }}
                            labelAlign="left"
                            form={formSubMatkul}
                            colon={false}
                            wrapperCol={{ flex: 1 }}
                        >
                            <Form.Item
                                label="Topic Number"
                                name="topikNo"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input No. sub mata kuliah",
                                    },
                                ]}
                            >
                                <Input placeholder="Please input No. sub mata kuliah" />
                            </Form.Item>
                            <Form.Item
                                label="Topic"
                                name="topik"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input sub mata kuliah",
                                    },
                                ]}
                            >
                                <Input placeholder="Please input sub mata kuliah" />
                            </Form.Item>
                            <Form.Item
                                label="Time Practice (Min)"
                                name="timePraktek"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Time Practice",
                                    },
                                ]}
                            >
                                <Input placeholder="Please input Time Practice in minutes" type="number" min={0} step={1}/>
                            </Form.Item>
                            <Form.Item
                                label="Time Daring (Min)"
                                name="timeDaring"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Time Daring",
                                    },
                                ]}
                            >
                                <Input placeholder="Please input Time Daring in minutes" type="number" min={0} step={1}/>
                            </Form.Item>
                            <Form.Item
                                label="File Name"
                                name="fileName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input file name",
                                    },
                                ]}
                            >
                                <Input placeholder="Please input file name" />
                            </Form.Item>
                            <Form.Item
                                label="Link File"
                                name="linkFileName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input link file sub mata kuliah",
                                    },
                                ]}
                            >
                                <Input placeholder="Please input link file  sub mata kuliah" />
                            </Form.Item>
                            <Form.Item
                                label="Link Internet"
                                name="linkInternet"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input link internet sub mata kuliah",
                                    },
                                ]}
                            >
                                <Input placeholder="Please input link internet  sub mata kuliah" />
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    onCancel={() => closeModal('confirm')}
                    title="Delete Sub Mata Kuliah"
                    open={isOpenConfirm}
                    footer={
                        <>
                            <div className="flex justify-between mt-[20px]">
                                <Button key="back" danger onClick={() => closeModal('confirm')}>
                                    No
                                </Button>
                                <Button
                                    type="primary"
                                    style={{ backgroundColor: "#DD0000", color: "white" }}
                                    onClick={() => deleteSubMatkul()}
                                >
                                    Yes
                                </Button>
                            </div>
                        </>
                    }
                >
                    <div className="container p-[10px] my-[30px]">
                        <p>Are you sure to delete this `<b>{detailSubMatkul?.topik}</b>` ?</p>
                    </div>
                </Modal>
            </div>
    </>)
}

export default SubMataKuliah