import { useCallback, useEffect } from "react";
import { EventCardModal } from "../submodules/events/components/EventCardModal";
import { JourneyEvent } from "./JourneyEvent";
import { useJourneyNavigator } from "../hooks/useJourneyNavigator";
import { useLocation } from "react-router-dom";

export const JourneyNavigator = ({ events }) => {
  const location = useLocation();
  const { navigator, slideNavigatorTo } = useJourneyNavigator();

  const getEventType = useCallback((status, image) => {
    switch (status) {
      case "FINISHED-EVENT":
      case "FINISHED":
        return <JourneyEvent.FinishedEvent image={image} />;
      case "CLAIMABLE":
        return <JourneyEvent.ClaimableEvent image={image} />;
      case "FAILED":
        return <JourneyEvent.FailedEvent />;
      case "ON-GOING":
      case "ON_GOING":
        return <JourneyEvent.OnGoingEvent image={image} />;
      case "LOCKED":
        return <JourneyEvent.LockedEvent image={image} />;
      case "UP-COMING":
      case "UP_COMING":
        return <JourneyEvent.UpComingEvent image={image} />;
      default:
        throw new Error("No event status declared!");
    }
  }, []);

  useEffect(() => {
    slideNavigatorTo();
  }, [location.search, slideNavigatorTo]);

  return (
    <div
      ref={navigator}
      className="relative z-[1] w-full h-[240px] pt-5 flex flex-col md:flex-row flex-nowrap items-center gap-0 md:gap-20 overflow-x-auto px-4 sm:px-[50px] overflow-y-auto"
    >
      {events.map((event, key) => (
        <EventCardModal key={`${key}-${event.id}`} eventId={event.id}>
          {(showModal) => (
            <JourneyEvent onClick={showModal} eventName={event.eventName}>
              {getEventType(event.status, event.img)}
            </JourneyEvent>
          )}
        </EventCardModal>
      ))}
    </div>
  );
};
