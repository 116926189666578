import CallApi from "../../services/Request-Helper";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;
const getDashboardTalent = async () => {
    const url = `${API_BASE}/badges/user-level`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getTalentProfile = async () => {
    const url = `${API_BASE}/user/detail-profile`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getTalentJourney = async () => {
    const url = `${API_BASE}/event/list-user-status?page=0&size=10&status=ON-GOING`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getIssuedCertificate = async () => {
    const url = `${API_BASE}/issued-certificate/user?page=0&size=10`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getRevokedCertificate = async () => {
    const url = `${API_BASE}/issued-certificate/user-revoke?page=0&size=10`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getCoinHistory = async (page = 0, size = 100, id, keyword = "") => {
    const url = `${API_BASE}/redeem-coins/list-history-coin?page=${page}&size=${size}&userId=${id}&description=${keyword}`;

    const response = await CallApi({ url, method: "GET" });
    return response;
}

const getCertificateAll = async () => {
    const url = `${API_BASE}/issued-certificate/list?page=0&size=6&status=&orderBy=issuedDate&orderType=asc`;
    const response = await CallApi({ url, method: "GET" });
    return response;
}

const DashboardTalentRepo = {
    getDashboardTalent,
    getTalentJourney,
    getIssuedCertificate,
    getRevokedCertificate,
    getCertificateAll,
    getTalentProfile,
    getCoinHistory
}

export default DashboardTalentRepo