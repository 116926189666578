import React from "react";
import { Avatar, Tag } from 'antd';
import { isNullOrEmpty } from "../../../../../utils/utils";
import CheckedIcon from '../../../../../assets/icons/CheckedIcon.png'
import { InboxOutlined } from '@ant-design/icons';

const DetailTabsComponent = ({detailEvent, navigateToParticipant, committe}) => {
    const getInitialName = (data) => {
        let arrData = data.split(' ')
        if(arrData.length > 1) {
            return `${arrData[0].split("")[0]}${arrData[1].split("")[0]}`
        }
        return `${arrData[0].split("")[0]}`
    }
    

    return <>
        <div className="w-full " style={{overflowWrap: 'break-word'}}>
            <p>
                {detailEvent?.masterEvent?.description}
            </p>
        </div>
        <div className="mt-[20px]">
            <div className="flex justify-between mb-[10px]">
                <p className="font-semibold">{detailEvent?.participant?.length} People Invited</p>
                <p className="font-semibold">{detailEvent?.committe?.length} Comittee</p>
            </div>
            <div className="flex justify-between">
                <div >
                    <div>
                        <Avatar.Group>
                            {
                                detailEvent?.participant.length ? detailEvent?.participant.map((participan, index) => (
                                    index < 10 && (<Avatar key={`${participan.id}-${index}`} src={participan?.user?.photo ? participan?.user?.photo : ''} style={{
                                        backgroundColor: '#f56a00',
                                    }} >
                                        {getInitialName(participan.user.fullname)}
                                    </Avatar>)
                                )) : 
                                <></>
                            }
                            {
                                detailEvent?.participant.length > 10 && (
                                <a onClick={navigateToParticipant} href="#">
                                    <Avatar
                                        style={{
                                            backgroundColor: '#f56a00',
                                        }}
                                        >
                                    {(detailEvent?.participants.length-10)}
                                    </Avatar>
                                </a>)
                            }
                            
                        </Avatar.Group>
                    </div>
                    
                </div>
                <div >
                    <div>
                    <Avatar.Group>
                            {
                                detailEvent?.committe.length ? detailEvent?.committe.map((committe, index) => (
                                    index < 10 && (<Avatar key={`${committe.id}-${index}`} src={committe?.user?.photo ? committe?.user?.photo : ''} style={{
                                        backgroundColor: '#f56a00',
                                    }} >
                                        {getInitialName(committe.user.fullname)}
                                    </Avatar>)
                                )) : 
                                <></>
                            }
                            {
                                detailEvent?.committe.length > 10 && (<a onClick={navigateToParticipant} href="#">
                                <Avatar
                                    style={{
                                        backgroundColor: '#f56a00',
                                    }}
                                    >
                                +{(detailEvent?.committe.length-10)}
                                </Avatar>
                            </a>)
                            }
                            
                        </Avatar.Group>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="mt-[20px]">
            <div>
                <b>Requirements</b>
            </div>
            {
                !isNullOrEmpty(detailEvent?.masterEvent?.preReiqusition) && 
                    detailEvent?.masterEvent?.preReiqusition?.length ? 
                        (
                            <div className="flex">
                                {
                                    detailEvent?.masterEvent?.preReiqusition.map((_prequistion, index) => (
                                        <Tag key={`${detailEvent?.masterEvent.id}-${index}`} className="mx-[5px]">
                                            {_prequistion?.name}
                                        </Tag>
                                    ))
                                }
                            </div>
                        )
                        :
                        <>
                            <div className="text-center w-[100px]">
                                <div>
                                    <InboxOutlined style={{ fontSize: '24px' }} />
                                </div>
                                <div>
                                    No Data
                                </div>
                            </div>
                        </>
            }
            
        </div>
        <div className="mt-[20px]">
            <div className="flex">
                <div>
                    <b>Task to be done</b>
                </div>
                <div>
                    <img src={CheckedIcon} alt="checked icon" width={16} className="mt-[4px] mx-[5px]"/>
                </div>
                <div>
                    Passing Grade: {detailEvent?.masterEvent?.grade}%
                </div>
            </div>
            <div className="mt-[5px]">
                <ListTaskToBeDone dataSrc={detailEvent?.masterEvent?.masterEventTasks} />
            </div>
        </div>
        <div className="mt-[20px]">
            <div>
                <b>Certificates</b>
            </div>
            {
                !isNullOrEmpty(detailEvent?.masterEvent?.certificate?.title) ? 
                    (
                        <div className="mt-[5px]">
                            <Tag>
                                {detailEvent?.masterEvent?.certificate?.title }
                            </Tag>

                        </div>
                    ) :
                    <>
                    n/a
                    </>
            }
            
        </div>
    </>
}

const ListTaskToBeDone = ({dataSrc}) => {
    if(isNullOrEmpty(dataSrc)) {
     return  (
        <div className="text-center w-[100px]">
            <div>
                <InboxOutlined style={{ fontSize: '24px' }} />
            </div>
            <div>
                No Data
            </div>
        </div>
     )           
    }
    return (
        <>
            {
                dataSrc.map((task, index) => 
                    ((task.type.name == "Attendance") ? 
                        <Tag key={`task-${index}`} className="my-[5px] w-full bg-[#F6F6F6] p-2 px-2">
                            <div className="flex justify-between">
                                <div>
                                    Attendance
                                </div>
                                <div>
                                    {task.grade}%
                                </div>
                            </div>
                        </Tag> :
                        <Tag key={`task-${index}`} className="my-[5px] w-full bg-[#F6F6F6]">
                            <div className="flex justify-between">
                                <div>
                                    {task?.task?.title}
                                </div>
                                <div>
                                    {task.grade}%
                                </div>
                            </div>
                        </Tag>
                    
                ))
            }
        </>
    )
    
    
}

export default DetailTabsComponent