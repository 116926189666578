import React, { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

const CustomModal = ({ show, closeModal, children, title }) => {
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show, closeModal]);

  return (
    <AnimatePresence>
      {show && (
        <>
          <div className="custom-modal-backdrop" onClick={closeModal}></div>

          <motion.div
            initial={{ opacity: 0, translateY: "100%", scale: 0.8 }}
            animate={{ opacity: 1, translateY: 0, scale: 1 }}
            exit={{ opacity: 0, translateY: "100%", scale: 0.8 }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 100,
              damping: 15,
            }}
            className="custom-modal"
          >
            <div ref={modalRef} className="custom-modal-content">
              <div className="modal-header">
                <h2 className="text-2xl font-bold">{title}</h2>
                <button onClick={closeModal}>
                  <img src="/assets/icons/close-icon.svg" alt="icon-delete" />
                </button>
              </div>
              <div className="modal-body">{children}</div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default CustomModal;
