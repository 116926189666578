import React from "react";
import { Table } from "antd";
import { clientTableColumn } from "../data/column.data";

const ClientTable = ({ data, actionEdit, actionDelete }) => {
  const getId = (actionTask, selectedId) => {
    if (actionTask === "delete") actionDelete(selectedId);
    else if (actionTask === "edit") actionEdit(selectedId);
  };

  return (
    <div className="mt-[10px] w-full">
      <Table
        columns={clientTableColumn((actionTask, selectedId) =>
          getId(actionTask, selectedId)
        )}
        dataSource={data}
        pagination={false}
        sticky={true}
        rowKey={"id"}
        scroll={{
          x: 800,
          y: 520,
        }}
      />
    </div>
  );
};

export default ClientTable;
