import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListRewards = async (page, size) => {
    let params = {
        page: page.toString(),
        size: size
    }
    const url = `${API_BASE}/voucher/list?${createSearchParams(params)}`;
    const response = await CallApi({ url, method: "GET" });
    return response;

  }
const getRewards = async (page, size, desc) => {
    let params = {
        page: page.toString(),
        size: size,
        description: desc
    }
    const url = `${API_BASE}/voucher/list?${createSearchParams(params)}`;
    const response = await CallApi({ url, method: "GET" });
    return response;

  }
const getListByID = async (id) => {
    const url = `${API_BASE}/voucher/${id}`;
    const response = await CallApi({ url, method: "GET" });
    return response;
}

const addRewards = async (body) => {
    const url = `${API_BASE}/voucher`;
    const response = await CallApi({ url, method: "POST", data: body });
    return response;
}

const editRewards = async (body) => {
    const url = `${API_BASE}/voucher`;
    const response = await CallApi({ url, method: "PUT", data: body });
    return response;
}

const deleteRewards = async (body) => {
    const url = `${API_BASE}/voucher`;
    const response = await CallApi({ url, method: "DELETE", data: body });
    return response;
}

const RewardsRepo = {
    getListRewards,
    getListByID,
    addRewards,
    editRewards,
    deleteRewards,
    getRewards
}

export default RewardsRepo